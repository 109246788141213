import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AppointmentComments from '../Appointment/AppointmentComments';
import StaticTextInput from '../StaticTextInput';
import UserSearch from '../UserSearch';
import customerActions from '../../Actions/customerActions';
import commonActions from '../../Actions/commonActions';
import { bindActionCreators } from 'redux';
import Loader from '../Loader';
import routes from '../../Constants/routes';
import TextInput from '../TextInput';
import {useParams, useNavigate } from 'react-router-dom';

const AttendedOnboardingDetails = ({ t, attendedOnboardingForm, vbCustomer, customer, commonActions, customerActions }) => {

    const navigate = useNavigate();

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField("attendedOnboardingForm", field, value);
    }

    const onCancelForm = () => {
        commonActions.cancelFormEditing("attendedOnboardingForm", () => navigate(routes.attendedOnboardings(customer.pathId)));
    }

    const onSaveForm = () => {
        customerActions.updateOnboardingDetails(attendedOnboardingForm, () => navigate(routes.attendedOnboardings(customer.pathId)));
    }

    if (!attendedOnboardingForm)
        return null;

    const onboarding = attendedOnboardingForm.data || {};
    return (<>
        <div className="form">
            <fieldset className="col-xs-10">
                <UserSearch label={t('attendedonboardingdetails.assignto')} onChange={onFieldChange.bind(this, 'assignee')} defaultSelected={onboarding.assignedName} />
                <StaticTextInput label={t('attendedonboardingdetails.service')} value={onboarding.serviceName} />
                <StaticTextInput label={t('attendedonboardingdetails.contactperson')} value={onboarding.contactPerson} />
                <StaticTextInput label={t('attendedonboardingdetails.email')} value={vbCustomer.emailAddress} />
                <StaticTextInput label={t('attendedonboardingdetails.phone')} value={vbCustomer.phoneNumber} />
                <AppointmentComments comments={onboarding.allComments} />
                <TextInput label={t('attendedonboardingdetails.createcomment')} maxAmountOfCharacters={4000} onChange={onFieldChange.bind(this, 'comment')} value={onboarding.comment || ""} multiline={true} />
                <div className="form-group padding-top no-left-margin btn-toolbar">
                        <button type="button" className="btn btn-primary" onClick={onSaveForm.bind(this)} >{t('attendedonboardingdetails.save')}</button>
                        <button type="button" className="btn btn-default" onClick={onCancelForm.bind(this)}>{t('attendedonboardingdetails.cancel')}</button>
                        <Loader />
                </div>
            </fieldset>
        </div>
    </>);
}


function mapStateToProps(state) {
    return {
        attendedOnboardingForm: state.CommonReducer.forms && state.CommonReducer.forms.attendedOnboardingForm,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttendedOnboardingDetails));