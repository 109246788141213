import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import Informative from './Informative';
import helpers from '../Utils/helpers';
import ProductVersionRow from './ProductVersionRow';

function ProductVersionList({productVersionList, selectedProductId, productOverviewActions, editable}) {

    useEffect(() => {
        if(selectedProductId){
            productOverviewActions.getProductVersionList(selectedProductId);
        }
    }, [selectedProductId])


    if(!selectedProductId){
        return <Informative cause="You have not selected a product yet" directions="Please select a product in the menu to the left" icon="info"/>
    }

    if(selectedProductId && (!productVersionList || productVersionList.length === 0)){
        return <Informative cause="Oops.. There is no version list for this product" icon="warning"/>
    }

  return (
    <div className='overflow-table-container'>
        <table className='overflow-table-body table table-hover table-hover-custom'>
            <thead>
                <tr>
                    <th>Version</th>
                    <th>Serial Number</th>
                    <th>File Size</th>
                    <th>Release Date</th>
                    <th className='iconzindex'>Send Version</th>
                    {editable && <th></th>}
                    {editable && <th></th>}
                </tr>
            </thead>
            <tbody>
                {productVersionList && productVersionList.map((version, i) => {
                    return<ProductVersionRow key={i} editable={editable} version={version}/>
                })}
            </tbody>
        </table>
    </div>
  )
}

function mapStateToProps(state) {
    return {
      productVersionList: state.ProductOverviewReducer.productVersionList,
      selectedProductId: state.ProductOverviewReducer.selectedProductId,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      productOverviewActions: bindActionCreators(productOverviewActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductVersionList));