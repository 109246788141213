import React from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom'
import DiscountContainer from '../../Containers/Discounts/DiscountContainer';
import DiscountsContainer from '../../Containers/Discounts/DiscountsContainer';
import Breadcrumbs from '../Breadcrumbs';

function DiscountLayout() {
    const [searchParams] = useSearchParams();


    const discountId = searchParams.get("DiscountId")

    const breadcrumbsCreate = [
        { header: "Rabattavtal", label: 'Rabattavtal', url: '/discounts/' },
        { header: 'Skapa rabattavtal', label: 'Skapa rabattavtal', url: '/discounts/createnewdiscount/' },
    ];

    const breadcrumbsEdit = [
        { header: "Rabattavtal", label: 'Rabattavtal', url: '/discounts/' },
        { header: 'Redigera rabattavtal', label: 'Redigera rabattavtal', url: `/discounts/editDiscount/` },
    ];

    return (
        <div className="row">
            <div className="col-xs-12">
                <Breadcrumbs breadcrumbs={discountId ? breadcrumbsEdit : breadcrumbsCreate} />
                <Routes>
                    <Route path={'/createnewdiscount'} element={<DiscountContainer />} />
                    <Route path={'/editdiscount/'} element={<DiscountContainer isEdit={true}/>} />
                    <Route index element={<DiscountsContainer />} />
                </Routes>
            </div>
        </div>
    )
};

export default DiscountLayout;