import React from 'react';

function FormSearch({children, label, className}) {

    return <div className={'form-control search-group skynet-form-search search-group-button'}>
        <label className="control-label">{label}</label>
        {children(`${className ? className : ''}`)}
    </div>
}

export default FormSearch;
