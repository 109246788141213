import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import RadioButton from './RadioButton';
import TextInput from './TextInput';
import commonActions from '../Actions/commonActions';
import productOverviewActions from '../Actions/productOverviewActions';
import helpers from '../Utils/helpers';
import DateTimeReact from 'react-datetime';
import SwitchToggle from "./SwitchToggle";

function AddVersionForm({productId, commonActions, productVersionForm, downloadLinkTemplate, productOverviewActions, t}) {

    const formData = {
        id: 0,
        productId,
        version: null,
        serialNumber: false,
        downloadLink: null,
        fileSize: 0,
        releaseDate: null,
        sendVersion: false,
    }

    useEffect(() => {
        commonActions.startFormEditing("productVersionForm", {...formData});
    }, [productId]);

    useEffect(() => {
        return () => {
            commonActions.cancelFormEditing(productVersionForm?.id);
        }
    }, [])

    const handleFormSubmit = (e) => {
        e.preventDefault();

        productOverviewActions.updateProductVersion(productVersionForm.data)
    }

    const handleInputChange = (field, value) => {
        commonActions.changeMultiFormField(productVersionForm.id, field, value);
    }

    const handleNewVersionClick = () => {
        commonActions.startFormEditing("productVersionForm", {...formData});
    }

    const replaceTemplateWithVersion = (template, version) => {
        if(!template) return null;

        return template.replace("[version]", version ? version : "[version]");
    }

    if(!productVersionForm){
        return null;
    }

    const isNotExistingVersion = productVersionForm.data.id === 0;


  return (
    <div className='addversionformcontainer'>
        <div className='col-xs-12'>
          <h3 className='titleAndLink'><strong>{t("productOverview.addNewVersion")}</strong>{!isNotExistingVersion && <p className='ProductOverviewItemTitle' onClick={handleNewVersionClick}>{t("productOverview.createNewVersion")}</p>}</h3>
        </div>
        <form className='addversionform' onSubmit={handleFormSubmit}>
            <div>
                <TextInput textInputWidth={12} label={<strong>Version</strong>} value={productVersionForm.data.version} onChange={(value) => handleInputChange("version", value)} disabled={!isNotExistingVersion}/>
            </div>
            <div className='col-xs-12'>
                <label className='control-label'><strong>{t("productOverview.releaseDate")}</strong></label>
                <DateTimeReact 
                    value={productVersionForm.data.releaseDate && helpers.formatDate(productVersionForm.data.releaseDate)} 
                    onChange={(value) => handleInputChange("releaseDate", value)} 
                    dateFormat="YYYY-MM-DD" 
                    timeFormat={false}
                    inputProps={{placeholder: "YYYY-MM-DD"}}
                    utc={true}/>
            </div>
            <div>
                <div className='col-xs-12 form-group'>
                    <label><strong>{t("productOverview.downloadLink")}</strong></label>
                    <RadioButton label="Product Template URL" style={{marginRight: 1 + 'rem'}} checked={productVersionForm.data.downloadLink === null} onChange={() => handleInputChange("downloadLink", null)}/>
                    <RadioButton label="Custom URL" style={{marginRight: 1 + 'rem'}} checked={productVersionForm.data.downloadLink === "" || productVersionForm.data.downloadLink?.length > 0} onChange={() => handleInputChange("downloadLink", downloadLinkTemplate ? replaceTemplateWithVersion(downloadLinkTemplate, productVersionForm.data.version) : '')}/>
                </div>
                <TextInput textInputWidth={12} disabled={productVersionForm.data.downloadLink === null} value={productVersionForm.data.downloadLink === null ? replaceTemplateWithVersion(downloadLinkTemplate, productVersionForm.data.version) : productVersionForm.data.downloadLink} onChange={(value) => handleInputChange("downloadLink", value)}/>
            </div>
            <div>
                <div className='form-group'>
                    <label className="col-xs-4 control-label"><strong>{t("productOverview.showInSendversion")}</strong></label>
                    <div className="col-xs-8">
                        <SwitchToggle checked={productVersionForm.data.sendVersion} onChange={() => handleInputChange("sendVersion", !productVersionForm.data.sendVersion)}/>
                    </div>
                </div>
            </div>
            <div>
                <div className='form-group'>
                    <label className="col-xs-4 control-label"><strong>Serial Number</strong></label>
                    <div className="col-xs-8">
                        <SwitchToggle checked={productVersionForm.data.serialNumber} onChange={() => handleInputChange("serialNumber", !productVersionForm.data.serialNumber)}/>
                    </div>
                </div>
            </div>
            <div className='col-xs-12'>
                <button type='submit' className="btn btn-primary no-margin" disabled={!productVersionForm.data.version}>{t("productOverview.addVersion")}</button>
            </div>
        </form>
    </div>
  )
}

function mapStateToProps(state) {
    return {
      productVersionForm: state.CommonReducer.forms && state.CommonReducer.forms.productVersionForm,
      downloadLinkTemplate: state.CommonReducer.forms && state.CommonReducer.forms.productForm?.data.downloadLinkTemplate,
    }
}

function mapDispatchToProps(dispatch) {
    return {
      productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
      commonActions: bindActionCreators(commonActions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddVersionForm));