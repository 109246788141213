import React from "react";

export function Checkbox({
    label,
    checked,
    onChange,
    disabled,
    className,
    required = false,
    id
}) {

    return (
        <div className={`form-control checkbox skynet-form-input${required ? " required" : ""}`}>
            <input
                type="checkbox"
                id={id ?? label}
                disabled={disabled}
                checked={checked ?? false}
                defaultValue={checked}
                onChange={(e) => onChange(e.target.checked, e.target.id)}
                className={className ?? "skynet-input"}
            />
            <label htmlFor={id ?? label}>{label}</label>
        </div>
    );
}