import React, { Component } from 'react';
import helper from '../../Utils/helpers'
import './OrderGrid.css'
import { withTranslation } from 'react-i18next';

import OrderGridActions from './OrderGridActions'

import system from '../../Constants/system'

export class OrderGrid extends Component {

    renderAdditions(orderline) {
        const {t} = this.props
        if(orderline.sourceSystem === system.Von) {
            return orderline.addons && orderline.addons.map(addon=>addon.productDescription).join(', ');
        }

        return t('ordergrid.additionalusers') + `${orderline.additionalUsersCount}` + t('ordergrid.api') + `${orderline.api ? "yes" : "no"}`
    }

    renderOrderLines(orderlines) {
        return (
            orderlines.map((orderline, index) =>
                <tr key={index} className="cursorpointer">
                    <td className="text-left">{orderline.productName}</td>
                    <td className="text-left">{this.renderAdditions(orderline)}</td>
                    <td className="text-left">{helper.currencyFormatter(orderline.totalInitialPrice)}</td>
                    <td className="text-left">{helper.currencyFormatter(orderline.totalRecurringPrice)}</td>
                    {!this.props.isApprovalRequest && <OrderGridActions orderline={orderline} onEdit={(orderline => this.props.onEditClick(orderline))} onRemove={(orderlineId) => this.props.onRemoveClick(orderlineId)} />}
                </tr>
            )
        )
    }

    renderTotals(order) {
        const {t} = this.props
        return (
            <tr className="active">
                <td className="text-center no-border">{t('ordergrid.total')}</td>
                <td className="text-left no-border"></td>
                <td className="text-left no-border">{helper.currencyFormatter(order.totalInitialPrice)}</td>
                <td className="text-left no-border">{helper.currencyFormatter(order.totalRecurringPrice)}</td>
                {!this.props.isApprovalRequest && <td className="text-left no-border"></td>}
            </tr>
        )
    }

    render() {
        const order = this.props.order;
        const {t, isApprovalRequest} = this.props
        return (
            <table className="table table-stripe table-hover order-details">
                <thead>
                    <tr>
                        <th width="250" className="text-left">{t('ordergrid.product')}</th>
                        <th className="text-left">{t('ordergrid.additions')}</th>
                        <th width="100" className="text-left">{t('ordergrid.initialprice')}</th>
                        <th width="100" className="text-left">{t('ordergrid.recurringprice')}</th>
                        {!isApprovalRequest && <th width="120" className="text-left">{t('ordergrid.actions')}</th>}
                    </tr>
                </thead>
                <tbody>
                    {order.orderlines && this.renderOrderLines(order.orderlines)}
                    {this.renderTotals(order)}
                </tbody>
            </table>
        );
    }
}

export default withTranslation()(OrderGrid)