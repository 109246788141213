import { useState } from "react";
import { FormInput } from "../../FormComponents/Input/Input";
import BaseSearch from "../../BaseSearch";

function DiscountCustomerForm({ onChange, form: { data }, ...props }) {
    const [intervalStart, setIntervalStart] = useState('');
    const [intervalEnd, setIntervalEnd] = useState('');

    const onAddCustomerGroup = () => {
        const existingCustomerGroups = data.customerGroups || [];
        existingCustomerGroups.push({ intervalStart, intervalEnd });
        setIntervalStart('');
        setIntervalEnd('');
        onChange(existingCustomerGroups, 'customerGroups');
    };

    const onAddCustomer = (customerId) => {
        const existingCustomers = data.customers || [];
        existingCustomers.push({customerId});
        onChange(existingCustomers, 'customers');
    };

    return (
        <div style={{width: "304px"}}>
            <fieldset className={`d-flex flex-column gap-16 mb-0`}>
                <CustomerSelector onChange={onAddCustomer} data={data} {...props} />
                <div className='mb-0 d-flex align-items-center'>
                    <div className="flex-grow-1">
                        <FormInput
                            label={'Interval start'}
                            value={intervalStart}
                            onChange={(value) => setIntervalStart(value)}
                        />
                    </div>
                    <p className="mr-16 ml-16 pt-8 mb-0">_</p>
                    <div className="flex-grow-1">
                        <FormInput
                            label={'Interval end'}
                            value={intervalEnd}
                            onChange={(value) => setIntervalEnd(value)}
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="btn float-right w-100"
                        onClick={onAddCustomerGroup}
                        disabled={!intervalStart || !intervalEnd}
                    >
                        Add customer group
                    </button>
                </div>
            </fieldset>
        </div>
    );
}

function CustomerSelector({ onChange, customerSearchResults, commonActions }) {
    const selectCustomer = (value) => {
        if (value.length) {
            onChange(value[0].customerId);
        }
    };

    const attributes = {
        id: "customer-selector",
        labelKey: option => option.name,
        options: customerSearchResults || [],
        placeholder: "Search customer",
        onChange: (selected) => selectCustomer(selected),
        onChangeAttr: () => {},
        onSearchAttr: (query) => commonActions.searchVossCustomer(query),
        className: ""
    };

    return (
        <div className="form-control skynet-form-input px-0 mb-0">
            <label>Customer</label>
            <BaseSearch attributes={attributes} />
        </div>
    );
}

export default DiscountCustomerForm;