import React, { useState } from 'react';

function TextInput({ label, value, field, onChange, fieldErrorFlags, placeholder, disabled, hideErrorIcon, readonly }) {
    const [showTooltip, setShowTooltip] = useState(false);

    const error = fieldErrorFlags && fieldErrorFlags[field];

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className={`mb-12 form-group ${error ? 'has-error' : ''}`}>
            {label && <label>{label}</label>}
            <div className='d-flex'>
                <input value={value ?? ''} type="text" className={`form-control rounded-8 ${readonly ? "inverted" : ""}`} onChange={onChange} placeholder={placeholder} disabled={disabled} readOnly={readonly}/>
                {error && !hideErrorIcon && <div className='position-relative'>
                    <span
                        className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                    {showTooltip && (
                        <div className="tooltip tooltip-error text-input-error-tooltip" type="tooltip">
                            <div className="tooltip-arrow"></div>
                            <div className="tooltip-inner">{error}</div>
                        </div>
                    )}</div>}
            </div>
        </div>
    );
}

export default TextInput;
