import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import moment from 'moment'
import routes from '../../Constants/routes'
import ActionHistory from '../../Components/ActionHistory'
import appointmentActions from '../../Actions/appointmentActions'
import SendEmailModal from '../Appointment/AppointmentSendEmailModal'
import CloseModal from '../Appointment/AppointmentCloseModal'
import BuyModal from '../Appointment/AppointmentBuyModal'
import AppointmentDetailActions from '../../Components/Appointment/AppointmentDetailActions'
import AppointmentFormContainer from '../Appointment/AppointmentFormContainer'
import CustomerInfo from '../../Components/CustomerInfo'
import {useParams, useNavigate } from 'react-router-dom';
import Panel from "../../Components/Panel";
import {withTranslation} from "react-i18next";

function CustomerAppointmentFormContainer({ appointmentActions, vbCustomer, customer, user, appointment, t }) {

    const { appointmentId } = useParams();
    const navigate = useNavigate();
    const [activeModal, setActiveModal] = useState();
    const [emailActionLogId, setEmailActionLogId] = useState(0)

    useEffect(() => {
        appointmentId
            ? appointmentActions.editAppointment(appointmentId)
            : appointmentActions.addAppointment('appointmentForm', createAppointmentFormData())

            return () => {
                appointmentActions.clearAppointment();
            }
    }, [])

    const createAppointmentFormData = () => {

        return {
            customerNumber: vbCustomer.customerNumber,
            customerName: vbCustomer.name,
            contactPerson: vbCustomer.contactPerson,
            phoneNumber: vbCustomer.phoneNumber,
            emailAddress: vbCustomer.emailAddress,
            time: moment().format('YYYY-MM-DD HH:mm'),
            assigneeName: user.name,
            assignee: user.email,
            subject: "",
            comments: ""
        }
    }

    const toggleModal = (modalName, emailActionLogId = 0) => {
        setActiveModal(modalName)

        setEmailActionLogId(emailActionLogId)
    }

    const onNoAnswerClick = () => {
        var payload = { data: { action: "No answer" } };

        appointmentActions.saveAction(appointment.id, payload, false);
        refresh();

    }

    const onCloseClick = (isModal) => {

        if (isModal) {
            navigate(routes.customerAppointments(customer.pathId));
        } else {
            if (location.useGoBack) {
                navigate(-1)
            } else {
                navigate(routes.customerAppointments(customer.pathId))
            }
        }
    }

    const onBuyClick = (actionName) => {
        if (actionName === 'order') {
            navigate(routes.orderPlacement(appointment.customerNumber))
        }
        else if (actionName === 'close') {
            toggleModal("showBuyModal");
        }
    }

    const refresh = () => {
        appointmentActions.editAppointment(appointment.id);
    }

    const navigateAfterAction = () => {
        return () => navigate(routes.customerAppointments(customer.pathId));
    }


    const isCreation = !appointmentId;
    
    const isCompleted = (appointment && appointment.isCompleted) || false;

    let leadType = "";
    if (appointment)
        leadType = appointment.lead && appointment.lead.isAoLead ? "aoLead" : "lead";
    
    
    return (
        <Panel title={t('appointmentscontainer.headernew')}>
            {activeModal === "showSendEmailModal" && <SendEmailModal onClose={toggleModal.bind(this, "showSendEmailModal")} onSave={() => onCloseClick(true)} emailActionLogId={emailActionLogId} />}
            {activeModal === "showCloseModal" && <CloseModal leadType={leadType} onClose={toggleModal.bind(this, "showCloseModal")} onSave={() => onCloseClick(true)} callback={navigateAfterAction()} />}
            {activeModal === "showBuyModal" && <BuyModal leadType={leadType} onClose={toggleModal.bind(this, "showBuyModal")} onSave={() => onCloseClick(true)} callback={navigateAfterAction()} />}

            
            
            <div className="row">
                <div className="col-7">
                    <AppointmentFormContainer callback={navigateAfterAction()} isCreation={!appointmentId} isMyAppointment={false} appointmentFormWidth={12} />
                </div>


                <div className="col-5">
                    <div className="row">
                        {!isCreation && !isCompleted && <div className="col-12">
                            <AppointmentDetailActions
                                leadType={leadType}
                                onClick={(modalId) => toggleModal(modalId)}
                                onNoAnswerClick={() => onNoAnswerClick()}
                                onBuyClick={onBuyClick}
                                hasCustomer={appointment && !!appointment.customerNumber}
                            />
                        </div>}

                        <div className="col-12">
                            <CustomerInfo vbCustomer={vbCustomer} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {!isCreation && appointment && <ActionHistory actionHistory={appointment.actionHistory} toggleModal={(modalId, emailActionLogId) => toggleModal(modalId, emailActionLogId)} />}
                </div>
            </div>
        </Panel>
    );
}


function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        appointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerAppointmentFormContainer));