import React from 'react';

const AppointmentComments = ({comments}) => {

    if(!comments || comments.length < 1) {
        return null;
    }

    return (
        <div className="form-group">
            <div className="col-xs-4">
                <label>Comments</label>
            </div>
            <div className="col-xs-8 table-responsive">
                <table className="appointment-comment table">
                    <tbody>
                        { comments.map((comment, index) => {
                            return <tr key={index}>
                                        <td>
                                            <div className="comment">{comment.comment}</div>
                                            <div className="author text-primary">{comment.author}</div>
                                            <div className="date text-disabled">{comment.date}</div>
                                        </td>
                                    </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AppointmentComments;