import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import Checkbox from "../../../Checkbox";
import helpers from "../../../../Utils/helpers";

export function BlikkAdditionalUsersCard({ ...props }) {
    return <Card>
        <Card.Header>
            <Card.Header.Title>Additional Users</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <AdditionalUsers {...props} />
        </Card.Content>
    </Card>
}

function AdditionalUsers({ product, commonActions, customer, order, orderId, orderActions }) {

    const { t } = useTranslation();

    const onChange = (licenseTypes) => {
        orderActions.changeBlikkUserLicenses(licenseTypes);
    };

    const onSave = () => {
        orderActions.getBlikkProductDetails(product.productId, customer.vbCustomer.customerNumber, orderId, product.tenantGuid);
      }

    const onIncrease = (value, licenseId) => {
        onLicenseChange(value, licenseId, 1);
    };

    const onDecrease = (value, licenseId) => {
        onLicenseChange(value, licenseId, -1);
    };

    const onLicenseChange = (value, licenseId, change) => {
        value = parseInt(value, 10);

        if (isNaN(value)) value = 0;

        const licenseTypes = product.licenseTypes;
        const currentLicenseType = licenseTypes.find((item) => item.id === licenseId);

        if (change < 0 && (value <= 0 || value <= currentLicenseType.used)) {
            if (currentLicenseType.used > 0)
                renderNotAllowedToDecreaseMessage(currentLicenseType);

            return;
        }

        if (change === 0 && value < currentLicenseType.used)
            value = currentLicenseType.used;

        const count = value + change;
        currentLicenseType.count = count;

        const index = licenseTypes.findIndex((item) => item.id === currentLicenseType.id);
        licenseTypes[index] = currentLicenseType;

        onChange(licenseTypes);
        onSave(licenseId, count);
    };

    const renderNotAllowedToDecreaseMessage = (licenseType) => {
        const title = `Cannot decrease license`;
        const plural = licenseType.used > 1 ? 's' : '';
        const message = `License type "${licenseType.name}" has ${licenseType.used} user${plural} connected to this license type.`;

        commonActions.showError(message, title);
    };

    const onToggleReceiptInterpretation = (receiptInterpretation) => {

        const licenseTypes = product.licenseTypes;
        const currentLicenseType = licenseTypes.find(item => item.id === "NumberOfKvittotolkning");

        const count = receiptInterpretation ? 1 : 0;
        currentLicenseType.count = count;

        const index = product.licenseTypes.findIndex(item => item.id === "NumberOfKvittotolkning");
        licenseTypes[index] = currentLicenseType;

        onChange(licenseTypes)
        orderActions.toggleReceiptInterpretation(receiptInterpretation)

        orderActions.getBlikkProductDetails(product.productId, customer.vbCustomer.customerNumber, order.orderId, product.tenantGuid);
    }

    if (!product) return null;

    const licenseTypes = product.licenseTypes;
    const kvittotolkning = licenseTypes.find(item => item.id === "NumberOfKvittotolkning")


    return <>
        {kvittotolkning && <div className='mb-32 mt-12'>
            <div className="d-flex align-items-center">
                <Checkbox checked={product.receiptInterpretation} disabled={product.userHasReceiptInterpretation} value={product.receiptInterpretation} onChange={(element) => { onToggleReceiptInterpretation(element.checked) }} style={{ marginLeft: 20, marginTop: 5 }} />
                <label className="">Kvittotolkning - {helpers.currencyFormatter(kvittotolkning.initialPrice)} / {t("blikkadditionalusers.year")}</label>
            </div>
        </div>}

        <div className="pb-0 d-flex flex-column" style={{ gap: "32px" }}>
            {licenseTypes.map((item) => {
                const disabledDecreaseButton = item.count === item.used || item.count === 0;
                const decreaseClassList = `vismaicon vismaicon-dynamic vismaicon-remove-circle ${disabledDecreaseButton ? 'disabled' : ''}`;
                if (item.display) {
                    return (
                        <div key={item.id}>
                            <div className='mb-8'>
                                <label className="control-label">{item.name}</label>
                            </div>
                            <div className="pl-0 d-flex align-items-center" style={{ gap: "12px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span
                                        className={decreaseClassList}
                                        onClick={!disabledDecreaseButton ? () => onDecrease(item.count, item.id) : null}
                                    />
                                    <input style={{ width: "50px" }} className="text-center ml-10 mr-10" type="number"
                                        value={'' + item.count}
                                        onChange={(e) => onLicenseChange(e.target.value, item.id, 0)}
                                    />
                                    <span
                                        className="vismaicon vismaicon-dynamic vismaicon-add-circle"
                                        onClick={() => onIncrease(item.count, item.id)}
                                    />
                                </div>
                                <label className="control-label">{helpers.currencyFormatter(item.initialPrice)} / {t("blikkadditionalusers.userandyear")}</label>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    </>
}