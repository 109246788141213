
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class HittaByraTable extends Component {

    

    render() {
        const { aoCustomers, t, onClick } = this.props;

        return <table className="table table-stripe table-hover">
        <thead>
            <tr>
                <th>{t('hittabyratable.customernumber')}</th>
                <th>{t('hittabyratable.name')}</th>
                <th>{t('hittabyratable.duplicate')}</th>
                <th>{t('hittabyratable.organizationnumber')}</th>
            </tr>
        </thead>
        <tbody>
            { aoCustomers.map((customer, index) => {
                return <tr key={index} onClick={() => onClick(customer)}>
                            <td>{customer.customerNumber}</td>
                            <td>{customer.name}</td>
                            <td>{customer.isDuplicate && <span className="vismaicon vismaicon-filled is-duplicate-checkmark"/>}</td>
                            <td>{customer.organizationNumber}</td>
                        </tr>
            }) }
        </tbody>
    </table>
    }
}

export default withTranslation()(HittaByraTable);
