import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import discountActions from '../../Actions/discountActions';
import commonActions from '../../Actions/commonActions';
import Panel from '../../Components/Panel';
import DiscountInformationFormContainer from './DiscountInformationFormContainer';
import NavTabs from '../../Components/NavTabs';
import DiscountArticlesContainer from './DiscountArticlesContainer';
import * as formValidationRules from '../../Constants/formValidationRules'
import CustomerCategoryContainer from './CustomerCategoryContainer';
import routes from '../../Constants/routes';
import Modal from '../../Components/Modal';
function DiscountContainer({ discountActions, commonActions, discountForm, services, isEdit }) {

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const discountId = searchParams.get("DiscountId")

    useEffect(() => {
        discountActions.getServices();

        if (isEdit) {
            discountActions.getDiscount(discountId)
        } else {
            commonActions.startFormEditing("newDiscount", {
                name: "",
                salesPersonEmail: null,
                description: "",
                allCustomers: false,
                discountPercent: "",
                discountMoney: "",
                hiddenForCustomers: false,
                appliesToClients: false,
                customerCategories: [],
                includedArticles: []
            },
                formValidationRules.CreateDiscount)
        }

        return () => {
            commonActions.cancelFormEditing("newDiscount")
        }
    }, [])

    function OnDeleteModal({ showDeleteModal, onCancelClick, onDelete }) {
        const modalProps = {
            mainButtonTitle: 'Ja',
            onCancelClick: () => onCancelClick(false),
            stateful: true,
            open: showDeleteModal,
            onSaveClick: onDelete,
            showButtons: true,
        };

        return (
            <Modal {...modalProps}>
                <div>
                    <div className='float-left mr-32'>
                        <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error" />
                    </div>
                    <div className='mb-64'>
                        <h3>Är du säker på att du vill ta bort rabattavtalet?</h3>
                        <p>Åtgärden kan inte ångras</p>
                    </div>
                </div>
            </Modal>
        );
    }

    const onChange = (field, value) => {
        commonActions.changeMultiFormField("newDiscount", field, value);
    }

    const onSave = () => {
        discountActions.createDiscount(discountForm, callback)
    }

    const onUpdate = () => {
        discountActions.updateDiscount(discountForm, callback)
    }

    const onDelete = () => {
        discountActions.deleteDiscount(discountId, callback)
    }

    const onChangeList = (field, list) => {
        onChange(field, list)
    }

    const callback = () => {
        navigate(routes.discounts)
        discountActions.getDiscounts();
    }

    const tabs = [
        {
            name: 'artiklar',
            label: 'Artiklar',
            content: <DiscountArticlesContainer services={services} onChangeArticles={onChangeList} includedArticles={discountForm ? discountForm.data.includedArticles : []}/>
        },
        {
            name: 'kundkategorier',
            label: 'Kundkategorier',
            content: <p><CustomerCategoryContainer customerCategories={discountForm ? discountForm.data.customerCategories : []} onChangeCustomerCategories={onChangeList} /></p>
        }
    ];

    return (
        <div>
            <div className='mb-64'>
                <h3 className="panel-title"><span className="badge badge-info mr-12">1</span>Ange information</h3>
                <Panel>
                    <DiscountInformationFormContainer onChange={onChange} discountForm={discountForm} />
                </Panel>
            </div>
            <div className='mb-64'>
                <h3 className="panel-title"><span className="badge badge-info mr-12">2</span>Konfigurera rabattavtal</h3>
                <NavTabs tabs={tabs}/>
            </div>
            <div className='float-right'>
                {isEdit && <button className="btn btn-primary btn-destructive" onClick={() => setShowDeleteModal(true)}>Radera</button>}
                <button type="button" onClick={isEdit ? onUpdate : onSave} className="btn">{isEdit ? "Uppdatera rabattavtal" : "Spara rabattavtal"}</button>
            </div>
            {showDeleteModal && <OnDeleteModal
                showDeleteModal={showDeleteModal}
                onCancelClick={setShowDeleteModal}
                onDelete={onDelete}
            />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        discountForm: state.CommonReducer.forms && state.CommonReducer.forms.newDiscount,
        services: state.DiscountReducer.services
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountContainer));