import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";

import EmailTemplateWithButtons from '../../Components/EmailTemplateWithButtons'
import routes from '../../Constants/routes'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'

function EmailHistoryContainer({ customerActions, commonActions, adminForm, t}) {

    const {emailActionLogId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getFormFromEmailActionLog(emailActionLogId)
    }, [])

    const navigateAfterAction = () => {
        return () => navigate(-1);
    }

    const navigateOnCancel = () => {
        return () => navigate(-1);
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(adminForm.id, field, value)
    }

    const onSaveClick = () => {
        customerActions.sendAdminForm(adminForm, navigateAfterAction());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(adminForm.id, navigate(-1))
    }

    if (!adminForm) {
        return null;
    }

    return (
        <div className="row justify-content-center">
            <div className="col-xs-8 col-xs-offset-2">
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <h3 className="panel-title">{adminForm.data.name ? adminForm.data.name : t('emailhistorycontainer.orderconfirmation')}</h3>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <EmailTemplateWithButtons data={adminForm.data}
                            onChange={onFieldChange.bind(this)}
                            onSaveClick={onSaveClick.bind(this)}
                            onCancelClick={onCancelClick.bind(this)}
                            senderEmails={adminForm.data.defaultSenderAddresses}
                            onSenderChange={onFieldChange.bind(this)}
                            mainButtonTitle={t('emailhistorycontainer.mainbutton')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        adminForm: state.CommonReducer.forms && state.CommonReducer.forms.adminForm
    }
}

function mapDispatchToProps(dispatch) {
   return {
       commonActions : bindActionCreators(commonActions, dispatch),
       customerActions : bindActionCreators(customerActions, dispatch)
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailHistoryContainer)); 