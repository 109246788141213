import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import ReactModal from 'react-modal';
import TextInput from '../TextInput'
import StaticTextInput from '../StaticTextInput'
import Loader from '../Loader'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'

class CheckAddressModal extends Component {

    state = {
        open: false
    };

    handleFieldChange = (field, value) => {
        const { commonActions, updatedInfo } = this.props;
        commonActions.changeMultiFormField(updatedInfo.id, field, value)
    }

    handleClickOpen = () => {
        this.props.customerActions.getCustomerInfoForUpdate(this.props.vbCustomer.organizationNumber)
        this.setState({ open: true });
    };

    handleClickClose = () => {
        const { commonActions, updatedInfo } = this.props;

        commonActions.cancelFormEditing(updatedInfo.id);
        this.setState({ open: false });
    };

    handleClickSave = () => {
        this.props.customerActions.updateCustomerAddress(this.props.updatedInfo, this.props.vbCustomer.customerNumber)
    }

    render() {
        const { t } = this.props;
        const customStyles = {
            content: {
                top: '5%'
            },
            overlay: {
                backgroundColor: 'rgba(40, 40, 40, 0.5)'
            }
        };

        const dialogStyle = {
            width: '800px'
        }
        if ( !this.props.vbCustomer || !this.props.updatedInfo) {
            return null
        }

        const data = { current: this.props.vbCustomer, updated: this.props.updatedInfo.data }

        return <>
                <ReactModal
                    isOpen={true}
                    shouldCloseOnOverlayClick={true}
                    contentLabel="Modal"
                    ariaHideApp={false}
                    className='modal fade in'
                    style={customStyles}
                >
                    <div className="modal-dialog" style={dialogStyle}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClickClose}><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{t('checkaddressmodal.header')}</h4>
                            </div>
                            <div className="modal-body">

                                <form className="form-horizontal">
                                    <div className="row">
                                        <div className="col-xs-7">
                                                <h3>{t('checkaddressmodal.current')}</h3>
                                                <StaticTextInput label={t("checkaddressmodal.companyname")} value={data.current.name} disabled={true} />
                                                <StaticTextInput label={t("checkaddressmodal.streetaddress")} value={data.current.streetAddress} />
                                                <StaticTextInput label={t("checkaddressmodal.coaddress")} value={data.current.coAddress} />
                                                <StaticTextInput label={t("checkaddressmodal.postalcode")} value={data.current.postalCode} />
                                                <StaticTextInput label={t("checkaddressmodal.postalcity")} value={data.current.postalCity} />
                                        </div>
                                        <div className="col-xs-5">
                                                <h3>{t('checkaddressmodal.new')}</h3>
                                                <TextInput label="noLabel" value={data.updated.name} onChange={this.handleFieldChange.bind(this, "name")} />
                                                <TextInput label="noLabel" value={data.updated.streetAddress} onChange={this.handleFieldChange.bind(this, "streetAddress")} />
                                                <TextInput label="noLabel" value={data.updated.coAddress} onChange={this.handleFieldChange.bind(this, "coAddress")} />
                                                <TextInput label="noLabel" value={data.updated.postalCode} onChange={this.handleFieldChange.bind(this, "postalCode")} />
                                                <TextInput label="noLabel" value={data.updated.postalCity} onChange={this.handleFieldChange.bind(this, "postalCity")} />
                                        </div>
                                    </div>
                                </form>

                                <div className="clearfix"></div>

                                <div className="form-group padding-top no-left-margin btn-toolbar action-buttons">
                                    <button type="button" className="btn btn-primary" onClick={this.handleClickSave}>{t("checkaddressmodal.update")}</button>
                                    <button type="button" className="btn btn-default" onClick={this.handleClickClose}>{t("checkaddressmodal.cancel")}</button>
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal>
        </>
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(withTranslation()(CheckAddressModal));
