import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import StaticTextInput from './StaticTextInput'

import commonActions from '../Actions/commonActions'

import BaseSearch from './BaseSearch';  

class ResellerSearch extends Component {

    onChange(object) {
        const customerNumber = object.customerNumber || '';
        const customerName = object.name || '';
        const organizationNumber = object.organizationNumber || '';
        const supplierNumber = object.supplierNumber || '';

        this.props.onChange(supplierNumber, customerNumber, customerName, organizationNumber);
    }

    renderRemoveAction() {
        return <span className="vismaicon vismaicon-remove-circle cursorpointer" onClick={this.props.onRemoveAction} />
    }

    render() {

        if (this.props.readonly) {
            return (
                <StaticTextInput label={this.props.label} 
                    value={`${this.props.defaultSelected.name} / ${this.props.defaultSelected.customerNumber}`} 
                    renderAfter={this.props.onRemoveAction && this.renderRemoveAction()} 
                />
            );
        }
        
        let attributes = {
            labelKey: option => `${option.name} / ${option.customerNumber} / ${option.organizationNumber}`,                 
            options: this.props.searchResults || [],
            onSearchAttr: (query) => this.props.commonActions.searchReseller(query),
            placeholder: "Enter reseller name, number or organization number..",
            onChangeAttr: this.onChange.bind(this),
            className: 'col-sm-12 col-md-8'
        }
        
        if(this.props.newCustomerLink) {
            attributes.className = 'col-xs-5';
        }
        
        if (this.props.defaultSelected && this.props.defaultSelected.name) {
            attributes.defaultSelected = [this.props.defaultSelected];
        }

        return (
            <div className='form-group'>
                { this.props.label && 
                    <label className="col-sm-12 col-md-4 control-label">{this.props.label}</label>
                }

                <BaseSearch attributes={attributes} />
                
                { this.props.onRemoveAction &&
                    this.renderRemoveAction()
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        searchResults: state.CommonReducer.resellerSearchResults && state.CommonReducer.resellerSearchResults.results,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerSearch);