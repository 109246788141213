import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import CustomerSearch from '../Search/CustomerSearch';

class SubCustomersEditForm extends Component {

    onInputChange = (index, customerNumber, name) => {
        const { onChange, title, customers, commonActions, t, customer } = this.props;
        if(customer != undefined && customerNumber === customers.customerNumber){
            commonActions.showWarning(t('subcustomercontainer.owncompanywarning'));
            return;
        }
        onChange(title, customers, index, {customerNumber: customerNumber, name: name, organizationNumber: ''})
    }

    onAddAction = () => {
        const { onChange, title, customers } = this.props;
        onChange(title, customers, customers.length, '')
    }

    onRemoveAction = (index) => {
        const { onChange, title, customers } = this.props;
        onChange(title, customers, index, '-1')
    }

    render() {
        const { customers, t } = this.props;

        return  <div className="customerConnections subCustomers">
                    <h4><span className="vismaicon vismaicon-sm vismaicon-users" /> {t('subcustomercontainer.header')}</h4>
                    { customers?.map((subCustomer, index) => {
                        return <div key={index}>
                                    <CustomerSearch 
                                        id={"subCustomerSearch" + index}
                                        index={index}
                                        readonly={subCustomer && typeof subCustomer === 'object'}
                                        onChange={this.onInputChange.bind(this, index)} 
                                        onRemoveAction={this.onRemoveAction.bind(this, index)}
                                        defaultSelected={{name: subCustomer.name, customerNumber: subCustomer.customerNumber }} 
                                    />
                                </div>
                    })}
                    <div><span className="vismaicon vismaicon-add-circle" onClick={ this.onAddAction } /></div>
                </div>
        }
}

export default withTranslation()(SubCustomersEditForm);