import { Navigate, Outlet } from "react-router-dom";
import React from 'react'
import { connect } from 'react-redux';
import {hasAccess} from "../../Utils/permissionUtils";
import {Access} from "../../Constants/userRoles";

function RequireReadOnlyAuth({ user, roles }) {

    if (roles && roles.some(role => hasAccess(user, role, Access.ReadOnly))) {
        return <Outlet />;
    }

    return <></>
}

function RequireReadWriteAuth({ user, roles }) {

    if (roles && roles.some(role => hasAccess(user, role, Access.ReadWrite))) {
        return <Outlet />;
    }

    return <></>
}

function RequireAdminAuth({ user, roles }) {

    if (roles && roles.some(role => hasAccess(user, role, Access.Admin))) {
        return <Outlet />;
    }

    return <></>
}

function RequireAuth({ user }) {
    return (
        <>
            {!user && <Navigate to="/login" />}
            <Outlet />
        </>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        isAuthenticating: state.CommonReducer.authenticating
    }
}

export default {
    RequireAuth: connect(mapStateToProps)(RequireAuth),
    RequireReadOnlyAuth: connect(mapStateToProps)(RequireReadOnlyAuth),
    RequireReadWriteAuth: connect(mapStateToProps)(RequireReadWriteAuth),
    RequireAdminAuth: connect(mapStateToProps)(RequireAdminAuth)
}