import Modal from '../../Components/Modal'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import { bindActionCreators } from 'redux';
import Canvas from './PongCanvas';

class Pong extends Component{

    handleClickClose = () => {
        const {commonActions, pongToggle} = this.props;
        commonActions.togglePong(!pongToggle)
        return null;
    }

    render(){
        const {pongToggle} = this.props;
        
        const canvasProperties={
            height: "600",
            width: "800"
        }

        return <Modal open={pongToggle} stateful={true} showButtons={false} onCancelClick={this.handleClickClose} customClassName={"pong-modal"}>
            <div>
                <Canvas properties={canvasProperties}/>
            </div>
        </Modal>
    }
}

function mapStateToProps(state) {
    return {
        pongToggle: state.CommonReducer.pongToggle
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pong);
