import React from 'react';
import { Route, Routes } from 'react-router-dom'

import LeadsListContainer from '../../Containers/Leads/LeadsListContainer'
import LeadEditContainer from '../../Containers/Leads/LeadEditContainer'

import MySettingsContainer from '../../Containers/Leads/MySettingsContainer'

const LeadsLayout = () => (
    <div className="row">
        <div className="col-xs-12">
            <Routes>
                <Route path={'/my-settings/'} element={<MySettingsContainer />} />
                <Route path={'/edit/:leadId'} element={<LeadEditContainer />} />
                <Route path={'/:leadId/'} element={<LeadsListContainer />} />
                <Route index element={<LeadsListContainer />} />
            </Routes>
        </div>
    </div>
);

export default LeadsLayout;