import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import Modal from '../Modal'
import commonActions from '../../Actions/commonActions'
import DropDown from '../../Components/DropDown'
import EditMovieFormContainer from './EditMovieFormContainer';
import TextInput from '../TextInput';
import {FormEditor} from '../Editor';
import * as formValidationRules from './../../Constants/formValidationRules'
import CustomerSearchNew from '../Search/CustomerSearchNew';
import FormSearch from '../Search/FormSearch';
import {UserRoles as userRoles} from "../../Constants/userRoles";
import {hasAdminAccess} from "../../Utils/permissionUtils";

function SendMovieModal({ commonActions, onClose, t, movies, sendMovieform, user }) {

    const [movie, setMovie] = useState();
    const [selectedCategory, setCategory] = useState();
    const [isEditMovie, setEditMovie] = useState(false);

    useEffect(() => {
        commonActions.getMovieMails();
    }, [])

    useEffect(() => {
        if (movie) {
            setMovie(movies.find(m => m.id === movie.id))
        }
    }, [movies])

    useEffect(() => {
        if (movie != undefined) {
            const linkText = movie.linkText || movie.link;
            const form = {
                text: '<div> <p>' + movie.description + '</p> <p><a href="' + movie.link + '">' + linkText + '</a></p> </div>',
                to: "",
                subject: movie.name,
                title: movie.name
            }
            commonActions.startFormEditing('sendMovie', form, formValidationRules.SendMovieMail)
        }
    }, [movie])

    const handleFieldChange = (field, value) => {
        if (!isEditMovie)
            commonActions.changeMultiFormField("sendMovie", field, value);
    }

    const handleSearchChange = (customerNumber, customerName, organizationNumber, group, email) => {
        if (email) {
            handleFieldChange('to', email);
        }

        handleFieldChange('customerNumber', customerNumber)
    }

    const handleClickSave = () => {
        commonActions.sendMovieMail(sendMovieform, () => handleClickClose())
    }

    const handleClickClose = () => {
        commonActions.cancelFormEditing("sendMovie", onClose)
    }

    const modal = {
        title: t('sendmoviemodal.sendmovie'),
        mainButtonTitle: t('sendmoviemodal.sendmovie'),
        onCancelClick: handleClickClose,
        stateful: true,
        open: true,
        onChange: handleFieldChange,
        onSaveClick: handleClickSave,
        disableMainButton: !movie
    }

    const onDropDownSelect = (value) => {
        setMovie(movies.find(movie => movie.id === value))
    }

    const getFilteredMovies = (movies) => {
        if (selectedCategory) {
            return movies.filter(movie => movie.category === selectedCategory)
        }
        return movies
    }

    const getCategories = (movies) => {
        let categories = [];
        [...new Set(movies.map((movie) => movie.category))].forEach((category) => {
            if(category)
                categories.push({
                    id: category,
                    name: category
                })
        });
        return categories;
    }

    const onCategoryChange = (category) => {
        if(movie && movie.category != category && category != "")
            setMovie(null)

        setCategory(category)
    }

    return (
        <Modal {...modal}>
            {!isEditMovie ? <form className="form-horizontal">
                <fieldset>
                    {movies && <DropDown label={t('sendmoviemodal.category')} value={selectedCategory} blankOption={true} list={getCategories(movies)} onChange={(value) => onCategoryChange(value)} />}
                    <DropDown label={t('sendmoviemodal.movie')} value={movie ? movie.id : 0} onChange={onDropDownSelect.bind(this)} blankOption={true} list={getFilteredMovies(movies)} />
                    {movie && sendMovieform &&
                        <>
                            <FormSearch label={t('sendmoviemodal.customer')}>{(className) => <CustomerSearchNew onChange={handleSearchChange.bind(this)} className={className} />}</FormSearch>
                            <TextInput label={t("sendmoviemodal.to")} onChange={handleFieldChange.bind(this, 'to')} value={sendMovieform.data.to} />
                            <TextInput label={t("sendmoviemodal.subject")} onChange={handleFieldChange.bind(this, 'subject')} value={sendMovieform.data.subject} />
                            <FormEditor onChange={handleFieldChange.bind(this, "text")} value={sendMovieform.data.text} />
                        </>
                    }
                </fieldset>
                {hasAdminAccess(user, userRoles.Movie) && <button className="btn btn-default" onClick={() => setEditMovie(!isEditMovie)}>{movie ? t("sendmoviemodal.editmovie") : t("sendmoviemodal.createmovie")}</button>}
            </form> :
                <EditMovieFormContainer onClose={onClose} movie={movie} toggleEditMovie={() => setEditMovie(false)} />
            }
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        selectedCustomerAndType: state.CommonReducer.selectedCustomerAndType,
        sendMovieform: state.CommonReducer.forms && state.CommonReducer.forms.sendMovie,
        dropdownlists: state.CommonReducer.dropdownlists,
        demoProduct: state.CommonReducer.selectedDemoProductId,
        movies: state.CommonReducer.movieMails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendMovieModal));