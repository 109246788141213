import {Access} from "../Constants/userRoles";

export function hasAccess(user, role, access = Access.Admin) {
    return user && user.userRoles.some(r => r.roleId === role && r.access >= access);
}

export function hasAdminAccess(user, role) {
    return hasAccess(user, role, Access.Admin);
}

export function hasReadWriteAccess(user, role) {
    return hasAccess(user, role, Access.ReadWrite);
}