import React, { useEffect } from "react";
import {bindActionCreators} from "redux";
import commonActions from "../Actions/commonActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import helpers from "../Utils/helpers";
import routes from "../Constants/routes";
import { useNavigate, useParams } from "react-router-dom";

function VersionArchiveDetails({t, releaseVersion, commonActions}) {
    const {versionId} = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.getVersionById(versionId);
    }, [versionId])

    function onGoBack() {
        navigate(routes.versionArchive);
    }

    if (!releaseVersion)
        return null;

    return (
        <div className="panel">
            <div className="panel-heading">

                <h1>{t('versionarchive.detailsheading')}</h1>
            </div>
            <div className="panel-body">
                <h3>{releaseVersion.title}</h3>
                <p>{helpers.formatDate(releaseVersion.publishDate)}</p>
                <p dangerouslySetInnerHTML={{__html: releaseVersion.content}}></p>
                <div className="margin-top">
                    <button className="btn btn-default" onClick={onGoBack.bind(this)}><i
                        className="vismaicon vismaicon-sm vismaicon-undo"></i>{t('versionarchive.back')}
                    </button>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        releaseVersion: state.CommonReducer.releaseVersion,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VersionArchiveDetails));