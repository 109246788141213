import {useTranslation, withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import customerActions from "../../Actions/customerActions";
import commonActions from "../../Actions/commonActions";
import {connect} from "react-redux";
import {InternalInfo} from "../../Components/Customer/CustomerComponents/InternalInfo";
import {DefaultCard} from "../../Components/Card/Card";
import {useEffect} from "react";
import targetGroupActions from "../../Actions/Voss/targetGroupActions";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";

function InternalInfoContainer({ vbCustomer, customer, dropdownlists, user, toggleModal, targetGroupActions, targetGroups }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (isFeatureEnabled(user, FeatureFlags.VOSS)) {
            targetGroupActions.getTargetGroups();
        }
    }, []);

    return (<>
        <DefaultCard title={t("internalinfocontainer.header")} isLoading={!vbCustomer} >
            <InternalInfo vbCustomer={vbCustomer} customer={customer} dropdownlists={dropdownlists} user={user} toggleModal={toggleModal} targetGroups={targetGroups}/>
        </DefaultCard>
    </>)
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        targetGroups: state.TargetGroupsReducer.targetGroups,
        dropdownlists: state.CommonReducer.dropdownlists,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        targetGroupActions: bindActionCreators(targetGroupActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalInfoContainer));