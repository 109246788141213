import React, { Component } from 'react';
import Modal from '../Modal';
import routes from '../../Constants/routes'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

class VbProductDetailsModal extends Component {

    render() {

        const { product, onClose, t } = this.props;
        
        if (!product) {
            return null;
        }

        const modalProps = {
            title: product.product,
            stateful: true,
            open: true,
            onCancelClick: () => onClose(),
            readonly: true,
            dialogStyle: {width: '900px'}
        }
       
        return (
            <Modal {...modalProps}>
                <fieldset className="col-xs-12">                
                {product.isProductValid &&<table className="table table-stripe table-hover"> 
                    <thead>
                        <tr>
                        <   th className="text-left">{t('vbproductdetailsmodal.productnumber')}</th>
                            <th className="text-left">{t('vbproductdetailsmodal.product')}</th>
                            <th className="text-left">{t('vbproductdetailsmodal.fromdate')}</th>
                            <th className="text-left">{t('vbproductdetailsmodal.enddate')}</th>
                            <th className="text-left">{t('vbproductdetailsmodal.users')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.serviceAgreements && product.serviceAgreements.map((item, index) => {
                            const startDate = item.startDate === '0001-01-01' ? 'N/A' : item.startDate;
                            const endDate = item.endDate === '0001-01-01' ? 'N/A' : item.endDate;
                            return <tr key={index}>
                                <td className="text-left">{item.productNumber}</td>
                                <td className="text-left">{item.description}</td>
                                <td className="text-left">{startDate}</td>
                                <td className="text-left">{endDate}</td>
                                <td className="text-left">{item.users}</td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>}
                {!product.isProductValid && 
                <p > 
                    {product.terminationReason} 
                    <Link target='_top' to={routes.customerMain(product.transferredToCustomerNumber)}>{product.transferredToCustomerNumber}</Link>
                </p>}
            </fieldset>
            </Modal>
        );
    }
}


export default withTranslation()(VbProductDetailsModal);