import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from '../../Constants/routes'
import CustomerForm from '../../Components/Customer/CustomerForm'
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function CustomerCreateContainer({ customerForm, t }) {

    const navigate = useNavigate()

    const navigateAfterAction = () => {
        if (customerForm.data.leadId) {
            return () => navigate(routes.leadEdit(customerForm.data.leadId));
        }
        return () => navigate(routes.search(''));
    }
    if (!customerForm) {
        return null;
    }

    return (
        <div className="col-xs-10 col-xs-offset-1">
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">{t('customercreatecontainer.createnewcustomer')} {customerForm.data.name}</h3>
                </div>
                <div className="panel-body">
                    <CustomerForm callback={navigateAfterAction()} isCreation={true} />
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData
    }
}

export default connect(mapStateToProps)(withTranslation()(CustomerCreateContainer));