import React, { Component } from 'react';
import StaticTextInput from '../Components/StaticTextInput'
import { withTranslation } from 'react-i18next';

class CustomerInfo extends Component {
    renderDefaultContent()
    {
        const { t } = this.props;
        return (
            <>
                <StaticTextInput label={t('customerinfo.customernumber')} value={this.props.vbCustomer.customerNumber} col1Width="6" col2Width="6" />
                <StaticTextInput label={t('customerinfo.contactperson')} value={this.props.vbCustomer.contactPerson} col1Width="6" col2Width="6" />
                <StaticTextInput label={t('customerinfo.email')} value={this.props.vbCustomer.emailAddress} col1Width="6" col2Width="6" />
                <StaticTextInput label={t('customerinfo.organizationnumber')} value={this.props.vbCustomer.organizationNumber} col1Width="6" col2Width="6" />
            </>
        )
    }

    renderCreateVonAccountContent()
    {   
        const { t } = this.props;

        return (
            <>
                <StaticTextInput label={t('customerinfo.contactperson')} value={this.props.vbCustomer.contactPerson} col1Width="4" col2Width="8" />
                <StaticTextInput label={t('customerinfo.email')} value={this.props.vbCustomer.emailAddress} col1Width="4" col2Width="8" />
            </>
        )
    }

    render() {
        const vbCustomer = this.props.vbCustomer;
        let content;
        
        if (this.props.context === "CreateVonAccount") {
            content = this.renderCreateVonAccountContent();
        } else {
            content = this.renderDefaultContent();
        }
        
        return (
            <>
                <h3>{vbCustomer.name}</h3>
                {content}
            </>
        );
    }
}

export default withTranslation()(CustomerInfo);