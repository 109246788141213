import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Modal from '../../Components/Modal'
import EmailTemplate from '../../Components/EmailTemplate'

import commonActions from '../../Actions/commonActions'
import leadActions from '../../Actions/leadActions'

class SendEmailModal extends Component {

    componentDidMount() {

        const { leadActions, lead: { email: customerEmail, customerNumber }, emailActionLogId } = this.props;

        if (emailActionLogId)
            leadActions.getFormFromEmailActionLog(emailActionLogId)
        else
            leadActions.startEmailSendingModal(customerEmail, customerNumber);
    }

    onTemplateChange = (id) => {
        const { leadActions, lead: { email: customerEmail, customerNumber } } = this.props;

        leadActions.getEmailTemplate(id, customerEmail, customerNumber)
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("leadEmail", field, value);
    }

    handleClickSave = () => {
        const { leadActions, lead, emailForm, onSave, leadType } = this.props;

        leadActions.sendEmail(lead.id, emailForm, onSave, leadType)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("leadEmail", onClose)
    }

    render() {
        const { dropdowns, emailForm, emailActionLogId } = this.props;

        if (!emailForm) {
            return null;
        }

        if (!emailActionLogId && !dropdowns.LeadsEmailTemplates)
            return null;


        const modalProps = {
            title: emailActionLogId ? 'Resend Email' : 'Send Email',
            mainButtonTitle: emailActionLogId ? 'Resend' : 'Send',
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <EmailTemplate data={emailForm.data} templates={dropdowns.LeadsEmailTemplates} onChange={this.handleFieldChange}
                    onSaveClick={this.handleClickSave} onCancelClick={this.handleClickClose} onTemplateChange={this.onTemplateChange}
                    senderEmails={emailForm.data.defaultSenderAddresses} onSenderChange={this.handleFieldChange} />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdowns: state.CommonReducer.dropdownlists,
        emailForm: state.CommonReducer.forms && state.CommonReducer.forms.leadEmail
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal);
