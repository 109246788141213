import React, { Component } from 'react';
import TextInput from '../../Components/TextInput'
import StaticTextInput from '../StaticTextInput';
import { withTranslation } from 'react-i18next';
import SwitchToggle from "../SwitchToggle";
import DropDown from '../DropDown';

class BokaMeraForm extends Component {

    componentDidMount() {
        this.props.booking.customFields.forEach(field => {
            this.props.onFieldChange(field.name, field.value)
        })
    }
    
    render() {

        const { booking, readonly, onFieldChange, onEmployeeSelected, availableResources, employees, t } = this.props;

        return (
        <form className={`form-horizontal`}>
                    <fieldset>
                    <StaticTextInput label={t('bokameraform.availableresources')} value={availableResources} readonly={readonly}/>
                    <TextInput label={t('bokameraform.service')} value={booking.service.name} readonly={readonly}/>
                    <TextInput label={t('bokameraform.lengthinminutes')} value={booking.service.lengthInMinutes} readonly={readonly}/>
                    {booking.service.prices.length > 0 && <TextInput label={t('bokameraform.cost')} value={booking.service.prices[0].priceText} readonly={readonly}/>}
                    {employees && <DropDown label={t('bokameraform.chooseparticipant')} blankOption={true} list={employees} onChange={(value) => onEmployeeSelected(value, employees)}></DropDown>}
                    <TextInput label={t('bokameraform.participant')} onChange={onFieldChange.bind(this, 'contactPerson')} value={booking.contactPerson} />
                    <TextInput label={t('bokameraform.email')} onChange={onFieldChange.bind(this, 'email')} value={booking.email} />
                    <TextInput label={t('bokameraform.phone')} onChange={onFieldChange.bind(this, 'phone')} value={booking.phone} />
                    <TextInput label={t('bokameraform.fromdate')} value={booking.fromDate} readonly={readonly} />
                    <TextInput label={t('bokameraform.todate')} value={booking.toDate} readonly={readonly} />
                    {booking.customFields.map(field => (
                        <TextInput key={field.id} onChange={onFieldChange.bind(this, field.name)} label={t('bokameraform.' + field.name)} value={booking[field.name]} multiline={field.multipleLineText}/>
                    ))}
                    <p>{t('bokameraform.bokameraprofile')}</p>
                    <div className="form-group">
                        <label className="col-10 control-label">{t('bokameraform.ihaveinformedthecustomer')}</label>
                        <div className="col-2">
                            <SwitchToggle checked={booking.informedCustomer} onChange={onFieldChange.bind(this, "informedCustomer")} value={booking.informedCustomer} />
                        </div>
                    </div>
                    </fieldset>

                </form>
        )
    }
} 

export default withTranslation()(BokaMeraForm);