import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import CustomerSearch from '../../Components/Search/CustomerSearch'
import settingsActions from '../../Actions/settingsActions'
import routes from '../../Constants/routes'
import { useNavigate } from 'react-router-dom';

function CustomerInfoTypesContainer({ t }) {

    const navigate = useNavigate();

    const customerSearchOnChange = (customerNumber, customerName) => {
        if (customerNumber !== "")
            navigate(routes.settingsCustomerInfoTypeEdit(customerNumber));
    }

    return (
        <div>
            <CustomerSearch id={"customerInfoTypesSearch"} label={t('customerinfotypescontainer.searchcustomer')}
                onChange={(customerNumber, customerName) => customerSearchOnChange(customerNumber, customerName)} />
        </div>
    );
}



function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerInfoTypesContainer));