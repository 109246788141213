import DiscountCustomerForm from "../../../Components/Voss/DiscountAgreements/DiscountCustomerForm";
import DiscountCustomerGroupsTable from "../../../Components/Voss/DiscountAgreements/DiscountCustomerGroupsTable";
import DiscountCustomersTable from "../../../Components/Voss/DiscountAgreements/DiscountCustomersTable";

function DiscountCustomerFormContainer({ onChange, ...props }) {
    const { readOnly } = props;

    return <div className={`d-flex ${readOnly ? 'disabled' : ''}`}>
        <div className="col-3">
            <DiscountCustomerForm onChange={onChange} {...props} />
        </div>
        <div className="col">
            <div className="col-6 pl-0">
                <DiscountCustomerGroupsTable onChange={onChange} {...props} />
                <DiscountCustomersTable onChange={onChange} {...props} />
            </div>
        </div>
    </div>
}

export default DiscountCustomerFormContainer;