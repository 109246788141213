import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Routes, Route } from 'react-router-dom'

import AttendedCoursesContainer from './AttendedEducationsContainer'
import AttededOnboardingsContainer from './AttendedOnboardingsContainer'

import SentMoviesContainer from './SentMoviesContainer'

import './../Settings/Settings.css'
import AttendedOnboardingDetails from '../../Components/Customer/AttendedOnboardingDetails';
import Panel from "../../Components/Panel";
import routes from "../../Constants/routes";
import PillNavigation from "../../Components/Navigation/PillNavigation";

function AttendedEducationsNavigationContainer ({customer, t}) {
    const links =   [
        { to: routes.attendedCourses(customer.pathId), text: t('attendededucationsnavigation.courses') },
        { to: routes.attendedOnboardings(customer.pathId), text: t('attendededucationsnavigation.onboarding') },
        { to: routes.sentMovies(customer.pathId), text: t('attendededucationsnavigation.sentmovies') }
    ];

    return (
        <Panel title={t('attendededucationsnavigationcontainer.attendededucations')}>
            <PillNavigation path={window.location.pathname} links={links} />
            <Routes>
                <Route path={`/courses/`} element={<AttendedCoursesContainer/>} />
                <Route path={'/onboardings/'} element={<AttededOnboardingsContainer/>} />
                <Route path={'/editonboarding/:onboardingId'} element={<AttendedOnboardingDetails/>} />
                <Route path={'/sent-movies'} element={<SentMoviesContainer/>}/>
            </Routes>
        </Panel>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer
    }
}

export default connect(mapStateToProps, null)(withTranslation()(AttendedEducationsNavigationContainer));