import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class StaticTextInput extends Component {
   
   renderEmail(email) {
       return (
           <a href={"mailto:" + email}>{email}</a>
       )
   }

    renderLink(to, value, newTab) {
        return (
            <Link target={newTab ? "_blank" : ""} to={to}>{value}</Link>
        );
    }

   renderIcon(iconName) {
       switch(iconName) {
        
        case "iconAO":
            return (
                <span className="vismaicon vismaicon-office-building align-customerIcon" aria-hidden="true" title="Accounting Office"></span>
            )

        case "iconReseller":
            return (
                <span className="vismaicon vismaicon-locked align-customerIcon" aria-hidden="true" title="Reseller - Contact PartnerSales"></span>
            )

        case "iconIntegrationPartner":
            return (
                <span className="vismaicon vismaicon vismaicon-link align-customerIcon" aria-hidden="true" title="Integration Partner - Contact TechSales"></span>
            ) 
        
        case "vismaicon vismaicon-priority-high":
            return (
                <span className="vismaicon vismaicon-priority-high" style={{height: "15px"}}></span>
            )
        case "vismaicon vismaicon-priority-medium":
            return (
                <span className="vismaicon vismaicon-priority-medium" style={{height: "15px"}}></span>
            )
        case "vismaicon vismaicon-priority-low":
            return (
                <span className="vismaicon vismaicon-priority-low" style={{height: "15px"}}></span>
            )

        default:
            return null;
       }    
   } 

    render() {

        const { col1Width, col2Width, customClassName, label, type, to, value, icon, renderAfter, scrollable, height, marginBottom, noWordBreak, isBoldLabel, newTab} = this.props;

        const col1WidthClass = col1Width ? `col-xs-${col1Width}`: 'col-xs-4';
        const col2WidthClass = col2Width ? `col-xs-${col2Width}` : 'col-xs-8';
        const wrapperClass = scrollable ? "form-group pre-scrollable" : "form-group" 
        const wrapperStyle = {height: height, marginBottom: marginBottom}

        const wordBreak = noWordBreak ? "" : "word-break";
        const bold = !isBoldLabel ? "" : "bold"


        let displayValue = ''

        switch(type) {
            case 'email':
                displayValue = this.renderEmail(value)
                break
            case 'link':
                displayValue = this.renderLink(to, value, newTab) 
                break
            case 'raw':
                displayValue = <span dangerouslySetInnerHTML={{__html: value}}></span> 
                break
            default:
                displayValue = value
                break;
         }
  
        return (
            <div className={wrapperClass} style={wrapperStyle}>
                {label && <label className={'control-label ' + col1WidthClass + " " + bold}>{label}</label>}
                <div className={col2WidthClass + (customClassName ? customClassName : "")}>
                    <p className={'form-control-static ' + wordBreak}>
                        {displayValue}
                        {this.renderIcon(icon)}
                    </p>
                </div>
                {renderAfter}
            </div>
        )
    }
}

export default StaticTextInput
