import React, { Component } from 'react';

class VismaFinanceProduct extends Component {
    
    render() {
        
        const {product, status, color, isAllowedToBuyProduct} = this.props;

        const buttonStyle = {
            backgroundColor: color,
            borderColor: color,
            color: '#fff'
        }

        if(!isAllowedToBuyProduct)
            return (<p>N/A</p>);
        
        return <>
                <button className="btn" style={buttonStyle} disabled>{product}</button>
                <h3 className="align-middle">{status}</h3>
        </>
    }
}

export default (VismaFinanceProduct);