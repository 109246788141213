import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from '../../Utils/helpers';
import Checkbox from '../../Components/Checkbox';
import TextInput from '../../Components/TextInput';
import Editor from '../../Components/Editor';

function FileBoxRequestRequirementContainer({ t, selectedEvent }) {

    const inputStyle = {
        padding: "0",
        verticalalign: "center"
    }


    return (
        <table className="table table-stripe table-actionLogs">
            <thead>
                <tr>
                    <th className="text-left">{t('fileboxrequestrequirementcontainer.name')}</th>
                    <th className="text-left">{t('fileboxrequestrequirementcontainer.extension')}</th>
                    <th className="text-left">{t('fileboxrequestrequirementcontainer.required')}</th>
                    <th className="text-left">{t('fileboxrequestrequirementcontainer.instruction')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {selectedEvent.fileRequirements.map((requirement, index) => {
                    return <tr key={index}>
                        <td>{requirement.fileName}</td>
                        <td>{requirement.fileExtension}</td>
                        <td><Checkbox checked={requirement.required} disabled={true} /></td>
                        <td dangerouslySetInnerHTML={{ __html: requirement.instruction }}></td>
                        <td><a><span className="vismaicon vismaicon-remove-circle"></span></a></td>
                    </tr>
                })}
                <tr className="new-request-requirement-table-row">
                    <td><TextInput label={"noLabel"} noLabelStyle={inputStyle} /></td>
                    <td><TextInput label={"noLabel"} noLabelStyle={inputStyle} /></td>
                    <td><Checkbox /></td>
                    <td style={{ width: "50%", wordBreak: "break-all"}}><div><Editor /></div></td>
                    <td><a><span className="vismaicon vismaicon-add-circle"></span></a></td>
                </tr>
            </tbody>
        </table>
    );
}
function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxRequestRequirementContainer));