import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'

class AdminLinks extends Component {

    constructor(props) {
        super(props);
        this.initState = {
        }

        this.state = this.initState;
    }

    getLinks() {
        const { compensationHistoryCount, customer, t } = this.props;
        let items = [
            {
                title: t('leftnavigation.admin'), id: "admin", onClick: () => this.props.setHeader("admin"), subItems: [
                    { title: t('leftnavigation.actionhistory'), path: routes.customerActions(customer.pathId) },
                    { title: t('leftnavigation.senddesktopkey'), path: routes.customerSendDesktopVersion(customer.pathId) },
                    { title: t('leftnavigation.compensation'), path: routes.customerCompensation(customer.pathId), badgeNumber: compensationHistoryCount },
                    { title: t('leftnavigation.consulting'), path: routes.customerConsulting(customer.pathId)},
                    { title: t('leftnavigation.invoices'), path: routes.customerInvoices(customer.pathId) }
                ]
            }
        ];
        return items;
    }


    render() {

        return <>
                {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                    return <LeftNavigationBarItem key={index} link={item} location={this.props.location} openHeader={this.props.openHeader}/>
                })}
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        compensationHistoryCount: state.CustomerReducer.compensationHistoryCount,
        openHeader: state.CommonReducer.openHeader
    }
}

export default connect(mapStateToProps)(withTranslation()(AdminLinks));