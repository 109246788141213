import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import ListSearch from '../../Components/ListSearch';
import HittaByraTable from '../../Components/Settings/HittaByraTable'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import { useParams, useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'

function HittaByraContainer({ settingsActions, commonActions, form, aoCustomers, t, aoCustomerSearchResults }) {

    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getAoCustomers();
    }, [])

    const onClick = (aoCustomer) => {
        navigate(routes.hittaByraEdit(aoCustomer.id));
    }

    const moveToAoCustomer = (aoCustomer) => {
        navigate(routes.hittaByraEdit(aoCustomer.id));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const onSearchClick = (phrase) => {
        settingsActions.searchAoCustomers(phrase)
    }

    const onClearClick = () => {
        settingsActions.clearAoCustomerSearch();
    }

    if (!aoCustomers) {
        return null;
    }

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ListSearch onSearchClick={onSearchClick} onClearClick={onClearClick} results={aoCustomerSearchResults} placeholder={t('hittabyracontainer.searchplaceholder')}>
                        <HittaByraTable aoCustomers={aoCustomerSearchResults} onClick={moveToAoCustomer} />
                    </ListSearch>
                </div>
            </div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th>{t('hittabyracontainer.customernumber')}</th>
                        <th>{t('hittabyracontainer.name')}</th>
                        <th>{t('hittabyracontainer.organizationnumber')}</th>
                    </tr>
                </thead>
                <tbody>
                    {aoCustomers.map((aoCustomer, index) => {
                        return <tr key={index} onClick={() => onClick(aoCustomer)}>
                            <td>{aoCustomer.customerNumber}</td>
                            <td>{aoCustomer.name}</td>
                            <td>{aoCustomer.organizationNumber}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        aoCustomers: state.SettingsReducer.aoCustomers,
        aoCustomerSearchResults: state.SettingsReducer.aoCustomerSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HittaByraContainer));