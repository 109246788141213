import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

function VossDiscountOptions({ product, onChange }) {
    const { t } = useTranslation();
    const onAddDiscount = (discountId) => {

        if (discountId != '')
            onChange("discountAgreementIds", [
                ...product.priceCalculation.discountAgreementIds,
                discountId
            ]);
    };

    const onRemoveDiscount = (discountId) => {
        onChange("discountAgreementIds", product.priceCalculation.discountAgreementIds.filter(x => x != discountId));
    };

    var selectableDiscounts = product.offer.discounts.filter(discount => !product.priceCalculation.discountAgreementIds.includes(discount.id));
    var selectedDiscounts = product.offer.discounts.filter(discount => product.priceCalculation.discountAgreementIds.includes(discount.id));

    return <fieldset className="d-grid col gap-24 mb-0 px-0">
        <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t('vossdiscountoptions.availablediscounts')}</h3>
            <select className="form-control" disabled={selectableDiscounts.length <= 0} onChange={(e) => onAddDiscount(e.target.value)} value={0}>
                <option value="">Välj rabattavtal...</option>
                {selectableDiscounts.map((discount, index) => (
                    <option key={index} value={discount.id}>{discount.name}</option>
                ))}
            </select>
        </div>
        {selectedDiscounts.length > 0 && <div>
            <table className="table table-stripe skynet-table-no-divider mb-0">
                <tbody>
                    {selectedDiscounts.map((discount, index) => (
                        <tr key={index}>
                            <td>{discount.name}</td>
                            <td>
                                <div className="float-right">
                                    <SkynetIcon icon={"vismaicon vismaicon-cancel-circle"} onClick={() => onRemoveDiscount(discount.id)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>}
    </fieldset>
}

export function VossDiscountOptionsCard({...props}) {
    const { t } = useTranslation();


    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('vossdiscountoptions.title')}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VossDiscountOptions {...props} />
        </Card.Content>
    </Card>
}