
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import React, {useEffect} from "react";
import {MainInfo} from "../../Components/Customer/CustomerComponents/MainInfo";
import commonActions from "../../Actions/commonActions";
import customerActions from "../../Actions/customerActions";
import CustomerInfoFlagsContainer from "./CustomerInfoFlagsContainer";
import {DefaultCard} from "../../Components/Card/Card";


export function MainInfoContainer({ customer, commonActions, toggleModal }) {
    const { t } = useTranslation();
    function getHeader() {
        return (<>{/* TODO: Add in Skynet when verified customer is implemented
            <Tooltip> Sätt opacity om inte verifierad. Öppna en modal där man sätter verifierad.
                <TooltipTrigger>
                    {<img className='pl-8 pr-16' alt={"Verified Customer"} src={verifiedcustomer} />}
                </TooltipTrigger>
                <TooltipContent>
                    <div>{t('maininfocontainer.verifiedcustomer')}</div>
                </TooltipContent>
            </Tooltip>*/}
                <div className="ml-8">
                    <CustomerInfoFlagsContainer onClick={toggleModal} />
                </div>
            </>
        )
    }

    return <>
        <DefaultCard
            title={t("maininfocontainer.header")}
            headerContent={getHeader()}
            isLoading={!customer}>
            <MainInfo customer={customer.vossCustomer ?? customer.vbCustomer}/>
        </DefaultCard>
    </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.mainInfoFormData,
        customerInfoFlags: state.CustomerReducer.customerInfoFlags
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainInfoContainer));