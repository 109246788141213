import React from 'react';
import QuantitySelector from "../FormComponents/QuantitySelector";


export default function AdditionalUsers({onChange, product, label}) {

    const disabled = ((product?.usesAdditionalLicenses !== undefined && !product?.usesAdditionalLicenses) || (product?.showHideDisableAddonsToggle && product?.showHideDisableAddonsToggle.hideExtraUser))
    
    const additionalUsersCount = product?.additionalUsersCount || 0;

    return <div className="row pb-0">
            {label  && <div className="col-6">
                <span>{label}</span>
            </div>}
        <div className="col-6 pl-0">
            <QuantitySelector value={additionalUsersCount} disabled={disabled} onChange={onChange}/>
        </div>
    </div>
}