import React from 'react';

const MessageAlertLevel = ({message}) => {
    
    return <div className="alert alert-info alert-sm">
        {message}
    </div>
}

export default MessageAlertLevel;
