import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Modal from './Modal'
import quotationActions from '../Actions/quotationActions'
import StaticTextInput from './StaticTextInput';

import './QuotationFAQ.css'

class QuotationFAQModal extends Component {

    componentDidMount() {
        const {onLoad} = this.props;

        onLoad();
    }

    handleClickClose = () => {
        const { onClose, quotationActions } = this.props;

        onClose();

        quotationActions.clearAnswer();
    }

    renderAnswer(question)
    {
        this.props.quotationActions.getAnswer(question);
    }

    render() {
        const { faq, faqAnswer } = this.props;

        if(!faq)
            return null;

        const modalProps = {
            title: 'FAQ - price',
            mainButtonTitle: '',
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            readonly: true,
            dialogStyle: {width: "80%"}
        }

        return (
            <Modal {...modalProps}>
                <form className="form-horizontal text-wrap col-md-12">
                    {
                        faq.map((question, index) => {
                            return (
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-primary btn-sm btn-block btn-outline btn-wrap-text" data-toggle="tooltip" title={question.question} onClick={() => this.renderAnswer(question)}>{question.question}</button>
                                </div>
                            )
                        })
                    }
                </form>
                <StaticTextInput type={"raw"} col2Width={"12"} height={300} scrollable={true} value={faqAnswer} />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        faq: state.QuotationReducer.quotationFaq && state.QuotationReducer.quotationFaq,
        faqAnswer: state.QuotationReducer.quotationAnswer && state.QuotationReducer.quotationAnswer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        quotationActions: bindActionCreators(quotationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationFAQModal);