import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import settingsActions from "../../Actions/settingsActions";
import { connect } from "react-redux";
import helpers from "../../Utils/helpers";
import Modal from "../../Components/Modal";
import Panel from "../../Components/Panel";
import Checkbox from "../../Components/Checkbox";
import TextInput from "../../Components/TextInput";

function EloquaDefinitionsContainer({settingsActions, eloquaDefinitions, syncLogs}) {

    const [showLogModal, setShowLogModal] = useState(false);

    useEffect(() => {
        settingsActions.getEloquaDefinitions();
    }, [])

    const showLog = (syncUri) => {
        settingsActions.getEloquaSyncLogs(syncUri, () => setShowLogModal(true));
    }

    const updateSyncStatus = (syncUri) => {
        settingsActions.updateEloquaSyncStatus(syncUri, settingsActions.getEloquaDefinitions);
    }

    const modalProps = {
        title: "Eloqua sync log",
        onCancelClick: () => setShowLogModal(false),
        stateful: true,
        open: true,
        showButtons: false,
        dialogStyle: {width: '900px'}
    }

    const renderStatusTableCell = (status) => {

        let icon;
        let className;
        if(status === "success")
            icon = "success";
        else if(status === "warning")
            icon = "warning";
        else if(status === "information")
            icon = "info";

        if(icon)
            className = `vismaicon vismaicon-filled vismaicon-${icon}`;

        return <div style={{display: "flex", justifyContent: "space-around"}}>
            {status} <span className={className} style={{verticalAlign: "middle"}}></span>
        </div>
    }

    if(!eloquaDefinitions)
        return null;

    return <Panel title="Eloqua data import synchronization">

        <button className="btn btn-default" onClick={settingsActions.getEloquaDefinitions}>Refresh</button>
        <button className="btn btn-default" onClick={settingsActions.updateSyncStatusForAll}>Update all syncs</button>

        <div className="group-list">
        {eloquaDefinitions.map((item, key) => {
            return <details className="collapse-list" key={key}>
                <summary className="collapse-list-header">
                    <div className="collapse-list-title d-flex align-items-center"
                         style={{justifyContent: "space-between"}}>
                            <span>
                                {item.name}
                            </span>
                        <span className="caret"></span>
                    </div>
                </summary>
                <div className="collapse-list-content">
                    <form className="form-horizontal">
                        <div className="form-group">
                            <Checkbox label="Enabled" checked={item.definition.enabled} disabled/>
                        </div>
                        <div className="form-group">
                            <Checkbox label="MapDataCards" checked={item.definition.mapDataCards} disabled/>
                        </div>
                        {item.definition.mapDataCards && <>
                            <TextInput label="MapDataCardsEntityField" value={item.definition.mapDataCardsEntityField}
                                       disabled copyToClipBoard={true} />
                            <TextInput label="MapDataCardsEntityType" value={item.definition.mapDataCardsEntityType}
                                       disabled copyToClipBoard={true} />
                            <TextInput label="MapDataCardsSourceField" value={item.definition.mapDataCardsSourceField}
                                       disabled copyToClipBoard={true} />
                        </>}
                        <TextInput label="UniqueFieldUri" value={item.definition.uniqueFieldUri} disabled copyToClipBoard={true}/>
                        <TextInput label="Source table name (VDL)" multiline={true} value={item.definition.sourceTableName} disabled copyToClipBoard={true} />
                        <TextInput label="Latest run" value={item.definition.latestRunTime} disabled copyToClipBoard={true} />
                    </form>
                    <br />
                    <br />
                    <h3>Latest syncs</h3>
                    <table className="table table-stripe table-condensed">
                        <thead>
                            <tr>
                                <th>Started UTC</th>
                                <th>Ended UTC</th>
                                <th>Status</th>
                                <th>Created by</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {item.syncs.map((sync, key) => {

                         return <tr key={key}>
                             <td>{helpers.formatDateAndTime(sync.syncStartedAt)}</td>
                             <td>{helpers.formatDateAndTime(sync.syncEndedAt)}</td>
                             <td>{renderStatusTableCell(sync.status)}</td>
                             <td>{sync.createdBy}</td>
                             <td><button className="btn btn-default btn-sm" onClick={() => showLog(sync.uri)}>Show log</button></td>
                             <td>{sync.status !== "success" && <button className="btn btn-default btn-sm" onClick={() => updateSyncStatus(sync.uri)}>Update status</button>}</td>
                         </tr>
                        })}
                        </tbody>
                    </table>

                </div>
            </details>
        })
        }
    </div>

        {showLogModal && <Modal {...modalProps}>
            <div className="panel panel-default">
                <div className="panel-body">

                    <table className="table table-stripe">
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>Severity</th>
                                <th>Message</th>
                                <th>Count</th>
                                <th className="text-nowrap">Status code</th>
                            </tr>
                        </thead>
                        <tbody>
                        {syncLogs && syncLogs.map((log, key) => {

                            return <tr key={key}>
                                <td className="text-nowrap">{helpers.formatDateAndTime(log.createdAt)}</td>
                                <td>{log.severity}</td>
                                <td>{log.message}</td>
                                <td>{log.count}</td>
                                <td className="text-nowrap">{log.statusCode}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                </div>
            </div>
        </Modal>}
    </Panel>
}

function mapStateToProps(state) {
    return {
        eloquaDefinitions: state.SettingsReducer.eloquaDefinitions,
        syncLogs: state.SettingsReducer.syncLogs
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EloquaDefinitionsContainer);
