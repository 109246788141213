import React, { element, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Route, Routes, Outlet, useNavigate, useParams, Navigate } from 'react-router-dom'

import './App.css';
import './Appointment/Appointments.css';

import TopNavigation from '../Components/Layout/TopNavigation'
import Footer from '../Components/Layout/Footer'
import Alert from '../Components/Alert'
import LeftNavigation from '../Components/Layout/LeftNavigation'
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import CustomerContainer from './CustomerContainer'
import CustomerCreateContainer from './Customer/CustomerCreateContainer'
import SearchLayout from '../Components/Layout/SearchLayout';
import CustomerSearchContainer from './Customer/CustomerSearchContainer'
import MyAppointmentsLayout from '../Components/Layout/MyAppointmentsLayout'
import MyActionLogLayout from '../Components/Layout/MyActionLogLayout'
import OrderConfirmationContainer from './Order/OrderConfirmationContainer'
import EmailHistory from './Customer/EmailHistoryContainer'
import UserSignatureContainer from './UserSignatureContainer'
import Settings from './SettingsContainer'
import WebNotification from '../Components/WebNotification'
import LeadsLayout from '../Components/Layout/LeadsLayout'
import AoLeadsLayout from '../Components/Layout/AoLeadsLayout'
import StatusContainer from './StatusContainer'
import QuotationLayout from '../Components/Layout/QuotationLayout'
import MyStatsContainer from './MyStatsContainer';

import commonActions from '../Actions/commonActions'

import Login from '../Components/Login'
import Logout from '../Components/Logout'

import routes from '../Constants/routes'
import Pong from '../Components/Game/Pong';
import ProductOverviewContainer from './ProductOverviewContainer';
import VersionArchive from "../Components/VersionArchive";
import VersionArchiveDetails from "../Components/VersionArchiveDetails";
import CustomerDirectoryContainer from './CustomerDirectoryContainer';
import CustomerDirectoryEditContainer from './CustomerDirectoryEditContainer';
import FileBoxContainer from './FileBox/FileBoxContainer';
import FileBoxCreateTicketContainer from './FileBox/FileBoxCreateTicketContainer';
import FileBoxTicketDetailsContainer from './FileBox/FileBoxTicketDetailsContainer';
import DiscountAgreementsLayout from '../Components/Layout/Voss/DiscountAgreementsLayout';
import InternalCustomerContainer from './Settings/InternalCustomerContainer';

import {UserRoles} from '../Constants/userRoles';
import RequireAuth from "../Components/Permission/RequireAuth";
import EditCustomerContainer from "./Customer/EditCustomerContainer";
import {ActionType} from "../Constants/actionType";
import { FeatureFlags, isFeatureEnabled } from '../Utils/featureFlags';
import DiscountLayout from '../Components/Layout/DiscountLayout';
import { hasAdminAccess } from '../Utils/permissionUtils';

function App({ vbCustomer, customer, pongToggle, vonWarning, user: isAuthenticated, commonActions, sideMenuOpen, user }) {

    const navigate = useNavigate();

    function isCustomerSelected() {
        if (customer && window.location.pathname.includes('/customer/' + customer.pathId)) {
            return true
        }
    }

    function showVertMenu() {
        const classes = ["page-holder"];
        if (!isCustomerSelected() || !sideMenuOpen) {
            classes.push("hidden-vert-menu");
        }

        return classes.join(" ");
    }

    const AppLayout = () => {
        return <div className="App">
            {<TopNavigation navigate={navigate}/>}
            {pongToggle && <Pong />}
            {isAuthenticated && <WebNotification />}
            <Alert />

            <div className={showVertMenu()}>
                {isCustomerSelected() && <LeftNavigation />}
                <div className="container-fluid">
                    <Outlet />
                </div>
            </div>
            {vonWarning && VonWarningLayout()}
        </div>
    }
    
    const VonWarningLayout = () => {
        return (<div className="alert" role="alert">
            <div>
                <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error" aria-hidden="true"></span>
                <strong>Can´t access VON</strong> Information and functionality is limited.
            </div>
        </div>);
    }

    if (!vbCustomer) {
        commonActions.setOpenHeader(undefined)
    }
    
    if(window.location.href.includes('/status')) {
      commonActions.getStatus()
    }

    return (
        <I18nextProvider i18n={i18n}>
            <Routes>
                <Route path={"/"} element={AppLayout()}>
                    {isAuthenticated && renderAuthRoutes()}
                    {!isAuthenticated && renderAnonymousRoutes()}
                </Route>
            </Routes>
            <Footer />
        </I18nextProvider>
    );

    function renderAnonymousRoutes() {
        return <>
            <Route path={routes.status} element={<StatusContainer />} />
            <Route path='*' element={<Login/>} />
            <Route index element={<Login />}/>
        </>
    }

    function renderAuthRoutes() {
        return <>
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.status} element={<StatusContainer />} />
            <Route element={<RequireAuth.RequireAuth />}>
                <Route element={<RequireAuth.RequireReadOnlyAuth roles={[UserRoles.Spcs]} />}>
                    <Route element={<RequireAuth.RequireAdminAuth roles={Object.values(UserRoles)} />}>
                        <Route path={'/settings/*'} element={<Settings />} />
                    </Route>
                    <Route path={routes.logout} element={<Logout />} />
                    <Route path={'/search/*'} element={<SearchLayout />} />
                    <Route path="/customer/:customerNumber/*" element={<CustomerContainer />} />
                    <Route path={routes.createCustomerForm} element={<EditCustomerContainer actionType={ActionType.Create} />}/>
                    <Route exact path={routes.newCustomer} element={<CustomerSearchContainer />} />
                    <Route path={routes.newCustomerForm} element={<CustomerCreateContainer />} />
                    <Route path={'/my-appointments/*'} element={<MyAppointmentsLayout/>} />
                    <Route path={routes.myActions} element={<MyActionLogLayout/>} />
                    <Route path={'/quotation/*'} element={<QuotationLayout/>} />
                    <Route path="/orderconfirmationmail/:customerNumber/:orderId" element={<OrderConfirmationContainer />} />
                    <Route path="/emailhistory/:emailActionLogId" element={<EmailHistory />} />
                    <Route path={'/leads/*'} element={<LeadsLayout/>} />
                    <Route path={'/internal-leads/*'} element={<AoLeadsLayout/>} />
                    <Route path={routes.editSignature} element={<UserSignatureContainer />} />
                    <Route path={routes.myStats} element={<MyStatsContainer />} />
                    <Route element={<RequireAuth.RequireReadWriteAuth roles={[UserRoles.ProductOverview]} />}>
                        <Route path={routes.productOverview} element={<ProductOverviewContainer />} />
                    </Route>
                    <Route path={'/version-archive/'} element={<VersionArchive />} />
                    <Route path={'/version-archive/:versionId'} element={<VersionArchiveDetails />} />
                    <Route element={<RequireAuth.RequireReadWriteAuth roles={[UserRoles.AOSales]} />}>
                        <Route path={routes.customerDirectoryContainer} element={<CustomerDirectoryContainer/>} />
                        <Route path={'/customer-directory/:customerId'} element={<CustomerDirectoryEditContainer/>} />
                    </Route>
                    <Route path={'/filebox'} element={<FileBoxContainer/>}/>
                    <Route path={'/filebox/:ticketId'} element={<FileBoxTicketDetailsContainer/>}/>
                    <Route path={'/createfileboxticket/*'} element={<FileBoxCreateTicketContainer/>}/>
                    <Route path={'/discounts/*'} element={isFeatureEnabled(user, FeatureFlags.DiscountAgreements) ? <DiscountAgreementsLayout readOnly={!hasAdminAccess(user, UserRoles.Discounts)} /> : <DiscountLayout />} />
                    <Route path={'/internal-customers/'} element={<InternalCustomerContainer/>} />
                    <Route path='*' element={<Navigate to={"/search/"} />} />
                    <Route index element={<Navigate to={"/search/"} />} />
                </Route>
            </Route>
        </>
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        isAuthenticating: state.CommonReducer.authenticating,
        userDoesNotExists: state.CommonReducer.userDoesNotExists,
        notificationMessage: state.CommonReducer.webNotificationMessage,
        vonWarning: state.CommonReducer.vonWarning,
        openHeader: state.CommonReducer.openHeader,
        pongToggle: state.CommonReducer.pongToggle,
        sideMenuOpen: state.CommonReducer.sideMenuOpen,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);