import React, {Component, useEffect, useState} from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import { bindActionCreators } from 'redux'
import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next';
import LeadSendEmailModal from '../Customer/LeadSendEmailModal'
function SalesLinks ({ customerActions,  setHeader, openHeader, location, t, vbCustomer, customer, customerInfoCounts, dataLoading }) {
    const [showLeadSendEmailModal, setShowLeadSendEmailModal] = useState(false);

    useEffect(() => {
        if (!customerInfoCounts && !dataLoading)
            customerActions.getCustomerInfoFlags(customer);
    }, []);

    const toggleModal = (modalName, emailTemplate) => {
        if (emailTemplate)
            customerActions.setInternalLeadsEmailTemplate(emailTemplate);

        setShowLeadSendEmailModal(!showLeadSendEmailModal);
    }
    function getLinks() {
        let items = [
            {
                title: t('leftnavigation.sales'), id: "sales", onClick: () => setHeader("sales"), badgeNumber: (customerInfoCounts?.quotationsCount + customerInfoCounts?.openLeads + customerInfoCounts?.openAppointments), subItems: [
                ]
            }
        ];

        items[0].subItems.push({ title: t('leftnavigation.placeorder'), path: routes.orderPlacement(customer.pathId) })
        
        items[0].subItems.push(
                    { title: t('leftnavigation.leads'), path: routes.customerLeads(customer.pathId), badgeNumber: (customerInfoCounts?.openLeads) },
                    { title: t('leftnavigation.appointments'), path: routes.customerAppointments( customer.pathId), badgeNumber: customerInfoCounts?.openAppointments },
                    { title: t('leftnavigation.sendleads'), onClick: () => toggleModal("showLeadSendEmailModal", "InternalLead")  },
                    { title: t('leftnavigation.campaigns'), path: routes.customerCampaign(customer.pathId) }
                    )

        return items;
    }

    return <>
            {getLinks().filter(link => !link.disabled).map((item, index) => {
                return <LeftNavigationBarItem key={index} link={item} location={location} openHeader={openHeader} />
            })}
            {showLeadSendEmailModal && <LeadSendEmailModal onClose={toggleModal.bind(this, "showLeadSendEmailModal")} />}
        </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customerInfoCounts: state.CustomerReducer.customerInfoCounts,
        openHeader: state.CommonReducer.openHeader,
        dataLoading: state.CommonReducer.dataLoading.customerInfoFlags
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SalesLinks));