import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import commonActions from '../Actions/commonActions'

import Loader from './Loader'

import './Modal.css'

const styles = {
    content: {
        overflowY: 'auto',
        
    },
    overlay: {
        backgroundColor: 'rgba(40, 40, 40, 0.5)'
    },
    zindex: 9999
};

class Modal extends Component {

    render() {
        const { t } = this.props;
        if (!this.props.stateful && !this.props.data) {
            return null;
        }

        var { component: Component, title, disableMainButton, mainButtonTitle, onSaveClick, onCancelClick, dialogStyle, readonly, customClassName, customStyles, showButtons = true, additionalButton, onAdditionalClick, additionalButtonTitle, onCloseClick, additionalButtonStyle, cancelTitle, hideMainButton, ...rest } = this.props;

        const isOpen = this.props.stateful ? this.props.open : this.props.showModal

        return (
            <ReactModal
                isOpen={isOpen}
                shouldCloseOnOverlayClick={true}
                contentLabel="Modal"
                ariaHideApp={false}
                className='modal '
                style={customStyles ? customStyles : styles}
            >
                <div className='d-flex min-h-100'>
                <div className={"modal-dialog " + (customClassName ? customClassName : "")} style={dialogStyle}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseClick ? onCloseClick.bind(this) : onCancelClick.bind(this)}><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{title}</h4>
                        </div>
                        <div className="modal-body">
                            {this.props.stateful ? this.props.children : <Component {...rest} />}

                            <div className="clearfix"></div>

                            {showButtons && <div className="form-group padding-top no-left-margin btn-toolbar action-buttons">
                                {!readonly && <>
                                    {this.props.saveData ? <button type="button" className="btn btn-primary" disabled={disableMainButton} onClick={onSaveClick.bind(this, this.props.saveData)}>{mainButtonTitle || 'Submit'}</button>
                                        : <button type="button" className="btn btn-primary" disabled={disableMainButton} onClick={onSaveClick.bind(this)}>{mainButtonTitle || t('defaultmodal.submit')}</button>}
                                    {additionalButton &&
                                        <button type="button" className={"btn " + (additionalButtonStyle ? additionalButtonStyle : "btn-default")} onClick={onAdditionalClick.bind(this)}>{additionalButtonTitle}</button>
                                    }
                                </>}
                                <button type="button" className="btn btn-default" onClick={onCancelClick.bind(this)}>{cancelTitle ? cancelTitle : t('defaultmodal.cancel')}</button>
                                <Loader />
                            </div>}
                        </div>
                    </div>
                </div>
                </div>
            </ReactModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        showModal: state.CommonReducer.showModal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Modal));