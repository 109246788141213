import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import TextInput from '../../Components/TextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import SwitchToggle from "../../Components/SwitchToggle";


function HittaByraEditContainer({ settingsActions, commonActions, form, aoCustomer, t }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getAoCustomer(id);
    }, [])

    const onSaveClick = () => {
        settingsActions.updateAoCustomer(form, () => navigate(routes.hittaByra));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.hittaByra))
    }

    const onDuplicateClick = () => {
        commonActions.cancelFormEditing(form.id)
        navigate(routes.hittaByraAddDuplicate(aoCustomer.id))
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const onToggleCheckbox = (field) => {
        const value = !form.data[field]
        onFieldChange(field, value)
    }

    const onRemoveDuplicateClick = () => {
        settingsActions.deleteDuplicateAoCustomer(form, () => navigate(routes.hittaByra));
        settingsActions.clearAoCustomerSearch();
    }

    const data = form && form.data;


    if (!data) {
        return null;
    }

    const inputStyle = {
        width: 450
    }

    return (
        <div>
            <form className="form-horizontal col-xs-12">
                <fieldset className="col-xs-6">
                    {data.hasDuplicates && <button className="btn btn-primary has-duplicate-button">{t("hittabyraeditcontainer.customerhasduplicates")}</button>}

                    <StaticTextInput label={t("hittabyraeditcontainer.customernumber")} value={data.customerNumber} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.organizationnumber")} value={data.organizationNumber} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.address")} value={data.address} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.postcode")} value={data.postCode} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.city")} value={data.city} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.certified")} value={data.certified ? t("hittabyraeditcontainer.yes") : t("hittabyraeditcontainer.no")} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.name")} value={data.name} disabled={data.updateFromVB || data.isDuplicate} onChange={(value) => onFieldChange("name", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualaddress")} value={data.manualAddress} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualAddress", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualpostcode")} value={data.manualPostCode} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualPostCode", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualcity")} value={data.manualCity} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualCity", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.url")} value={data.url} disabled={data.updateFromVB} onChange={(value) => onFieldChange("url", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.phone")} value={data.phone} disabled={data.updateFromVB} onChange={(value) => onFieldChange("phone", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.mobilephone")} value={data.mobilePhone} disabled={data.updateFromVB} onChange={(value) => onFieldChange("mobilePhone", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.email")} value={data.email} disabled={data.updateFromVB} onChange={(value) => onFieldChange("email", value)} style={inputStyle} />

                    {!data.isDuplicate && data.certified && <div className="form-group">
                        <label className="col-xs-4 control-label">{t('hittabyraeditcontainer.hidden')}</label>
                        <div className="col-xs-8">
                            <SwitchToggle checked={data.hidden} value={data.hidden} onChange={() => onToggleCheckbox("hidden")}/>
                        </div>
                    </div>}

                    {!data.isDuplicate && <div className="form-group">
                        <label className="col-xs-4 control-label">{t('hittabyraeditcontainer.updatefromvb')}</label>
                        <div className="col-xs-8">
                            <SwitchToggle checked={data.updateFromVB} value={data.updateFromVB} onChange={() => onToggleCheckbox("updateFromVB")} />
                        </div>
                    </div>}

                </fieldset>
                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("hittabyraeditcontainer.save")}</button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("hittabyraeditcontainer.cancel")}</button>
                    {!data.isDuplicate ? <>
                        <button type="button" className="btn btn-default" onClick={onDuplicateClick}>{t("hittabyraeditcontainer.addduplicate")}</button>
                    </> : <>
                        <button type="button" className="btn btn-default" onClick={onRemoveDuplicateClick}>{t("hittabyraeditcontainer.removeduplicate")}</button>
                    </>}
                    <Loader />
                </div>
            </form>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        aoCustomer: state.SettingsReducer.aoCustomer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.aoCustomerEdit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HittaByraEditContainer));