import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import ProductSearch from '../Components/Order/ProductSearch'

import quotationActions from '../Actions/quotationActions'
import orderActions from '../Actions/orderActions'
import ProductDetailsContainer from '../Containers/Order/ProductDetailsContainer'
import OrderDetailsContainer from './Order/OrderDetailsContainer';
import QuotationFAQModal from '../Components/QuotationFAQModal'
import OrderActions from '../Components/Order/OrderActions'
import commonActions from '../Actions/commonActions'
import QuotationListContainer from './QuotationListContainer'
import * as formValidationRules from '../Constants/formValidationRules'

import system from '../Constants/system'
import * as calcTypes from '../Constants/priceCalculationTypes'
import QuotationModal from '../Components/QuotationModal'
import withModalForm from '../Components/HOC/withModalForm'
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../Components/Panel';


function CheckPriceContainer({ commonActions, quotationActions, product, orderActions, isModalActive, onFieldChange, onCloseClick, onOpenModal, quotationCustomerForm, order, history, t, searchResults, pushedQuotation }) {

    const { organizationNumber } = useParams();
    const navigate = useNavigate();

    const [showQuotationFAQModal, setShowQuoationFAQModal] = useState();

    useEffect(() => {

        if (organizationNumber)
            quotationActions.getQuotations(organizationNumber);
        else {
            quotationActions.getQuotations(null);
            quotationActions.clearSelectedCustomer();
        }

        const fakeCompanyExpenseFields = {
            adminEmail: '',
            adminFirstName: '',
            adminLastName: ''
        }

        commonActions.startFormEditing("orderForm", fakeCompanyExpenseFields, formValidationRules.OrderForm)

        return () => {
            orderActions.clearQuotation(false);
        }
    }, [])

    const onSearch = (query) => {
        quotationActions.search(query)
    }

    const onProductClick = (productId, sourceSystem) => {

        if (product !== null)
            quotationActions.hideProductDetails();

        if (sourceSystem === system.Von) {
            quotationActions.getVonProductDetails(productId, 0);
        } else if (sourceSystem === system.Vb) {
            quotationActions.getProductDetails(productId, 0, null, null);
        } else if (sourceSystem === system.CompanyExpense) {
            quotationActions.getCompanyExpenseProductDetails(productId, -1);
        }
        else if (sourceSystem === system.Blikk)
            quotationActions.getBlikkProductDetails(productId);
    }

    const onCancel = () => {
        orderActions.clearQuotation(true);
    }

    const onLoad = () => {
        quotationActions.getFaq();
    }

    var quotationModalAttr = {
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => orderActions.startQuotationModal(order, quotationCustomerForm),
        onSave: (form) => orderActions.sendQuotation(form, onCloseClick)
    }

    return (
        <div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <button className="btn btn-primary pull-right" onClick={(e) => setShowQuoationFAQModal(true)}>{t('checkpricecontainer.faq')}</button>
                            <h3 className="panel-title">{t('checkpricecontainer.productsearch')}</h3>
                        </div>
                        <div className="panel-body">
                            <ProductSearch results={searchResults} onSearch={onSearch.bind(this)} onProductClick={onProductClick.bind(this)} isVonEnabled={true} />
                        </div>
                    </div>
                    <Panel>
                    <ProductDetailsContainer genericActions={quotationActions} priceCalcType={calcTypes.priceCalculationTypes.quotation} />
                    <OrderDetailsContainer genericActions={quotationActions} priceCalcType={calcTypes.priceCalculationTypes.quotation} />
                    <OrderActions onSendQuotation={onOpenModal} onCancel={onCancel.bind(this)} disabled={!order || (order.orderlines && order.orderlines.length === 0)} priceCalcType={calcTypes.priceCalculationTypes.quotation} pushedQuotation={pushedQuotation} quotationCurrentlyInProgress={order && order.quotationInProgress} orderStatus={order && order.status} />
                    {showQuotationFAQModal && <QuotationFAQModal onClose={() => setShowQuoationFAQModal(false)} onLoad={onLoad.bind(this)} />}
                    {isModalActive('quotation') && <QuotationModal {...quotationModalAttr} />}
                    </Panel>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12">
                    <QuotationListContainer history={history} />
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        searchResults: state.OrderReducer.searchResults,
        order: state.OrderReducer.order,
        quotationCustomerForm: state.CommonReducer.forms && state.CommonReducer.forms.quotationCustomerForm,
        pushedQuotation: state.OrderReducer.pushedQuotation,
        // product: state.OrderReducer.product && state.OrderReducer.product,
        // sourceSystem: state.OrderReducer.sourceSystem && state.OrderReducer.sourceSystem
    }
}

function mapDispatchToProps(dispatch) {
    return {
        quotationActions: bindActionCreators(quotationActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}


export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckPriceContainer)));