import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import DesktopVersionsList from '../../Components/Customer/DesktopVersionsList'
import Modal from '../../Components/Modal'
import EmailTemplate from '../../Components/EmailTemplate'
import Panel from '../../Components/Panel';

class SendDesktopVersionContainer extends Component {

    componentDidMount() {
        const { customerActions: actions, customer, t } = this.props;

        actions.getDesktopLicenses(customer.customerNumber);
    }

    onOpenFormClick(id, version, licenseKey) {
        const { customerActions: actions, customer } = this.props;

        actions.getSendDesktopLicenseTemplate(customer.customerNumber, id, version, licenseKey)
    }

    getOldVersionsLink() {
        return <a href="http://www.google.com/url?q=http%3A%2F%2Fspcs-spv-web-03%2FSolum%2FMailCreator%2FMailVersion&sa=D&sntz=1&usg=AFQjCNG3Fr-NmmOk47HqVoz9w6jjCnx2Cg" target="_blank" rel="noopener noreferrer" className="btn btn-default">Old Versions <span className="vismaicon vismaicon-sm vismaicon-export" aria-hidden="true" style={{paddingLeft: 5}}></span></a>
    }

    renderNoData() {
        const {t} = this.props
        return [
                <p key='no_entries'>{t('senddesktopversioncontainer.noentriesfound')}</p>,
                // <p key='old_versions_external'>{this.getOldVersionsLink()}</p>
            ];
    }

    onFieldChange(field, value) {
        const { commonActions, emailForm } = this.props;

        commonActions.changeMultiFormField(emailForm.id, field, value)
    }

    onSendClick() {
        const { emailForm, customerActions: actions  } = this.props;

        actions.sendDesktopVersionEmail(emailForm);
    }

    onCancelClick() {
        const { commonActions: actions, emailForm } = this.props;

        actions.cancelFormEditing(emailForm.id);
        actions.showModal(false);
    }

    render() {

        const { licenses, emailForm, t } = this.props;

        if(!licenses) {
            return null;
        }

        const modalProps = {
            component: EmailTemplate,
            title: t('senddesktopversioncontainer.senddesktopversion'),
            mainButtonTitle: t('senddesktopversioncontainer.send'),
            data: emailForm && emailForm.data,
            bodyHeight: 200,
            onChange: this.onFieldChange.bind(this),
            onSaveClick: this.onSendClick.bind(this),
            onCancelClick: this.onCancelClick.bind(this)
        }

        return (
            <Panel title={t('senddesktopversioncontainer.header')}>
                {(licenses.length === 0) ? this.renderNoData() : <>
                    <DesktopVersionsList licenses={licenses} onOpenFormClick={this.onOpenFormClick.bind(this)} />
                    {/* <p>{this.getOldVersionsLink()}</p> */}
                    <Modal {...modalProps} />
                </>}
            </Panel>
        );
    }
}

function mapStateToProps({CustomerReducer, CommonReducer}) {
    return {
        licenses: CustomerReducer.desktopLicenses,
        customer: CustomerReducer.selectedCustomer,
        emailForm: CommonReducer.forms && CommonReducer.forms.sendDesktopVersion
    }
}

function mapDispatchToProps(dispatch) {
   return {
       customerActions : bindActionCreators(customerActions, dispatch),
       commonActions : bindActionCreators(commonActions, dispatch)
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendDesktopVersionContainer));