import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from '../../Utils/helpers';

function FileBoxOverviewContainer({ fileBoxActions, t, fileBoxCase }) {

    return (
        <>
            {fileBoxCase.allFiles.length > 0 && <fieldset className="col-xs-12" style={{ marginBottom: "20px" }}>
                <legend style={{ marginBottom: "0px" }}>Uploaded files</legend>
                <table className="table table-stripe table-actionLogs">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th className="text-left">{t('fileboxoverviewcontainer.name')}</th>
                            <th className="text-left">{t('fileboxoverviewcontainer.size')}</th>
                            <th className="text-left">{t('fileboxoverviewcontainer.uploadedby')}</th>
                            <th className="text-left">{t('fileboxoverviewcontainer.uploaded')}</th>
                            <th className="text-left">{t('fileboxoverviewcontainer.downloaded')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileBoxCase.allFiles.map((file, index) => {
                            return <tr key={index}>
                                <td>{!file.byCustomer ? <span style={{ display: "block" }} className="vismaicon vismaicon-move-left-circle">
                                    <div className="sent-from-us">
                                        Sent from us
                                    </div>
                                </span> : <span style={{ display: "block" }} className="vismaicon vismaicon-move-right-circle">
                                    <div className="sent-from-us">
                                        Sent from customer
                                    </div>
                                </span>}</td>
                                <td><span style={{ display: "block" }} className={`vismaicon ${file.vismaFileIcon ? file.vismaFileIcon : "vismaicon-new"}`}></span></td>
                                <td >{file.fileName}</td>
                                <td>{file.formatedFileSize}</td>
                                <td>{file.uploadedBy}</td>
                                <td>{helpers.formatDateAndTime(file.uploadedAt)}</td>
                                <td>{helpers.formatDateAndTime(file.downloadedAt)}</td>
                                <td><a><span className="vismaicon vismaicon-download"></span></a></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </fieldset>}
            <fieldset className="col-xs-12" style={{ marginBottom: "0px" }}>
                <legend style={{ marginBottom: "0px" }}>Comments</legend>
                {fileBoxCase.comments.map((comment, index) => {
                    return <div className='media'>
                        <div className="media-left media-top">
                            <a>
                                <span className="vismaicon vismaicon-comment" />
                            </a>
                        </div>
                        <div className="media-body">
                            <div>
                                {comment.text}
                            </div>
                            <h6 className="media-heading" style={{ color: "#0974b3" }}>{comment.author}({helpers.formatDateAndTime(comment.created)})</h6>
                        </div>
                    </div>
                })}
                <div className='media'>
                    <div className="media-left media-top">
                        <a>
                            <span className="vismaicon vismaicon-add-comment" />
                        </a>
                    </div>
                    <div className="media-body">
                        <textarea className="form-control" placeholder='Add a new comment to customer' />
                    </div>
                    <button type='button' className='btn btn-default pull-right' style={{ marginTop: "15px" }}>Add comment</button>
                </div>
            </fieldset>
        </>
    );
}
function mapStateToProps(state) {
    return {
        fileBoxCase: state.FileBoxReducer.fileBoxCase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxOverviewContainer));