import { fileBoxActionTypes as actionTypes } from "../ActionTypes/fileBoxActionTypes";
import { initialState } from "./CommonReducer";

export default function FileBoxReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_FILEBOX_TEAMS:
            newState = { fileBoxTeams: action.data }
            break;
        case actionTypes.STORE_FILEBOX_CASES:
            newState = { fileBoxCases: action.data }
            break;
        case actionTypes.STORE_FILEBOX_CASE:
            newState = { fileBoxCase: action.data}
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}