import { appointmentActionTypes as actionTypes } from '../ActionTypes/appointmentActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'
import commonActions from './commonActions'
import customerActions from './customerActions'
import actionLogActions from './actionLogActions';
import leadActions from './leadActions'

import helpers from '../Utils/helpers'
import { httpFetch } from '../Utils/httpUtils'
import * as formValidationRules from '../Constants/formValidationRules'
import moment from 'moment'

const appointmentActions = {

    getMyAppointments() {
        return (dispatch, getState) => {
            return httpFetch(`/api/appointment/GetAllForUser`, dispatch)
                .then(json => {
                    const openAppointments = (json || []).filter(json => !json.isClosed)
                    const closedAppointments = (json || []).filter(json => json.isClosed)
                    dispatch({ type: actionTypes.STORE_MY_APPOINTMENTS, myOpenAppointments: openAppointments, myClosedAppointments: closedAppointments });
                    dispatch(appointmentActions.updateMyAppointmentsCount(openAppointments.length));
                    const state = getState();
                    const filter = state.AppointmentReducer.myFilter;
                    if (filter) {
                        const list = state.AppointmentReducer.myOpenAppointments;
                        dispatch(appointmentActions.filterAppoinments(list, filter));
                    }
                })
        }
    },

    getMyCustomerAppointments(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/appointment/GetMyOpenCustomerAppointments/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MY_CUSTOMER_APPOINTMENTS, data: json });
                })
        }
    },

    getCustomerAppointments(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/appointment/GetAllForCustomer/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CUSTOMER_APPOINTMENTS, data: json });
                    dispatch(customerActions.updateCustomerAppointmentsCount(appointmentActions.filterOpenAppointments(json).length))
                })
        }
    },

    search(phrase) {
        return (dispatch) => {
            return httpFetch(`/api/appointment/Search/${phrase}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SEARCH_APPOINTMENTS_RESULTS, data: json });
                })
        }
    },

    clearSearch() {
        return { type: actionTypes.STORE_SEARCH_APPOINTMENTS_RESULTS, data: [] };
    },

    addAppointment(form, data) {
        return commonActions.startFormEditing(form, data, formValidationRules.AppointmentForm);
    },

    editAppointment(appointmentId) {
        return dispatch => {
            return httpFetch(`/api/appointment/get/${appointmentId}`, dispatch)
                .then(json => {

                    dispatch({ type: actionTypes.STORE_APPOINTMENT, data: json });
                    dispatch(commonActions.loadDropdownList("AppointmentCloseReasonCodes"));
                    dispatch(commonActions.loadDropdownList("AppointmentBuyReasonCodes"));
                    dispatch(commonActions.startFormEditing("appointmentForm", json, formValidationRules.AppointmentForm));
                    const leadId = appointmentActions.getLeadId(json);
                    dispatch(leadActions.getLead(leadId, false, false));
                    dispatch({ type: commonActionTypes.STORE_SELECTED, data: { customerNumber: json.customerNumber, type: "appointment", typeId: json.id } });
                })
        }
    },

    clearAppointment(){
        return  {type: actionTypes.CLEAR_APPOINTMENT, data: null}
    },

    openAppointment(appointmentId) {
        return dispatch => {
            return httpFetch(`/api/appointment/get/${appointmentId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_APPOINTMENT, data: json });
                    dispatch(commonActions.loadDropdownList("AppointmentCloseReasonCodes"));
                    dispatch(commonActions.loadDropdownList("AppointmentBuyReasonCodes"));
                    dispatch(commonActions.startFormEditing("appointmentForm", json, formValidationRules.AppointmentForm));
                    const leadId = appointmentActions.getLeadId(json);
                    dispatch(leadActions.getLead(leadId, false, false));
                })
        }
    },

    closeAppointment(customerNumber, form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/appointment/complete`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("appointmentForm"));
                    dispatch(commonActions.cancelFormEditing("appointmentCloseModal"));

                    dispatch(appointmentActions.getOpenAppointmentsCount(customerNumber));
                    dispatch(commonActions.showSuccess('Appointment has been completed'));
                    callback();
                })
        }
    },

    reopenAppointment(appointmentId, customerNumber, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(appointmentId)
            }

            return httpFetch('/api/appointment/reopen', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("appointmentForm"));
                    dispatch(commonActions.showSuccess('Appointment has been reopened'));

                    dispatch(appointmentActions.getOpenAppointmentsCount(customerNumber));
                    callback();
                })
        }
    },


    saveAppointment(appointment, isCreation, customerNumber, callback, createBooking) {
        return (dispatch) => {
            const hasChanges = appointment.changed;

        
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(appointment);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            if (!isCreation && !hasChanges) {
                if(createBooking){
                    window.open(helpers.createBookingLink(appointment.data))
                }
                dispatch(commonActions.cancelFormEditing("appointmentForm"));
                callback();
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(appointment.data)
            }

            dispatch(commonActions.progressLoader(true))

            const apiActionName = isCreation ? 'add' : 'update';

            return httpFetch(`/api/appointment/${apiActionName}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing(appointment.id));
                    dispatch(appointmentActions.getMyAppointments())

                    if (isCreation) {

                        if (createBooking) {
                            dispatch(appointmentActions.redirectNewCreationToBooking());
                        }

                        dispatch(appointmentActions.getOpenAppointmentsCount(customerNumber));
                        dispatch(commonActions.showSuccess(`Appointment has been created`, null));
                        callback()
                    }
                    else {

                        if (createBooking) {
                            window.open(helpers.createBookingLink(appointment.data))
                        }

                        dispatch(commonActions.showSuccess(`Appointment has been updated`));
                        callback();

                    }

                })
        }
    },

    redirectNewCreationToBooking(){
        return (dispatch) => {
            return httpFetch(`/api/appointment/GetLatestCreatedAppointment`, dispatch)
                .then(json => {
                    window.open(helpers.createBookingLink(json))
                })
        }
    },

    updateMyAppointmentsCount(number) {
        return {
            type: actionTypes.UPDATE_MY_APPOINTMENTS_COUNT,
            openAppointments: number
        }
    },

    getOpenAppointmentsCount(customerNumber) {
        return dispatch => {
            return httpFetch(`/api/appointment/getOpenAppointmentsCount/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch(appointmentActions.updateMyAppointmentsCount(json.userAppointments));

                    if (customerNumber) {
                        dispatch(customerActions.updateCustomerAppointmentsCount(json.customerAppointments));
                    }
                })
        }
    },

    filterOpenAppointments(json) {
        return json.filter(item => item.status.toLowerCase() === 'open');
    },

    startCloseModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("appointmentCloseModal", data, formValidationRules.AppointmentCloseModal))
        }
    },

    filterAppoinments(list, filter) {
        const currentDate = moment();
        
        if (filter === "day") {
            list = (list || []).filter(list => moment(list.time).dayOfYear() === currentDate.dayOfYear() && moment(list.time).year() === currentDate.year());
        } else if (filter === "week") {
            list = (list || []).filter(list => moment(list.time).week() === currentDate.week() && moment(list.time).year() === currentDate.year());
        } else if (filter === "month") {
            list = (list || []).filter(list => moment(list.time).month() === currentDate.month() && moment(list.time).year() === currentDate.year());
        }

        return {
            type: actionTypes.FILTERED_OPEN_APPOINTMENTS,
            list: list,
            filter: filter
        }
    },

    saveAction(appointmentId, form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/appointment/SaveAction/${appointmentId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Saved to action history'));
                    if (callback) {
                        callback();
                    }
                })
        }
    },

    sendEmail(appointmentId, emailForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/appointment/SendEmail/${appointmentId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Email has been sent'));

                    callback();
                })
        }
    },

    startBuyModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("appointmentBuyModal", data))
        }
    },

    getEmailTemplate(id, customerEmail, customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/appointment/emailTemplate/${id}`, dispatch)
                .then(json => {
                    json.to = customerEmail
                    json.customerNumber = customerNumber
                    json.emailTemplate = json.name
                    json.defaultFrom = json.from

                    dispatch(commonActions.startFormEditing("appointmentEmail", json, formValidationRules.EmailTemplate))
                })
        }
    },

    startEmailSendingModal(customerEmail, customerNumber) {
        return (dispatch) => {
            dispatch(commonActions.loadDropdownList("AppointmentEmailTemplates"))
            dispatch(appointmentActions.getEmailTemplate(null, customerEmail, customerNumber))
        }
    },

    getFormFromEmailActionLog(emailActionLogId) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("appointmentEmail"))
            dispatch(actionLogActions.getFormFromEmailActionLog(emailActionLogId, json =>
                dispatch(commonActions.startFormEditing('appointmentEmail', json, formValidationRules.EmailTemplate))))
        }
    },

    getLeadId(appointment) {
        let leadId = undefined;
        if (appointment && appointment.refData) {
            if (appointment.refData.match("\/leads\/"))
                leadId = appointment.refData.match(/\d+/g).join([]);
        }
        return leadId;
    },

    saveMyTemplate(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/appointment/saveMyTemplate/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess(`Template has been ${form.data.templateId > 0 ? 'updated' : 'created'}`));
                    callback();
                })
        }
    },

    getMyTemplate(id) {
        return dispatch => {
            return httpFetch(`/api/appointment/myEmailTemplate/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("appointmentsMyTemplate", json, null));
                })
        }
    },

    deleteMyTemplate(id, callback) {
        const httpRequest = { method: 'DELETE' }

        return dispatch => {
            return httpFetch(`/api/appointment/deleteMyTemplate/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Template has been deleted`));
                    callback();
                })
        }
    },

    getMySignature() {
        return dispatch => {
            return httpFetch(`/api/appointment/mySignature`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("appointmentsMySignature", json, null))
                })
        }
    },

    updateMySignature(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            var regex = /(<([^>]+)>)/ig
            form.data.noHtml = form.data && form.data.signature && form.data.signature.replace(regex, '');

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/appointment/updateMySignature/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess('Signature has been updated'));
                    callback();
                })
        }
    },


}

export default appointmentActions;