import React from 'react';
import { articleTypes, articlePeriods } from '../../Constants/dropDownLists';

function ServiceAccordion({ services, searchQuery, onAddArticle }) {
    if (!services) {
        return null;
    }

    const formatNameWithHighlights = (name) => {
        if (!searchQuery) {
            return name;
        }

        const regex = new RegExp(`(${searchQuery})`, 'gi');
        return name.replace(regex, '<strong>$1</strong>');
    };

    return (
        <div className="group-list">
            {services.applicationsWithArticles.map((service, index) => {
                
                    return (
                        <details className="collapse-list service-detail" open={searchQuery.length > 0} key={index}>
                            <summary className="collapse-list-header">
                                <div className="collapse-list-title">
                                    {service.name}
                                    <span className="caret" />
                                </div>
                            </summary>
                            <div className="collapse-list-content">
                                {service.sortedArticles.map((sortedArticle, subIndex, key) => (
                                    <details className="collapse-list service-detail"  open={searchQuery.length > 0} key={subIndex}>
                                        <summary className="collapse-list-header">
                                            <div className="collapse-list-title">
                                                 {sortedArticle.variant ? sortedArticle.variant : "övriga tjänster"}
                                                <span className="caret ml-12" />
                                            </div>
                                        </summary>
                                        <div className="collapse-list-content">
                                            {sortedArticle.articles.map((article, subIndex) => (
                                                <div className="collapse-list service-detail" open={false} onClick={() => onAddArticle(article)}>
                                                    <summary className="collapse-list-header">
                                                        <div className="collapse-list-title">
                                                            <div dangerouslySetInnerHTML={{ __html: formatNameWithHighlights(article.articleNo) }} />
                                                            <div>
                                                            {article.period != "" && article.articleType != "" && (
                                                                <p className='mb-0'>{articlePeriods[article.period] + " - " + articleTypes[article.articleType]}</p>
                                                            )}
                                                        </div>
                                                        </div>
                                                    </summary>
                                                </div>
                                            ))}
                                        </div>
                                    </details>
                                ))}
                            </div>
                        </details>
                    );
            })}
        </div>
    );
}

export default ServiceAccordion;
