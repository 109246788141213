import { useEffect, useState } from "react";
import AlertSmall from "../../AlertSmall";
import OrderSummaryTable from "./OrderSummaryTable"; // Import the new component
import system from "../../../Constants/system";
import VossOrderSummaryTable from "./VOSS/VossOrderSummaryTable";

export function OrderSummary({ order, isApprovalRequest, orderActions, product, customer }) {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    setAlerts(getAlerts());
  }, [order, product]);

  const getAlerts = () => {
    const alerts = [];
    if (order?.totalInitialPrice > customer?.creditLimit) {
      alerts.push({
        type: 'warning',
        title: 'Orderläggningen är över kreditgränsen och kommer behöva godkännas av ekonomiavdelningen.',
      });
    }
    return alerts;
  };

  const onOrderLineEdit = (orderline) => {
    orderActions.editOrderline(orderline, order.customerNumber, order.orderId);
  };

  const onOrderLinesRemove = (orderlineIds) => {
    orderActions.removeOrderLines(order.orderId, orderlineIds, order.customerNumber);
  };

  const renderOrderSummaryTable = () => {
    if (order.orderlines.find(ol => ol.sourceSystem === system.Voss))
      return <VossOrderSummaryTable order={order} isApprovalRequest={isApprovalRequest} onEdit={onOrderLineEdit} onRemove={onOrderLinesRemove} />
    return <OrderSummaryTable order={order} isApprovalRequest={isApprovalRequest} onEdit={onOrderLineEdit} onRemove={onOrderLinesRemove} />

  };

  return <>
    {alerts.map((alert, index) => (
      <AlertSmall key={index} alert={alert} />
    ))}
    {renderOrderSummaryTable()}
  </>
}