import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from "../Checkbox";

export default function CompetitorDiscount({product, onChange}) {

    const change = element => {
        onChange(element.checked)
    }

    const { t } = useTranslation();
    
    const checked = product.competitorDiscount === "yes" || false;
    
    return <Checkbox label={t('competitordiscount.competitor')} checked={checked} disabled={false} onChange={change} />
}