import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function BreadCrumbs({ breadcrumbs }) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const activeIndex = breadcrumbs.findIndex(breadcrumb => {
        const breadcrumbUrl = breadcrumb.url.replace(/\/$/, '');
        const currentUrl = currentPath.replace(/\/$/, '');
        return currentUrl === breadcrumbUrl || currentUrl === breadcrumb.url;
    });

    const displayedBreadcrumbs = breadcrumbs.slice(0, activeIndex + 1);
    
    return (
        <div className='mb-48'>
            <h2 className="panel-title">{displayedBreadcrumbs[activeIndex].header}</h2>
            {activeIndex !== 0 && <ol className="breadcrumb">
                {displayedBreadcrumbs.map((breadcrumb, index) => (
                    <li
                        key={index}
                        className={
                            activeIndex == index ? 'active' : breadcrumb.disabled ? 'disabled' : ''
                        }
                    >
                        {breadcrumb.disabled ? (
                            <a onClick={() => navigate(breadcrumb.url)} tabIndex="-1" aria-disabled="true">
                                {breadcrumb.label}
                            </a>
                        ) : (
                            <a href={breadcrumb.url}>{breadcrumb.label}</a>
                        )}
                    </li>
                ))}
            </ol>}
        </div>
    );
}



export default withTranslation()(BreadCrumbs);
