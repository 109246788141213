import { useTranslation } from 'react-i18next';
import helpers from '../../Utils/helpers';
import { vossCustomerSubscriptionStatus } from '../../Constants/VOSS/vossCustomerSubscriptionStatus';
import { VossProductGroupType } from '../../Constants/VOSS/vossProductGroupType';

export default function CustomerPurchaseHistory({ vbProducts, vonProducts, subscriptions, productsLoading }) {

    const { t } = useTranslation();

    const isActive = (product) => {
        const currentDate = new Date();
        const purchaseDate = new Date(product.purchaseDate);
        const endDate = product.endDate ? new Date(product.endDate) : null;

        return endDate ? (currentDate >= purchaseDate && currentDate <= endDate) : true;
    };

    const activeOnPremProducts = vbProducts?.filter(product => isActive(product));
    const inactiveOnPremProducts = vbProducts?.filter(product => !isActive(product));
    const activeSaasProducts = vonProducts?.filter(product => isActive(product));
    const inactiveSaasProducts = vonProducts?.filter(product => !isActive(product));
    const activesubscriptions = subscriptions?.filter(subscription => subscription.status === vossCustomerSubscriptionStatus.Active);
    const terminatedSubscriptions = subscriptions?.filter(subscription => subscription.status === vossCustomerSubscriptionStatus.Terminated);

    return (
        <div className="rounded-8 bg-primary customer-purchase-history-container">
            <div className="card-heading">
                <h3 className="card-title font-family-ubuntu-semibold">{t('customerpurchasehistory.customerpurchasehistory')}</h3>
            </div>
            <div className="card-body p-24">
                {productsLoading ? <div className="spinner spinner-default-green m-auto d-block" /> :
                    (vbProducts?.length > 0 || vonProducts?.length > 0 || subscriptions?.length > 0) ? <div className="d-flex flex-column" style={{ gap: "24px" }}>
                        {vbProducts?.length > 0 && <div className='d-grid gap-12'>
                            {activeOnPremProducts?.length > 0 && <div>
                                <h3 className="font-family-ubuntu-semibold mb-16 text-success">OnPrem - {t('customerpurchasehistory.activesubscriptions')}</h3>
                                <div className='d-grid gap-12'>
                                    {activeOnPremProducts?.map((product) => {
                                        return (
                                            <div key={product.id}>
                                                <p className='mb-0'>
                                                    <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                                </p>
                                                <p className='mb-0'>{t('customerpurchasehistory.users')}: {product.users}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>}
                            {inactiveOnPremProducts?.length > 0 && <div>
                                <h3 className="font-family-ubuntu-semibold mb-16 text-error">OnPrem - {t('customerpurchasehistory.inactivesubscriptions')}</h3>
                                <div className='d-grid gap-12'>
                                    {inactiveOnPremProducts?.map((product) => {
                                        return (
                                            <div key={product.id}>
                                                <p className='mb-0'>
                                                    <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                                </p>
                                                <p className='mb-0'>{t('customerpurchasehistory.users')}: {product.users}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>}
                        </div>}
                        {vonProducts?.length > 0 && <div className='d-grid gap-12'>
                            {activeSaasProducts?.length > 0 && <div>
                                <h3 className={`font-family-ubuntu-semibold mb-16 text-success`}>SAAS - {t('customerpurchasehistory.activesubscriptions')}</h3>
                                <div className='d-grid gap-12'>
                                    {activeSaasProducts?.map((product) => {
                                        return (
                                            <div key={product.id}>
                                                <p className='mb-0'>
                                                    <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                                </p>
                                                <p className='mb-0'>{t('customerpurchasehistory.module')}: {product.module}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>}
                            {inactiveSaasProducts?.length > 0 && (
                                <div>
                                    <h3 className={`font-family-ubuntu-semibold mb-16 text-error`}>SAAS - {t('customerpurchasehistory.inactivesubscriptions')}</h3>
                                    <div className='d-grid gap-12'>
                                        {inactiveSaasProducts?.map((product) => (
                                            <div key={product.id}>
                                                <p className='mb-0'>
                                                    <span className='font-family-ubuntu-semibold'>{product.product}</span> ∙ {product.purchaseDate} - {product.endDate}
                                                </p>
                                                <p className='mb-0'>{t('customerpurchasehistory.module')}: {product.module}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>}
                        {subscriptions?.length > 0 && <div className='d-grid gap-12'>
                            {activesubscriptions?.length > 0 && <div>
                                <h3 className="font-family-ubuntu-semibold mb-16 text-success">VOSS - {t('customerpurchasehistory.activesubscriptions')}</h3>
                                <div className='d-grid gap-12'>
                                    {subscriptions?.map((subscription) => (
                                        <div key={subscription.id}>
                                            <p className='mb-0'>
                                                <span className='font-family-ubuntu-semibold'>{subscription.package.name} - {subscription.tier.name}</span> ∙ <span className='no-wrap'>{helpers.formatDate(subscription.subscriptionBillingPeriod.startDate)} - {helpers.formatDate(subscription.subscriptionBillingPeriod.endDate)}</span>
                                            </p>
                                            {subscription.productGroups.filter(x => x.productGroupType === VossProductGroupType.Optional).map((productGroup, index) => (
                                                <p className='mb-0' key={index}>{productGroup.name}</p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            }
                            {terminatedSubscriptions?.length > 0 && <div>
                                <h3 className="font-family-ubuntu-semibold mb-16 text-error">VOSS - {t('customerpurchasehistory.inactivesubscriptions')}</h3>
                                <div className='d-grid gap-12'>
                                    {terminatedSubscriptions?.map((subscription) => (
                                        <div key={subscription.id}>
                                            <p className='mb-0'>
                                                <span className='font-family-ubuntu-semibold'>{subscription.package.name}</span> ∙ {helpers.formatDate(subscription.subscriptionBillingPeriod.startDate)} - {helpers.formatDate(subscription.subscriptionBillingPeriod.endDate)}
                                            </p>
                                            {subscription.productGroups.filter(x => x.productGroupType === VossProductGroupType.Optional).map((productGroup, index) => (
                                                <p className='mb-0' key={index}>{productGroup.name}</p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                        }
                    </div> : <p><em>{t('customerpurchasehistory.nohistoryfound')}</em> ¯\_(ツ)_/¯</p>}
            </div>
        </div>
    );
}