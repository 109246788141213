import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import StaticTextInput from './StaticTextInput'

import commonActions from '../Actions/commonActions'

import BaseSearch from './BaseSearch';

class UserSearch extends Component {

    onChange(object) {
        const newValue = object.email || '';

        this.props.onChange(newValue);
    }

    render() {

        const { readonly, searchResults, commonActions, clearButton, label} = this.props; 

        let { defaultSelected } = this.props;

        if(defaultSelected === null) {
            defaultSelected = ''
        }

        if (readonly) {
            const val = (typeof defaultSelected === 'object') 
                ? (defaultSelected.name || defaultSelected.email)
                : defaultSelected
                
            return <StaticTextInput label={this.props.label} value={val} />
        }

        let labelExists = this.props.label;
        let divWidth = !labelExists ? "col-xs-12" :  "col-xs-8"; 

        const attributes = {
            labelKey: "name",
            defaultSelected: [defaultSelected],
            options: searchResults || [],
            onSearchAttr: (query) => commonActions.searchUser(query),
            placeholder: "Enter name..",
            onChangeAttr: this.onChange.bind(this),
            clearButton: clearButton,
            className: divWidth
        }

        return (
            <div className={'form-group ' + (labelExists ? 'form-search' : '')}>
                { labelExists && <label className="col-xs-4 control-label">{label}</label> }
                <BaseSearch attributes={attributes} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        searchResults: state.CommonReducer.userSearchResults && state.CommonReducer.userSearchResults.results
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);   