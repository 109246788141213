
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class CompensationTable extends Component {

    

    render() {
        const { tickets, t, onClick } = this.props;

        return <table className="table table-stripe table-hover">
        <thead>
            <tr>
                <th>{t('settingscompensationcontainer.customernumber')}</th>
                <th>{t('settingscompensationcontainer.product')}</th>
                <th>{t('settingscompensationcontainer.compensationreason')}</th>
            </tr>
        </thead>
        <tbody>
            { tickets.map((ticket, index) => {
                return <tr key={index} onClick={() => onClick(ticket)}>
                            <td>{ticket.customerNumber}</td>
                            <td>{ticket.product}</td>
                            <td>{ticket.compensationReason}</td>
                        </tr>
            }) }
        </tbody>
    </table>
    }
}

export default withTranslation()(CompensationTable);
