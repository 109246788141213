import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import commonActions from '../Actions/commonActions';

function ProductOverviewItem(
  {
    id,
    productName,
    productOwner,
    handleActiveProduct,
    selectedProductId,
    sku,
    imageContent,
    commonActions,
    handleMoreInformation,
    t
  }
  ) {
    
  return (
    <>
    <div className={'productItemBox'}>
      <div className={`thumbnail ${selectedProductId === id ? "activeProduct": null}`}>
        <div className='imgBox'>
          <img src={!imageContent ? "http://placehold.jp/100x100.png" : `data:image/png;base64,${imageContent}`} alt="..." className='ProductOverviewItemImage'/>
        </div>
          
        <div className="caption">
          <h3 className='ProductOverviewItemTitle' onClick={() => handleActiveProduct(id)}>{`${productName} (${sku})`}</h3>
          <div>
            <p><strong>{t("productOverview.productOwner")}:</strong><br /> {productOwner}</p>
            <p className='ProductOverviewItemTitle' onClick={() => {
              commonActions.showModal(true);
              handleMoreInformation(id);
            }}>{t("productOverview.moreInformation")}</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    selectedProductId: state.ProductOverviewReducer.selectedProductId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductOverviewItem));
