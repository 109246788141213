import React from 'react';
import { Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import MyActionLogContainer from '../../Containers/MyActionLogContainer'
const MyActionLogLayout = () => {
    const {t} = useTranslation()
    return(
        <div className="row">
            <div className="col-xs-12">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">{t('myactionloglayout.myactions')}</h3>
                    </div>
                    <div className="panel-body">
                        <Routes>
                            <Route index element={<MyActionLogContainer/>} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
)};

export default MyActionLogLayout;