import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonDropdown from '../ButtonDropdown'

const AppointmentDetailActions = ({ onClick, onNoAnswerClick, onBuyClick, hasCustomer, leadType, onBusyClick }) => {
    const {t} = useTranslation();
    return <div className="panel panel-primary leads-panel">
        <div className="panel-body text-center">
            <legend className="text-left">{t('appointmentdetailactions.actions')}</legend>
            <button className="btn btn-default" style={{marginRight: "10px"}} onClick={() => onBusyClick()}>{t('appointmentdetailactions.busy')}</button>
            <button className="btn btn-default" style={{marginRight: "10px"}} onClick={() => onNoAnswerClick()}>{t('appointmentdetailactions.noanswer')}</button>
            <button className="btn btn-default" onClick={() => onClick("showSendEmailModal")}>{t('appointmentdetailactions.sendemail')}</button>
            {leadType !== "aoLead" && <ButtonDropdown title={t('appointmentdetailactions.buy')} disabled={!hasCustomer} options={[{ title: 'appointmentdetailactions.placeorder', identifier: "order" }, { title: 'appointmentdetailactions.close', identifier: "close" }]} onClickAction={onBuyClick} defaultStyle={true}>{'appointmentdetailactions.buy'}</ButtonDropdown> }   
            {leadType === "aoLead" && <button className="btn btn-default" onClick={() => onBuyClick("close")}>{t('appointmentdetailactions.buy')}</button>}
            <button className="btn btn-default" style={{marginLeft: "10px"}} onClick={() => onClick("showCloseModal")}>{t('appointmentdetailactions.close')}</button>
        </div>
    </div>
}

export default AppointmentDetailActions;