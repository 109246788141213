import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {FormInput} from "../FormComponents/Input/Input";
import BaseSearch from "../BaseSearch";
import commonActions from "../../Actions/commonActions";

function UserSearchNew({ readonly, searchResults, commonActions, clearButton, defaultSelected, className, onChange }) {

    function handleOnChange(object) {
        const newValue = object.email || '';

        onChange(newValue);
    }

    if(!defaultSelected || defaultSelected === null) {
        defaultSelected = ''
    }

    if (readonly) {
        const val = (typeof defaultSelected === 'object')
            ? (defaultSelected.name || defaultSelected.email)
            : defaultSelected

        return <FormInput  label={this.props.label} value={val} />
    }

    const attributes = {
        labelKey: "name",
        defaultSelected: [defaultSelected],
        options: searchResults || [],
        onSearchAttr: (query) => commonActions.searchUser(query),
        placeholder: "Enter name..",
        onChangeAttr: (object) => handleOnChange(object),
        clearButton: clearButton,
        className: className ? className : 'form-group search-group search-group-button px-0',
    }
    return <BaseSearch attributes={attributes} />;
}

function mapStateToProps(state) {
    return {
        searchResults: state.CommonReducer.userSearchResults && state.CommonReducer.userSearchResults.results
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchNew);