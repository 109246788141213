import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import StaticTextInput from '../../Components/StaticTextInput'
import BlikkAdditionalUsers from '../../Components/Order/BlikkAdditionalUsers'
import helper from '../../Utils/helpers'
import LeadsOrderDropdown from '../../Components/Leads/LeadsOrderDropdown'
import AppointmentOrderDropdown from '../Appointment/AppointmentOrderDropdown'
import MessageInfoLevel from '../../Components/MessageInfoLevel'
import Checkbox from '../../Components/Checkbox'
import orderActions from '../../Actions/orderActions'
import commonActions from '../../Actions/commonActions'
import BlikkTransactions from '../../Components/Order/BlikkTransactions';

class BlikkProductDetails extends Component {


    componentWillUnmount() {
        this.props.genericActions.clearBlikkUserLicenses();
        this.props.genericActions.clearBlikkTransactions();
    }

    onAdditionalUserChange(licenseTypes) {
        this.props.genericActions.changeBlikkUserLicenses(licenseTypes)
    }

    onToggleReceiptInterpretation(receiptInterpretation){
        const { customer, orderId, product } = this.props

        const licenseTypes = product.licenseTypes;
        const currentLicenseType = licenseTypes.find(item => item.id === "NumberOfKvittotolkning");

        const count = receiptInterpretation ? 1 : 0;
        currentLicenseType.count = count;

        const index = product.licenseTypes.findIndex(item => item.id === "NumberOfKvittotolkning");
        licenseTypes[index] = currentLicenseType;

        this.onAdditionalUserChange(licenseTypes)
        this.props.genericActions.toggleReceiptInterpretation(receiptInterpretation)

        this.props.genericActions.getBlikkProductDetails(product.productId, customer.customerNumber, orderId, product.tenantGuid);
    }

    onTransactionsChange(transactionTypes) {
        this.props.genericActions.changeBlikkTransactions(transactionTypes)
    }

    onAdditionalUserSave() {
        const { customer, orderId, product } = this.props
        this.props.genericActions.getBlikkProductDetails(product.productId, customer.customerNumber, orderId, product.tenantGuid);
    }

    onAssignedLeadChange = (value) => {
        //This is not used for Blikk
        this.props.genericActions.changeAssignedLead(value);
    }

    onAssignedAppointmentChange = (value) => {
        //This is not used for Blikk
        this.props.genericActions.changeAssignedAppointment(value);
    }

    onFieldChange(field, value) {
        const { commonActions, form } = this.props;

        commonActions.changeMultiFormField(form.id, field, value);
    }

    reloadFromBlikk() {
        const { customer, orderId, product } = this.props
        this.props.genericActions.getBlikkProductDetails(product.productId, customer.customerNumber, orderId, product.tenantGuid, true);
    }

    render() {
        const { product, form } = this.props;

        if (this.props.priceCalcType === "order" && form == null)
            return null;

        const licenseTypes = product.licenseTypes;
        const kvittotolkning = licenseTypes.find(item => item.id === "NumberOfKvittotolkning");

        return <div>
            <div className="row">
                <div className="col-xs-12">
                    <fieldset >
                        <legend>Blikk customer info</legend>
                        <StaticTextInput label="Name" value={product.customerName} />
                        <StaticTextInput label="Customer number" value={product.erpCustomerNumber} />
                        {product.isTrial && <StaticTextInput label="Trial start date" value={helper.formatDate(product.trialStartDate)} />}
                        {product.isTrial && <StaticTextInput label="Trial end date" value={helper.formatDate(product.trialEndDate)} />}
                        <StaticTextInput label="Main contact" value={product.primaryContactEmail} />
                        <StaticTextInput label="Edition" value={product.edition} />
                        {product.proEvaluationEndDate && <StaticTextInput label="Pro evaluation end date" value={helper.formatDate(product.proEvaluationEndDate)} />}
                        <button className="btn btn-default" onClick={() => this.reloadFromBlikk()}>Reload from Blikk</button>

                        {product.trialEndDateHasPassed && product.isTrial && <div><MessageInfoLevel message={`Trial end date has passed with ${product.daysAfterTrialEndDate} days`} /></div>}


                    </fieldset>
                    <fieldset>
                        <legend>Product info</legend>
                        <BlikkAdditionalUsers product={product} licenseTypes={this.props.licenseTypes} onChange={this.onAdditionalUserChange.bind(this)} onSave={this.onAdditionalUserSave.bind(this)} />
                        {kvittotolkning && <div className="form-group">
                            <label className="col-xs-4 control-label">{kvittotolkning.name}</label>
                            <div className="col-xs-8 blikk-checkbox-container">
                                <Checkbox checked={product.receiptInterpretation} disabled={product.userHasReceiptInterpretation} value={product.receiptInterpretation} onChange={(element) => { this.onToggleReceiptInterpretation(element.checked) }} style={{ marginLeft: 20, marginTop: 5 }} />
                            </div>
                            <label className="col-xs-4 control-label blikk-checkbox-label">{helper.currencyFormatter(kvittotolkning.initialPrice)} / year</label>
                        </div>
                        }
                        <BlikkTransactions product={product} transactionTypes={this.props.transactionTypes} onChange={this.onTransactionsChange.bind(this)} onSave={this.onAdditionalUserSave.bind(this)} />
                        <StaticTextInput label="Agreement period" value={helper.formatDate(product.currentPeriodStartDate) + " - " + helper.formatDate(product.currentPeriodEndDate)} />
                        <StaticTextInput label="Active users" value={product.activeUsers} />
                        <LeadsOrderDropdown onChange={this.onAssignedLeadChange} leadId={product.leadId} currentlyInBasket={product.currentlyInBasket} />
                        <AppointmentOrderDropdown onChange={this.onAssignedAppointmentChange} appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
                    </fieldset>

                </div>
            </div>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,
        templateOrganizations: state.OrderReducer.templateOrganizations,
        licenseTypes: state.OrderReducer.blikkLicenseTypes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlikkProductDetails);