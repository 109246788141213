import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next';
import leadActions from '../../Actions/leadActions'
import { useNavigate, useParams } from "react-router-dom";
import LeadsList from '../../Components/Leads/LeadsList'
import routes from '../../Constants/routes'
import AoCustomerLeadsList from '../../Components/Leads/AoCustomerLeadsList';
import commonActions from '../../Actions/commonActions'
import Panel from "../../Components/Panel";

function CustomerLeadsContainer ({ leadActions, commonActions, leads, aoLeads }) {

    const navigate = useNavigate();
    const { customerNumber } = useParams();
    const { t } = useTranslation();
    
    useEffect(() => {
        leadActions.getCustomerLeads(customerNumber);
        leadActions.getAoCustomerLeads(customerNumber);
    }, [])
    
    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    const moveToDetails = (leadId) => {
        navigate(routes.leadDetails(leadId));
    }

    const moveToDetailsAo = (leadId) => {
        navigate(routes.aoleadDetails(leadId));
    }

    if (!leads && !aoLeads) {
        return null;
    }
    
    const hasNoData = () => {
        // have neither leads nor aoLeads
        return leads && !leads.length && aoLeads && !aoLeads.length;
    }
    
    return <Panel title={t('leftnavigation.leads')} isNoData={hasNoData()}>
        {leads && leads.length > 0 && <LeadsList leads={leads} hideCustomer={true} useGoBack={true} moveToDetails={moveToDetails} sortListByKey={sortListByKey("customerLeads", leads)} />}
        {aoLeads && aoLeads.length > 0 && <AoCustomerLeadsList leads={aoLeads} hideCustomer={true} useGoBack={true} moveToDetails={moveToDetailsAo} />}
    </Panel>
}


function mapStateToProps(state) {
    return {
        leads: state.LeadsReducer.customerLeads,
        aoLeads: state.LeadsReducer.aoCustomerLeads
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLeadsContainer);