import React, { Component } from 'react';

import MySignatureContainer from '../../Containers/Leads/MySignatureContainer'
import MyTemplatesContainer from '../../Containers/Leads/MyTemplatesContainer'

class MySettingsContainer extends Component {

    render() {
        return <>
            <MySignatureContainer history={this.props.history} />
            <MyTemplatesContainer history={this.props.history} />
        </>

    }
}

export default MySettingsContainer;