import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import customerActions from '../../../Actions/customerActions'
import commonActions from '../../../Actions/commonActions'

import withModalForm from '../../../Components/HOC/withModalForm'

import BlikkProducts from '../../../Components/Customer/BlikkProducts'
import ProductTerminationModal from '../../../Components/Customer/ProductTerminationModal'
import CompanyExpenseAddAdminUserModel from '../../../Components/Customer/CompanyExpenseAddAdminUserModel'
import SubscriptionCard from '../../../Components/Card/SubscriptionCard';
import InactiveProducts from '../../../Components/Products/InactiveProducts';
import Tooltip, {TooltipContent, TooltipTrigger} from '../../../Components/Overlays/Tooltip';
import helpers from "../../../Utils/helpers";

class ThirdPartyProductsContainer extends Component {

    componentDidMount() {
        const { customerActions, vbCustomer } = this.props;

        customerActions.getPendingTerminationRequests(vbCustomer.customerNumber);
        customerActions.getBlikkProducts(vbCustomer.customerNumber);
    }

    refreshProducts() {
        const { companyExpenseProductLoading, companyExpenseCustomerNumber, blikkLoading, blikkCustomerNumber } = this.props;
        const { customerActions, vbCustomer } = this.props;

        if (!blikkLoading && vbCustomer.customerNumber != blikkCustomerNumber && blikkCustomerNumber != undefined) {
            customerActions.getBlikkProducts(vbCustomer.customerNumber);
        }

        if (!companyExpenseProductLoading && vbCustomer.customerNumber != companyExpenseCustomerNumber && companyExpenseCustomerNumber != undefined) {
            customerActions.getCompanyExpenseProducts(vbCustomer.customerNumber, vbCustomer.organizationNumber);
        }
    }

    render() {

        const { blikkProducts, blikkResponse, blikkLoading } = this.props;
        const { vbCustomer, terminationForm, reasons, customerActions, pendingTerminationRequests, adminUserForm } = this.props;
        const { payload, isModalActive, onFieldChange, onCloseClick, onOpenModal } = this.props;

        const { t } = this.props;


        let showBlikkProducts = true;
        let showBlikkNoResonseMessage = false;

        const renderThirdPartyProductsNoResponse = (productName) => {
            return (<div className="d-flex justify-content-around">
                <Tooltip>
                    <TooltipTrigger>
                        {<span className={'pl-8 pr-16 pt-4 vismaicon vismaicon vismaicon-sm vismaicon-filled vismaicon-' + "warning"} aria-hidden="true" />}
                    </TooltipTrigger>
                    <TooltipContent>
                        <strong>{t("thirdpartyproductscontainer.cantaccess") + productName}</strong> <span>{t("thirdpartyproductscontainer.message")}</span>
                    </TooltipContent>
                </Tooltip>
            </div>)
        }

        if (!blikkLoading && !blikkResponse)
            showBlikkNoResonseMessage = true;

        if (!blikkProducts || blikkProducts.length === 0 || blikkLoading)
            showBlikkProducts = false;

        var terminationModalAttr = {
            form: terminationForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.getCompanyExpenseTerminationTemplate(vbCustomer.customerNumber, payload),
            onSave: () => customerActions.cancelCompanyExpenseAgreement(terminationForm, () => onCloseClick(terminationForm.id))
        }

        var addAdminUserData = {
            customerNumber: vbCustomer.customerNumber,
            email: "",
            firstName: "",
            lastName: "",
            langCode: "sv",
            languageList: [{ id: "sv", name: "Swedish" }, { id: "en", name: "English" }]
        };

        var addAdminUserModalAttr = {
            form: adminUserForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.showAddCompanyExpenseAdminUserEditForm(addAdminUserData),
            onSave: () => customerActions.addCompanyExpenseAdminUser(adminUserForm, () => onCloseClick(adminUserForm.id))
        }

        function getHeaderContent() {
            if (showBlikkNoResonseMessage)
                return renderThirdPartyProductsNoResponse("Visma Tid Smart or Pro"); {
            }
        }

        function mapInactiveProducts() {
            let invalidProducts = blikkProducts && blikkProducts.filter((product) => product.canceledDate)

            if(!invalidProducts) {
                return [];
            }

            return invalidProducts.map(product => {
                return {
                    product: product.productDescription,
                    purchaseDate: helpers.formatDate(product.activationDate),
                    endDate: helpers.formatDate(product.currentPeriodEndDate)
                }
            })
        }

        return <>
            <SubscriptionCard
                title={t("thirdpartyproductscontainer.thirdpartyproducts")}
                headerContent={getHeaderContent()}
                activeContent={showBlikkProducts && <BlikkProducts products={blikkProducts.filter((product) => !product.canceledDate)}/>}
                inactiveContent={<InactiveProducts products={mapInactiveProducts()}/>}
                isLoading={blikkLoading}
            >
                {isModalActive('terminate') && <ProductTerminationModal {...terminationModalAttr} />}
                {isModalActive('adminuser') && <CompanyExpenseAddAdminUserModel {...addAdminUserModalAttr} />}
                {!showBlikkProducts && !blikkLoading && <h4>{t("thirdpartyproductscontainer.nothirdpartyproducts")}</h4>}
            </SubscriptionCard>
        </>
    }
}

function mapStateToProps(state) {
    return {
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.CancelCompanyExpenseAgreement,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['SaasTerminationReasons'],
        vbCustomer: state.CustomerReducer.selectedCustomer,
        pendingTerminationRequests: state.CustomerReducer.pendingTerminationRequests,
        adminUserForm: state.CommonReducer.forms && state.CommonReducer.forms.addCompanyExpenseAdminUser,
        blikkProducts: state.CustomerReducer.blikkProducts,
        blikkResponse: state.CustomerReducer.blikkResponse,
        blikkLoading: state.CommonReducer.dataLoading.blikkData,
        blikkCustomerNumber: state.CustomerReducer.blikkCustomerNumber,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ThirdPartyProductsContainer)));