import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import RoleTable from "../../Components/Permission/RoleTable";

function AdminUsersEditContainer({ settingsActions, form, commonActions, t, userRoles }) {

    const { id, type } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (type === "user") {
            settingsActions.getUserForRoleEdit(id);
        } else {
            settingsActions.getTeam(id);
        }

        return () => {
            commonActions.cancelFormEditing(form?.id);
        };
    }, []);

    const onSaveClick = () => {
        settingsActions.updateRole(form, id, type, () => navigate(routes.settingsAdminUsers));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.settingsAdminUsers))
    }

    const onChange = (value, item, list, idType, formId) => {
        const newList = [...list];
        const index = newList.findIndex(x => x[idType] === item[idType]);

        if (!value) {
            if (index !== -1) {
                newList.splice(index, 1);
            }
        } else {
            if (index !== -1) {
                newList[index].access = value;
            } else {
                const newItem = { ...item, access: value };
                newList.push(newItem);
            }
        }

        commonActions.changeMultiFormField(form.id, formId, newList);
    };

    const data = form && form.data;

    if (!data)
        return null;

    return (
        <div>
            <form className="form-horizontal">
                <fieldset className="col-12">
                    <StaticTextInput label={t("adminusereditcontainer.name")} value={data.name} />
                    {type === "user" && <StaticTextInput label={t("adminusereditcontainer.email")} value={data.email} />}
                </fieldset>
                {userRoles &&
                    <><h2>{t('adminusereditcontainer.roles')}</h2>
                    <RoleTable roles={userRoles} data={data} onChange={onChange} /></>}
            </form>
            <div className="form-group padding-top no-left-margin btn-toolbar">
                <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("adminusereditcontainer.save")}</button>
                <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("adminusereditcontainer.cancel")}</button>
                <Loader />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.adminUserEdit,
        userRoles: state.SettingsReducer && state.SettingsReducer.userRoles
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminUsersEditContainer));