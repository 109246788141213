import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions';
import BaseSearch from '../BaseSearch';

function CustomerSearchNew({ id, placeholder, className, onChange, searchResults, autoFocus, commonActions, t, defaultSelected, disabled = false }) {
    const ref = useRef(null);

    useEffect(() => {
        if (autoFocus && ref.current && ref.current.getInput()) {
            ref.current.getInput().focus()
        }
    }, [ref && ref.current])

    function handleOnChange(object) {
        const customerNumber = object.customerNumber || '';
        const customerName = object.name || '';
        const organizationNumber = object.organizationNumber || '';
        const group = object.group || '';
        const email = object.email || '';

        onChange(customerNumber, customerName, organizationNumber, group, email);
    }

    let attributes = {
        labelKey: option => `${option.name} / ${option.customerNumber} / ${option.organizationNumber} / ${option.phoneNumber}`,
        options: searchResults || [],
        onSearchAttr: (query) => commonActions.searchCustomer(query),
        placeholder: !placeholder ? t('customersearch.placeholder') : placeholder,
        onChangeAttr: handleOnChange.bind(this),
        id: id ? id : 'customerSearch',
        className: className ? className : 'form-group search-group search-group-button px-0', 
        ref: ref,
        disabled: disabled
    }

    if (defaultSelected && defaultSelected.name)
        attributes.defaultSelected = [defaultSelected];

    return <> <BaseSearch attributes={attributes} /> </>

}

function mapStateToProps(state) {
    return {
        searchResults: state.CommonReducer.customerSearchResults && state.CommonReducer.customerSearchResults.results,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSearchNew));