import React, {useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import commonActions from '../Actions/commonActions';
import helpers from '../Utils/helpers';

function ProductVersionRow({version, editable, commonActions, productOverviewActions}) {
    const handleVersionRowClick = (version) => {
        if(editable){
            commonActions.startFormEditing("productVersionForm", {...version});
            return;
        }
    }

  return (
    <>
        <tr>
            <td>{version.version}</td>
            <td>{version.serialNumber ? "Yes" : ""}</td>
            <td>{helpers.calculateByteToRightFormat(version.fileSize)}</td>
            <td>{helpers.formatDate(version.releaseDate)}</td>
            <td><span className={`column-fix vismaicon vismaicon-${version.sendVersion === true ? 'ok-circle': 'cancel-circle'}`}></span></td>
            {editable && <td><span className='table-data-deleteable vismaicon vismaicon-edit' onClick={() => handleVersionRowClick(version)}/></td>}
            {editable && <td><span className='table-data-deleteable vismaicon vismaicon-delete' onClick={() => productOverviewActions.deleteProductVersion({id: version.id, productId: version.productId, version: version.version})}/></td>}
        </tr>
    </>
    )
}

function mapStateToProps(state) {
    return {
      productVersionList: state.ProductOverviewReducer.productVersionList,
      selectedProductId: state.ProductOverviewReducer.selectedProductId,
    }
  }
  
function mapDispatchToProps(dispatch) {
return {
    productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductVersionRow));