import React, { Component } from 'react';
import PastActionInfo from '../PastActionInfo'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import customerActions from '../../Actions/customerActions'
import { connect } from 'react-redux';
import helpers from '../../Utils/helpers'

class CompensationBlikkProducts extends Component {

    onClick(form, item){
        var product = {  productNumber: item.productDescription, product: item.productDescription, isBlikk: true }
        this.props.onClick(form, product)
    }

    render() {
        const { products, t } = this.props
        let key = 1;
        
        return products &&
            <fieldset className="col-xs-12">
                <legend>{t('compensationblikkproduct.header')}</legend>
                <div className="table-container">
                    <table className="table table-stripe table-hover table-saas-products">
                        <thead>
                            <tr>
                                <th className="text-left">{t('compensationblikkproduct.program')}</th>
                                <th width="130" className="text-left">{t('compensationblikkproduct.purchasedate')}</th>
                                <th width="130" className="text-left">{t('compensationblikkproduct.enddate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(item =>
                                <tr key={key++} onClick={() => this.onClick('compensation', item)}>
                                    <td className="text-left">{item.productDescription}</td>
                                    <td className="text-left">{helpers.formatDate(item.activationDate)}</td>
                                    <td className="text-left">{helpers.formatDate(item.currentPeriodEndDate)}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompensationBlikkProducts));