import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import moment from "moment";

import MyStatsGraph from "../MyStatsGraph";

import {
  handleClick,
  handleDataFromTime,
  countOccurrence,
  getReferenceValue,
} from "./graphHelpers";
import commonActions from "../../Actions/commonActions";
import settingsActions from "../../Actions/settingsActions";

class TransactionServiceData extends Component {
  state = {
    data: [],
    timePeriod: "month",
    budgetData: [],
    month: moment().format("MMMM"),
    year: moment().format("yyyy"),
  };

  async componentDidMount() {
    const { user, commonActions } = this.props;
    await commonActions.getTransactionServiceBySalesNumber(user.salesNumber);
    /* await this.props.settingsActions.getUserBudgetById(user.id); */

    this.setState({
      ...this.state,
      data: handleDataFromTime(
        this.props.transactionData,
        moment().startOf("month"),
        moment().endOf("month"),
        "activationDate"
      ),
    });
  }

  clickFunc = (event) => {
    const { value } = event.target;

    this.setState({
      ...this.state,
      data: handleClick(event, this.props.transactionData, "activationDate"),
    });

    this.setState((state) => ({ timePeriod: value }));
  };

  render() {
    const { transactionData, userBudget, t } = this.props;

    if (!transactionData) {
      return null;
    }

    const referenceValue = userBudget
      ? countOccurrence(this.state.data, "name").map(() =>
          getReferenceValue(userBudget, this.state.timePeriod)
        )
      : null;

    return (
      <MyStatsGraph
        typeOfGraph='Bar'
        onClick={(event) => this.clickFunc(event)}
        title={t("graph.transactionServicesIndividual")}
        labels={countOccurrence(this.state.data, "name").map(
          ({ name }) => name
        )}
        data={countOccurrence(this.state.data, "name").map(
          ({ occurrence }) => occurrence
        )}
        backgroundColor={["rgb(9, 116, 179)"]}
        timePeriod={this.state.timePeriod}
        reference={
          referenceValue && referenceValue.every((value) => value === 0)
            ? null
            : referenceValue
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.CommonReducer.user,
    transactionData: state.CommonReducer.transactionServices,
    testData: state.GraphReducer.tranData,
    userBudget: state.SettingsReducer.budgetUserData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TransactionServiceData));
