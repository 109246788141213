import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import TextInput from '../../Components/TextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import SwitchToggle from "../../Components/SwitchToggle";


function HittaPartnerEditContainer({ settingsActions, form, commonActions, partnerCompetences, partnerBusinesses, t }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getEpiPartner(id);
    }, [])

    const onSaveClick = () => {
        settingsActions.updateEpiPartner(form, () => navigate(routes.hittaPartner));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.hittaPartner))
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const onToggleCheckbox = (field) => {
        const value = !form.data[field]
        onFieldChange(field, value)
    }

    const onTogglePartnerCheckbox = (item, list, idType, formId) => {
        if (isCheckboxActive(item, list, idType)) {
            var index = list.findIndex(x => x[idType] === item[idType])
            list.splice(index, 1)
        } else {
            list.push(item)
        }
        commonActions.changeMultiFormField(form.id, formId, list)
    }

    const isCheckboxActive = (item, list, idType) => {
        if (list.find(x => x[idType] === item[idType])) {
            return true
        } else {
            return false
        }
    }
    const data = form && form.data;

    if (!data) {
        return null;
    }

    return (
        <div>
            <form className="row form-horizontal">
                <fieldset className="col-xs-6">
                    <StaticTextInput label={t("hittapartnereditcontainer.customernumber")} value={data.customerNumber} />
                    <StaticTextInput label={t("hittapartnereditcontainer.organizationnumber")} value={data.organizationNumber} />
                    <StaticTextInput label={t("hittapartnereditcontainer.address")} value={data.address} />
                    <StaticTextInput label={t("hittapartnereditcontainer.postcode")} value={data.postCode} />
                    <StaticTextInput label={t("hittapartnereditcontainer.city")} value={data.city} />
                    <TextInput label={t("hittapartnereditcontainer.name")} value={data.name} disabled={data.updateFromVB} onChange={(value) => onFieldChange("name", value)} />
                    <TextInput label={t("hittapartnereditcontainer.manualaddress")} value={data.manualAddress} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualAddress", value)} />
                    <TextInput label={t("hittapartnereditcontainer.manualpostcode")} value={data.manualPostCode} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualPostCode", value)} />
                    <TextInput label={t("hittapartnereditcontainer.manualcity")} value={data.manualCity} disabled={data.updateFromVB} onChange={(value) => onFieldChange("manualCity", value)} />
                    <TextInput label={t("hittapartnereditcontainer.url")} value={data.url} disabled={data.updateFromVB} onChange={(value) => onFieldChange("url", value)} />
                    <TextInput label={t("hittapartnereditcontainer.phone")} value={data.phone} disabled={data.updateFromVB} onChange={(value) => onFieldChange("phone", value)} />
                    <TextInput label={t("hittapartnereditcontainer.mobilephone")} value={data.mobilePhone} disabled={data.updateFromVB} onChange={(value) => onFieldChange("mobilePhone", value)} />
                    <TextInput label={t("hittapartnereditcontainer.email")} value={data.email} disabled={data.updateFromVB} onChange={(value) => onFieldChange("email", value)} />
                    <div className="form-group">
                        <label className="col-xs-4 control-label">{t('hittapartnereditcontainer.visible')}</label>
                        <div className="col-xs-8">
                            <SwitchToggle checked={data.visible} value={data.visible} onChange={() => onToggleCheckbox("visible")} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-xs-4 control-label">{t('hittapartnereditcontainer.updatefromvb')}</label>
                        <div className="col-xs-8">
                            <SwitchToggle checked={data.updateFromVB} value={data.updateFromVB} onChange={() => onToggleCheckbox("updateFromVB")} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-xs-6">

                    {partnerCompetences &&
                        <>
                            <h2>{t('hittapartnereditcontainer.competences')}</h2>
                            {partnerCompetences.map(competence =>
                                <div className="form-group">
                                    <label className="col-xs-4 control-label">{competence.competence}</label>
                                    <div className="col-xs-8">
                                        <SwitchToggle checked={isCheckboxActive(competence, form.data.competences, 'competenceId')} value={competence} onChange={() => onTogglePartnerCheckbox(competence, form.data.competences, 'competenceId', 'competences')} />
                                    </div>
                                </div>
                            )}
                        </>}
                    {partnerBusinesses &&
                        <>
                            <h2>{t('hittapartnereditcontainer.business')}</h2>
                            {partnerBusinesses.map(business =>
                                <div className="form-group">
                                    <label className="col-xs-4 control-label">{business.business}</label>
                                    <div className="col-xs-8">
                                        <SwitchToggle checked={isCheckboxActive(business, form.data.businesses, 'businessId')} value={business} onChange={() => onTogglePartnerCheckbox(business, form.data.businesses, 'businessId', 'businesses')} />
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </fieldset>
            </form>

            <div className="form-group padding-top no-left-margin btn-toolbar">
                <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("hittapartnereditcontainer.save")}</button>
                <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("hittapartnereditcontainer.cancel")}</button>
                <Loader />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.epiPartnerEdit,
        partnerCompetences: state.SettingsReducer && state.SettingsReducer.partnerCompetences,
        partnerBusinesses: state.SettingsReducer && state.SettingsReducer.partnerBusinesses
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HittaPartnerEditContainer));