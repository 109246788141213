import React from 'react';
import MessageAlertLevel from '../MessageAlertLevel'
import { useTranslation } from 'react-i18next';

const CompensationVbProducts = ({products, onClick}) => {
    const {t} = useTranslation();
    return  <fieldset className="col-xs-12 vbProducts">
                <legend>{t('compensationvbproducts.onpremproducts')}</legend>
                <div className="table-container">
                <table className="table table-stripe table-hover">
                    <thead>
                        <tr>
                            <th width="250" className="text-left">{t('compensationvbproducts.program')}</th>
                            <th width="100" className="text-left">{t('compensationvbproducts.purchasedate')}</th>
                            <th width="100" className="text-left">{t('compensationvbproducts.enddate')}</th>
                            <th width="100" className="text-left">{t('compensationvbproducts.users')}</th>
                            <th width="100" className="text-left">{t('compensationvbproducts.inactiveusers')}</th>
                            <th width="100" className="text-left">{t('compensationvbproducts.api')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.filter(item => item.isProductValid).map((item, index) => {
                            const purchaseDate = item.purchaseDate === '0001-01-01' ? 'N/A' : item.purchaseDate;
                            const endDate = item.endDate === '0001-01-01' ? 'N/A' : item.endDate;
                            return <tr key={index} onClick={() => onClick('compensation', item)}>
                                <td className="text-left">{item.product}</td>
                                <td className="text-left">{purchaseDate}</td>
                                <td className="text-left">{!item.isProductValid ? <span style={{color: "red"}}>{endDate}</span> : endDate}</td>
                                <td className="text-left">{item.users}</td>
                                <td className="text-left">{item.inactiveUsers}</td>
                                <td className="text-left">{item.api}</td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
                </div>
            </fieldset>
}

export default CompensationVbProducts;