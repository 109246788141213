import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VismaLogo from '../../Images/visma-logo-white.svg'
import VismaBallLogo from '../../Images/VismaBall.svg'
import commonActions from "../../Actions/commonActions";
import WebSocketHandler from "../../Utils/WebSocketHandler";

const playerHeight = 110

const canvasHeight = 600
const canvasWidth = 800

class Canvas extends Component {

    constructor(props) {
        super(props);
        this.canvas = createRef();
        this.img = createRef();
        this.ballImg = createRef();
        this.initState = {
            ballX: 400,
            ballY: 300,
            intervalId: -1,
            player1Y: 250,
            player2Y: 250,
            player1Points: 0,
            player2Points: 0,
            lastSentYPosition: -100,
            sendingYPosition: false
        }

        this.state = this.initState;
    }

    componentDidMount() {
        const { user } = this.props;

        this.socket = new WebSocketHandler(message => this.messageCallback(this, message));
        this.socket.connect("pong", user.email);

        const ctx = this.canvas.current.getContext("2d");
        this.drawBaseCanvas(ctx);

        this.setState({
            intervalId: setInterval(() => {
                this.drawBaseCanvas(ctx)
            }, 10)
        });
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.sendMessage(JSON.stringify({
                type: "quitMessage",
                playerId: this.state.playerId,
                gameId: this.state.gameId
            }))
            this.socket.close()
            this.socket = null;
        }
        clearInterval(this.state.intervalId);
    }

    messageCallback(self, message) {
        if (self) {
            const s = JSON.parse(message);
            switch (s.MessageType ) {
                case 1:
                    self.setState({
                        gameId: s.GameId,
                        playerId: s.YouAreLeft ? 1 : 2,
                        player1Name: s.Player1Name,
                        player2Name: s.Player2Name
                    });
                    break;

                case 2:
                    if (self.state === "ended")
                        return;
                    if (self.state.playerId === 1)
                        self.setState({
                            ballX: s.BallPosition.x,
                            ballY: s.BallPosition.y,
                            player2Y: s.RightPlayer
                        });
                    else
                        self.setState({
                            ballX: s.BallPosition.x,
                            ballY: s.BallPosition.y,
                            player1Y: s.LeftPlayer
                        });
                    break;

                case 3:
                    self.setState({

                        player1Points: s.Player1Points,
                        player2Points: s.Player2Points,
                        ballX: s.BallPosition.x,
                        ballY: s.BallPosition.y
                    });
                    break;
                case 4:
                    self.setState({state: "ended", reason: s.Reason});
                    break;
            }
        }
    }

    drawBaseCanvas(ctx) {

        const x = 400;
        const y = 300;
        const radiusStart = 1;
        const radiusEnd = 600;
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        var grd = ctx.createRadialGradient(x, y, radiusStart, x, y, radiusEnd);
        grd.addColorStop(0, "#007ACA");
        grd.addColorStop(1, "#191919");

        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        ctx.drawImage(this.img.current, canvasWidth / 2 - this.img.current.width / 2, 570);

        ctx.beginPath();
        ctx.setLineDash([5, 15]);
        ctx.moveTo(400, 0);
        ctx.lineTo(400, 600);
        ctx.strokeStyle = 'white'
        ctx.lineWidth = 5
        ctx.stroke();
        this.drawCanvas(ctx);
    }

    drawCanvas(ctx) {
        this.drawMessage(ctx);
        this.drawScore(ctx, 0, 0)
        this.drawPlayers(ctx)
        this.drawBall(ctx);
    }

    drawMessage(ctx) {
        if(this.state.state !== "ended")
            return;

        const message = this.state.reason;

        ctx.font = "75px sans-serif"
        const textWidth =  ctx.measureText(message).width;

        if(textWidth > canvasWidth - 100)
            ctx.font = "30px sans-serif"

        ctx.fillStyle = "white"
        ctx.fillText(message, canvasWidth / 2 - ctx.measureText(message).width / 2, canvasHeight / 2);
        clearInterval(this.state.intervalId);
    }

    drawBall(ctx) {
        ctx.beginPath();
        ctx.drawImage(this.ballImg.current, this.state.ballX, this.state.ballY)

        ctx.fill();
        ctx.closePath();
    }

    drawScore(ctx) {
        const { player1Points, player2Points } = this.state

        ctx.font = "75px sans-serif"
        ctx.fillStyle = "white"
        ctx.fillText(player1Points, canvasWidth / 2 - ctx.measureText(player1Points).width / 2 - 50, 70)

        ctx.font = "75px sans-serif"
        ctx.fillStyle = "white"
        ctx.fillText(player2Points, canvasWidth / 2 - ctx.measureText(player2Points).width / 2 + 50, 70)
    }

    drawPlayers(ctx) {
        const { player1Y, player2Y, player1Name, player2Name } = this.state

        ctx.font = "20px sans-serif"
        ctx.fillStyle = "white"

        if (player1Name)
            ctx.fillText(player1Name, canvasWidth / 4 - ctx.measureText(player1Name).width / 2, 55)
        if (player2Name)
            ctx.fillText(player2Name, (canvasWidth / 4) * 3 - ctx.measureText(player2Name).width / 2, 55)

        ctx.fillRect(canvasWidth / 2 - 390, player1Y + 10, 15, playerHeight);

        ctx.fillRect(canvasWidth / 2 + 390 - 14, player2Y + 10, 15, playerHeight);
    }

    onMouseMove(e) {
        var rect = this.canvas.current.getBoundingClientRect();
        let playerY = (e.clientY - rect.top) / (rect.bottom - rect.top) * canvasHeight - (playerHeight / 2);
        if (this.state.playerId === 1)
            this.setState({ player1Y: playerY })
        else
            this.setState({ player2Y: playerY })

        if (this.state.sendingYPosition)
            return;

        if (this.state.lastSentYPosition !== playerY) {
            this.setState({sendingYPosition: true})
            setTimeout(() => {
                if (this.state.playerId === 1)
                    playerY = this.state.player1Y
                else
                    playerY = this.state.player2Y
                this.setState({sendingYPosition: false, lastSentYPosition: playerY})
                const message = {
                    type: "positionMessage",
                    playerId: this.state.playerId,
                    gameId: this.state.gameId,
                    y: playerY
                }
                this.socket.sendMessage(JSON.stringify(message))
            }, 40);
        }
    }

    render() {
        const { properties } = this.props;

        return (
            <div tabIndex="0" onMouseMove={this.onMouseMove.bind(this)}>
                <canvas className={"pong-canvas"} ref={this.canvas} width={properties.width} height={properties.height} />
                <img ref={this.img} src={VismaLogo} style={{ display: 'none' }} />
                <img ref={this.ballImg} src={VismaBallLogo} style={{ display: 'none' }} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);