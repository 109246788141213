import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import routes from '../../Constants/routes'
import LeadsList from '../../Components/Leads/LeadsList'
import ListSearch from '../../Components/ListSearch'
import MyOpenLeadsContainer from '../../Containers/Leads/MyOpenLeadsContainer'
import LeadDetailsContainer from '../../Containers/Leads/LeadDetailsContainer'
import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Panel from "../../Components/Panel";
import { withTranslation } from 'react-i18next';

function LeadsListContainer({ leads, searchResults, leadActions, lead, commonActions, t }) {

    const { leadId } = useParams();
    const navigate = useNavigate();
    const [searchClosedLeads, toggleSearchClosed] = useState(false)

    useEffect(() => {
        leadActions.getMyLeads();
        leadActions.getAvailableLeadTypes();

        reloadLead();
    }, [])


    const reloadLead = () => {
        if (leadId !== undefined)
            moveToDetails(leadId);
    }

    const moveToDetails = (leadId) => {
        leadActions.getLead(leadId, false, true);
        navigate(routes.leadDetails(leadId));
    }

    const onClearClick = () => {
        leadActions.clearSearch();
    }

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    if (!leads) {
        return null;
    }

    let listWidth = 7;

    if (!leadId && !lead)
        listWidth = 12;

    const detailsWidth = 12 - listWidth;

    return <>
        <div className="row">
            <div className={`col-${listWidth} p-0`}>
                <Panel>
                <button type="button" className={`btn btn-default ${!searchClosedLeads ? "active" : ""}`} onClick={() => toggleSearchClosed(!searchClosedLeads)}>{t("leadslistcontainer.openleads")}</button>
                <button type="button" className={`btn btn-default ${searchClosedLeads ? "active" : ""}`} onClick={() => toggleSearchClosed(!searchClosedLeads)}>{t("leadslistcontainer.closedleads")}</button>
                    <ListSearch onSearchClick={(searchPhrase) => leadActions.searchMyLeads(searchPhrase, searchClosedLeads)} onClearClick={onClearClick} results={searchResults} placeholder="Search by email, name, phone...">
                        <LeadsList leads={searchResults} key={321} activeLead={lead} moveToDetails={moveToDetails} sortListByKey={sortListByKey("myLeadSearchResults", searchResults)} />
                    </ListSearch>
                </Panel>
            </div>
            {lead && <div >
                <div className={`col-${detailsWidth} pl-0 pr-0 position-fixed overflow-y-scroll`} style={{ paddingBottom: "20rem", height: "90%" }}>
                    <LeadDetailsContainer lead={lead} navigate={navigate} moveToDetails={(leadId) => moveToDetails(leadId)} />
                </div>
            </div>
            }
        </div>
        <div className="row">
            <div className={`col-${listWidth} p-0`}>
                <MyOpenLeadsContainer activeLead={lead} moveToDetails={moveToDetails} />
            </div>

        </div>
    </>
}


function mapStateToProps(state) {
    return {
        lead: state.LeadsReducer.lead,
        leads: state.LeadsReducer.myLeads,
        leadTypes: state.LeadsReducer.leadTypes,
        searchResults: state.LeadsReducer.myLeadSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadsListContainer));