import { settingsActionTypes as actionTypes } from '../ActionTypes/settingsActionTypes'

export const initialState = {
    guidedSellingRules: [],
    guidedSellingRule: null,
    emailTemplateApproval: null,
    compensationSearchResults: null,
    aoCustomerSearchResults: null,
    epiPartners: null,
    epiPartnerSearchResults: null,
    invoiceProcessingParameters: {customerNumbers:[], timeOrDate: 'time', amountLimitSelected: true, amountLimit: "",
        tranSourceVoss: true, tranSourceVon: true}
}

export default function SettingsReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.GET_GUIDED_SELLING_RULES:
            newState = { guidedSellingRules: action.data }
            break;

        case actionTypes.GET_GUIDED_SELLING_RULE:
            newState = { guidedSellingRule: action.data }
            break;

        case actionTypes.STORE_EMAIL_TEMPLATES:
            newState = { emailTemplates: action.data }
            break;

        case actionTypes.STORE_EMAIL_TEMPLATE:
            newState = { emailTemplate: action.data }
            break;

        case actionTypes.STORE_EMAIL_TEMPLATE_APPROVAL:
            newState = { emailTemplateApproval: { approved: action.data.approved, approvalDate: action.data.approvalDate } }
            break;

        case actionTypes.GET_WEBINARS:
            newState = { webinars: action.data.webinars, webinarFilters: { products: action.data.products } }
            break;

        case actionTypes.STORE_WEBINAR:
            newState = { webinar: action.data }
            break;
            
        case actionTypes.STORE_EDIT_ROLE_MODEL:
            newState = { managedTeams: action.data.teams, users: action.data.users }
            break;
        
        case actionTypes.STORE_USERS:
            newState = { users: action.data }
            break;
        
        case actionTypes.STORE_USER_ROLES:
            newState = { userRoles: action.data }
            break;

        case actionTypes.STORE_CUSTOMER_AND_INFO_TYPES:
            newState = { settingsCustomerInfoTypes: action.data }
            break;

        case actionTypes.GET_COMPANY_EXPENSE_PARTNERS:
            newState = { companyExpensePartners: action.data }
            break;

        case actionTypes.STORE_COMPANY_EXPENSE_PARTNER:
            newState = { companyExpensePartner: action.data }
            break;

        case actionTypes.GET_COMPENSATION_TICKETS:
            newState = { compensationTickets: action.data }
            break;

        case actionTypes.GET_AO_CUSTOMERS:
            newState = { aoCustomers: action.data }
            break;

        case actionTypes.GET_EPI_PARTNERS:
            newState = { epiPartners: action.data }
            break;

        case actionTypes.STORE_SELECTED_EPI_PARTNER:
            newState = { epiPartner: action.data }
            break;

        case actionTypes.SEARCH_EPI_PARTNERS:
            newState = { epiPartnerSearchResults: action.data }
            break;

        case actionTypes.GET_ALL_PARTNER_COMPETENCES:
            newState = { partnerCompetences: action.data }
            break;

        case actionTypes.GET_ALL_PARTNER_BUSINESSES:
            newState = { partnerBusinesses: action.data }
            break;

        case actionTypes.STORE_SELECTED_TICKET:
            newState = { compensationTicket: action.data }
            break;

        case actionTypes.STORE_SELECTED_AO_CUSTOMER:
            newState = { aoCustomer: action.data }
            break;

        case actionTypes.STORE_DUPLICATE_AO_CUSTOMER:
            newState = { aoCustomer: action.data }
            break;

        case actionTypes.ASSIGN_TEMPLATE_RESPONSIBLE:
            const template = action.data
            const templates = state.emailTemplates

            const index = templates.findIndex(item => item.id === template.id);

            templates[index] = template;

            newState = { emailTemplates: [...templates] }
            break;

        case actionTypes.SEARCH_CLOSED_COMPENSATION_TICKETS:
            newState = { compensationSearchResults: action.data }
            break;

        case actionTypes.SEARCH_AO_CUSTOMERS:
            newState = { aoCustomerSearchResults: action.data }
            break;
        
        case actionTypes.GET_BUDGET_BY_USER:
            newState = { budgetUserData: action.data }
            break;

        case actionTypes.GET_BUDGET_BY_TEAM:
            newState = { budgetUserData: action.data }
            break;

        case actionTypes.GET_BUDGET_TEAM:
            newState = { budgetTeam: action.data }
            break;

        case actionTypes.GET_ALL_BUDGETS_BY_MONTH_YEAR:
            newState = { budgetData: action.data }
            break;

        case actionTypes.GET_USER:
            newState = { user: action.data }
            break;

        case actionTypes.GET_BUDGET_USER:
            newState = { budgetUser: action.data }
            break;

        case actionTypes.STORE_BUDGET_USERS:
            newState = { budgetUsers: action.data }
            break;

        case actionTypes.STORE_SERVICE_ADDRESSES:
            newState = { serviceAddresses: action.data }
            break;
        
        case actionTypes.STORE_BUDGET_TEAMS:
            newState = { budgetTeams: action.data }
            break;

        case actionTypes.GET_ALL_RELEASES:
            newState = { releaseNews: action.data }
            break;
            
        case actionTypes.STORE_RELEASE_VERSION_FORM:
            newState = { releaseNewsItem: action.data }
            break;
        case actionTypes.GET_ELOQUA_FIELDS:
            newState = { eloquaFields: action.data }
            break;
        case actionTypes.GET_ELOQUA_DEFINITIONS:
            newState = { eloquaDefinitions: action.data }
            break;
        case actionTypes.GET_ELOQUA_SYNC_LOGS:
            newState = { syncLogs: action.data }
            break;
        case actionTypes.UPDATE_ELOQUA_FIELD:
            const eloquaField = action.data
            const eloquaFields = state.eloquaFields;

            let fieldIndex = eloquaFields.findIndex(item => item.statement === eloquaField.statement);

            eloquaFields[fieldIndex] = eloquaField;

            newState = { eloquaFields: [...eloquaFields] }
            break;
        case actionTypes.GET_WORKER_JOBS:
            newState = { workerJobs: action.data }
            break;
        case actionTypes.STORE_ALL_FEATURE_FLAGS:
            newState = { allFeatureFlags: action.data }
            break;
        case actionTypes.STORE_ENABLED_FEATURE_FLAGS:
            newState = { enabledFeatureFlags: action.data }
            break;
        case actionTypes.STORE_INVOICE_PROCESSINGS:
            newState = { invoiceProcessings: action.data }
            break;
        case actionTypes.STORE_INVOICE_PROCESSING_PARAMETERS:
            newState = { invoiceProcessingParameters: { ...state.invoiceProcessingParameters, ...action.data } }
            break;
        default:
            return state

    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}