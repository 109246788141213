import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../Constants/routes";
import { Dropdown } from "../../FormComponents/Dropdown";
import { VossVersionStatus, VossVersionStatusText } from "../../../Constants/VOSS/vossVersionStatus";
import helpers from "../../../Utils/helpers";

export default function DiscountStatus({ status, ...props }) {
    const { discountAgreementVersions } = props;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const versionId = searchParams.get("VersionId")

    const onSelectVersion = (versionId) => {
        const params = new URLSearchParams();
        params.append("DiscountId", discountAgreementVersions.id);
        versionId && params.append("VersionId", versionId);
        navigate(routes.editDiscount + "?" + params.toString());
    }

    const renderStatus = () => {
        let label = VossVersionStatusText[status];
        let labelClass;

        switch (status) {
            case VossVersionStatus.Draft:
                labelClass = "text-primary";
                break;
            case VossVersionStatus.Staged:
                labelClass = "text-secondary";
                break;
            case VossVersionStatus.Published:
                labelClass = "text-success";
                break;
            case VossVersionStatus.Archived:
                labelClass = "text-warning";
                break;
            case VossVersionStatus.Discarded:
                labelClass = "text-error";
                break;
        }

        return <h1 className={`${labelClass} mb-0`}>{label}</h1>;
    };

    const prepareDropdownOptions = () => {
        if (!discountAgreementVersions) return [];
        return discountAgreementVersions.versions.map(version => {
            return {
                name: VossVersionStatusText[version.status] + ' - ' + helpers.formatDateAndTime(version.created),
                id: version.versionId
            };
        });
    }

    let options = prepareDropdownOptions();

    return <div className=" gap-24 d-flex flex-column">
        <div>
            <div class="card-list card-list-secondary">
                <div class="card-list-item">
                    <div class="description text-center">
                        <p>Current status:</p>
                        {renderStatus()}
                    </div>
                </div>
            </div>
        </div>
        {discountAgreementVersions && <div>
            <Dropdown label="Version history" options={options} value={versionId} disabled={options.length == 1} onChange={(value) => onSelectVersion(value)} />
        </div>}
    </div>
}