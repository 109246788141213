import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import helpers from '../../Utils/helpers'

class AttendedEducationsList extends Component {

    renderNoData() {
        const {t} = this.props
        return (<p>{t('attendededucationslist.noentriesfound')}</p>);
    }

    render() {

        const attendedEducations = this.props.attendedEducations;
        const {t} = this.props
        if (!attendedEducations) {
            return this.renderNoData()
        }

        return (
            <div className="col-xs-12">
                <table className="table table-stripe table-invoices">
                    <thead>
                        <tr>
                            <th className="text-left">{t('attendededucationslist.participant')}</th>
                            <th className="text-left">{t('attendededucationslist.course')}</th>
                            <th className="text-left">{t('attendededucationslist.startdate')}</th>
                            <th className="text-left">{t('attendededucationslist.enddate')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendedEducations.map((education, index) => {
                            return <tr key={index}>
                                <td>{education.participant}</td>
                                <td>{education.name}</td>
                                <td>{helpers.formatDate(education.date)}</td>
                                <td>{helpers.formatDate(education.endDate)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(AttendedEducationsList);