import {bindActionCreators} from "redux";
import settingsActions from "../Actions/settingsActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {Component, useEffect} from "react";
import routes from "../Constants/routes";
import helpers from "../Utils/helpers";
import { useParams, useNavigate } from 'react-router-dom';

function ReleaseNewsContainer ({settingsActions, t, releaseNews}) {

    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getSettingsReleaseVersions();
    }, [])

    const onClick = (item) => {
        if (item.id === "new") {
            settingsActions.startAddReleaseItemForm({
                title: "",
                publishDate: new Date(),
                content: ""
            })

        } else {
            settingsActions.getRelease(item.id);
        }
        navigate(routes.releaseNewsEdit(item.id));
    }


        if (!releaseNews)
            return null;

        return (
            <div>
                <div>
                    <button className="btn btn-primary"
                            onClick={() => onClick({id: "new"})}>{t("releasenews.addnew")}</button>
                </div>
                <table className="table table-stripe table-hover">
                    <thead>
                    <tr>
                        <th>{t('releasenews.title')}</th>
                        <th>{t('releasenews.published')}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {releaseNews.map((item, index) => {
                        return <tr key={index} onClick={() => onClick(item)}>
                            <td>{item.title}</td>
                            <td>{helpers.formatDate(item.publishDate)}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        )
    }


function mapStateToProps(state) {
    return {
        releaseNews: state.SettingsReducer.releaseNews,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReleaseNewsContainer));