import React from 'react'
import BaseSearch from '../BaseSearch';
import MessageAlertLevel from '../MessageAlertLevel'
import { useTranslation } from 'react-i18next';

import './ProductSearch.css'

const ProductSearch = ({results, onSearch, onProductClick, isVonEnabled}) => {
        const { t } = useTranslation();
        const attributes = {
            labelKey: option => `${option.description}`, 
            options: results || [],
            onSearch: (query) => onSearch(query),
            placeholder: t('productsearch.placeholder'),
            onChange: (options) => options && options[0] && onProductClick(options[0].id, options[0].sourceSystem),
            onChangeAttr: () => {},
            className: "form-group search-group search-group-button px-0 col-5 mr-12",
            autoFocus: true,
            defaultOpen: true,
            minLength: 0,
            filterBy: () => true
        };

        return (
            <div className='d-flex align-items-center'>
                <BaseSearch attributes={attributes} />
                { !isVonEnabled && <MessageAlertLevel message={t('productsearch.vonaccountmissing')} /> }
            </div>
        );
    }

export default ProductSearch;