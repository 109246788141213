import React, { Component } from 'react';
import statusIcon from '../../Constants/statusIcon'
import helpers from '../../Utils/helpers';
import { withTranslation } from 'react-i18next';

class AoCustomerLeadsList extends Component {

    renderNoData() {
        const {t} = this.props
        return (<p>{t('aocustomerleadslist.noentriesfound')}</p>);
    }

    getClassNameForStatus(leadStatus) {
        if (leadStatus) {
            return statusIcon.closed;
        } else
            return statusIcon.open;
    }

    onMoveToDetails(e, leadId) {

        this.props.moveToDetails(leadId)
    }

    assignTo(leadId, userId) {
        this.props.AssignTo(leadId, userId)
    }


    render() {
        const { leads, t} = this.props;
    
        if (!leads || leads.length === 0) {
            return this.renderNoData()
        }

        return <div>
            <h3>{t('aocustomerleadslist.aoleads')}</h3>
            <div className="table-responsive">
                <table className="table table-stripe table-hover">
                    <thead>
                        <tr>
                            <th className="text-left">{t('aocustomerleadslist.contact')}</th>
                            <th className="text-left">{t('aocustomerleadslist.assignedto')}</th>
                            <th className="text-left">{t('aocustomerleadslist.createdby')}</th>
                            <th className="text-left">{t('aocustomerleadslist.created')}</th>
                            <th className="text-left">{t('aocustomerleadslist.salesamount')}</th>
                            <th className="text-left">{t('aocustomerleadslist.status')}</th>
                            <th className="text-center" width="50"></th>
                        </tr>
                    </thead>
                    <tbody>

                        {leads.map((lead) => {

                            return <tr className="cursorpointer" key={lead.id} onClick={(e) => this.onMoveToDetails(e, lead.id)}>
                                
                                <td>{lead.contact}</td>
                                <td>{lead.assignedTo}</td>
                                <td>{lead.createdBy}</td>
                                <td>{helpers.formatDateAndTime(lead.dateCreated)}</td>
                                <td>{lead.amount > 0 && helpers.currencyFormatter(lead.amount)}</td>
                                <td>{lead.isClosed ? t('aocustomerleadslist.closed') : t('aocustomerleadslist.open')}</td>
                                <td className="text-center"><span className={this.getClassNameForStatus(lead.isClosed)}></span></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div >
    }
}

export default withTranslation()(AoCustomerLeadsList);