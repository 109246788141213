import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../FormComponents/Dropdown"
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import { FormInput } from "../../FormComponents/Input/Input";
import { ActionType } from "../../../Constants/actionType";
import FormSearch from "../../Search/FormSearch";
import UserSearchNew from "../../Search/UserSearchNew";
import EditExpiryDate from "./EditExpiryDate";
import targetGroupHelpers from "../../../Utils/targetGroups";
import { targetGroupConfigurations } from "../../../Constants/VOSS/targetGroups";
import { hasAdminAccess } from "../../../Utils/permissionUtils";
import { UserRoles } from "../../../Constants/userRoles";
export function InternalInfoEditContent({ dropdownlists, commonActions, form, actionType, targetGroups, user }) {
    const [editExpiryDate, setEditExpiryDate] = React.useState(false);
    const { t } = useTranslation();
    const userHasInternalInfoRole = hasAdminAccess(user, UserRoles.InternalInfo);

    const invoiceFees = [
        { id: 8192, name: "Yes" },
        { id: 8200, name: "No" }
    ];
    const paymentTerms = [
        { id: 30, name: "30 dagar netto" },
        { id: 2, name: "2 postförskott" },
        { id: 6, name: "Förskott" },
        { id: 7, name: "Postförskott KK" },
        { id: 8, name: "Förskott swish" }
    ]

    useEffect(() => {
        setEditExpiryDate(targetGroupHelpers.hasConfig(targetGroups, form.data?.targetGroups, targetGroupConfigurations.expiryDateRequired));
    }, [form.data?.targetGroups])
    const onChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value);
    }
    const onDeleteTargetGroup = (index) => {
        commonActions.changeFormArrayField(form.id, "targetGroups", form.data?.targetGroups, index, '-1');
    }
    const onAddTargetGroup = (value) => {

        form.data.targetGroups = form.data.targetGroups ?? [];

        let name = dropdownlists?.TargetGroup.find(x => x.guid === value)?.name;
        const newTargetGroup = { name: name, id: value };

        commonActions.changeFormArrayField(form.id, "targetGroups", form.data.targetGroups, form.data.targetGroups?.length, newTargetGroup);
    }

    const onChangeResponsibleSalesPerson = (value) => {
        let salesPerson = {
            salesPersonNumber: 0,
            salesPersonName: "",
            salesPersonEmail: value
        }
        commonActions.changeMultiFormField(form.id, "responsibleSalesPerson", salesPerson);
    }

    const formatGroupName = (group) => {
        if (group.id > 0) {
            return `${group.name} / ${group.id}`;
        } else {
            return t("internalinfocontainer.selectcustomergroup");
        }
    }

    return (<>
            <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                <label className="bold">{t("internalinfocontainer.header")}</label>
                {actionType === ActionType.Edit && form.data?.customerNumber != null &&
                    <>
                        <FormInput
                            label={t("internalinfocontainer.creditlimit")}
                            value={form.data?.creditLimit} type='tel'
                            disabled={!userHasInternalInfoRole}
                            onChange={(value) => onChange('creditLimit', value)}/>
                        <Dropdown label={t("internalinfocontainer.invoicefee")}
                                  options={invoiceFees}
                                  value={form.data?.invoiceFee}
                                  disabled={!userHasInternalInfoRole}
                                  placeholder={t("internalinfocontainer.selectinvoicefee")}
                                  onChange={(value) => onChange('invoiceFee', value)}/>
                        <Dropdown label={t("internalinfocontainer.spcsedi")} options={dropdownlists?.SpcsEdi}
                                  value={form.data?.spcsEdi}
                                  placeholder={t("internalinfocontainer.selectspcsedi")}
                                  disabled={!userHasInternalInfoRole}
                                  onChange={(value) => onChange('spcsEdi', value)}/>
                        <Dropdown label={t("internalinfocontainer.paymentterms")} options={paymentTerms}
                                  value={form.data?.paymentTerms}
                                  disabled={!userHasInternalInfoRole}
                                  placeholder={t("internalinfocontainer.selectpaymentterms")}
                                  onChange={(value) => onChange('paymentTerms', value)}/>
                    </>
                }
                {actionType === ActionType.Edit && form.data?.customerNumber != null && <>
                    <FormSearch label={t("internalinfocontainer.responsibleperson")}>{(className) => <UserSearchNew
                        onChange={(value) => onChangeResponsibleSalesPerson(value)}
                        defaultSelected={{
                            email: form.data?.responsibleSalesPerson?.salesPersonEmail,
                            name: form.data?.responsibleSalesPerson?.salesPersonName ?? ""
                        }}
                        label={t('internalinfocontainer.responsibleperson')}
                        className={"form-control rounded-8 skynet-form-input"}/>}</FormSearch>
                    <Dropdown label={t('internalinfocontainer.customerstatus')} options={dropdownlists?.CustomerStatus}
                              value={form.data?.status}
                              placeholder={t('internalinfocontainer.selectcustomerstatus')}
                              onChange={(value) => onChange('status', value)}/>
                </>
                }
                <Dropdown label={t("internalinfocontainer.customergroup")} options={dropdownlists?.CustomerGroup}
                          placeholder={t("internalinfocontainer.selectcustomergroup")}
                          value={form.data?.customerGroup}
                          nameAggregate={formatGroupName}
                          onChange={(value) => onChange('customerGroup', value)}/>

            </fieldset>
            {(actionType === ActionType.Create || form.data?.id) &&
                <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                        <label className="bold">{t("internalinfocontainer.internalinfotargetgroup")}</label>
                        <table className="table table-stripe skynet-table-no-divider mb-2">
                            <tbody>
                            {form.data?.targetGroups?.map((targetGroup, index) => (
                                <tr key={index}>
                                    <td>{targetGroup.name}</td>
                                    <td>
                                        <div className="float-right">
                                            <SkynetIcon icon={'vismaicon-delete'}
                                                        onClick={() => onDeleteTargetGroup(index)}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="p-12" colSpan="2">
                                    <Dropdown options={dropdownlists?.TargetGroup} valueProperty={"guid"}
                                              property={"name"} placeholder={t("internalinfocontainer.selecttargetgroup")}
                                              onChange={(value) => onAddTargetGroup(value)}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    {(!!form.data.expiryDate || editExpiryDate) && <>
                        <EditExpiryDate commonActions={commonActions} form={form} /></>
                        }
                    </fieldset>}


        </>
    )
}