import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import CustomerLinks from '../NavigationLinks/CustomerLinks'
import VonLinks from '../NavigationLinks/VonLinks';
import AdditionalLinks from '../NavigationLinks/AdditionalLinks';
import EducationLinks from '../NavigationLinks/EducationLinks';
import EmailLinks from '../NavigationLinks/EmailLinks';
import AdminLinks from '../NavigationLinks/AdminLinks';
import commonActions from '../../Actions/commonActions';
import { bindActionCreators } from 'redux';
import InyettLinks from '../NavigationLinks/InyettLinks';
import {Access, UserRoles} from "../../Constants/userRoles";
import {hasAccess} from "../../Utils/permissionUtils";
import VossLinks from '../NavigationLinks/VossLinks';
import { FeatureFlags, isFeatureEnabled } from '../../Utils/featureFlags';
import SalesLinks from "../NavigationLinks/SalesLinks";

function LeftNavigation({commonActions, sideMenuOpen, user, vbCustomer }) {

    let timeOutFunctionId;
  
    useEffect(() => {
        const handleResize = () => {
            clearTimeout(timeOutFunctionId);
            timeOutFunctionId = setTimeout(() => {

                const innerWidth = window.innerWidth

                if(innerWidth <= 992) 
                    hideMenu()

                if(innerWidth > 992)
                    showMenu();
                
            }, 200);
        }
        
        handleResize();
        window.addEventListener('resize', handleResize)
    },[] )

    
    const hideMenu = () => {
        commonActions.toggleSideMenu(false);
    }
    
    const showMenu = () => {
        commonActions.toggleSideMenu(true);
    }
    
    const showHideMenu = () => {
        // without parameter it will toggle
        commonActions.toggleSideMenu();
    }

    const setOpenHeader = (title) => {
        commonActions.setOpenHeader(title)
    }

    const customerNumber = vbCustomer.customerNumber;

    return <div className={sideMenuOpen ? "vertical-nav" : "vertical-nav hidden-menu"}>
                    <ul className="nav" aria-expanded={sideMenuOpen}>
                        <CustomerLinks setHeader={(title) => setOpenHeader(title)}/>
                        <VonLinks setHeader={(title) => setOpenHeader(title)} />
                        {isFeatureEnabled(user, FeatureFlags.VOSS) && <VossLinks setHeader={(title) => setOpenHeader(title)} />}
                        {hasAccess(user, UserRoles.SpcsSales, Access.ReadWrite) &&
                            <SalesLinks setHeader={(title) => setOpenHeader(title)} customerNumber={customerNumber}/>
                        }
                        <EducationLinks setHeader={(title) => setOpenHeader(title)} customerNumber={customerNumber} />
                        <EmailLinks setHeader={(title) => setOpenHeader(title)} />
                        <AdminLinks setHeader={(title) => setOpenHeader(title)}  />
                        <InyettLinks setHeader={(title) => setOpenHeader(title)} />
                        <AdditionalLinks setHeader={(title) => setOpenHeader(title)}/>
                    </ul>
                    <li className="show-nav">
                        <a onClick={showHideMenu} aria-controls="verticalNav" aria-expanded={sideMenuOpen}>  Show/Hide Menu</a></li>
                </div>
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        sideMenuOpen: state.CommonReducer.sideMenuOpen
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);