import React, {useEffect, useRef} from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import routes from '../../Constants/routes'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Shortcuts from '../Layout/Shortcuts';
import { useNavigate } from 'react-router-dom';
import aocustomerLogo from "../../Images/aocustomer.svg"
import aocustomerLogoDark from "../../Images/aocustomerDark.svg"
import customerLogo from "../../Images/customer.svg"
import customerLogoDark from "../../Images/customerDark.svg"
import integrationPartnerLogo from "../../Images/integrationPartner.svg"
import integrationPartnerLogoDark from "../../Images/integrationPartnerDark.svg"
import resellerLogo from "../../Images/reseller.svg"
import resellerLogoDark from "../../Images/resellerDark.svg"
import SkynetCopy from "../Fundamentals/SkynetCopy";
import SkynetIcon from "../Fundamentals/SkynetIcon";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";
import withModalForm from "../HOC/withModalForm";
import customerActions from "../../Actions/customerActions";
import {ActionType} from "../../Constants/actionType";
import EditCustomerModal from "./CustomerComponents/EditCustomerModal";

function CustomerTopMenu({ t, customer, vbCustomer, customerInfoFlags, user, onOpenModal, isModalActive, onCloseClick }) {

    const imgRef = useRef(null);
    const navigate = useNavigate();

    if (!customer) {
        return null;
    }
    const navigateHome = () => {
        navigate(routes.customerMain(customer.pathId))
    }

    const containsProduct = (productArray, productNumber) => {
        return productArray && productArray.some(product => product.productNumber === productNumber);
    }
    const getCustomerImg = () => {
        let logo = customerLogo;
        let darkmodeLogo = customerLogoDark;
        let title = "Customer";

        if (vbCustomer.group >= 1800 && vbCustomer.group <= 1899) {
            logo = aocustomerLogo
            darkmodeLogo = aocustomerLogoDark
            title = "Accounting Office"
        } else if ((vbCustomer.group >= 3000 && vbCustomer.group <= 3999) || vbCustomer.type === 2) {
            logo = resellerLogo
            darkmodeLogo = resellerLogoDark
            title = "Reseller - Contact PartnerSales"
        } else if (containsProduct(vbCustomer.products, "ADMIN.API.UTV")) {
            logo = integrationPartnerLogo
            darkmodeLogo = integrationPartnerLogoDark
            title = "Integration Partner - Contact TechSales"
        }

        return (<>
            <img className='skynet-customer-logo pt-2 cursorpointer show-on-light' alt={"Customer logo"} title={title}
                 ref={imgRef} src={logo} onClick={() => navigateHome()}/>
            <img className='skynet-customer-logo pt-2 cursorpointer show-on-dark' alt={"Customer logo"} title={title}
                 ref={imgRef} src={darkmodeLogo} onClick={() => navigateHome()}/>
        </>)
    }

    return <>
        <div className="d-flex pb-8">
            {getCustomerImg()}
            <div className='flex-colum flex-auto pl-8'>
                <div className='d-flex'>
                    <h1 className="m-0 bold">{(vbCustomer.isNonInteractive ? "Non-interactive: " : "") + vbCustomer.name}</h1>
                    {!vbCustomer.isNonInteractive && user && isFeatureEnabled(user, FeatureFlags.VOSS) &&
                        <div className='ms-auto pt-2 px-2'>
                            <SkynetIcon icon="vismaicon-edit" onClick={() => onOpenModal('editcustomer', customer)}/>
                        </div>
                    }
                </div>
                <div>
                    <span className="pr-8">Kundnr:</span>
                    <SkynetCopy label={t("customerinfo.customernumber")}
                                value={vbCustomer.customerNumber}>{vbCustomer.customerNumber}</SkynetCopy>
                </div>
                <div>
                    <span className="pr-8">Orgnr:</span>
                    <SkynetCopy label={t("customerinfo.organizationnumber")}
                                value={vbCustomer.organizationNumber}>{vbCustomer.organizationNumber}</SkynetCopy>
                </div>
            </div>
        </div>
        <div className="mb-8 skynet-customer-shortcuts-container">
            <Shortcuts/>
        </div>
        {isModalActive('editcustomer') && <EditCustomerModal actionType={ActionType.Edit} onCloseClick={onCloseClick} navigateAfterAction={navigateHome}/>}
    </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customerInfoFlags: state.CustomerReducer.customerInfoFlags,
        user: state.CommonReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}
export default withModalForm((connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerTopMenu))));