import React, { Component } from 'react';

export default class RadioButton extends Component {
    
    onChange(e) {
        if (e.target.checked)
            this.props.onChange(e.target.value)
    }

    render() {
        return <div className="radio">
                    <input type="radio" style={this.props.style} id={this.props.label} name={this.props.group}
                        checked={this.props.checked} value={this.props.value} onChange={this.onChange.bind(this)}/>
                    <label className="radio-inline" htmlFor={this.props.label}>{this.props.label}</label>
                </div>
    }
}
