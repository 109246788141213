import React from 'react';
import { Route, Routes } from 'react-router-dom'

import CheckPriceContainer from '../../Containers/CheckPriceContainer'

const QuotationLayout = () => (
        <div className="row">
            <div className="col-xs-12">
                <Routes>
                    <Route index element={<CheckPriceContainer/>} />
                </Routes>
            </div>
        </div>
);

export default QuotationLayout;