import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import InternalLeadsListContainer from './InternalLeadsListContainer';
import AoLeadsListContainer from './AoLeadsListContainer'
import DropDown from '../../Components/DropDown';
import leadActions from '../../Actions/leadActions'
import {hasReadWriteAccess} from "../../Utils/permissionUtils";
import {UserRoles} from "../../Constants/userRoles";


class InternalLeadsContainer extends Component {


    constructor(props) {
        super(props);
        this.initState = {
            leadType: JSON.parse(localStorage.getItem("SelectedLeadType")) || '{}'
        }
        this.state = this.initState;
    }

    componentDidMount() {

        this.props.leadActions.getInternalLeads();
        this.props.leadActions.getInternalEmails();
    }

    handleFilterSet(type) {
        localStorage.setItem("SelectedLeadType", JSON.stringify(type));
        this.props.leadActions.getInternalLeads();

        this.setState({ leadType: type })
    }

    getFilteredLeads(){
        const {filteredLeads, internalLeads} = this.props;
        
        var filteredLeadss = internalLeads.filter(lead => lead.type == this.state.leadType.type);
        return filteredLeadss;
    }

    render() {
        const { t, internalLeads, user } = this.props;


        if (!internalLeads) {
            return null;
        }

        const leadType = this.state.leadType.type;
        const leadName = this.state.leadType.name;

        const leadTypes = [{ type: 'tidSmartLead', name: 'Tid Smart'}, { type: 'supportLead', name: 'eEkonomi/Lön Smart'}]

        if (hasReadWriteAccess(user, UserRoles.AOLeads))
            leadTypes.push({ type: "aoLead", name: "AO" });

        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">{t("internalleadscontainer.leads")}</h3>
                    </div>
                    <div className="panel-body">
                        <div className="col-xs-12">
                            <div className="filters">
                                {leadTypes && leadTypes.map((type, index) => {
                                    return <button type="button" className={`btn btn-default ${this.state.leadType.type == type.type && "active"}`} onClick={() => this.handleFilterSet(type)}>{type.name}</button>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {leadType == 'aoLead' ? <AoLeadsListContainer history={this.props.history}/> :
                    leadType != null ? <InternalLeadsListContainer salesPersons={this.props.internalEmails} leads={this.getFilteredLeads()} history={this.props.history} leadName={leadName}/> : null }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        internalLeads: state.LeadsReducer.internalLeads,
        filteredLeads: state.LeadsReducer.filteredLeads,
        internalEmails: state.LeadsReducer.internalEmails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalLeadsContainer));
