const statusIcon = {
    open: "vismaicon vismaicon-ok-circle",
    closed: "vismaicon vismaicon-cancel-circle",
    new: "vismaicon vismaicon-add-circle",
    warning: "vismaicon vismaicon-filled vismaicon-error"
}

export default statusIcon;


