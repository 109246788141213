import React from 'react';
import { Route, Routes } from 'react-router-dom'
import NewDiscountAgreementFormContainer from '../../../Containers/Discounts/VOSS/NewDiscountAgreementFormContainer';
import DiscountAgreementsContainer from '../../../Containers/Discounts/VOSS/DiscountAgreementsContainer';
import EditDiscountAgreementFormContainer from '../../../Containers/Discounts/VOSS/EditDiscountAgreementFormContainer';

function DiscountAgreementsLayout({readOnly}) {
    return (
        <div className="row">
            <div className="col-xs-12">
                <Routes>
                    <Route path={'/createnewdiscount'} element={<NewDiscountAgreementFormContainer readOnly={readOnly} />} />
                    <Route path={'/editdiscount/'} element={<EditDiscountAgreementFormContainer readOnly={readOnly} />} />
                    <Route index element={<DiscountAgreementsContainer readOnly={readOnly} />} />
                </Routes>
            </div>
        </div>
    )
};

export default DiscountAgreementsLayout;
