import { orderActionTypes as actionTypes } from '../ActionTypes/orderActionTypes'

export const initialState = {}

export default function OrderReducer(state = initialState, action) {

    let newState;

    switch (action.type) {

        case actionTypes.START_ORDER:
            newState = { searchResults: null, product: null, orderId: action.orderId, customerNumber: action.customerNumber, isVonEnabled: action.isVonEnabled }
            break;
        case actionTypes.START_ORDER_QUOTATION:
            newState = { searchResults: null, orderId: action.orderId, customerNumber: action.customerNumber, isVonEnabled: action.isVonEnabled }
            break;
        case actionTypes.CLEAR_ORDER:
            newState = { orderId: null, order: null, product: null, addons: null }
            break;
        case actionTypes.SHOW_SEARCH_RESULTS:
            newState = { searchResults: action.results }
            break;
        case actionTypes.SHOW_TRIAL_SEARCH_RESULTS:
            newState = { trialSearchResults: action.results }
            break;
        case actionTypes.SHOW_PRODUCT_DETAILS:
            newState = { product: action.product, sourceSystem: action.sourceSystem }
            break;
        case actionTypes.HIDE_PRODUCT_DETAILS:
            newState = { product: null, editOrderline: null, addons: null }
            break;
        case actionTypes.SHOW_ORDER_DETAILS:
            newState = { orderId: action.order.orderId, order: action.order }
            break;
        case actionTypes.EDIT_ORDERLINE:
            newState = { editOrderline: action.orderline }
            break;
        case actionTypes.CANCEL_ORDER:
            newState = { order: null, orderId: null, product: null, addons: null }
            break;
        case actionTypes.CHANGE_ADDITIONAL_USERS:
            newState = { product: Object.assign({}, state.product, { additionalUsersCount: action.count }) }
            break;
        case actionTypes.CHANGE_ADDITIONAL_BLIKK_USERS:
            newState = { blikkLicenseTypes: action.licenseTypes }
            break;
        case actionTypes.CLEAR_BLIKK_ADDITIONAL_USERS:
            newState = { blikkLicenseTypes: null }
            break;
        case actionTypes.CLEAR_BLIKK_TRANSACTIONS:
            newState = { blikkTransactionTypes: null }
            break;
        case actionTypes.CHANGE_BLIKK_TRANSACTIONS:
            newState = { blikkTransactionTypes: action.transactionTypes }
             break;
        case actionTypes.TOGGLE_RECEIPT_INTERPRETATION:
            newState = {receiptInterpretation: action.receiptInterpretation}
            break;
        case actionTypes.CHANGE_API:
            newState = { product: Object.assign({}, state.product, { api: action.api }) }
            break;
        case actionTypes.CHANGE_PERIOD:
            newState = { product: Object.assign({}, state.product, { period: action.period }) }
            break;
        case actionTypes.CHANGE_ADDONS:
            newState = { addons: Object.assign({}, action.values) }
            break;
        case actionTypes.CHANGE_COMPETITOR_DISCOUNT:
            newState = { product: Object.assign({}, state.product, { competitorDiscount: action.discountSelected }) }
            break;
        case actionTypes.CHANGE_LOYALTY_DISCOUNT_CUSTOMER:
            newState = { product: Object.assign({}, state.product, { loyaltyDiscountCustomer: action.loyaltyDiscountCustomerSelected }) }
            break;
        case actionTypes.CHANGE_ASSIGNED_LEAD:
            newState = { product: Object.assign({}, state.product, { leadId: action.leadId }) }
            break;
        
        case actionTypes.CHANGE_ASSIGNED_APPOINTMENT:
            newState = { product: Object.assign({}, state.product, { appointmentId: action.appointmentId }) }
            break;

        case actionTypes.CHANGE_TEMPLATE_ORGANIZATION:
            newState = { product: Object.assign({}, state.product, { templateOrganizationId: action.templateOrganizationId }) }
            break;

        case actionTypes.CHANGE_LANG_CODE:
            newState = { product: Object.assign({}, state.product, { langCode: action.langCode }) }
            break;

        case actionTypes.SHOW_TEMPLATE_ORGANIZATIONS:
            newState = {templateOrganizations: action.values}
            break;

        case actionTypes.SET_PUSHED_QUOTATION:
            newState = {pushedQuotation: action.pushedQuotation, quotationId: action.quotationId}
            break;
            
        case actionTypes.CLEAR_PUSHED_QUOTATION:
            newState = {pushedQuotation: action.value, quotationId: action.value}
            break;

        case actionTypes.SET_QUOTATION_CURRENTLY_IN_PROGRESS:
            newState = {order: Object.assign({}, state.order, { quotationCurrentlyInProgress: action.value }) }
            break;

        case actionTypes.STORE_APPROVAL_REQUESTS:
            newState = {approvalRequests: action.data}
            break;
            
        case actionTypes.STORE_VOSS_INTERMEDIARIES:
            newState = {vossIntermediaries: action.data}
            break;
            
        default:
            return state
    }

    if (newState) {
        return Object.assign({}, state, newState)
    }

    return state;
}