import React from 'react';
import * as calcTypes from '../../Constants/priceCalculationTypes';
import Grid from "../../Components/Grid/Grid";
import { VbProductOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbProductOptionsCard';
import { VbDiscountOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbDiscountOptionsCard';
import { VbProductSummaryCard } from '../../Components/OrderPlacement/ProductComponents/Vb/VbProductSummaryCard';

const VbProductContainer = ({ ...props }) => {

  return (
    <>
      <Grid.Row>
        <Grid.Row.Column width={4}>
          <VbProductOptionsCard {...props} />
        </Grid.Row.Column>
        <Grid.Row.Column width={4}>
          <VbDiscountOptionsCard {...props} />
        </Grid.Row.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Row.Column>
          <VbProductSummaryCard {...props} priceCalcType={calcTypes.priceCalculationTypes.order} />
        </Grid.Row.Column>
      </Grid.Row>
    </>
  );
};

export default VbProductContainer;
