import React from 'react';
import helpers from '../Utils/helpers'
import { useTranslation } from 'react-i18next';
import Panel from "./Panel";

export default function ActionHistory({actionHistory}) {
    
    const { t } = useTranslation();
    
    const renderNoDataFound = () => {
        return (<p><i>{t('actionhistory.noentriesfound')}</i></p>);
    }

    const toggleResendMailModal = (emailActionLogId) =>{
        this.props.toggleModal("showSendEmailModal", emailActionLogId)
    }
    
    if(!actionHistory)
        return null;
    
    return <Panel title={t('actionhistory.actionhistory')} isNoData={actionHistory.length === 0}
                  renderNoData={renderNoDataFound}>
        
        <div className="table-responsive" style={{height: "100%"}}>
            <table className="table table-stripe">
                <thead>
                <tr>
                    <th className="text-left">{t('actionhistory.action')}</th>
                    <th className="text-left">{t('actionhistory.author')}</th>
                    <th className="text-left">{t('actionhistory.dateandtime')}</th>
                </tr>
                </thead>
                <tbody>
                {actionHistory.map((action, index) => {
                    return <tr key={index}>
                        {action.emailActionLogId
                            ? <td><a onClick={() => toggleResendMailModal(action.emailActionLogId)}>{action.action}</a>
                            </td>
                            : <td>{action.action}</td>
                        }
                        <td>{action.author}</td>
                        <td style={{whiteSpace: 'nowrap'}}>{helpers.formatDateAndTime(action.date)}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </Panel>
}