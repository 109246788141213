import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NoInformation } from "./Panel";

class ListSearch extends Component {
    state = {
        searchInput: ''
    }

    onChange = (e) => {
        this.setState({ searchInput: e.target.value })
    }

    onSearchClick = () => {
        if (this.state.searchInput.length === 0) {
            return null;
        }

        this.props.onSearchClick(this.state.searchInput);
    }

    onKeyClick = (e) => {
        if (e.key === "Enter") {
            this.onSearchClick();
        }
    }

    onCloseClick = () => {
        this.setState({ searchInput: '' })
        this.props.onClearClick();
    }

    renderResults(results) {
        const {t} = this.props
        if (!results) {
            return null;
        }
        
        if(!results.length)
            return <NoInformation />
        
        return [
            <button key={100000} className="btn btn-default pull-right" type="button" onClick={this.onCloseClick}>{t('listsearch.clear')}</button>,
            <h3 key={123}>{results.length} {t('listsearch.resultsfound')}</h3>,            
            this.props.children
        ]
    }

    render() {
        return <div className="appointmentSearch">
                        <div className="form-group search-group">
                            <input type="text" className="form-control" value={this.state.searchInput} aria-describedby="searchBlock" placeholder={this.props.placeholder} onChange={(e) => this.onChange(e)} onKeyPress={(e) => this.onKeyClick(e)}/>
                                <button type="button" className="btn btn-default search-icon" onClick={this.onSearchClick}></button>
                        </div>
                    {this.renderResults(this.props.results)}
                </div>
    }
}

export default withTranslation()(ListSearch);