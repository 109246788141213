import React, { useEffect, useState } from 'react';
import { useTranslation} from 'react-i18next';

import helpers from '../../Utils/helpers'
import routes from '../../Constants/routes'
import moment from 'moment'
import { NoInformation } from "../Panel";
import { DropDownToggle } from '../DropDownToggle';

export default function EducationsList({filters, educations, customerNumber, freeWebinars, warning, redirect}) {
    
    const { t } = useTranslation();
    const [subjects, setSubjects] = useState([])
    const [locations, setLocations] = useState([])

    useEffect(() => {
        if (filters)
            resetFilters();
    }, [filters]);

    const resetFilters = () => {
        // sets filters from props to local state
        setSubjects(filters.subjects.map(item => ({
            label: item,
            selected: false
        })));

        setLocations(filters.locations.map(item => ({
            label: item,
            selected: false
        })))
    }
    
    const filterEducations = () => {
        const locationFilter = locations.filter(s => s.selected).map(m => m.label);
        const subjectFilter = subjects.filter(s => s.selected).map(m => m.label);
        
        if (locationFilter.length) 
            educations = educations.filter(e => locationFilter.includes(e.location));

        if (subjectFilter.length) 
            educations = educations.filter(e => e.subjects.some(f => subjectFilter.includes(f)));

        if (freeWebinars) {
            educations = educations.filter(e => e.price === 0);
        } else {
            educations = educations.filter(e => e.price > 0);
        }

        return educations;
    }

    const onClickAction = (education) => {
        const currentDate = moment();
        const currentDay = moment().startOf('day');
        
        if (moment.duration(moment(education.date).diff(currentDate)).asHours() < 25) {
            warning(t('educationslist.contactadministration'), t('educationslist.coursestarttomorrow'))
        }
        else if ((education.maximumParticipants !== null || education.maximumParticipants === 0) && (education.participantsBooked === education.maximumParticipants)) {
            warning(t('educationslist.contactadministration'), t('educationslist.educationisfull'));
        }
        else if (currentDay.isAfter(moment(education.lastApplicationDate))) {
            warning(t('educationslist.contactadministration'), t('educationslist.lastapplication'));
        }
        else {
            redirect(routes.course(customerNumber, education.courseTemplateId, education.id));
        }
    }

    const colourField = (education) => {
        if (education.warningMarker) {
            return "text-danger"
        }

        if (education.maximumParticipants !== null && education.maximumParticipants === education.participantsBooked) {
            return "text-primary"
        }

        return "";
    }
    
    if (!educations) 
        return null;

    if (!educations.length) 
        return <NoInformation/>

    const subjectOnChange = (item) => {
        const subject = subjects.find(s => s.label === item.label);
        subject.selected = !subject.selected
        setSubjects([...subjects]);
    }   
    
    const locationOnChange = (item) => {
        const element = locations.find(s => s.label === item.label);
        element.selected = !element.selected
        setLocations([...locations]);
    }
    
    const hasFilter = () => {
        return locations.some(s => s.selected) || subjects.some(s => s.selected);
    }
    
    return <div>
            <button className="btn btn-default" disabled={!hasFilter()}  onClick={resetFilters}>Clear filters</button>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th className="text-left">{t('educationslist.name')}</th>
                        <th className="text-left"><DropDownToggle buttonLabel="Category" items={subjects} itemOnClick={subjectOnChange} /></th>
                        <th className="text-left"><DropDownToggle buttonLabel="Location" items={locations} itemOnClick={locationOnChange} /></th>
                        <th className="text-left">{t('educationslist.startdate')}</th>
                        <th className="text-left">{t('educationslist.enddate')}</th>
                        <th className="text-left">{t('educationslist.participantsbooked')}</th>
                        <th className="text-left">{t('educationslist.minimumparticipants')}</th>
                        <th className="text-left">{t('educationslist.maximumparticipants')}</th>
                        {!freeWebinars && <th className="text-left">{t('educationslist.price')}</th>}
                    </tr>
                </thead>
                <tbody>
                    { filterEducations().map((education, index) => {
                        const className = colourField(education);

                        return <tr onClick={() => onClickAction(education)} key={index}>
                            <td className={className}>{education.warningMarker && <span className="vismaicon vismaicon-filled vismaicon-warning education-warning-icon"></span>}{education.name}</td>
                            <td>{education.subjects && education.subjects.join(', ')}</td>
                            <td>{education.location}</td>
                            <td style={{ whiteSpace: "nowrap" }}>{education.onDemand ?  'Vid beställning' : education.date}</td>
                            <td style={{ whiteSpace: "nowrap" }}>{education.onDemand ?  education.onDemandAccessDays+' dagar efter start': education.endDate}</td>
                            <td className={className}>{education.participantsBooked}</td>
                            <td className={className}>{education.onDemand ? '-' :education.minimumParticipants}</td>
                            <td className={className}>{education.onDemand ? '-' :education.maximumParticipants}</td>
                            {!freeWebinars && <td>{helpers.currencyFormatter(education.price)}</td>}
                        </tr>
                    })}
                </tbody>
            </table>
    </div>
}