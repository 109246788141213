import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment'
import StaticTextInput from '../../Components/StaticTextInput'
import Checkbox from '../../Components/Checkbox'
import RadioButtonsInline from '../../Components/RadioButtonsInline'
import RadioButton from '../../Components/RadioButton'
import LeadsOrderDropdown from '../../Components/Leads/LeadsOrderDropdown'
import AdditionalUsers from '../../Components/Order/AdditionalUsers'
import AppointmentOrderDropdown from '../Appointment/AppointmentOrderDropdown'
import TextInput from '../../Components/TextInput';
import DateTimeInput from '../../Components/DateTimeInput'
import * as calcTypes from '../../Constants/priceCalculationTypes'


import helper from '../../Utils/helpers'
import HorizontalText, { HorizontalRow } from "../../Components/HorizontalText";
import {hasReadWriteAccess} from "../../Utils/permissionUtils";
import {UserRoles} from "../../Constants/userRoles";

class VonProductDetails extends Component {

    constructor(props) {
        super(props);
        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.onAddonCheck = this.onAddonCheck.bind(this);

        this.initState = {
            calculations: []
        }

        this.state = this.initState
    }

    onPeriodChange(period) {
        const addons = this.getCheckedAddons();

        this.props.genericActions.getVonProductDetails(this.props.product.productNumber, this.props.customerNumber, period, this.props.orderId, this.props.product.additionalUsersCount, addons, true, this.props.product.invoiceStartDate);
    }

    onAddonCheck(element) {
        const product = this.props.product;

        let checkedAddons = this.getCheckedAddons();

        if (element.checked) {
            checkedAddons.push(element.value);
        } else {
            var index = checkedAddons.indexOf(element.value);

            if (index > -1) {
                checkedAddons.splice(index, 1);
            }
        }

        this.props.genericActions.updateAddons(checkedAddons);

        this.props.genericActions.getVonProductDetails(product.productNumber, this.props.customerNumber, product.period, this.props.orderId, product.additionalUsersCount, checkedAddons, true, product.invoiceStartDate);
    }

    onAssignedLeadChange = (value) => {
        this.props.genericActions.changeAssignedLead(value);
    }

    onAssignedAppointmentChange = (value) => {
        this.props.genericActions.changeAssignedAppointment(value);
    }

    getCheckedAddons() {
        return this.props.product.addons
            .filter(addon => addon.isChecked)
            .map(addon => addon.productId);
    }

    onAdditionalUserChange(value) {
        const { customerNumber, product, orderId } = this.props;
        this.props.genericActions.changeAdditionalUsersCount(value)
        const addons = this.getCheckedAddons();

        this.props.genericActions.getVonProductDetails(product.productId, customerNumber, product.period, orderId, product.additionalUsersCount, addons, true, product.invoiceStartDate);
    }

    onDateChange(date) {
        const addons = this.getCheckedAddons();
        const product = this.props.product;

        product.invoiceStartDate = date;
        this.props.genericActions.getVonProductDetails(product.productNumber, this.props.customerNumber, product.period, this.props.orderId, product.additionalUsersCount, addons, true, date);
    }

    onChangeCostCalculation(cost, id) {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.calculations = stateCopy.calculations.slice();
        stateCopy.calculations[id] = Object.assign({}, stateCopy.calculations[id]);
        stateCopy.calculations[id].cost = cost;
        this.setState(stateCopy);
    }

    onChangeNumberOfEmployees(employees, id) {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.calculations = stateCopy.calculations.slice();
        stateCopy.calculations[id] = Object.assign({}, stateCopy.calculations[id]);
        stateCopy.calculations[id].employees = employees;
        this.setState(stateCopy);
    }

    getCalculatedPrice(id) {
        if (this.state.calculations[id] !== undefined) {
            if (this.state.calculations[id].employees !== undefined && this.state.calculations[id].cost !== undefined) {
                return helper.currencyFormatter(this.state.calculations[id].employees * this.state.calculations[id].cost)
            }
        }
        return helper.currencyFormatter(0);
    }
    
    render() {
        const { t, user, customer } = this.props
        const product = this.props.product;
        
        return <div className="row">
            <div className="col-6">
                <h3>{t('vonproductdetails.main')}</h3>
                
                <HorizontalRow>
                    <HorizontalText columns={1} label={t("vonproductdetails.name")} value={product.productDescription} />
                    <RadioButtonsInline label={t("vonproductdetails.renewperiod")} name="periods" values={product.periods} currentChecked={product.period} onChange={this.onPeriodChange} disabled={product.readonlyPeriods} />
                    {hasReadWriteAccess(user, UserRoles.SMBSales) && (this.props.calcType === calcTypes.priceCalculationTypes.order) && <DateTimeInput placeholderText={moment().format('YYYY-MM-DD')} showTime={false} label={t("vonproductdetails.invoicestartdate")} onChange={(value) => this.onDateChange(value)} value={product.invoiceStartDate} closeOnSelect={true}  />}
                    <HorizontalText columns={1} label={t("vonproductdetails.price")} value={helper.currencyFormatter(product.mainInitialPrice)} />
                    <HorizontalText columns={1} label={t("vonproductdetails.nextprice")} value={helper.currencyFormatter(product.mainRecurringPrice)} />
                    <HorizontalText columns={1} label={t("vonproductdetails.nextinvoicedate")} value={helper.formatDate(product.nextInvoiceDate)} />
                    <LeadsOrderDropdown onChange={this.onAssignedLeadChange} leadId={product.leadId} currentlyInBasket={product.currentlyInBasket} />
                    <AppointmentOrderDropdown onChange={this.onAssignedAppointmentChange} appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
                </HorizontalRow>
                
            </div>

            {product.productCostCalculations && product.productCostCalculations.length > 0 &&
                <div className="col-6">
                    {product.productCostCalculations.map((productCostCalculation, index) => {
                        return <div className="form-group" key={index}>
                            <h3>{productCostCalculation.calculationName}</h3>
                            <div className="form-group">
                                <div className="col-xs-8">
                                    {productCostCalculation.calculationCosts.map((calculationCost) => {
                                        return <div> <RadioButton style={{ marginRight: 5, marginTop: 4 }} group={productCostCalculation.id} label={calculationCost.costName} value={calculationCost.price} onChange={(value) => this.onChangeCostCalculation(value, productCostCalculation.id)} /> </div>
                                    })}
                                    <TextInput placeholder={0} label={t("vonproductdetails.noofemployees")} value={this.state.calculations[productCostCalculation.id] != undefined && this.state.calculations[productCostCalculation.id].employees} onChange={(value) => this.onChangeNumberOfEmployees(value, productCostCalculation.id)} />
                                    <StaticTextInput label={t("vonproductdetails.price")} value={this.getCalculatedPrice(productCostCalculation.id)} />
                                </div>
                            </div>
                            <br />
                        </div>
                    })}
                </div>
            }

            <div className="col-6">
                {product.addons && product.addons.length > 0 &&
                    <>
                        <h3>{t('vonproductdetails.addons')}</h3>
                        {product.addons.map((addon, index) => {
                            if(customer && customer.typeOfBusiness === 2 && addon.productNumber === "Lime.annualreport")
                                return;

                            if(customer && customer.typeOfBusiness !== 2 && addon.productNumber === "Lime.standard")
                                return;
                                 
                            return <div key={index}>
                                <Checkbox label={addon.productDescription} value={addon.productId} checked={addon.isChecked} disabled={addon.isDisabled} onChange={this.onAddonCheck} />
                                <HorizontalRow>
                                    {/*<HorizontalText columns={1} label={t("vonproductdetails.name")} value={addon.productDescription} />*/}
                                    {addon.isChecked && <HorizontalText columns={1} label={t("vonproductdetails.price")} value={helper.currencyFormatter(addon.initialPrice)} />}
                                    {addon.isChecked && <HorizontalText columns={1} label={t("vonproductdetails.nextprice")} value={helper.currencyFormatter(addon.recurringPrice)} />}
                                </HorizontalRow>
                                <hr/>
                            </div>
                        })}
                    </>
                }
                
                <AdditionalUsers product={product} onChange={this.onAdditionalUserChange.bind(this)} />
                <br />
                <HorizontalRow>
                    <HorizontalText columns={1} label={t("vonproductdetails.price")} value={helper.currencyFormatter(product.additionalUsersInitialPrice)} />
                    <HorizontalText columns={1} label={t("vonproductdetails.nextprice")} value={helper.currencyFormatter(product.additionalUsersRecurringPrice)} />
                </HorizontalRow>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user
    }
}

export default connect(mapStateToProps, null)(withTranslation()(VonProductDetails)); 