import { appointmentActionTypes as actionTypes } from '../ActionTypes/appointmentActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'
import { leadActionTypes } from '../ActionTypes/leadActionTypes'

export const initialState = {
    appointments: [],
    notification: null
}

export default function AppointmentReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_APPOINTMENT:
            newState = { appointment: action.data }
            break;

        case actionTypes.CLEAR_APPOINTMENT:
            newState = { appointment: action.data }
            break;

        case actionTypes.STORE_MY_APPOINTMENTS:
            newState = {
                myOpenAppointments: action.myOpenAppointments,
                myClosedAppointments: action.myClosedAppointments
            }
            break;

        case actionTypes.STORE_MY_CUSTOMER_APPOINTMENTS:
            newState = { myCustomerAppointments: action.data }
            break;

        case actionTypes.STORE_CUSTOMER_APPOINTMENTS:
            newState = { customerAppointments: action.data }
            break;

        case actionTypes.UPDATE_MY_APPOINTMENTS_COUNT:
            newState = { openAppointmentsCount: action.openAppointments };
            break;

        case actionTypes.STORE_SEARCH_APPOINTMENTS_RESULTS:
            newState = { searchResults: action.data };
            break;

        case commonActionTypes.SORTED_LIST:
            if (action.listName === "myOpenAppointments")
                newState = { myOpenAppointments: action.list }
            else if (action.listName === "myClosedAppointments")
                newState = { myClosedAppointments: action.list }
            else if (action.listName === "searchResults")
                newState = { searchResults: action.list }
            else if (action.listName === "myFilteredOpenAppointments")
                newState = { myFilteredOpenAppointments: action.list }
            break;

        case actionTypes.FILTERED_OPEN_APPOINTMENTS:
            newState = { myFilteredOpenAppointments: action.list, myFilter: action.filter }
            break;

        case leadActionTypes.STORE_LEAD:
            newState = { lead: action.data }
            break;

        case leadActionTypes.CLEAR_LEAD:
            newState = { lead: null }
            break;

        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}