import React, { useEffect } from 'react'
import EmailTemplate from './EmailTemplate'
import Modal from './Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../Actions/commonActions';

function EducationInfoModal({commonActions, form, educationInfoHtmlString, togglemodal}) {

    useEffect(() => {
        commonActions.getEducationInformationTemplate(educationInfoHtmlString);
    }, [])

    const handleClickClose = () => {
        togglemodal();
    }

    const handleFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value);
    }

    const handleClickSave = () => {
        commonActions.sendEducationInformation(form);
        togglemodal();
    }

    const modalProps = {
        title: 'Send Education Information',
        mainButtonTitle: 'Send',
        onCancelClick: handleClickClose,
        stateful: true,
        open: true,
        onChange: handleFieldChange, 
        onSaveClick: handleClickSave,
    }

    return (
    <Modal {...modalProps}>
        <EmailTemplate data={form && form.data} templates={null} onChange={handleFieldChange} onSaveClick={handleClickSave} onCancelClick={handleClickClose}/>
    </Modal>
    )
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.sendEducationInformationForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EducationInfoModal));