import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import commonActions from '../../Actions/commonActions'
import leadActions from '../../Actions/leadActions'

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'


class CloseModal extends Component {

    componentDidMount() {
        const { leadActions } = this.props;
        leadActions.startCloseModal(this.getModel())
        leadActions.getLeadsCloseReasonCodes();
    }

    getModel() {
        return { 
            reason: '',
            reasonId: '',
            action:'Close'
        }
    }

    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId } } = this.props;

        commonActions.changeMultiFormField(formId, field, value);
    }

    handleClickSave = () => {
        const { leadActions, lead, form, onSave, selectedLeads, multiple, leadType} = this.props;

        if(multiple)
        {
            leadActions.closeMultiple(selectedLeads, form, onSave)
        }
        else
        {
            leadActions.close(lead.id, form, leadType, onSave)
        }

        this.props.onClose();        
    }

    handleClickClose = () => {
        this.props.onClose();
    }

    render() {
        const { reasonCodes, form, selectedLeads, multiple, leadType, t } = this.props;

        let title = t('closemodal.close');
        
        if(multiple)
        {
            const countSelectedLeads = selectedLeads.length; 
            title = t('closemodal.closeselected') + `${countSelectedLeads}` + t('closemodal.selectedleads')
        }
        
        if(!form) {
            return null;
        }

        const modalProps = {
            title: title,
            mainButtonTitle: t('closemodal.mainbutton'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <DropDown label={t('closemodal.reason')} onChange={this.handleFieldChange.bind(this, 'reasonId')} list={reasonCodes} />
                    {leadType === "aoLead" && <TextInput label={t('closemodal.message')} multiline={true} onChange={this.handleFieldChange.bind(this, 'message')} value={form.data.message}/>}
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        reasonCodes: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.LeadsCloseReasonCodes,
        form: state.CommonReducer.forms && state.CommonReducer.forms.leadCloseModal,
        selectedLeads: state.LeadsReducer.selectedLeads
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CloseModal));
