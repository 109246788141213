import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import DateTimeInput from "../../FormComponents/Input/DateTimeInput";
import moment from "moment";

export default function EditExpiryDate({form, commonActions }) {
    const {t} = useTranslation();
    const yesterday = moment().subtract( 1, 'day' );
    const oneYearFromToday = moment().add( 1, 'year');

    const isValidExpiryDate = (current) => {
        return current?.isAfter(yesterday) && current?.isBefore(oneYearFromToday);
    }

    return <DateTimeInput
                dateFormat={"YYYY-MM-DD"} showTime={false}
                onChange={(value) => commonActions.changeMultiFormField(form.id, "expiryDate", value)}
                initialValue={form.data?.expiryDate}
                isValidDate={isValidExpiryDate}
                error={!isValidExpiryDate(moment(form.data?.expiryDate)) ? "Expiry date needs to be within a year" : false}
                label={t("maininfocontainer.expirydate")}/>
}