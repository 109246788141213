import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux'

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import StaticTextInput from '../../Components/StaticTextInput'
import TextInput from '../../Components/TextInput'

import commonActions from '../../Actions/commonActions'
import leadActions from '../../Actions/leadActions'



class NewLeadsModal extends Component {

    componentDidMount() {
        const { leadActions } = this.props;
        leadActions.startNewLeadsModal(this.getModel())
    }

    getModel() {
        return { 
            leadType: '',
            availableLeadAmount: 0,
            leadAmount: 0
        }
    }

    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId } } = this.props;

        commonActions.changeMultiFormField(formId, field, value);
    }
    
    handleLeadTypeChange = (field, value) => {
        const { commonActions, form: { id: formId }, leadTypes} = this.props;

        commonActions.changeMultiFormField(formId, field, value);
        this.setState({leadType: value})
        commonActions.changeMultiFormField(formId, 'availableLeadAmount', value != "" ? leadTypes.find(type => type.name === value).availableCount : 0 )
    }

    handleClickClose = () => {
        this.props.onClose();
    }

    handleClickSave = () => {
        const {leadActions, form} = this.props
        leadActions.assignNewLeads(form);
    }

    render() {
        const { t, leadTypes, form } = this.props;

        if(!form)
        return null;

        let options = [];

        if (leadTypes) {
            for (let index = 0; index < leadTypes.length; index++) {
                options[index] = { name: leadTypes[index].name, identifier: leadTypes[index].name }
            }
        }

        const modalProps = {
            title: t('newleadsmodal.newleads'),
            mainButtonTitle: t('newleadsmodal.save'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }



        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <DropDown label={t('newleadsmodal.leadtype')} blankOption={true} onChange={this.handleLeadTypeChange.bind(this, 'leadType')} list={options} />
                    <StaticTextInput label={t('newleadsmodal.availableleads')}  value={form.data.availableLeadAmount}/>
                    <TextInput label={t('newleadsmodal.leadsamount')} onChange={this.handleFieldChange.bind(this, 'leadAmount')} value={form.data.leadAmount}/>
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.newLeadsModal
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewLeadsModal));