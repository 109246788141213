import { useEffect, useState } from "react";
import { Dropdown } from "../../FormComponents/Dropdown";
import { RadioButtons } from "../../FormComponents/RadioButtons";
import { VossDiscountType } from "../../../Constants/VOSS/vossDiscountType";
import { FormInput } from "../../FormComponents/Input/Input";
import { VossPricingScheme, VossPricingSchemeText } from "../../../Constants/VOSS/vossPricingScheme";
import SkynetIcon from "../../Fundamentals/SkynetIcon";

function DiscountPricingForm({ selectedPackage, onAddDiscount, discountToEdit, disableEdit, chargePlanDiscounts }) {
    const [formState, setFormState] = useState({
        selectedTier: null,
        selectedPlan: null,
        selectedChargePlan: null,
        selectedProductGroupPricing: null,
        selectedProductPricing: null,
        selectedDiscountType: VossDiscountType.FlatPrice,
        unitPrice: 0,
        tierPricingDiscounts: [],
    });

    const { selectedTier, selectedPlan, selectedChargePlan, selectedProductGroupPricing, selectedProductPricing, selectedDiscountType, unitPrice, tierPricingDiscounts } = formState;

    const updateFormState = (field, value) => {
        setFormState(prevState => ({ ...prevState, [field]: value }));
    };

    const getExistingDiscount = (chargePlanDiscounts, selectedChargePlanId, selectedProductGroupPricing, selectedProductPricing) => {
        if (!chargePlanDiscounts) return null;
        return chargePlanDiscounts.find((chargePlan) =>
            chargePlan.chargePlanId === selectedChargePlanId &&
            chargePlan.productGroupDiscounts.some((groupDiscount) =>
                groupDiscount.productGroupId === selectedProductGroupPricing &&
                groupDiscount.productPricingDiscounts.some(
                    (pricingDiscount) =>
                        pricingDiscount.productId === selectedProductPricing
                )
            )
        );
    };

    useEffect(() => {
        if (discountToEdit) {
            setFormState({
                selectedTier: discountToEdit.selectedTier,
                selectedPlan: discountToEdit.selectedPlan,
                selectedChargePlan: discountToEdit.selectedChargePlan,
                selectedProductGroupPricing: discountToEdit.selectedProductGroupPricing,
                selectedProductPricing: discountToEdit.selectedProductPricing,
                selectedDiscountType: discountToEdit.selectedDiscountType,
                unitPrice: discountToEdit.unitPrice,
                tierPricingDiscounts: discountToEdit.tierPricingDiscounts
            });
        }
    }, [discountToEdit]);

    useEffect(() => {
        if (selectedPackage) {
            setFormState({
                selectedTier: -1,
                selectedPlan: -1,
                selectedChargePlan: null,
                selectedProductGroupPricing: null,
                selectedProductPricing: null,
                selectedDiscountType: VossDiscountType.FlatPrice,
                unitPrice: 0,
                tierPricingDiscounts: [],
            });
        }
    }, [selectedPackage]);

    const setChargePlan = (tier, plan) => {
        if (tier && plan) {
            const chargePlan = selectedPackage.chargePlans.find(p => p.tierId === tier && p.planId === plan);
            if (chargePlan) updateFormState("selectedChargePlan", chargePlan);
            updateFormState("selectedProductGroupPricing", null);
            updateFormState("selectedProductPricing", null);
            updateFormState("unitPrice", 0);
        }
    };

    const handleTierChange = (value) => {
        updateFormState("selectedTier", value);
        setChargePlan(value, selectedPlan);
    };

    const handlePlanChange = (value) => {
        updateFormState("selectedPlan", value);
        setChargePlan(selectedTier, value);
    };

    const handleProductChange = (value) => {
        updateFormState("unitPrice", 0);
        updateFormState("selectedProductPricing", value);
        let existingDiscount = getExistingDiscount(chargePlanDiscounts, selectedChargePlan?.id, selectedProductGroupPricing, value);
        let productPricing = existingDiscount?.productGroupDiscounts.find(group => group.productGroupId === selectedProductGroupPricing)?.productPricingDiscounts.find(pp => pp.productId === value);
        updateFormState("tierPricingDiscounts", productPricing?.tierPricingDiscounts || []);
    };

    const getProductGroup = (productGroupId) => {
        return selectedPackage.tiers.find(tier => tier.id === selectedTier)?.productGroups.find(group => group.id === productGroupId);
    };

    const getProduct = (productGroupId, productId) => {
        const productGroup = getProductGroup(productGroupId);
        return productGroup?.products.find(product => product.id === productId);
    };

    const handleAddDiscount = () => {
        const discountData = { ...formState };
        onAddDiscount(discountData);
    };

    const handleAddTierPricing = (tierPricing) => {
        const existingTierPricing = tierPricingDiscounts.findIndex(tier => Number(tier.index) === Number(tierPricing.index));
        if (existingTierPricing !== -1) {
            tierPricingDiscounts[existingTierPricing] = tierPricing;
        } else {
            tierPricingDiscounts.push(tierPricing);
        }
        updateFormState("tierPricingDiscounts", tierPricingDiscounts);
        handleAddDiscount();
    };

    const handleRemoveTierPricing = (index) => {
        const existingTierPricing = tierPricingDiscounts.findIndex(tier => tier.index == index);
        tierPricingDiscounts.splice(existingTierPricing, 1);
        updateFormState("tierPricingDiscounts", tierPricingDiscounts);
        handleAddDiscount();
    };

    const productPricing = selectedChargePlan?.productGroupPricings
        .find(pgp => pgp.productGroupId === selectedProductGroupPricing)
        ?.productPricings.find(pp => pp.productId === selectedProductPricing);

    return (
        <fieldset className={`d-flex flex-column gap-16 ${disableEdit ? 'disabled' : ''}`} style={{ width: "304px" }}>
            {selectedPackage && <>
                <Dropdown
                    label="Tier"
                    options={selectedPackage.tiers}
                    value={selectedTier ?? -1}
                    onChange={handleTierChange}
                />
                <div className="form-control skynet-form-input">
                    <label>Plan</label>
                    <div className="d-flex flex-column">
                        <RadioButtons
                            items={selectedPackage.plans.sort((a, b) => a.bindingPeriodCycle.length.value - b.bindingPeriodCycle.length.value)} valueProperty="id"
                            labelProperty="name"
                            value={selectedPlan}
                            onChange={handlePlanChange}
                        />
                    </div>
                </div>
            </>}
            <Dropdown
                label="Product Group Pricing"
                options={selectedChargePlan?.productGroupPricings.map(pgp => ({
                    value: pgp.productGroupId,
                    name: `${getProductGroup(pgp.productGroupId)?.name}`,
                })) || []}
                value={selectedProductGroupPricing}
                onChange={(value) => { updateFormState("selectedProductGroupPricing", value); updateFormState("selectedProductPricing", null); }}
                valueProperty="value"
                required
                disabled={!selectedChargePlan}
            />
            <Dropdown
                label="Product Pricing"
                options={selectedProductGroupPricing && selectedChargePlan?.productGroupPricings.find(pgp => pgp.productGroupId === selectedProductGroupPricing)?.productPricings.map(pp => ({
                    value: pp.productId,
                    name: `${getProduct(selectedProductGroupPricing, pp.productId)?.name}`,
                })) || []}
                value={selectedProductPricing}
                onChange={(value) => handleProductChange(value)}
                valueProperty="value"
                required
                disabled={!selectedProductGroupPricing || !selectedChargePlan}
            />
            {productPricing && <>
                <label><span className="bold">Pricing scheme: </span>{VossPricingSchemeText[productPricing.pricingScheme]}</label>
                {productPricing.pricingScheme === VossPricingScheme.UnitPricing ? <>
                    <FormInput label="Unit price" value={productPricing.unitPrice != 0 ? productPricing.unitPrice : '0'} disabled />
                    <div className={`form-control skynet-form-input required ${!selectedProductPricing ? 'disabled' : ''}`}>
                        <label>Discount type</label>
                        <div className={`btn-group btn-group-primary d-block`}>
                            <button type="button" className={`btn mb-0 ${selectedDiscountType === VossDiscountType.FlatPrice ? 'active' : ''}`} style={{ width: "50%" }} onClick={() => updateFormState("selectedDiscountType", VossDiscountType.FlatPrice)}>Flat price</button>
                            <button type="button" className={`btn mb-0 ${selectedDiscountType === VossDiscountType.Percentage ? 'active' : ''}`} style={{ width: "50%" }} onClick={() => updateFormState("selectedDiscountType", VossDiscountType.Percentage)}>Percent</button>
                        </div>
                    </div>
                    <div>
                        <FormInput
                            disabled={selectedDiscountType == null || !selectedProductPricing}
                            required
                            label="Unit price discount"
                            value={unitPrice}
                            onChange={(value) => updateFormState("unitPrice", value)}
                        />
                    </div>
                    <div>
                        <button className="btn float-right" onClick={handleAddDiscount} disabled={
                            !selectedProductPricing ||
                            selectedDiscountType == null ||
                            !unitPrice
                        }>
                            {getExistingDiscount(chargePlanDiscounts, selectedChargePlan.id, selectedProductGroupPricing, selectedProductPricing) ? 'Update Discount' : 'Add Discount'}
                        </button>
                    </div>
                </> :
                    <>
                        <TierPricingTable tierPricingDiscounts={tierPricingDiscounts} onDeleteTierPricing={handleRemoveTierPricing} />
                        <TierPricingForm productPricing={productPricing} onAddTierPricing={handleAddTierPricing} />
                    </>
                }
            </>}
        </fieldset>
    );
}

function TierPricingForm({ productPricing, onAddTierPricing }) {

    const [tierPricingFormState, setTierPricingFormState] = useState({
        selectedDiscountType: VossDiscountType.FlatPrice,
        unitPriceDiscount: null,
        flatPriceDiscount: null,
        selectedIndex: null,
    });

    const { selectedDiscountType, unitPriceDiscount, flatPriceDiscount, selectedIndex } = tierPricingFormState;

    const updateTierPricingFormState = (field, value) => {
        setTierPricingFormState(prevState => ({ ...prevState, [field]: value }));
    };

    const handleAddTierPricing = () => {
        onAddTierPricing({
            discountType: selectedDiscountType,
            unitPriceDiscount: unitPriceDiscount || 0,
            flatPriceDiscount: flatPriceDiscount || 0,
            index: selectedIndex
        });
        setTierPricingFormState({
            selectedDiscountType: VossDiscountType.FlatPrice,
            unitPriceDiscount: null,
            flatPriceDiscount: null,
            selectedIndex: null,
        });

    };

    let index = productPricing.tierPricing.find(tier => tier.index === Number(selectedIndex));

    return (
        <>
            <Dropdown label='Index' options={productPricing.tierPricing} valueProperty="index" property="index" value={selectedIndex} onChange={(value) => updateTierPricingFormState('selectedIndex', value)} />
            {selectedIndex && <>
                <div className="d-flex gap-12">
                    {index.flatPrice ?
                        <FormInput label="Flat price" value={index.flatPrice != 0 ? index.flatPrice : '0'} disabled />
                        :
                        <FormInput label="Unit price" value={index.unitPrice != 0 ? index.unitPrice : '0'} disabled />
                    }
                    <FormInput label="Upper limit" value={index.upperLimit ?? 'None'} disabled />
                </div>
                <div className={`form-control skynet-form-input required`}>
                    <label>Discount type</label>
                    <div className={`btn-group btn-group-primary d-block`}>
                        <button type="button" className={`btn mb-0 ${selectedDiscountType === VossDiscountType.FlatPrice ? 'active' : ''}`} style={{ width: "50%" }} onClick={() => updateTierPricingFormState("selectedDiscountType", VossDiscountType.FlatPrice)}>Flat price</button>
                        <button type="button" className={`btn mb-0 ${selectedDiscountType === VossDiscountType.Percentage ? 'active' : ''}`} style={{ width: "50%" }} onClick={() => updateTierPricingFormState("selectedDiscountType", VossDiscountType.Percentage)}>Percent</button>
                    </div>
                </div>
                <FormInput label="Flat price" value={flatPriceDiscount} onChange={(value) => updateTierPricingFormState('flatPriceDiscount', value)} />
                <FormInput label="Unit price discount" value={unitPriceDiscount} onChange={(value) => updateTierPricingFormState('unitPriceDiscount', value)} />
                <div>
                    <button className="btn float-right" onClick={handleAddTierPricing} disabled={selectedDiscountType == null || (!unitPriceDiscount && !flatPriceDiscount) || selectedIndex == null}>Add tier pricing</button>
                </div>
            </>}
        </>
    );
}

function TierPricingTable({ tierPricingDiscounts, onDeleteTierPricing }) {
    return (
        <table className="table table-stripe skynet-table-no-divider">
            <thead>
                <tr>
                    <th>Index</th>
                    <th>Flat price discount</th>
                    <th>Unit price discount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tierPricingDiscounts?.length > 0 ? tierPricingDiscounts.map((tier, index) => (
                    <tr key={index}>
                        <td>{tier.index}</td>
                        <td>{tier.flatPriceDiscount}</td>
                        <td>{tier.unitPriceDiscount}</td>
                        <td>
                            <SkynetIcon icon="vismaicon-delete" onClick={() => onDeleteTierPricing(tier.index)} />
                        </td>
                    </tr>
                )) : <tr>
                    <td colSpan={4}><em>No tier pricing discounts added</em></td>
                </tr>}
            </tbody>
        </table>
    );
}

export default DiscountPricingForm;