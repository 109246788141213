import React, { Component } from 'react';
import { VictoryChart, VictoryLine, VictoryLabel, VictoryAxis, VictoryTooltip, VictoryScatter, VictoryPie } from 'victory';
import helpers from '../Utils/helpers'
import { withTranslation } from 'react-i18next';

export class MyDashboardCharts extends Component {


    hasGoal(goalData){
        if(!goalData){
            return false
        }

        return !(goalData[0].y <= 0)

    }

    render() {

        const tickStyle = {
            axis: {
                stroke: '#EBEDEE',
                strokeOpacity: 1
            },
            tickLabels: {
                fontFamily: 'inherit',
                fontSize: 8.5,
                fillOpacity: 0.5,
            },
            grid: {
                stroke: '#EBEDEE',
                strokeLinecap: 'round',
                strokeLinejoin: 'round'
            },
        };

        const lineStyle = {
            data: {
                stroke: '#2ECC71',
               
            }
        }

        const goalLineStyle= {
            data: {
                strokeDasharray: 5,
                stroke: '#003253'
            }
        }

        const scatterStyle = {
            data: {
                fill: '#FFFFFF',
                stroke: '#2ECC71',
                strokeWidth: 2
            }
        }

        const tooltipStyle = {
            fill: '#FFFFFF'
        }

        const labelStylePie = {
            fontFamily: 'sans-serif',
            fontSize: 15,
            fillOpacity: 0.9,
            fontWeight: 'bolder',
        }

        if (!this.props.data || !this.props.data.quantityData || !this.props.data.revenueData) {
            return null; //no data found on api
        }

        const quantityData = this.props.groupDataAction(this.props.data.quantityData, "x", this.props.accumulative);
        const quantityDataTotal = this.props.groupDataAction(this.props.data.quantityData, "x");
        const goalData = this.props.showgoals && this.props.goalData != null ? this.props.groupGoalAction(this.props.goalData) : null;
        const revenueData = this.props.groupDataAction(this.props.data.revenueData, "x", this.props.accumulative);
        const revenueDataTotal = this.props.groupDataAction(this.props.data.revenueData, "x");
        const pieData = this.props.groupDataAction(this.props.data.quantityData, "productName");
        const totalQuantity = this.props.groupTotalsAction(quantityDataTotal);
        const totalRevenue = this.props.groupTotalsAction(revenueDataTotal);
        const {t} = this.props
        return (
            <div>
                {totalQuantity === 0
                    ? this.props.renderNoData()
                    :
                    <div>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <h1 className="text-left ">{this.props.titlePrefix}{t('mydashboardchart.totalquantity')} <b>{totalQuantity}</b></h1>
                                <VictoryChart
                                    animate={{
                                        duration: 250,
                                        onLoad: { duration: 250 }
                                      }}
                                    padding={{ top: 50, left: 40, right: 35, bottom: 35 }}
                                    scale={{ x: "time" , y: "linear" }}
                                    domain={this.props.domain}>
                                    <VictoryLine
                                        style={lineStyle}
                                        data={quantityData}
                                        sortKey={"x"} />
                                    {goalData && this.hasGoal(goalData.quantityGoal) ? <VictoryLine
                                        style={goalLineStyle}
                                        data={goalData.quantityGoal}
                                        sortKey={"x"} /> : null}
                                    <VictoryAxis
                                        crossAxis
                                        style={tickStyle}
                                        tickLabelComponent={<VictoryLabel />} />
                                    <VictoryAxis
                                        dependentAxis
                                        crossAxis
                                        style={tickStyle}
                                        domain={{ y: [0, helpers.getArrayPropertyMaximum(quantityData, "y") + 1] }}
                                    />
                                    <VictoryScatter
                                        data={quantityData}
                                        style={scatterStyle}
                                        labels={({ datum }) => `${datum.y}`}
                                        labelComponent={<VictoryTooltip flyoutStyle={tooltipStyle} height={20} cornerRadius={0} />} />

                                </VictoryChart>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-body">
                                <h1 className="text-left">{this.props.titlePrefix} {t('mydashboardchart.totalrevenue')} <b>{helpers.currencyFormatter(totalRevenue)}</b></h1>
                                <VictoryChart
                                    animate={{
                                        duration: 250,
                                        onLoad: { duration: 250 }
                                      }}
                                    padding={{ top: 50, left: 40, right: 35, bottom: 35 }}
                                    scale={{ x: "time", y: "linear" }}
                                    domain={this.props.domain}>
                                    <VictoryLine
                                        style={lineStyle}
                                        data={revenueData} />
                                    <VictoryAxis
                                        crossAxis
                                        style={tickStyle}
                                        tickLabelComponent={<VictoryLabel />} />
                                        {goalData && this.hasGoal(goalData.revenueGoal) ? <VictoryLine
                                        style={goalLineStyle}
                                        data={goalData.revenueGoal}
                                        sortKey={"x"} /> : null}
                                    <VictoryAxis
                                        dependentAxis
                                        crossAxis style={tickStyle}
                                        domain={{ y: [0, helpers.getArrayPropertyMaximum(revenueData, "y") + 5000] }}
                                    />
                                    <VictoryScatter
                                        data={revenueData}
                                        style={scatterStyle}
                                        labels={({ datum }) => `${helpers.currencyFormatter(datum.y)}`}
                                        labelComponent={<VictoryTooltip flyoutStyle={tooltipStyle} height={20} cornerRadius={0} />} />
                                </VictoryChart>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <div className="panel-body">
                                <h1 className="text-left ">{this.props.titlePrefix} {t('mydashboardchart.saletypes')}</h1>
                                <VictoryPie
                                    data={pieData}
                                    x="dimension"
                                    innerRadius={100}
                                    padAngle={3}
                                    width={700}
                                    labelRadius={120}
                                    colorScale="cool"
                                    animate={{ duration: 500 }}
                                    labelComponent={
                                        <VictoryTooltip
                                            text={(datum) => `${datum.slice.data.productName}: ${datum.slice.data.y}`}
                                            cornerRadius={0}
                                            style={labelStylePie}
                                            flyoutStyle={tooltipStyle} />}>
                                </VictoryPie>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(MyDashboardCharts)