import React, { Component } from 'react';
import helpers from '../../Utils/helpers'
import { withTranslation } from 'react-i18next';

class RelatedLeads extends Component {
    
    renderNoDataFound() {
        const {t} = this.props
        return (<p>{t('relatedleads.noentriesfound')}</p>);
    }

    render() {
        const { leads, moveToDetails, height, t } = this.props;

        if (!leads) {
            return null
        }

        return (
            <div>
                <div className="panel panel-default" style={{ height: height }}>
                    <div className="panel-heading">{t('relatedleads.relatedleads')}</div>
                    <div className="panel-body" style={{ height: "calc(100% - 48px)" }}>
                        {leads.length === 0 ? this.renderNoDataFound() :
                            <div className="table-responsive" style={{ height: "100%" }}>
                                <table className="table table-stripe table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-left">{t('relatedleads.product')}</th>
                                            <th className="text-left">{t('relatedleads.lastaction')}</th>
                                            <th className="text-left">{t('relatedleads.assigned')}</th>
                                            <th className="text-left">{t('relatedleads.status')}</th>
                                            <th className="text-left">{t('relatedleads.lastactiondate')}</th>
                                            <th className="text-left">{t('relatedleads.created')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads.map((lead, index) => {

                                            return <tr className="cursorpointer" onClick={() => moveToDetails(lead.id)} key={index}>
                                                <td>{lead.productNumber}</td>
                                                <td>{lead.lastAction}</td>
                                                <td>{lead.assignee}</td>
                                                <td>{lead.status}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{helpers.formatDateAndTime(lead.lastActionDate)}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{helpers.formatDateAndTime(lead.dateCreated)}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(RelatedLeads);