
import React, {useEffect} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import routes from "../../../Constants/routes";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {bindActionCreators} from "redux";
import customerActions from "../../../Actions/customerActions";
import commonActions from "../../../Actions/commonActions";
import actionLogActions from "../../../Actions/actionLogActions";
import targetGroupActions from "../../../Actions/Voss/targetGroupActions";
import {connect} from "react-redux";
import {MainContainer} from "../../../Containers/Customer/MainContainer";

function ConnectedCustomerRow({customer, product, type}) {
    const {t} = useTranslation();

    if (!customer) return null;
    const customerType = () => {
        let icon = "user";
        let text = "main";

        if (!type) {
            icon = "users";
            text = "sub";
        }

        return <><SkynetIcon icon={`vismaicon-sm vismaicon-${icon} pt-2 mr-8`} title={t("connectedcustomers."+text)}/>{t("connectedcustomers."+text)}</>
    }

    return (
        <tr key={customer.customerNumber} onClick={() => window.open(routes.customerMain(customer.customerNumber), '_blank')} >
            <th>{customerType()}</th>
            <td>{customer.name}</td>
            <td>{customer.customerNumber}</td>
            <td>{customer.organizationNumber}</td>
            <td>{customer.product ? customer.product : product}</td>
        </tr>
    )
}

function ConnectedCustomerRows({customers, product, type}){
    return (customers?.map((customer, index) => {
        return <ConnectedCustomerRow key={index} customer={customer} product={product} type={type}/>
    }))
}
function ConnectedCustomers({vbCustomer, connectedCustomers, dataLoading, customerActions}) {
    const {t} = useTranslation();

    useEffect(() => {
        if (!connectedCustomers && !dataLoading) {
            customerActions.getConnectedCustomers(vbCustomer.customerNumber);
        }
    }, []);

    if (dataLoading || !connectedCustomers) {
        return <div className="spinner spinner-default-green m-auto d-block"/>
    }

    return (
        <div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr className="bold">
                        <th>{t('connectedcustomers.type')}</th>
                        <th>{t('connectedcustomers.customer')}</th>
                        <th>{t('connectedcustomers.custno')}</th>
                        <th>{t('connectedcustomers.orgno')}</th>
                        <th>{t('connectedcustomers.product')}</th>
                    </tr>
                </thead>
                <tbody>
                    <ConnectedCustomerRows customers={connectedCustomers.connectedLonSmartCustomers.mainCustomers} type={"Main"}/>
                    <ConnectedCustomerRows customers={connectedCustomers.connectedLonSmartCustomers.subCustomers}/>
                    <ConnectedCustomerRows customers={connectedCustomers.connectedSkattBokslutCustomers.mainCustomers} type={"Main"}/>
                    <ConnectedCustomerRows customers={connectedCustomers.connectedSkattBokslutCustomers.subCustomers}/>
                    <ConnectedCustomerRow customer={connectedCustomers.connectedVbCustomers.mainCustomer} product={"N/A"} type={"Main"}/>
                    <ConnectedCustomerRows customers={connectedCustomers.connectedVbCustomers.subCustomers} product={"N/A"}/>
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        connectedCustomers: state.CustomerReducer.connectedCustomers,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        dataLoading: state.CommonReducer.dataLoading.connectedCustomers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectedCustomers));