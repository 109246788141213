import React, { Component } from 'react';
import Checkbox from '../Checkbox';
import leadStatuses from '../../Constants/leadStatus';
import statusIcon from '../../Constants/statusIcon'
import { withTranslation } from 'react-i18next';
import PhoneLink from '../../Components/PhoneLink';
import SendEmailModal from '../../Components/Leads/SendEmailModal'
import helpers from "../../Utils/helpers";

class LeadsList extends Component {   

    constructor(props) {
        super(props);
        this.initState = {
            showSendEmailModal: false,
            emailActionLogId: 0,
            lead: null
        }
        this.state = this.initState;
    }

    renderNoData() {
        const {t} = this.props
        return (<p>{t('leadslist.noentriesfound')}</p>);
    }

    //is not in use
    getClassNameForStatus(leadStatus){
        if(leadStatus === leadStatuses.closed){
            return statusIcon.closed;
        }else if(leadStatus === leadStatuses.assigned){
            return statusIcon.new;
        }else if(leadStatus === leadStatuses.open){
            return statusIcon.open;
        }
    }   

    onMoveToDetails(e, leadId) {
        
        // Ugly way of handling this but it works as long the html doesn't change...
        if(e.target.className === "preventRowClick" || e.target.tagName === "INPUT" || e.target.tagName === "LABEL")
            this.props.onLineSelect(leadId);
        
        if(e.target.className !== "preventRowClick")
            this.props.moveToDetails(leadId)
    }

    renderStyles(){
        if(!this.props.styles)
            return null;

        return this.props.styles;
    }

    sortList() {
        const jsonSort = localStorage.getItem("sortOrder_myLeads");
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    toggleModal = (modalName, lead, emailActionLogId = 0) => {
        
        this.setState({
            [modalName]: !this.state[modalName],
            emailActionLogId: emailActionLogId,
            lead: lead
        });
    }

    onSendbuttonClick = (modalName) => {
        this.toggleModal(modalName);
    }
    
    render() {
        const { leads, hideCustomer, onLineSelect, selectedLeads, sortListByKey, activeLead, t, onClick} = this.props;

        let showCheckbox = leads.some(lead => !lead.isClosed) && onLineSelect;
        const sort = this.sortList();
        const sortOrder = sort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";   
        const sortKey = sort.key;

        if (!leads || leads.length === 0) {
            return this.renderNoData()
        }
        
        return <div className="table-responsive" style={this.renderStyles()}>
            <table className="table table-stripe table-hover table-active">
                <thead>
                    <tr>
                        {showCheckbox && <th className="text-center" width="30"></th>}
                        <th className="text-left sortable" onClick={() => sortListByKey('status')}>{t('leadslist.status')} {sortKey === 'status' && <i className={sortOrder}></i>}</th>
                        {!hideCustomer && <th className="text-left sortable" onClick={() => sortListByKey('submitted')}>{t('leadslist.submitted')} {sortKey === 'submitted' && <i className={sortOrder}></i>}</th>}
                        {!hideCustomer && <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('leadslist.customernumber')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>}
                        {!hideCustomer && <th className="text-left sortable" onClick={() => sortListByKey('name')}>{t('leadslist.name')} {sortKey === 'name' && <i className={sortOrder}></i>}</th>}
                        <th className="text-left sortable" onClick={() => sortListByKey('contact')}>{t('leadslist.contact')} {sortKey === 'contact' && <i className={sortOrder}></i>}</th>
                        {!hideCustomer && <th className="text-left sortable" onClick={() => sortListByKey('email')}>{t('leadslist.email')} {sortKey === 'email' && <i className={sortOrder}></i>}</th>}
                        {!hideCustomer && <th className="text-left sortable" onClick={() => sortListByKey('phonenumber')}>{t('leadslist.phonenumber')} {sortKey === 'phoneNumber' && <i className={sortOrder}></i>}</th>}
                        <th className="text-left sortable" onClick={() => sortListByKey('productNumber')}>{t('leadslist.type')} {sortKey === 'productNumber' && <i className={sortOrder}></i>}</th>
                        <th className="text-left sortable" onClick={() => sortListByKey('lastActionDate')}>{t('leadslist.lastaction')} {sortKey === 'lastActionDate' && <i className={sortOrder}></i>}</th>
                    </tr>
                </thead>
                <tbody>

                    {leads.map((lead, index) => {
                        let classes = "cursorpointer";
                        let checked = false;
                        if (selectedLeads) {

                            let result = selectedLeads.find(item => item.id === lead.id);
     
                            if (result !== undefined && result.id === lead.id) {
                                classes = "cursorpointer active";
                                checked = true;
                            }
                        }

                        if(lead.isClosed)
                            showCheckbox = false;

                        if(activeLead)
                            classes = activeLead.id === lead.id ? classes + " active" : classes;
                        
                        return <tr className={classes} key={index} onClick={(e) => this.onMoveToDetails(e, lead.id)} >
                            {showCheckbox && <td className="preventRowClick"><Checkbox checked={checked} onChange={() => this.props.onLineSelect(lead.id)} pure={true} /></td>}
                            <td>{lead.status}</td>
                            {!hideCustomer && <td className="text-nowrap">{helpers.formatDateAndTime(lead.dateSubmitted)}</td>}
                            {!hideCustomer && <td>{lead.customerNumber ? lead.customerNumber : 'N/A'}</td>}
                            {!hideCustomer && <td>{lead.name}</td>}
                            <td>{lead.contact}</td>
                            {!hideCustomer && <td>{lead.email} {<span onClick={() => this.toggleModal("showSendEmailModal", lead)} className="pull-right vismaicon vismaicon-sm vismaicon-email"></span>}</td>}
                            {!hideCustomer && <td>{lead.phoneNumber} {lead.phoneNumber && <PhoneLink phoneNumber={lead.phoneNumber} icon={true} />}</td>}
                            <td>{lead.productNumber ? `${lead.type}: ${lead.productNumber}` : lead.type}</td>
                            <td>{lead.lastAction === null ? '' : `${lead.lastAction} ${lead.lastActionDate}`}</td>
                        </tr>
                    })}
                    {this.state.showSendEmailModal && <SendEmailModal leadType="lead" lead={this.state.lead} emailActionLogId={this.state.emailActionLogId}  />}
                </tbody>
            </table>
        </div>
    }
}

export default withTranslation()(LeadsList);