import React, { Component } from 'react';

import MyAppointmentsTemplatesContainer from './MyAppointmentsTemplatesContainer'
import MyAppointmentsSignatureContainer from './MyAppointmentsSignatureContainer'


class MyAppointmentsSettingsContainer extends Component {

    render() {
        return <>
            <MyAppointmentsSignatureContainer history={this.props.history} />
            <MyAppointmentsTemplatesContainer history={this.props.history} />
        </>

    }
}

export default MyAppointmentsSettingsContainer;