import React from 'react';
import Tabs, { TabContent } from './Tabs.js';

export function ContentTabs({ tabs, initialTab }) {
    return (<>
        <Tabs tabs={tabs} initialTab={initialTab}>
            <TabContent tabs={tabs} />
        </Tabs>
    </>)
}
