import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import routes from '../../Constants/routes'

import LeadsList from '../../Components/Leads/LeadsList'
import NewLeadButton from '../../Components/Leads/NewLeadButton'
import SelectedLeadsButton from '../../Components/Leads/SelectedLeadsButton'
import CloseModal from '../../Components/Leads/CloseModal'

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'
import NewLeadsModal from '../../Components/Leads/NewLeadsModal';
import Panel from "../../Components/Panel";

class MyOpenLeadsContainer extends Component {

    constructor(props) {
        super(props);
        this.initState = {
            showCloseModal: false,
            showNewLeadModal: false,
            filterStatement: localStorage.getItem("leadTypeFilter")
        }
        this.state = this.initState;
    }

    onClearClick = () => {
        this.props.leadActions.clearSearch();
    }

    sortListByKey(listName, list) {
        return key => {
            const { commonActions } = this.props;
            commonActions.sortListByKey(list, key, listName);
        }
    }

    onLineSelect = (leadId) => {
        const lead = this.props.leads.find(item => item.id === leadId);
        this.props.leadActions.selectLead(lead, this.props.selectedLeads);
    }

    deselectAll = () => {
        this.props.leadActions.clearSelectedLeads();
    }

    toggleModal = (modalName) => {
        this.setState({
            [modalName]: !this.state[modalName]
        });
    }

    onCloseClick = (isModal) => {

        if (isModal)
            this.state.showCloseModal = false;
    }

    onClosedLeadsClick = () => {

        this.setState({ filterStatement : "closedLeads" });
        this.props.leadActions.getMyClosedLeads();
    }

    filterLeads = (filterStatement) => {
        localStorage.setItem("leadTypeFilter", filterStatement);

        this.setState({ filterStatement });
    }

    renderLeadsCounter = (leads) => {
        const countLeads = leads.length;
        if (countLeads > 0)
            return <span className="badge badge-default">{countLeads}</span>
    }


    render() {
        const { leads, leadTypes, leadActions, selectedLeads, activeLead, t, closedLeads } = this.props;

        if (!leads) {
            return null;
        }

        const filter = this.state.filterStatement;

        let myLeads = leads;
        let filterOpenLeads = false;
        let filterClosedLeads = false;
        let filterAssignedLeads = false;

        const assignedLeads = leads.filter(lead => !lead.isClosed && lead.lastAction === "Assigned");
        const openLeads = leads.filter(lead => !lead.isClosed && lead.lastAction !== "Assigned");

        if (filter === "openLeads" || !filter) {
            myLeads = openLeads
            filterOpenLeads = true;
        }

        if (filter === "closedLeads") {
            myLeads = closedLeads
            filterClosedLeads = true;
        }

        if (filter === "assignedLeads") {
            myLeads = assignedLeads
            filterAssignedLeads = true;
        }

        return <>
                {this.state.showCloseModal && <CloseModal multiple={true} onClose={this.toggleModal.bind(this, "showCloseModal")} onSave={() => this.onCloseClick(true)} />}
                {this.state.showNewLeadModal && <NewLeadsModal multiple={true} onClose={this.toggleModal.bind(this, "showNewLeadModal")} onSave={leadActions.assingLeads} leadTypes={leadTypes} />}
                
                <Panel title={t('myopenleadscontainer.myleads')}>
                <Link to={routes.leadsMySettings}><span className="vismaicon vismaicon-settings pull-right margin-right cursorpointer" title={t('myopenleadscontainer.settings')}></span></Link>
                    
                        <div className='mb-10'>
                            <button className={filterAssignedLeads ? "btn btn-default active" : "btn btn-default"} onClick={() => this.filterLeads("assignedLeads")}>{t('myopenleadscontainer.assigned')}  {this.renderLeadsCounter(assignedLeads)}</button>
                            <button className={filterOpenLeads ? "btn btn-default active" : "btn btn-default"} onClick={() => this.filterLeads("openLeads")}>{t('myopenleadscontainer.openleads')} {this.renderLeadsCounter(openLeads)}</button>
                            <button className={filterClosedLeads ? "btn btn-default active" : "btn btn-default"} onClick={() => this.onClosedLeadsClick()}>{t('myopenleadscontainer.closedleads')}</button>
                            <div className='float-right'>
                                <button type="button"
                                    className={`btn btn-primary no-margin`} onClick={this.toggleModal.bind(this, "showNewLeadModal")}>{t('myopenleadscontainer.newleads')}</button>
                                <div className="clearfix"></div>
                                <SelectedLeadsButton selectedLeads={selectedLeads} deselectAll={this.deselectAll} toggleModal={this.toggleModal} />
                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <div>
                            {myLeads?.length < 1 && <p>{t('myopenleadscontainer.noentriesfound')}</p>}
                            {myLeads?.length > 0 && <LeadsList styles={{ height: "917px" }} leads={myLeads} activeLead={activeLead} onLineSelect={this.onLineSelect} moveToDetails={this.props.moveToDetails} selectedLeads={selectedLeads} sortListByKey={this.sortListByKey("myLeads", leads)} />}
                        </div>
                </Panel>
            </>
    }
}

function mapStateToProps(state) {
    return {
        leads: state.LeadsReducer.myLeads,
        closedLeads: state.LeadsReducer.myClosedLeads,
        selectedLeads: state.LeadsReducer.selectedLeads,
        leadTypes: state.LeadsReducer.leadTypes,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyOpenLeadsContainer));