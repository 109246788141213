import { commonActionTypes } from "../../ActionTypes/commonActionTypes";
import { discountAgreementActionTypes as actionTypes } from "../../ActionTypes/Voss/discountAgreementActionTypes";
import { httpFetch } from "../../Utils/httpUtils";
import commonActions from "../commonActions";
import packageActions from "./packageActions";

const discountAgreementActions = {

    createDiscountAgreement(form, callback) {
        return dispatch => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    packageId: form.data.packageId,
                    name: form.data.name,
                    validFrom: form.data.validFrom,
                    validTo: form.data.validTo,
                    applicationScope: form.data.applicationScope,
                    discountPeriod: {
                        periodKind: form.data.periodKind,
                        periodIterationCount: form.data.periodIterationCount,
                        length: {
                            value: form.data.length,
                            unit: form.data.unit
                        },
                    },
                    customerGroups: form.data.customerGroups,
                    customers: form.data.customers,
                    externalId: form.data.externalId,
                    chargePlanDiscounts: form.data.chargePlanDiscounts
                })
            }

            return httpFetch("/api/discountAgreements/CreateDiscountAgreement", dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`${json.name} successfully created!`));
                    if (callback) {
                        callback();
                    }
                })
        }
    },


    getDiscountAgreements() {
        return (dispatch) => {
            return httpFetch(`/api/discountAgreements/GetDiscountAgreements`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_DISCOUNT_AGREEMENTS, data: json });
                })
        }
    },

    getDiscountAgreement(id, versionId) {
        return (dispatch) => {

            return httpFetch(`/api/discountAgreements/GetDiscountAgreement/${id}${versionId ? `?versionId=${versionId}` : ''}`, dispatch)
                .then(json => {
                    return json;
                });
        };
    },

    getDiscountAgreementVersions(id) {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "discountversions", value: true});
            return httpFetch(`/api/discountAgreements/GetDiscountAgreementVersions/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "discountversions", value: false});
                    dispatch({ type: actionTypes.STORE_DISCOUNT_AGREEMENT_VERSIONS, data: json });
                })
        }
    },

    getDiscountAgreementForEdit(id, versionId) {
        return (dispatch) => {
            return (this.getDiscountAgreement(id, versionId))
                .then(json => {
                    const form = {
                        packageId: json.package.id,
                        name: json.name,
                        validFrom: json.validFrom,
                        validTo: json.validTo,
                        status: json.status + "",
                        applicationScope: json.applicationScope + "",
                        periodKind: json.discountPeriod.periodKind + "",
                        periodIterationCount: json.discountPeriod.periodIterationCount,
                        length: json.discountPeriod.length && json.discountPeriod.length.value,
                        unit: json.discountPeriod.length && json.discountPeriod.length.unit + "",
                        externalId: json.externalId,
                        customerGroups: json.customerGroups,
                        customers: json.customers,
                        chargePlanDiscounts: json.chargePlanDiscounts,
                    };
                    dispatch({ type: actionTypes.STORE_DISCOUNT_AGREEMENT, data: json });
                    this.getChargePlanDiscounts(id, versionId);
                    dispatch(packageActions.getPackage(json.package.id))
                    dispatch(commonActions.startFormEditing("editDiscountAgreementForm", form));
                })
        }
    },

    createNewDiscountAgreementVersion(discountAgreementId, versionId, callback) {
        return (dispatch) => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    versionId: versionId
                })
            };

            return httpFetch(`/api/discountAgreements/CreateNewDiscountAgreementVersion/${discountAgreementId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`New version of ${json.name} created!`));
                    if (callback && typeof callback === 'function') {
                        callback(json);
                    }
                    return json;
                }
                );
        };
    },

    updateDiscountAgreement(discountAgreementId, versionId, form, callback) {
        return dispatch => {
            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    packageId: form.data.packageId,
                    name: form.data.name,
                    status: form.data.status,
                    validFrom: form.data.validFrom,
                    validTo: form.data.validTo,
                    applicationScope: form.data.applicationScope,
                    discountPeriod: {
                        periodKind: form.data.periodKind,
                        periodIterationCount: form.data.periodIterationCount,
                        length: {
                            value: form.data.length ?? 0,
                            unit: form.data.unit ?? 0
                        }
                    },
                    customers: form.data.customers,
                    customerGroups: form.data.customerGroups,
                    externalId: form.data.externalId
                })
            }

            return httpFetch(`/api/discountAgreements/UpdateDiscountAgreement/${discountAgreementId}${versionId ? `?versionId=${versionId}` : ''}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`${json.name} successfully updated!`));
                    if (callback) {
                        callback();
                    }
                });
        };
    },

    getChargePlanDiscounts(discountAgreementId, versionId) {
        return (dispatch) => {
            return httpFetch(`/api/discountAgreements/GetChargePlanDiscounts/${discountAgreementId}${versionId ? `?versionId=${versionId}` : ''}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CHARGE_PLAN_DISCOUNTS, data: json });
                });
        };
    },

    deleteProductPricingDiscount(discountAgreementId, chargePlanDiscountId, productGroupDiscountId, productPricingDiscountId, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'DELETE'
            }

            return httpFetch(`/api/discountAgreements/DeleteProductPricingDiscount/${discountAgreementId}/${chargePlanDiscountId}/${productGroupDiscountId}/${productPricingDiscountId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Product pricing discount deleted!'));

                    if (callback) {
                        callback();
                    }
                });
        };
    },

    deleteChargePlanDiscount(discountAgreementId, chargePlanDiscountId, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'DELETE'
            }

            return httpFetch(`/api/discountAgreements/DeleteChargePlanDiscount/${discountAgreementId}/${chargePlanDiscountId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Charge plan discount deleted!'));

                    if (callback) {
                        callback();
                    }
                });
        };
    },

    createChargePlanDiscount(discountAgreementId, chargePlanDiscount, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(chargePlanDiscount)
            }

            return httpFetch(`/api/discountAgreements/CreateChargePlanDiscount/${discountAgreementId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Charge plan discount created!'));
                    if (callback) {
                        callback();
                    }
                });
        };
    },

    createProductPricingDiscount(discountAgreementId, chargePlanDiscountId, productGroupDiscountId, productPricingDiscount, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(productPricingDiscount)
            }

            return httpFetch(`/api/discountAgreements/CreateProductPricingDiscount/${discountAgreementId}/${chargePlanDiscountId}/${productGroupDiscountId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Product pricing discount created!'));

                    if (callback) {
                        callback();
                    }
                });
        };
    },

    createProductGroupDiscount(discountAgreementId, chargePlanDiscountId, productGroupDiscount, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(productGroupDiscount)
            }

            return httpFetch(`/api/discountAgreements/CreateProductGroupDiscount/${discountAgreementId}/${chargePlanDiscountId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Product group discount created!'));

                    if (callback) {
                        callback();
                    }
                });
        };
    },

    updateProductPricingDiscount(discountAgreementId, chargePlanDiscountId, productGroupDiscountId, productPricingDiscountId, productPricingDiscount, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify(productPricingDiscount)
            }

            return httpFetch(`/api/discountAgreements/UpdateProductPricingDiscount/${discountAgreementId}/${chargePlanDiscountId}/${productGroupDiscountId}/${productPricingDiscountId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Product pricing discount updated!'));

                    if (callback) {
                        callback();
                    }
                });
        };
    }
};

export default discountAgreementActions;
