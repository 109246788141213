import DiscountInfoForm from "../../../Components/Voss/DiscountAgreements/DiscountInfoForm";
import DiscountStatus from "../../../Components/Voss/DiscountAgreements/DiscountStatus";

function DiscountInfoFormContainer({ onChange, ...props }) {

    const { discountAgreement, readOnly } = props;

    return <div className="d-flex">
        <div className={`col-7 ${readOnly ? 'disabled' : ''}`}>
            <DiscountInfoForm onChange={onChange} {...props} />
        </div>
        {discountAgreement && <div className="ml-auto col-2">
            <DiscountStatus status={discountAgreement.status} {...props} />
        </div>}
    </div>
}

export default DiscountInfoFormContainer;