import React from 'react';
import { withTranslation } from 'react-i18next';
import ButtonDropdown from '../ButtonDropdown'

const LeadDetailActions = ({ onClick, onNoAnswerClick, onBuyClick, hasCustomer, leadType, t, onBusyClick }) => {

    const buyDropdownOptions = [{ title: 'Place Order', identifier: "order" }, { title: 'Close', identifier: "close" }]

    return <div className="panel panel-primary leads-panel">
        <div className="panel-body text-center">
            <legend className="text-left">{t("appointmentdetailactions.actions")}</legend>
            <button className="btn btn-default" style={{marginRight: "10px"}} onClick={() => onBusyClick()}>{t("appointmentdetailactions.busy")}</button>
            <button className="btn btn-default" style={{marginRight: "10px"}} onClick={() => onNoAnswerClick()}>{t("appointmentdetailactions.noanswer")}</button>
            <button className="btn btn-default" onClick={() => onClick("showSendEmailModal")}>{t("appointmentdetailactions.sendemail")}</button>
            {leadType !== "AoLead" && <ButtonDropdown title="Buy" disabled={!hasCustomer} options={buyDropdownOptions} onClickAction={onBuyClick} defaultStyle={true}>{t("appointmentdetailactions.buy")}</ButtonDropdown>}
            {leadType === "AoLead" && <button className="btn btn-default" onClick={() => onBuyClick("close")}>{t("appointmentdetailactions.buy")}</button>}
            <button className="btn btn-default" style={{marginLeft: "10px"}} onClick={() => onClick("showAppointmentModal")}>{t("appointmentdetailactions.appointment")}</button>          
            <button className="btn btn-default" onClick={() => onClick("showCloseModal")}>{t("appointmentdetailactions.close")}</button>
        </div>
    </div>
}

export default withTranslation()(LeadDetailActions);