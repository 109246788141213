import React, { Component } from 'react';
import DropDown from '../DropDown';
import helpers from '../../Utils/helpers';
import { withTranslation } from 'react-i18next';
import { DropDownToggle } from '../DropDownToggle';

class InternalLeadsList extends Component {

    constructor(props) {
        super(props);
        this.initState = {
            filterState: localStorage.getItem("AoLeadDepartment"),
            filterOptions: false,
            isOpen: false,
            i: -1,
            filterUsed: false,
            selectedSalesPerson: null,
            filterSalesPersons: false
        }
        this.state = this.initState;
        this.filterChoices = this.filterChoices.bind(this);
        this.filterSalesPersons = this.filterSalesPersons.bind(this);
    }

    componentDidMount() {
        if (this.state.filterState === "cs" || this.state.filterState === "sale") {
            this.filterIsUsed(true)
        }
    }

    renderNoData() {
        const { t } = this.props
        return (<p>{t('internalleadslist.noentriesfound')}</p>);
    }

    onMoveToDetails(e, leadId) {

        if (e.target.className !== "preventRowClick" && e.target.tagName !== "INPUT" && e.target.tagName !== "SELECT" && e.target.tagName !== "BUTTON")
            this.props.moveToDetails(leadId)
    }

    assignTo(leadId, userId) {
        this.props.AssignTo(leadId, userId)
    }

    sortList(listName) {
        const jsonSort = localStorage.getItem("sortOrder_" + listName);
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    filterLeads = (filterState) => {
        localStorage.setItem("AoLeadDepartment", filterState.id);
        this.setState({ filterState : filterState.id });
        if (filterState.id !== "all") {
            this.filterIsUsed(true);
        } else {
            this.filterIsUsed(false)
        }
        this.filterChoices();
    }

    filterLeadsBySalesPerson = (filterState) => {
        if (filterState.id === "all") {
            this.setState({ selectedSalesPerson: null });
        } else {
            this.setState({ selectedSalesPerson: filterState.id });
        }

        this.filterSalesPersons();

    }

    filterChoices() {
        this.setState({ filterOptions: !this.state.filterOptions });
    }

    filterSalesPersons() {
        this.setState({ filterSalesPersons: !this.state.filterSalesPersons });
    }

    filterIsUsed(filter) {
        this.setState({ filterUsed: filter })
    }

    render() {
        const { leads, sortListByKey, salesPersons, user, listType, t } = this.props;

        if (!leads || leads.length === 0) {
            return this.renderNoData()
        }

        let filteredLeads = leads;
        const filter = this.state.filterState;
        const selectedSalesPerson = this.state.selectedSalesPerson;

        const sort = this.sortList("internalLeads");
        const searchSort = this.sortList("internalLeadSearchResults")
        const sortOrder = sort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
        const searchSortOrder = searchSort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
        const sortKey = sort.key;
        const searchSortKey = searchSort.key;
        const closedList = listType === "closedList" ? true : false;
        const mainList = listType === "mainList" ? true : false;
        const searchList = listType === "searchList" ? true : false;
        const csLeads = (leads || []).filter(lead => lead.departmentId ? lead.departmentId.substring(0, 3) === '605' : false);
        const saleLeads = (leads || []).filter(lead => lead.departmentId ? lead.departmentId.substring(0, 3) !== '605' : true);
        const className = `${this.state.isOpen ? 'open' : ''}`;

        if (filter === "cs")
            filteredLeads = csLeads;

        if (filter === "sale")
            filteredLeads = saleLeads;

        if (selectedSalesPerson != null) {
            filteredLeads = filteredLeads.filter(lead => lead.assigneeUserId === selectedSalesPerson)
        }

        let showAssignColumn;

        if (mainList && salesPersons) {

            const userHasAllLeads = filteredLeads.every(lead => lead.assigneeUserId === user.id);

            const userExistInSalesPersonlist = salesPersons.some(item => item.id === user.id);

            if (!userHasAllLeads)
                showAssignColumn = true;

            if ((userExistInSalesPersonlist && userHasAllLeads) || !userExistInSalesPersonlist)
                showAssignColumn = false;

        }

        const filterList = [
            { id: 'all', label: t('internalleadslist.all') },
            { id: 'cs', label: t('internalleadslist.cs') },
            { id: 'sale', label: t('internalleadslist.sale') }
        ]

        const salesPersonsOptions = [
            { id: 'all', label: t('internalleadslist.all') }
        ];

        if (salesPersons)
            salesPersons.filter(s => s.name != "").forEach(salesPerson => {
                var leadAmount = leads.filter(l => l.assigneeUserId === salesPerson.id).length
                if(leadAmount > 0)
                salesPersonsOptions.push({ id: salesPerson.id, label: salesPerson.name + " - " + leadAmount + (leadAmount == 1 ? " lead" : " leads")})
            });

        return <div className="table">
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        {mainList && <th className="text-left sortable" onClick={() => sortListByKey('priority')}>{t('internalleadslist.priority')} {sortKey === 'priority' && <i className={sortOrder}></i>}</th>}
                        <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('internalleadslist.customernumber')} {mainList && sortKey === 'customerNumber' && <i className={sortOrder}></i> || searchList && searchSortKey === 'customerNumber' && <i className={searchSortOrder}></i>} </th>
                        <th className="text-left sortable" onClick={() => sortListByKey('name')}>{t('internalleadslist.name')} {mainList && sortKey === 'name' && <i className={sortOrder}></i> || searchList && searchSortKey === 'name' && <i className={searchSortOrder}></i>} </th>
                        <th className="text-left sortable" onClick={() => sortListByKey('contact')}>{t('internalleadslist.contact')} {mainList && sortKey === 'contact' && <i className={sortOrder}></i> || searchList && searchSortKey === 'contact' && <i className={searchSortOrder}></i>} </th>
                        {(mainList || searchList) && <th colSpan={showAssignColumn ? 2 : 1} className="text-left sortable"><DropDownToggle buttonLabel={t("internalleadslist.assignedto")} items={salesPersonsOptions} itemOnClick={this.filterLeadsBySalesPerson}/></th>}
                        {closedList && <th className="text-left sortable" onClick={() => sortListByKey('closedBy')}>{t('internalleadslist.closedby')} {sortKey === 'closedBy' && <i className={sortOrder}></i>}</th>}
                        {closedList && <th className="text-left sortable" onClick={() => sortListByKey('closed')}>{t('internalleadslist.closed')} {sortKey === 'closed' && <i className={sortOrder}></i>}</th>}
                        {searchList && <th className="text-left sortable" onClick={() => sortListByKey('isClosed')}>{t('internalleadslist.status')} {searchSortKey === 'isClosed' && <i className={searchSortOrder}></i>}</th>}
                        <th className="text-left sortable" width="150px"><DropDownToggle buttonLabel={t('internalleadslist.createdby')} items={filterList} itemOnClick={this.filterLeads}/></th>
                        <th className="text-left sortable" onClick={() => sortListByKey('dateCreated')}>{t('internalleadslist.created')} {mainList && sortKey === 'dateCreated' && <i className={sortOrder}></i> || searchList && searchSortKey === 'dateCreated' && <i className={searchSortOrder}></i>} </th>
                        <th className="text-left sortable" onClick={() => sortListByKey('lastAction')}>{t('internalleadslist.lastaction')} {mainList && sortKey === 'lastAction' && <i className={sortOrder}></i> || searchList && searchSortKey === 'lastAction' && <i className={searchSortOrder}></i>} </th>
                        {closedList && <th className="text-left sortable" onClick={() => sortListByKey('amount')}>{t('internalleadslist.saleamount')} {mainList && sortKey === 'amount' && <i className={sortOrder}></i> || searchList && searchSortKey === 'amount' && <i className={searchSortOrder}></i>} </th>}
                    </tr>
                </thead>
                <tbody>

                    {filteredLeads.map((lead, index) => {

                        let assignButtonHidden;
                        let priority = '';

                        if (lead.priorityType === "high")
                            priority = "vismaicon vismaicon-priority-high";
                        else if (lead.priorityType === "medium")
                            priority = "vismaicon vismaicon-priority-medium";
                        else if (lead.priorityType === "low")
                            priority = "vismaicon vismaicon-priority-low";

                        if (mainList && salesPersons) {

                            var currentUserExistInSalesPersons = salesPersons.some(item => item.id === user.id);

                            assignButtonHidden = user.id === lead.assigneeUserId || !currentUserExistInSalesPersons ? true : false;

                        }

                        return <tr className="cursorpointer" key={lead.id} onClick={(e) => this.onMoveToDetails(e, lead.id)}>
                            {mainList && <td><i className={priority}></i></td>}
                            <td>{lead.customerNumber}</td>
                            <td onMouseEnter={() => { this.setState({ isOpen: true, i: index }) }} onMouseLeave={() => { this.setState({ isOpen: false, i: index }) }}>
                                {lead.name}
                                {className && this.state.i === index && <span className="table-content-hover" dangerouslySetInnerHTML={{ __html: lead.description }}></span>}
                            </td>
                            <td>{lead.contact}</td>
                            {mainList && <td className="preventRowClick" style={{ minWidth: 200 }}>
                                <DropDown elementWidth={12} list={salesPersons} value={lead.assigneeUserId} onChange={value => this.assignTo(lead.id, value)} />
                            </td>}
                            {closedList && <td>{lead.closedBy}</td>}
                            {closedList && <td>{helpers.formatDateAndTime(lead.dateClosed)}</td>}

                            {showAssignColumn && <td className="preventRowClick">
                                {!assignButtonHidden &&
                                    <div className="form-group">
                                        <button className="btn btn-default" onClick={() => this.assignTo(lead.id, user.id)}>{t('internalleadslist.assigntome')}</button>
                                    </div>
                                }
                            </td>}
                            {searchList && <td>{lead.assignedTo}</td>}
                            {searchList && <td>{lead.isClosed ? t('internalleadslist.closed') : t('internalleadslist.open')}</td>}
                            <td>{lead.createdBy}</td>
                            <td>{helpers.formatDateAndTime(lead.dateCreated)}</td>
                            <td>{lead.lastAction === null ? '' : `${lead.lastAction} ${lead.lastActionDate}`}</td>
                            {closedList && <td>{lead.amount > 0 ? helpers.currencyFormatter(lead.amount) : ""}</td>}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}

export default withTranslation()(InternalLeadsList);