import React, { Component } from 'react';

import TextInput from '../TextInput'
import Loader from '../Loader'
import DateTimeInput from '../../Components/DateTimeInput'
import { withTranslation } from 'react-i18next';

export class InternalCustomerCreateForm extends Component {

    render() {

        const { data, fieldChangeAction, t } = this.props;

        if (!data) {
            return null;
        }

        return (
            <form className="form-horizontal customerFormEdit">

                <fieldset className="col-xs-6">
                    <legend>{t('internalcustomercreateform.details')}</legend>
                    <TextInput label={t('internalcustomercreateform.firstname')} onChange={(value) => fieldChangeAction('firstName', value)} value={data.firstName} />
                    <TextInput label={t('internalcustomercreateform.lastname')} onChange={(value) => fieldChangeAction('lastName', value)} value={data.lastName} />
                    <TextInput label={t('internalcustomercreateform.companyname')} onChange={(value) => fieldChangeAction('companyName', value)} value={data.companyName} />
                    <DateTimeInput label={t("internalcustomercreateform.validto")} showTime={false} onChange={(value) => fieldChangeAction('removalTime', value)} value={data.removalTime} closeOnSelect={false} />
                    <TextInput label={t('internalcustomercreateform.emailaddress')} onChange={(value) => fieldChangeAction('emailAddress', value)} value={data.emailAddress} type="email" />
                </fieldset>

                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={this.props.saveAction}>{t('internalcustomercreateform.create')}</button>
                    <Loader />
                </div>
            </form>
        );
    }
}

export default withTranslation()(InternalCustomerCreateForm);