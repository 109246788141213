import React from 'react';
import helper from '../../Utils/helpers'
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

const PriceExplanation = ({ data } ) => {
    const { t } = useTranslation();
    return  <div className="row priceExplanation">
                <div className="col-xs-6">
                    <h4>{t('priceexplanation.main')}</h4>
                    { Item(t('priceexplanation.license'), data.licensePrice) }
                    { Item(t('priceexplanation.serviceagreement'), data.serviceAgreementPrice) }
                    { Item(t('priceexplanation.credit'), data.credit * -1) }
                </div>
                <div className="col-xs-6">
                    <h4>{t('priceexplanation.additionalusers')}</h4>
                    { Item(t('priceexplanation.license'), data.additionalUserLicensePrice) }
                    { Item(t('priceexplanation.serviceagreement'), data.additionalUserServiceAgreementPrice) }

                    <h4>{t('priceexplanation.api')}</h4>
                    { Item(t('priceexplanation.license'), data.apiLicensePrice) }
                    { Item(t('priceexplanation.serviceagreement'), data.apiServiceAgreementPrice) }

                    <h4>{t('priceexplanation.discounts')}</h4>
                    { Item(t('priceexplanation.customerdiscount'), data.customerDiscount) }
                    { Item(t('priceexplanation.discount'), data.discount * -1) }
                    { Item(t('priceexplanation.discountpercent'), data.discountPercent + '%', true) }
                </div>
            </div>
}

const Item = (label, value, plain) => {
    return  <div className="row">
                <div className="col-xs-7">{ label }</div>
                <div className="col-xs-5">{ plain ? value : helper.currencyFormatter(value) }</div>
            </div>
}

PriceExplanation.propTypes = {
    data: PropTypes.object.isRequired
};

export default PriceExplanation