import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import StaticTextInput from '../../Components/StaticTextInput'
import routes from '../../Constants/routes'
import helpers from '../../Utils/helpers'
import { useNavigate, useParams } from 'react-router-dom';

import customerActions from '../../Actions/customerActions'
import moment from 'moment'
import commonActions from '../../Actions/commonActions'
import EducationInfoModal from '../../Components/EducationInfoModal';
import { withTranslation } from 'react-i18next';
import Panel from '../../Components/Panel';

function CourseInfoContainer({ customerActions, customer, commonActions, course, t }) {
    const navigate = useNavigate();
    const { courseId, eventId, customerNumber } = useParams();
    const [activeModal, setActiveModal] = useState();

    useEffect(() => {
        customerActions.getCourse(courseId);
    }, [])

    const onCancelClick = () => {
        navigate(routes.courses(customerNumber));
    }

    const colourField = (education) => {
        if (education.warningMarker) {
            return "text-danger"
        }

        if (education.maximumParticipants === education.participantsBooked) {
            return "text-primary"
        }

        return "";
    }

    const fullWarning = (text, header) => {
        commonActions.showWarning(text, header)
    }

    const onClickAction = (education) => {
        const currentDate = moment();
        const currentDay = moment().startOf('day');

        if (currentDate.isAfter(moment('12:00', 'HH:mm')) && currentDate.add(1, 'day') >= moment(education.date)) {
            fullWarning("Please contact Customer Administration if you want to put customer on wait list", "Course start is tomorrow")
        }
        else if (education.maximumParticipants != null && education.participantsBooked === education.maximumParticipants) {
            fullWarning("Please contact Customer Administration if you want to put customer on wait list", "Education is full");
        }
        else if (currentDay.isAfter(moment(education.lastApplicationDate))) {
            fullWarning("Please contact Customer Administration if you want to put customer on wait list", "Last application date for this education has pased. ");
        }
        else {
            navigate(routes.customerBookEducation(customerNumber, education.id));
        }
    }

    const toggleModal = (modalName) => {
        setActiveModal(modalName)
    };

    const educationTable = (educations) => {

        return <table className="table table-stripe table-hover">
            <thead>
                <tr>
                    <th className="text-left">Start Date</th>
                    <th className="text-left">End Date</th>
                    <th className="text-left">Address</th>
                    <th className="text-left">Location</th>
                    <th className="text-left">Participants Booked</th>
                    <th className="text-left">Minimum Participants</th>
                    <th className="text-left">Maximum Participants</th>
                    <th className="text-left">Price</th>
                    <th className="text-left"></th>
                </tr>
            </thead>
            <tbody>
                {educations.map((education, index) => {
                    var btnClass = education.id == eventId ? "btn btn-primary" : "btn btn-default";
                    const className = colourField(education);
                    return <tr onClick={() => onClickAction(education)} key={index}>
                            <td style={{ whiteSpace: "nowrap" }}>{education.onDemand ? 'Vid beställning' : education.date}</td>
                            <td style={{ whiteSpace: "nowrap" }}>{education.onDemand ?  education.onDemandAccessDays+' dagar efter start': education.endDate}</td>
                             <td>{education.addressName}</td>
                        <td>{education.location}</td>
                            <td className={className}>{education.participantsBooked}</td>
                            <td className={className}>{education.onDemand ? '-' :education.minimumParticipants}</td>
                            <td className={className}>{education.onDemand ? '-' :education.maximumParticipants}</td>
                        <td>{helpers.currencyFormatter(education.price)}</td>
                        <td><button className={btnClass}>Book</button></td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    if (course == null)
        return null;

    let educationDays = course.days ? `Dagar: ${course.days}<br>` : '';
    let educationInfoHtmlString = `
                <h2>Kursinformation</h2>
                Namn: ${course.courseName}<br>
                ${educationDays}
                Nivå: ${course.courseLevel}<br>
                Kategorier: ${course.subjects && course.subjects.join(', ')}<br>
                <br>
                `;

    educationInfoHtmlString += course.targetGroup ? `<p>${course.targetGroup}</p>` : '';
    educationInfoHtmlString += course.courseDescription;
    educationInfoHtmlString += course.notes !== null ? course.notes : "";
    educationInfoHtmlString += course.prerequisites;
    educationInfoHtmlString += course.courseGoal;

    return (
        <Panel>
            <div className="form-horizontal">
                <fieldset className="col-xs-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">Course info</div>
                        <div className="panel-body">
                            <StaticTextInput label="Name" value={course.courseName} />
                            {course.days != null && <StaticTextInput label="Days" value={course.days} />}
                            <StaticTextInput label="Level" value={course.courseLevel} />
                            <StaticTextInput label="Categories" value={course.subjects && course.subjects.join(', ')} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-xs-6">
                    <div><p dangerouslySetInnerHTML={{ __html: course.targetGroup }} />  </div>
                    <div>   <p dangerouslySetInnerHTML={{ __html: course.prerequisites }} />  </div>
                </fieldset>
                <fieldset className="col-xs-12">
                    <div><p dangerouslySetInnerHTML={{ __html: course.courseDescriptionShort }} />   </div>
                    <div>   <p dangerouslySetInnerHTML={{ __html: course.courseDescription }} />  </div>
                    <div>   <p dangerouslySetInnerHTML={{ __html: course.notes }} />      </div>
                    <div>   <p dangerouslySetInnerHTML={{ __html: course.courseGoal }} />   </div>
                    <div className='margin-top'>
                        <button className='btn btn-primary' onClick={() => toggleModal('showEducationInfoModal')}>{t('educationscontainer.sendInfo')}</button>
                    </div>
                </fieldset>
                <fieldset className="col-xs-12">
                    {educationTable(course.educations)}
                </fieldset>
                <fieldset className="col-xs-12"><button className="btn btn-default pull-right" onClick={() => onCancelClick()}>Back to educations</button> </fieldset>

            </div>

            {activeModal === 'showEducationInfoModal' && <EducationInfoModal educationInfoHtmlString={educationInfoHtmlString} togglemodal={toggleModal} />}
        </Panel>
    )

}


function mapStateToProps(state) {
    return {
        course: state.CustomerReducer.selectedCourse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CourseInfoContainer));