import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import discountActions from '../../Actions/discountActions';
import Panel from '../../Components/Panel';
import routes from '../../Constants/routes';
import commonActions from '../../Actions/commonActions';
import DiscountList from '../../Components/Discounts/DiscountList';

function DiscountsContainer({ discountActions, discounts, commonActions }) {
    const [searchText, setSearchText] = useState('');
    const [searchDiscount, setSearchDiscount] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        discountActions.getDiscounts();
    }, []);

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearchDiscountChange = (e) => {
        setSearchDiscount(e.target.value);
    };

    const filterDiscounts = (discounts) => {
        return discounts.filter((discount) => {
            const nameMatch = discount.name.toLowerCase().includes(searchText.toLowerCase());
            const discountMatch = (discount.discountPercent && discount.discountPercent.toString().includes(searchDiscount) || discount.discountMoney && discount.discountMoney.toString().includes(searchDiscount));

            return nameMatch && discountMatch;
        });
    };

    const filteredDiscounts = discounts ? filterDiscounts(discounts) : [];

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    return (
        <Panel>
            <button type="button" className="btn btn-primary" onClick={() => navigate(routes.createNewDiscount)}>
                Skapa rabattavtal
            </button>
            <div className="card-list card-list-secondary mt-24 mb-16">
                <div className="card-list-item">
                    <div className="description">
                        <h3>Sök</h3>
                        <form className="form d-flex">
                            <div className='form-group d-flex mr-24 mb-0'>
                                <label>Sök i fritext</label>
                                <div className="form-group search-group">
                                    <input className="form-control" type="text" value={searchText} onChange={handleSearchTextChange} />
                                    <span className="search-icon" />
                                    <span className={`clear-search ${searchText && "show"}`} onClick={() => setSearchText('')}>Clear</span>
                                </div>
                            </div>
                            <div className='form-group d-flex mb-0'>
                                <label>Sök rabatt</label>
                                <div className="form-group search-group">
                                    <input className="form-control" type="text" value={searchDiscount} onChange={handleSearchDiscountChange} />
                                    <span className="search-icon" />
                                    <span className={`clear-search ${searchDiscount && "show"}`} onClick={() => setSearchDiscount('')}>Clear</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DiscountList onSort={sortListByKey("discounts", discounts)} filteredDiscounts={filteredDiscounts} />
        </Panel>
    );
}

function mapStateToProps(state) {
    return {
        discounts: state.DiscountReducer.discounts,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountsContainer));