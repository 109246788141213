import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withTranslation} from 'react-i18next';

import commonActions from '../Actions/commonActions'

function StatusContainer ({status, t}) {

    useEffect(() => {
        commonActions.getStatus();
    }, [status])

    function translateStatus(statusKey) {
        if (!status) {
            return "spinner spinner-default-green"
        }
    
        return `vismaicon vismaicon-lg vismaicon-filled vismaicon-${status[statusKey] ? 'success' : 'error'}`
    }

    function renderItem(statusKey) {
        return  <div className="card-list-item row gap-x-0"  style={{width: '600px'}}>
                    <div className="col-md-4 col-md-6">
                        <div className="title">
                            <span className={translateStatus(statusKey)} style={{marginRight: "2.4rem"}} aria-hidden="true"></span>
                            <div>
                                <h4>{t('statuscontainer.'+statusKey)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
    }

    return( 
        <div className="card-list card-list-primary align-items-center">
            {renderItem("businessDatabase")}
            {renderItem("vismaOnline")}
            {renderItem("vismaBusinessProxy")}
        </div>	
    );
}

function mapStateToProps(state) {
    return {
        status: state.CommonReducer.status
    }
}

function mapDispatchToProps(dispatch) {
   return {
       commonActions: bindActionCreators(commonActions, dispatch) 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StatusContainer)); 