import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import ListSearch from '../../Components/ListSearch';
import CompensationTable from '../../Components/Settings/CompensationTable'
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import { useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'

function SettingsCompensationContainer({ settingsActions, commonActions, webinarForm, tickets, t, compensationSearchResults }) {
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getCompensationTickets();
    }, [])

    const onClick = (ticket) => {
        navigate(routes.settingsCompensationEdit(ticket.id));
    }

    const moveToTicket = (ticket) => {
        navigate(routes.settingsCompensationEdit(ticket.id));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(webinarForm.id, field, value)
    }

    const onSearchClick = (phrase) => {
        settingsActions.searchClosedCompensations(phrase)
    }

    const onClearClick = () => {
        settingsActions.clearSearch();
    }

    if (!tickets) {
        return null;
    }

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ListSearch onSearchClick={onSearchClick} onClearClick={onClearClick} results={compensationSearchResults} placeholder={t('settingscompensationcontainer.searchplaceholder')}>
                        <CompensationTable tickets={compensationSearchResults} onClick={moveToTicket} />
                    </ListSearch>
                </div>
            </div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th>{t('settingscompensationcontainer.customernumber')}</th>
                        <th>{t('settingscompensationcontainer.product')}</th>
                        <th>{t('settingscompensationcontainer.compensationreason')}</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map((ticket, index) => {
                        return <tr key={index} onClick={() => onClick(ticket)}>
                            <td>{ticket.customerNumber}</td>
                            <td>{ticket.product}</td>
                            <td>{ticket.compensationReason}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        tickets: state.SettingsReducer.compensationTickets,
        compensationSearchResults: state.SettingsReducer.compensationSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsCompensationContainer));