import { actionLogActionTypes as actionTypes } from '../ActionTypes/actionLogActionTypes'
import { httpFetch } from '../Utils/httpUtils'
import commonActions from '../Actions/commonActions'
export const initialState = {
    
}

export default function GraphReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_CUSTOMER_ACTIONLOGS:
            newState = { actionLogs: action.data }
            break;
        
        case actionTypes.STORE_MY_ACIONLOGS:
            newState = { actionLogs: action.data }
            break;

        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}