import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import commonActions from "../Actions/commonActions";
import { connect } from "react-redux";

export function HorizontalRow({children}) {
    return <div className="row">
        {children}
    </div>
}

function HorizontalText({label, type, to, value, icon, renderAfter, noWordBreak, columns, newTab, copyToClipBoard, commonActions}) {

    const renderEmail = email => {
        return <a href={"mailto:" + email}>{email}</a>
    }

    const renderLink = (to, value, newTab) => {
        return <Link target={newTab ? "_blank" : ""} to={to}>{value}</Link>
    }

    const renderIcon = (iconName) => {
        switch (iconName) {

            case "iconAO":
                return (
                    <span className="vismaicon vismaicon-office-building align-customerIcon" aria-hidden="true"
                          title="Accounting Office"></span>
                )

            case "iconReseller":
                return (
                    <span className="vismaicon vismaicon-locked align-customerIcon" aria-hidden="true"
                          title="Reseller - Contact PartnerSales"></span>
                )

            case "iconIntegrationPartner":
                return (
                    <span className="vismaicon vismaicon vismaicon-link align-customerIcon" aria-hidden="true"
                          title="Integration Partner - Contact TechSales"></span>
                )

            default:
                return null;
        }
    }

    const wordBreak = noWordBreak ? "" : "word-break";
    
    let displayValue = ''

    switch (type) {
        case 'email':
            displayValue = renderEmail(value)
            break
        case 'link':
            displayValue = renderLink(to, value, newTab)
            break
        case 'raw':
            displayValue = <span dangerouslySetInnerHTML={{__html: value}}></span>
            break
        default:
            displayValue = value
            break;
    }

    const cols = () => {
        if(!columns)
            columns = 2; // default two columns
        
        return 12 / columns;
    } 
    
    const copyToClipBoardIcon = () => {
        if(!copyToClipBoard || !value)
            return null;
        
        return <div className="customer-number-copy"><span title={`Copy ${label.toLowerCase()} to clipboard`}
                                                    style={{cursor: "pointer"}}
                                                    className="vismaicon vismaicon-copy customer-number-copy-icon"
                                                    onClick={() => commonActions.copyToClipboard(label, value)}></span>
        </div>
    }
    
    return <div className={`col-sm-12 col-md-${cols()} p-0`}>
            <div className='row align-items-center'>
                {label &&
                    <div className='col-6 p-2'>
                        <span className='horizontal-text-label'>{label}:</span>
                    </div>
                }
                <div className='col-6 p-2 align-items-center' style={{display: "flex"}}>
                            {displayValue}
                            {renderIcon(icon)}
                            {copyToClipBoardIcon()}
                {renderAfter}
                </div>
            </div>
        </div>
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(HorizontalText);