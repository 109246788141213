import React, { Component } from 'react';
import './../Layout/Calendar.css';
import { withTranslation } from 'react-i18next';

class BokaMeraCalendar extends Component {

    render(){
        const data = this.props.bokaMeraTimes;
        const {t} = this.props
        if(!data)
            return null;

        return <>
                <div className="mt-10">
                    <h2 className="text-center">{t('bokameracalendar.week')}{data.weekNo}</h2>
                    <div>
                        {this.props.weekNo > 0 ? <>
                        <button className="btn left pull-left" onClick={this.props.onWeekChange.bind(this, this.props.weekNo -1)}><span>{t('bokameracalendar.previous')}</span></button>
                        <button className="btn btn-default pull-left" onClick={this.props.onWeekChange.bind(this, 0)}><span>{t('bokameracalendar.currentweek')}</span></button>
                        
                        </>
                        : null
                        }
                        <button className="btn right pull-right" onClick={this.props.onWeekChange.bind(this, this.props.weekNo +1)}><span>{t('bokameracalendar.next')}</span></button>
                    </div>
                </div>
                <br />
            <div className="table-responsive">
                <table className="table table-stripe calendar">
                    <thead>
                    <tr>
                        <th></th>
                        {data.days.map(day =>
                            <th key={day.dayOfWeek}>
                                <span className="weekDay">{day.dayOfWeek}</span><br/>
                                {day.date}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {data.times.map((time, index) =>
                        <tr key={index}>
                            <td><b>{time}</b></td>
                            {data.days.map((day, index) => {
                                const slot = day.slots.find(slot => slot.fromTime === time)
                                return <td key={index}><a onClick={() => this.props.onClick(slot.from, slot.to, slot && slot.free)}>{slot && slot.free > 0 ? slot.fromTime + " - " + slot.toTime : ""}</a></td>
                            })
                            }
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            </>
    }
}


export default withTranslation()(BokaMeraCalendar);