import React from 'react';
import helpers from '../../Utils/helpers'
import { withTranslation } from 'react-i18next';

const LeadsActionHistoryComments = ({ actionHistory, t }) => {

    if (!actionHistory || actionHistory.length < 1) {
        return null;
    }

    return (
        <div className="form-group">
            <div className="col-xs-4">
                <label>{t('leadactionhistory.leadactionhistory')}</label>
            </div>
            <div className="col-xs-8">
                <table className="appointment-comment table">
                    <tbody>
                        {actionHistory.map((action, index) => {
                            return <tr key={index}>
                                <td>
                                    <span className="date text-disabled">{helpers.formatDate(action.date)} </span>
                                    <span className="author text-primary">{action.author} : </span>
                                    <span className="comment">{action.action}</span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default withTranslation()(LeadsActionHistoryComments);