import { useEffect, useState } from "react";
import Card from "../../../Components/Card/Card";
import { bindActionCreators } from "redux";
import discountAgreementActions from "../../../Actions/Voss/discountAgreementActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../Constants/routes";
import DiscountAgreementsTable from "../../../Components/Voss/DiscountAgreements/DiscountAgreementsTable";
import packageActions from "../../../Actions/Voss/packageActions";

function DiscountAgreementsContainer({ ...props }) {

    const { discountAgreements, discountAgreementActions, packageActions, readOnly } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        discountAgreementActions.getDiscountAgreements();
        packageActions.getPackages();
    }, []);

    const filteredDiscountAgreements = discountAgreements?.filter(agreement =>
        agreement.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (input) => {
        setSearchTerm(input);
    };

    return (
        <Card>
            <Card.Header>
                <Card.Header.Title>Discount agreements</Card.Header.Title>
            </Card.Header>
            <Card.Content>
                {!readOnly && <div className="mb-12">
                    <button className="btn btn-primary mb-0" onClick={() => navigate(routes.createNewDiscount)}>New discount agreement</button>
                </div>}
                <div className="mb-12">
                    <div className="card-list card-list-secondary">
                        <div className="card-list-item">
                            <div className="description">
                                <h3>Search</h3>
                                <form className="form d-flex">
                                    <div className='form-group d-flex mr-24 mb-0'>
                                        <label>Search discounts</label>
                                        <div className="form-group search-group">
                                        <input className="form-control" type="text" value={searchTerm} onChange={(e) => handleSearchChange(e.target.value)} />
                                            <span className="search-icon" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DiscountAgreementsTable
                        {...props}
                        discountAgreements={filteredDiscountAgreements}
                        searchTerm={searchTerm}
                    />
                </div>
            </Card.Content>
        </Card>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        discountAgreementActions: bindActionCreators(discountAgreementActions, dispatch),
        packageActions: bindActionCreators(packageActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        discountAgreements: state.DiscountAgreementsReducer.discountAgreements,
        packages: state.PackageReducer.packages,
        discountAgreementVersions: state.DiscountAgreementsReducer.discountAgreementVersions,
        versionsLoading: state.CommonReducer.dataLoading.discountversions,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountAgreementsContainer);