import { actionLogActionTypes as actionTypes } from '../ActionTypes/actionLogActionTypes'
import { customerActionTypes } from '../ActionTypes/customerActionTypes'

export const initialState = {
    actionLogs: []
}

export default function ActionLogReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_CUSTOMER_ACTIONLOGS:
            newState = { actionLogs: action.data }
            break;

        case customerActionTypes.RESET:
            newState = initialState
            break;
        
        case actionTypes.STORE_MY_ACIONLOGS:
            newState = { actionLogs: action.data }
            break;

        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}