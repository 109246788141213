import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import routes from '../../Constants/routes'
import NewDiscountModal from '../../Components/Customer/NewDiscountModal';
import moment from 'moment'
import customerActions from '../../Actions/customerActions'
import { useNavigate } from 'react-router-dom';
import Panel from "../../Components/Panel";
import {DefaultCard} from "../../Components/Card/Card";

function DiscountsContainer({ t, customerDiscounts, customerActions, vbCustomer,  }) {

    const navigate = useNavigate();
    const [showNewDiscountModal, setShowNewDiscountModal] = useState(false)

    useEffect(() => {
        if (!vbCustomer.hasVonAccount)  {
            navigate(routes.customerNewVonAccount(customer.pathId));
        } else {
            customerActions.getDiscounts(vbCustomer.customerId);
        }
    }, [])

    const renderNoData = () => {
        return <>
            {showNewDiscountModal && <NewDiscountModal multiple={true} onClose={() => setShowNewDiscountModal(false)} onSaveClick={onSave} />}
            <div className="pull-right"><button onClick={() => setShowNewDiscountModal(!showNewDiscountModal)} className="btn btn-primary">{t('appointmentscontainer.addnew')}</button></div>
            <p>{t('discountscontainer.noentries')}</p>
        </>
    }
    
    const onSave = (form, customerId) => {
        customerActions.assignNewDiscount(form, customerId, () => setShowNewDiscountModal(!showNewDiscountModal));
    }

    const onDelete = (customerId, discountId) => {
        customerActions.deleteDiscount(customerId, discountId);
    }
    
    let renderContent = () => {
        return <>
            {showNewDiscountModal &&
                <NewDiscountModal multiple={true} onClose={() => setShowNewDiscountModal(false)} onSaveClick={onSave}/>}

            <div className="pull-right">
                <button onClick={() => setShowNewDiscountModal(!showNewDiscountModal)}
                        className="btn btn-primary">{t('appointmentscontainer.addnew')}</button>
            </div>

            <div className="table-responsive">
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th>{t('discountscontainer.name')}</th>
                        <th>{t('discountscontainer.validto')}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {customerDiscounts.map((discount, index) => {
                        return <tr key={index}>
                            <td>{discount.name}</td>
                            <td>{moment(discount.validToDateForCustomer).format('YYYY-MM-DD')}</td>
                            <td>
                                <button className="btn btn-danger delete-discount-btn"
                                        onClick={() => onDelete(vbCustomer.customerId, discount.discountAgreementId)}>Delete
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </>
    }
    
    if (!customerDiscounts || customerDiscounts.length === 0) {
        renderContent = renderNoData;
    }
    
    return <DefaultCard title={t('discountscontainer.header')}>
        {renderContent()}
    </DefaultCard>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        customerDiscounts: state.CustomerReducer.customerDiscounts
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountsContainer));