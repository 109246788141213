import {Access} from "../../Constants/userRoles";

const AccessIcon = ({userRole}) => {
    if (userRole && userRole.access >= Access.ReadOnly) {
        switch (userRole.access) {
            case Access.ReadOnly:
                return <span className="vismaicon vismaicon-dynamic vismaicon-new" title='ReadOnly'></span>;
            case Access.ReadWrite:
                return <span className="vismaicon vismaicon-dynamic vismaicon-edit-file" title='ReadWrite'></span>;
            case Access.Admin:
                return <span className="vismaicon vismaicon-dynamic vismaicon-file-locked" title='Admin'></span>;
            default:
                return <span className="vismaicon vismaicon-dynamic vismaicon-ok-circle"></span>;
        }
    }
}

export default AccessIcon;