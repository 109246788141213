import React from 'react'
import { useTranslation } from 'react-i18next'

export default function UserSearchResultTable({ searchResult }) {
        
    const { t } = useTranslation();
    
    if(!searchResult)
        return null;

    return <table className="table table-stripe table-hover table-appointments">
        <thead>
        <tr>
            <th className="text-left">{t('usersearch.name')}</th>
            <th className="text-left">{t('usersearch.salesnumber')}</th>
            <th className="text-left">{t('usersearch.email')}</th>
            <th className="text-left">{t('usersearch.teamname')}</th>
        </tr>
        </thead>
        <tbody>
        {searchResult.map((user, index) => {
            return <tr key={index}>
                <td>{user.name ? user.name : 'N/A'}</td>
                <td>{user.salesNumber ? user.salesNumber : 'N/A'}</td>
                <td>{user.email ? user.email : 'N/A'}</td>
                <td>{user.teamName ? user.teamName : 'N/A'}</td>
            </tr>
        })}
        </tbody>
    </table>
}