import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import Modal from "../../Modal";
import {ActionType} from "../../../Constants/actionType";
import {bindActionCreators} from "redux";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
import moment from "moment";
import EditExpiryDate from "./EditExpiryDate";

const EditExpiryDateModal = ({form, customer, commonActions, customerActions, onClose }) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (!form)
            customerActions.getSelectedCustomerForEdit(customer.vbCustomer.customerNumber, "customerFormData");

        return () => {
            commonActions.cancelFormEditing("customerFormData");
        }
    }, []);

    const handleCloseClick = () => {
        commonActions.cancelFormEditing(form.id, onClose);
    }
    const onSave = () => {
        customerActions.saveVossCustomer(form, ActionType.Edit, handleCloseClick);
    }


    const modalProps = {
        title:  `${t("editcustomermodal.editcustomer")} ${customer?.vossCustomer?.name ?? customer?.vbCustomer?.name}`,
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => handleCloseClick(),
        onSaveClick: onSave,
        mainButtonTitle: t("common.save"),
    }

    return form && form.data && <Modal {...modalProps}>
        <form className="skynet-form container">
            <fieldset>
                <EditExpiryDate form={form} customer={customer} commonActions={commonActions}/>
            </fieldset>
        </form>
    </Modal>
}


function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditExpiryDateModal));
