import React from 'react'

function Informative({cause, directions, icon}) {
  return (
    <div className='informativeContainer'>
        <span className={`vismaicon vismaicon-filled vismaicon-${icon}`} aria-hidden="true"></span>
        <div>
            <strong>{cause}</strong>
            <p>{directions}</p>
        </div>
    </div>
  )
}

export default Informative