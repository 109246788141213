import { packageActionTypes as actionTypes } from "../../ActionTypes/Voss/packageActionTypes";
import { initialState } from "../CommonReducer";

export default function PackageReducer(state = { initialState }, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_PACKAGES:
            newState = { packages: action.data }
            break;
        case actionTypes.STORE_PACKAGE:
            newState = { package: action.data }
            break;
        case actionTypes.CLEAR:
            newState = { [action.key]: null }
            break;
        case actionTypes.STORE_PACKAGE_NAMES:
            newState = { packageNames: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}