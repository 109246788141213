import React, { Component } from 'react';

import DateTimeReact from 'react-datetime';
import StaticTextInput from './StaticTextInput'
import moment from 'moment'

import './DateTimeInput.css';

export default class DateTimeInput extends Component {

    onBlur(datetime) {
        const newValue = (!datetime || !datetime._isAMomentObject) ? "" : (this.props.dateFormat === false) ? datetime.format("HH:mm") : datetime.format('YYYY MM DD HH:mm');

        this.props.onChange(newValue);
    }

    render() {

        moment.updateLocale('en', {
            week: {
              dow: 1,
            },
          })
        
        if(this.props.readonly) {
            return <StaticTextInput label={this.props.label} value={this.props.value && this.props.value.toString()} />
        }
        const timeFormat = (this.props.showTime === false) ? "" : "HH:mm";
        const dateFormat = (this.props.dateFormat === undefined) ? "YYYY-MM-DD" : (this.props.dateFormat === false) ? false : "YYYY-MM-DD"
        const placeHolderDateFormat = this.props.placeholderText != undefined ? this.props.placeholderText : (this.props.dateFormat === undefined && timeFormat === "HH:mm") ? "YYYY-MM-DD HH:mm" : (this.props.dateFormat === false) ? "HH:mm" : "YYYY-MM-DD";
        const closeOnSelect = this.props.closeOnSelect === false ? false : true;
        const value = this.props.dateFormat === false && this.props.value ? this.props.value.toString("HH:mm") : this.props.value ? moment(this.props.value) : undefined;
        
        const inputProps = {
            dateFormat: dateFormat,
            timeFormat: timeFormat,
            inputProps: {placeholder: placeHolderDateFormat},
            onChange: this.onBlur.bind(this),
            closeOnSelect: closeOnSelect,
            value: value
        }

        return (
            <div className="form-group">
                <label className="col-xs-4 control-label">{this.props.label}</label>
                <div className="col-xs-8">
                    <DateTimeReact {...inputProps} />
                </div>
            </div>
        );
    }
}