import { searchActionTypes as actionTypes } from '../ActionTypes/searchActionTypes'

export const initialState = {
    searchInput: "",
    searchResult: null,
}

export default function SearchReducer(state = initialState, action) {

    let newState;

    switch(action.type) {

        case actionTypes.SEARCH_CUSTOMER_RESULT:
            newState = { searchInput: action.json.input, searchResultInput: action.json.input, searchResult: action.json.data }
            break;

        case actionTypes.EMPTY_SEARCH_RESULTS:
            newState = { searchResult: null }
            break;
            
        case actionTypes.VALIDATE_SEARCH_INPUT:
            newState = {searchInputError: action.hasError}
            break;

        case actionTypes.SEARCH_INPUT_CHANGED:
            newState = {searchInput: action.value }
            break;

        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)
            
    return state;
}