import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { articlePeriods } from '../../Constants/dropDownLists';

function ArticleListContainer({ articles, onDelete, discountForm }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredArticles, setFilteredArticles] = useState([]);

    useEffect(() => {
        handleSearchInputChange(searchQuery);
    }, [articles]);

    const handleSearchInputChange = (query) => {
        setSearchQuery(query);
        setFilteredArticles(articles.filter((article) => article.articleNo.toLowerCase().includes(query)));
    };

    const renderArticleInfo = (article, index) => {
        let adjustedPrice = article.unitPrice;
    
        if (discountForm && discountForm.data) {
            const { discountType, discountMoney, discountPercent } = discountForm.data;
    
            if (discountType === 'discountPercent') {
                const discountAmount = (adjustedPrice * discountPercent) / 100;
                adjustedPrice -= discountAmount;
            } else if (discountType === 'discountMoney') {
                const totalMonths = article.period || article.periodLength;
                adjustedPrice -= discountMoney * totalMonths;
            }
    
            adjustedPrice = Math.max(0, adjustedPrice);
        }
    
        return (
            <tr key={index}>
                <td>{article.articleNo}</td>
                <td>{articlePeriods[article.period || article.periodLength]}</td>
                <td>{article.unitPrice.toFixed(2) + " SEK"}</td>
                <td>{adjustedPrice.toFixed(2) + " SEK"}</td>
                <td>
                    <span className="vismaicon vismaicon-delete" onClick={() => onDelete(article.articleId)} />
                </td>
            </tr>
        );
    };

    return (
        <div className="form-group">
            <div className="form-group d-flex mb-24">
                <div className="form-group search-group">
                    <input
                        className="form-control"
                        placeholder="Sök artiklar"
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearchInputChange(e.target.value)}
                    />
                    <span className="search-icon" />
                    <span className={`clear-search ${searchQuery && 'show'}`} onClick={() => setSearchQuery('')} />
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-stripe table-hover" style={{ wordBreak: 'break-all' }}>
                    <thead>
                        <tr>
                            <th>Artikel</th>
                            <th>Period</th>
                            <th>Pris</th>
                            <th>{discountForm && discountForm.data ? 'Rabatterat pris' : ''}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(searchQuery ? filteredArticles : articles).map((article, index) => {
                            return renderArticleInfo(article, index);
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        discountForm: state.CommonReducer.forms && state.CommonReducer.forms.newDiscount,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ArticleListContainer));
