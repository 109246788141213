import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import ListSearch from '../../Components/ListSearch';
import HittaPartnerTable from '../../Components/Settings/HittaPartnerTable'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import routes from '../../Constants/routes'
import { useNavigate } from 'react-router-dom';

function HittaPartnerContainer({ settingsActions, commonActions, form, epiPartners, t, epiPartnerSearchResults }) {

    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getEpiPartners();
    }, [])

    const onClick = (epiPartner) => {
        navigate(routes.hittaPartnerEdit(epiPartner.id));
    }

    const moveToEpiPartner = (epiPartner) => {
        navigate(routes.hittaPartnerEdit(epiPartner.id));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const onSearchClick = (phrase) => {
        settingsActions.searchEpiPartners(phrase)
    }
    const onClearClick = () => {
        settingsActions.clearEpiPartnerSearch();
    }

    if (!epiPartners) {
        return null;
    }

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ListSearch onSearchClick={onSearchClick} onClearClick={onClearClick} results={epiPartnerSearchResults} placeholder={t('hittapartnercontainer.searchplaceholder')}>
                        <HittaPartnerTable epiPartners={epiPartnerSearchResults} onClick={moveToEpiPartner} />
                    </ListSearch>
                </div>
            </div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th>{t('hittapartnercontainer.customernumber')}</th>
                        <th>{t('hittapartnercontainer.name')}</th>
                        <th>{t('hittapartnercontainer.organizationnumber')}</th>
                    </tr>
                </thead>
                <tbody>
                    {epiPartners.map((partner, index) => {
                        return <tr key={index} onClick={() => onClick(partner)}>
                            <td>{partner.customerNumber}</td>
                            <td>{partner.name}</td>
                            <td>{partner.organizationNumber}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        epiPartners: state.SettingsReducer.epiPartners,
        epiPartnerSearchResults: state.SettingsReducer.epiPartnerSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HittaPartnerContainer));