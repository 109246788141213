import { myDashboardActionTypes as actionTypes } from '../ActionTypes/myDashboardActionTypes'

export const initialState = {
    data: null
}

export default function MyDashboardReducer(state = initialState, action) {

    let newState;

    switch (action.type) {

        case actionTypes.STORE_MY_REPORT:
            newState = { data: Object.assign({}, state.data, { myData: action.data }) }
            break;

        case actionTypes.STORE_TEAM_REPORT:
            newState = { data: Object.assign({}, state.data, { teamData: action.data }) }
            break;
        
        case actionTypes.STORE_DASHBOARD_GOALS:
            newState = { data: Object.assign({}, state.data, {dashboardGoals: action.data})}
            break;
      
        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}