import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import ProductOverviewList from '../Components/ProductOverviewList';
import ProductVersionList from '../Components/ProductVersionList';
import productOverviewActions from '../Actions/productOverviewActions';

function ProductOverviewContainer({productList, productOverviewActions, t}) {
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        if(!productList){
          productOverviewActions.getProducts();
        }
    }, [])

    const filteredProductList = productList && productList.filter(({productName}) => {
        return productName.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    });
    
    return (
        <div className="panel">
            <div className="panel-body">
                <div className='productoverviewpage col-xs-10'>
                    <div className='productoverviewsection col-xs-7'>
                        <div className='panel-controls panel-heading'>
                            <h3>{t("productOverview.productOverviewText")}</h3>
                            <div className='col-xs-12 col-md-6'>
                                <div className='form-group search-group'>
                                    <input type="text" className='form-control' aria-describedby='searchBlock' placeholder='Search by Product Name' onChange={(e) => setSearchString(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className='productOverviewListContainer'>
                            <ProductOverviewList productList={filteredProductList}/>
                        </div>
                    </div>
                    <div className='productoverviewsection productVersionContainer-scroll col-xs-5'>
                        <ProductVersionList />
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
      productList: state.ProductOverviewReducer.productList,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductOverviewContainer)); 