import {targetGroups} from "../Constants/VOSS/targetGroups";

const customerGroupsWithConfig = (targetGroups, customerTargetGroups) => {
    return targetGroups.filter(t => customerTargetGroups.findIndex(ct => ct.id === t.id) > -1);
}
const hasActiveConfig = (targetGroup, config) => {
    return !!targetGroup.configuration[config];
}

const hasTargetGroup = (targetGroups, targetGroup) => {
    return targetGroups.findIndex(t => t.name === targetGroup.name) > -1;
}

const targetGroupHelpers = {
    hasConfig(targetGroups, customerTargetGroups, configuration) {
        return targetGroups && customerTargetGroups && customerGroupsWithConfig(targetGroups, customerTargetGroups).some(t => hasActiveConfig(t, configuration));
    },

    isTestCustomer(customer) {
        return customer.vossCustomer && hasTargetGroup(customer.vossCustomer.targetGroups, targetGroups.TestCustomer) || customer.oldVbCustomer?.isTestCustomer;
    },

    isNonInteractive(customer) {
        return customer.vossCustomer && hasTargetGroup(customer.vossCustomer.targetGroups, targetGroups.NonInteractive) || customer.oldVbCustomer?.isNonInteractive;
    },

    hasAoSimplicity(customer) {
        return customer.vossCustomer && hasTargetGroup(customer.vossCustomer.targetGroups, targetGroups.AOSimplicity) || customer.oldVbCustomer?.hasAoSimplicity;
    },

    hasTargetGroup(customer, targetGroup) {
        return customer && hasTargetGroup(customer.targetGroups, targetGroup);
    }
}

export default targetGroupHelpers;
