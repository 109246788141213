import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom'
import { bindActionCreators } from "redux";
import VonAccounts from "../VonAccounts";
import VonNewEmployeeFormContainer from "./Employee/VonNewEmployeeFormContainer";
import VonEditEmployeeFormContainer from "./Employee/VonEditEmployeeFormContainer";
import VonAdministerLicenseFormContainer from "./Licenses/VonAdministerLicenseFormContainer";
import customerActions from "../../../Actions/customerActions";

function VonAccountsContainer({ customer, vonAccounts, vonProducts, customerActions, vonProductsLoading, vonAccountsLoading }) {

    const custNo = customer.vbCustomer?.customerNumber ?? customer.vossCustomer.customerNumber;

    useEffect(() => {
        if (!vonAccountsLoading && !vonAccounts) {
            customerActions.getVonAccounts(custNo);
        }

        if (!vonProductsLoading && !vonProducts)
            customerActions.getCustomerVonProducts(custNo);
    }, []);

    return <Routes>
        <Route path={'/new-employee'} element={<VonNewEmployeeFormContainer />} />
        <Route path={'/edit-employee/:id'} element={<VonEditEmployeeFormContainer />} />
        <Route path={'/administer-licenses'} element={<VonAdministerLicenseFormContainer />} />
        <Route path={'*'} element={<VonAccounts />} />
    </Routes>

}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        vonProducts: state.CustomerReducer.vonProducts,
        vonProductsLoading: state.CommonReducer.dataLoading.vonProducts,
        vonAccountsLoading: state.CommonReducer.dataLoading.vonAccounts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VonAccountsContainer));
