import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import MessageAlertLevel from '../MessageAlertLevel'


class DesktopVersionsList extends Component {

    state = {
        licenseKey: null
    };

    onRowClick(licenseKey) {
        this.setState({ licenseKey: this.state.licenseKey === licenseKey ? '' : licenseKey })
    }

    render() {
        var { licenses, onOpenFormClick, t } = this.props;

        return (
            <table className="table table-stripe table-hover table-active">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th style={{ width: 200 }}>{t('desktopversionslist.version')}</th>
                        <th style={{ width: 190 }}></th>
                        <th style={{ width: 190 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {licenses.map((license, index) => {
                        return (
                            [
                                <tr key={`main-${index}`} className={this.state.licenseKey === license.licenseKey ? 'mainLicense active' : 'mainLicense'} onClick={() => this.onRowClick(license.licenseKey)}>
                                    <td><a>{license.productName}</a></td>
                                    <td>{license.version ? license.version : <MessageAlertLevel message={t('desktopversionslist.contactcustomeradministration')} />}</td>
                                    <td className="text-center">{t('desktopversionslist.currentversion')}</td>
                                    <td className="text-center"><button className="btn btn-default" disabled={!license.version} onClick={onOpenFormClick.bind(this, license.applicationNo, license.version, license.licenseKey)}><span className="vismaicon vismaicon-sm vismaicon-email" title="Send Key" /> {t('desktopversionslist.sendkey')}</button></td>
                                </tr>,

                                license.productVersions.map((item, index2) => {
                                    const className = this.state.licenseKey === license.licenseKey ? 'subLicenses' : 'subLicenses hidden'
                                    return <tr key={`version-${index2}${item.id}`} className={className}>
                                        <td></td>
                                        <td>{item.version}</td>
                                        <td />
                                        <td />
                                    </tr>
                                })
                            ]
                        )
                    })}
                </tbody>
            </table>
        );
    }
}

export default withTranslation()(DesktopVersionsList);