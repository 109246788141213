import { actionLogActionTypes as actionTypes } from '../ActionTypes/actionLogActionTypes'
import { httpFetch } from '../Utils/httpUtils'
import commonActions from '../Actions/commonActions'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'

const actionLogActions = {

    getCustomerActionLog(customerNumber) {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "customerActionLogs", value: true });
            return httpFetch(`/api/actionlog/GetCustomerLogs/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "customerActionLogs", value: false });
                    dispatch({ type: actionTypes.STORE_CUSTOMER_ACTIONLOGS, data: json });
                })
        }
    },

    getMyActionLog() {
        return (dispatch) => {
            return httpFetch(`/api/actionlog/GetAllForUser`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MY_ACIONLOGS, data: json });
                })
        }
    },

    getFormFromEmailActionLog(emailActionLogId, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/actionlog/GetEmailFromActionLog/${emailActionLogId}`, dispatch)
                .then(json => {
                    callback(json);
                })
        }
    }
}

export default actionLogActions;