import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import TextInput from '../../Components/TextInput';
import Editor from '../../Components/Editor';


function FileBoxTextsContainer({ t, selectedEvent }) {

    const inputStyle = {
        padding: "0",
        verticalalign: "center"
    }

    return (
        <div className='filebox-text-container-wrapper'>
            <div className="form-group ">
                <h3>Product</h3>
                <select className="form-control" disabled={true}>{selectedEvent.productName}</select>
            </div>
                <h3>Title</h3>
                <TextInput label={"noLabel"} noLabelStyle={inputStyle} />
            <h3>Description</h3>
            <Editor />
            <div className="btn-group save-filebox-text-btn-group">
                <button type="button" className="btn btn-primary">Save</button>
                </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxTextsContainer));