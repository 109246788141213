import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link, useNavigate } from 'react-router-dom'

import routes from '../../Constants/routes'

import InternalLeadsList from '../../Components/Leads/InternalLeadsList'
import ListSearch from '../../Components/ListSearch'

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'
import { withTranslation } from 'react-i18next';

function InternalLeadsListContainer({ searchResults, leadActions, user, salesPersons, t, commonActions, leads, leadName }) {

    const [showCloseModal, setShowCloseModal] = useState(false)
    const [filterStatement, setFilterStatement] = useState(localStorage.getItem("InternalLeadFilter"))
    const navigate = useNavigate();

    const moveToDetails = (leadId) => {
        navigate(routes.internalLeadDetails(leadId));
    }

    const onClearClick = () => {
        leadActions.clearAoSearch();
    }

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    const filterLeads = (filterStatement) => {
        localStorage.setItem("InternalLeadFilter", filterStatement);

        setFilterStatement(filterStatement)
    }

    const onCloseClick = (isModal) => {

        if (isModal)
            setShowCloseModal = false;
    }
    const allLeads = leads;
    let filteredLeads = allLeads;
    const filter = filterStatement;

    if (!allLeads) {
        return null;
    }

    const allLeadsCounter = <span className="badge badge-default">{allLeads.filter(lead => !lead.isClosed).length}</span>;

    const unassignedLeads = (allLeads || []).filter(lead => lead.assigneeUserId === 0);
    const unassignedCounter = <span className="badge badge-default">{unassignedLeads.filter(lead => !lead.isClosed).length}</span>;

    const myLeads = (allLeads || []).filter(lead => lead.assigneeUserId === user.id);
    const myLeadsCounter = <span className="badge badge-default">{myLeads.filter(lead => !lead.isClosed).length}</span>;

    if (filter === "unassigned")
        filteredLeads = unassignedLeads

    if (filter === "myLeads")
        filteredLeads = myLeads;

    const openLeads = (filteredLeads || []).filter(lead => !lead.isClosed);
    const closedLeads = (filteredLeads || []).filter(lead => lead.isClosed);

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ListSearch onSearchClick={leadActions.searchInternalLeads} onClearClick={onClearClick} results={searchResults} placeholder={t("internalLeadListContainer.searchLeads")}>
                        <InternalLeadsList listType="searchList" leads={searchResults} salesPersons={salesPersons} key={321} moveToDetails={moveToDetails.bind(this)} sortListByKey={sortListByKey("internalLeadSearchResults", searchResults)} />
                    </ListSearch>
                </div>
            </div>

            <div className="panel panel-default">
                <div className="panel-heading">
                    <Link to={routes.leadsMySettings}><span className="vismaicon vismaicon-settings pull-right margin-right cursorpointer" title="My settings"></span></Link>
                    <h3 className="panel-title">{leadName + " " + t("internalLeadListContainer.leads")}</h3>
                </div>
                <div className="panel-body">
                    <div className='mb-10'>
                        <button className={filter === "allLeads" ? "btn btn-default active" : "btn btn-default"} onClick={() => filterLeads("allLeads")}>{t("internalLeadListContainer.allLeads")} {allLeadsCounter}</button>
                        <button className={filter === "unassigned" ? "btn btn-default active" : "btn btn-default"} onClick={() => filterLeads("unassigned")}>{t("internalLeadListContainer.unassignedLeads")} {unassignedCounter}</button>
                        <button className={filter === "myLeads" ? "btn btn-default active" : "btn btn-default"} onClick={() => filterLeads("myLeads")}>{t("internalLeadListContainer.myLeads")} {myLeadsCounter}</button>
                    </div>
                    <div className='clearfix'></div>
                    <div>
                        {openLeads.length < 1 && <p>No entries found.</p>}
                        {openLeads.length > 0 && <InternalLeadsList listType="mainList" AssignTo={(leadId, userId) => leadActions.assignInternalLead(leadId, userId)} leads={openLeads} user={user} salesPersons={salesPersons} moveToDetails={moveToDetails.bind(this)} sortListByKey={sortListByKey("internalLeads", allLeads)} />}
                    </div>
                </div>
            </div>

            {closedLeads.length > 0 && <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Closed Leads</h3>
                </div>
                <div className="panel-body">
                    <div>
                        <InternalLeadsList listType="closedList" leads={closedLeads} moveToDetails={moveToDetails.bind(this)} sortListByKey={sortListByKey("internalLeads", allLeads)} />
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        searchResults: state.LeadsReducer.internalLeadSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalLeadsListContainer));