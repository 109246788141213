import React from 'react';
import Loader from '../Loader'
import { useTranslation } from 'react-i18next';
import AlertSmall from '../../Components/AlertSmall';

import * as calcTypes from '../../Constants/priceCalculationTypes'
import { func } from 'prop-types';

const OrderActions = ({onSendApprovalRequest, order, vbCustomer, onComplete, onCancel, disabled, priceCalcType, onSendQuotation, pushedQuotation, quotationCurrentlyInProgress, orderStatus}) => {
    const {t} = useTranslation();
    let disableQuotationButton = disabled;
    if(pushedQuotation || orderStatus === 4 || !quotationCurrentlyInProgress)
        disableQuotationButton = true;

    let disableCompleteOrderButton = disabled;
    if(orderStatus === 4)
        disableCompleteOrderButton = false;
    else if(quotationCurrentlyInProgress)
        disableCompleteOrderButton = true;

    let isAboveCreditLimit = (order && vbCustomer) && order.totalInitialPrice > vbCustomer.creditLimit;

    const completeButtonProps = {
        type: 'button',
        onClick: onComplete,
        className: 'btn btn-primary',
        disabled: disableCompleteOrderButton
    }

    const orderApprovalButtonProps = {
        type: 'button',
        onClick: onSendApprovalRequest,
        className: 'btn btn-primary'
    }

    const quotationButtonProps = {
        type: 'button',
        className: 'btn btn-primary',
        onClick: () => onSendQuotation('quotation'),
        disabled: disableQuotationButton
    }

    const renderAlert = () => {
        if(isAboveCreditLimit){
            return <AlertSmall alert={{ type: "warning", title: "Orderläggningen är över kreditgränsen och kommer behöva godkännas av ekonomiavdelningen." }} />
        }
    }

    const renderOrderApprovalButton = () => {
        return <div>
                <button {...orderApprovalButtonProps}>{t('orderaction.requestapproval')}</button>
        </div>
    }
    
    const renderCompleteButton = () => {
        return priceCalcType && priceCalcType === calcTypes.priceCalculationTypes.order &&
            <>
                    <button {...completeButtonProps}>{t('orderaction.completeorder')}</button>
            </>
    }

    const renderOrderButton = () => {
        if (isAboveCreditLimit) {
            return renderOrderApprovalButton();
        }
        return renderCompleteButton();
    }

    return (
        <div>
            {renderAlert()}
            <div className="panel">
                <div className="body">
                    <div className="padding-top padding-left padding-bottom btn-toolbar" style={{ marginLeft: 0 }}>
                        {renderOrderButton()}
                            <button {...quotationButtonProps}>{t('orderaction.sendquotation')}</button>
                            <button type="button" className="btn btn-default" onClick={onCancel}>{t('orderaction.cancel')}</button>
                            <Loader />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderActions;