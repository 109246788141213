import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../Actions/commonActions'
import appointmentActions from '../../Actions/appointmentActions'

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'

class AppointmentBuyModal extends Component {

    componentDidMount() {
        const { appointmentActions } = this.props;
        appointmentActions.startBuyModal(this.getModel())
    }

    getModel() {
        const { appointment } = this.props;
        return { 
            reasonId: '',
            action:'Close',
            amount: 0,
            appointmentId: appointment.id
        }
    }

    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId } } = this.props;

        commonActions.changeMultiFormField(formId, field, value);
    }

    handleClickSave = () => {
        const { appointmentActions, customerNumberForCallback, form, callback} = this.props;
        appointmentActions.closeAppointment(customerNumberForCallback, form, callback)
    }

    handleClickClose = () => {
        this.props.onClose();
    }

    render() {
        const { reasonCodes, form, leadType } = this.props;

        if(!form) {
            return null;
        }

        const modalProps = {
            title: `Close`,
            mainButtonTitle: "Close",
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <DropDown label="Reason" onChange={this.handleFieldChange.bind(this, 'reasonId')} list={reasonCodes} />
                    <TextInput label="Amount" type={"number"} onChange={this.handleFieldChange.bind(this, 'amount')} value={form.data.amount} />
                    {leadType === "aoLead" && <TextInput label="Message" multiline={true} onChange={this.handleFieldChange.bind(this, 'message')} value={form.data.message} />}
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        reasonCodes: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.AppointmentBuyReasonCodes,
        form: state.CommonReducer.forms && state.CommonReducer.forms.appointmentBuyModal,
        appointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentBuyModal);
