import React from "react";
import AccessIcon from "./AccessIcon";
import { Access, RoleAccessDescriptions } from "../../Constants/userRoles";
import { DropDown } from "../DropDown";
import { useTranslation } from "react-i18next";
import { hasAccess } from "../../Utils/permissionUtils";

function RoleTable({ data, roles, onChange, user }) {
    const { t } = useTranslation();
    const roleAccessDropdown = Object.entries(Access).map(([value, key]) => ({ id: key, name: value }));

    const accessToString = (access) => {
        switch (access) {
            case Access.ReadOnly:
                return "ReadOnly";
            case Access.ReadWrite:
                return "ReadWrite";
            case Access.Admin:
                return "Admin";
            default:
                return "";
        }
    };

    const allowEdit = !!(data && data.userRoles && typeof onChange === 'function');

    return (
        <div className="table-responsive">
            <table className="table table-hover table-relaxed">
                <thead>
                    <tr>
                        <th>{t('roletable.role')}</th>
                        <th>{t('roletable.access')}</th>
                        <th>{t('common.description')}</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((role, index) => {
                        return (
                            <tr key={role.roleId}>
                                <td>{role.roleName}</td>
                                {allowEdit ?
                                    <td>
                                        <DropDown list={roleAccessDropdown} blankOption={true} value={roleAccessDropdown.find(x => x.id === data.userRoles.find(y => y.roleId === role.roleId)?.access)?.id} onChange={(value) => onChange(value, role, data.userRoles, 'roleId', 'userRoles')} customFormClass={"mb-0"} />
                                    </td> :
                                    <td key={index} className="text-center">{t('roletable.' + role.access)}</td>
                                }
                                <td>
                                    {RoleAccessDescriptions[role.roleName] &&
                                        Object.entries(RoleAccessDescriptions[role.roleName]).map(([access, roleAccessDescription], index) => {
                                            return roleAccessDescription && (
                                                <>
                                                    {allowEdit ? <p key={index}>
                                                        <span><strong>{accessToString(Number(access))}: </strong></span>
                                                        <span className={`${(hasAccess(data, role.roleId, access)) ? "text-success bold" : ""}`}>{roleAccessDescription}</span>
                                                    </p> : hasAccess(user, role.roleId, access) ? <p key={index}><AccessIcon access={Number(access)} /> {roleAccessDescription}</p> : null}   
                                                </>
                                            );
                                        })
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default RoleTable;