import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import routes from '../../Constants/routes'
import statusIcon from '../../Constants/statusIcon'
import { Link, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import appointmentActions from '../../Actions/appointmentActions'
import commonActions from '../../Actions/commonActions'

import MyAppointmentTable from '../../Components/Appointment/MyAppointmentTable'
import ListSearch from '../../Components/ListSearch';
import moment from 'moment'



function MyAppointmentsContainer ({appointmentActions, commonActions, myOpenAppointments, searchResults, myFilteredOpenAppointments, myFilter, myClosedAppointments, t}) {

    const navigate = useNavigate();

    useEffect(() => {
        appointmentActions.getMyAppointments()
    }, [])

    const onSearchClick = (phrase) => {
        appointmentActions.search(phrase)
    }

    const onClearClick = () => {
        appointmentActions.clearSearch();
    }

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    const moveToDetails = (appointmentId) => {
        navigate(routes.appointmentDetails(appointmentId));
    }

    const getClassNameForStatus = (appointment) => {
        const currentDate = moment();
        if (appointment.isClosed) {
            return statusIcon.closed;
        } else if (moment(appointment.time) > currentDate) {
            return statusIcon.open;
        } else if (moment(appointment.time) <= currentDate) {
            return statusIcon.warning;
        }
    }

    const filterAppoinments = (filter) => {
        appointmentActions.filterAppoinments(myOpenAppointments, filter);
    }

        if (!myOpenAppointments && !myClosedAppointments) {
            return null;
        }

        if(!myFilter){
            myFilter = "all";
        }

        if (!myFilteredOpenAppointments)
            myFilteredOpenAppointments = myOpenAppointments;

        return (<div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <ListSearch onSearchClick={onSearchClick} onClearClick={onClearClick} results={searchResults} placeholder={t('myappointmentscontainer.searchplaceholder')}>
                        <MyAppointmentTable key={321} appointments={searchResults} sortListByKey={sortListByKey("searchResults", searchResults)} moveToDetails={moveToDetails} getClassNameForStatus={getClassNameForStatus} />
                    </ListSearch>
                </div>
            </div>

            <div className="panel panel-default">
                <div className="panel-heading">
                <Link to={routes.appointmentsMySettings}><span className="vismaicon vismaicon-settings pull-right margin-right cursorpointer" title={t('myopenleadscontainer.settings')}></span></Link>
                    <h3 className="panel-title">{t('myappointmentscontainer.openappointments')}</h3>
                    
                </div>
                <div className="panel-body">
                    <div className="float-right"><Link to={routes.appointmentsNew} className="btn btn-primary">{t('myappointmentscontainer.addnew')}</Link></div>
                    <div><button className={`btn btn-default ${myFilter === "day" && "active"}`} type="button" onClick={() => filterAppoinments("day")}>{t('myappointmentscontainer.thisday')}</button>
                        <button className={`btn btn-default ${myFilter === "week" && "active"}`} type="button" onClick={() => filterAppoinments("week")}>{t('myappointmentscontainer.thisweek')}</button>
                        <button className={`btn btn-default ${myFilter === "month" && "active"}`} type="button" onClick={() => filterAppoinments("month")}>{t('myappointmentscontainer.thismonth')}</button>
                        <button className={`btn btn-default ${myFilter === "all" && "active"}`} type="button" onClick={() => filterAppoinments("all")}>{t('myappointmentscontainer.all')}</button></div>
                    <div>
                        {myFilteredOpenAppointments.length < 1 && <p>{t('myappointmentscontainer.noentries')}</p>}
                        {myFilteredOpenAppointments.length > 0 && <MyAppointmentTable appointments={myFilteredOpenAppointments} sortListByKey={sortListByKey("myFilteredOpenAppointments", myFilteredOpenAppointments)} moveToDetails={moveToDetails} getClassNameForStatus={getClassNameForStatus} />}
                    </div>
                </div>
            </div>

            {myClosedAppointments.length > 0 && <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">{t('myappointmentscontainer.closedappointments')}</h3>
                </div>
                <div className="panel-body">
                    <div>
                        <MyAppointmentTable appointments={myClosedAppointments} closed={true} sortListByKey={sortListByKey("myClosedAppointments", myClosedAppointments)} moveToDetails={moveToDetails} getClassNameForStatus={getClassNameForStatus} />
                    </div>
                </div>
            </div>
            }
        </div>
        );
    }

function mapStateToProps(state) {
    return {
        myOpenAppointments: state.AppointmentReducer.myOpenAppointments,
        myClosedAppointments: state.AppointmentReducer.myClosedAppointments,
        searchResults: state.AppointmentReducer.searchResults,
        myFilteredOpenAppointments: state.AppointmentReducer.myFilteredOpenAppointments,
        myFilter:  state.AppointmentReducer.myFilter
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyAppointmentsContainer));