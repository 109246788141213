import { discountAgreementActionTypes as actionTypes } from "../../ActionTypes/Voss/discountAgreementActionTypes";
import { initialState } from "../CommonReducer";

export default function DiscountAgreementsReducer(state = {initialState}, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_DISCOUNT_AGREEMENTS:
            newState = { discountAgreements: action.data }
            break;
        case actionTypes.STORE_DISCOUNT_AGREEMENT:
            newState = { discountAgreement: action.data }
            break;
        case actionTypes.STORE_DISCOUNT_AGREEMENT_VERSIONS:
            newState = { discountAgreementVersions: action.data }
            break;
        case actionTypes.STORE_CHARGE_PLAN_DISCOUNTS:
            newState = { chargePlanDiscounts: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}