import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import routes from '../Constants/routes'
import { withTranslation } from 'react-i18next';

import Modal from '../Components/Modal'
import EmailTemplate from '../Components/EmailTemplate'
import StaticTextInput from './StaticTextInput';
import TextInput from './TextInput'

class ConvertQuotationModal extends Component {

    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    handleFieldChange = (field, value) => {
        const { onChange, form } = this.props;

        onChange(form.id, field, value);
    }

    handleSaveClick = () => {
        const {onSave, form} = this.props;

        onSave(form);
    }

    handleCloseClick = () => {
        const { onClose, form } = this.props;
        
        onClose(form.id);
    }

    renderHeading(orderline, lineNumber)
    {
        const {t}  = this.props
        if(orderline.sourceSystem === 2 && orderline.addons.length > 0)
            return <legend style={{ marginTop: "20px" }}>{t("convertquotationmodal.addonsfororderline") + lineNumber}</legend> 
    }

    renderAddons(orderline)
    {
        const {t} = this.props
        return orderline.addons && orderline.addons.map((addon, index) => {
            return <div style={{marginBottom:"20px"}} key={index}>
                    <StaticTextInput label={t("convertquotationmodal.addonname")} value={addon.productDescription} />
                    <StaticTextInput label={t("convertquotationmodal.addoninitialprice")} value={addon.initialPrice} />
                    <StaticTextInput label={t("convertquotationmodal.addonrecurringprice")} value={addon.recurringPrice} />
                </div>
        })
    }

    render() {

        const { onClose, form, t} = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        const modalProps = {
            title: t("convertquotationmodal.quotationdetails"),
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.handleFieldChange,
            onCancelClick: () => onClose(form.id),
            onSaveClick: this.handleSaveClick,
            dialogStyle: {width:"80%"},
            mainButtonTitle: t("convertquotationmodal.converttoorder")
        }

        return (
            <Modal {...modalProps}>
                <div>
                    <div>
                        <form className="form-horizontal">
                                <h2 style={{marginTop: "20px"}}>{t("convertquotationmodal.quotationsummary")}</h2>
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                    <h3 style={{marginTop: "20px"}}>{t("convertquotationmodal.quotationinfo")}</h3>
                                        <StaticTextInput label={t("convertquotationmodal.quotationid")} value={data.id} />
                                        <StaticTextInput label={t("convertquotationmodal.orderid")} value={data.orderId} />
                                        <StaticTextInput label={t("convertquotationmodal.salespersonnumber")} value={data.salesPersonNumber} />
                                        <StaticTextInput label={t("convertquotationmodal.email")} value={data.email} />
                                        <TextInput label={t("convertquotationmodal.organizationnumber")} textInputWidth={4} value={data.organizationNumber} onChange={this.handleFieldChange.bind(this, "organizationNumber")} />
                                        <TextInput label={t("convertquotationmodal.customernumber")} textInputWidth={4} value={data.customerNumber} onChange={this.handleFieldChange.bind(this, "customerNumber")} />
                                        <StaticTextInput label={t("convertquotationmodal.orderinitialprice")} value={data.order.totalInitialPrice} />
                                        <StaticTextInput label={t("convertquotationmodal.orderrecurringprice")} value={data.order.totalRecurringPrice} />
                                    </div>
                                    {data.order && data.order.orderlines && data.order.orderlines.map((orderline, index) => {
                                        return <div key={index} className="col-md-6">
                                                <h3 style={{ marginTop: "20px" }}>{t("convertquotationmodal.orderline") + (index + 1)}</h3>
                                                <StaticTextInput label={t("convertquotationmodal.productname")} value={orderline.productName} />
                                                <StaticTextInput label={t("convertquotationmodal.additionalusers")} value={orderline.additionalUsersCount} />
                                                {
                                                    orderline.sourceSystem === 1 && 
                                                    <StaticTextInput label={t("convertquotationmodal.api")} value={orderline.api ? "Yes" : "No"} />
                                                }
                                                <StaticTextInput label={t("convertquotationmodal.initialprice")} value={orderline.totalInitialPrice} />
                                                <StaticTextInput label={t("convertquotationmodal.recurringprice")} value={orderline.totalRecurringPrice} />
                                                <StaticTextInput label={t("convertquotationmodal.competitordiscount")} value={orderline.competitorDiscount ? "Yes" : "No"} />
                                                {
                                                    this.renderHeading(orderline, index + 1)
                                                }
                                                {
                                                    this.renderAddons(orderline)
                                                }
                                        </div>
                                    })}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        //customerActions: bindActionCreators(customerActions, dispatch),
        //commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConvertQuotationModal));