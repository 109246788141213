import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import { ProductSummaryCard } from "../ProductSummary";
import helpers from "../../../../Utils/helpers";

export function VonProductSummaryCard({ ...props }) {
    const { product } = props;
    const { t } = useTranslation();

    const getAddons = () => {

        const addons = [];

        const checkedAddons = product.addons.filter(addon => addon.isChecked);
        if (checkedAddons.length > 0) {
            const mappedAddons = checkedAddons.map((addon) => ({
                description: addon.productDescription,
                initialPrice: addon.initialPrice,
                recurringPrice: addon.recurringPrice,
            }));

            addons.push(...mappedAddons);
        }

        if (product?.additionalUsersCount > 0) {
            const addon = {
                description: `${product?.additionalUsersCount > 0 ? `${product.additionalUsersCount} additional ${product.additionalUsersCount > 1 ? "users" : "user"}` : ""}`,
                initialPrice: product.additionalUsersInitialPrice,
                recurringPrice: product.additionalUsersRecurringPrice
            };
            addons.push(addon);
        }

        return addons;
    };

    let addons = getAddons();

    return <ProductSummaryCard product={product}>
        <ProductSummaryCard.Table>
            <ProductSummaryCard.Table.THeader>
                <th className='col-6'>{t('productsummary.main')}</th>
                <th className='col-2'>{t('productsummary.initialprice')}</th>
                <th className='col-2'>{t('productsummary.nextprice')}</th>
            </ProductSummaryCard.Table.THeader>
            <ProductSummaryCard.Table.TBody>
                <tr>
                    <td scope="row">{product.productDescription}</td>
                    <td>{helpers.currencyFormatter(product.mainInitialPrice ?? 0)}</td>
                    <td>{helpers.currencyFormatter(product.mainRecurringPrice ?? 0)}</td>
                </tr>
            </ProductSummaryCard.Table.TBody>
        </ProductSummaryCard.Table>
        {(addons && addons.length > 0) && <ProductSummaryCard.Table>
            <ProductSummaryCard.Table.THeader>
                <th className="col-6">{t('productsummary.addons')}</th>
                <th className='col-2'></th>
                <th className='col-2'></th>
            </ProductSummaryCard.Table.THeader>
            <ProductSummaryCard.Table.TBody>
                {addons.map((addon) => (
                    <tr>
                        <td scope="row">{addon.description}</td>
                        <td>{helpers.currencyFormatter(addon.initialPrice ?? 0)}</td>
                        <td>{helpers.currencyFormatter(addon.recurringPrice ?? 0)}</td>
                    </tr>
                ))}
            </ProductSummaryCard.Table.TBody>
        </ProductSummaryCard.Table>}
        <ProductSummaryCard.Table className="border-0 shadow-0">
            <ProductSummaryCard.Table.THeader>
                <th className="col-6">{t('productsummary.total')}</th>
                <th className='col-2'>{helpers.currencyFormatter(product.totalInitialPrice)}</th>
                <th className='col-2'>{helpers.currencyFormatter(product.totalRecurringPrice)}</th>
            </ProductSummaryCard.Table.THeader>
        </ProductSummaryCard.Table>
        <div className='d-flex d-flex flex-row justify-content-end my-24 mr-24 align-items-center'>
            <div className='col-3 mr-24'>
                <div className='col-12'>
                    <div className='d-flex justify-content-between mb-12'>
                        <p className='mb-0 font-family-ubuntu-semibold'>{t('productsummary.renewperiod')}</p>
                        <p className='mb-0'>{product.period} {t('productsummary.months')}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='mb-0 font-family-ubuntu-semibold'>{t('productsummary.nextinvoicedate')}</p>
                        <p className='mb-0'>{helpers.formatDate(product.nextInvoiceDate)}</p>
                    </div>
                </div>
            </div>
            <div>
                <ProductSummaryCard.Actions {...props} />
            </div>
        </div>
    </ProductSummaryCard>
}