import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import routes from '../../Constants/routes'
import UserSearch from '../../Components/UserSearch';
import helpers from '../../Utils/helpers';
import { useState } from 'react';

function EmailTemplatesContainer({ settingsActions, commonActions, t, emailTemplates }) {

    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        settingsActions.getEmailTemplates();
    }, [])

    const onFieldChange = (templateId, userName) => {
        if (userName === '') {
            commonActions.showError(t('emailtemplatescontainer.responsiblewarning'));
        } else {
            settingsActions.assignTemplateResponsible(templateId, userName)
        }
    }

    const onClick = (e, template) => {
        if (e.target.className !== "preventRowClick" && e.target.tagName !== "INPUT" && e.target.tagName !== "SELECT" && e.target.tagName !== "SPAN")
            navigate(routes.settingsEmailTemplateEdit(template.id));
    }

    const onChangeSearch = (search) => {
        setSearchTerm(search)
    }

    const filterUserBySearchTerm = (template) => {
        return (template.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            (template.responsibleName ? template.responsibleName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) : false)
            || template.subject.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (!emailTemplates) {
        return null;
    }

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="col-xs-12">
                        <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={t('emailtemplatescontainer.placeholder')}></input>
                    </div>
                </div>
            </div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th>{t('emailtemplatescontainer.name')}</th>
                        <th>{t('emailtemplatescontainer.from')}</th>
                        <th>{t('emailtemplatescontainer.subject')}</th>
                        <th>{t('emailtemplatescontainer.type')}</th>
                        <th>{t('emailtemplatescontainer.responsible')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {emailTemplates.map((template, index) => {
                        if (filterUserBySearchTerm(template)) {
                            return <tr key={index + 10} onClick={(e) => onClick(e, template)}>
                                <td>{template.name}</td>
                                <td>{template.from}</td>
                                <td>{template.subject}</td>
                                <td>{template.type}</td>
                                <td className="preventRowClick"><UserSearch onChange={(value) => onFieldChange(template.id, value)} defaultSelected={template.responsibleName} /></td>
                            </tr>
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        emailTemplates: state.SettingsReducer.emailTemplates,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailTemplatesContainer));