import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import appointmentActions from '../../Actions/appointmentActions'
import commonActions from '../../Actions/commonActions'

import Loader from '../../Components/Loader'
import {FormEditor} from '../../Components/Editor'
import { useNavigate } from 'react-router-dom';
import Panel from "../../Components/Panel";

function MyAppointmentsSignatureContainer({ appointmentActions, signatureForm, commonActions, t }) {

    const navigate = useNavigate()

    useEffect(() => {
        appointmentActions.getMySignature();
    }, [])

    const onSaveClick = () => {
        appointmentActions.updateMySignature(signatureForm, () => onCancelClick());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(signatureForm.id, () => navigate(-1));
    }

    const onFieldChange = (value) => {
        commonActions.changeMultiFormField(signatureForm.id, 'signature', value);
    }

    if (!signatureForm) {
        return null;
    }

    return <Panel title={t('myappointmentssignaturecontainer.mysignature')}>
        <form className="form-horizontal col-xs-10">
            <div className="margin-bottom">
                <FormEditor label={t('myappointmentssignaturecontainer.signature')} onChange={onFieldChange} value={signatureForm.data.signature} />
            </div>
            <div className="clearfix"></div>
            <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>{t('myappointmentssignaturecontainer.save')}</button>
                <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('myappointmentssignaturecontainer.cancel')}</button>
                <Loader />
            </div>
        </form>
    </Panel>
}

function mapStateToProps(state) {
    return {
        signatureForm: state.CommonReducer.forms && state.CommonReducer.forms.appointmentsMySignature
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyAppointmentsSignatureContainer));