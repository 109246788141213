const compareVariables = (a, b) => {

    a = !a ? "" : a;
    b = !b ? "" : b;

    a = (typeof a === 'string') ? a.toUpperCase() : a;
    b = (typeof b === 'string') ? b.toUpperCase() : b;

    return a > b;
}

const sorting = {
    sortListByKey(list, key, listName) {
        const sortOrder = this.getSortOrder(listName, key);

        if (sortOrder === "")
            return list;

        list = list.slice();
        list.sort(this.compareValues(key, sortOrder));

        const sortOrderStorage = {
            sortOrder,
            key
        };

        localStorage.setItem("sortOrder_" + listName, JSON.stringify(sortOrderStorage));

        return list;
    },
    getSortOrderFromStorage(listName) {
        const storage = localStorage.getItem("sortOrder_" + listName);

        if (!storage)
            return null;

        return JSON.parse(storage);
    },
    sortListFromStorage(listName, list) {

        const sortOrderStorage = this.getSortOrderFromStorage(listName);

        if (!sortOrderStorage)
            return list;

        return list.sort(this.compareValues(sortOrderStorage.key, sortOrderStorage.sortOrder));
    },
    compareValues(key, order) {
        return function (a, b) {
            if (key.includes(".")) {
                // Handle nestled object
                var propertySplit = key.split(".");

                for (let p = 0; p < propertySplit.length; p++) {

                    let property = propertySplit[p];

                    if (a.hasOwnProperty(property))
                        a = a[property];

                    if (b.hasOwnProperty(property))
                        b = b[property];
                }
            } else {

                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key))
                    return 0;

                a = a[key];
                b = b[key];
            }

            let comparison = compareVariables(a, b) ? 1 : -1;

            return order === 'desc' ? -comparison : comparison;
        };
    },
    getSortOrder(listName, key) {

        const sortOrderStorage = this.getSortOrderFromStorage(listName);

        if (!sortOrderStorage || sortOrderStorage.key !== key)
            return "asc";

        return sortOrderStorage.sortOrder === "desc" ? "asc" : "desc";
    }
};

export default sorting;