export const orderActionTypes = {
    START_ORDER: "START_ORDER",
    START_ORDER_QUOTATION: "START_ORDER_QUOTATION",
    CLEAR_ORDER: "CLEAR_ORDER",
    SHOW_SEARCH_RESULTS: "SHOW_SEARCH_RESULTS",
    SHOW_PRODUCT_DETAILS: "SHOW_PRODUCT_DETAILS",
    HIDE_PRODUCT_DETAILS: "HIDE_PRODUCT_DETAILS",
    SHOW_ORDER_DETAILS: "SHOW_ORDER_DETAILS",
    CHANGE_ADDITIONAL_USERS: "CHANGE_ADDITIONAL_USERS",
    CHANGE_ADDITIONAL_BLIKK_USERS : "CHANGE_ADDITIONAL_BLIKK_USERS",
    CLEAR_BLIKK_ADDITIONAL_USERS: "CLEAR_BLIKK_ADDITIONAL_USERS",
    CLEAR_BLIKK_TRANSACTIONS: "CLEAR_BLIKK_TRANSACTIONS",
    CHANGE_BLIKK_TRANSACTIONS : "CHANGE_BLIKK_TRANSACTIONS",
    TOGGLE_RECEIPT_INTERPRETATION: "TOGGLE_RECEIPT_INTERPRETATION",
    CHANGE_API: "CHANGE_API",
    CANCEL_ORDER: "REMOVE_ALL_PRODUCTS_FROM_ORDER:",
    EDIT_ORDERLINE : "EDIT_ORDERLINE",
    CHANGE_PERIOD: "CHANGE_PERIOD",
    CHANGE_ADDONS: "CHANGE_ADDONS",
    CHANGE_COMPETITOR_DISCOUNT: "CHANGE_COMPETITOR_DISCOUNT",
    CHANGE_LOYALTY_DISCOUNT_CUSTOMER: "CHANGE_LOYALTY_DISCOUNT_CUSTOMER",
    CHANGE_ASSIGNED_LEAD: "CHANGE_ASSIGNED_LEAD",
    CHANGE_ASSIGNED_APPOINTMENT: "CHANGE_ASSIGNED_APPOINTMENT",
    CHANGE_LANG_CODE: "CHANGE_LANG_CODE",
    CHANGE_TEMPLATE_ORGANIZATION: "CHANGE_TEMPLATE_ORGANIZATION",
    SHOW_TEMPLATE_ORGANIZATIONS: "SHOW_TEMPLATE_ORGANIZATIONS",
    SHOW_TRIAL_SEARCH_RESULTS: "SHOW_TRIAL_SEARCH_RESULTS",
    SET_PUSHED_QUOTATION: "SET_PUSHED_QUOTATION",
    CLEAR_PUSHED_QUOTATION: "CLEAR_PUSHED_QUOTATION",
    SET_QUOTATION_CURRENTLY_IN_PROGRESS: "SET_QUOTATION_CURRENTLY_IN_PROGRESS",
    STORE_APPROVAL_REQUESTS: "STORE_APPROVAL_REQUESTS",
    STORE_VOSS_INTERMEDIARIES: "STORE_VOSS_INTERMEDIARIES",
}