import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import quotationActions from '../Actions/quotationActions'
import orderActions from '../Actions/orderActions'
import ListSearch from '../Components/ListSearch'
import ConvertQuotationModal from '../Components/ConvertQuotationModal'

import routes from '../Constants/routes'

import withModalForm from '../Components/HOC/withModalForm'
import { useNavigate } from 'react-router-dom';

function QuotationListContainer({ t, quotationActions, quotations, convertQuotationForm, onFieldChange, onCloseClick, isModalActive, payload, onOpenModal, orderActions }) {

    const navigate = useNavigate();

    const renderConvertButton = (quotation) => {
        return <button className="btn btn-primary" onClick={() => onOpenModal('convertQuotation', quotation)}><span title={t('quotationlistcontainer.detailsconvert')} /> {t('quotationlistcontainer.detailsconvert')}</button>
    }

    const renderCloseButton = (quotation) => {
        return <button className="btn btn-primary" onClick={() => CloseQuotation(quotation)}><span title={t('quotationlistcontainer.closequotation')} /> {t('quotationlistcontainer.closequotation')}</button>
    }

    const CloseQuotation = (quotation) => {
        if (window.confirm(t('quotationlistcontainer.confirmation'))) quotationActions.CloseQuotation(quotation);
    }

    const onSearch = (query) => {
        quotationActions.getQuotations(query)
    }

    const onClearClick = () => {
        quotationActions.clearQuotationSearch();
    }

    const renderNoResults = () => {
        return (<p>{t('quotationlistcontainer.noentriesfound')}</p>);
    }

    const quotation = payload

    if (!quotations)
        return null;

    if (quotations.length < 0)
        renderNoResults();

    var convertQuotationModalProps = {
        form: convertQuotationForm,
        quotation: quotation,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => quotationActions.showConvertQuotationModal(quotation),
        onSave: (form) => orderActions.pushQuotationToCustomerOrder(form, quotation, () => navigate(routes.orderPlacement(form.data.customerNumber)))
    }

    return (
        <div className="panel panel-default">
            <div className="row">
                <div className="col-xs-12">

                    <div className="panel-heading">
                        <h3 className="panel-title">{t('quotationlistcontainer.search')}</h3>
                        <div className="panel-body">
                            <ListSearch onSearchClick={onSearch.bind(this)} onClearClick={onClearClick} results={quotations} placeholder={t('quotationlistcontainer.placeholder')} />
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel panel-default">
                        <div className="table-container">
                            <table className="table table-stripe table-quotations">
                                <thead>
                                    <tr>
                                        <th className="text-left" style={{ width: 40 }}>{t('quotationlistcontainer.quotationnumber')}</th>
                                        <th className="text-left" style={{ width: 140 }}>{t('quotationlistcontainer.organizationnumber')}</th>
                                        <th className="text-left" style={{ width: 140 }}>{t('quotationlistcontainer.customernumber')}</th>
                                        <th className="text-left" style={{ width: 140 }}>{t('quotationlistcontainer.emailaddress')}</th>
                                        <th className="text-right" style={{ width: 140 }}>{t('quotationlistcontainer.salesperson')}</th>
                                        <th className="text-right" style={{ width: 200 }}>{t('quotationlistcontainer.createdat')}</th>
                                        <th className="text-center" style={{ width: 140 }}></th>
                                        <th className="text-center" style={{ width: 140 }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quotations.map((quotation, index) => {
                                        return <tr key={index}>
                                            <td>{quotation.id}</td>
                                            <td>{quotation.organizationNumber}</td>
                                            <td>{quotation.customerNumber}</td>
                                            <td>{quotation.email}</td>
                                            <td className="text-right">{quotation.salesPerson}</td>
                                            <td className="text-right">{quotation.createDate}</td>
                                            <td className="text-center infoColumn">{renderConvertButton(quotation)}</td>
                                            <td className="text-center infoColumn">{renderCloseButton(quotation)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {isModalActive('convertQuotation') && <ConvertQuotationModal {...convertQuotationModalProps} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        quotations: state.QuotationReducer && state.QuotationReducer.quotations,
        convertQuotationForm: state.CommonReducer.forms && state.CommonReducer.forms.convertQuotationForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        quotationActions: bindActionCreators(quotationActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuotationListContainer)));