import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import { useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'
import DropDown from '../../Components/DropDown';
import AccessIcon from "../../Components/Permission/AccessIcon";
import { hasAdminAccess } from '../../Utils/permissionUtils';
import { UserRoles } from '../../Constants/userRoles';

function AdminUsersContainer({ settingsActions, users, t, userRoles, managedTeams, user }) {

    const navigate = useNavigate();

    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [roleType, setRoleType] = useState('team');

    useEffect(() => {

        if (hasAdminAccess(user, UserRoles.AdministratorSkynet)) {
            settingsActions.getAllEditRoleModel();

        } else {
            settingsActions.getEditRoleModel();
        }

        settingsActions.getAllUserRoles()
    }, [])

    const onClick = (user, type) => {
        navigate(routes.settingsAdminUsersEdit(user.id, type));
    }

    const isFilterActive = (filter) => {
        return filters.some(item => filter === item);
    }

    const handleFilterSet = (filter) => {
        var array = [...filters]
        var index = array.indexOf(filter)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(filter);
        }
        setFilters(array);
    }

    const filterUserByRole = (user) => {

        if (filters.length < 1) {
            return true
        }

        if (!user.userRoles)
            return false

        return filters.every(filter => user.userRoles.map(r => r.roleId).includes(filter));
    }

    const filterUserBySearchTerm = (user) => {
        return (user.name.toLowerCase().includes(searchTerm.toLowerCase()) || user.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    const renderRoleSpan = (user, role) => {

        const userRole = user.userRoles.find(r => r.roleId === role.roleId);

        return <AccessIcon userRole={userRole} />
    };
    const changeRoleType = (type) => {
        setRoleType(type)
    }

    if (!users) {
        return null;
    }

    const roleTypes = [
        ...(managedTeams && managedTeams.length > 0 ? [{ id: 'team', name: 'Teams' }] : []),
        { id: 'user', name: 'Users' }
    ];

    const getItems = (type) => {
        if (type === 'user') {
            return users
        } else
            return managedTeams;
    }

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="filters">
                        {userRoles && userRoles.map((role, index) => {
                            return <button key={index} type="button" className={`btn btn-default ${isFilterActive(role.role) && "active"}`} onClick={() => handleFilterSet(role.roleId)}>{role.roleName}</button>
                        })}
                    </div>
                    {roleTypes.length > 1 && <DropDown onChange={(value) => changeRoleType(value)} list={roleTypes} value={roleType} customClass={'budget-dropdown mt-24'} />}
                    <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={t('adminuserscontainer.placeholder')}></input>
                    <br />
                    <button type="button" onClick={settingsActions.runUserSynchronization} className="btn btn-default">Synkronisera användare</button>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>{t('adminuserscontainer.name')}</th>
                            {userRoles && userRoles.map((role, index) => {
                                return <th key={index}>{role.roleName}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {getItems(roleType).map((item, index) => {
                            if (filterUserByRole(item) && filterUserBySearchTerm(item)) {
                                return <tr key={index} onClick={() => onClick(item, roleType)}>
                                    <td>{item.name}</td>
                                    {userRoles && userRoles.map((role, index) => {
                                        return (
                                            <td key={index} className="text-center">
                                                {renderRoleSpan(item, role)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            }
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        userRoles: state.SettingsReducer.userRoles,
        users: state.SettingsReducer.users,
        managedTeams: state.SettingsReducer.managedTeams,
        user: state.CommonReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminUsersContainer));