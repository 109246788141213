import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import packageActions from "../../../Actions/Voss/packageActions";
import commonActions from "../../../Actions/commonActions";
import discountAgreementActions from "../../../Actions/Voss/discountAgreementActions";
import DiscountPricingFormContainer from "./DiscountPricingFormContainer";
import { TabsCard } from "../../../Components/Card/TabsCard";
import Card from "../../../Components/Card/Card";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../Constants/routes";
import DiscountCustomerFormContainer from "./DiscountCustomerFormContainer";
import { VossApplicationScopes } from "../../../Constants/VOSS/vossApplicationScope";
import { VossPeriodKind } from "../../../Constants/VOSS/vossPeriodKind";
import DiscountInfoFormContainer from "./DiscountInfoFormContainer";

function NewDiscountAgreementFormContainer({ ...props }) {
    const { packageActions, commonActions, form, discountAgreementActions, selectedPackage } = props;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const packageId = searchParams.get("PackageId");

    useEffect(() => {

        if (packageId) {
            packageActions.getPackage(packageId);
        }

        commonActions.startFormEditing("newDiscountAgreementForm", {
            packageId: packageId,
            validFrom: new Date(),
            applicationScope: VossApplicationScopes.Any.toString(),
            periodKind: VossPeriodKind.AlignedToBindingPeriod.toString(),
            periodIterationCount: 1,
        });

        return () => {
            packageActions.clear('package');
            commonActions.cancelFormEditing('newDiscountAgreementForm');
        };
    }, []);

    useEffect(() => {
        packageActions.getPackages();
    }, []);

    useEffect(() => {
        if (selectedPackage) {
            onChange([], 'chargePlanDiscounts');
        }
    }, [selectedPackage]);

    const onSave = () => {
        discountAgreementActions.createDiscountAgreement(form, () => navigate(routes.discounts));
    };

    const onChange = (value, field) => {
        commonActions.changeMultiFormField('newDiscountAgreementForm', field, value);
    };

    const onAddDiscount = (discount) => {
        const chargePlanId = discount.selectedChargePlan.id;
        const productGroupId = discount.selectedProductGroupPricing;
        const productId = discount.selectedProductPricing;

        const productPricingDiscount = {
            productId,
            discountType: discount.selectedDiscountType,
            unitPriceDiscount: discount.unitPrice,
            tierPricingDiscounts: discount.tierPricingDiscounts
        };

        const existingDiscounts = form.data.chargePlanDiscounts || [];

        const chargePlanIndex = existingDiscounts.findIndex(d => d.chargePlanId === chargePlanId);

        if (chargePlanIndex !== -1) {
            const chargePlanDiscount = existingDiscounts[chargePlanIndex];

            const productGroupIndex = chargePlanDiscount.productGroupDiscounts.findIndex(g => g.productGroupId === productGroupId);

            if (productGroupIndex !== -1) {
                const productGroupDiscount = chargePlanDiscount.productGroupDiscounts[productGroupIndex];

                const productPricingIndex = productGroupDiscount.productPricingDiscounts.findIndex(p => p.productId === productId);

                if (productPricingIndex !== -1) {
                    productGroupDiscount.productPricingDiscounts[productPricingIndex] = productPricingDiscount;
                } else {
                    productGroupDiscount.productPricingDiscounts.push(productPricingDiscount);
                }
            } else {
                chargePlanDiscount.productGroupDiscounts.push({
                    productGroupId,
                    productPricingDiscounts: [productPricingDiscount],
                });
            }
        } else {
            existingDiscounts.push({
                chargePlanId,
                productGroupDiscounts: [{
                    productGroupId,
                    productPricingDiscounts: [productPricingDiscount],
                }],
            });
        }

        onChange(existingDiscounts, 'chargePlanDiscounts');
    };

    const onDeleteChargePlanDiscount = (chargePlan) => {
        const existingDiscounts = form.data.chargePlanDiscounts || [];

        const chargePlanId = chargePlan.chargePlanId;
        const chargePlanIndex = existingDiscounts.findIndex(d => d.chargePlanId === chargePlanId);

        if (chargePlanIndex !== -1) {
            existingDiscounts.splice(chargePlanIndex, 1);
        }

        onChange(existingDiscounts, 'chargePlanDiscounts');
    }

    const onDeleteProductPricingDiscount = (chargePlan, productGroup, product) => {
        const existingDiscounts = form.data.chargePlanDiscounts || [];

        const chargePlanId = chargePlan.chargePlanId;
        const productGroupId = productGroup.productGroupId;
        const productId = product.productId;

        const chargePlanIndex = existingDiscounts.findIndex(d => d.chargePlanId === chargePlanId);

        if (chargePlanIndex !== -1) {
            const chargePlanDiscount = existingDiscounts[chargePlanIndex];

            const productGroupIndex = chargePlanDiscount.productGroupDiscounts.findIndex(g => g.productGroupId === productGroupId);

            if (productGroupIndex !== -1) {
                const productGroupDiscount = chargePlanDiscount.productGroupDiscounts[productGroupIndex];

                const productPricingIndex = productGroupDiscount.productPricingDiscounts.findIndex(p => p.productId === productId);

                if (productPricingIndex !== -1) {
                    productGroupDiscount.productPricingDiscounts.splice(productPricingIndex, 1);
                }
            }
        }

        onChange(existingDiscounts, 'chargePlanDiscounts');
    };

    const tabs = [
        {
            name: 'discount-information',
            label: 'Discount information',
            content: <DiscountInfoFormContainer onChange={onChange} {...props} />
        },
        {
            name: 'customer-categories',
            label: 'Customer categories',
            content: <DiscountCustomerFormContainer onChange={onChange} {...props} />
        }
    ];

    if (!form) return null;

    return (
        <div>
            <h2>Create discount agreement</h2>
            <fieldset className="d-grid gap-24 mb-0">
                <TabsCard tabs={tabs} initialTab={tabs[0].name}>
                    <Card.Footer>
                        <Card.Footer.Buttons>
                            <button className="btn btn-primary" onClick={() => navigate(routes.discounts)}>Cancel</button>
                            <button className="btn btn-primary active" onClick={onSave}>Save</button>
                        </Card.Footer.Buttons>
                    </Card.Footer>
                </TabsCard>
                <div>
                    <Card>
                        <Card.Content>
                            <DiscountPricingFormContainer
                                onAddDiscount={onAddDiscount}
                                onDeleteChargePlanDiscount={onDeleteChargePlanDiscount}
                                onDeleteProductPricingDiscount={onDeleteProductPricingDiscount}
                                chargePlanDiscounts={form.data.chargePlanDiscounts}
                                onChange={onChange}
                                {...props}
                            />
                        </Card.Content>
                    </Card>
                </div>
            </fieldset>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        packages: state.PackageReducer.packages,
        selectedPackage: state.PackageReducer.package,
        form: state.CommonReducer.forms && state.CommonReducer.forms.newDiscountAgreementForm,
        customerSearchResults: state.CommonReducer.vossCustomerSearchResults && state.CommonReducer.vossCustomerSearchResults.results,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        packageActions: bindActionCreators(packageActions, dispatch),
        discountAgreementActions: bindActionCreators(discountAgreementActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDiscountAgreementFormContainer);