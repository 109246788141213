import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import TextInput from '../../Components/TextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';


function HittaByraAddDuplicateContainer({ settingsActions, commonActions, form, t }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getDuplicateAoCustomer(id);

    }, [])

    const onSaveClick = () => {

        settingsActions.createDuplicateAoCustomer(form, () => navigate(routes.hittaByra));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.hittaByra))
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }
    
    const data = form && form.data;


    if (!data) {
        return null;
    }

    var inputStyle = {
        width: 450
    }

    return (
        <div>
            <form className="form-horizontal col-xs-12">
                <fieldset className="col-xs-6">
                    <StaticTextInput label={t("hittabyraeditcontainer.customernumber")} value={data.customerNumber} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.organizationnumber")} value={data.organizationNumber} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.address")} value={data.address} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.postcode")} value={data.postCode} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.city")} value={data.city} style={inputStyle} />
                    <StaticTextInput label={t("hittabyraeditcontainer.certified")} value={data.certified ? t("hittabyraeditcontainer.yes") : t("hittabyraeditcontainer.no")} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.name")} value={data.name} disabled={true} onChange={(value) => onFieldChange("name", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualaddress")} value={data.manualAddress} onChange={(value) => onFieldChange("manualAddress", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualpostcode")} value={data.manualPostCode} onChange={(value) => onFieldChange("manualPostCode", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.manualcity")} value={data.manualCity} onChange={(value) => onFieldChange("manualCity", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.url")} value={data.url} onChange={(value) => onFieldChange("url", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.phone")} value={data.phone} onChange={(value) => onFieldChange("phone", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.mobilephone")} value={data.mobilePhone} onChange={(value) => onFieldChange("mobilePhone", value)} style={inputStyle} />
                    <TextInput label={t("hittabyraeditcontainer.email")} value={data.email} onChange={(value) => onFieldChange("email", value)} style={inputStyle} />
                </fieldset>
                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("hittabyraeditcontainer.save")}</button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("hittabyraeditcontainer.cancel")}</button>
                    <Loader />
                </div>
            </form>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        aoCustomer: state.SettingsReducer.aoCustomer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.duplicateAoCustomerEdit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HittaByraAddDuplicateContainer));