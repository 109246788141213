import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import appointmentActions from '../../Actions/appointmentActions'
import commonActions from '../../Actions/commonActions'
import { withTranslation } from 'react-i18next';

import Loader from '../../Components/Loader'
import {FormEditor} from '../../Components/Editor'
import TextInput from '../../Components/TextInput'
import DropDown from '../../Components/DropDown'
import { useNavigate } from 'react-router-dom';

function MyAppointmentsTemplatesContainer({ commonActions, appointmentActions, appointmentsMyTemplate, templates, t }) {

    const navigate = useNavigate()

    useEffect(() => {
        commonActions.loadDropdownList("CustomAppointmentTemplates");
        createBlankFormData();
    }, [])


    const onSaveClick = () => {
        appointmentActions.saveMyTemplate(appointmentsMyTemplate, () => onCancelClick());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(appointmentsMyTemplate.id, () => navigate(-1));
    }

    const onDeleteClick = () => {
        appointmentActions.deleteMyTemplate(appointmentsMyTemplate.data.templateId, () => navigate(-1));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(appointmentsMyTemplate.id, field, value);
    }

    const onTemplateSelect = (id) => {
        if (id > 0) {
            appointmentActions.getMyTemplate(id);
        }
        else {
            createBlankFormData();
        }
    }

    const createBlankFormData = () => {
        commonActions.startFormEditing("appointmentsMyTemplate", { name: "", body: "", subject: "" })
    }

    if (!templates || !appointmentsMyTemplate) {
        return null;
    }

    return (
        <div className="panel">
            <div className="panel-heading">
                <h3 className="panel-title">{t('myappointmentstemplatescontainer.mytemplates')}</h3>
            </div>
            <div className="panel-body">
                <form className="form-horizontal col-xs-10">
                    <div className="margin-bottom">
                        <DropDown label={t('myappointmentstemplatescontainer.template')} onChange={onTemplateSelect} list={templates} blankOption={true} />
                        <TextInput value={appointmentsMyTemplate.data.name} onChange={(value) => onFieldChange("name", value)} label={t('myappointmentstemplatescontainer.templatename')} />
                        <br />
                        <TextInput label={t('myappointmentstemplatescontainer.subject')} value={appointmentsMyTemplate.data.subject} onChange={(value) => onFieldChange("subject", value)} />
                        <FormEditor label={t('myappointmentstemplatescontainer.text')} onChange={(value) => onFieldChange("body", value)} value={appointmentsMyTemplate.data.body} />
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                        <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>
                            {appointmentsMyTemplate.data.templateId > 0 ? t('myappointmentstemplatescontainer.update') : t('myappointmentstemplatescontainer.createnew')}</button>
                        <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('myappointmentstemplatescontainer.cancel')}</button>
                        {appointmentsMyTemplate.data.templateId > 0 &&
                            <>
                                <button type="button" className="btn btn-default pull-right" onClick={() => onDeleteClick()}>{t('myappointmentstemplatescontainer.delete')}</button>
                            </>
                        }
                        <Loader />
                    </div>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        appointmentsMyTemplate: state.CommonReducer.forms && state.CommonReducer.forms.appointmentsMyTemplate,
        templates: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.CustomAppointmentTemplates
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyAppointmentsTemplatesContainer));