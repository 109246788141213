import React, { useState } from 'react';

function NavTabs({ tabs, initialTab }) {
    const [activeTab, setActiveTab] = useState(initialTab ? initialTab.name : tabs[0].name);

    return (
        <div>
            <ul className="nav nav-tabs nav-tabs-primary p-0" role="tablist">
                {tabs.map((tab) => (
                    <li className={`nav-item ${activeTab === tab.name ? 'active' : ''}`} key={tab.name}>
                        <a
                            id={`${tab.name}-tab`}
                            tabIndex="0"
                            role="tab"
                            data-toggle="tab"
                            aria-controls={tab.name.toLowerCase().replace(/\s/g, '-')}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {tab.label}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="tab-content p-32">
                <div id="EventDiv">
                    {tabs.map((tab) => (
                        <div
                            key={tab.name}
                            className={`tab-pane ${activeTab === tab.name ? 'active' : ''}`}
                            role="tabpanel"
                            id={`${tab.name}-tab`}
                        >
                            {activeTab === tab.name && tab.content}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NavTabs;