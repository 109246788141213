import Card from "../../../../Components/Card/Card";
import { EditSubscriptionPlanForm } from "../../../../Components/Customer/VOSS/Subscriptions/EditSubscriptionPlanForm";
import { ScheduledSubscriptionChanges } from "../../../../Components/Customer/VOSS/Subscriptions/ScheduledSubscriptionChanges";

export function EditSubscriptionPlanContainer({ ...props }) {
    const { subscriptionActions, planForm } = props;

    const onSave = () => {
        subscriptionActions.changeSubscriptionPlan(planForm, () => { subscriptionActions.getSubscription(planForm.data.id) });
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>Change plan</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <div className="d-grid gap-24">
                <EditSubscriptionPlanForm {...props} />
                <ScheduledSubscriptionChanges {...props} changeType="plan" />
            </div>
        </Card.Content>
        <Card.Footer>
            <div className="card-btn-group btn-group btn-group-primary">
                <button className="btn btn-primary">Cancel</button>
                <button className="btn btn-primary active" onClick={() => onSave()}>Save</button>
            </div>
        </Card.Footer>
    </Card>
}