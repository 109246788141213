import { useState } from "react";
import DiscountPricingForm from "../../../Components/Voss/DiscountAgreements/DiscountPricingForm";
import ChargePlanDiscountsTable from "../../../Components/Voss/DiscountAgreements/ChargePlanDiscountsTable";

function DiscountPricingFormContainer({ onChange, onAddDiscount, onDeleteChargePlanDiscount, onDeleteProductPricingDiscount, chargePlanDiscounts, ...props }) {
    const [discountToEdit, setDiscountToEdit] = useState(null);

    return <div className="d-flex">
        <div className="col-3">
            <DiscountPricingForm
                onAddDiscount={onAddDiscount}
                discountToEdit={discountToEdit}
                chargePlanDiscounts={chargePlanDiscounts}
                {...props}
            />
        </div>
        <div className="col">
            <ChargePlanDiscountsTable
                onEdit={setDiscountToEdit}
                onDeleteChargePlanDiscount={onDeleteChargePlanDiscount}
                onDeleteProductPricingDiscount={onDeleteProductPricingDiscount}
                chargePlanDiscounts={chargePlanDiscounts}
                {...props}
            />
        </div>
    </div>
}

export default DiscountPricingFormContainer;