import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class AdditionalVonLicensesDropdown extends Component {

    state = {
        isOpen: false
    }

    render() {
        const {disabled, t, users} = this.props;

        let key = 1;

        if(disabled)
            this.state.isOpen = false;
            
        const className = `${this.state.isOpen ? 'open' : ''}`;        

        return <div className={className + " additional-vonlicenses-dropdown-container"} onMouseEnter={() => { this.setState({ isOpen: true })}} onMouseLeave={() => { this.setState({ isOpen: false })}}>
            <div className="additional-vonlicenses-text"><p>{this.props.title}</p></div>
            <div className="dropdown-wrapper">
            <div className={className + " additional-vonlicenses-table-container"}>
            {users && users.length > 0 ? 
            <table className="table table-stripe additional-vonlicenses-dropdown" role="menu">
                <thead>
                    <tr>
                        <th width="150" className="text-left">{t('additionalvonlicensesdropdown.name')}</th>
                        <th className="text-left">{t('additionalvonlicensesdropdown.email')}</th>
                        <th className="text-left">{t('additionalvonlicensesdropdown.role')}</th>
                    </tr>
                </thead>
               
                <tbody>
                {users && users.map(user =>
                    <tr key={key++}>
                        <td className="text-left" style={user.isPrimaryContact ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{user.name}</td>
                        <td className="text-left">{user.email}</td>
                        <td className="text-left">{user.role}</td>
                    </tr>
                )}
                </tbody>
            </table>
             : <div className="dropdown-menu additional-vonlicenses-dropdown no-user-container" role="menu"><p>{t('additionalvonlicensesdropdown.nouser')}</p></div>}
            </div>
            </div>
        </div>
    }
}

export default withTranslation()(AdditionalVonLicensesDropdown)