import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import Card, {CardContainer} from '../../Components/Card/Card';
import InvoiceList from '../../Components/Customer/InvoiceList'
import InvoiceListVoss, {InvoiceDetailsVoss} from '../../Components/Customer/InvoiceListVoss'
import EmailTemplate from '../../Components/EmailTemplate'
import Modal from '../../Components/Modal'

import withModalForm from '../../Components/HOC/withModalForm'
import SendInvoiceCreditModal from '../../Components/Customer/SendInvoiceCreditModal'
import SkynetIcon from "../../Components/Fundamentals/SkynetIcon";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";

function InvoicesContainer({customerActions, commonActions, invoiceForm, invoices, vossInvoices, vossInvoice, vbCustomer, sendInvoiceCreditForm, payload, onCloseClick, isModalActive, onOpenModal, user})
{
    useEffect(() => {
        if (!isFeatureEnabled(user, FeatureFlags.VOSS))
            customerActions.getCustomerInvoices(vbCustomer.customerNumber);
        return () => customerActions.storeVossCustomerInvoice(null)
    }, [])

    const onSendClick = () => {
        customerActions.sendInvoiceEmail(invoiceForm);
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(invoiceForm.id);
        commonActions.showModal(false);
    }

    const onFieldChange = (formId, field, value) => {
        commonActions.changeMultiFormField(formId, field, value)
    }

    const onOpenFormClick = (solumInvoiceOCR, vbInvoiceOCR, invoiceNumber, id) => {
        customerActions.getInvoiceEmailTemplate(vbCustomer.customerNumber, solumInvoiceOCR, vbInvoiceOCR, invoiceNumber, id)
    }

    const showInvoiceDetails = (invoiceId) => {
        customerActions.getVossCustomerInvoice(vbCustomer.customerId, invoiceId)
    }

    const renderNoData = () => {
        return (<p>{t('invoicescontainer.noentriesfound')}</p>);
    }

    const { t}  = useTranslation();

    const invoice = payload;

    const sendInvoiceModalProps = {
        component: EmailTemplate,
        title: t('invoicescontainer.sendinvoice'),
        mainButtonTitle: t('invoicescontainer.send'),
        data: invoiceForm && invoiceForm.data,
        bodyHeight: 200,
        onChange: (field, value) => commonActions.changeMultiFormField(invoiceForm.id, field, value),
        onSaveClick: onSendClick,
        onCancelClick: onCancelClick
    }

    const sendInvoiceCreditModalProps = {
        form: sendInvoiceCreditForm,
        invoice: invoice,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => customerActions.showInvoiceCreditModal(vbCustomer, invoice),
        onSave: (form, body) => customerActions.sendInvoiceCredit(vbCustomer.customerNumber, form, body, () => onCloseClick())
    }

    const onVbCardOpenChanged = (open) => {
        if (open && !invoices) {
            customerActions.getCustomerInvoices(vbCustomer.customerNumber);
        }
    }

    const onVossCardOpenChanged = (open) => {
        if (open && !vossInvoices) {
            customerActions.getVossCustomerInvoices(vbCustomer.customerId);
        }
    }

    const renderVbInvoices = () => {
        if (!invoices)
            return <></>
        if (invoices.length < 1)
            return renderNoData();

        return <>
            <InvoiceList invoices={invoices} customerNumber={vbCustomer.customerNumber} onSendClick={onOpenFormClick} onCreditClick={onOpenModal} />
            <Modal {...sendInvoiceModalProps} />
            {isModalActive('sendInvoiceCredit') && <SendInvoiceCreditModal {...sendInvoiceCreditModalProps} />}
        </>
    }

    const renderVossInvoices = () => {
        if (!vbCustomer.customerId)
            return <p>{t("invoicescontainer.novosscustomer")}</p>;
        if (!vossInvoices)
            return <></>
        if (vossInvoices.length < 1)
            return renderNoData();

        return <InvoiceListVoss invoices={vossInvoices} selectInvoice={showInvoiceDetails} invoice={vossInvoice}/>
    }

    const refreshVossInvoices = (e) => {
        e.stopPropagation();
        customerActions.storeVossCustomerInvoice(null)
        customerActions.getVossCustomerInvoices(vbCustomer.customerId);
    }

    return <CardContainer>
        <Card>
            <Card.CHeader openDefault={!isFeatureEnabled(user, FeatureFlags.VOSS)} onOpenChanged={onVbCardOpenChanged}>
                <Card.Header.Title>{t('invoicescontainer.invoices')}</Card.Header.Title>
            </Card.CHeader>
            <Card.Content isLoading={!invoices}>
                {renderVbInvoices()}
            </Card.Content>
        </Card>
        {vossInvoice ?
            <Card>
                <Card.Header openDefault={true}>
                    <Card.Header.Title>{t('invoicescontainer.invoicedetails')}</Card.Header.Title>
                    <Card.Header.Buttons>
                        <button className="btn btn-default" onClick={() => customerActions.storeVossCustomerInvoice(null)}>
                            {t('invoicescontainer.close')}
                        </button>
                    </Card.Header.Buttons>
                </Card.Header>
                <Card.Content isLoading={vossInvoice?.loading}>
                    <InvoiceDetailsVoss invoice={vossInvoice.invoice} invoiceReceiverCustomerId={vbCustomer.customerId}/>
                </Card.Content>
            </Card>
            : <></>
        }
        {vbCustomer.customerId && isFeatureEnabled(user, FeatureFlags.VOSS) ?
        <Card>
            <Card.CHeader openDefault={false} onOpenChanged={onVossCardOpenChanged}>
                <Card.Header.Title>{t('invoicescontainer.latestinvoicing')}</Card.Header.Title>
                <Card.Header.Buttons>
                    <SkynetIcon icon="vismaicon-refresh" onClick={refreshVossInvoices}/>
                </Card.Header.Buttons>
            </Card.CHeader>
            <Card.Content isLoading={!vossInvoices}>
                {renderVossInvoices()}
            </Card.Content>
        </Card> : <></>}

    </CardContainer>;
}

function mapStateToProps(state) {
    return {
        invoices: state.CustomerReducer.invoices,
        vossInvoices: state.CustomerReducer.vossCustomerInvoices,
        vossInvoice: state.CustomerReducer.vossCustomerInvoice,
        showModal: state.CommonReducer.showModal,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        invoiceForm: state.CommonReducer.forms && state.CommonReducer.forms.invoiceEmail,
        sendInvoiceCreditForm: state.CommonReducer.forms && state.CommonReducer.forms.sendInvoiceCreditForm,
        user: state.CommonReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(InvoicesContainer));