import React from 'react';
import { Route, Routes } from 'react-router-dom'

import MyAppointmentsContainer from '../../Containers/Appointment/MyAppointmentsContainer'
import MyAppointmentsSettingsContainer from '../../Containers/Appointment/MyAppointmentsSettingsContainer'

import MyAppointmentsFormContainer from '../../Containers/Appointment/MyAppointmentsFormContainer'
import routes from '../../Constants/routes'

const MyAppointmentsLayout = () => (
    
        <div className="row">
            <div className="col-xs-12">
                <Routes>
                    <Route index element={<MyAppointmentsContainer/>} />
                    <Route path="/new/" element={<MyAppointmentsFormContainer/>} />
                    <Route path={'/my-settings/'} element={<MyAppointmentsSettingsContainer/>} />
                    <Route path="/appointment/:appointmentId" element={<MyAppointmentsFormContainer/>} />
                </Routes>
            </div>
    </div>
);

export default MyAppointmentsLayout;