import { VossScheduledChangeStatus } from "../../../../Constants/VOSS/vossScheduledChangeStatus";
import helpers from "../../../../Utils/helpers";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

export function ScheduledSubscriptionChanges({ ...props }) {
    const { subscription, subscriptionActions, changeType } = props;
    const scheduledChanges = subscription.scheduledChanges.filter(
        x => x[changeType] != null && x.status === VossScheduledChangeStatus.Pending
    );

    if (!scheduledChanges || scheduledChanges.length === 0) {
        return null;
    }

    const onDelete = (change) => {
        subscriptionActions.deleteScheduledChange(subscription.id, change.id, () => {
            subscriptionActions.getSubscription(subscription.id);
        });
    }

    return <div>
        <h3>Scheduled change</h3>
        {scheduledChanges.map((change, index) => (
            <div key={index} className="d-flex gap-12 align-items-center">
                <p className="mb-0">
                    Change to {change[changeType].name} planned for{" "}
                    {helpers.formatDate(change.scheduledDate)}
                </p>
                <SkynetIcon icon="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-cancel-circle" onClick={() => onDelete(change)} />
            </div>
        ))}
    </div>
}