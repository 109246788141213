import { Typeahead } from "react-bootstrap-typeahead";

export function PackageSelector({ packages, onChange, defaultPackageId, disabled, label, required }) {

    const selectPackage = (value) => {
        if (value.length)
            onChange(value[0].id);
        else
            onChange(null);
    };

    const attributes = {
        id: "package-selector",
        labelKey: option => option.name,
        options: packages,
        placeholder: "Choose package",
        onChange: (selected) => selectPackage(selected),
        defaultSelected: defaultPackageId ? [packages.find(p => p.id === defaultPackageId)] : [],
        disabled: disabled,
    };

    return (
            <div className={`form-control skynet-form-input px-0 mb-0 ${required ? "required":""}`}>
                {label && <label>{label}</label>}
                <Typeahead {...attributes} />
            </div>
        );
}
