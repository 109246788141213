import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/react-dom';
import React, { createContext, useState, useContext } from 'react';

const TooltipContext = createContext(null);
const useTooltipContext = () => {
    const context = useContext(TooltipContext);

    return context;
};

export function TooltipTrigger({ children, attributes }) {
    const context = useTooltipContext();

    const defaultAttributes = {
        onMouseOver: () => context.setIsOpen(true),
        onMouseLeave: () => context.setIsOpen(false),
    };

    const finalAttributes = Object.assign({}, defaultAttributes, attributes);

    return (
        <div ref={context.refs.setReference} {...finalAttributes}>
            {React.Children.map(children, (child) => child && React.cloneElement(child))}
        </div>)
}

export function TooltipContent({ children }) {
    const context = useTooltipContext();

    return (context.isOpen && <div className={`tooltip tooltip-primary ${context.finalOptions.arrowPlacement}`} ref={context.refs.setFloating} style={context.floatingStyles} role="tooltip">
        <div className="tooltip-inner">
            {children}
        </div>
    </div>)
}

export default function Tooltip({ children, options, placement = 'top', arrowPlacement = 'top in' }) {
    const [isOpen, setIsOpen] = useState(false);

    let defaultOptions = {
        placement: placement,
        arrowPlacement: arrowPlacement,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpen,
    }

    var finalOptions = Object.assign([], defaultOptions, options);
    const { refs, floatingStyles } = useFloating(finalOptions);

    return (
        <TooltipContext.Provider value={{ isOpen, setIsOpen, floatingStyles, refs, finalOptions }}>
            {React.Children.map(children, (child) => child && React.cloneElement(child))}
        </TooltipContext.Provider>
    );
}
