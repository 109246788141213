import { useNavigate } from 'react-router-dom';
import helpers from '../../Utils/helpers';
import routes from '../../Constants/routes';
import Modal from '../Modal';
import { useState } from 'react';

function DiscountList({ onSort, filteredDiscounts }) {
    const [comment, setComment] = useState("")
    const navigate = useNavigate();
    const sortList = () => {
        const jsonSort = localStorage.getItem("sortOrder_discounts");
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    const navigateToEdit = (discountId) => {
        const params = new URLSearchParams();
        params.append("DiscountId", discountId);
        navigate(routes.editDiscount + "?" + params.toString())
    }



    const sort = sortList();
    const sortOrder = sort.sortOrder === "asc" ? "float-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "float-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
    const sortKey = sort.key;


    const modalProps = {
        title: "Kommentar",
        customClassName: "discount-comment-modal",
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => setComment(null),
        dialogStyle: { width: "25%", height: "fit-content", minHeight: "0%" },
        readonly:true,
        hideFooter: true,
        cancelTitle:"Avbryt"
    }

    return (
        <>
            <div className="table-responsive">
                <table className="table table-stripe table-hover" style={{ wordBreak: "break-all" }}>
                    <thead>
                        <tr>
                            <th onClick={() => onSort('name')}>Namn {sortKey === 'name' && <i className={sortOrder}></i>}</th>
                            <th onClick={() => onSort('discountPercent')}>Rabatt {sortKey === 'discountPercent' && <i className={sortOrder}></i>}</th>
                            <th onClick={() => onSort('lastEditedTimeUtc')}>Senast ändrad {sortKey === 'lastEditedTimeUtc' && <i className={sortOrder}></i>}</th>
                            <th onClick={() => onSort('lastEditedBy')}>Senast ändrad av {sortKey === 'lastEditedBy' && <i className={sortOrder}></i>}</th>
                            <th>Kommentar</th>
                            <th>Redigera</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDiscounts.map((discount, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td>{discount.name}</td>
                                        {discount.discountPercent && <td>{discount.discountPercent + "%"}</td>}
                                        {discount.discountMoney && <td>{discount.discountMoney + " SEK"}</td>}
                                        <td>{helpers.formatDateAndTime(discount.lastEditedTimeUtc)}</td>
                                        <td>{discount.lastEditedBy}</td>
                                        <td>
                                            {discount.description && <span className="vismaicon vismaicon-comment" onClick={() => setComment(discount.description)} />}
                                        </td>
                                        <td>
                                            <span className="vismaicon vismaicon-edit" onClick={() => navigateToEdit(discount.id)} />
                                        </td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {comment && <Modal {...modalProps}>
                <div>
                    {comment}
                </div>
            </Modal>}
        </>
    )
}
export default DiscountList