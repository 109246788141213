import React, { Component } from 'react';
import './CheckboxToggle.css';

export default function SwitchToggle({ onChange, value, checked, disabled, style, size = "lg" }) {

    const generateId = () => {
        return Math.floor(Math.random() * 100000);
    }

    const id = generateId();

    return <label className={`switch switch-${size}`} htmlFor={id}>
        <input key={value} id={id} style={style} type="checkbox" value={value} checked={checked} disabled={disabled} onChange={onChange} />
        <span className="togglemark"></span>
    </label>
}