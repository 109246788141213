import commonActions from './commonActions'
import customerActions from './customerActions'
import orderActions from './orderActions'
import { quotationActionTypes as actionTypes } from '../ActionTypes/quotationActionTypes'
import { orderActionTypes } from '../ActionTypes/orderActionTypes'
import { customerActionTypes } from '../ActionTypes/customerActionTypes'

import helpers from '../Utils/helpers'
import { httpFetch } from '../Utils/httpUtils'

import system from '../Constants/system'
import * as formValidationRules from '../Constants/formValidationRules'
import * as calcTypes from '../Constants/priceCalculationTypes'

const quotationActions = {
    clearSelectedCustomer() {
        return {
            type: customerActionTypes.RESET
        }
    },

    startOrder(customerNo, form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({ customerNumber: customerNo, organizationNumber: form.data.orgNr, email: form.data.emailAddress, priceCalculationType: calcTypes.priceCalculationTypes.quotation, })
            }

            httpFetch('/api/quotation/startQuotation', dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: orderActionTypes.START_ORDER_QUOTATION, orderId: json.orderId, customerNumber: customerNo, isVonEnabled: json.isVonEnabled });
                    dispatch(quotationActions.getOrder(0, json.orderId)).then(() => {
                        callback(json.orderId);
                    })
                })
        }
    },

    clearOrder() {
        return {
            type: orderActionTypes.CLEAR_ORDER
        }
    },

    getOrder(customerNumber, orderId) {
        return (dispatch, getState) => {
            return httpFetch(`/api/order/GetOrder/${customerNumber}/${orderId}`, dispatch)
                .then(json => {
                    dispatch(quotationActions.showOrderDetails(json))
                    const data = getState().CommonReducer.forms.orderForm.data

                    if(!data)
                        dispatch(commonActions.startFormEditing("orderForm", json, formValidationRules.OrderForm));
                })
        }
    },

    addOrderLine(orderId) {
        return (dispatch, getState) => {
            const orderState = getState().OrderReducer;
            const orderline = orderState.editOrderline;
            const orderlineId = (orderline && orderline.orderlineId) || 0;
            const product = orderState.product;
            const customerNumber = getState().CustomerReducer.selectedCustomer == null ? 0 : getState().CustomerReducer.selectedCustomer.customerNumber;
            const sourceSystem = orderState.sourceSystem;
            const vonAddons = orderState.addons;
            const data = getState().CommonReducer.forms.orderForm.data;
            const adminEmail = data.adminEmail;
            const adminFirstName = data.adminFirstName;
            const adminLastName = data.adminLastName;
            const retailerNumber = data.supplierNumber;
            const partnerId = data.partnerId;
            const templateOrganizationId = product && product.templateOrganizationId ? product.templateOrganizationId : orderState.templateOrganizations && orderState.templateOrganizations.length > 0 ? orderState.templateOrganizations[0].id : "";
            const langCode = product.langCode || "sv";
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.quotation,
                    productId: product.productId,
                    orderId,
                    orderlineId,
                    additionalUsersCount: product.additionalUsersCount,
                    api: product.api === "yes",
                    competitorDiscount: product.competitorDiscount === "yes",
                    customerNumber,
                    sourceSystem: sourceSystem,
                    Period: product.period,
                    VonAddonsIds: vonAddons && Object.values(vonAddons),
                    leadId: product.leadId,
                    appointmentId: product.appointmentId,
                    adminEmail,
                    adminFirstName,
                    adminLastName,
                    partnerId,
                    templateOrganizationId: templateOrganizationId,
                    langCode: langCode,
                    retailerNumber,
                    isNew: product.isNew,
                    ActiveUsers: product.activeUsers,
                    createdDate: product.createdDate,
                    blikkLicenseTypes : product.licenseTypes,
                    blikkTransactionTypes : product.transactionTypes,
                    blikkTenantGuid : product.tenantGuid
                })
            }

            httpFetch('/api/order/AddOrderLine', dispatch, httpRequest)
                .then(json => {
                    dispatch(quotationActions.hideProductDetails());
                    dispatch(quotationActions.getOrder(customerNumber, orderId))
                })
        }
    },

    editOrderline(orderline, customerNumber, orderId) {

        return dispatch => {
            dispatch({ type: orderActionTypes.EDIT_ORDERLINE, orderline })
            
            if (orderline.sourceSystem === system.Von)
                dispatch(quotationActions.getVonProductDetails(orderline.productId, customerNumber, orderline.period, orderId, orderline.additionalUsersCount, orderline.addons && orderline.addons.map(addon => addon.productId))) 
            else if (orderline.sourceSystem === system.Vb)
                dispatch(quotationActions.getProductDetails(orderline.productId, customerNumber, orderline.additionalUsersCount, orderline.api, orderline.competitorDiscount, orderId))
            else if (orderline.sourceSystem === system.CompanyExpense)
                dispatch(quotationActions.getCompanyExpenseProductDetails(orderline.productId, customerNumber, orderline.additionalUsersCount, orderId))
            else if (orderline.sourceSystem === system.Blikk)
                dispatch(quotationActions.getBlikkProductDetails(orderline.productId, customerNumber, orderId))
        }
    },

    removeOrderLines(orderId, orderlineIds, customerNumber) {
        return (dispatch, getState) => {
            const editorderline = getState().OrderReducer.editOrderline;

            if (editorderline && orderlineIds.contains(editorderline.orderlineId)) {
                dispatch(orderActions.hideProductDetails());
            }

            const httpRequest = { method: 'DELETE', body: JSON.stringify(orderlineIds) }

            httpFetch(`/api/order/RemoveOrderLines/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(quotationActions.getOrder(customerNumber, orderId));

                    const order = getState().OrderReducer;

                    if (order.product) {
                        const product = order.product;

                        if (order.sourceSystem === system.Von) {
                            dispatch(quotationActions.getVonProductDetails(product.productId, customerNumber, product.Period, orderId, product.additionalUsersCount, product.addons))
                        }
                        else if (order.sourceSystem === system.Vb) {
                            dispatch(quotationActions.getProductDetails(product.productId, customerNumber, product.additionalUsers, product.api, orderId))
                        }
                        else if (order.sourceSystem === system.CompanyExpense)  {
                            dispatch(quotationActions.getCompanyExpenseProductDetails(product.productId, customerNumber, product.additionalUsersCount, orderId))
                        }
                    }

                })
        }
    },

    search(input) {
        return dispatch => {
            return httpFetch(`/api/quotation/SearchProducts/${input}?searchVon=${true}`, dispatch)
                .then(json => {
                    dispatch(quotationActions.showSearchResults(json));
                })
        }
    },

    getProductDetails(productId, customerNumber, additionalUsers, api, competitorDiscount, orderId) {
        return (dispatch, getState) => {

            if (additionalUsers === undefined) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
                api = (product && product.api === "yes") || false
                competitorDiscount = (product && product.competitorDiscount === "yes") || false
            }

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.quotation,
                    productId,
                    customerNumber,
                    additionalUsersCount: additionalUsers || 0,
                    api: api || false,
                    competitorDiscount: competitorDiscount || false,
                    orderId: orderId === null ? 0 : orderId
                })
            }

            return httpFetch(`/api/order/GetProductDetails`, dispatch, request)
                .then(json => {
                    dispatch(orderActions.setQuotationCurrentlyInProgress(false));
                    dispatch(quotationActions.showProductDetails(json, system.Vb));
                })
                .catch(() => {
                    dispatch(quotationActions.hideProductDetails());
                });
        }
    },

    getVonProductDetails(productId, customerNumber, period, orderId, additionalUsers, vonAddonsIds, getProductFromState) {

        return (dispatch, getState) => {
            
            if (getProductFromState) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
            }

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.quotation,
                    productId,
                    customerNumber,
                    period,
                    additionalUsersCount: additionalUsers,
                    vonAddonsIds: vonAddonsIds && Object.values(vonAddonsIds),
                    orderId: orderId === null ? 0 : orderId
                })
            }

            return httpFetch("/api/order/GetVonProductDetails", dispatch, request)
                .then(json => {
                    dispatch(orderActions.setQuotationCurrentlyInProgress(false));
                    dispatch(quotationActions.showProductDetails(json, system.Von));
                })
                .catch(() => {
                    dispatch(quotationActions.hideProductDetails());
                });
        }
    },

    getCompanyExpenseProductDetails(productId, customerNumber, additionalUsers, orderId) {
        return (dispatch, getState) => {

            if (additionalUsers === undefined) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
            }

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.quotation,
                    productId,
                    customerNumber,
                    additionalUsersCount: additionalUsers,
                    orderId: orderId === null ? 0 : orderId
                })
            }

            return httpFetch(`/api/order/GetCompanyExpenseProductDetails`, dispatch, request)
                .then(json => {
                    json.langCode = json.langCode || "sv";
                    dispatch(orderActions.setQuotationCurrentlyInProgress(false));
                    dispatch(orderActions.showProductDetails(json, system.CompanyExpense));
        
                    if(json.administrator !== undefined && json.administrator !== null)
                    {
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminFirstName', json.adminUser.firstName));
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminLastName', json.adminUser.lastName));
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminEmail', json.adminUser.email));
                    }

                    const partnerId = json.partnerId || "";
                    if(partnerId)
                        dispatch(commonActions.changeMultiFormField('orderForm', 'partnerId', partnerId));
                        
                    dispatch(orderActions.getCompanyExpenseTemplateOrganizations(partnerId));
            
                    if(json.retailerNumber !== undefined && json.retailerNumber !== null)
                        dispatch(commonActions.changeMultiFormField('orderForm', 'supplierNumber', json.retailerNumber));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                    dispatch(commonActions.showError("Something went wrong!", ""));
                });
        }
    },

    getCompanyExpenseTemplateOrganizations(partnerId) {
        var url = partnerId === "" ? `/api/order/GetCompanyExpenseMainTemplates/` : `/api/order/GetCompanyExpenseTemplates/${partnerId}`;
        return (dispatch) => {
            return httpFetch(url, dispatch)
                .then(json => {
                    dispatch({type: orderActionTypes.SHOW_TEMPLATE_ORGANIZATIONS, values: json});
                    if(json.length > 0)
                    dispatch(orderActions.changeAssignedTemplateOrganization(json[0].id));
                })
        }
    },

    getBlikkProductDetails(productId, customerNumber, orderId, tenantGuid, reload) {
        return (dispatch, getState) => {

            let licenseTypes = null;
            if(!reload)
                licenseTypes= getState().QuotationReducer.blikkLicenseTypes;

            let transactionTypes = null;
            if(!reload)
                transactionTypes = getState().OrderReducer.blikkTransactionTypes;
            
            const body = {
                productId,
                customerNumber,
                tenantGuid,
                licenseTypes,
                orderId: orderId === null ? 0 : orderId,
                transactionTypes
            }
            
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(body)
            }
            //Should call a method that doesn't look at a customer number
            return httpFetch(`/api/order/GetBlikkProductDetails`, dispatch, httpRequest)
                .then(json => {
                    dispatch(orderActions.setQuotationCurrentlyInProgress(false));
                    dispatch(quotationActions.changeBlikkUserLicenses(json.licenseTypes));
                    dispatch(quotationActions.showProductDetails(json, system.Blikk));
                })
                .catch(() => {
                    dispatch(quotationActions.hideProductDetails());
                });
        }
    },
    

    showSearchResults(json) {
        return {
            type: orderActionTypes.SHOW_SEARCH_RESULTS,
            results: json
        }
    },

    showProductDetails(product, sourceSystem) {
        return {
            type: orderActionTypes.SHOW_PRODUCT_DETAILS,
            product,
            sourceSystem
        }
    },

    hideProductDetails() {
        return { type: orderActionTypes.HIDE_PRODUCT_DETAILS };
    },

    showOrderDetails(order) {
        return {
            type: orderActionTypes.SHOW_ORDER_DETAILS,
            order
        }
    },

    hideOrderDetails() {
        return quotationActions.showOrderDetails(null)
    },

    cancel(orderId, customerNumber, callback) {
    },

    complete(orderId, customerNumber, orderForm, callback) {
    },

    changeAdditionalUsersCount(count) {
        return {
            type: orderActionTypes.CHANGE_ADDITIONAL_USERS,
            count
        }
    },

    clearBlikkUserLicenses(){
        return {
            type: orderActionTypes.CLEAR_BLIKK_ADDITIONAL_USERS
        }
    },

    changeBlikkUserLicenses(licenseTypes) {
        return {
            type: orderActionTypes.CHANGE_ADDITIONAL_BLIKK_USERS, 
            licenseTypes
        }
    },

    clearBlikkTransactions(){
        return {
            type: orderActionTypes.CLEAR_BLIKK_TRANSACTIONS
        }
    },

    changeBlikkTransactions(transactionTypes) {
        return {
            type: orderActionTypes.CHANGE_BLIKK_TRANSACTIONS, 
            transactionTypes
        }
    },

    changeApi(apiSelected) {
        return {
            type: orderActionTypes.CHANGE_API,
            api: apiSelected
        }
    },

    changePeriod(newPeriod) {
        return {
            type: orderActionTypes.CHANGE_PERIOD,
            period: newPeriod
        }
    },

    changeCompetitorDiscount(discountSelected) {
        return {
            type: orderActionTypes.CHANGE_COMPETITOR_DISCOUNT,
            discountSelected
        }
    },

    changeAssignedLead(leadId) {
    },

    changeAssignedAppointment(appointmentId) {
    },

    changeLangCode(langCode) {
        return {
            type: orderActionTypes.CHANGE_LANG_CODE,
            langCode
        }
    },

    changeAssignedTemplateOrganization(templateOrganizationId) {
        return {
            type: orderActionTypes.CHANGE_TEMPLATE_ORGANIZATION,
            templateOrganizationId
        }
    },

    updateAddons(vonAddonsIds) {
        return {
            type: orderActionTypes.CHANGE_ADDONS,
            values: vonAddonsIds
        }
    },    

    getConfirmationFormTemplate(customerNumber, orderId) {
    },

    getFaq() {
        return dispatch => {
            return httpFetch(`/api/quotation/GetFaq`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.FAQ_QUOTATION, faq: json});
                })
        }
    },

    getAnswer(question)
    {
        return {
            type: actionTypes.SET_ANSWER_QUOTATION,
            faqAnswer: question.answer
        }
    },

    clearAnswer()
    {
        return {
            type: actionTypes.CLEAR_ANSWER_QUOTATION
        }
    },

    startFaqForm(question)
    {
        return dispatch => {
            dispatch(commonActions.startFormEditing("quotationFaqForm", question, formValidationRules.QuotationFaqForm));
        }
    },

    saveFaq(form, callback)
    {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const validationResult = helpers.validateForm(form);

            if(validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/settings/savequotationfaq/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    callback();
                })
        }
    },

    deleteFaq(id, callback)
    {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'POST'
            }

            return httpFetch(`/api/settings/deletequotationfaq/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    callback();
                })
        }
    },

    getQuotations(searchPhrase)
    {
        return dispatch => {
            return httpFetch(`/api/quotation/SearchQuotations/${searchPhrase}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_QUOTATIONS, quotations: json});
                })
        }
    },

    getQuotationById(id)
    {
        return dispatch => {

        }
    },

    clearQuotationSearch()
    {
        return (dispatch) => 
        {
            dispatch(quotationActions.getQuotations(null));
        }
    },

    showConvertQuotationModal(quotation) {
        return (dispatch) => {
            return httpFetch(`/api/quotation/GetQuotationById/${quotation.id}`, dispatch)
            .then(json => {
                const form = {
                    ...{ ...json }, customerNumber: json.order.customerNumber, organizationNumber: json.organizationNumber
                }
                dispatch(commonActions.startFormEditing('convertQuotationForm', form, formValidationRules.convertQuotationForm));
            })
        };
    },

    CloseQuotation(quotation) {
        return (dispatch) => {

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    id: quotation.id
                })
            }

            return httpFetch(`/api/quotation/CloseQuotation/`, dispatch, httpRequest)
            .then(json => {
                dispatch(commonActions.showSuccess("Quotation has been closed!"));
                dispatch(quotationActions.getQuotations(null));
            })
        };
    },

    getOpenQuotationsForCustomer(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/quotation/GetOpenQuotationsAmount/${customerNumber}`, dispatch)
            .then(json => {
                dispatch({type: actionTypes.STORE_CUSTOMER_QUOTATIONS_AMOUNT, quotationsAmount: json});
            })
        };
    }
}

export default quotationActions;