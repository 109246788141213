export const VonAccessRights = {
    Administrator: 'ddb8d5cc-0e91-400c-bcb9-aedc5b850d11',
    User: '0600429b-02de-464f-9845-49b60b6d10cb',
    Inactive: 'Inactive',
    Delete: 'SoftDelete'
}

export const VonAccessRightsRoles = {
    "vismaonline:customeradmin" : VonAccessRights.Administrator,
    "vismaonline:user" : VonAccessRights.User,
}

