import { quotationActionTypes as actionTypes } from '../ActionTypes/quotationActionTypes'

export const initialState = {}

export default function QuotationReducer(state = initialState, action) {

    let newState;

    switch (action.type) {

        case actionTypes.START_ORDER_QUOTATION:
        newState = { searchResults: null, product: null, orderId: action.orderId, customerNumber: action.customerNumber, isVonEnabled: action.isVonEnabled }
        break;

        case actionTypes.SHOW_SEARCH_RESULTS_QUOTATION:
        newState = { searchResults: action.results }
        break;
        
        case actionTypes.SHOW_PRODUCT_DETAILS_QUOTATION:
        newState = { product: action.product, sourceSystem: action.sourceSystem }
        break;

        case actionTypes.HIDE_PRODUCT_DETAILS_QUOTATION:
        newState = { product: null, editOrderline: null }
        break;

        case actionTypes.CHANGE_ADDITIONAL_USERS_QUOTATION:
        newState = { product: Object.assign({}, state.product, { additionalUsersCount: action.count }) }
        break;

        case actionTypes.CHANGE_COMPETITOR_DISCOUNT_QUOTATION:
        newState = { product: Object.assign({}, state.product, { competitorDiscount: action.discountSelected }) }
        break;

        case actionTypes.CHANGE_API_QUOTATION:
        newState = { product: Object.assign({}, state.product, { api: action.api }) }
        break;

        case actionTypes.SHOW_TEMPLATE_ORGANIZATIONS_QUOTATION:
        newState = { templateOrganizations: action.values }
        break;

        case actionTypes.CHANGE_TEMPLATE_ORGANIZATION_QUOTATION:
        newState = { product: Object.assign({}, state.product, { templateOrganizationId: action.templateOrganizationId }) }
        break;

        case actionTypes.FAQ_QUOTATION:
        newState = { quotationFaq: action.faq }
        break;

        case actionTypes.SET_ANSWER_QUOTATION:
        newState = { quotationAnswer: action.faqAnswer}
        break;

        case actionTypes.CLEAR_ANSWER_QUOTATION:
        newState = { quotationAnswer: null}
        break;

        case actionTypes.STORE_QUOTATIONS:
        newState = { quotations: action.quotations }
        break;

        case actionTypes.CLEAR_QUOTATION_SEARCH_RESULTS:
        newState = { quotations: null}
        break;

        case actionTypes.STORE_QUOTATION_WITH_ORDER:
        newState = {quotationWithOrder: action.quotationWithOrder}
        break;

        case actionTypes.STORE_CUSTOMER_QUOTATIONS_AMOUNT:
        newState = {quotationsAmount: action.quotationsAmount}
        break;
        
        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

return state;
}