import React, { Component } from 'react';
import { connect } from 'react-redux'

import './Loader.css'

class Loader extends Component {
    render() {

        if(!this.props.isLoading && !this.props.override) {
            return null;
        }

        return <div className='loader m-auto'/>
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.CommonReducer.isLoading
    }
}

export default connect(mapStateToProps)(Loader);