import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import EmailTemplate from './EmailTemplate'

import Loader from './Loader'

class EmailTemplateWithButtons extends Component {


    render() {
        const data = this.props.data;
        const { t } = this.props
        if (!data) {
            return null;
        }

        return (

            <div>
                <EmailTemplate {...this.props} />
                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar action-buttons">
                    <button type="button" className="btn btn-primary" onClick={this.props.onSaveClick.bind(this)}>{this.props.mainButtonTitle ? this.props.mainButtonTitle : t('emailtemplatewithbuttons.send')}</button>
                    <button type="button" className="btn btn-default" onClick={this.props.onCancelClick.bind(this)}>{t('emailtemplatewithbuttons.cancel')}</button>
                    <Loader />
                </div>
            </div>
        );
    }
}

export default withTranslation()(EmailTemplateWithButtons);