export const targetGroups = {
    TestCustomer: { name: "TestCustomer"},
    AOSimplicity: { name: "AOSimplicity"},
    NonInteractive: { name: "NonInteractive" }
}

export const targetGroupConfigurations = {
    organizationNumberRequired: "organizationNumberRequired,",
    organizationNumberUnique: "organizationNumberUnique",
    customerNumberRequired: "customerNumberRequired",
    customerAddressRequired: "customerAddressRequired",
    contactPersonRequired: "contactPersonRequired",
    expiryDateRequired: "expiryDateRequired",
    excludeCustomerFromInvoiceFile: "excludeCustomerFromInvoiceFile"
}