import React, { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import routes from "../../Constants/routes";
import { withTranslation } from "react-i18next";
import commonActions from "../../Actions/commonActions";
import EditShortcutsModal from "../../Components/Layout/EditShortcutsModal";
import ChangeUserSettingsModal from "../Layout/ChangeUserSettingsModal";
import { useNavigate } from 'react-router-dom';
import NavigationItem from "../Navigation/NavigationItem";

const UserLinks = ({ user, location, t, commonActions, showIcon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showEditShortcutsModal, setShowEditShortcutsModal] = useState(false);
    const [showUserSettingsModal, setShowUserSettingsModal] = useState(false);

    const navigate = useNavigate();

    const logout = () => {
        commonActions.signOut(() => navigate('/'))
    };

    const toggleModal = (modalName) => {
        if (modalName === "showEditShortcutsModal") {
            setShowEditShortcutsModal(!showEditShortcutsModal);
        } else if (modalName === "showUserSettingsModal") {
            setShowUserSettingsModal(!showUserSettingsModal);
        }
    };

    const onModalClicked = (modalName) => {
        setIsOpen(false);
        toggleModal(modalName);
    }

    const getLinks = () => {
        return [
            {
                title: t("topnavigation.shortcuts"),
                path: location.pathname,
                activate: false,
                onClick: () => onModalClicked("showEditShortcutsModal"),
            },
            {
                title: t("changeusersettings.usersettings"),
                path: location.pathname,
                onClick: () => onModalClicked("showUserSettingsModal"),
            },
            {
                title: t("topnavigation.signature"),
                path: routes.editSignature,
                onClick: () => setIsOpen(false),
            },
            // { title: t("graph.myStats"), path: routes.myStats },
            {
                title: t("topnavigation.releaseNews"),
                path: routes.versionArchive,
                onClick: () => setIsOpen(false),
            },
            { title: t("topnavigation.internalcustomernumbers"),
              path: routes.internalCustomers,
              onClick: () => setIsOpen(false)
            }
        ];
    };

    const getTitle = () => {
        return ( <>
            {showIcon ? 
                <>
                    <span className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-user"></span>
                </> : <>
                    {user.name}
                    <small className="selectedContext">{user.teamName}</small>
                    <span className="caret"></span>
                </>    
            }  
            </>
        )
    }

    return (
            <li className={`dropdown user-dropdown ${isOpen ? "open" : ""} ${showIcon ? "icon" : ""}`} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                <a
                    type='button'
                    className={ `${showIcon ? "" : "dropdown-toggle button-context"}`}
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded={isOpen ? "true" : "false"}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {getTitle()}
                </a>
                <ul className='dropdown-menu' role='menu'>
                    <li className="user-details-area clear" role="menuitem">
                        <div>
                            <div className="user-image">
                                <img className="rounded" src={user.picture} alt="User avatar image" />
                            </div>
                            <div className="user-text">
                                <span>{user.name}</span>
                                <span className="text-disabled">{user.email}</span>
                            </div>
                        </div>
                    </li>
                    <li className="divider" />
                    {getLinks().map((item, index) => (
                        <NavigationItem key={index} link={item} location={location} />
                    ))}
                    <li className="divider divider-strong" />
                    <li>
                        <button className="btn btn-lg" onClick={() => logout()}>
                            <span className='vismaicon vismaicon-dropdown-menu vismaicon-logout'></span>
                            {t("topnavigation.logout")}
                        </button>
                    </li>
                </ul>
                {showEditShortcutsModal && (
                    <EditShortcutsModal onClose={() => toggleModal("showEditShortcutsModal")} />
                )}
                {showUserSettingsModal && (
                    <ChangeUserSettingsModal onClose={() => toggleModal("showUserSettingsModal")} />
                )}
            </li>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.CommonReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserLinks));
