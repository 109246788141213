import React from 'react';

import StaticText from '../StaticTextInput'
import DropDown from '../DropDown'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next';

const GuidedSellingEdit = ({ guidedSellingRule, onSaveClick, onCancelClick, onDropDownChange }) => {
    const { t } = useTranslation();
    if (!guidedSellingRule) {
        return null;
    }

    return <div>
        <form className="form-horizontal col-xs-10">
            <fieldset>
                <StaticText label={t('guidedsellingedit.priority')} value={guidedSellingRule.priority} />
                <StaticText label={t('guidedsellingedit.cjomain')} value={guidedSellingRule.cjoMain} />
                <StaticText label={t('guidedsellingedit.hasproduct')} value={guidedSellingRule.productName} />
                {guidedSellingRule.productSuggestions.map((suggestion, index) => {
                    return <DropDown key={index} label={t('guidedsellingedit.suggestion') + ` ${index + 1}`} list={guidedSellingRule.availableSuggestions} value={suggestion.id} onChange={(val) => onDropDownChange(index, val)} />
                })}
            </fieldset>

            <div className="clearfix"></div>
            <div className="form-group padding-top no-left-margin btn-toolbar">
                <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t('guidedsellingedit.save')}</button>
                <button type="button" className="btn btn-default" onClick={onCancelClick}>{t('guidedsellingedit.cancel')}</button>
                <Loader />
            </div>
        </form>
    </div>
}

export default GuidedSellingEdit;