import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import routes from '../../Constants/routes'
import helpers from '../../Utils/helpers'

import StaticTextInput from '../StaticTextInput'
import TextInput from '../TextInput'
import CustomerSearch from '../Search/CustomerSearch';
import UserSearch from '../UserSearch'
import Loader from '../Loader'
import leadActions from '../../Actions/leadActions'
import LeadTrials from './LeadTrials';
import HorizontalText, { HorizontalRow } from "../HorizontalText";

class LeadForm extends Component {

    customerSearchOnChange = (customerNumber, customerName) => {
        this.props.onFieldChange('customerNumber', customerNumber);
        this.props.onFieldChange('name', customerName);
    }

    render() {

        const { lead, onFieldChange, onSaveClick, onCancelClick, readonly, t, onCloseClick } = this.props;

        const newCustomerLink = { pathname: routes.newCustomer, search: `leadId=${lead.id}` }

        if (!lead) {
            return null;
        }

        const phoneNumber = readonly ? helpers.formatPhoneNumberForCountry(lead.phoneNumber) : lead.phoneNumber;

        return <>
            <form className="form-horizontal col-xs-12">
                {!readonly && <>
                    <h3>{t('leadform.searchcustomer')}</h3>
                    <CustomerSearch id={'myLeadDetailsSearch'} label=" " onChange={this.customerSearchOnChange} newCustomerLink={newCustomerLink} />

                            <h3>{t('leadform.contactinformation')}</h3>
                            <TextInput label={t('leadform.phone')} copyToClipBoard={true} onChange={onFieldChange.bind(this, 'phoneNumber')} value={phoneNumber} readonly={readonly} />
                            <TextInput label={t('leadform.email')} copyToClipBoard={true} onChange={onFieldChange.bind(this, 'email')} value={lead.email} type="email" readonly={readonly} />

                            <StaticTextInput label={t('leadform.name')} value={lead.name} />
                            <StaticTextInput label={t('leadform.custno')} value={lead.customerNumber ? lead.customerNumber : 'N/A'} type={lead.customerNumber && lead.customerNumber !== 'N/A' ? 'link' : ''} to={routes.customerMain(lead.customerNumber)} newTab={true} />
                            <TextInput label={t('leadform.organizationnumber')} onChange={onFieldChange.bind(this, 'organizationNumber')} value={!lead.organizationNumber && readonly ? 'N/A' : lead.organizationNumber} readonly={readonly} />
                            <StaticTextInput label={t('leadform.type')} value={lead.companyType} />
                            <TextInput label={t('leadform.contact')} onChange={onFieldChange.bind(this, 'contact')} value={lead.contact} readonly={readonly} />
                            <h3>{t('leadform.leadinformation')}</h3>
                            <UserSearch label={t('leadform.assignedto')} onChange={onFieldChange.bind(this, 'assignee')} defaultSelected={lead.assignee} clearButton={true}  />
                            <StaticTextInput label={t('leadform.type')} value={lead.type} />
                            <StaticTextInput label={t('leadform.product')} value={lead.productNumber} />
                            <StaticTextInput label={t('leadform.created')} value={helpers.formatDate(lead.dateCreated)} />
                            <StaticTextInput label={t('leadform.submitted')} value={helpers.formatDate(lead.dateSubmitted)} />
                            {readonly && <StaticTextInput label={t('leadform.status')} value={lead.status} />}

                    <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                        <button type="button" className="btn btn-primary" onClick={onSaveClick.bind(this)}>{t('leadform.save')}</button>
                        <button type="button" className="btn btn-default" onClick={onCancelClick.bind(this)}><span className="vismaicon vismaicon-sm vismaicon-undo"></span>{t('leadform.cancel')}</button>
                        <Loader />
                    </div>
                </>}
                {readonly && <>
                    <h3>{t('leadform.contactinformation')}</h3>
                    <HorizontalRow>
                        <HorizontalText label={t('leadform.phone')} copyToClipBoard={true} onChange={onFieldChange.bind(this, 'phoneNumber')} value={phoneNumber}  />
                        <HorizontalText label={t('leadform.email')} copyToClipBoard={true} onChange={onFieldChange.bind(this, 'email')} value={lead.email} type="email"  />

                        <HorizontalText label={t('leadform.name')} value={lead.name} />
                        <HorizontalText label={t('leadform.custno')} value={lead.customerNumber ? lead.customerNumber : 'N/A'} type={lead.customerNumber && lead.customerNumber !== 'N/A' ? 'link' : ''} to={routes.customerMain(lead.customerNumber)} newTab={true} />
                        <HorizontalText label={t('leadform.organizationnumber')} onChange={onFieldChange.bind(this, 'organizationNumber')} value={!lead.organizationNumber && readonly ? 'N/A' : lead.organizationNumber}  />
                        <HorizontalText label={t('leadform.type')} value={lead.companyType} />
                        <HorizontalText label={t('leadform.contact')} onChange={onFieldChange.bind(this, 'contact')} value={lead.contact}  />
                        <br/>
                        <HorizontalText label={t('leadform.assignedto')} value={lead.assignee} clearButton={true}  />
                        <HorizontalText label={t('leadform.type')} value={lead.type} />
                        <HorizontalText label={t('leadform.product')} value={lead.productNumber} />
                        <HorizontalText label={t('leadform.created')} value={helpers.formatDate(lead.dateCreated)} />
                        <HorizontalText label={t('leadform.submitted')} value={helpers.formatDate(lead.dateSubmitted)} />
                        <HorizontalText label={t('leadform.status')} value={lead.status} />
                    </HorizontalRow>
                    <br/>
                    <div>
                        <button type="button" className="btn btn-default" onClick={onCloseClick}><span className="vismaicon vismaicon-sm vismaicon-undo"></span>{t('leadform.close')}</button>
                    </div>
                    <LeadTrials lead={lead} />
                </>}
            </form>
        </>

    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(withTranslation()(LeadForm));