import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'

class LeadTrials extends Component {

    renderInternalCustomerAdminButton(customer) {

        const { isProduction, t } = this.props;

        let customerAdminUrl = `https://admin.vismaonline.com/Internal/customerinformationtabstrip.aspx?customerid=${customer.id}`;

        if (!isProduction)
            customerAdminUrl = `https://admin.stage.vismaonline.com/Internal/customerinformationtabstrip.aspx?customerid=${customer.id}`;

        return <button className="btn btn-default" style={{ position: "absolute", top: "24px", right: "24px" }} onClick={() => this.onExternalButtonClick(customerAdminUrl)}>{t("leadtrials.activatetrial")}</button>

    }

    onExternalButtonClick(url) {
        window.open(url);
    }

    render() {

        const { trials, lead, t } = this.props;        

        if (!trials)
            return null;
       
        if(trials.input !== lead.email)
            return null;

        if(trials.customers.length === 0)
            return null;
            
        return <>
                <p className="text-muted">{t('leadtrials.trialfound') + `${lead.email}`}</p>
                {trials.customers.map((customer, index) => {

                    return <div key={customer.id}>{customer.products.map((product, index) => {
                        return <div className="alert alert-info alert-sm" key={index} style={{ marginBottom: "0" }}>
                                <span className="vismaicon vismaicon-filled vismaicon-info vismaicon-alignCenter"></span>
                                <strong>{product.module}</strong>
                                <p>{t('leadtrials.trialexpiredate')}{product.trialExpireDate}</p>
                                {this.renderInternalCustomerAdminButton(customer)}
                                <div className="clearfix"></div>
                            </div>
                    })}</div>
                    })
                }
            <div className="clearfix"></div>
            </>
    }
}

function mapStateToProps(state) {
    return {
        trials: state.LeadsReducer.leadTrial,
        isProduction: state.CommonReducer.isProduction
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadTrials));