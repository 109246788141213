import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import settingsActions from "../../Actions/settingsActions";
import Loader from "../../Components/Loader";
import routes from "../../Constants/routes";
import commonActions from "../../Actions/commonActions";
import TextInput from "../../Components/TextInput";
import {FormEditor} from "../../Components/Editor";
import DateTimeInput from "../../Components/DateTimeInput";
import { useParams, useNavigate } from 'react-router-dom';

function ReleaseNewsEditContainer({ settingsActions, form, commonActions, t }) {

    const navigate = useNavigate();
    const { id } = useParams();

    const onSaveClick = () => {
        if (id === "new") {
            settingsActions.addNewReleaseItemForm(form, () => navigate(routes.releaseNews));
        } else {
            settingsActions.updateReleaseNewsItem(form, () => navigate(routes.releaseNews));
        }
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.releaseNews));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value);
    }

    const onDeleteClick = (id) => {
        settingsActions.deleteReleaseItem(id, () => navigate(routes.releaseNews));
    }

    const data = form && form.data;

    if (!data)
        return null;

    return (
        <form>
            <fieldset>
                <TextInput label={t("releasenews.title")} value={data.title}
                    onChange={(value) => onFieldChange("title", value)}></TextInput>
                <DateTimeInput showTime={false} label={t("releasenews.published")} value={data.publishDate}
                    onChange={(value) => onFieldChange("publishDate", value)}></DateTimeInput>
                <FormEditor label={t('releasenews.content')} value={data.content}
                    onChange={(value) => onFieldChange("content", value)} />
            </fieldset>
            <div className="form-group padding-top no-left-margin btn-toolbar">
                <button type="button" className="btn btn-primary"
                    onClick={onSaveClick}>{t('releasenews.save')}</button>
                <button type="button" className="btn btn-default"
                    onClick={onCancelClick}>{t('releasenews.cancel')}</button>
                <button type="button" className="btn btn-default" onClick={() => onDeleteClick(data.id)}><i
                    className="vismaicon vismaicon-sm vismaicon-delete"></i>{t('releasenews.delete')}</button>
                <Loader />
            </div>
        </form>
    );
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.releaseNewsForm
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReleaseNewsEditContainer));