import React from "react";

const SkynetList = ({title, listItems, keyProperty = 'id', property = 'name'}) => {
    return <>
        {title && <h4 className="bold">{title}</h4>}
        <ul className="no-bullet pl-8 mb-8 skynet-left-border">
            {Object.values(listItems)?.map((item) => <li key={item[keyProperty]}>{item[property]}</li>)}
        </ul>
    </>
}
export default SkynetList;