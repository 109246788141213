import React, {useState} from 'react';
import HorizontalText from '../../HorizontalText';
import {useTranslation} from "react-i18next";
import {Access, UserRoles} from "../../../Constants/userRoles";
import {hasAccess} from "../../../Utils/permissionUtils";
import Tooltip, {TooltipContent, TooltipTrigger} from "../../Overlays/Tooltip";
import infoicon from "../../../Images/infoicon.svg";

export default function AoCustomerInformation( { user, aoCustomerInfo, toggleModal } ) {
    const {t} = useTranslation()

    return <>
        <div className="table-responsive">
            <table className={`table table-stripe`}>
                <tbody>
                <tr>
                    <th>
                        <div className="d-flex">
                            <Tooltip arrowPlacement='bottom in'>
                                <TooltipTrigger><>
                                    {t('aocustomerinformation.assignments')}
                                    <img className="ml-8 mb-2" src={infoicon} alt="Info"/></>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('aocustomerinformation.assignmentreadhover')}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </th>
                    <td>{aoCustomerInfo?.accountingAssignments}</td>
                </tr>
                <tr>
                    <th>
                        <div className="d-flex">
                            <Tooltip arrowPlacement='bottom in'>
                                <TooltipTrigger><>
                                    {t('aocustomerinformation.eekonomi')}
                                    <img className="ml-8 mb-2" src={infoicon} alt="Info"/></>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('aocustomerinformation.eekonomireadhover')}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </th>
                    <td>{aoCustomerInfo?.eekonomi}</td>
                </tr>
                <tr>
                    <th colSpan={2}>
                        <div className="d-flex justify-content-center">
                            <Tooltip>
                                <TooltipTrigger><div >
                                    {t('aocustomerinformation.supplier')}
                                    <img className="ml-8 mb-2" src={infoicon} alt="Info"/></div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('aocustomerinformation.supplierreadhover')}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>{t('aocustomerinformation.accounting')}</th>
                    <td>{aoCustomerInfo?.accounting}</td>
                </tr>
                <tr>
                    <th>{t('aocustomerinformation.payroll')}</th>
                    <td>{aoCustomerInfo?.payroll}</td>
                </tr>
                <tr>
                    <th>{t('aocustomerinformation.clienthandler')}</th>
                    <td>{aoCustomerInfo?.clientHandler}</td>
                </tr>
                <tr>
                    <th>{t('aocustomerinformation.financialstatements')}</th>
                    <td>{aoCustomerInfo?.financialStatements}</td>
                </tr>
                </tbody>
            </table>
            {hasAccess(user, UserRoles.AOSales, Access.ReadWrite) &&
                <button type="button" className="pull-right btn btn-default"
                        onClick={() => toggleModal("editAOCustomerInfo", "updateAOCustomerInfoModal")}>{t('aocustomerinformation.edit')}</button>
            }
        </div>
    </>
}