import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import moment from "moment";
import leadActions from "../../Actions/leadActions";

import MyStatsGraph from "../MyStatsGraph";

import {
  handleClick,
  handleDataFromTime,
  countOccurrenceOnDate,
  addOccurrencesOverTime,
} from "./graphHelpers";

class CreatedAoLeadsTeamGraph extends Component {
  state = {
    data: [],
    timePeriod: "month",
  };

  async componentDidMount() {
    const { leadActions, user } = this.props;
    await leadActions.getAoLeads();
    this.setState({
      ...this.state,
      data: handleDataFromTime(
        this.props.createdAoLeadsTeam.filter(
          (lead) => parseInt(lead.teamId) === user.teamId
        ),
        moment().startOf("month"),
        moment().endOf("month"),
        "dateCreated"
      ),
    });
  }

  clickFunc = (event) => {
    const { value } = event.target;

    this.setState({
      ...this.state,
      data: handleClick(
        event,
        this.props.createdAoLeadsTeam.filter(
          (lead) => parseInt(lead.teamId) === this.props.user.teamId
        ),
        "dateCreated"
      ),
    });

    this.setState((state) => ({ timePeriod: value }));
  };

  render() {
    const { createdAoLeadsTeam, t } = this.props;

    if (!createdAoLeadsTeam) {
      return null;
    }
    const finalData = addOccurrencesOverTime(
      countOccurrenceOnDate(this.state.data, "dateCreated"),
      "dateCreated",
      "occurrence"
    );

    return (
      <MyStatsGraph
        typeOfGraph='Line'
        labels={finalData.map(({ date }) => moment(date).format("MMM DD"))}
        data={finalData.map(({ value }) => value)}
        timePeriod={this.state.timePeriod}
        title={t("graph.createdAoLeadsTeam")}
        backgroundColor={["rgb(245, 245, 245)"]}
        onClick={(event) => this.clickFunc(event)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.CommonReducer.user,
    createdAoLeadsTeam: state.LeadsReducer.aoLeads,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadActions: bindActionCreators(leadActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreatedAoLeadsTeamGraph));
