import { discountActionTypes as actionTypes } from "../ActionTypes/discountActionTypes";
import { commonActionTypes } from "../ActionTypes/commonActionTypes";
import { initialState } from "./CommonReducer";

export default function DiscountReducer(state = {initialState}, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_DISCOUNTS:
            newState = { discounts: action.data }
            break;
        case commonActionTypes.SORTED_LIST:
            if (action.listName === "discounts")
                newState = { discounts: action.list }
            break;
        case actionTypes.STORE_SERVICES:
            newState = { services: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}