import VonEditEmployeeLicensesTable from "./VonEditEmployeeLicensesTable";

export default function VonEditEmployeeLicensesForm({ vonLicenseAdministrationForm, selectedService, onChange }) {
    return <div className="d-grid">
        <VonEditEmployeeLicensesTable data={vonLicenseAdministrationForm.data} onChange={onChange} />
        <div>
            <p className="float-right">
                Kunden använder {vonLicenseAdministrationForm.data.vonAccounts.filter(x => x.roleId != '0').length} av {selectedService.licenses} möjliga licenser
            </p>
        </div>
    </div>
}