import { httpFetch } from '../../Utils/httpUtils'
import { internalCustomerAdminActionTypes as actionTypes } from '../../ActionTypes/InternalCustomerAdmin/internalCustomerAdminActionTypes'
import commonActions from '../commonActions';
import { commonActionTypes } from '../../ActionTypes/commonActionTypes';

const activityLogActions = {

    searchLogs(data) {
        return (dispatch) => {

            dispatch(commonActions.progressLoader(true));
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "activityLogs", value: true });
            return httpFetch(`/api/ActivityLog/GetActivityLogs?searchBy=${data.searchBy}&value=${data.value}&fromDate=${data.fromDate}&toDate=${data.toDate}&applicationId=${data.applicationId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: json });
                })
                .catch(error => {
                    console.error(error);
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: [] });
                })
                .finally(() => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "activityLogs", value: false });
                });
        }
    },

    clear: (key) => {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR, key });
        }
    },
    
    searchFailedMails(data) {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "activityLogs", value: true });
            return httpFetch(`/api/ActivityLog/GetFailedMails?email=${data.value}&fromDate=${data.fromDate}&toDate=${data.toDate}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: json, logType: 'failedMails' });
                })
                .catch(error => {
                    console.error(error);
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: [] });
                })
                .finally(() => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "activityLogs", value: false });
                });
        }
    }
}
export default activityLogActions;