import commonActions from "../../Actions/commonActions";
import Modal from "../Modal";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useEffect } from "react";
import TextInput from "../TextInput";
import {FormEditor} from "../Editor";
import * as formValidationRules from './../../Constants/formValidationRules'
import { withTranslation } from 'react-i18next';

function EditMovieFormContainer({ movie, commonActions, onClose, editForm, toggleEditMovie, t }) {

    useEffect(() => {
        let form = {
            id: movie ? movie.id : null, 
            name: movie ? movie.name : null,
            description: movie ? movie.description : null,
            link: movie ? movie.link : null,
            linkText: movie ? movie.linkText : null,
            category: movie ? movie.category : null
        };

        commonActions.startFormEditing('editSendMovie', form, formValidationRules.EditMovieMail)
    },[])

    const handleFieldChange = (field, value) => {
        commonActions.changeMultiFormField("editSendMovie", field, value);
    }

    const handleClickClose = () => {
        commonActions.cancelFormEditing("editSendMovie", onClose)
    }

    const handleClickUpdate = () => {
        commonActions.updateMovieMail(editForm, () => toggleEditMovie());
    }

    const handleClickSave = () => {
        commonActions.createMovieMail(editForm, () => toggleEditMovie());
    }

    const handleClickDelete = () => {
        commonActions.deleteMovieMail(movie.id, () => toggleEditMovie());
    }

    const modal = {
        title: movie ? t('editmovieformcontainer.editmovie') : t('editmovieformcontainer.createmovie'),
        mainButtonTitle: movie ? t('editmovieformcontainer.updatemovie') : t('editmovieformcontainer.savemovie'),
        onCancelClick: toggleEditMovie,
        cancelTitle: t('editmovieformcontainer.goback'),
        onCloseClick: handleClickClose,
        stateful: true,
        open: true,
        onChange: handleFieldChange,
        onSaveClick: movie ? handleClickUpdate : handleClickSave,
        additionalButtonTitle: t('editmovieformcontainer.deletemovie'),
        onAdditionalClick: handleClickDelete,
        additionalButton: movie,
        additionalButtonStyle: "btn-danger"
    }

    if (!editForm)
        return null

    return (
        <Modal {...modal}>
            <form>
                <fieldset>
                    <div className="form-group">
                        <TextInput label={t('editmovieformcontainer.name')} className="col-xs-8" type="text" value={editForm.data.name} onChange={handleFieldChange.bind(this, "name")} />
                    </div>
                    <div className="form-group">
                        <FormEditor label={t('editmovieformcontainer.description')} className="col-xs-8" type="text" value={editForm.data.description} onChange={handleFieldChange.bind(this, "description")} />
                    </div>
                    <div className="form-group">
                        <TextInput label={t('editmovieformcontainer.link')}  className="col-xs-8" type="text" value={editForm.data.link} onChange={handleFieldChange.bind(this, "link")} />
                    </div>
                    <div className="form-group">
                        <TextInput label={t('editmovieformcontainer.linktext')}  className="col-xs-8" type="text" value={editForm.data.linkText} onChange={handleFieldChange.bind(this, "linkText")} />
                    </div>
                    <div className="form-group">
                        <TextInput label={t('editmovieformcontainer.category')}  className="col-xs-8" type="text" value={editForm.data.category} onChange={handleFieldChange.bind(this, "category")} />
                    </div>
                </fieldset>
            </form>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        editForm: state.CommonReducer.forms && state.CommonReducer.forms.editSendMovie,      
    }
}
function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditMovieFormContainer))