import React from 'react';
import { Route, Routes } from 'react-router-dom'
import TerminationReasonsContainer from '../../../Containers/Settings/InternalCustomerAdmin/TerminationReasons/TerminationReasonsContainer';
import TerminationReasonFormContainer from '../../../Containers/Settings/InternalCustomerAdmin/TerminationReasons/TerminationReasonsFormContainer';

function TerminationReasonsLayout() {

    return <Routes>
        <Route path={'/create'} element={<TerminationReasonFormContainer />} />
        <Route path={'/edit/'} element={<TerminationReasonFormContainer isEdit={true} />} />
        <Route index element={<TerminationReasonsContainer />} />
    </Routes>
};

export default TerminationReasonsLayout;
