import React from 'react';

export default function QuantitySelector({onChange, disabled, value, lowerThreshold = 0}) {
    const [quantity, setQuantity] = React.useState(value)
    const quantityAsNumber = Number(quantity)

    const onIncrease = value => {
        setQuantity(quantityAsNumber + 1)
        onChange(value + 1)
    }

    const onDecrease = value => {
        if (value > 0) {
            setQuantity(quantityAsNumber - 1 )
            onChange(value - 1)
        }
    }

    const onValueChange = e => {
        setQuantity(e.target.value)
        onChange(e.target.value)
    }

    return <div style={{display: "flex", alignItems: "center"}}>
        <span
            className={`vismaicon vismaicon-dynamic vismaicon-remove-circle ${disabled || quantity === lowerThreshold ? 'disabled' : 'cursorpointer'}`}
            onClick={onDecrease.bind(this, quantity)}/>
        <input
            disabled={disabled}
            style={{width: "50px"}}
            className="text-center ml-10 mr-10 rounded-4"
            type="number"
            value={quantity}
            onChange={onValueChange}/>
        <span className={`vismaicon vismaicon-dynamic vismaicon-add-circle ${disabled ? 'disabled' : 'cursorpointer'}`}
              onClick={onIncrease.bind(this, quantity)}/>
    </div>
}