
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class HittaPartnerTable extends Component {

    

    render() {
        const { epiPartners, t, onClick } = this.props;

        return <table className="table table-stripe table-hover">
        <thead>
            <tr>
                <th>{t('hittapartnertable.customernumber')}</th>
                <th>{t('hittapartnertable.name')}</th>
                <th>{t('hittapartnertable.organizationnumber')}</th>
            </tr>
        </thead>
        <tbody>
            { epiPartners.map((partner, index) => {
                return <tr key={index} onClick={() => onClick(partner)}>
                            <td>{partner.customerNumber}</td>
                            <td>{partner.name}</td>
                            <td>{partner.organizationNumber}</td>
                        </tr>
            }) }
        </tbody>
    </table>
    }
}

export default withTranslation()(HittaPartnerTable);
