import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import settingsActions from '../../Actions/settingsActions';
import commonActions from '../../Actions/commonActions';

import routes from '../../Constants/routes';
import moment from 'moment';
import DropDown from '../../Components/DropDown';
import { useNavigate } from 'react-router-dom';
import SwitchToggle from "../../Components/SwitchToggle";


function AdminBudgetContainer({ settingsActions, user, budgetUsers, t, budgetData, budgetTeams }) {

    const navigate = useNavigate();
    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [month, setMonth] = useState(moment().format("MMMM"));
    const [year, setYear] = useState(moment().format("yyyy"));
    const [filterByTeam, setFilterByTeam] = useState(localStorage.getItem("filterByTeam") === "true" ? true : false);
    const [budgetType, setBudgetType] = useState(localStorage.getItem("budgetType") != null ? localStorage.getItem("budgetType") : 'user');

    useEffect(() => {
        settingsActions.getUsersForBudget();
        settingsActions.getAllUserBudgetByMonthYear(month, year);
        settingsActions.getTeamsForBudget();
    }, [])

    const onClick = (item) => {

        if (item.userId != null && item.userId != 0) {
            navigate(routes.editBudget(item.userId, "user"));
        } else {
            navigate(routes.editBudget(item.teamId, "team"))
        }
    }

    const filterUserBySearchTerm = (user) => {
        return (user.userName.toLowerCase().includes(searchTerm.toLowerCase()) || user.userEmail.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    const filterTeamBySearchTerm = (team) => {
        return (team.teamName.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    const budgetTableData = (budgetData, userid) => {
        let userbudgetData;
        if (budgetData && budgetData.some(data => { userbudgetData = data; return data.userId === userid && data.month === month && data.year === year })) {
            return (
                <>
                    <td>{userbudgetData.sek}</td>
                    <td>{userbudgetData.amount}</td>
                </>
            )
        } else {
            return (
                <>
                    <td>0</td>
                    <td>0</td>
                </>
            )
        }
    }

    const teamBudgetTableData = (budgetData, teamId) => {
        let teamBudgetData;
        if (budgetData && budgetData.some(data => { teamBudgetData = data; return data.teamId === teamId && data.month === month && data.year === year && data.userId === null })) {
            return (
                <>
                    <td>{teamBudgetData.sek}</td>
                    <td>{teamBudgetData.amount}</td>
                </>
            )
        } else {
            return (
                <>
                    <td>0</td>
                    <td>0</td>
                </>
            )
        }
    }

    const onTogglePartnerCheckbox = (value) => {
        localStorage.setItem("filterByTeam", JSON.stringify(!value))
        setFilterByTeam(!value)
    }

    const filterUsersByTeam = (user) => {
        return user.managerId === user.vomId;
    }

    const changeBudgetType = (type) => {
        localStorage.setItem("budgetType", type)
        setBudgetType(type)
    }

    const budgetTypes = [{ id: 'user', name: 'Users' }, { id: 'team', name: 'Teams' }]

    if (budgetUsers && budgetType === 'user') {
        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="col-xs-12">
                            <div style={{ float: "left" }}>
                                <label className="control-label" style={{ float: "left", marginRight: "10px", marginBottom: "20px", marginTop: "2px" }}>{"Sort users by my team"}</label>
                                <SwitchToggle checked={filterByTeam} value={filterByTeam} onChange={() => onTogglePartnerCheckbox(filterByTeam)} />
                            </div>
                            <DropDown onChange={(value) => changeBudgetType(value)} list={budgetTypes} value={budgetType} customClass={'budget-dropdown'} />

                            <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={t('adminuserscontainer.placeholder')}></input>
                        </div>
                    </div>
                </div>
                <table className="table table-stripe table-hover">
                    <thead>
                        <tr>
                            <th>{t('adminuserscontainer.name')}</th>
                            <th>{t('adminuserscontainer.email')}</th>
                            <th>{t('budget.sek')}</th>
                            <th>{t('budget.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            budgetUsers.filter(user => user.userName != "").map((user, index) => {
                                if (filterUserBySearchTerm(user) && (filterByTeam ? filterUsersByTeam(user) : true)) {
                                    return <tr key={index} onClick={() => onClick(user)}>
                                        <td>{user.userName}</td>
                                        <td>{user.userEmail}</td>
                                        {budgetTableData(budgetData, user.userId)}
                                    </tr>
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }



    else if (budgetTeams && budgetType === 'team') {
        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="col-xs-12 mb-2">
                            <DropDown onChange={(value) => changeBudgetType(value)} list={budgetTypes} value={budgetType} customClass={'budget-dropdown'} />
                            <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={t('adminuserscontainer.teamplaceholder')}></input>
                        </div>
                    </div>
                </div>
                <table className="table table-stripe table-hover">
                    <thead>
                        <tr>
                            <th>{t('adminuserscontainer.name')}</th>
                            <th>{t('budget.sek')}</th>
                            <th>{t('budget.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            budgetTeams.filter(team => team.teamName != "").map((team, index) => {
                                if (filterTeamBySearchTerm(team)) {
                                    return <tr key={index} onClick={() => onClick(team)}>
                                        <td>{team.teamName}</td>
                                        {teamBudgetTableData(budgetData, team.teamId)}
                                    </tr>
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    } else {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        dropdownlists: state.CommonReducer.dropdownlists,
        budgetUsers: state.SettingsReducer.budgetUsers,
        budgetData: state.SettingsReducer.budgetData,
        user: state.CommonReducer.user,
        budgetTeams: state.SettingsReducer.budgetTeams
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminBudgetContainer));