import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import settingsActions from '../../Actions/settingsActions'

import GuidedSellingList from '../../Components/Settings/GuidedSellingList'

class GuidedSellingContainer extends Component {

    componentDidMount() {
        this.props.settingsActions.getGuidedSellingRules();
    }

    render () {
        return(
            <GuidedSellingList guidedSellingRules={this.props.guidedSellingRules} /> 
        )
    }
}

function mapStateToProps(state) {
    return {
        guidedSellingRules: state.SettingsReducer.guidedSellingRules
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidedSellingContainer);