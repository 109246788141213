export const VossProductType = {
    Main: 0,
    Addon: 1,
    License: 2,
    Usage: 3
}
export const VossProductTypeName = {
    [VossProductType.Main]: "Main",
    [VossProductType.Addon]: "Addon",
    [VossProductType.License]: "License",
    [VossProductType.Usage]: "Usage"
}