import { myDashboardActionTypes as actionTypes } from '../ActionTypes/myDashboardActionTypes'

import { httpFetch } from '../Utils/httpUtils'

const myDashboardActions = {

    getMyDashboardData() {
        return (dispatch) => {
            return httpFetch(`/api/Dashboard/GetMyDashboardData`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MY_REPORT, data: json.myDashboardData });
                    dispatch({ type: actionTypes.STORE_TEAM_REPORT, data: json.teamDashboardData });
                })
        }
    },
    getMyDashboardGoals() {
        return (dispatch) => {
            return httpFetch(`/api/Dashboard/GetMyDashboardGoals`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_DASHBOARD_GOALS, data: json });
                })
        }
    }
}

export default myDashboardActions;    