import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import commonActions from '../Actions/commonActions';

function EditVersionDropdown({productOverviewActions, productList, newProductState, productId, commonActions, t}) {

    useEffect(() => {
        if(!productList){
            productOverviewActions.getProducts();
        }
    }, []);

    useEffect(() => {
      if(newProductState){
        productOverviewActions.resetVersionState()
        productOverviewActions.setSelectedProduct(null)
      }
    }, [newProductState])

    const handleSelect = (e) => {
        commonActions.cancelFormEditing("productForm");
        const productId = e.target.value;
        productOverviewActions.setSelectedProduct(productId)
    }

  return (
    <div>
      <label><strong>{t("productOverview.selectProductDropDown")}</strong></label>
      <select className='form-control' onChange={handleSelect} disabled={newProductState} value={productId === null ? 0 : productId}>
          <option value={0} hidden={true} disabled={true}>Product...</option>
          {productList && productList.map((product, i) => {
              return <option key={i} value={product.id}>{`${product.productName} (${product.sku})`}</option>
          })}
      </select>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    productList: state.ProductOverviewReducer.productList,
    productId: state.ProductOverviewReducer.selectedProductId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditVersionDropdown));