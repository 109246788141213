import React, { useState, useRef } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './BaseSearch.css'

function BaseSearch ({ attributes, results, id }) {
   const [isLoading, setIsLoading] = useState(false);
   const ref = useRef(null);
   
    function handleOnSearch(query) {
        if (!query) {
            return;
        }
        setIsLoading(true);
        attributes.onSearchAttr(query);
    }

    function handleOnChange(options) {
        if(options && options[0]) {
            attributes.onChangeAttr(options[0])
            
            if(attributes.id === 'topnavigationsearch'){
                handleOnClear(undefined)
            }
        }
    }

    function handleOnClear(input) {
        if(!input) {
            attributes.onChangeAttr('')
        }
    }

        let defaultAttributes = {
            labelKey: option => `${option.id}}`, 
            delay: 400,
            minLength: 2,
            highlight: true,
            useCache: false,
            options: results || [],
            onSearch: handleOnSearch.bind(this),
            placeholder: 'Type to search..',
            className: 'col-8',
            onChange: handleOnChange.bind(this),
            onInputChange: handleOnClear.bind(this),
            align: 'left',
            isLoading: isLoading,
            id: id || "baseSearch",
            ref: ref,
            
        };

    const finalAttributes = Object.assign({}, defaultAttributes, attributes);
    return (
        <AsyncTypeahead {...finalAttributes} />
    );
}

export default BaseSearch;