import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import TextInput from '../../Components/TextInput'
import StaticTextInput from '../../Components/StaticTextInput'
import Checkbox from '../../Components/Checkbox'
import RadioButton from '../../Components/RadioButton'
import Loader from '../../Components/Loader'
import routes from '../../Constants/routes'
import helpers from '../../Utils/helpers'
import LeadsOrderDropdown from '../../Components/Leads/LeadsOrderDropdown'
import { useParams, useNavigate } from 'react-router-dom';
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import ResellerSearch from '../../Components/ResellerSearch'
import Panel from "../../Components/Panel";
import { useTranslation } from "react-i18next";

function BookEducationContainer({ customerActions, vbCustomer, customer, userSalesNumber, commonActions, bookEducationForm, education }) {

    const navigate = useNavigate();
    const { courseId } = useParams();
    const { t } = useTranslation();
    
    useEffect(() => {
        customerActions.startEducationBooking(createEducationFormData(), courseId);
    }, [])

    const navigateAfterAction = () => {
        return () => navigate(routes.customerMain(customer.pathId));
    }

    const createEducationFormData = () => {
        
        return {
            customerNumber: vbCustomer.customerNumber,
            invoiceCustomerNumber: vbCustomer.customerNumber,
            name: vbCustomer.name,
            contactFirstName: getCustomerContactPersonFirstName(vbCustomer),
            contactLastName: getCustomerContactPersonLastName(vbCustomer),
            contactMobilenumber: vbCustomer.phoneNumber,
            contactEmail: vbCustomer.emailAddress,
            participantFirstName: "",
            participantLastName: "",
            participantMobilenumber: "",
            participantEmail: "",
            allergiesChecked: null,
            additionalInfo: "",
            bookTerms: false,
            salesNumber: userSalesNumber,
            leadId: "",
            reseller: "",
            couponCode: "",
            invoiceReference: ""
        }
    }

    const getCustomerContactPersonFirstName = (customer) => {
        var names = customer.contactPerson.split(' ');
        if (names.length > 0)
            return names[0];
        return "";
    }

    const getCustomerContactPersonLastName = (customer) => {
        var names = customer.contactPerson.split(' ');
        if (names.length === 2)
            return names[1];
        return "";
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(bookEducationForm.id, field, value)
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(bookEducationForm.id, navigate(routes.courses(customer.pathId)));
    }

    const onSaveClick = () => {

        const finalisedForm = {
            ...bookEducationForm, data:
            {
                ...bookEducationForm.data, course: education.name,
                price: education.price,
                date: helpers.formatDate(education.date),
                location: education.location,
                courseId: courseId
            }
        };

        customerActions.sendEducationBooking(finalisedForm, navigateAfterAction());
    }

    const copyContactPerson = () => {
        const booking = bookEducationForm.data;
        onFieldChange('participantFirstName', booking.contactFirstName);
        onFieldChange('participantLastName', booking.contactLastName);
        onFieldChange('participantMobilenumber', booking.contactMobilenumber);
        onFieldChange('participantEmail', booking.contactEmail);
    }

    if (!bookEducationForm || !education) {
        return null;
    }

    const booking = bookEducationForm.data;
    const allergiesChecked = booking.allergiesChecked && booking.allergiesChecked;

    return <Panel title={t('leftnavigation.bookeducation')}>
            <div className="form-horizontal">
                <div className="col-sm-12 col-md-8">
                    <StaticTextInput label="Customer number" value={booking.customerNumber} />
                    <ResellerSearch label="Search reseller" onChange={(supplierNumber) => onFieldChange('reseller', supplierNumber)} />
                    <StaticTextInput label="Name" value={booking.name} />
                    <StaticTextInput label="Course" value={education.name} />
                    <StaticTextInput label="Course date" value={helpers.formatDate(education.date)} />
                    <StaticTextInput label="Location" value={education.location} />
                    <StaticTextInput label="Price" value={helpers.currencyFormatter(education.price)} />
                    <TextInput label="Sales number" onChange={onFieldChange.bind(this, 'salesNumber')} value={booking.salesNumber} />
                    <TextInput label="Invoice Ref" onChange={onFieldChange.bind(this, 'invoiceReference')} value={booking.invoiceReference} maxAmountOfCharacters={200} />
                </div>
            </div>
            <div className="form-horizontal">
                <div className="col-sm-12 col-md-8">
                    <h3>Contact Person</h3>
                    <TextInput label="First name" onChange={onFieldChange.bind(this, 'contactFirstName')} value={booking.contactFirstName} maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Last name" onChange={onFieldChange.bind(this, 'contactLastName')} value={booking.contactLastName} maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Mobile number" onChange={onFieldChange.bind(this, 'contactMobilenumber')} value={booking.contactMobilenumber} type="tel" maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Email" onChange={onFieldChange.bind(this, 'contactEmail')} value={booking.contactEmail} type="email" maxAmountOfCharacters={200} hideAdditionalInfo={true} />
                </div>
            </div>
            <div className="form-horizontal">
                <div className="col-sm-12 col-md-8">
                    <h3>Participant</h3>
                    <TextInput label="First name" onChange={onFieldChange.bind(this, 'participantFirstName')} value={booking.participantFirstName} maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Last name" onChange={onFieldChange.bind(this, 'participantLastName')} value={booking.participantLastName} maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Mobile number" onChange={onFieldChange.bind(this, 'participantMobilenumber')} value={booking.participantMobilenumber} type="tel" maxAmountOfCharacters={100} hideAdditionalInfo={true} />
                    <TextInput label="Email" onChange={onFieldChange.bind(this, 'participantEmail')} value={booking.participantEmail} type="email" maxAmountOfCharacters={200} hideAdditionalInfo={true} />
                    <div className="float-right">
                        <button type="button" className="btn btn-default" onClick={copyContactPerson.bind(this)}>Use Contact Person</button>
                    </div>
                    <div className="form-group">
                        <label className="col-4 control-label">Allergies</label>
                        <div className="col-8">
                            <RadioButton label="Yes" onChange={onFieldChange.bind(this, 'allergiesChecked')} checked={allergiesChecked === "yes"} value='yes' />
                            <RadioButton label="No" onChange={onFieldChange.bind(this, 'allergiesChecked')} checked={allergiesChecked === "no"} value='no' />
                        </div>
                    </div>
                    <TextInput label="Additional info" placeholder="Fill in allergies, special diets or disabilities" onChange={onFieldChange.bind(this, 'additionalInfo')} value={booking.additionalInfo || ""} maxAmountOfCharacters={255} multiline={true} />
                    <TextInput label="Coupon Code" onChange={onFieldChange.bind(this, 'couponCode')} value={booking.couponCode} />
                    <div className="form-group">
                        <div className="col-md-4 col-12 control-label"></div>
                        <Checkbox label="Booking terms" onChange={(element) => { onFieldChange('bookTerms', element.checked) }} link='https://vismaspcs.se/utbildning/bokningsvillkor' value={booking.bookTerms} checked={booking.bookTerms}/>
                    </div>
                    <LeadsOrderDropdown onChange={onFieldChange.bind(this, 'leadId')} leadId={booking.leadId} />
                    <div className="form-group padding-top no-left-margin btn-toolbar">
                        <button type="button" className="btn btn-primary" onClick={onSaveClick.bind(this)} disabled={!booking.bookTerms}>Save</button>
                        <button type="button" className="btn btn-default" onClick={onCancelClick.bind(this)}>Cancel</button>
                        <Loader />
                    </div>
                </div>
            </div>
        </Panel>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        userSalesNumber: state.CommonReducer.user && state.CommonReducer.user.salesNumber,
        bookEducationForm: state.CommonReducer.forms && state.CommonReducer.forms.bookEducation,
        education: state.CustomerReducer.selectedEducation
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookEducationContainer);