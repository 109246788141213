import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next'
import Modal from '../../Components/Modal';
import TextInput from '../../Components/TextInput';
import DropDown from '../../Components/DropDown';
import commonActions from '../../Actions/commonActions';
import customerActions from '../../Actions/customerActions';
import StaticTextInput from '../../Components/StaticTextInput';
import moment from 'moment';
import routes from '../../Constants/routes';
import { useNavigate } from 'react-router-dom';

function AOCustomerInformationModal ({vbCustomer, customerActions, commonActions, onClose, form, dropdownlists, t  }) {

    const [supplierHover, setSupplierHover] = useState(false)
    const [assignmentHover, setAssignmentHover] = useState(false)
    const [eEkonomiHover, seteEkonomiHover] = useState(false)
    const navigate = useNavigate()
    
    useEffect(() => {
        commonActions.loadDropdownList("AoSuppliers");
        customerActions.startAOCustomerInfoEdit(vbCustomer.customerNumber);
    }, [])
    
    const handleClickClose = () => {
        commonActions.cancelFormEditing("updateAOCustomerInfoModal", onClose);
    }

    const handleClickSave = () => {
        customerActions.saveAoCustomerInfo(form, () => handleClickClose());
    }

    const handleFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value);
    }

    const handleAdditionalButtonClick = () => {
        customerActions.saveAoCustomerInfo(form, () => handleRedirect());
        handleRedirect();
    }

    const handleRedirect = () => {
        handleClickClose();
        navigate(setRouteVariables(routes.customerNewAppointment(customer.pathId)));
    }

    const setRouteVariables = (route) => {
        return route.replace('${customerNumber}', customer.pathId)
    }

        const customerInfo = form && form.data; 

        if(!customerInfo)
            return null;

        const suppliers = dropdownlists?.AoSuppliers;

        const modalProps = {
            title: t('aocustomerinformation.modalheader'),
            mainButtonTitle: t('aocustomerinformation.save'),
            onCancelClick: handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: handleClickSave,
            additionalButton: true,
            additionalButtonTitle: t('aocustomerinformation.saveandtoappointment'),
            onAdditionalClick: handleAdditionalButtonClick
        }

        let eEkonomiDate;
        if(customerInfo.eekonomiChangeDate)
        eEkonomiDate = moment(customerInfo.eekonomiChangeDate).format("DD-MM-YYYY");

        const iconStyle = 'vismaicon vismaicon-sm vismaicon-filled vismaicon-help cursorpointer';

        const accountingSuppliers = suppliers?.find(s => s.name === 'accounting');
        const payrollSuppliers = suppliers?.find(s => s.name === 'payroll');
        const clientHandlerSuppliers = suppliers?.find(s => s.name === 'clientHandler');
        const financialStatementsSuppliers = suppliers?.find(s => s.name === 'financialStatements');

        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <TextInput 
                        label={<>{t('aocustomerinformation.assignments')} <i onMouseEnter={() => setAssignmentHover(true)} onMouseLeave={() => setAssignmentHover(false)} 
                            className={iconStyle}></i> </>} 
                        onChange={handleFieldChange.bind(this, 'accountingAssignments')} value={customerInfo.accountingAssignments} >
                    </TextInput>
                    {assignmentHover && <div className='table-content-hover'>{t('aocustomerinformation.assignmentedithover')}</div>}
                    <TextInput 
                        label={<>{t('aocustomerinformation.eekonomi')} <i onMouseEnter={() => seteEkonomiHover(true)} onMouseLeave={() => seteEkonomiHover(false)} 
                        className={iconStyle}></i>  </>} 
                        onChange={handleFieldChange.bind(this, 'eekonomi')} value={customerInfo.eekonomi} >
                    </TextInput>
                    {eEkonomiHover && <div className='table-content-hover'>{t('aocustomerinformation.eekonomiedithover')}</div>}
                    {customerInfo.eekonomiChangeDate && <StaticTextInput label={t('aocustomerinformation.eekonomichangedate')} value={eEkonomiDate} />}
                    <br/>
                    <legend>{t('aocustomerinformation.supplier')} <i onMouseEnter={() => setSupplierHover(true)} onMouseLeave={() => setSupplierHover(false)} 
                    className={iconStyle}></i></legend>
                    {supplierHover && <div className='table-content-hover'>{t('aocustomerinformation.supplieredithover')}</div>}
                    <DropDown label={t('aocustomerinformation.accounting')} blankOption={true} nameValued={true} onChange={handleFieldChange.bind(this, 'accounting')} list={accountingSuppliers?.subOptions} value={customerInfo.accounting} />
                    <DropDown label={t('aocustomerinformation.payroll')} blankOption={true} nameValued={true} onChange={handleFieldChange.bind(this, 'payroll')} list={payrollSuppliers?.subOptions} value={customerInfo.payroll} />
                    <DropDown label={t('aocustomerinformation.clienthandler')} blankOption={true} nameValued={true} onChange={handleFieldChange.bind(this, 'clientHandler')} list={clientHandlerSuppliers?.subOptions} value={customerInfo.clientHandler} />
                    <DropDown label={t('aocustomerinformation.financialstatements')} blankOption={true} nameValued={true} onChange={handleFieldChange.bind(this, 'financialStatements')} list={financialStatementsSuppliers?.subOptions} value={customerInfo.financialStatements} />
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.updateAOCustomerInfoModal,
        dropdownlists: state.CommonReducer.dropdownlists
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AOCustomerInformationModal));
