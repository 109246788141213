import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import Modal from '../../Components/Modal'
import EmailTemplate from '../../Components/EmailTemplate'

class VonChangePeriodModal extends Component {

    componentDidMount() {

        const { customerActions, vbCustomer, service } = this.props;

        customerActions.getChangeSaasPeriodTemplate(vbCustomer.customerNumber, service.number);
    }

    handleFieldChange = (field, value) => {
        const { onChange, form } = this.props;
        
        onChange(form.id, field, value);
    }

    handleSaveClick = () => {
        const { customerActions, form, onClose } = this.props;

        customerActions.changeSaasPeriod(form, () => onClose(form.id));
    }

    handleCloseClick = () => {
        const { onClose, form } = this.props;
        
        onClose(form.id);
    }

    render() {

        const { form } = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        const modalProps = {
            title: 'Change period',
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.handleFieldChange,
            onCancelClick: this.handleCloseClick,
            onSaveClick: this.handleSaveClick
        }

        return (
            <Modal {...modalProps}>
                <div>
                    <EmailTemplate data={data} onChange={this.handleFieldChange} />
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.changeSaasPeriod,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VonChangePeriodModal);