import {httpFetch} from "../Utils/httpUtils";
import { bokaMeraActionTypes } from "../ActionTypes/bokaMeraActionTypes";
import commonActions from "./commonActions";
import helpers from "../Utils/helpers";

export const bokaMeraActions = {

    getDates() {
        return (dispatch) => {
            return httpFetch(`/api/bokaMera/GetBokaMeraInvoiceDates/`, dispatch)
                .then(json => {
                    dispatch({type: bokaMeraActionTypes.GET_INVOICE_DATES, data: json});
                })
        }
    },

    getInvoiceDetails(invoiceDate) {
        return (dispatch) => {
            return httpFetch(`/api/bokaMera/GetBokaMeraErrorInvoices/${invoiceDate}`, dispatch)
                .then(json => {
                    dispatch({type: bokaMeraActionTypes.GET_ORDER_ERRORS, data: json});
                })
        }
    },

    runInvoicing(invoiceDate) {
        const httpRequest = {
            method: 'PUT',
        }

        return dispatch => {
            return httpFetch(`/api/bokaMera/RunInvoicing/${helpers.formatDate(invoiceDate)}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Faktureringen har startats på datumet: ${invoiceDate}`));
                })
        }
    },

    addReference(item) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify({
                companyId : item.companyId,
                customerNumber : item.customerNumber,
                invoiceId: item.invoiceId
            })
        }

        return dispatch => {
            return httpFetch(`/api/bokaMera/AddReference`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Reference added`));
                })
        }
    }
}