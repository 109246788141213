import { Dropdown } from "../../../FormComponents/Dropdown";

export function EditSubscriptionPlanForm({ ...props }) {
    const { subscription, subscriptionPackage, planForm, onChange } = props;

    return <div>
        <p>Current plan: {subscription.plan.name} </p>
        <div className="col-3 pl-0">
            <fieldset className="d-flex flex-column gap-16 mb-0">
                <Dropdown onChange={(value) => onChange(planForm.id, value, 'planId')} label={"Change plan"} options={subscriptionPackage ? subscriptionPackage.plans.filter(x => x.id != subscription.plan.id) : []} value={planForm.data.planId}/>
            </fieldset>
        </div>
    </div>
}