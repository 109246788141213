import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PillNavigation = ({user, path, links}) => {
    const {t} = useTranslation();

    return  <ul className="settingsNavigation nav nav-pills nav-pills-primary mb-10">
                { links.map((link, index) => {
                    if (!link.disabled) {
                        return <NavigationPill key={index} path={path} to={link.to} text={link.text} />
                    }
                })
                }
            </ul>
}

const NavigationPill = ({ to, text }) => {
    const location = useLocation();
  
    if (to.startsWith('https')) {
      return <li><a href={to} target='_blank' rel="noopener noreferrer">{text}</a></li>
    } else {
      return <li className={location.pathname === to ? 'active' : ''}><Link to={to}>{text}</Link></li>
    }
  }



export default PillNavigation;