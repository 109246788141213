import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import Loader from '../../Components/Loader'
import {FormEditor} from '../../Components/Editor'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function MySignatureContainer({ leadActions, signatureForm, commonActions, t }) {

    const navigate = useNavigate();

    useEffect(() => {
        leadActions.getMySignature();
    }, [])


    const onSaveClick = () => {
        leadActions.updateMySignature(signatureForm, () => onCancelClick());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(signatureForm.formId, () => navigate(-1));
    }

    const onFieldChange = (value) => {
        commonActions.changeMultiFormField(signatureForm.id, 'signature', value);
    }

    if (!signatureForm) {
        return null;
    }

    return (
        <div className="panel">
            <div className="panel-heading">
                <h3 className="panel-title">{t('mysignaturecontainer.mysignature')}</h3>
            </div>
            <div className="panel-body">
                <form className="form-horizontal col-xs-10">
                    <div className="margin-bottom">
                        <FormEditor label={t('mysignaturecontainer.signature')} onChange={value => onFieldChange(value)} value={signatureForm.data.signature} />
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                        <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>{t('mysignaturecontainer.save')}</button>
                        <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('mysignaturecontainer.cancel')}</button>
                        <Loader />
                    </div>
                </form>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        signatureForm: state.CommonReducer.forms && state.CommonReducer.forms.leadsMySignature
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MySignatureContainer));