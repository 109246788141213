import React from 'react';
import { useTranslation } from 'react-i18next';

const CompensationHistory = ({customerCompensations}) => {
    const {t} = useTranslation();
    if(!customerCompensations)
        return null;

    return  <fieldset className="col-xs-12">
                <legend>{t('compensationhistory.compensationhistory')}</legend>
                <div className="table-container">
                <table className="table table-stripe">
                    <thead>
                        <tr>
                            <th width="150" className="text-left">{t('compensationhistory.program')}</th>
                            <th width="10" className="text-left">{t('compensationhistory.months')}</th>
                            <th width="100" className="text-left">{t('compensationhistory.reason')}</th>
                            <th width="70" className="text-left">{t('compensationhistory.sum')}</th>
                            <th width="150" className="text-left">{t('compensationhistory.salesforceticketlink')}</th>
                            <th width="150" className="text-left">{t('compensationhistory.createdby')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerCompensations.map((item, index) => {
                                return <tr key={index}>
                                    <td className="text-left">{item.productName}</td>
                                    <td className="text-left">{item.months}</td>
                                    <td className="text-left">{item.compensationReason}</td>
                                    <td className="text-left">{item.sum}</td>
                                    <td className="text-left"><a href={item.salesforceTicketLink} target='_blank' rel="noopener noreferrer">{item.salesforceTicketLink}</a></td>
                                    <td className="text-left">{item.email}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                </div>
            </fieldset>
}

export default CompensationHistory;