import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commonActions from '../../Actions/commonActions'
import helper from '../../Utils/helpers'
import TextInput from '../TextInput';
import DropDown from '../DropDown';
import StaticTextInput from '../StaticTextInput';

class BlikkTransactions extends Component {

    onTransactionChange(value, id) {
        
        const transactionTypes = this.props.product.transactionTypes;

        var type = transactionTypes.find(type => type.name === id);

        if(type != null){
            
            type.transactions.forEach(t => {
                t.count = 0;
            })

            const transaction = type.transactions.find(transaction => transaction.name === value);
            if(transaction != null){                
                transaction.count = 1;
            } 
        }
                
        this.props.onChange(transactionTypes);

        this.props.onSave();
    }

    getSelectedValue(item){
        var transaction = item.transactions.find(transaction => transaction.count === 1);
        if(transaction != null)
            return transaction.name;
        return "";
    }

    getPrice(item){
        const transactionTypes = this.props.product.transactionTypes;
        var price = 0;

        const type = transactionTypes.find(type => type.name === item.name);

        if(type != null){
            var transaction = type.transactions.find(transaction => transaction.count === 1);
        
            if(transaction != null)
                price = transaction.initialPrice;
        }       

        return helper.currencyFormatter(price);

    }


    render() {
        const {product} = this.props;

        if(!product)
            return null

        const transactionTypes = product.transactionTypes; 

        return (
            
            <>
            {transactionTypes.map(item => {  

                return <div key={item.id} className="form-group">
                        <label className="col-xs-4 control-label">{item.name} (credit: {item.credit})</label>
                        <div className="col-xs-4 control-label">
                             <DropDown list={item.transactions} nameValued={true} blankOption={true} onChange={this.onTransactionChange.bind(this)} id={item.name} value={this.getSelectedValue(item)}/> 
                        </div>
                        <label className="col-xs-4 control-label">{this.getPrice(item)}</label>
                    </div>
            })}
        </>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(BlikkTransactions);