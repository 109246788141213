import { Component } from 'react';

import commonActions from '../Actions/commonActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

class WebNotification extends Component {
    render() {

        if (!("Notification" in window)) {
            return null;
        }

        const message = this.props.notification.message;

        if (!message || this.props.notification.isShown) {
            return null;
        }

        const options = {
            body: message.body,
            icon: '/favicon.ico'
        }

        const notification = new Notification(message.title, options);

        notification.onclick = () => {
            window.location.href = `/my-appointments/appointment/${message.id}`;
            notification.close();
        }

        Notification.requestPermission();
        commonActions.notifyUser(message, true)

        return null;
    }
}

function mapStateToProps(state) {
    return {
        notification: state.CommonReducer.webNotification
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebNotification);