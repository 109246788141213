import { httpFetch } from '../Utils/httpUtils'
import { fileBoxActionTypes as actionTypes } from '../ActionTypes/fileBoxActionTypes'
import commonActions from "./commonActions"
import helpers from '../Utils/helpers'

const fileBoxActions = {

    getCases() {
        return (dispatch) => {
            return httpFetch(`/api/fileBox/getCases/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_FILEBOX_CASES, data: json });
                })
        }
    },

    getTeams() {
        return (dispatch) => {
            return httpFetch(`/api/fileBox/getTeams/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_FILEBOX_TEAMS, data: json });
                })
        }
    },

    createTicket(ticket) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(ticket);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(ticket.data)
            }

            return httpFetch(`/api/fileBox/createCase/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('FileBox ticket has been sent'));

                    callback();
            })
        }
    },

    getCase(caseId) {
        return dispatch => {
            return httpFetch(`/api/fileBox/getCase/${caseId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_FILEBOX_CASE, data: json });
                })
        }
    }
}

export default fileBoxActions