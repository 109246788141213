import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import TextInput from '../TextInput'
import {FormEditor} from '../Editor'

import Modal from '../Modal'
import EmailTemplate from '../EmailTemplate'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'


class SendTemplateApprovalEmailModal extends Component {

    componentDidMount() {
        this.props.customerActions.getTemplateApprovalTemplate(this.props.templateId)
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("templateApprovalForm", field, value);

    }

    handleClickSave = () => {
        const {templateApprovalForm, customerActions} = this.props;

        customerActions.sendAdminForm(templateApprovalForm, this.handleClickClose)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;
        commonActions.cancelFormEditing("templateApprovalForm", onClose)
    }

    render() {
        const { templateApprovalForm, t } = this.props;
        
        if (!templateApprovalForm) {
            return null;
        }

        var data = templateApprovalForm.data
        const modalProps = {
            title: t('sendtemplateapprovalemailmodal.sendtemplateapprovalrequest'),
            mainButtonTitle: t('sendtemplateapprovalemailmodal.send'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <form className="form-horizontal">
                <fieldset className="margin-top">
                    <legend>{t('sendtemplateapprovalemailmodal.emaildetails')}</legend>
                    <TextInput label={t('sendtemplateapprovalemailmodal.to')} value={data.to} onChange={this.handleFieldChange.bind(this, 'to')} disabled={true} />
                    <TextInput label={t('sendtemplateapprovalemailmodal.from')} value={data.from} onChange={this.handleFieldChange.bind(this, 'from')} disabled={true} />
                    <TextInput label={t('sendtemplateapprovalemailmodal.subject')} value={data.subject} onChange={this.handleFieldChange.bind(this, 'subject')} disabled={true} />
                    <FormEditor label={t('sendtemplateapprovalemailmodal.body')} toolbar={false} value={data.body} onChange={this.handleFieldChange.bind(this, 'body')} />
                </fieldset>
            </form>
            </Modal>
           
        );
    }
}

function mapStateToProps(state) {
    return {
        templateApprovalForm: state.CommonReducer.forms && state.CommonReducer.forms.templateApprovalForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendTemplateApprovalEmailModal));