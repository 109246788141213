import React, { Component } from 'react';
import './Alert.css'

export default class AlertSmall extends Component {
    
    render() {
        const alert = this.props.alert;

        if(!alert) {
            return null;
        }

        // TODO do it as VUD-style
        return (
            <div className={'alert mb-0 alert-' + alert.type + ' alert-sm'} role="alert">
                <div>
                    <span className={'vismaicon vismaicon vismaicon-filled vismaicon-' + alert.type } aria-hidden="true"></span>
                    <strong>{alert.title}</strong> <span>{alert.message}</span>
                </div>
            </div>
        );
    }
}