import { subscriptionActionTypes as actionTypes } from "../../ActionTypes/Voss/subscriptionActionTypes";
import { initialState } from "../CommonReducer";

export default function SubscriptionReducer(state = { initialState }, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_SUBSCRIPTION:
            newState = { subscription: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}