import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'
import AoLeadForm from '../../Components/Leads/AoLeadForm'
import { useNavigate, useParams } from 'react-router-dom';

function InternalLeadEditContainer({ form, user, salesPersons, leadActions, commonActions }) {

    const { leadId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        leadActions.getInternalLead(leadId, false, "internalleadEdit");
        leadActions.getInternalEmails();
    }, [])

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField("internalleadEdit", field, value);
    }

    const onSaveClick = () => {
        leadActions.saveInternal(form, navigateToDetails);
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing("internalleadEdit", navigateToDetails);
    }

    const navigateToDetails = () => {
        navigate(-1);
    }

    if (!form) {
        return null;
    }

    return (
        <div>
            <div className="col-xs-8">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">Edit Lead</h3>
                    </div>
                    <div className="panel-body">
                        <AoLeadForm lead={form.data} user={user} onFieldChange={onFieldChange} onCancelClick={onCancelClick} onSaveClick={onSaveClick} salesPersons={salesPersons} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.internalleadEdit,
        user: state.CommonReducer.user,
        salesPersons: state.LeadsReducer.internalEmails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalLeadEditContainer);