import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import Modal from '../Modal'
import EmailTemplate from '../EmailTemplate'

import commonActions from '../../Actions/commonActions'

import DropDown from '../../Components/DropDown'
import customerActions from '../../Actions/customerActions';

class SendDesktopVersionModal extends Component {


    componentDidMount() {
        const {commonActions, selectedCustomerAndType, dropdownlists} = this.props; //120
        if(dropdownlists){
            commonActions.getSendDesktopVersionsEmailTemplate(selectedCustomerAndType && selectedCustomerAndType.customerNumber, dropdownlists && dropdownlists.DesktopVersion[0].id);
        }
    }

    onFieldChange = (value) => {
        const {commonActions, selectedCustomerAndType} = this.props;
        commonActions.getSendDesktopVersionsEmailTemplate(selectedCustomerAndType && selectedCustomerAndType.customerNumber, value);
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("sendDesktopVersionEmail", field, value);
    }

    handleClickSave = () => {
        const {form, customerActions } = this.props;

        customerActions.sendDesktopVersionEmail(form, this.handleClickClose)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("sendDesktopVersionEmail", onClose)
    }

    getReceiverEmails()
    {
        const {emailForm} = this.props;
        return emailForm && emailForm.data && emailForm.data.defaultToAddresses;
    }

    render() {
        const { form, dropdownlists, desktopVersion, t } = this.props;
        const desktopVersions = dropdownlists && dropdownlists.DesktopVersion;
        const data = form && form.data;
        var productId = desktopVersion;    

        /* if (!form) {
            return null;
        } */

        if(!desktopVersion)
            productId = 1

        const modalProps = {
            title: t('senddesktopversionemailmodal.sendversion'),
            mainButtonTitle:  t('senddesktopversionemailmodal.mainbuttontitle'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }
        
        
        return (
            <Modal {...modalProps}>
                <form className="form-horizontal">
                    <DropDown label={t('senddemoemailmodal.product')} onChange={this.onFieldChange.bind(this)} list={desktopVersions} value={productId}/>
                   
                    <EmailTemplate data={form?.data} templates={null} onChange={this.handleFieldChange}
                        onSaveClick={this.handleClickSave} onCancelClick={this.handleClickClose} onTemplateChange={this.onTemplateChange}
                        senderEmails={form?.data.defaultSenderAddresses} receiverEmails={this.getReceiverEmails()} onSenderChange={this.handleFieldChange} sendDemo={true}/>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCustomerAndType: state.CommonReducer.selectedCustomerAndType,
        form: state.CommonReducer.forms && state.CommonReducer.forms.sendDesktopVersionEmail,
        dropdownlists: state.CommonReducer.dropdownlists,
        desktopVersion: state.CommonReducer.selectedDesktopVersionId,
        sendVersionsList: state.ProductOverviewReducer.sendVersionsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendDesktopVersionModal));
