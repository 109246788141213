import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import commonActions from '../Actions/commonActions';
import EditVersionDropdown from '../Components/EditVersionDropdown';
import EditProductOwnerForm from '../Components/EditProductOwnerForm';
import AddVersionForm from '../Components/AddVersionForm';
import ProductVersionList from '../Components/ProductVersionList';
import settingsActions from '../Actions/settingsActions';

function EditProductVersionContainer({productOverviewActions, productId, product, productList, productForm, commonActions, settingsActions, t}) {
    const [newProductState, setNewProductState] = useState(false);


    useEffect(() => {
        settingsActions.getUsers();
        commonActions.cancelFormEditing(productForm?.id);
    }, [])

    useEffect(() => {
        if(productId){
          productOverviewActions.getProductById()
        }
    }, [productId, productList]);

    useEffect(() => {
        productOverviewActions.resetVersionState()
    }, []);

    useEffect(() => {
        return () => productOverviewActions.resetVersionState()
    }, []);

  return (
    <>
        <div className='col-xs-12 col-lg-11 center-block'>
            <div className='editVersionContainer panel panel-default'>
                <div className='panel-heading'>
                    <h3>{t("productOverview.editProductVersion")}</h3>
                </div>
                <div className='panelContainer panel-body'>
                    <div className='editVersionNoPadding POforms-container col-xs-12 col-md-6-5'>
                        <div className='dropdownContainer col-xs-12 col-md-6'>
                            <EditVersionDropdown newProductState={newProductState}/>
                        </div>
                        
                        <div>
                            <div className='editVersionNoPadding col-xs-12 col-md-6'>
                                <EditProductOwnerForm product={product} newProductState={newProductState} setNewProductState={setNewProductState}/>
                            </div>
                            <div className='editVersionNoPadding col-xs-12 col-md-6'>
                                {!newProductState && product ? <AddVersionForm productId={productId}/> : null}
                            </div>
                        </div>
                    </div>
                    {!newProductState && product ? 
                    <div className='col-xs-12'>
                        <h3>{t("productOverview.productVersions")}</h3>
                        <ProductVersionList editable={true}/>
                    </div>
                    : null}
                </div>
            </div>    
        </div>
    </>
  )
}
  
function mapStateToProps(state) {
    return {
      product: state.ProductOverviewReducer.singleProduct,
      productId: state.ProductOverviewReducer.selectedProductId,
      productList: state.ProductOverviewReducer.productList,
      productForm: state.CommonReducer.forms && state.CommonReducer.forms.productForm,
      productVersionForm: state.CommonReducer.forms && state.CommonReducer.forms.productVersionForm,
      users: state.SettingsReducer.users && state.SettingsReducer.users
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
      commonActions: bindActionCreators(commonActions, dispatch),
      settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditProductVersionContainer));