import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import routes from '../../Constants/routes'
import Loader from '../../Components/Loader'
import StaticText from '../../Components/StaticTextInput'
import TextInput from '../../Components/TextInput'
import {FormEditor} from '../../Components/Editor'
import DropDown from '../../Components/DropDown'
import { useParams, useNavigate } from 'react-router-dom';
import OrderConfirmationList from '../../Components/Settings/OrderConfirmationList'
import UserSearch from '../../Components/UserSearch';
import moment from 'moment';

function EmailTemplateEditContainer({ settingsActions, emailTemplateForm, commonActions, t, emailTemplateApproval, serviceAddresses, user }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getEmailTemplate(id);
        settingsActions.getServiceAddresses();
    }, [])

    const onSaveClick = () => {
        if (emailTemplateForm.data.responsible === "" || emailTemplateForm.data.responsible === undefined) {
            commonActions.showError(t('emailtemplatescontainer.responsiblewarning'));
        } else {
            settingsActions.updateEmailTemplate(emailTemplateForm, () => navigate(routes.settingsEmailTemplates));
        }
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(emailTemplateForm.id, () => navigate(routes.settingsEmailTemplates))
    }

    const approveTemplate = () => {
        commonActions.changeMultiFormField(emailTemplateForm.id, 'approved', true)
        settingsActions.approveEmailTemplate(emailTemplateForm.data.id);
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(emailTemplateForm.id, field, value)
    }

    const onFromChange = (field, value) => {
        if (!emailTemplateForm.data.from.includes(value)) {
            let newValue = value;
            if (emailTemplateForm.data.from !== "")
                newValue += ";" + emailTemplateForm.data.from
            commonActions.changeMultiFormField(emailTemplateForm.id, "from", newValue)
        }
    }

    const renderFromAddresses = (from) => {
        if (from.length > 1) {
            var fromAddresses = from.split(/(?<=;)/)
            return (
                <div className="address-button-container">
                    {fromAddresses && fromAddresses.length > 0 && fromAddresses.map((fromAddress, index) => {
                        return <button className="btn btn-default false address-button" type="button">{fromAddress.replace(";", "")}<span onClick={() => onRemoveAddress(fromAddress)} className="address-remove">&times;</span></button>
                    })}
                </div>
            )
        }
    }

    const isUserResponsible = () => {
        const userEmail = user.email ? user.email.toLowerCase() : '';
        const responsibleEmail = emailTemplate.responsible
          ? emailTemplate.responsible.toLowerCase()
          : '';
    
        return userEmail === responsibleEmail;
      };

    const onRemoveAddress = (address) => {
        commonActions.changeMultiFormField(emailTemplateForm.id, "from", emailTemplateForm.data.from.replace(address, ""))
    }

    if (!emailTemplateForm || !emailTemplateForm.data) {
        return null;
    }

    const emailTemplate = emailTemplateForm.data;


    return (
        <div>
            <form className="form-horizontal col-xs-10">
                <fieldset>
                    <StaticText label={t('emailtemplateeditcontainer.name')} value={emailTemplate.name} />
                    <StaticText label={t('emailtemplateeditcontainer.type')} value={emailTemplate.type} />
                    {emailTemplate.variables && <StaticText label={t('emailtemplateeditcontainer.availablevariables')} value={emailTemplate.variables} />}
                    <UserSearch label={t('emailtemplateeditcontainer.responsible')} onChange={(value) => onFieldChange("responsible", value)} defaultSelected={emailTemplate.responsibleName} />
                    <DropDown label={t('emailtemplateeditcontainer.from')} blankOption={true} onChange={onFromChange.bind(this, "from")} list={serviceAddresses} />
                    {renderFromAddresses(emailTemplate.from)}
                    <TextInput label={t('emailtemplateeditcontainer.subject')} value={emailTemplate.subject} onChange={(value) => onFieldChange("subject", value)} />
                    <FormEditor label={t('emailtemplateeditcontainer.text')} value={emailTemplate.body} onChange={(value) => onFieldChange("body", value)} />
                </fieldset>

                {emailTemplate.orderConfirmationAdditions && <OrderConfirmationList data={emailTemplate.orderConfirmationAdditions} templateId={emailTemplate.id} />}
                {isUserResponsible() && emailTemplateApproval && <div>
                    <button type="button" className={`btn " ${emailTemplateApproval.approved ? "btn-primary " : "btn-default"}`} onClick={() => approveTemplate()}>{t('emailtemplateeditcontainer.approved')}</button>
                    {emailTemplateApproval.approvalDate && <StaticText col2Width={"0"} value={t('emailtemplateeditcontainer.approvaldate') + moment(emailTemplateApproval.approvalDate).format(moment.HTML5_FMT.DATE)} />}
                </div>}

                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t('emailtemplateeditcontainer.save')}</button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>{t('emailtemplateeditcontainer.cancel')}</button>
                    <Loader />
                </div>
            </form>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        emailTemplateForm: state.CommonReducer.forms && state.CommonReducer.forms.editEmailTemplate,
        emailTemplateApproval: state.SettingsReducer.emailTemplateApproval,
        serviceAddresses: state.SettingsReducer.serviceAddresses
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailTemplateEditContainer));