import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import LeadForm from '../../Components/Leads/LeadForm'
import { useNavigate, useParams } from 'react-router-dom';

function LeadEditContainer({ commonActions, leadActions, form, t }) {

    const { leadId } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        leadActions.getLead(leadId, false, true, "leadEdit");
    }, [])

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField("leadEdit", field, value);
    }

    const onSaveClick = () => {
        leadActions.save(form, navigateToDetails);
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing("leadEdit", navigateToDetails);
    }

    const navigateToDetails = () => {
        navigate(routes.leadDetails(leadId))
    }

    if (!form) {
        return null;
    }

    return (
        <div>
            <div className="col-xs-8">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">{t('leadeditcontainer.editlead')}</h3>
                    </div>
                    <div className="panel-body">
                        <LeadForm lead={form.data} onFieldChange={onFieldChange} onCancelClick={onCancelClick} onSaveClick={onSaveClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.leadEdit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadEditContainer));