import InyettDetails from "../../Components/Customer/CustomerComponents/InyettDetails";
import {DefaultCard} from "../../Components/Card/Card";
import React from "react";

function InyettContainer({}) {
  return (
      <DefaultCard title={"Company data"}><InyettDetails/></DefaultCard>
  );
}

export default InyettContainer;