import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import CampaignsList from '../../Components/Customer/CampaignsList'
import Loader from '../../Components/Loader'
import Panel from "../../Components/Panel";
import { useTranslation } from "react-i18next";

function CampaignContainer({customerActions, vbCustomer, customerCampaigns}) {
    
    useEffect(() => {
        customerActions.getCampaignsForCustomer(vbCustomer.customerNumber);
        
        return () => {
            // componentWillUnmount
            customerActions.clearCampaignsForCustomer();
        }
        
    }, [])
    
    const { t } = useTranslation();

    const onSendClick = (emailId, contactId, email) => {
        if (window.confirm('Are you sure you wish to send this email to '+ email+'?')) {
            customerActions.sendCampaignEmail(emailId, contactId, vbCustomer.customerNumber);
        }        
    }
    
    return <Panel title={t('leftnavigation.campaigns')}> 
            <CampaignsList customerCampaigns={customerCampaigns} onSendClick={onSendClick}/> 
                <Loader />
    </Panel>
}

function mapStateToProps(state) {
    return {
        customerCampaigns: state.CustomerReducer.customerCampaigns,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignContainer);

