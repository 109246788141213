import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../Actions/commonActions'

const withModalForm = (WrappedComponent) => {
    class HOC extends Component {

        state = {
            modal: null,
            payload: null
        }

        isModalActive = (id) => {
            return this.state.modal === id;
        }

        onOpenModal = (modalId, modalPayload) => {
            this.setState({modal: modalId, payload: modalPayload });
        }
    
        onFieldChange = (formId, field, value) => {
            const { commonActions } = this.props;

            commonActions.changeMultiFormField(formId, field, value);
        }

        onCloseClick = (formId) => {
            const { commonActions } = this.props;
    
            this.setState({ modal:null, payload: null});
    
            commonActions.cancelFormEditing(formId);
        }

        render() {
            const args = {
                isModalActive: this.isModalActive,
                onOpenModal: this.onOpenModal,
                onFieldChange: this.onFieldChange,
                onCloseClick: this.onCloseClick,
                payload: this.state.payload,
                match: this.props.match,
                history: this.props.history
            }

            return <WrappedComponent {...args} />
        }
    }

    function mapDispatchToProps(dispatch) {        
        return {
            commonActions: bindActionCreators(commonActions, dispatch)
        }
    }

    return connect(null, mapDispatchToProps)(HOC);

}

export default withModalForm;