import moment from "moment";
//general functions for graphs

export const getReferenceValue = (arr, timePeriod) => {
  let referenceValue;

  if (timePeriod === "month" || timePeriod === "week") {
    referenceValue = arr.find(
      (obj) =>
        obj.year === moment().format("yyyy") &&
        obj.month === moment().format("MMMM")
    );
  }
  if (timePeriod === "lastMonth") {
    referenceValue = arr.find(
      (obj) =>
        obj.year === moment().format("yyyy") &&
        obj.month === moment().subtract(1, "month").format("MMMM")
    );
  }
  if (!referenceValue.amount) {
    return;
  }
  return referenceValue.amount;
};

export const handleDataFromTime = (data, dateFrom, dateTo, dateKey) => {
  let timespan = data.filter(
    (obj) => moment(obj[dateKey]) >= dateFrom && moment(obj[dateKey]) <= dateTo
  );

  return timespan;
};

export const handleClick = (e, data, dateKey) => {
  let timeFrom;
  let timeTo;

  switch (e.target.value) {
    case "week":
      timeFrom = moment().startOf("isoWeek");
      timeTo = moment().startOf("isoWeek").add(4, "days");
      return handleDataFromTime(data, timeFrom, timeTo, dateKey);

    case "month":
      timeFrom = moment().startOf("month");
      timeTo = moment().endOf("month");
      return handleDataFromTime(data, timeFrom, timeTo, dateKey);

    case "lastMonth":
      timeFrom = moment().subtract(1, "months").startOf("month");
      timeTo = moment().subtract(1, "months").endOf("month");
      return handleDataFromTime(data, timeFrom, timeTo, dateKey);
  }
};

export const getTimePeriod = (timePeriod) => {
  const timeperiodArray = [];
  let startDate;
  let endDate;

  switch (timePeriod) {
    case "week":
      startDate = moment().startOf("isoWeek");
      endDate = moment().startOf("isoWeek").add(4, "days");
      while (startDate <= endDate) {
        timeperiodArray.push(startDate.format("MMM DD"));
        startDate = moment(startDate).add(1, "days");
      }
      break;

    case "month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      while (startDate <= endDate) {
        timeperiodArray.push(startDate.format("MMM DD"));
        startDate = moment(startDate).add(1, "days");
      }
      break;

    case "lastMonth":
      startDate = moment().subtract(1, "months").startOf("month");
      endDate = moment().subtract(1, "months").endOf("month");
      while (startDate <= endDate) {
        timeperiodArray.push(startDate.format("MMM DD"));
        startDate = moment(startDate).add(1, "days");
      }
      break;
  }

  return timeperiodArray;
};

export const matchDates = (baseArr, arr, dateKey, key) => {
  let returnArray = [];
  let value;

  baseArr.forEach((item) => {
    if (
      arr.some((obj) => {
        value = obj[key];
        return moment(obj[dateKey]).format("MMM DD") === item;
      })
    ) {
      returnArray.push({
        date: item,
        value: value,
      });
    } else {
      returnArray.push({
        date: item,
        value: parseInt("0"),
      });
    }
  });

  return returnArray;
};

const sortDates = (arr, dateKey) => {
  return arr.sort((a, b) => moment(a[dateKey]) - moment(b[dateKey]));
};

export const addOccurrencesOverTime = (arr, dateKey, key) => {
  let returnArray = [];
  let value = 0;

  sortDates(arr, dateKey).forEach((obj) => {
    returnArray.push({
      date: obj[dateKey],
      value: (value += obj[key]),
    });
  });

  return returnArray;
};

export const countOccurrence = (arr, key) => {
  let returnArray = [];

  arr.forEach((obj) => {
    if (returnArray.some((val) => val[key] === obj[key])) {
      returnArray.forEach((occ) => {
        if (occ[key] === obj[key]) {
          occ["occurrence"]++;
        }
      });
    } else {
      let newObj = {};

      newObj[key] = obj[key];
      newObj["occurrence"] = 1;
      returnArray.push(newObj);
    }
  });

  return returnArray;
};

export const countOccurrenceOnDate = (arr, key) => {
  let returnArray = [];

  arr.forEach((obj) => {
    if (
      returnArray.some(
        (val) => moment(val[key]).format("DD") === moment(obj[key]).format("DD")
      )
    ) {
      returnArray.forEach((occ) => {
        if (occ[key] === obj[key]) {
          occ["occurrence"]++;
        }
      });
    } else {
      let newObj = {};

      newObj[key] = obj[key];
      newObj["occurrence"] = 1;
      returnArray.push(newObj);
    }
  });

  return returnArray;
};
