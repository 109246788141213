import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonDropdown from '../../Components/ButtonDropdown';
import TextInput from '../../Components/TextInput';
import routes from '../../Constants/routes';
import StaticTextInput from '../../Components/StaticTextInput';
import NavTabs from '../../Components/NavTabs';
import FileBoxTicketActionHistoryContainer from './FileBoxTicketActionHistoryContainer';
import FileBoxTicketInstructionsContainer from './FileBoxTicketInstructionsContainer';
import FileBoxOverviewContainer from './FileBoxOverviewContainer';
import helpers from '../../Utils/helpers';
import { caseStatuses, eventStatuses } from '../../Constants/fileBoxStatuses';

function FileBoxTicketDetailsContainer({ fileBoxActions, t, fileBoxTeams, fileBoxCase }) {

    const { ticketId } = useParams();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false)
    const [isCollapseShown, setCollapseShown] = useState(false)

    const tabs = [
        {
            name: 'overview',
            label: 'Overview',
            content: <FileBoxOverviewContainer />
        },
        {
            name: 'instructions',
            label: 'Instructions',
            content: <FileBoxTicketInstructionsContainer />
        },
        {
            name: 'action-log',
            label: 'Action Log',
            content: <FileBoxTicketActionHistoryContainer />
        },
    ];

    let options = [
        { title: 'appointmentdetailactions.busy', identifier: "busy" },
        { title: 'leaddetailscontainer.noanswer', identifier: "noAnswer" },
        { title: 'leaddetailscontainer.sendemail', identifier: "sendEmail" },
        { title: 'leaddetailscontainer.createappointment', identifier: "appointment" },
        { title: 'leaddetailscontainer.closelead', identifier: "closeLead" }
    ];
    useEffect(() => {
        fileBoxActions.getCase(ticketId);
        fileBoxActions.getTeams();
    }, [])

    const handleCopyClipboardClick = () => {
        setCollapseShown(true)
        setTimeout(() => {
            setCollapseShown(false)
        }, 3000);
    }

    return (
        <div className={`col-xs-12`}>
            {fileBoxCase && <div className="panel panel-default">
                <div className={`filebox-ticket-details-header-container ${eventStatuses[fileBoxCase.eventStatus].className}`}>
                    <h2 className="heading-primary">
                        Ticket # {fileBoxCase.ticketId}
                        <span className='float-right'>{eventStatuses[fileBoxCase.eventStatus].status}</span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className={`filebox-collapse ${isCollapseShown ? 'show' : ''}`}>
                            <div className="alert alert-success alert-sm" role="alert">
                                <div><span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-success" aria-hidden="true"></span>Successfully copied link clipboard</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    <div>
                        <form className="row form-horizontal justify-content-between">
                            <fieldset className="col-xs-6">
                                <legend>{t('fileboxticketdetailscontainer.maininfo')}
                                    <a id="EditCaseInfo"><span className="vismaicon vismaicon-edit float-right" aria-hidden="true" onClick={() => setIsEditing(!isEditing)}></span></a></legend>
                                {!isEditing ? <><StaticTextInput col1Width={"4"} label={"Customer number"} value={fileBoxCase.customerNumber} />
                                <StaticTextInput col1Width={"4"} label={"Customer name"} value={fileBoxCase.customerName} />
                                <StaticTextInput col1Width={"4"} label={"Created"} value={helpers.formatDateAndTime(fileBoxCase.created)} />
                                <StaticTextInput col1Width={"4"} label={"Last modified"} value={helpers.formatDateAndTime(fileBoxCase.modified)} />
                                <StaticTextInput col1Width={"4"} label={"Email address"} value={fileBoxCase.email} />
                                <StaticTextInput col1Width={"4"} label={"Phone number"} value={fileBoxCase.phone} />
                                <StaticTextInput col1Width={"4"} label={"Owner"} value={fileBoxTeams ? fileBoxTeams.find(x => x.id === fileBoxCase.teamId).name : fileBoxCase.teamId} />
                                </> :<>
                                <TextInput col1Width={"4"} label={"Customer number"} value={fileBoxCase.customerNumber} />
                                <TextInput col1Width={"4"} label={"Customer name"} value={fileBoxCase.customerName} />
                                <StaticTextInput col1Width={"4"} label={"Created"} value={helpers.formatDateAndTime(fileBoxCase.created)} />
                                <StaticTextInput col1Width={"4"} label={"Last modified"} value={helpers.formatDateAndTime(fileBoxCase.modified)} />
                                <TextInput col1Width={"4"} label={"Email address"} value={fileBoxCase.email} />
                                <TextInput col1Width={"4"} label={"Phone number"} value={fileBoxCase.phone} />
                                <TextInput col1Width={"4"} label={"Owner"} value={fileBoxTeams ? fileBoxTeams.find(x => x.id === fileBoxCase.teamId).name : fileBoxCase.teamId} />
                                </>}
                                <StaticTextInput col1Width={"4"} label={"Assigned to person"} value={fileBoxCase.assignedTo ? fileBoxCase.assignedTo : "Assign to me"} />
                            </fieldset>
                            <fieldset className="col-xs-4">
                                <legend>{t('fileboxticketdetailscontainer.actions')}</legend>
                                <div className='filebox-ticket-details-button-container'>
                                    <ul className='filebox-ticket-details-button-list'>
                                        <li><ButtonDropdown onSplitClick={() => navigate(routes.searchMain)} options={options} /></li>
                                        <li><button type='button' className='btn btn-default no-margin'>View cust page</button></li>
                                        <li><button type='button' onClick={() => handleCopyClipboardClick()} className='btn btn-default no-margin'>Copy link</button></li>
                                        <li><button type='button' className='btn btn-default no-margin'>Close ticket</button></li>
                                    </ul>
                                </div>
                            </fieldset>
                            <NavTabs tabs={tabs} />
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        fileBoxTeams: state.FileBoxReducer.fileBoxTeams,
        fileBoxCase: state.FileBoxReducer.fileBoxCase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxTicketDetailsContainer));