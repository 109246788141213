import React from 'react';
import { Link } from 'react-router-dom'
import BadgeCounter from "../BadgeCounter";

const NavigationItem = ({ location, link, classList }) => {

    const path = link.path;
    const title = link.title;
    const badgeNumber = link.badgeNumber
    const activate = link.activate === undefined ? true : link.activate;

    const isActive = (path) => {
        return (location.pathname === path || (path !== '/' && location.pathname.indexOf(path) > -1)) && activate;
    }

    return (
        <li className={classList}>
            <Link className="nav-item fl-menu-item" to={path} onClick={link.onClick}>{title} <BadgeCounter badgeNumber={badgeNumber} /></Link>
        </li>
    );
}

export default NavigationItem;