import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import helpers from '../../Utils/helpers';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import orderActions from '../../Actions/orderActions';
import { useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'

function OrderApprovalRequestsContainer({ orderActions, commonActions, form, approvalRequests, t }) {

    const navigate = useNavigate();

    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        orderActions.getOrderApprovalRequests();
    }, [])

    const onClick = (approvalRequest) => {
        navigate(routes.orderApprovalRequestsEdit(approvalRequest.id));
    }

    const isFilterActive = (filter) => {
        return filters.some(item => filter === item);
    }

    const handleFilterSet = (filter) => {
        var array = [...filters]; // make a separate copy of the array
        var index = array.indexOf(filter)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(filter);
        }
        setFilters(array);

    }

    const filterRequestsBySearchTerm = (approvalRequest) => {
        var lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (approvalRequest.customerNumber.includes(lowerCaseSearchTerm) || approvalRequest.orderLines.some(x => x.productName.toLowerCase().includes(lowerCaseSearchTerm) || approvalRequest.salesPersonEmail.toLowerCase().includes(lowerCaseSearchTerm)))
    }

    const filterRequestByProductType = (approvalRequest) => {
        
        if (filters.length < 1)
            return true

        if (approvalRequest.vonOrderLines.length > 0 && filters.indexOf("VON") > -1)
            return true;

        if (approvalRequest.vbOrderLines.length > 0 && filters.indexOf("VB") > -1)
            return true;

        if (approvalRequest.blikkOrderLines.length > 0 && filters.indexOf("Blikk") > -1)
            return true;

        return false;
    }

    const getProducts = (approvalRequest) => {

        var products = [];
        approvalRequest.orderLines.forEach(orderline => {
            products.push(orderline.productName)
        });

        return products.join(', ');
    }

    if (!approvalRequests) {
        return null;
    }

    var productFilters = ['VON', 'VB', 'Blikk']

    return (
        <div>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="col-xs-12">
                        <div className="filters">
                            {productFilters.map((filter, index) => {
                                return <button key={index} type="button" className={`btn btn-default ${isFilterActive(filter) && "active"}`} onClick={() => handleFilterSet(filter)}>{filter}</button>
                            })}
                        </div>
                        <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={t('orderapprovalrequestscontainer.placeholder')}></input>
                    </div>
                </div>
            </div>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th>{t('orderapprovalrequestscontainer.customernumber')}</th>
                        <th>{t('orderapprovalrequestscontainer.salespersonemail')}</th>
                        <th>{t('orderapprovalrequestscontainer.products')}</th>
                        <th>{t('orderapprovalrequestscontainer.orderdate')}</th>
                        <th>{t('orderapprovalrequestscontainer.status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {approvalRequests.map((approvalRequest, index) => {
                        if (filterRequestByProductType(approvalRequest) && filterRequestsBySearchTerm(approvalRequest)) {
                            return <tr key={index} onClick={() => onClick(approvalRequest)}>
                                <td>{approvalRequest.customerNumber}</td>
                                <td>{approvalRequest.salesPersonEmail}</td>
                                <td>{getProducts(approvalRequest)}</td>
                                <td>{helpers.formatDate(approvalRequest.orderDate)}</td>
                                <td>{approvalRequest.status == 5 ? t("orderapprovalrequestscontainer.approvalneeded") : t("orderapprovalrequestscontainer.denied")}</td>
                            </tr>
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        approvalRequests: state.OrderReducer.approvalRequests,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderApprovalRequestsContainer));