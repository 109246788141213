import React from 'react';
import helpers from '../../Utils/helpers';
import { useTranslation } from 'react-i18next';
import { NoInformation } from "../Panel";

const CampaignsList = ({ customerCampaigns, onSendClick }) => {
    
    const { t } = useTranslation();
    
    if (!customerCampaigns)
        return null;
    
    if (!customerCampaigns.length)
        return <NoInformation />
    
    return <div className="table-responsive">
        <table className="table table-stripe">
            <thead>
                <tr>
                    <th className="text-left">{t('campaignlist.emailto')}</th>
                    <th className="text-left">{t('campaignlist.emailsubject')}</th>
                    <th className="text-left">{t('campaignlist.weblink')}</th>
                    <th className="text-left">{t('campaignlist.date')}</th>
                    <th className="text-left"></th>
                </tr>
            </thead>
            <tbody>
                {customerCampaigns.map((item, index) => {
                    return <tr key={index}>
                        <td className="text-left">{item.email}</td>
                        <td className="text-left">{item.emailSubject}</td>
                        <td className="text-left">{ item.emailWebLink && <a className="btn btn-default" href={item.emailWebLink} target='_blank' rel="noopener noreferrer">{t('campaignlist.opencampaign')}</a>}</td>
                        <td className="text-left">{helpers.formatDateAndTime(item.emailSent)}</td>
                        <td className="text-center infoColumn">
                            <button className="btn btn-default" onClick={onSendClick.bind(this,item.emailId, item.contactId, item.email)}><span className="vismaicon vismaicon-sm vismaicon-email" title="Send" /> {t('campaignlist.send')}</button>
                        </td>
                    </tr>
                })
                }
            </tbody>
        </table>
    </div>
}

export default CampaignsList;