import { Typeahead } from "react-bootstrap-typeahead";
import Card from "../../../Card/Card";
import Checkbox from "../../../Checkbox";
import { useTranslation } from "react-i18next";
import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import DateTimeInput from "../../../FormComponents/Input/DateTimeInput";
import { hasReadWriteAccess } from "../../../../Utils/permissionUtils";
import { UserRoles } from "../../../../Constants/userRoles";

function VossTierOptions({ product, onChange, intermediaries, user }) {
    const { t } = useTranslation();

    const handleProductGroupChange = (productGroup, isChecked) => {
        if (isChecked) {
            onChange("optionalProductGroups", [
                ...product.priceCalculation.optionalProductGroups,
                productGroup
            ]);
        } else {
            onChange("optionalProductGroups", product.priceCalculation.optionalProductGroups.filter(x => x.id != productGroup.id));
        }
    };

    let tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId);
    let selectableProductGroups = tier.productGroups
        .filter(x => x.productGroupType !== VossProductGroupType.Included);
    let selectablePlans = product.offer.plans.filter(plan => tier.applicablePlans.includes(plan.id));

    return <fieldset className="d-grid col gap-24 mb-0 px-0">
        <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.plan")}</h3>
            <fieldset className="d-grid col gap-12 mb-0 px-0">
                {selectablePlans.map((plan, index) => (
                    <div key={index} className="radio mb-0">
                        <input type="radio" name="plan" value={plan.id} id={index} checked={plan.id === product.priceCalculation.planId} onChange={() => onChange("planId", plan.id)} />
                        <label htmlFor={index}>{plan.name}</label>
                    </div>
                ))}
            </fieldset>
        </div>
        {/* {user && (hasReadWriteAccess(user, UserRoles.SMBSales) || hasReadWriteAccess(user, UserRoles.PartnerSales)) && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.invoicestartdate")}</h3>
            <fieldset className="d-grid col gap-12 mb-0 px-0">
                <DateTimeInput
                onChange={(value) => onChange("subscriptionBilling", value)}
                dateFormat={"YYYY-MM"} showTime={false}
                value={product.priceCalculation.subscriptionBilling}
                />
            </fieldset>
        </div>} */}
        {selectableProductGroups.length > 0 && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.addons")}</h3>
            <fieldset className="d-grid col gap-12 mb-0 px-0">
                {selectableProductGroups
                    .map((productGroup, index) => (
                        <div key={index} className="mb-0">
                            <Checkbox
                                label={productGroup.name}
                                checked={product.priceCalculation.optionalProductGroups?.some(pg => pg.id === productGroup.id)}
                                onChange={(e) => handleProductGroupChange(productGroup, e.checked)}
                            />
                        </div>))}
            </fieldset>
        </div>}
        {intermediaries && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.intermediary")}</h3>
            <IntermediarySelector intermediaries={intermediaries} onChange={onChange} product={product} />
        </div>}
    </fieldset>
};

function IntermediarySelector({ intermediaries, onChange, product }) {

    const selectIntemediary = (key, value) => {
        if (value.length) {
            onChange(key, value[0].id);
        }
    }

    const attributes = {
        labelKey: option => `${option.name + " - " + option.invoicingId}`,
        options: intermediaries,
        placeholder: "Välj förmedlare/partner...",
        className: "form-group search-group search-group-button px-0 mb-0",
        onChange: (selected) => selectIntemediary("intermediaryId", selected),
        defaultSelected: intermediaries.filter(x => x.id === product.priceCalculation.intermediaryId)
    };

    return <Typeahead {...attributes} />
}

export function VossTierOptionsCard({ ...props }) {
    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('vosstieroptions.title')} </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VossTierOptions {...props} />
        </Card.Content>
    </Card>
}