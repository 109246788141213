import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import Card from "../../Components/Card/Card";

function JobsContainer({ settingsActions, workerJobs }) {
    const [jobArgs, setJobArgs] = useState([]);

    useEffect(() => {
        settingsActions.getJobs();
    }, [])
    function addJobToQueue() {
        settingsActions.addJobToQueue(jobArgs)
        setJobArgs([]);
    }

    return (
            <Card>
                <Card.Header>
                    <Card.Header.Title>Worker jobs</Card.Header.Title>
                </Card.Header>
                <Card.Content>
                    <div className="table-responsive" style={{height: "100%"}}>
                        <table className="table table-stripe table-hover">
                            <thead>
                            <tr>
                                <th className="text-left">Job name</th>
                                <th className="text-left">Args</th>
                                <th className="text-left">Cron</th>
                                <th className="text-left">Active</th>
                            </tr>
                            </thead>
                            <tbody>
                            {workerJobs && workerJobs.map((job, index) => {
                                return <tr key={job.name} onClick={(event) => setJobArgs(job.args)}>
                                    <td>{job.name}</td>
                                    <td>{job.args.map((arg, index) => {
                                        return index > 0 && arg + " "
                                    })
                                    }</td>
                                    <td>{job.cron}</td>
                                    <td><i
                                        className={`vismaicon vismaicon-sm ${job.active ? "vismaicon-filled vismaicon-success" : "vismaicon-forbidden-circle icon-error"} `}> </i>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                        <div className="input-group my-12">
                        <label><strong>Name,arguments (comma separated)</strong></label>
                            <div className="input-group my-8">
                                <input type="text" className={`form-control rounded-left`} value={jobArgs}
                                       aria-describedby="basic-addon"
                                       onChange={(event) => setJobArgs(event.target.value ? event.target.value.split(",") : [])}/>
                                <button type="button" id="basic-addon" className="btn btn-primary" onClick={() => addJobToQueue()}> Add to queue</button>
                            </div>
                        </div>
                    </div>
                </Card.Content>
            </Card>
    )
}

function mapStateToProps(state) {
    return {
        workerJobs: state.SettingsReducer.workerJobs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsContainer));