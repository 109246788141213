import React, { Component } from 'react';

export default class TextInputWithButton extends Component {

    render() {
        let inputProps = {
            type: 'text',
            onChange: (e) => this.props.onChange(e.target.value),
            value: this.props.value || ''
        };

        return (
            <div className="form-group">
                <label className="col-xs-4 control-label">{this.props.label}</label>
                <div className="col-xs-8">
                    <div className="input-group">
                        <input className="form-control" {...inputProps} />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="button" onClick={this.props.buttonClick.bind(this)}>{this.props.buttonTitle || 'Go'}</button>
                        </span>
                    </div>              
                </div>
            </div>
        )
    }
}