import { Fragment, useState } from "react";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import { VossDiscountType } from "../../../Constants/VOSS/vossDiscountType";

function ChargePlanDiscountsTable({ ...props }) {
    const { chargePlanDiscounts, selectedPackage, onEdit, onDeleteChargePlanDiscount, onDeleteProductPricingDiscount, disableEdit } = props;

    const getChargePlan = (chargePlanId) => {
        return selectedPackage.chargePlans.find(plan => plan.id === chargePlanId);
    };

    const getTier = (tierId) => {
        return selectedPackage.tiers.find(tier => tier.id === tierId);
    };

    const getPlan = (planId) => {
        return selectedPackage.plans.find(plan => plan.id === planId);
    };

    const getProductGroup = (productGroupId) => {
        return selectedPackage.tiers
            .flatMap(tier => tier.productGroups)
            .find(group => group.id === productGroupId);
    };

    const getProduct = (productGroupId, productId) => {
        const group = getProductGroup(productGroupId);
        return group?.products.find(product => product.id === productId);
    };

    const handleEditClick = (chargePlan, productGroup, productPricing) => {
        const discountToEdit = {
            selectedTier: chargePlan.tierId,
            selectedPlan: chargePlan.planId,
            selectedChargePlan: chargePlan,
            selectedProductGroupPricing: productGroup.productGroupId,
            selectedProductPricing: productPricing.productId,
            selectedDiscountType: productPricing.discountType,
            unitPrice: productPricing.unitPriceDiscount,
            tierPricingDiscounts: productPricing.tierPricingDiscounts,
        };

        onEdit(discountToEdit);
    };

    const discountType = {
        [VossDiscountType.FlatPrice]: 'Flat price',
        [VossDiscountType.Percentage]: 'Percentage',
    };

    return <div>
        <table className="table table-stripe skynet-table-no-divider">
            <thead>
                <tr>
                    <td width={500}></td>
                    <td width={500}>Product/Index</td>
                    <td width={200}>Discount Type</td>
                    <td width={300}>Discount amount</td>
                    <td></td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {chargePlanDiscounts && chargePlanDiscounts.length > 0 ? (
                    selectedPackage && chargePlanDiscounts.map((chargePlanDiscount) => {
                        const chargePlan = getChargePlan(chargePlanDiscount.chargePlanId);
                        if (!chargePlan) return null;
                        const tier = getTier(chargePlan.tierId);
                        const plan = getPlan(chargePlan.planId);
                        return <Fragment key={chargePlanDiscount.chargePlanId}>
                            <tr className={`table-primary`}>
                                <td className={'bold'} colSpan={5}>{`${tier.name} - ${plan.name}`}</td>
                                <td>{!disableEdit ? <SkynetIcon onClick={() => onDeleteChargePlanDiscount(chargePlanDiscount)} icon={'vismaicon-delete'} /> : null}</td>
                            </tr>
                            {chargePlanDiscount.productGroupDiscounts.map((productGroup) => (
                                productGroup.productPricingDiscounts.map((productPricing) => (
                                    <Fragment key={`${productGroup.productGroupId}-${productPricing.productId}`}>
                                        <tr>
                                            <td className="pl-48">{getProductGroup(productGroup.productGroupId).name}</td>
                                            <td>{getProduct(productGroup.productGroupId, productPricing.productId).name}</td>
                                            {productPricing.tierPricingDiscounts?.length <= 0 ?
                                                <>
                                                    <td>{discountType[productPricing.discountType]}</td>
                                                    <td>{productPricing.unitPriceDiscount}</td>
                                                </>
                                                :
                                                <td colSpan={2}></td>
                                            }
                                            <td>{!disableEdit ? <SkynetIcon onClick={() => handleEditClick(chargePlan, productGroup, productPricing)} icon={'vismaicon-edit'} /> : null}</td>
                                            <td>{!disableEdit ? <SkynetIcon onClick={() => onDeleteProductPricingDiscount(chargePlanDiscount, productGroup, productPricing)} icon={'vismaicon-delete'} /> : null}</td>
                                        </tr>
                                        {productPricing.tierPricingDiscounts.map((tierPricing) => (
                                            <Fragment key={tierPricing.index}>
                                                <tr>
                                                    <td></td>
                                                    <td><span className="bold">Index:</span> {tierPricing.index}</td>
                                                    <td>{discountType[tierPricing.discountType]}</td>
                                                    <td colSpan={3}>{tierPricing.unitPriceDiscount ? <p className="mb-0"><span className="bold">Unit price: </span>{tierPricing.unitPriceDiscount}</p> : null}
                                                        {tierPricing.flatPriceDiscount ? <p className="mb-0"><span className="bold">Flat price: </span>{tierPricing.flatPriceDiscount}</p> : null}
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                ))
                            ))}
                        </Fragment>
                    })
                ) : (
                    <tr>
                        <td colSpan="6"><em>No charge plan discounts added</em></td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}

export default ChargePlanDiscountsTable;