import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commonActions from '../../Actions/commonActions'
import helper from '../../Utils/helpers'
import TextInput from '../TextInput';

class BlikkAdditionalUsers extends Component {

    onIncrease(value, licenseId) {
        this.onLicenseChange(value, licenseId, +1)
        
    }

    onDecrease(value, licenseId) {
        this.onLicenseChange(value, licenseId, -1)
    }

    onLicenseChange(value, licenseId, change) {

        value = parseInt(value, 10);

        if (isNaN(value))
            value = 0;
        
        const licenseTypes = this.props.product.licenseTypes;
        const currentLicenseType = licenseTypes.find(item => item.id === licenseId);
        
        if (change < 0 && (value <= 0 || value <= currentLicenseType.used)) {
            
            if(currentLicenseType.used > 0)
                this.renderNotAllowedToDecreaseMessage(currentLicenseType);
            
            return;
        }
            
        if(change === 0 && value < currentLicenseType.used)
            value = currentLicenseType.used; 

        const count = value + change;
        currentLicenseType.count = count;

        const index = licenseTypes.findIndex(item => item.id === currentLicenseType.id);
        licenseTypes[index] = currentLicenseType;

        this.props.onChange(licenseTypes);

        this.props.onSave(licenseId, count);
    }

    renderNotAllowedToDecreaseMessage(licenseType) {
        
        const title = `Cannot decrease license`;         
        const plural = licenseType.used > 1 ? "s" : "";
        const message = `Licensetype "${licenseType.name}" has ${licenseType.used} user${plural} connected to this licenseType.`;         
        
        this.props.commonActions.showError(message, title);            
    }

    render() {
        const {product} = this.props;

        if(!product)
            return null

        const licenseTypes = product.licenseTypes; 

        return (
            <>
            {licenseTypes.map(item => {  

                const disabledDecreaseButton = item.count === item.used || item.count === 0;
                const decreaseClassList = `vismaicon vismaicon-remove-circle ${disabledDecreaseButton ? "disabled" : ""}`;
                if(item.display){
                    return <div key={item.id} className="form-group additionalUsers">
                    <label className="col-xs-4 control-label">{item.name}</label>
                    <div className="col-xs-4 inline-block inline">
                        <span className={decreaseClassList} onClick={!disabledDecreaseButton ? this.onDecrease.bind(this, item.count, item.id) : null} />
                        <TextInput type="number" label="noLabel" value={"" + item.count} onChange={(value) => this.onLicenseChange(value, item.id, 0)}></TextInput>
                        <span className="vismaicon vismaicon-add-circle col-xs-1" onClick={this.onIncrease.bind(this, item.count, item.id)} />
                    </div>
                    <label className="col-xs-4 control-label">{helper.currencyFormatter(item.initialPrice)} / user and year</label>
                </div>
                }
            })}
        </>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(BlikkAdditionalUsers);