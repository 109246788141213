import { useTranslation } from "react-i18next";
import AppointmentOrderDropdown from "../../../../Containers/Appointment/AppointmentOrderDropdown";
import helpers from "../../../../Utils/helpers";
import Card from "../../../Card/Card";
import DropDown from "../../../DropDown";

export function BlikkTransactionsCard({ ...props }) {

    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t("blikktransactions.title")}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <BlikkTransactions {...props} />
        </Card.Content>
    </Card>
}


function BlikkTransactions({ product, customer, orderId, orderActions }) {

    const onChange = (transactionTypes) => {
        orderActions.changeBlikkTransactions(transactionTypes);
    };

    const onSave = () => {
        orderActions.getBlikkProductDetails(product.productId, customer.vbCustomer.customerNumber, orderId, product.tenantGuid);
      }
    

    const onTransactionChange = (value, id) => {
        const transactionTypes = product.transactionTypes;
        const type = transactionTypes.find((type) => type.name === id);

        if (type !== null) {
            type.transactions.forEach((t) => {
                t.count = 0;
            });

            const transaction = type.transactions.find((transaction) => transaction.name === value);

            if (transaction) {
                transaction.count = 1;
            }
        }

        onChange(transactionTypes);
        onSave();
    };

    const getSelectedValue = (item) => {
        const transaction = item.transactions.find((transaction) => transaction.count === 1);
        return transaction ? transaction.name : '';
    };

    const getPrice = (item) => {
        const transactionTypes = product.transactionTypes;
        let price = 0;

        const type = transactionTypes.find((type) => type.name === item.name);

        if (type !== null) {
            const transaction = type.transactions.find((transaction) => transaction.count === 1);

            if (transaction) {
                price = transaction.initialPrice;
            }
        }

        return helpers.currencyFormatter(price);
    };

    const transactionTypes = product.transactionTypes;

    return <div className='d-flex flex-column' style={{ gap: "24px" }}>
        {transactionTypes.map((item) => (
            <div key={item.id}>
                <div>
                    <label>{item.name} (credit: {item.credit})</label>
                </div>
                <div>
                    <DropDown
                        list={item.transactions}
                        nameValued={true}
                        blankOption={true}
                        onChange={(value) => onTransactionChange(value, item.name)}
                        id={item.name}
                        value={getSelectedValue(item)}
                        elementWidth="100"
                        customFormClass="mb-8"
                    />
                </div>
                <label className="control-label">{getPrice(item)}</label>
            </div>
        ))}
        <AppointmentOrderDropdown appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
    </div>
}