import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class SelectedLeadsButton extends Component {
    
    state = {
        isOpen: false
    }


    buttonClick = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        const {selectedLeads, toggleModal, deselectAll} = this.props  
     
        let disabled = true;
        if(selectedLeads !== undefined){
            if(selectedLeads.length > 0)
                disabled = false;
        }

        if(disabled)
            this.state.isOpen = false;


        const className = ` ${this.state.isOpen ? 'open' : ''}`;       
        const {t} = this.props;

        return <div className="pull-right" style={{ marginBottom: 10 }}>
                    <div className={'dropdown ' + className}>
                        <button type="button"
                            className="btn btn-default dropdown-toggle no-margin"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded={this.state.isOpen ? 'true' : 'false'}
                            onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                            disabled={disabled}
                        >
                            {t('selectedleadbutton.selected')}
                        </button>
                        <ul className="dropdown-menu" role="menu">
                            <li><a onClick={() => toggleModal("showCloseModal")}> {t('selectedleadbutton.close')}</a></li>
                            <li><a onClick={() => deselectAll()}> {t('selectedleadbutton.deselect')}</a></li>
                        </ul>
                    </div>
                </div>
    }
}

export default withTranslation()(SelectedLeadsButton);