import { VossApplicationScopes } from "../../../Constants/VOSS/vossApplicationScope";
import { VossPeriodKind } from "../../../Constants/VOSS/vossPeriodKind";
import { VossTimeLengthUnits } from "../../../Constants/VOSS/vossTimeLengthUnits";
import DateTimeInput from "../../FormComponents/Input/DateTimeInput";
import { FormInput } from "../../FormComponents/Input/Input";
import { RadioButtons } from "../../FormComponents/RadioButtons";
import { VossVersionStatus, VossVersionStatusText } from "../../../Constants/VOSS/vossVersionStatus";
import { PackageSelector } from "../../FormComponents/PackageSelector";

function DiscountInfoForm({ packages, packageActions, onChange, form: { data }, discountAgreement, chargePlanDiscounts, disableEdit }) {

    let applicationScopes = [
        { id: 'access-type-1', label: 'All', value: VossApplicationScopes.Any.toString() },
        { id: 'access-type-2', label: 'Subscription customer', value: VossApplicationScopes.SubscriptionCustomer.toString() },
        { id: 'access-type-3', label: 'AO-client', value: VossApplicationScopes.NonSubscriptionCustomerInvoiceRecipient.toString() },
    ];

    let periodKinds = [
        { id: 'period-type-1', label: 'Align to binding period', value: VossPeriodKind.AlignedToBindingPeriod.toString() },
        { id: 'period-type-2', label: 'Align to subscription billing period', value: VossPeriodKind.AlignedToSubscriptionBillingPeriod.toString() },
        { id: 'period-type-3', label: 'Fixed time', value: VossPeriodKind.FixedTime.toString() },
    ];

    let lengthUnits = [
        { id: 'length-unit-1', label: 'Days', value: VossTimeLengthUnits.Day.toString() },
        { id: 'length-unit-2', label: 'Months', value: VossTimeLengthUnits.Month.toString() },
        { id: 'length-unit-3', label: 'Years', value: VossTimeLengthUnits.Year.toString() },
    ];

    const renderStatusRadioButtons = () => {
        let statuses = [
            { id: 'status-1', label: VossVersionStatusText[VossVersionStatus.Draft], value: VossVersionStatus.Draft.toString(), disabled: discountAgreement.status === VossVersionStatus.Published || discountAgreement.status === VossVersionStatus.Archived || discountAgreement.status === VossVersionStatus.Staged },
            { id: 'status-2', label: VossVersionStatusText[VossVersionStatus.Staged], value: VossVersionStatus.Staged.toString(), disabled: (chargePlanDiscounts && chargePlanDiscounts.length === 0) || discountAgreement.status === VossVersionStatus.Published || discountAgreement.status === VossVersionStatus.Archived || discountAgreement.status === VossVersionStatus.Discarded },
            { id: 'status-3', label: VossVersionStatusText[VossVersionStatus.Published], value: VossVersionStatus.Published.toString(), disabled: (chargePlanDiscounts && chargePlanDiscounts.length === 0) || discountAgreement.status === VossVersionStatus.Archived || discountAgreement.status === VossVersionStatus.Discarded },
            { id: 'status-4', label: VossVersionStatusText[VossVersionStatus.Archived], value: VossVersionStatus.Archived.toString(), disabled: discountAgreement.status === VossVersionStatus.Draft || discountAgreement.status === VossVersionStatus.Discarded },
            { id: 'status-5', label: VossVersionStatusText[VossVersionStatus.Discarded], value: VossVersionStatus.Discarded.toString(), disabled: discountAgreement.status !== VossVersionStatus.Draft },
        ];

        return <div className="form-control skynet-form-input">
            <label className="bold">Status</label>
            <div className="d-flex flex-column">
                <RadioButtons items={statuses} onChange={(value) => onChange(value, 'status')} value={data.status} />
            </div>
        </div>
    }

    const handlePackageChange = (selected) => {
        packageActions.getPackage(selected);
        handleFormChange(selected, 'packageId');
    };

    const handleFormChange = (value, field) => {
        onChange(value, field);
    };


    return (
        <div className="d-flex gap-64">
            <fieldset className="d-flex flex-column gap-16" style={{width:"300px"}}>
                {packages && packages.length > 0 && <PackageSelector packages={packages} onChange={handlePackageChange} defaultPackageId={data.packageId} disabled={discountAgreement} label="Package" required={true} />}
                <FormInput label="Name" onChange={(value) => onChange(value, 'name')} required value={data.name} />
                <div className={`form-control skynet-form-input required ${disableEdit ? 'disabled' : ''}`}>
                    <label>Valid from</label>
                    <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} onChange={(value) => onChange(value, 'validFrom')} value={data.validFrom} />
                </div>
                <div className={`form-control skynet-form-input ${disableEdit ? 'disabled' : ''}`}>
                    <label>Valid to</label>
                    <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} onChange={(value) => onChange(value, 'validTo')} value={data.validTo} />
                    <em className="mb-0">Leave empty for unlimited validity</em>
                </div>
                <div>
                    <FormInput label="External ID" onChange={(value) => onChange(value, 'externalId')} value={data.externalId} />
                    <em className="mb-0">Use only if the discount agreement is relevant to another system</em>
                </div>
            </fieldset>
            <fieldset className={`d-flex flex-column gap-16 ${disableEdit ? 'disabled' : ''}`}>
                <div className="form-control skynet-form-input">
                    <label className="bold">Behörighet på Visma Spcs webbplatser</label>
                    <div className="d-flex flex-column">
                        <RadioButtons items={applicationScopes} onChange={(value) => onChange(value, 'applicationScope')} value={data.applicationScope} />
                    </div>
                </div>
                <div className="form-control skynet-form-input required">
                    <label className="bold">Period kind</label>
                    <div className="d-flex flex-column">
                        <RadioButtons items={periodKinds} onChange={(value) => onChange(value, 'periodKind')} value={data.periodKind} />
                    </div>
                </div>
                {data.periodKind === VossPeriodKind.FixedTime.toString() && (
                    <div className="pl-0 d-flex flex-column gap-16">
                        <div>
                            <FormInput label="Amount" required onChange={(value) => onChange(value, 'length')} value={data.length} />
                        </div>
                        <div className="d-flex flex-column">
                            <RadioButtons items={lengthUnits} onChange={(value) => onChange(value, 'unit')} value={data.unit} />
                        </div>
                    </div>
                )}
                {(data.periodKind === VossPeriodKind.AlignedToBindingPeriod.toString() || data.periodKind === VossPeriodKind.AlignedToSubscriptionBillingPeriod.toString()) && <div className="pl-0 d-flex flex-column gap-16">
                    <div>
                        <FormInput label="Period iteration count" required onChange={(value) => onChange(value, 'periodIterationCount')} value={data.periodIterationCount} />
                    </div>
                </div>}
            </fieldset>
            {discountAgreement &&
                <fieldset className="d-flex flex-column gap-16">
                    {renderStatusRadioButtons()}
                </fieldset>
            }
        </div>
    );
}

export default DiscountInfoForm;