import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import LeadDetailActions from '../../Components/Leads/LeadDetailActions'
import PanelActions from '../../Components/PanelActions'
import AoLeadForm from '../../Components/Leads/AoLeadForm'
import ActionHistory from '../../Components/ActionHistory'

import SendEmailModal from '../../Components/Leads/SendEmailModal'
import AppointmentModal from '../../Components/Leads/AppointmentModal'
import CloseModal from '../../Components/Leads/CloseModal'
import AoBuyModal from '../../Components/Leads/AoBuyModal'
import './Lead.css'
import { useNavigate, useParams } from 'react-router-dom';
import {hasReadWriteAccess} from "../../Utils/permissionUtils";
import {UserRoles} from "../../Constants/userRoles";

function AoLeadDetailsContainer({  leadActions, lead, user, t, salesPersons }) {

    const [activeModal, setActiveModal] = useState("")
    const [emailActionLogId, setEmailActionLogId] = useState(0)
    const { leadId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        leadActions.getAoLead(leadId);
        leadActions.getAoSalesPersons();
    }, [])

    const onNoAnswerClick = () => {
        var payload = { data: { action: "No answer", leadType: "AoLead" } };

        leadActions.saveAction(leadId, payload, () => refresh());
    }

    const onBusyClick = () => {
        var payload = { data: { action: "Busy", leadType: "AoLead" } };

        leadActions.saveAction(leadId, payload, () => refresh());
    }

    const onCloseClick = (isModal) => {

        if (isModal) {
            setActiveModal("");
            refresh();
        } else {
            navigate(-1)
        }
    }

    const onReopenClick = () => {

        leadActions.reopenAo(leadId);
    }

    const toggleModal = (modalName, emailActionLogId = 0) => {

        if (activeModal === modalName) {
            setActiveModal("")
        } else {
            setActiveModal(modalName)

        }
        setEmailActionId(emailActionLogId)
    };

    const refresh = () => {

        leadActions.getAoLead(lead.id, true)
    }

    const onSendbuttonClick = (modalName) => {
        toggleModal(modalName);
    }

    const onAssingButtonClick = () => {

        if(user.id){
            leadActions.assignAOLead(lead.id, user.id, refresh());
        }else{
            leadActions.unassignAOLead(lead.id, refresh())
        }
        refresh();
    }

    const AssignTo = (leadId, userId) => {

        if(userId){
            leadActions.assignAOLead(leadId, userId, refresh());
        }else{
            leadActions.unassignAOLead(leadId, refresh())
        }
        refresh();
    } 

    const onBuyClick = () => {
        toggleModal("showBuyModal");
    }

    if (!lead) {
        return null;
    }

    const aoLeadsAccess = hasReadWriteAccess(user, UserRoles.AOLeads);

    const containerWidth = 7;

    return (
        <div className="row">
            {activeModal === "showSendEmailModal" && <SendEmailModal leadType="aoLead" lead={lead} onClose={toggleModal.bind(this, "showSendEmailModal")} onSave={() => onSendbuttonClick("showSendEmailModal")} emailActionLogId={emailActionLogId} />}
            {activeModal === "showAppointmentModal" && <AppointmentModal leadType="aoLead" lead={lead} onClose={toggleModal.bind(this, "showAppointmentModal")} onComplete={() => onCloseClick(true)} />}
            {activeModal === "showCloseModal" && <CloseModal leadType="aoLead" lead={lead} onClose={toggleModal.bind(this, "showCloseModal")} onSave={() => onCloseClick(true)} />}
            {activeModal === "showBuyModal" && <AoBuyModal lead={lead} leadType="aoLead" onClose={toggleModal.bind(this, "showBuyModal")} onSave={() => onCloseClick(true, "showBuyModal")} />}

            <div className={`col-lg-${containerWidth} col-xs-12`}>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        {!lead.isClosed && aoLeadsAccess && <PanelActions editLink={routes.aoleadEdit(lead.id)} onRefresh={refresh} />}
                        <h3 className="panel-title">{t('aoleaddetailscontainer.aoleaddetails')}</h3>
                    </div>
                    <div className="panel-body">
                        <AoLeadForm
                            user={user}
                            lead={lead}
                            onFieldChange={() => { }}
                            readonly={true}
                            salesPersons={salesPersons}
                            AssignTo={(leadId, userId) => AssignTo(leadId, userId)}
                            onCancelClick={() => onCloseClick()}
                            onReopenClick={onReopenClick}
                            onAssingButtonClick={onAssingButtonClick}
                        />
                    </div>
                </div>

            </div>
            <div className="col-lg-5 col-xs-12">
                {!lead.isClosed && aoLeadsAccess && <LeadDetailActions
                    leadType="AoLead"
                    onClick={(modalId) => toggleModal(modalId)}
                    onNoAnswerClick={() => onNoAnswerClick()}
                    onBuyClick={onBuyClick}
                    onBusyClick={onBusyClick}
                    hasCustomer={!!lead.customerNumber}
                />}
                <ActionHistory actionHistory={lead.actionHistory} toggleModal={(modalId, emailActionLogId) => toggleModal(modalId, emailActionLogId)} />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        lead: state.LeadsReducer.aoLead,
        salesPersons: state.LeadsReducer.aoSalesPersons
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AoLeadDetailsContainer));