import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from '../../Utils/helpers';

function FileBoxTicketActionHistoryContainer({ fileBoxActions, t }) {

    useEffect(() => {
        // fileBoxActions.getFileBoxTicketActionHistory(ticketId);
    }, [])

    const actionLogs = [
        {
          action: "Open ticket",
          user: "asa.karlsson2@visma.com",
          logdate: "2023-04-05T13:25:00.000Z"
        },
        {
          action: "Closed ticket",
          user: "asa.karlsson2@visma.com",
          logdate: "2023-04-05T13:25:00.000Z"
        },
        {
          action: "Received case from customer",
          user: "Customer",
          logdate: "2023-04-05T13:07:00.000Z"
        },
        {
          action: "Customer added file 'ADMBA_Strömstad Marina AB_230404_1.BCK'",
          user: "Customer",
          logdate: "2023-04-05T13:07:00.000Z"
        },
        {
          action: "Received case from customer",
          user: "Customer",
          logdate: "2023-04-05T12:07:00.000Z"
        },
        {
          action: "Received case from customer",
          user: "Customer",
          logdate: "2023-04-04T10:51:00.000Z"
        },
        {
          action: "Sent mail to ekonomi@stromstadmarina.se",
          user: "julijana.vasilj@visma.com",
          logdate: "2023-04-04T10:49:00.000Z"
        },
        {
          action: "Added event 'Rättning Administration Sql'",
          user: "julijana.vasilj@visma.com",
          logdate: "2023-04-04T10:48:00.000Z"
        },
        {
          action: "Created new ticket",
          user: "julijana.vasilj@visma.com",
          logdate: "2023-04-04T10:48:00.000Z"
        }
      ];
    

    return (
            <table className="table table-stripe table-actionLogs">
                <thead>
                    <tr>
                        <th className="text-left">{t('fileboxticketactionhistorycontainer.action')}</th>
                        <th className="text-left">{t('fileboxticketactionhistorycontainer.user')}</th>
                        <th className="text-left">{t('fileboxticketactionhistorycontainer.logdate')}</th>
                    </tr>
                </thead>
                <tbody>
                    {actionLogs.map((actionLog, index) => {
                        return <tr key={index}>
                            <td>{actionLog.action}</td>
                            <td>{actionLog.user}</td>
                            <td>{helpers.formatDateAndTime(actionLog.logdate)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
    );
}
function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxTicketActionHistoryContainer));