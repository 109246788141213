
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PhoneLink from '../PhoneLink';

class MyAppointmentTable extends Component {

    state = {
        isOpen: false,
        i: -1
    }

    sortList() {
        const jsonSort = localStorage.getItem("sortOrder_myFilteredOpenAppointments");
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    render() {
        const { appointments, closed, sortListByKey, moveToDetails, getClassNameForStatus, t } = this.props;

        const className = `${this.state.isOpen ? 'open' : ''}`; 
        const sort = this.sortList();
        const sortOrder = sort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";   
        const sortKey = sort.key;

        return <table className="table table-stripe table-hover table-appointments">
        <thead>
            <tr>
                <th className="text-left sortable" onClick={() => sortListByKey('customerName')}>{t('myopenappointmentstable.customername')} {sortKey === 'customerName' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('myopenappointmentstable.customernumber')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('myopenappointmentstable.email')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('myopenappointmentstable.phonenumber')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('time')}>{t('myopenappointmentstable.time')} {sortKey === 'time' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('subject')}>{t('myopenappointmentstable.subject')} {sortKey === 'subject' && <i className={sortOrder}></i>}</th>
                <th className="text-left sortable" onClick={() => sortListByKey('customerNumber')}>{t('myopenappointmentstable.comment')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>
                { closed && <th className="text-left sortable" onClick={() => sortListByKey('reason')}>{t('myopenappointmentstable.reason')} {sortKey === 'reason' && <i className={sortOrder}></i>}</th>}
                <th className="text-center" width="50"></th>
            </tr>
        </thead>
        <tbody>
            {appointments.map((appointment, index) => {
                return <tr key={index} onClick={() => moveToDetails(appointment.id)}>
                    <td>{appointment.customerName ? appointment.customerName : 'N/A'}</td>
                    <td>{appointment.customerNumber ? appointment.customerNumber : 'N/A'}</td>
                    <td>{appointment.emailAddress}</td>
                    <td>{appointment.phoneNumber} {appointment.phoneNumber && <PhoneLink phoneNumber={appointment.phoneNumber} icon={true} />}</td>
                    <td>{appointment.time}</td>
                    <td>{appointment.subject}</td>
                    <td onMouseEnter={() => { this.setState({ isOpen: true, i: index })}} onMouseLeave={() => { this.setState({ isOpen: false, i: index })}}>
                        {appointment.comments === null ? '' : appointment.comments.slice(0,20) }
                        {appointment.comments && this.state.i === index && className && appointment.comments.length > 20 && <div className="table-content-hover">{appointment.comments} </div>}
                    </td>
                    { closed && <td>{appointment.reason}</td>}
                    <td className="text-center"><span className={getClassNameForStatus(appointment)}></span></td>
                </tr>
            })}
        </tbody>
    </table>
    }
}

export default withTranslation()(MyAppointmentTable);
