import { connect } from "react-redux";
import Card from "../../../../Components/Card/Card";
import ActivityLogForm from "../../../../Components/InternalCustomerAdmin/ActivityLog/ActivityLogForm";
import { ActivityLogTable } from "../../../../Components/InternalCustomerAdmin/ActivityLog/ActivityLogTable";
import { useTranslation } from "react-i18next";
import activityLogActions from "../../../../Actions/InternalCustomerAdmin/activityLogActions";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import commonActions from "../../../../Actions/commonActions";

function ActivityLogContainer({ ...props }) {

    const { t } = useTranslation();
    const { activityLogs, activityLogActions, commonActions, activityLogsLoading } = props;

    useEffect(() => {
        commonActions.loadDropdownList('VonServices');

        return () => {
            activityLogActions.clear('activityLogs');
        }
    }, []);

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('activitylogcontainer.showlogging')}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <div>
                <div>
                    <ActivityLogForm {...props} />
                </div>
                <div>
                    {!activityLogsLoading && (activityLogs ? <ActivityLogTable activityLogs={activityLogs} /> : null)}
                </div>
                {activityLogsLoading && <div className="spinner spinner-default-green m-auto d-block" />}
            </div>
        </Card.Content>
    </Card>
}

function mapStateToProps(state) {
    return {
        activityLogs: state.InternalCustomerAdminReducer.activityLogs,
        customer: state.CustomerReducer.customer,
        activityLogsLoading: state.CommonReducer.dataLoading.activityLogs,
        services: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.VonServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        activityLogActions: bindActionCreators(activityLogActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogContainer);