import React from 'react';
import { bindActionCreators } from "redux";
import commonActions from "../Actions/commonActions";
import { connect } from "react-redux";
import SwitchToggle from './SwitchToggle';


function TextInput({ maxAmountOfCharacters, checkbox, checkboxToggle, style, type, label, labelWidth, placeholder, textInputWidth, readonly, disabled, value, multiline, hideAdditionalInfo, copyToClipBoard, onChange, noLabelStyle, commonActions }) {

    let inputProps = {
        type: type ? type : 'text',
        onChange: (e) => onChange(e.target.value),
        value: value || '',
        disabled: disabled,
        readOnly: readonly,
        placeholder: placeholder,
        style: style,
        maxLength: maxAmountOfCharacters
    };
    
    const random = () => {
        return Math.floor(Math.random() * 100000);
    }

    const randomNumber = random();

    const content = multiline ? <textarea ref={(input) => TextInput = input} className="form-control" {...inputProps} /> : <input ref={(input) => TextInput = input} className="form-control" {...inputProps} />;

    let additionalInfo = null;
    if (maxAmountOfCharacters && !hideAdditionalInfo) {
        let charactersLeft = maxAmountOfCharacters - value.length;
        additionalInfo = <p className="form-text text-muted">Maximum amount of characters: {charactersLeft}</p>;
        if (charactersLeft !== maxAmountOfCharacters)
            additionalInfo = <p className="form-text text-muted">Amount of characters left to use: {charactersLeft}</p>;
        if (charactersLeft < 0)
            additionalInfo = <p className="form-text text-muted">You have exceeded the maximum amount of characters</p>;
    }

    let labelWidth2 = 4;
    if (labelWidth)
        labelWidth2 = labelWidth

    let width = 8;
    if (textInputWidth)
        width = textInputWidth

    if (copyToClipBoard)
        width = width - 2;

    if (checkbox) {

        return (
            <div className="form-group justify-content-between" >
                <div>
                    <div className='checkbox'>
                        <input type="checkbox" id={randomNumber} onClick={checkboxToggle()} />
                        <label for={randomNumber} />
                    </div>
                    <label className={`control-label`}>{label}</label>

                </div>
                <div className={`col-sm-12 col-md-${width} p-0`}>
                    {content}
                    {additionalInfo}
                </div>
            </div>
        )
    }

    if (label === "noLabel") {
        return (
            <div className="form-group">
                <div className={`col-xs-12`} style={noLabelStyle}>
                    {content}
                    {additionalInfo}
                </div>
            </div>
        )
    }

    return <div className="form-group">
        <label className={`col-sm-12 col-md-${labelWidth2} control-label`}>{label}</label>
        <div className={`col-sm-12 col-md-${width}`}>
            {content}
            {additionalInfo}

        </div>
        {copyToClipBoard && <div className="col-2"><span title={`Copy ${label}`} style={{ cursor: "pointer" }} className="vismaicon vismaicon-copy" onClick={() => commonActions.copyToClipboard(label, value)}></span></div>}
    </div>
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(TextInput);