import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../Actions/commonActions';
import ReactModal from 'react-modal';

function ProductInformationModal({commonActions, showModal, informationTitle, productInfo, t}) {
    
  return (
    <ReactModal isOpen={showModal} style={
        {overlay: {
            backgroundColor: "transparent",
            }
        }
    } ariaHideApp={false} className='informationModal modal fade in' >
        <div className='bigModal'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h4 className='modal-title'>{informationTitle}</h4>
                    <button type='button' className='close' aria-label='Close' onClick={() => commonActions.showModal(false)}>
                        <span aria-hidden={true}>x</span>
                    </button>
                </div>
                <div className='modalContent modal-body'>
                    <div>
                        <p><strong>{t("productOverview.productOwnerName")}:</strong> {productInfo?.productOwner}</p>
                        <p><strong>{t("productOverview.productOwnerEmail")}:</strong> {productInfo?.productOwnerEmail}</p>
                    </div>
                    <textarea className='productTextArea informationTextArea' readOnly value={productInfo?.notes}/>
                </div>
            </div>
        </div>
    </ReactModal>
  )
}

function mapStateToProps(state) {
    return {
      showModal: state.CommonReducer.showModal,
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductInformationModal));