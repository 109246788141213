import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import commonActions from '../Actions/commonActions';
import TextInput from './TextInput';
import { RegexLib } from '../Constants/formValidationRules';
import helpers from '../Utils/helpers';
import Dropzone from 'react-dropzone';
import ConfirmModal from './ConfirmModal';
import DropDown from './DropDown';

function EditProductOwnerForm({ product, productForm, productOverviewActions, commonActions, newProductState, setNewProductState, users, t }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [editLinkTemplate, setEditLinktemplate] = useState(false);

  const formValidationRules = [
    { inputId: 'productOwnerEmail', rule: RegexLib.email, message: 'Email is not valid format' }
  ];

  useEffect(() => {
    if (newProductState) {
      commonActions.startFormEditing("productForm", {
        sku: null,
        productName: null,
        productOwnerId: null,
        downloadLinkTemplate: null,
        notes: null,
      });
    };
    if (!newProductState && product) {
      commonActions.startFormEditing("productForm", { ...product });
    }
  }, [product, newProductState]);

  useEffect(() => {
    return () => commonActions.cancelFormEditing(productForm?.id);
  }, [])

  const onFieldChange = (field, value) => {
    commonActions.changeMultiFormField(productForm.id, field, value);
  };

  const handleSaveClick = () => {
    const errorMsg = helpers.validateForm(productForm);
    setErrorMessage(null);

    if (errorMsg) {
      setErrorMessage(errorMsg);
      return;
    }

    productOverviewActions.updateProduct(productForm.data.id, productForm.data)
  };

  const handleFileDrop = (files) => {
    productOverviewActions.uploadProductImage(files[0], productForm && productForm.data.id);
  }

  const handleEditClick = () => {
    setEditLinktemplate(!editLinkTemplate);
  }

  const toggleNewProductForm = () => {
    commonActions.cancelFormEditing(productForm?.id);
    setNewProductState(!newProductState)
  }

  const onConfirmed = () => {
    if (productForm.data?.id) {
      productOverviewActions.deleteProductAndVersions(productForm.data?.id);
    }
    commonActions.cancelFormEditing(productForm?.id);
    productOverviewActions.resetVersionState();
  }

  return (
    <>
      <div className='form-vertical'>
        <div className='col-xs-12'>
          <h3 className='titleAndLink'><strong>{!newProductState ? "Edit Product Information" : "New product"}</strong><p className='ProductOverviewItemTitle' onClick={() => toggleNewProductForm()}>{!newProductState ? t("productOverview.addNewProduct") : t("productOverview.editExistingProduct")}</p></h3>
        </div>
        <div className='textinputscontainer'>
          <TextInput label={<strong>SKU</strong>} labelWidth={12} textInputWidth={12} onChange={(value) => onFieldChange("sku", value)} value={productForm ? productForm.data.sku : null} disabled={!productForm} />
          <TextInput label={<strong>{t("productOverview.productName")}</strong>} labelWidth={12} textInputWidth={12} onChange={(value) => onFieldChange("productName", value)} value={productForm ? productForm.data.productName : null} disabled={!productForm} />
          <DropDown elementWidth={12} label={<strong>{t("productOverview.productOwner")}</strong>} list={users && users.filter(user => user.name !== "")} blankOption={true} value={productForm ? productForm.data.productOwnerId : ""} onChange={(value) => onFieldChange("productOwnerId", value)} disabled={!productForm} />
        </div>
        <div className='col-xs-12'>
          <div className='templateContainer'>
            <div className='templateinputcontainer'>
              <TextInput label={<strong>{t("productOverview.downloadLinkTemplate")}</strong>} labelWidth={12} textInputWidth={12} onChange={(value) => onFieldChange("downloadLinkTemplate", value)} value={productForm ? productForm.data.downloadLinkTemplate : null} disabled={!productForm || !editLinkTemplate} />
              <span className='vismaicon vismaicon-edit' onClick={handleEditClick}></span>
            </div>
          </div>
          {editLinkTemplate ?
            <div className="alert-infoContainer alert" role="alert">
              <div>
                <span className="vismaicon vismaicon-filled vismaicon-info" aria-hidden="true"></span>
                <strong>Information!</strong> The download link template needs to be formatted with [version] in order to work. Example, https://downloadlink.com/product_[version]_setup.exe
              </div>
            </div>
            : null}
        </div>
        <div className='col-xs-12'>
          <label className="control-label"><strong>{t("productOverview.notesAboutProduct")}</strong></label>
          <textarea className='productTextArea' disabled={!productForm} value={productForm ? productForm.data.notes : ""} onChange={(e) => onFieldChange("notes", e.target.value)} />
        </div>
        {errorMessage && <p className='text-error'>{errorMessage}</p>}
        <div className='product-controls col-xs-12'>
          <button onClick={handleSaveClick} className="btn btn-primary no-margin" disabled={!productForm}>{newProductState ? t("productOverview.create") : t("productOverview.save")}</button>
          {!newProductState && <button onClick={() => commonActions.showModal(true)} className="btn btn-danger no-margin" disabled={!productForm}>{t("productOverview.deleteProduct")}</button>}
        </div>
        {productForm && !newProductState ?
          <div className="dropzonePreview col-xs-12 margin-top">
            <div>
              <label className='control-label'><strong>{t("productOverview.addImageProduct")}</strong></label>
              <Dropzone
                multiple={false}
                onDrop={(files) => handleFileDrop(files)}
                accept="image/jpeg,image/png">
                {({ getRootProps }) => (
                  <div {...getRootProps()} className='text-center padding-top'>
                    <span className='vismaicon vismaicon-lg vismaicon-filled vismaicon-info' />
                    <h1>{t("productOverview.dropImage")}</h1>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <img src={!productForm.data.imageContent ? "http://placehold.jp/100x100.png" : `data:image/png;base64,${productForm?.data.imageContent}`} alt="..." className='ProductOverviewItemImage' />
            </div>
          </div>
          : null}
      </div>
      <ConfirmModal productName={productForm?.data && productForm.data.productName} onConfirmed={onConfirmed} confirmTextElement={<p>{t("productOverview.areYouSureDelete")} <strong>{productForm?.data.productName}({productForm?.data.sku})</strong> {t("productOverview.allOfItsVersions")}</p>} confirmTitle="Confirm Delete" />
    </>
  )
}

function mapStateToProps(state) {
  return {
    productForm: state.CommonReducer.forms && state.CommonReducer.forms.productForm,
    showModal: state.CommonReducer.showModal,
    users: state.SettingsReducer.users && state.SettingsReducer.users
  }
}

function mapDispatchToProps(dispatch) {
  return {
    productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditProductOwnerForm));