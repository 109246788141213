import React from 'react';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryTheme,
    VictoryTooltip,
    VictoryVoronoiContainer
} from 'victory';

function CollaborationVictoryBar({
    data,
    serviceName,
    year
}) {
    const lineStyle = {
        data: {
            fill: "rgb(39, 70, 185)"
        },
    }

    function getAccumulated() {
        const result = [];

        data.map(item => {
            result.push({
                x: item.x,
                y: item.accumulated,
                label: item.accumulated.toString()
            });
        });

        return result;
    }

    const labelFontSize = 9;

    if (data.length === 0)
        return null
    return (
        <VictoryChart
            // theme={VictoryTheme.grayscale} TODO: Fixa detta senare
            padding={{ top: 50, left: 40, right: 35, bottom: 35 }}
            domainPadding={{ x: 20 }}
            containerComponent={
                <VictoryVoronoiContainer
                />
            }>
            <VictoryLabel text={serviceName + " " + year} textAnchor="middle" x={225} y={20} />
            <VictoryBar name="stapeldiagram"
                style={lineStyle}
                alignment="middle"
                data={data}
                sortKey={"x"}
                labelComponent={<VictoryLabel dy={-8} style={{ fontSize: labelFontSize }} />}
                barRatio={0.3}
            />
            <VictoryLine
                data={getAccumulated()}
                labelComponent={<VictoryTooltip/>}
                interpolation="linear"
            />
            <VictoryAxis
                style={{ tickLabels: { angle: 0, fontSize: labelFontSize } }}
                dependentAxis />
            <VictoryAxis
                style={{ tickLabels: { angle: 20, fontSize: labelFontSize, padding: 10 } }} />

        </VictoryChart>
    );
}

export default CollaborationVictoryBar