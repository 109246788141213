import React from "react";

function Column({ children, width }) {
  return (
    <div className={`col ${width ? `col-md-${width}` : ''} p-0 d-flex flex-column`} style={{ gap: "24px" }}>
      {children}
    </div>
  );
}

function Row({ children, gap = "12px" }) {
  
  Row.Column = Column;

  return (
    <div className="row" style={{ gap }}>
      {children}
    </div>
  );
}

Grid.Row = Row;
Grid.Row.Column = Column;

export default function Grid({ children, gap = "12px" }) {
  return (
    <div className="p-0 m-0" style={{ gap }}>
      {children}
    </div>
  );
}