import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import { bindActionCreators } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import {shortcuts} from '../../Constants/shortcuts';
import {matchPath, useNavigate, useParams} from 'react-router-dom';
import Tabs from "../Tabs/Tabs";

function Shortcuts({ commonActions, customer, vbCustomer, userShortcuts }) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    let currentRoute = location.pathname;
    let currentShortcut = Object.values(shortcuts).find(shortcut => {
      if (userShortcuts?.includes(shortcut.id) && matchPath(shortcut.route,currentRoute)) {
        return shortcut;
      }
    });
    setActiveTab(currentShortcut ? currentShortcut.name : "");
  }, [location.pathname]);

  const handleFieldChange = (selectedRoute) => {
    if (selectedRoute && selectedRoute !== "") {
      const route = setRouteVariables(selectedRoute.route);
      if (selectedRoute.route !== undefined || location.pathname === route) {
        if (selectedRoute.redirect) {
          window.open(route);
        } else {
          navigate(route);
          commonActions.setOpenHeader(undefined)
        }
      }
    }
  }

  const setRouteVariables = (selectedRoute) => {
    return selectedRoute.replace(':customerNumber', customer.pathId)
      .replace(':organizationNumber', vbCustomer.organizationNumber)
      .replace(':phoneNumber', vbCustomer.phoneNumber)
  }
  
  const getShortcuts = () => userShortcuts.map(id => {
        let shortcut = shortcuts[Object.keys(shortcuts).find(key => shortcuts[key].id === id)]
        shortcut.label = t(shortcut.name);
        return shortcut;
      });

  return <Tabs tabs={getShortcuts()} onChange={handleFieldChange} initialTab={activeTab}/>
}

function mapStateToProps(state) {
  return {
    vbCustomer: state.CustomerReducer.selectedCustomer,
    customer: state.CustomerReducer.customer,
    userShortcuts: state.CommonReducer.userShortcuts
  }
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Shortcuts));
