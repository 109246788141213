import { bokaMeraActionTypes } from "../ActionTypes/bokaMeraActionTypes";

export const initialState = {
    errorOrders: []
}

export default function BokaMeraReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case bokaMeraActionTypes.GET_INVOICE_DATES:
            newState = { invoiceDates: action.data }
            break;
        case bokaMeraActionTypes.GET_ORDER_ERRORS:

            let list = [...state.errorOrders]

            // remove all items with same invoice date to then be replaced with new items from action data with same invoice date
            if(action.data.length) {
                const invoiceDate = action.data[0].invoiceDate;
                list = list.filter(e => e.invoiceDate !== invoiceDate);
                list = list.concat(action.data);
            }

            newState = { errorOrders:  list }
            break;
        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}