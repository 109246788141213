import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'

import AttendedEducationsList from '../../Components/Customer/AttendedEducationsList'
import { useParams } from "react-router-dom";
import {NoInformation} from "../../Components/Panel";

const AttendedEducationsContainer = ({ customerActions, attendedEducations }) => {

    const { customerNumber } = useParams();

    useEffect(() => {
        customerActions.getCustomerAttendedEducations(customerNumber);
    }, []);

    if (!attendedEducations || !attendedEducations[0]) {
      return <NoInformation />
    }

    return <AttendedEducationsList attendedEducations={attendedEducations} />
};

function mapStateToProps(state) {
    return {
        attendedEducations: state.CustomerReducer.attendedEducations,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttendedEducationsContainer));

