export const VossVersionStatus = {
    Draft: 0,
    Staged: 1,
    Published: 2,
    Archived: 3,
    Discarded: 4
}

export const VossVersionStatusText = {
    [VossVersionStatus.Draft]: "Draft",
    [VossVersionStatus.Staged]: "Staged",
    [VossVersionStatus.Published]: "Published",
    [VossVersionStatus.Archived]: "Archived",
    [VossVersionStatus.Discarded]: "Discarded"
}