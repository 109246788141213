import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next';
import Panel from "../../Components/Panel";
import {DefaultCard} from "../../Components/Card/Card";

class RelatedCustomersContainer extends Component {
    componentDidMount() {
        let vbCustomer = this.props.vbCustomer;
        this.props.customerActions.getRelatedCustomers(vbCustomer.customerNumber);
    }

    getDropdownItemName(list, value) {
        const { t } = this.props;
        if (!list) {
            return t("relatedcustomerscontainer.error");
        }

        list = list.filter(item => item.id === value);
        if (list.length === 1)
            return list[0].name;
        return t("relatedcustomerscontainer.missingvalue");
    }

    render() {
        const { relatedCustomers, dropdownlists, t } = this.props;

        if(!relatedCustomers) {
            return null;
        }
        
        const customerStatuses = dropdownlists && dropdownlists.CustomerStatus;
        const customerGroups = dropdownlists && dropdownlists.CustomerGroup;

        return <DefaultCard title={t('relatedcustomerscontainer.relatedcustomer')} isNoData={!relatedCustomers.length}>
                <div className="col-xs-12">
                    <table className="table table-stripe">
                        <thead>
                            <tr>
                                <th className="text-left">{t("relatedcustomerscontainer.customernumber")}</th>
                                <th className="text-left">{t("relatedcustomerscontainer.name")}</th>
                                <th className="text-left">{t("relatedcustomerscontainer.city")}</th>
                                <th className="text-left">{t("relatedcustomerscontainer.status")}</th>
                                <th className="text-left">{t("relatedcustomerscontainer.group")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            { relatedCustomers?.map((relatedCustomer, index) => {
                                return <tr key={index}>
                                            <td><a href={routes.customerMain(relatedCustomer.customerNumber)} target="_blank" rel="noopener noreferrer">{relatedCustomer.customerNumber}</a></td>
                                            <td>{relatedCustomer.name}</td>
                                            <td>{relatedCustomer.postalCity}</td>
                                            <td>{this.getDropdownItemName(customerStatuses, relatedCustomer.status)}</td>
                                            <td>{relatedCustomer.group !== 0 && `${this.getDropdownItemName(customerGroups, relatedCustomer.group)} / ${relatedCustomer.group}`}</td>
                                        </tr>
                            })}
                        </tbody>
                    </table>
                </div>
        </DefaultCard>
    }
}

function mapStateToProps(state) {
    return {
        relatedCustomers: state.CustomerReducer.relatedCustomers,
        dropdownlists: state.CommonReducer.dropdownlists,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
   return {
       customerActions : bindActionCreators(customerActions, dispatch)
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RelatedCustomersContainer));