export const settingsActionTypes = {
    GET_GUIDED_SELLING_RULES: "GET_GUIDED_SELLING_RULES",
    GET_GUIDED_SELLING_RULE: "GET_GUIDED_SELLING_RULE",
    STORE_EMAIL_TEMPLATES: "STORE_EMAIL_TEMPLATES",
    STORE_EMAIL_TEMPLATE: "STORE_EMAIL_TEMPLATE",
    STORE_EMAIL_TEMPLATE_APPROVAL: "STORE_EMAIL_TEMPLATE_APPROVAL",
    GET_WEBINARS: "GET_WEBINARS",
    STORE_EDIT_ROLE_MODEL: "STORE_EDIT_ROLE_MODEL",
    STORE_USER_ROLES: "STORE_USER_ROLES",
    STORE_WEBINAR: "STORE_WEBINAR",
    STORE_CUSTOMER_AND_INFO_TYPES: "STORE_CUSTOMER_AND_INFO_TYPES",
    GET_COMPANY_EXPENSE_PARTNERS: "GET_COMPANY_EXPENSE_PARTNERS",
    STORE_COMPANY_EXPENSE_PARTNER: "STORE_COMPANY_EXPENSE_PARTNER",
    CHANGE_FORM_VALUE_FOR_CUSTOMERINFOTYPES: "CHANGE_FORM_VALUE_FOR_CUSTOMERINFOTYPES",
    GET_COMPENSATION_TICKETS: "GET_COMPENSATION_TICKETS",
    GET_AO_CUSTOMERS: "GET_AO_CUSTOMERS",
    STORE_USERS: "STORE_USERS",
    GET_EPI_PARTNERS: "GET_EPI_PARTNERS",
    STORE_SELECTED_EPI_PARTNER: "STORE_SELECTED_EPI_PARTNER",
    SEARCH_EPI_PARTNERS: "SEARCH_EPI_PARTNERS",
    GET_ALL_PARTNER_COMPETENCES: "GET_ALL_PARTNER_COMPETENCES",
    GET_ALL_PARTNER_BUSINESSES: "GET_ALL_PARTNER_BUSINESSES",
    STORE_SELECTED_TICKET: "STORE_SELECTED_TICKET",
    STORE_SELECTED_AO_CUSTOMER: "STORE_SELECTED_AO_CUSTOMER",
    STORE_DUPLICATE_AO_CUSTOMER: "STORE_DUPLICATE_AO_CUSTOMER",
    ASSIGN_TEMPLATE_RESPONSIBLE: "ASSIGN_TEMPLATE_RESPONSIBLE",
    SEARCH_CLOSED_COMPENSATION_TICKETS: "SEARCH_CLOSED_COMPENSATION_TICKET",
    SEARCH_AO_CUSTOMERS: "SEARCH_AO_CUSTOMERS",
    GET_BUDGET_BY_USER: "GET_BUDGET_BY_USER",
    GET_ALL_BUDGETS_BY_MONTH_YEAR: "GET_ALL_BUDGETS_BY_MONTH_YEAR",
    GET_USER: "GET_USER",
    STORE_BUDGET_USERS: "STORE_BUDGET_USERS",
    STORE_SERVICE_ADDRESSES: "STORE_SERVICE_ADDRESSES",
    STORE_BUDGET_TEAMS: "STORE_BUDGET_TEAMS",
    GET_BUDGET_BY_TEAM: "GET_BUDGET_BY_TEAM",
    GET_BUDGET_USER: "GET_BUDGET_USER",
    GET_BUDGET_TEAM: "GET_BUDGET_TEAM",
    STORE_RELEASE_VERSION_FORM: "STORE_RELEASE_VERSION_FORM",
    GET_ALL_RELEASES: "GET_ALL_RELEASES",
    GET_ELOQUA_FIELDS: "GET_ELOQUA_FIELDS",
    GET_ELOQUA_DEFINITIONS: "GET_ELOQUA_DEFINITIONS",
    GET_ELOQUA_SYNC_LOGS: "GET_ELOQUA_SYNC_LOGS",
    UPDATE_ELOQUA_FIELD: "UPDATE_ELOQUA_FIELD",
    GET_WORKER_JOBS: "GET_WORKER_JOBS",
    STORE_ALL_FEATURE_FLAGS: "STORE_ALL_FEATURE_FLAGS",
    STORE_ENABLED_FEATURE_FLAGS: "STORE_ENABLED_FEATURE_FLAGS",
    STORE_INVOICE_PROCESSINGS: "STORE_INVOICE_PROCESSINGS",
    STORE_INVOICE_PROCESSING_PARAMETERS: "STORE_INVOICE_PROCESSING_PARAMETERS"
}