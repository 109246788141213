import React from 'react';
import { connect } from 'react-redux';
import * as calcTypes from '../../Constants/priceCalculationTypes';
import Grid from "../../Components/Grid/Grid";
import { VonProductOptionsCard } from '../../Components/OrderPlacement/ProductComponents/Von/VonProductOptionsCard';
import { VonProductSummaryCard } from '../../Components/OrderPlacement/ProductComponents/Von/VonProductSummaryCard';

function VonProductContainer({...props}){

  return (
    <>
      <Grid.Row>
        <Grid.Row.Column width={4}>
          <VonProductOptionsCard calcType={calcTypes.priceCalculationTypes.order} {...props}/>
        </Grid.Row.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Row.Column>
          <VonProductSummaryCard {...props} priceCalcType={calcTypes.priceCalculationTypes.order} />
        </Grid.Row.Column>
      </Grid.Row>
    </>
  );
};

export default VonProductContainer;