import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import Modal from '../../Components/Modal'
import BokaMeraForm from '../Customer/BokaMeraForm'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'

class BokaMeraBookModal extends Component {

    componentDidMount() {
        this.props.customerActions.startBokaMeraModal(this.createModel())
    }
    componentWillUnmount(){
        this.props.customerActions.clearCustomFields(this.props.customFields);
        this.props.customerActions.clearEmployees();
    }

    createModel() {
        const { vbCustomer, user, serviceId, fromDate, toDate, customFields, service} = this.props;
        
        if (vbCustomer.hasVonAccount) {
            this.props.customerActions.getEmployees(vbCustomer.customerNumber);
        }

        customFields.forEach(field => {
            if(field.name.toLowerCase() === "kundnummer")
                field.value = vbCustomer.customerNumber
            else if(field.name.toLowerCase().includes("sälj"))
                field.value = user.salesNumber
        })

        return {
            contactPerson: "",
            email: "",
            phone: "",
            serviceId: serviceId,
            service: service,
            fromDate: fromDate,
            toDate: toDate,
            customFields: customFields,
            informedCustomer: false ,
            price: service.prices.length > 0 ? service.prices[0].priceText : ''
        }
    }

    onSaveClick = () => {
        const { customerActions, bokaMeraForm, onComplete } = this.props;
        
        customerActions.saveBokaMeraBooking(bokaMeraForm, () => onComplete())
    }

    onCancelClick = () => {
        const { onClose, commonActions, bokaMeraForm } = this.props;

        commonActions.cancelFormEditing(bokaMeraForm, onClose)
    }

    onEmployeeSelected = (selected, employees) => {
        const { commonActions, bokaMeraForm } = this.props;

        let employee = employees.find(s => s.name === selected)

        if (employee) {
            commonActions.changeMultiFormField(bokaMeraForm.id, 'contactPerson', employee.name);
            commonActions.changeMultiFormField(bokaMeraForm.id, 'email', employee.email);
        }
    }

    onFieldChange = (field, value) => {
        const { commonActions, bokaMeraForm } = this.props;

         if(field === "informedCustomer")
             value = !bokaMeraForm.data["informedCustomer"];

        commonActions.changeMultiFormField(bokaMeraForm.id, field, value);
    }
    
    render() {

        const {bokaMeraForm, availableResources, employees, t} = this.props
        const bokaMera = bokaMeraForm && bokaMeraForm.data
        
        if(!bokaMera) {
            return null;
        }

        const modalProps = {
            title: t('bokamerabookmodal.createbooking'),
            mainButtonTitle: t('bokamerabookmodal.create'),
            stateful: true,
            open: true,
            onSaveClick: this.onSaveClick,
            onCancelClick: this.onCancelClick,
        }

         const formProps = {
             booking: bokaMera,
             readonly: true,
             onFieldChange: this.onFieldChange,
             onEmployeeSelected: this.onEmployeeSelected,
             availableResources: availableResources,
             employees: employees
         }

        return  <Modal {...modalProps}>
                    {<BokaMeraForm {...formProps} />}
                </Modal>
    }
}

function mapStateToProps(state) {
    return {
        bokaMeraForm: state.CommonReducer.forms && state.CommonReducer.forms.bokaMera,
        service: state.CustomerReducer.bokaMeraService,
        availableResources: state.CustomerReducer.availableResources,
        serviceId: state.CustomerReducer.bokaMeraServiceId,
        fromDate: state.CustomerReducer.bokaMeraFromDate,
        toDate: state.CustomerReducer.bokaMeraToDate,
        customFields: state.CustomerReducer.bokaMeraCustomFields,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        user: state.CommonReducer.user,
        employees: state.CustomerReducer.employees
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BokaMeraBookModal));