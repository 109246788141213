import React, { Fragment, useEffect, useState } from 'react';
import { VossVersionStatusText } from "../../../Constants/VOSS/vossVersionStatus";
import helpers from "../../../Utils/helpers";
import SkynetIcon from '../../Fundamentals/SkynetIcon';
import { useNavigate } from 'react-router-dom';
import routes from '../../../Constants/routes';

function DiscountAgreementsTable({ discountAgreements, discountAgreementActions, discountAgreementVersions, packages, searchTerm, versionsLoading, readOnly }) {
    const [activePackageIndices, setActivePackageIndices] = useState([]);
    const [activeRow, setActiveRow] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!searchTerm) {
            setActivePackageIndices([]);
            setActiveRow(null);
        }else{
            const matchedPackageIndices = packages.reduce((indices, pkg, index) => {
                const hasMatchingDiscounts = discountAgreements.some(
                    discount => discount.packageId === pkg.id && discount.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                if (hasMatchingDiscounts) indices.push(index);
                return indices;
            }, []);
            setActivePackageIndices(matchedPackageIndices);
        }
        
    }, [searchTerm]);

    const handlePackageClick = (index, hasDiscounts) => {
        if (!hasDiscounts) return;
        setActivePackageIndices(prevIndices =>
            prevIndices.includes(index) ? prevIndices.filter(i => i !== index) : [...prevIndices, index]
        );
    };

    const handleRowClick = (discountAgreementId) => {
        setActiveRow(prevActiveRow => (prevActiveRow === discountAgreementId ? null : discountAgreementId));
        discountAgreementActions.getDiscountAgreementVersions(discountAgreementId);
    };

    const navigateToEdit = (discountId, versionId) => {
        const params = new URLSearchParams();
        params.append("DiscountId", discountId);
        versionId && params.append("VersionId", versionId);
        navigate(routes.editDiscount + "?" + params.toString());
    };

    const navigateToCreate = (packageId) => {
        const params = new URLSearchParams();
        params.append("PackageId", packageId);
        navigate(routes.createNewDiscount + "?" + params.toString());
    };

    if (!packages || !discountAgreements) return null;

    const filteredDiscountAgreements = searchTerm
        ? discountAgreements.filter(discount => discount.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : discountAgreements;

    const highlightedText = (text) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? <span key={index} className="bold">{part}</span> : part
        );
    };

    const noDiscountsFound = filteredDiscountAgreements.length === 0 && searchTerm;

    return (
        <table className="table table-stripe skynet-table-no-divider table-active">
            <thead>
                <tr>
                    <th colSpan={2}>Package</th>
                    <th>Valid from</th>
                    <th>Valid to</th>
                    <th>Status</th>
                    <th colSpan={1} className='text-center'>Action</th>
                </tr>
            </thead>
            <tbody>
                {packages.map((packageItem, packageIndex) => {
                    const packageDiscounts = filteredDiscountAgreements.filter(discount => discount.packageId === packageItem.id);
                    const hasDiscounts = packageDiscounts.length > 0;
                    const isActivePackage = activePackageIndices.includes(packageIndex);
                    if (searchTerm && !hasDiscounts) return null;
                    return (
                        <Fragment key={packageIndex}>
                            <tr onClick={() => handlePackageClick(packageIndex, hasDiscounts)}>
                                <td colSpan={5} className={isActivePackage ? 'bold' : ''}>
                                    <span className={`caret caret-lg ${isActivePackage ? 'open' : ''} ${!hasDiscounts ? 'caret-disabled' : ''}`}></span>
                                    <div className="ml-24 d-inline-block">{packageItem.name}</div>
                                </td>
                                <td className='text-center'>
                                    {!readOnly && <SkynetIcon icon={'vismaicon-add-circle'}
                                        onClick={(e) => { e.stopPropagation(); navigateToCreate(packageItem.id); }}
                                    />}
                                </td>
                            </tr>
                            {isActivePackage && hasDiscounts && (
                                packageDiscounts.map((discountAgreement) => (
                                    <Fragment key={discountAgreement.id}>
                                        <tr onClick={() => handleRowClick(discountAgreement.id)} className={activeRow === discountAgreement.id ? 'active' : ''}>
                                            <td></td>
                                            <td width={800} className={activeRow === discountAgreement.id ? "bold" : ""}>
                                                <span className={`caret caret-lg ${activeRow === discountAgreement.id ? 'open' : ''}`}></span>
                                                <div className="ml-24 d-inline-block">
                                                    {highlightedText(discountAgreement.name)}
                                                </div>
                                            </td>
                                            <td>{helpers.formatDate(discountAgreement.validFrom)}</td>
                                            <td width={200}>{discountAgreement.validTo ? helpers.formatDate(discountAgreement.validTo) : ''}</td>
                                            <td width={200}>{VossVersionStatusText[discountAgreement.status]}</td>
                                            <td className='text-center'>
                                                <SkynetIcon icon="vismaicon-edit" onClick={() => navigateToEdit(discountAgreement.id, discountAgreement.versionId)} />
                                            </td>
                                        </tr>
                                        {activeRow === discountAgreement.id && (
                                            <>
                                                {versionsLoading ? <tr>
                                                    <td colSpan={8}>
                                                        <div className="spinner spinner-default-green m-auto d-block" />
                                                    </td>
                                                </tr> : discountAgreementVersions && discountAgreementVersions.versions.filter(v => v.versionId !== discountAgreement.versionId).length > 0 ? (
                                                    discountAgreementVersions.versions.filter(version => version.versionId !== discountAgreement.versionId).map((version, versionIndex) => (
                                                        <tr key={versionIndex}>
                                                            <td colSpan={2}></td>
                                                            <td colSpan={2}><span className='bold'>Created:</span> {helpers.formatDate(version.created)}</td>
                                                            <td>{VossVersionStatusText[version.status]}</td>
                                                            <td className='text-center'>
                                                                <SkynetIcon icon="vismaicon-edit" onClick={() => navigateToEdit(discountAgreement.id, version.versionId)} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={6}>No versions available</td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </Fragment>
                                ))
                            )}
                        </Fragment>
                    );
                })}
                {noDiscountsFound && (
                    <tr>
                        <td colSpan={6}><em>No discounts found</em></td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default DiscountAgreementsTable;