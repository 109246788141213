import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import Modal from '../Modal'
import EmailTemplate from '../EmailTemplate'

import commonActions from '../../Actions/commonActions'

import DropDown from '../../Components/DropDown'

class SendDemoEmailModal extends Component {


    componentDidMount() {
        const {commonActions, selectedCustomerAndType} = this.props;
        commonActions.getSendDemoEmailTemplate(selectedCustomerAndType && selectedCustomerAndType.customerNumber, 1);
    }

    onFieldChange = (value) => {
        const {commonActions, selectedCustomerAndType} = this.props;
        commonActions.getSendDemoEmailTemplate(selectedCustomerAndType && selectedCustomerAndType.customerNumber, value);
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("sendDemoEmail", field, value);
    }

    handleClickSave = () => {
        const {form, selectedCustomerAndType, commonActions, demoProduct} = this.props;

        commonActions.sendDemoEmail(form, demoProduct, selectedCustomerAndType && selectedCustomerAndType.type, selectedCustomerAndType && selectedCustomerAndType.typeId, this.handleClickClose)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("sendDemoEmail", onClose)
    }

    getReceiverEmails()
    {
        const {emailForm} = this.props;
        return emailForm && emailForm.data && emailForm.data.defaultToAddresses;
    }

    render() {
        const { form, dropdownlists, demoProduct, t } = this.props;
        const demoProducts = dropdownlists && dropdownlists.DemoProducts;
        const data = form && form.data;
        var productId = demoProduct;     

        if (!form) {
            return null;
        }

        if(!demoProduct)
            productId = 1;

        const modalProps = {
            title: t('senddemoemailmodal.senddemo'),
            mainButtonTitle:  t('senddemoemailmodal.mainbuttontitle'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <form className="form-horizontal">
                    <DropDown label={t('senddemoemailmodal.product')} onChange={this.onFieldChange.bind(this)} list={demoProducts} value={productId} />
                   
                    <EmailTemplate data={form.data} templates={null} onChange={this.handleFieldChange}
                        onSaveClick={this.handleClickSave} onCancelClick={this.handleClickClose} onTemplateChange={this.onTemplateChange}
                        senderEmails={form.data.defaultSenderAddresses} receiverEmails={this.getReceiverEmails()} onSenderChange={this.handleFieldChange} sendDemo={true}/>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCustomerAndType: state.CommonReducer.selectedCustomerAndType,
        form: state.CommonReducer.forms && state.CommonReducer.forms.sendDemoEmail,
        dropdownlists: state.CommonReducer.dropdownlists,
        demoProduct: state.CommonReducer.selectedDemoProductId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendDemoEmailModal));
