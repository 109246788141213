import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import CustomerCategoryTable from "../../Components/Discounts/CustomerCategoryList";
import { bindActionCreators } from 'redux';
import * as formValidationRules from '../../Constants/formValidationRules'
import commonActions from "../../Actions/commonActions";
import TextInput from "../../Components/FormComponents/Input/TextInput";
import DateTimeInput from "../../Components/FormComponents/Input/DateTimeInput";
import helpers from "../../Utils/helpers";

function CustomerCategoryContainer({ commonActions, onChangeCustomerCategories, customerCategoryForm, customerCategories }) {

    useEffect(() => {
        startCustomerCategoryFormEditing();
    }, [])

    const startCustomerCategoryFormEditing = () => {
        commonActions.startFormEditing("newCustomerCategory", {
            firstIncludedCustomerCategory: null,
            lastIncludedCustomerCategory: null,
            validFromDate: "",
            validToDate: "",
            notify: false,
            notifyEmailAddress: ""
        },
            formValidationRules.CreateCustomerCategory)
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField("newCustomerCategory", field, value);
    };

    const onNotifyChange = (value) => {

        if (!value)
        onFieldChange("notifyEmailAddress", "");

        onFieldChange("notify", value);
    }

    const onSave = () => {

        const validationResult = helpers.validateForm(customerCategoryForm, true);

        if (validationResult) {
            commonActions.setFieldErrorFlags(customerCategoryForm.id, validationResult.fieldErrorFlags)
        } else {
            const newCustomerCategory = {
                firstIncludedCustomerCategory: data.firstIncludedCustomerCategory,
                lastIncludedCustomerCategory: data.lastIncludedCustomerCategory,
                validFromDate: data.validFromDate,
                validToDate: data.validToDate,
                notify: data.notify,
                notifyEmailAddress: data.notifyEmailAddress,
            };
            onChangeCustomerCategories("customerCategories", [newCustomerCategory, ...customerCategories]);
            commonActions.cancelFormEditing("newCustomerCategory");
            startCustomerCategoryFormEditing();
        }
    };

    const onDelete = (index) => {
        const updatedCustomerCategories = [...customerCategories];
        updatedCustomerCategories.splice(index, 1);
        onChangeCustomerCategories("customerCategories", updatedCustomerCategories);
    };

    const data = customerCategoryForm ? customerCategoryForm.data : {};

    return (
        <div>
            <div className='d-flex flex-row'>
                <div className="col-xs-5 mr-64">
                    <div className='mb-32'>
                        <h4 className="panel-title">Lägg till kundkategori</h4>
                    </div>
                    <div className="mb-12 form-group">
                        <label>Kundkategori - sifferbeteckning från Visma Business</label>
                        <div className='mb-0 form-group d-flex'>
                            <div className="flex-grow-1">
                                <TextInput
                                    value={data.firstIncludedCustomerCategory}
                                    field="firstIncludedCustomerCategory"
                                    fieldErrorFlags={customerCategoryForm?.fieldErrorFlags}
                                    hideErrorIcon={true}
                                    onChange={(e) => onFieldChange('firstIncludedCustomerCategory', e.target.value)}
                                />
                            </div>
                            <p className="mr-16 ml-16">_</p>
                            <div className="flex-grow-1">
                                <TextInput
                                    value={data.lastIncludedCustomerCategory}
                                    field="lastIncludedCustomerCategory"
                                    fieldErrorFlags={customerCategoryForm?.fieldErrorFlags}
                                    onChange={(e) => onFieldChange('lastIncludedCustomerCategory', e.target.value)}
                                />
                            </div>
                        </div>
                        <label>*Giltighetstid</label>
                        <div className='mb-0 form-group d-flex'>
                            <div className="flex-grow-1">
                                <DateTimeInput
                                    dateFormat={"YYYY-MM"}
                                    showTime={false}
                                    field="validFromDate"
                                    closeOnSelect={true}
                                    fieldErrorFlags={customerCategoryForm?.fieldErrorFlags}
                                    hideErrorIcon={true}
                                    onChange={(value) => onFieldChange('validFromDate', value)}
                                    value={data.validFromDate}
                                />
                            </div>
                            <p className="mr-16 ml-16">_</p>
                            <div className="flex-grow-1">
                                <DateTimeInput
                                    dateFormat={"YYYY-MM"}
                                    showTime={false}
                                    field="validToDate"
                                    closeOnSelect={true}
                                    fieldErrorFlags={customerCategoryForm?.fieldErrorFlags}
                                    onChange={(value) => onFieldChange('validToDate', value)}
                                    value={data.validToDate}
                                />
                            </div>
                        </div>
                        <div className='mb-12 mt-12 form-group d-flex'>
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id='checkbox'
                                    name="opt1"
                                    checked={data.notify}
                                    onChange={(e) => onNotifyChange(e.target.checked)}
                                />
                                <label htmlFor={'checkbox'}>
                                    Avisering
                                </label>
                            </div>
                        </div>
                        <label>E-postadress för avisering</label>
                        <div className='form-group'>
                            <TextInput
                                value={data.notifyEmailAddress}
                                field="notifyEmailAddress"
                                fieldErrorFlags={customerCategoryForm?.fieldErrorFlags}
                                onChange={(e) => onFieldChange('notifyEmailAddress', e.target.value)}
                                disabled={!data.notify}
                            />
                        </div>
                    </div>
                    <button type="button" className="btn btn-default" onClick={() => onSave()}>Spara</button>
                </div>
                <div className="col-xs-5">
                    <div className='mb-32'>
                        <h4 className="panel-title">Tillagda kundkategorier</h4>
                    </div>
                    <CustomerCategoryTable customerCategories={customerCategories} onDelete={onDelete} />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        customerCategoryForm: state.CommonReducer.forms && state.CommonReducer.forms.newCustomerCategory
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerCategoryContainer));