import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom'
import './../Layout/SearchLayout.css'
import SearchContainer from '../../Containers/SearchContainer'
import DashboardContainer from "../../Containers/DashboardContainer"

const SearchLayout = () => (
  <div className='searchWrapper'>
    <Routes>
      <Route index element={<SearchContainer/>} />
      <Route path="/:input/" element={<SearchContainer/>} />
    </Routes>
    <DashboardContainer/>
  </div>
);

export default SearchLayout;