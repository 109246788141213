import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../Actions/commonActions'
import { withTranslation } from 'react-i18next';

import Loader from '../Components/Loader'
import {FormEditor} from '../Components/Editor'
import TextInput from '../Components/TextInput'
import DropDown from '../Components/DropDown'

function NonCustomerTemplateContainer({ templates, commonActions, t, toggleSetting, nonCustomerMyTemplate }) {

    useEffect(() => {
        commonActions.loadDropdownList("NonCustomerTemplates");
        createBlankFormData();
    }, [])

    const onSaveClick = () => {
        commonActions.saveMyNonCustomerTemplate(nonCustomerMyTemplate, () => toggleSetting());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(nonCustomerMyTemplate.id, () => toggleSetting());
    }

    const onDeleteClick = () => {
        commonActions.deleteMyNonCustomerTemplate(nonCustomerMyTemplate.data.templateId, () => toggleSetting());
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(nonCustomerMyTemplate.id, field, value);
    }

    const onTemplateSelect = (id) => {
        if (id > 0) {
            commonActions.getMyNonCustomerTemplate(id);
        }
        else {
            createBlankFormData();
        }
    }

    const createBlankFormData = () => {
        commonActions.startFormEditing("nonCustomerMyTemplate", { name: "", body: "", subject: "" })
    }

    if (!templates || !nonCustomerMyTemplate) {
        return null;
    }
    return (
        <form className="form-horizontal col-xs-10">
            <div className="margin-bottom">
                <DropDown label={t('myappointmentstemplatescontainer.template')} onChange={onTemplateSelect} list={templates} blankOption={true} />
                <TextInput value={nonCustomerMyTemplate.data.name} onChange={(value) => onFieldChange("name", value)} label={t('myappointmentstemplatescontainer.templatename')} />
                <br />
                <TextInput label={t('myappointmentstemplatescontainer.subject')} value={nonCustomerMyTemplate.data.subject} onChange={(value) => onFieldChange("subject", value)} />
                <FormEditor label={t('myappointmentstemplatescontainer.text')} onChange={(value) => onFieldChange("body", value)} value={nonCustomerMyTemplate.data.body} />
            </div>
            <div className="clearfix"></div>
            <div>
                <div className=' className="form-group padding-top no-left-margin btn-toolbar appointmentActions"'>
                    <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>
                        {nonCustomerMyTemplate.data.templateId > 0 ? t('myappointmentstemplatescontainer.update') : t('myappointmentstemplatescontainer.createnew')}</button>
                    <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('myappointmentstemplatescontainer.cancel')}</button>
                </div>
                {nonCustomerMyTemplate.data.templateId > 0 &&
                    <div>
                        <button type="button" className="btn btn-default pull-right" onClick={() => onDeleteClick()}>{t('myappointmentstemplatescontainer.delete')}</button>
                    </div>
                }
                <Loader />
            </div>
        </form>
    )
}

function mapStateToProps(state) {
    return {
        templates: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.NonCustomerTemplates,
        nonCustomerMyTemplate: state.CommonReducer.forms && state.CommonReducer.forms.nonCustomerMyTemplate,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NonCustomerTemplateContainer));