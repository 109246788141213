import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import leadActions from '../../Actions/leadActions'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next';

class leadCustomers extends Component {


    componentDidMount(){
        const {leadActions, lead} = this.props;

        this.props.leadActions.clearCustomersByEmail();
        leadActions.searchCustomersByEmail(lead.email)
    }

    componentWillUnmount(){
        //this.props.leadActions.clearCustomersByEmail();
    }

    renderNoDataFound() {
        const {t} = this.props
        return (<p>{t('leadcustomers.noentriesfound')}</p>);
    }

    onCustomerClick = (customerNumber) => {

        const url = routes.customerMain(customerNumber);
        window.open(url, "_blank"); 
    }

    render() {

        const {customers, height, t} = this.props
     
        if(!customers)
            return null;

        return <>
            <div className="panel panel-default" style={{height: height}}>
                <div className="panel-heading">{t('leadcustomers.customersuggestions')}</div>
                <div className="panel-body" style={{height: "calc(100% - 48px)"}}>
                    
                    {!customers || customers.length === 0 && this.renderNoDataFound()}
                    {customers.length > 0  && <>
                    <div className="table-responsive" style={{height: "100%"}}>
                        <table className="table table-stripe table-hover">
                            <thead>
                                <tr>
                                    <th>{t('leadcustomers.organizationnumber')}</th>
                                    <th>{t('leadcustomers.customernumber')}</th>
                                    <th>{t('leadcustomers.name')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer, index) => {

                                    return <tr className="cursorpointer" key={index} onClick={() => this.onCustomerClick(customer.customerNumber)}>
                                        <td>{customer.organizationNumber}</td>
                                        <td>{customer.customerNumber}</td>
                                        <td>{customer.name}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    </>}

                    
                </div>
            </div>
        </>
        }
    }

function mapStateToProps(state) {
    return {
        customers: state.LeadsReducer.leadCustomers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(leadCustomers));