import React from 'react';

const OrderConfirmationTable = ({label, data, onClick}) => {

    if(!data) {
        return null;
    }

    return  <div>
                <h3>{label}</h3>
                <table className="table table-stripe table-hover table-hover-custom">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Text</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    { data.map((item, index) => {
                    return <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.body.substr(0, 100)}...</td>
                                <td><a onClick={() => onClick(item)}>Edit</a></td>
                            </tr>
                }) }
                    </tbody>
                </table>
            </div>

}

export default OrderConfirmationTable;