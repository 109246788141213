import { bindActionCreators } from "redux";
import subscriptionActions from "../../../../Actions/Voss/subscriptionActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import packageActions from "../../../../Actions/Voss/packageActions";
import commonActions from "../../../../Actions/commonActions";
import { EditSubscriptionPlanContainer } from "./EditSubscriptionPlanContainer";

function CustomerSubscriptionEditContainer({ ...props }) {

  const { subscriptionActions, packageActions, commonActions, subscription } = props;
  const [searchParams] = useSearchParams();
  const subscriptionId = searchParams.get("SubscriptionId")

  useEffect(() => {
    subscriptionActions.getSubscription(subscriptionId);
    subscriptionActions.initializeSubscriptionEditForms(subscriptionId);
  }, [])

  useEffect(() => {
    if (subscription)
      packageActions.getPackage(subscription.package.id);
  }, [subscription])

  const onChange = (form, value, key) => {
    commonActions.changeMultiFormField(form, key, value);
  }

  if (!subscription) return null;

  return <div>
    <EditSubscriptionPlanContainer onChange={onChange} {...props} />
  </div>
}

function mapDispatchToProps(dispatch) {
  return {
    subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
    packageActions: bindActionCreators(packageActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    subscription: state.SubscriptionReducer.subscription,
    subscriptionPackage: state.PackageReducer.package,
    planForm: state.CommonReducer.forms && state.CommonReducer.forms.changeSubscriptionPlan
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerSubscriptionEditContainer);