import React, {useState} from 'react';
import './Input.css'
import SkynetCopy from "../../Fundamentals/SkynetCopy";
import PhoneLink from "../../PhoneLink";
export function TableInput({ label, value, readOnly, onClick }) {
    const attributes = {
        value: value,
        readOnly: readOnly,
        className: "skynet-table-input"
    }

    return <Input {...attributes} />
}

export function FormInput({ type, label, value, icon, readOnly, onChange, placeholder, disabled, required = false }) {
    const [toggleCopy, setToggleCopy] = useState();
    let className = readOnly ? "form-control skynet-input" : "form-control rounded-8 skynet-form-input"

    const attributes = {
        readOnly: readOnly,
        className: className,
        id: value,
        placeholder,
        disabled
    }
    function onClick() {
        setToggleCopy(!toggleCopy);
    }

    return (<div className={`form-control skynet-form-input${required ? " required" :""}`}>
            {label && <label htmlFor={value}>{label}</label>}
            <Input type={type} {...attributes} value={value} onChange={onChange} onClick={readOnly && onClick} placeholder={placeholder}/>
            {readOnly &&
                <SkynetCopy label={label} value={value} classList={`skynet-input-icon${icon ? " mr-32 skynet-input-divider" :""}`} toggleCopy={toggleCopy}/>}
            {icon && (type === 'tel' ?
                <PhoneLink phoneNumber={value} classList="skynet-input-icon"/>
                :
                <span className={`vismaicon vismaicon-sm skynet-input-icon ${icon}`}/>)}
        </div>
    );
}

// Base Input class. Extend and wrap this component in a new component and add your own logic there.
function Input({ type, value, onChange, onClick, ...props}) {

    let inputProps = {
        onClick: (e) => onClick && onClick(e.target.value),
        value: value || '',
        disabled: props.disabled,
        readOnly: props.readOnly,
        placeholder: props.placeholder,
        className: props.className ? props.className : "skynet-input",
    };

    const finalProps = Object.assign({}, inputProps, props);

    switch(type) {
        //case 'select':
            //return <TableDropDown {...finalProps} />
        case 'textarea':
            return <textarea {...finalProps} />
        default:
            return <input value={value} type={type ? type : "text"} {...finalProps} onChange={(e) => onChange(e.target.value)}/>
    }
 }