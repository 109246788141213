import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import DateTimeInput from '../../Components/DateTimeInput'
import routes from '../../Constants/routes'
import { useParams, useNavigate } from 'react-router-dom';
import SwitchToggle from "../../Components/SwitchToggle";

function CustomerInfoTypesEditContainer({ settingsActions, customerInfoTypesForm, commonActions, t }) {

    const { customerNumber } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getCustomerInfoTypesByCustomerNumber(customerNumber, () => navigate(routes.settingsCustomerInfoTypes));
    }, [])

    const onToggleFieldChange = (arr, index, value) => {
        settingsActions.changeCustomerInfoArrayToggle(customerInfoTypesForm.id, arr, index, value)
    }
    
    const clearAoDates = () => {
        commonActions.changeMultiFormField(customerInfoTypesForm.id, 'aoPartnerDateFrom', "")
        commonActions.changeMultiFormField(customerInfoTypesForm.id, 'aoPartnerDateTo', "")
    }

    const clearGrowthProgramDates = () => {
        commonActions.changeMultiFormField(customerInfoTypesForm.id, 'growthProgramDateFrom', "")
        commonActions.changeMultiFormField(customerInfoTypesForm.id, 'growthProgramDateTo', "")
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(customerInfoTypesForm.id, field, value)
    }

    const onSaveClick = () => {
        settingsActions.updateCustomerInfoTypes(customerInfoTypesForm, customerInfoTypesForm.data.customerNumber, () => navigate(routes.settingsCustomerInfoTypes));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(customerInfoTypesForm.id, () => navigate(routes.settingsCustomerInfoTypes))
    }

    if (!customerInfoTypesForm || !customerInfoTypesForm.data) {
        return null;
    }

    const customerInfoTypes = customerInfoTypesForm.data;

    return (
        <div>
            <form className="form-horizontal col-xs-5" >
                <fieldset>
                    <legend>{t('customerinfotypeseditcontainer.customerinfotypes')}{customerInfoTypes.name} - {customerInfoTypes.customerNumber}</legend>
                    {customerInfoTypes.customerInfoFlags.map((flag, index) =>
                        <div key={index}>
                            {flag.bitValue && flag.hasCheckbox &&
                                <div className="form-group">
                                    <label className="col-xs-4 control-label">{flag.friendlyName}</label>
                                    <div className="col-xs-8">
                                        <SwitchToggle checked={flag.hasFlag} value={flag.bitValue}  label={flag.friendlyName} onChange={(value) => { onToggleFieldChange(customerInfoTypes.customerInfoFlags, index, value.target.checked) }} />
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                    <div>
                        <h3 className="infotypeheader">{t('customerinfotypeseditcontainer.aopartnerdates')}</h3>
                        <DateTimeInput label={t('customerinfotypeseditcontainer.aopartnervalidfrom')} dateFormat={true} showTime={false} value={customerInfoTypes.aoPartnerDateFrom} onChange={(value) => onFieldChange('aoPartnerDateFrom', value)} />
                        <DateTimeInput label={t('customerinfotypeseditcontainer.aopartnervalidto')} dateFormat={true} showTime={false} value={customerInfoTypes.aoPartnerDateTo} onChange={(value) => onFieldChange('aoPartnerDateTo', value)} />
                        <p>{t('customerinfotypeseditcontainer.clearaodatesinfo')}</p>
                        <button type="button" className="btn btn-default" onClick={clearAoDates.bind(this)}>{t('customerinfotypeseditcontainer.cleardates')}</button>
                    </div>
                    <div>
                        <h3 className="infotypeheader">{t('customerinfotypeseditcontainer.growthprogramdates')}</h3>
                        <DateTimeInput label={t('customerinfotypeseditcontainer.growthprogramvalidfrom')} dateFormat={true} showTime={false} value={customerInfoTypes.growthProgramDateFrom} onChange={(value) => onFieldChange('growthProgramDateFrom', value)} />
                        <DateTimeInput label={t('customerinfotypeseditcontainer.growthprogramvalidto')} dateFormat={true} showTime={false} value={customerInfoTypes.growthProgramDateTo} onChange={(value) => onFieldChange('growthProgramDateTo', value)} />
                        <p>{t('customerinfotypeseditcontainer.cleargrowthprogramdatesinfo')}</p>
                        <button type="button" className="btn btn-default" onClick={clearGrowthProgramDates.bind(this)}>{t('customerinfotypeseditcontainer.cleardates')}</button>
                    </div>
                </fieldset>
                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t('customerinfotypeseditcontainer.save')}</button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>{t('customerinfotypeseditcontainer.cancel')}</button>
                </div>
            </form>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        // customerInfoTypes: state.SettingsReducer && state.SettingsReducer.settingsCustomerInfoTypes,
        customerInfoTypesForm: state.CommonReducer.forms && state.CommonReducer.forms.editCustomerInfoTypes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerInfoTypesEditContainer));