import { httpFetch } from "../../Utils/httpUtils";
import { subscriptionActionTypes as actionTypes } from "../../ActionTypes/Voss/subscriptionActionTypes";
import commonActions from "../commonActions";

const subscriptionActions = {

    getSubscription: (id) => {
        return (dispatch) => {
            return httpFetch(`/api/subscriptions/GetSubscription/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SUBSCRIPTION, data: json });
                })
        }
    },

    initializeSubscriptionEditForms: (id) => {
        return (dispatch) => {
        dispatch(commonActions.startFormEditing("changeSubscriptionPlan", {planId: null, id}));
        }
    },

    changeSubscriptionPlan: (form, callback) => {
        const data = form.data;
        return (dispatch) => {
            return httpFetch(`/api/subscriptions/ChangeSubscriptionPlan/${data.id}/${data.planId}`, dispatch, { method: 'PUT' })
                .then(json => {
                    dispatch(commonActions.showSuccess('Subscription plan changed successfully!'));
                    callback();
                })
        }
    },

    deleteScheduledChange: (id, scheduledChangeId, callback) => {
        return (dispatch) => {
            return httpFetch(`/api/subscriptions/DeleteScheduledChange/${id}/${scheduledChangeId}`, dispatch, { method: 'DELETE' })
                .then(json => {
                    dispatch(commonActions.showSuccess('Scheduled change deleted successfully!'));
                    callback();
                })
        }
    }

}

export default subscriptionActions;
