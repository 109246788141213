import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions';
import helpers from '../../Utils/helpers';
import routes from '../../Constants/routes';

function FileBoxContainer({ fileBoxActions, fileBoxCases, fileBoxTeams }) {

    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fileBoxActions.getCases();
        fileBoxActions.getTeams();
    }, [])

    const onClick = (fileBoxCase) => {

    }

    const isFilterActive = (filter) => {
        return filters.some(item => filter === item);
    }

    const handleFilterSet = (filter) => {
        var array = [...filters]; // make a separate copy of the array
        var index = array.indexOf(filter)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(filter);
        }
        setFilters(array);
    }

    const filterCaseByTeam = (ticket) => {

        if (filters.length < 1) {
            return true
        }

        return filters.find(filter => filter === ticket.teamId);
    }

    const filterCaseBySearchTerm = (fileBoxCase) => {
        return (fileBoxCase.customerName.toLowerCase().includes(searchTerm.toLowerCase()) || fileBoxCase.email.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    const getStatus = (status) => {
        switch (status) {
            case 1:
                return "Open"
            case 2:
                return "Closed"
            case 3:
                return "InMind"
        }

    }

    if (!fileBoxCases) {
        return null;
    }


    return (

        <div className={`col-xs-12`}>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">FileBox Ticket List</h3>
                </div>
                <div className="panel-body">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <div className="col-xs-12">
                                <div className="filters">
                                    {fileBoxTeams && fileBoxTeams.map((team, index) => {
                                        return <button key={index} type="button" className={`btn btn-default ${isFilterActive(team.id) && "active"}`} onClick={() => handleFilterSet(team.id)}>{team.name}</button>
                                    })}
                                </div>
                                <input onChange={(e) => setSearchTerm(e.target.value)} type="text" className="form-control" aria-describedby="searchBlock" placeholder={"Search ticket..."}></input>
                            </div>
                        </div>
                    </div>
                    <table className="table table-stripe table-hover">
                        <thead>
                            <tr>
                                <th>{"Ticket Id"}</th>
                                <th>{"Customer number"}</th>
                                <th>{"Customer name"}</th>
                                <th>{"Owner"}</th>
                                <th>{"Assigned to person"}</th>
                                <th>{"Status"}</th>
                                <th>{"Last modified"}</th>
                                <th>{"Last modified by"}</th>
                                <th>{" "}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileBoxCases.map((fileBoxCase, index) => {
                                if (filterCaseByTeam(fileBoxCase) && filterCaseBySearchTerm(fileBoxCase)) {
                                    return <tr key={index} onClick={() => onClick(fileBoxCase)}>
                                        <td><a onClick={() => navigate(routes.fileBoxTicketDetails(fileBoxCase.ticketId))}>{"#" + fileBoxCase.ticketId}</a></td>
                                        <td>{fileBoxCase.customerNumber}</td>
                                        <td>{fileBoxCase.customerName}</td>
                                        <td>{fileBoxTeams ? fileBoxTeams.find(x => x.id === fileBoxCase.teamId).name : fileBoxCase.teamId}</td>
                                        <td>{fileBoxCase.assingedTo}</td>
                                        <td></td>
                                        <td>{helpers.formatDateAndTime(fileBoxCase.modified)}</td>
                                        <td>{fileBoxCase.modifiedBy}</td>
                                        <td>{getStatus(fileBoxCase.status)}</td>
                                    </tr>
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        fileBoxTeams: state.FileBoxReducer.fileBoxTeams,
        fileBoxCases: state.FileBoxReducer.fileBoxCases
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileBoxContainer);