export const appointmentActionTypes = {
    STORE_APPOINTMENT: "STORE_APPOINTMENT",
    STORE_MY_APPOINTMENTS: "STORE_MY_APPOINTMENTS",
    FILTERED_OPEN_APPOINTMENTS: "FILTERED_OPEN_APPOINTMENTS",
    STORE_CUSTOMER_APPOINTMENTS: "STORE_CUSTOMER_APPOINTMENTS",
    UPDATE_MY_APPOINTMENTS_COUNT: "UPDATE_MY_APPOINTMENTS_COUNT",
    STORE_SEARCH_APPOINTMENTS_RESULTS: "STORE_SEARCH_APPOINTMENTS_RESULTS",
    STORE_ACTION_HISTORY: "STORE_ACTION_HISTORY",
    STORE_MY_CUSTOMER_APPOINTMENTS: "STORE_MY_CUSTOMER_APPOINTMENTS",
    CLEAR_APPOINTMENT: "CLEAR_APPOINTMENT"
}