import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../../Actions/customerActions'
import { useTranslation, withTranslation } from 'react-i18next';
import withModalForm from '../../../Components/HOC/withModalForm'
import VonProducts from '../../../Components/Customer/VonProducts'
import VonChangePeriodModal from '../../../Components/Customer/VonChangePeriodModal'
import ProductTerminationModal from '../../../Components/Customer/ProductTerminationModal'
import SubscriptionCard from '../../../Components/Card/SubscriptionCard';
import InactiveProducts from '../../../Components/Products/InactiveProducts';

function VonProductsContainer({ vbCustomer, customerActions, terminationForm, reasons, pendingTerminationRequests,
    vonAccounts, payload, onFieldChange, onCloseClick, onOpenModal, isModalActive, dataLoading, vonProducts, ...props }) {

    useEffect(() => {

        if (vbCustomer.hasVonAccount) {
            customerActions.getVonAccounts(vbCustomer.customerNumber);
            if (!vonProducts && !dataLoading)
                customerActions.getCustomerVonProducts(vbCustomer.customerNumber);
        }

        customerActions.getPendingTerminationRequests(vbCustomer.customerNumber);

    }, [])

    const { t } = useTranslation();

    const terminationModalAttr = {
        form: terminationForm,
        reasons: reasons,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => customerActions.getSaasTerminationEmailTemplate(vbCustomer.customerNumber, payload),
        onSave: () => customerActions.cancelSaasAgreement(terminationForm, () => onCloseClick(terminationForm.id))
    }

    const filterProducts = (products, active) => {
        return products?.filter((product) => active ? (product.isProductValid || !product.endDate) : (!product.isProductValid && product.endDate));
    }

    const renderEndDate = (product) => {
        if (product.isTrial) {
            return product.trialExpireDate
        }
        else if (!product.isProductValid) {
            return <span style={{ color: "red" }}>{product.endDate}</span>
        } else {
            return product.endDate
        }
    }
    const addons = vbCustomer.addons;

    return <>
        <SubscriptionCard
            title={t("vonproductcontainer.header")}
            activeContent={<>
                <VonProducts
                    products={filterProducts(vbCustomer.vonProducts, true)}
                    vonAccounts={vonAccounts}
                    pendingTerminationRequests={pendingTerminationRequests}
                    onButtonClick={onOpenModal}
                    customerGroup={vbCustomer.group}
                    getEndDate={renderEndDate}
                    {...props}
                />
                {addons && addons.length > 0 && <div className="table-responsive">
                    <table className="table table-stripe">
                        <thead>
                            <tr>
                                <th>{t('addons.addons')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addons.map((item, index) =>
                                <tr key={index}>
                                    <td>{item.name}</td>
                                </tr>)
                            }

                        </tbody>
                    </table>
                </div>}
            </>
            }
            inactiveContent={<InactiveProducts products={filterProducts(vbCustomer.vonProducts, false)} getEndDate={renderEndDate} />}
            isLoading={dataLoading}
        >
            {isModalActive('period') && <VonChangePeriodModal onChange={onFieldChange} onClose={onCloseClick} service={payload} />}
            {isModalActive('terminate') && <ProductTerminationModal {...terminationModalAttr} />}


        </SubscriptionCard>
    </>
}

function mapStateToProps(state) {
    return {
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.CancelSaasAgreement,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['SaasTerminationReasons'],
        customer: state.CustomerReducer.customer,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        pendingTerminationRequests: state.CustomerReducer.pendingTerminationRequests,
        vonProducts: state.CustomerReducer.vonProducts,
        dataLoading: state.CommonReducer.dataLoading.vonProducts
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VonProductsContainer)));