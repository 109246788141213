import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export class DropDown extends Component {

    onChange(event) {
        const number = Number(event.target.value)

        this.props.onChange(number ? number : event.target.value, event.target.id)
    }

    render() {

        const {label, list, value, id, t, customClass, style, customFormClass} = this.props

        const labelWidth = this.props.labelWidth || 4;
        let elementWidth = this.props.elementWidth || (12 - labelWidth);
      
        return (
            <div className={`form-group ${customFormClass}`}>
            {label && <label className={`col-xs-${labelWidth} control-label `}>{(label)}</label>}
                <div className={`col-xs-${elementWidth} ${customClass}`}>
                    <select style={style} id={id} disabled={this.props.disabled} className="form-control" value={value} onChange={this.onChange.bind(this)}>
                        {
                            this.props.blankOption && <option key={-1} value={""}>{""}</option>
                        }
                        {
                            list && list.map((item, index) => <option key={index} value={this.props.nameValued ? item.name : item.id}>{this.props.nameAggregate ? this.props.nameAggregate(item) : item.name}</option>)
                        }
                        {
                            this.props.blankOptionLast && <option key={-1} value={-1}>{""}</option>
                        }
                    </select>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DropDown);
