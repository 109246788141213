import { searchActionTypes as actionTypes } from '../ActionTypes/searchActionTypes'
import commonActions from './commonActions'
import { httpFetch, httpFetchNoEncode } from '../Utils/httpUtils'

const searchActions = {
    search(input) {
        return dispatch => {
            if (!input) {
                return dispatch({ type: actionTypes.VALIDATE_SEARCH_INPUT, hasError: true });
            }
            if (input == "playpong") {
                return dispatch(commonActions.togglePong(true));
            }

            dispatch(searchActions.searchCustomers(input));
        }
    },

    searchCustomers(input) {

        const inputEncoded = encodeURIComponent(input);

        return dispatch => {
            dispatch(commonActions.hideAlert());
            dispatch(searchActions.emptySearchResult(true));
            dispatch(commonActions.progressLoader(true));

            return httpFetchNoEncode('/api/customer/SearchCustomers/' + inputEncoded, dispatch)
                .then(json => {
                    dispatch(searchActions.showResult({ input, data: json.results }));
                })
        }
    },

    showResult(json) {
        return {
            type: actionTypes.SEARCH_CUSTOMER_RESULT,
            json
        }
    },

    emptySearchResult() {
        return {
            type: actionTypes.EMPTY_SEARCH_RESULTS
        }
    },

    searchInputChanged(newValue) {
        return {
            type: actionTypes.SEARCH_INPUT_CHANGED,
            value: newValue
        }
    }
}

export default searchActions;