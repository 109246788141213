
import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Modal';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DropDown from '../DropDown';
import RoleTable from '../Permission/RoleTable';

const dark_mode = "dark-mode";
const light_theme = "light-theme";
const dark_theme = "dark-theme";

function ChangeUserSettingsModal ({t, i18n, user, onClose, onSave}) {
    const darkModeRef = useRef();
    const [currentTheme, setCurrentTheme] = useState(light_theme);

    useEffect(() => {
        const darkMode = localStorage.getItem(dark_mode);

        if (!!darkMode && darkMode !== light_theme) {
            setCurrentTheme(dark_theme)
        }
    }, [])

    const toggleDarkmode = () => {
        const newTheme = currentTheme === light_theme ? dark_theme : light_theme;

        localStorage.setItem(dark_mode, newTheme);
        const classList = document.querySelector("html").classList;
        classList.replace(currentTheme, newTheme)

        setCurrentTheme(newTheme);
    };

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
    };

    const modalProps = {
        title: t('changeusersettings.usersettings'),
        mainButtonTitle: t('editshortcutsmodal.save'),
        onCancelClick: onClose, 
        cancelTitle: "Close",
        stateful: true,
        open: true,
        onSaveClick: () => onSave,
        saveData: null,
        readonly: true,
        dialogStyle: { width: "60%" }
    }

    const languages = [ { name: "Svenska", id: "sv"}, { name: "Engelska ", id: "en"}]

    return (
        <Modal {...modalProps}>
            <div>
                 <div>
                    <p>
                        <b>{t("changeusersettings.theme")}</b>
                    </p>
                    <label ref={darkModeRef} className="switch switch-label light-dark-toggle" style={{ width: 400 }}>
                        <b className="light">{t("changeusersettings.light")}</b>
                        <input id="switch-darkmode" type="checkbox" name="switch-darkmode"
                            checked={currentTheme === dark_theme}
                            onChange={(value) => toggleDarkmode(value)} />
                        <span className="togglemark">
                            <b className="details"></b>
                        </span>
                        <b className="dark">{t("changeusersettings.dark")}</b>
                    </label>
                </div>
                <div>
                    <p>
                        <b>{t("changeusersettings.language")}</b>
                    </p>
                    <DropDown onChange={(value) => handleLanguageChange(value)} list={languages} value={i18n.language}/>
                </div>
                <div>
                    <p>
                        <b>{t('adminusereditcontainer.roles')}</b>
                    </p>
                    <RoleTable roles={user.userRoles} user={user}/>
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeUserSettingsModal));