import React from 'react';
import { Route, Routes } from 'react-router-dom'
import CustomerSubscriptionEditContainer from '../../../Containers/Customer/VOSS/Subscriptions/CustomerSubscriptionEditContainer';

function CustomerSubscriptionsLayout({ ...props }) {
    return <Routes>
        <Route path={'/edit/'} element={<CustomerSubscriptionEditContainer />} />
    </Routes>
};

export default CustomerSubscriptionsLayout;
