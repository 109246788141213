import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../Actions/commonActions'
import routes from '../Constants/routes'
import Loader from '../Components/Loader'
import {FormEditor} from '../Components/Editor'
import { useNavigate } from 'react-router-dom';

function UserSignatureContainer({ commonActions, user, userSignatureForm, t }) {

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.getUserSignatureForm(user.email);
    }, [])

    const onSaveClick = () => {
        commonActions.updateMySignature(userSignatureForm, () => onCancelClick());
    }

    const onCancelClick = () => {

        commonActions.cancelFormEditing(userSignatureForm.id, () => navigate(routes.searchMain));
    }

    const onFieldChange = (value) => {
        commonActions.changeMultiFormField(userSignatureForm.id, 'signature', value);
    }

    if (!userSignatureForm) {
        return null;
    }

    return (
        <div className="panel">
            <div className="panel-heading">
                <h3 className="panel-title">{t('usersignaturecontainer.mysignature')}</h3>
            </div>
            <div className="panel-body">
                <form className="form-horizontal col-xs-10">
                    <div className="margin-bottom">
                        <FormEditor label={t('usersignaturecontainer.signature')} onChange={onFieldChange} value={userSignatureForm.data.signature} />
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                        <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>{t('usersignaturecontainer.save')}</button>
                        <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('usersignaturecontainer.cancel')}</button>
                        <Loader />
                    </div>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        userSignatureForm: state.CommonReducer.forms && state.CommonReducer.forms.userSignatureForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserSignatureContainer));