import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import commonActions from '../Actions/commonActions'
import customerActions from '../Actions/customerActions'
import routes from '../Constants/routes'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import StaticTextInput from '../Components/StaticTextInput';
import DropDown from '../Components/DropDown';

function CustomerDirectoryEditContainer({ commonActions, customerActions, form, aoCustomers, t }) {

    const { customerId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let directoryCustomer = aoCustomers.find(customer => customer.customerNumber === customerId);
        commonActions.startFormEditing("directoryCustomerEditForm", directoryCustomer)
    }, [])

    const onSaveClick = () => {
        customerActions.updateDirectoryCustomer(form, () => navigate(routes.customerDirectoryContainer));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.customerDirectoryContainer))
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const data = form && form.data;

    if (!data) {
        return null;
    }

    const priorityTypes = [
        { id: 'high', name: t('leadsendemailmodal.high') },
        { id: 'medium', name: t('leadsendemailmodal.medium') },
        { id: 'low', name: t('leadsendemailmodal.low') }
    ]

    return (
        <div className="panel" style={{ marginLeft: "20px" }}>
            <div className="panel-body">
                <div className="panel-heading">
                    <div className="panel-title"><h3>{t('customerdirectoryeditcontainer.customerdirectory')}</h3></div>
                    <form className="form-horizontal col-xs-5">
                            <StaticTextInput label={t("customerdirectoryeditcontainer.customername")} value={data.customerName} />
                            <StaticTextInput label={t("customerdirectoryeditcontainer.customernumber")} value={data.customerNumber} />
                            <StaticTextInput label={t("customerdirectoryeditcontainer.organizationnumber")} value={data.organizationNumber} />
                            <StaticTextInput label={t("customerdirectoryeditcontainer.address")} value={data.email} />
                            <StaticTextInput label={t("customerdirectoryeditcontainer.postcode")} value={data.postalCode} />
                            <StaticTextInput label={t("customerdirectoryeditcontainer.city")} value={data.city} />
                            <DropDown label={t('customerdirectoryeditcontainer.directorypriority')} blankOption={true} onChange={(value) => onFieldChange("priorityType", value)} list={priorityTypes} value={data.priorityType} />
                        <div className="form-group padding-top no-left-margin btn-toolbar">
                            <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("customerdirectoryeditcontainer.save")}</button>
                            <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("customerdirectoryeditcontainer.cancel")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        directoryCustomer: state.CustomerReducer.directoryCustomer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.directoryCustomerEditForm,
        aoCustomers: state.CustomerReducer.aoCustomers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerDirectoryEditContainer));