import ReactModal from "react-modal";

function Header({ children, onClick }) {
    return <div className="modal-header">
        <button type="button" className="close" onClick={() => onClick()}></button>
        {children}
    </div>
}

function Title({ children }) {
    return <h4 className="modal-title">{children}</h4>
}

function Body({ children }) {
    return <div className="modal-body">
        {children}
    </div>
}

function Footer({ children }) {
    return <div className="modal-footer mt-auto">
        {children}
    </div>
}

AlertModal.Header = Header;
AlertModal.Body = Body;
AlertModal.Header.Title = Title;
AlertModal.Footer = Footer;

export function AlertModal({type, children, isOpen }) {

    let modalProps = {
        isOpen: isOpen,
        className: "modal d-flex " + (type ? `modal-${type}` : "modal-info")
    };

    return <ReactModal {...modalProps}>
        <div className="modal-dialog">
            <div className="modal-content">
                    {children}
            </div>
        </div>
    </ReactModal>
}