import React from 'react';

export default function Checkbox(props) 
{
    const random = () => {
        return Math.floor(Math.random() * 100000);
    }
    
    const randomNumber = random();
    
    const renderLabel = () => {
        const { label, link } = props;
        
        if (link)
            return <label key={randomNumber} htmlFor={randomNumber}><a key={label} href={link} target="_blank" rel="noopener noreferrer">{label}</a></label>
        else
            return <label key={randomNumber} htmlFor={randomNumber}>{label}</label>
    }

    const renderInput = () => {
        let { value, checked, disabled, n} = props;

        return <>
            <input key={value} type="checkbox" id={randomNumber} value={value} checked={checked} disabled={disabled} onChange={(e) => props.onChange(e.target)}/>
            {renderLabel()}
            </>
    }
    
    return <div className={`checkbox ${props.disabled ? 'disabled' : ''}`} >
                { renderInput() }
            </div>
    
}
