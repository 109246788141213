import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Modal from '../../Components/Modal'
import EmailTemplate from '../../Components/EmailTemplate'

import commonActions from '../../Actions/commonActions'
import appointmentActions from '../../Actions/appointmentActions'
import actionLogActions from '../../Actions/actionLogActions';


class AppointmentSendEmailModal extends Component {

    componentDidMount() {
        const { appointmentActions, appointment: { emailAddress, customerNumber}, emailActionLogId } = this.props;
        
        this.props.commonActions.loadDropdownList("AppointmentEmailTemplates");
        this.props.commonActions

        if (emailActionLogId)
            appointmentActions.getFormFromEmailActionLog(emailActionLogId)
        else
            appointmentActions.startEmailSendingModal(emailAddress, customerNumber);
    }

    onTemplateChange = (id) => {
        const { appointmentActions, appointment: { emailAddress, customerNumber } } = this.props;

        appointmentActions.getEmailTemplate(id, emailAddress, customerNumber)
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("appointmentEmail", field, value);
    }

    handleClickSave = () => {
        const { appointmentActions, appointment, emailForm, onSave } = this.props;

        appointmentActions.sendEmail(appointment.id, emailForm, onSave)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("appointmentEmail", onClose)
    }

    render() {
        const { dropdowns, emailForm, emailActionLogId, templates } = this.props;

        if (!emailForm)
            return null;
        if (!emailActionLogId && !dropdowns.AppointmentEmailTemplates)
            return null;

        const modalProps = {
            title: emailActionLogId ? 'Resend Email' : 'Send Email',
            mainButtonTitle: emailActionLogId ? 'Resend' : 'Send',
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <EmailTemplate data={emailForm.data } templates={templates} onChange={this.handleFieldChange}
                    onSaveClick={this.handleClickSave} onCancelClick={this.handleClickClose} onTemplateChange={this.onTemplateChange}
                    senderEmails={emailForm.data.defaultSenderAddresses} onSenderChange={this.handleFieldChange} />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdowns: state.CommonReducer.dropdownlists,
        appointment: state.AppointmentReducer.appointment,
        emailForm: state.CommonReducer.forms && state.CommonReducer.forms.appointmentEmail,
        templates: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.AppointmentEmailTemplates
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        appointmentActions: bindActionCreators(appointmentActions, dispatch),
        actionLogActions: bindActionCreators(actionLogActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSendEmailModal);
