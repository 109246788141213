import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Card from "../../../../Components/Card/Card";
import {VonServiceSelection} from "../../../../Components/Customer/VON/AdministerLicenses/VonServiceSelection";
import VonEditEmployeeLicensesForm
    from "../../../../Components/Customer/VON/AdministerLicenses/VonEditEmployeeLicensesForm";
import routes from "../../../../Constants/routes";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import commonActions from "../../../../Actions/commonActions";
import customerActions from "../../../../Actions/customerActions";

function VonAdministerLicenseFormContainer({ customerActions, vonLicenseAdministrationForm, vonAccounts, vbCustomer, commonActions, vonProductsLoading, customer, vonProducts }) {
    const customerNumber = vbCustomer.customerNumber;
    const [services, setServices] = useState();
    const [selectedService, setSelectedService] = useState();
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            commonActions.cancelFormEditing("vonLicenseAdministrationForm");
        }
    }, [])

    useEffect(() => {
        if (vonAccounts && vonProducts) {
            let vonProductsWithLicenses = vonProducts.filter(product => product.licenses > 0);
            setServices(vonProductsWithLicenses);
            setSelectedService(vonProductsWithLicenses[0]);
            customerActions.beginVonLicenseAdministrationForm(vonAccounts, vonProductsWithLicenses[0]);
        }
    }, [vonAccounts, vonProducts])

    const onChangeService = (service) => {
        customerActions.beginVonLicenseAdministrationForm(vonAccounts, service);
        setSelectedService(service);
    }

    const onChange = (form, value, field) => {
        commonActions.changeMultiFormField(form, field, value);
    }

    const onSubmit = () => {
        customerActions.bulkUpdateVonEmployeeServices(vonLicenseAdministrationForm, () => { customerActions.getVonAccounts(customerNumber) });
    }

    let isLoading = vonProductsLoading || !vonLicenseAdministrationForm || !selectedService

    return <Card>
        <Card.Header>
            <Card.Header.Title>Administrera licenser</Card.Header.Title>
        </Card.Header>
        <Card.Content isLoading={isLoading}><div className="d-grid gap-24">
                <fieldset className="d-grid gap-24 mb-0 pl-0">
                    <VonServiceSelection selectedService={selectedService?.productNumber} onChange={onChangeService} services={services} />
                    <VonEditEmployeeLicensesForm onChange={onChange} selectedService={selectedService} vonLicenseAdministrationForm={vonLicenseAdministrationForm}/>
                </fieldset>
            </div>
        </Card.Content>
        <Card.Footer>
            <Card.Footer.Buttons>
                <button className="btn btn-default" onClick={onSubmit} disabled={isLoading}>Spara</button>
                <button className="btn btn-default"
                        onClick={() => navigate(routes.customerVonAccounts(customer.pathId))}>Tillbaka
                </button>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        vonLicenseAdministrationForm: state.CommonReducer.forms && state.CommonReducer.forms.vonLicenseAdministrationForm,
        vonProducts: state.CustomerReducer.vonProducts,
        vonProductsLoading: state.CommonReducer.dataLoading.vonProducts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonAdministerLicenseFormContainer);