import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import settingsActions from "../../Actions/settingsActions";
import { connect } from "react-redux";
import Checkbox from "../../Components/Checkbox";
import Panel from "../../Components/Panel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Modal";
import Loader from "../../Components/Loader";
import ButtonDropdown from '../../Components/ButtonDropdown';

function EloquaEditContainer({settingsActions, eloquaFields}) {

    const [filters, setFilters] = useState([]);
    const [createdFields, setCreatedFields] = useState(null);
    const [showChangesModal, setChangesModalVisible] = useState(false);
    const [showOnlyEnabledFields, setShowOnlyEnabledFields] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getEloquaFields();
    }, [])

    if(!eloquaFields)
        return null;

    const distinctObjectNames = Array.from(new Set(eloquaFields.map(item => item.importDefinitionName)));

    const updateWhenValueIsBlank = (item, element) => {
        item.updateWhenValueIsBlank = element.checked
        settingsActions.updateEloquaField(item);
    }

    const overwriteOnlyIfTargetIsBlank = (item, element) => {
        item.overwriteOnlyIfTargetIsBlank = element.checked
        settingsActions.updateEloquaField(item);
    }

    const updateEnabled = (item, element) => {
        item.enabled = element.checked
        settingsActions.updateEloquaField(item);
    }

    const updateSourceName = (item, e) => {
        settingsActions.updateEloquaField(item);
    }

    const sourceNameChange = (e, item) => {
        item.sourceName = e.target.value;
        settingsActions.updateEloquaField(item, false)
    }

    const isFilterActive = (filter) => {
        return filters.some(item => filter === item);
    }

    const handleFilterSet = (filter) => {
        var array = [...filters]; // make a separate copy of the array
        var index = array.indexOf(filter)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(filter);
        }
        setFilters(array);
    }

    const changeFieldsModal = (changes) => {
        setCreatedFields(changes);
        setChangesModalVisible(true)
    }

    const saveFields = () => {
        settingsActions.saveFields(changeFieldsModal)
    }

    const navigateToDefinitions = () => {
        navigate("definitions");
    }

    const modalProps = {
        title: "New created fields from Eloqua",
        onCancelClick: () => setChangesModalVisible(false),
        stateful: true,
        open: true,
        showButtons: false,
        mainButtonTitle: "",
    }


    const filteredByObjectType = eloquaFields.filter(e =>  filters.includes(e.importDefinitionName));
    const filtered = showOnlyEnabledFields ? filteredByObjectType.filter(e => e.enabled) : filteredByObjectType;

    const showOnlyEnabledFields2 = (element) => {
        setShowOnlyEnabledFields(element.checked)
    }

    const buttonDropDownOptions = [
        {
            title: "Sync custom fields",
            indentifier: "syncFields",
            buttonClick: saveFields
        },
        {
            title: "Sync Importdefinitions",
            indentifier: "createImportDefinitions",
            buttonClick: settingsActions.createImportDefinition
        },
    ]

    return <Panel title={t('navigation.eloquaMapping')}>
        <button className="btn btn-primary" onClick={() => navigateToDefinitions()  }>Show data import log</button>
        <ButtonDropdown title="Eloqua Actions"  options={buttonDropDownOptions} />
        <Checkbox label="View only enabled fields" checked={showOnlyEnabledFields} onChange={showOnlyEnabledFields2}/>
        <Loader />
        <div className="filters">
            {distinctObjectNames && distinctObjectNames.map((name, index) =>
                <button key={index} type="button"
                             className={`btn btn-default ${isFilterActive(name) && "active"}`}
                             onClick={() => handleFilterSet(name)}>{name}</button>)}
        </div>
        <div className="table-responsive">
            <table className="table table-stripe table-condensed">
                <thead>
                <tr>
                    {/*<th>Statement</th>*/}
                    <th>ImportDefinitionName</th>
                    <th>SourceName (VDL)</th>
                    <th>Value</th>
                    <th>Name (Eloqua)</th>
                    <th>InternalName (Eloqua)</th>
                    <th>Statement (Eloqua)</th>
                    <th>Enabled</th>
                    <th>DataType</th>
                    <th>UpdateWhenValueIsBlank</th>
                    <th>OverwriteOnlyIfTargetIsBlank</th>
                    <th>HasReadOnlyConstraint</th>
                    <th>HasNotNullConstraint</th>
                    <th>HasUniquenessConstraint</th>
                </tr>
                </thead>
                <tbody>
                {filtered.map((item, key) => {
                  return <tr key={key}>
                      {/*<td>{item.statement}</td>*/}
                      <td className="text-nowrap">{item.importDefinitionName}</td>
                      <td><input type="text" onChange={e => sourceNameChange(e, item)} value={item.sourceName  ? item.sourceName : ""} onBlur={(e) => updateSourceName(item, e)} /></td>
                      <td><input type="text" value={item.value ? item.value : ""} disabled /></td>
                      <td>{item.name}</td>
                      <td>{item.internalName}</td>
                      <td>{item.statement}</td>
                      <td className="text-center"><Checkbox checked={item.enabled} onChange={(element) => updateEnabled(item, element)} /> </td>
                      <td>{item.dataType}</td>
                      <td className="text-center"><Checkbox checked={item.updateWhenValueIsBlank} onChange={(element) => updateWhenValueIsBlank(item, element)}/> </td>
                      <td className="text-center"><Checkbox checked={item.overwriteOnlyIfTargetIsBlank} onChange={(element) => overwriteOnlyIfTargetIsBlank(item, element)} /> </td>
                      <td className="text-center"><Checkbox checked={item.hasReadOnlyConstraint} disabled /> </td>
                      <td className="text-center"><Checkbox checked={item.hasNotNullConstraint} disabled /> </td>
                      <td className="text-center"><Checkbox checked={item.hasUniquenessConstraint} disabled /> </td>
                  </tr>

                })}
                </tbody>
            </table>
        </div>

        {showChangesModal && <Modal {...modalProps}>
            <div className="table-responsive">
            <table className="table table-stripe table-condensed">
                <thead>
                <tr>
                    <th>Objekttyp</th>
                    <th>Namn</th>
                    <th>Internt namn</th>
                    <th>Datatyp</th>
                    <th>Statement</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(createdFields).map((key => {
                        const objectName = key[0];
                        return key[1].map((field, key) => <tr key={key}>
                            <td>
                                {objectName}
                            </td>
                            <td>
                                {field.name}
                            </td>
                            <td>
                                {field.internalName}
                            </td>
                            <td>
                                {field.dataType}
                            </td>
                            <td>
                                {field.statement}
                            </td>
                        </tr>)
                    })
                )}
                </tbody>
            </table>
            </div>

        </Modal>}
    </Panel>
}

function mapStateToProps(state) {
    return {
        eloquaFields: state.SettingsReducer.eloquaFields
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EloquaEditContainer);