import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Modal from '../../Components/Modal'
import TextInputWithButton from '../TextInputWithButton'
import DropDown from '../DropDown'
import TextInput from '../TextInput'
import EmailTemplate from '../EmailTemplate'
import RadioButton from '../RadioButton';
import customerActions from '../../Actions/customerActions';

class ProductTerminationModal extends Component {

    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    onChange = (key, value) => {
        const { onChange, form } = this.props; 
        if (key === "reasonCode2")
            onChange(form.id, "churnEffect", null);
        else if (key === "reasonCode") {
            onChange(form.id, "reasonCode2", null);
            onChange(form.id, "churnEffect", null);
        }
        else if(key === "churnEffect"){
            onChange(form.id, "churnEffectExtraInfo", null);
        }

        onChange(form.id, key, value);        
    }

    onCancellingDateChange = (key, value) => {
        const { dispatch , form, vbCustomer } = this.props;
        
        if(form.id.includes("Saas")){
            customerActions.changeSaasTerminationEmailTemplate(dispatch , vbCustomer, form, value);
        }else{
            customerActions.changeTerminationEmailTemplate(dispatch , vbCustomer, form, value);
        }       

        this.onChange(key, value);                  
    }

    renderCancelOnPurchaseDateButton(product){
        const { vbCustomer } = this.props;
        
        if(!vbCustomer.allowToTerminateOnPurchaseDate)
            return false;
            
        return product.allowToTerminateOnPurchaseDate;
    }


    renderReasonDropdowns() {
        const data = this.props.form && this.props.form.data;
        const {t} = this.props
        if (!data)
            return null
        const reasons = this.props.reasons;
        const firstLevelReasonId = (data.reasonCode ? data.reasonCode : reasons[0].id).toString();
        const firstLevelReason = reasons.find(reason => reason.id === firstLevelReasonId.toString());

        let secondLevelReason;

        let result = []

        result.push(<DropDown key={result.length ++} label={t("productterminationmodal.reason")} onChange={(value) => this.onChange('reasonCode', value)} list={reasons} value={firstLevelReasonId} />)

        if (firstLevelReason && firstLevelReason.secondLevelReasons) {
            const reasonCode2 = data.reasonCode2 ? data.reasonCode2 : firstLevelReason.secondLevelReasons[0].id
            result.push(<DropDown key={result.length ++} label={t("productterminationmodal.reason2")} onChange={(value) => this.onChange('reasonCode2', value)} list={firstLevelReason.secondLevelReasons} value={reasonCode2} />)
            
            secondLevelReason = firstLevelReason.secondLevelReasons.find(reason => reason.id === reasonCode2.toString());
            
        }

        const churnEffects = firstLevelReason && firstLevelReason.churnEffectReasons && firstLevelReason.churnEffectReasons.length > 0 ? firstLevelReason.churnEffectReasons : secondLevelReason && secondLevelReason.churnEffectReasons;

        if(churnEffects && churnEffects.length > 0 ) {
        
            const churnEffectId = data.churnEffect ? data.churnEffect : churnEffects[0].id;
            const churnEffect = churnEffects.find(c => c.id === churnEffectId);

            result.push(<DropDown key={result.length ++} label={t("productterminationmodal.churneffect")} onChange={(value) => this.onChange('churnEffect', value)} list={churnEffects} value={churnEffectId} />)
        
            if(churnEffect && churnEffect.extraInputLabel){
                const value = data.churnEffectExtraInfo; 
                result.push(<TextInput key={result.length ++} placeholder={churnEffect.extraInputLabel} onChange={this.props.onChange.bind(this, this.props.form.id, 'churnEffectExtraInfo')} value={value} />)
            }
        }
        const comment = data.comment; 

        let commentLabel = firstLevelReason.commentLabel ? firstLevelReason.commentLabel : t("productterminationmodal.comments");
        
        if(secondLevelReason && secondLevelReason.commentLabel)
            commentLabel = secondLevelReason.commentLabel
    

        result.push(<TextInput key={result.length ++} multiline={true} placeholder={commentLabel} onChange={this.props.onChange.bind(this, this.props.form.id, 'comment')} value={comment} />)

        return <div>
            {result.map(i => i)}
        </div>
    }

    render() {

        const { form, vbCustomer, onClose, onSave, t } = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        var textInputProps = {
            buttonTitle: t('productterminationmodal.usecontactinfo'),
            buttonClick: () => this.onChange('reference', vbCustomer.contactPerson),
            label: t('productterminationmodal.cancelledby'),
            value: data.reference,
            onChange: (value) => this.onChange('reference', value)
        }

        const modalProps = {
            title: t('productterminationmodal.cancelagreement'),
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.onChange,
            onCancelClick: () => onClose(form.id),
            onSaveClick: onSave
        }
       
        const cancellationDateLabel = data.product.canReceiveReturn ? "" : t('productterminationmodal.on') + data.product.endDate;
        
        if(data.cancellationDate === undefined)
            data.cancellationDate = data.product.canReceiveReturn ? null : data.product.endDate ;

        return (
            <Modal {...modalProps}>
                <div>
                    <form className="form-horizontal">
                        {/*<p>You can only cancel agreements on the end date. If you want to cancel on a previous date call Business Administration</p>*/}
                        <fieldset className="margin-top">
                            <legend>{t('productterminationmodal.cancelling') + ` ${data.product.product}`  + cancellationDateLabel}</legend>
                            <div className="form-group" hidden={!data.product.canReceiveReturn}>
                                <label className="col-xs-4 control-label">Cancellation Date *</label>
                                <div className="col-xs-8">
                                    {this.renderCancelOnPurchaseDateButton(data.product) && <RadioButton style={{ marginRight: 5, marginTop: 4 }} group="cancellationDate" label={data.product.purchaseDate} value={data.product.purchaseDate} onChange={(value) => this.onCancellingDateChange("cancellationDate", value)} />}
                                    <RadioButton style={{ marginRight: 5, marginLeft: 12, marginTop: 4 }} group="cancellationDate" label={data.product.endDate} value={data.product.endDate} onChange={(value) => this.onCancellingDateChange("cancellationDate", value)} />
                                </div>
                            </div>
                            <TextInputWithButton {...textInputProps} />
                            {this.renderReasonDropdowns()}
                            
                        </fieldset>
                    </form>
                    <EmailTemplate data={data} onChange={this.onChange}/>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

export default connect(mapStateToProps)(withTranslation()(ProductTerminationModal));