import { httpFetch } from '../Utils/httpUtils'
import { discountActionTypes as actionTypes } from '../ActionTypes/discountActionTypes'
import commonActions from './commonActions'
import sorting from '../Utils/sorting'
import helpers from '../Utils/helpers'
import { UpdateDiscount } from '../Constants/formValidationRules'
import { RegexLib } from '../Constants/formValidationRules'
const discountActions = {

    getDiscounts() {
        return (dispatch) => {
            return httpFetch(`/api/discount/getVonDiscountList/`, dispatch)
                .then(json => {

                    const list = sorting.sortListFromStorage("discounts", json);

                    dispatch({ type: actionTypes.STORE_DISCOUNTS, data: list });
                })
        }
    },

    getServices() {
        return (dispatch) => {
            return httpFetch(`/api/discount/GetServices/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SERVICES, data: json });
                })
        }
    },

    createDiscount(discountForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const updatedDiscountForm = {
                ...discountForm,
                validationRules: discountActions.getRulesForDiscountType(discountForm),
            };

            const validationResult = helpers.validateForm(updatedDiscountForm, true);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult.errorMessage, 'Please validate!'));
                dispatch(commonActions.setFieldErrorFlags(discountForm.id, validationResult.fieldErrorFlags))
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(updatedDiscountForm.data)
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch(`/api/discount/createDiscount/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Discount has been created'));
                    callback();
                })
        }
    },

    updateDiscount(discountForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const updatedDiscountForm = {
                ...discountForm,
                validationRules: discountActions.getRulesForDiscountType(discountForm),
            };

            const validationResult = helpers.validateForm(updatedDiscountForm, true);

            if (validationResult) {
                dispatch(commonActions.setFieldErrorFlags(discountForm.id, validationResult.fieldErrorFlags))
                return null;
            }

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify(updatedDiscountForm.data)
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch(`/api/discount/updateDiscount/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Discount has been updated'));
                    callback();
                })
        }
    },

    getDiscount(discountId) {
        return (dispatch) => {
            return httpFetch(`/api/discount/getDiscount/${discountId}`, dispatch)
                .then(data => {
                    data.discountType = (data.discountPercent !== null) ? "discountPercent" : "discountMoney";
                    dispatch(commonActions.startFormEditing("newDiscount", data, UpdateDiscount))
                })
        }
    },

    deleteDiscount(discountId, callback) {
        return (dispatch) => {
            const httpRequest = {
                method: 'DELETE'
            }
            httpFetch(`/api/discount/deleteDiscount/${discountId}`, dispatch, httpRequest)
            dispatch(commonActions.showSuccess('Discount has been deleted'));
            callback();
        }
    },

    terminateSubscriptionDiscount(subscriptionId, discount, callback) {
        return (dispatch) => {
            const httpRequest = {
                method: 'DELETE'
            }

            dispatch(commonActions.progressLoader(true));

            httpFetch(`/api/discount/terminateSubscriptionDiscount/?subscriptionId=${subscriptionId}&discountId=${discount.discountAgreementId}`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess(`"${discount.name}" has been terminated`));
                    callback();
                })
        }
    },

getRulesForDiscountType(discountForm){
    const rules = [...discountForm.validationRules];

    if (discountForm.data.discountType === "discountPercent") {
        rules.push({ inputId: 'discountPercent', rule: RegexLib.lessThan100, message: "Rabattsats can't be more than 100% or less than 0%" })
    } else {
        rules.push({ inputId: 'discountMoney', rule: 'required', message: '"Rabattsats" is required' })
    }

    return rules;
}
}

export default discountActions