import {useState} from "react";

export const Radiobutton = ({ item, currentChecked, onChange, labelProperty, valueProperty }) => {

    return <div key={item[labelProperty]} className="radio my-4">
        <input className="my-0" type="radio" id={item[labelProperty]} checked={item[valueProperty] === currentChecked} disabled={item.disabled} value={item[valueProperty]} onChange={(e) => onChange(e)}/>
        <label className="radio-inline" htmlFor={item[labelProperty]}>{item[labelProperty]}</label>
    </div>
}

export const RadioButtons = ({ items, onChange, label, value, labelProperty = 'label', valueProperty = 'value' }) => {
    const [checked, setChecked] = useState(value);

    if (value && value !== checked) {
        setChecked(value)
    }
    
    const onChecked = (e) => {
        setChecked(e.target.value)
        onChange(e.target.value)
    }

    return <>
        {label && <label>{label}</label>}
        {items && items.map((item) => {
                return <Radiobutton key={item.label} item={item} onChange={onChecked} currentChecked={checked} labelProperty={labelProperty} valueProperty={valueProperty}/>
            }
        )}</>
}

