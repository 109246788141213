import React from 'react';
import helpers from '../Utils/helpers';

export default function PhoneLink ({phoneNumber, label, button, classList}) {
    const formattedPhoneNumber = helpers.formatPhoneNumberForCountry(phoneNumber)
    let className = classList;
    let href = `tel:${formattedPhoneNumber}`;

    if (button)
        className += " btn btn-default";

    if (!formattedPhoneNumber) {
        className += " disabled cursordefault";
        href = "";
    }

    return <a className={className} href={href}><span className="vismaicon vismaicon-sm vismaicon-phone"></span>{label}</a>
}