import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next';
import appointmentActions from '../../Actions/appointmentActions'
import Panel from "../../Components/Panel";

function AppointmentsContainer({appointmentActions, t, vbCustomer, appointments, customer}) {
    
    useEffect(() => {
        appointmentActions.getCustomerAppointments(vbCustomer.customerNumber);
    }, []);
    
    const styleStatusCell = (appointment) => {
        if(appointment.isExpiredAndUnchanged) {
            return 'red';
        }

        if(appointment.isFutureAppointment && appointment.status === 0) {
            return 'bold';
        }

        return null;
    }

    const renderNoData = () =>  {
        return <>
            <Link to={routes.customerNewAppointment(customer.pathId)} className="btn btn-primary">{t('appointmentscontainer.addnewhere')}</Link>
            <p>{t('appointmentscontainer.noentriesfound')}</p>
        </>
    }
    
    if(!appointments) {
        return null;
    }
    
    return <Panel title={t('appointmentscontainer.header')} isNoData={appointments.length === 0} renderNoData={renderNoData}>
            <Link to={routes.customerNewAppointment(customer.pathId)} className="btn btn-primary">{t('appointmentscontainer.addnew')}</Link>
            <div className="table-responsive">
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th className="text-left">{t('appointmentscontainer.assignee')}</th>
                        <th className="text-left">{t('appointmentscontainer.subject')}</th>
                        <th className="text-left">{t('appointmentscontainer.time')}</th>
                        <th className="text-left">{t('appointmentscontainer.status')}</th>
                        <th className="text-center" width="50"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {appointments.map((appointment, index) => {
                        return <tr key={index}>
                            <td>{appointment.assigneeName || appointment.assignee}</td>
                            <td>{appointment.subject}</td>
                            <td>{appointment.time}</td>
                            <td className={styleStatusCell(appointment)}>{appointment.status}</td>
                            <td className="text-center"><Link to={routes.customerAppointmentDetails(customer.pathId, appointment.id)}><span className="vismaicon vismaicon-edit" title="Edit" /></Link></td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </Panel>
}

function mapStateToProps(state) {
    return {
        appointments: state.AppointmentReducer.customerAppointments,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer
    }
}

function mapDispatchToProps(dispatch) {
   return {
       appointmentActions : bindActionCreators(appointmentActions, dispatch) 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentsContainer));