import { useTranslation } from "react-i18next";
import * as calcTypes from '../../../Constants/priceCalculationTypes';
import Card from "../../Card/Card";
import AlertSmall from "../../AlertSmall";
import { useEffect, useState } from "react";
import moment from "moment";

  const Actions = ({
      orderActions,
      order,
      priceCalcType,
      editOrderline,
      product,
      order: { orderId }
    }) => {

    const { t } = useTranslation();
      
    const renderButtons = () => {
      if (priceCalcType === calcTypes.priceCalculationTypes.quotation) {
        return renderQuotationButton();
      } else {
        if (order.orderlines.length === 0) {
          return firstButtonRender();
        } else {
          return (
            <>
              {!order.quotationInProgress && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary mb-0"
                    disabled={!product.orderingValidation.successful}
                    onClick={() => saveChanges(false)}
                  >
                    {editOrderline
                      ? t('productdetailscontainer.savechanges')
                      : t('productdetailscontainer.addtoorder')}
                  </button>
                </>
              )}
              {order.quotationInProgress &&
                renderQuotationButton()}
            </>
          );
        }
      }
    };
  
    const saveChanges = (quotationFromOrderContainer) => {
  
  
      if (priceCalcType === calcTypes.priceCalculationTypes.quotation) {
        const addOrderLineFunc = () => orderActions.addOrderLine(orderId);
        if (!orderId) {
          orderActions.startOrder(-1, quotationCustomerForm, addOrderLineFunc);
        } else {
          addOrderLineFunc();
        }
      } else {
        orderActions.addOrderLine(orderId, quotationFromOrderContainer);
      }
    };
  
    const hasUnpaidInvoices = () =>
      product.hasUnpaidInvoices &&
      (!product.dueDate ||
        (product.dueDate &&
          !moment(product.dueDate).isAfter(moment().startOf('day'))));
  
    const renderQuotationButton = () => (
      <button
        type="button"
        className={`btn ${editOrderline && "btn-primary"} mb-0`}
        disabled={!product.orderingValidation.successful && !editOrderline}
        onClick={() => saveChanges(true)}
      >
        {editOrderline
          ? t('productdetailscontainer.savechanges')
          : t('productdetailscontainer.addtoquotation')}
      </button>
    );
  
    const firstButtonRender = () => (
      <>
        {renderQuotationButton()}
  
        <button
          type="button"
          className="btn btn-primary mb-0"
          disabled={!product.orderingValidation.successful}
          onClick={() => saveChanges(false)}
        >
          {editOrderline
            ? t('productdetailscontainer.savechanges')
            : t('productdetailscontainer.addtoorder')}
        </button>
      </>
    );
  
    if (editOrderline && product) {
      product.orderingValidation.successful = true;
    }
  
  return <div>
    {editOrderline && (
      <button
        type="button"
        className="btn btn-default mb-0"
        onClick={() => orderActions.hideProductDetails()}
      >
        {t('productdetailscontainer.cancel')}
      </button>
    )}
    {!hasUnpaidInvoices() && renderButtons()}
    {hasUnpaidInvoices() && (
      <>
        <div>{t('productdetailscontainer.unpaidinvoice')}</div>
        <div>{t('productdetailscontainer.contactcustomeradmin')}</div>
      </>
    )}
  </div>
}


function Table({ children, ...props }) {

    let tableProps = {
      className: 'table table-stripe skynet-table-no-divider ' + (props.className ?? ''),
    }

    return (
      <table {...tableProps}>
        {children}
      </table>
    )
  }

  function THeader({ children }) {
    return (
      <thead>
        <tr className='font-family-ubuntu-semibold'>
          {children}
        </tr>
      </thead>
    )
  }

  function TBody({ children }) {
    return (
      <tbody>
        {children}
      </tbody>
    )
  }

export function ProductSummaryCard({ product, children, ...props }) {

  const { t } = useTranslation();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    setAlerts(getAlerts())
  }, [product])

  const getAlerts = () => {
    const alerts = [];

    if (product && !product.orderingValidation.successful)
      alerts.push({
        type: 'info',
        title: product.orderingValidation.message,
      })

    return alerts
  };

  return <>
    <Card>
      <Card.Header>
        <Card.Header.Title>{t('productsummary.title')}</Card.Header.Title>
      </Card.Header>
      {children}
    </Card>
    {alerts.map((alert, index) => (
      <AlertSmall
        key={index}
        alert={alert}
      />
    ))}
  </>
};

ProductSummaryCard.Table = Table;
ProductSummaryCard.Table.THeader = THeader;
ProductSummaryCard.Table.TBody = TBody;
ProductSummaryCard.Actions = Actions;

