import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import routes from '../Constants/routes'
import { withTranslation } from 'react-i18next';
import Modal from '../Components/Modal'
import EmailTemplate from '../Components/EmailTemplate'

class QuotationModal extends Component {

    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    handleFieldChange = (field, value) => {
        const { onChange, form } = this.props;

        onChange(form.id, field, value);
    }

    handleSaveClick = () => {
        const {onSave, form, history} = this.props;

        onSave(form, history);
    }

    handleCloseClick = () => {
        const { onClose, form } = this.props;
        
        onClose(form.id);
    }

    renderPreview(data) {
        let newBody = data.body_copy
        if (data.rules) {
            data.rules.forEach(r => {
                newBody = newBody.replace(`[${r.id}]`, data[r.id] || '')
            })
        }
        else
            return data.body

        return newBody
    }

    render() {

        const { onClose, form, t} = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        const modalProps = {
            title: t('quotationmodal.title'),
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.handleFieldChange,
            onCancelClick: () => onClose(form.id),
            onSaveClick: this.handleSaveClick
        }

        const dataWithPreview = {...data}
        dataWithPreview.body = this.renderPreview(dataWithPreview)

        return (
            <Modal {...modalProps}>
                <div>
                    <div>
                        <EmailTemplate data={dataWithPreview} onChange={this.handleFieldChange} senderEmails={dataWithPreview.defaultSenderAddresses} onSenderChange={this.handleFieldChange}/>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.quotationMailForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //customerActions: bindActionCreators(customerActions, dispatch),
        //commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuotationModal));