import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../Actions/commonActions'
import appointmentActions from '../../Actions/appointmentActions'

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'


class AppointmentCloseModal extends Component {

    componentDidMount() {
        const { appointmentActions, appointment } = this.props;
        appointmentActions.startCloseModal({reason:'', appointmentId: appointment.id, action:'Close' })
    }
    
    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId }, reasonCodes } = this.props;

        if(field === "reason"){
            value = reasonCodes.find(code => code.id === value).name
        }

        commonActions.changeMultiFormField(formId, field, value);
    } 
       

    handleClickSave = () => {
        const { appointmentActions, customerNumberForCallback, form, callback } = this.props;
        appointmentActions.closeAppointment(customerNumberForCallback, form, callback);
    }

    handleClickClose = () => {
        const { commonActions, form, onClose } = this.props;

        commonActions.cancelFormEditing(form.id);
        onClose();
    }

    render() {
        const { reasonCodes, form, leadType } = this.props;

        if(!form) {
            return null;
        }

        const modalProps = {
            title: `Close`,
            mainButtonTitle: "Close",
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <DropDown label="Reason" onChange={this.handleFieldChange.bind(this, "reason")} list={reasonCodes} />
                    {leadType === "aoLead" && <TextInput label="Message" multiline={true} onChange={this.handleFieldChange.bind(this, "message")} value={form.data.message} />}
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        reasonCodes: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.AppointmentCloseReasonCodes,
        form: state.CommonReducer.forms && state.CommonReducer.forms.appointmentCloseModal,
        appointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCloseModal);
