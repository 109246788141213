import React, { useEffect, useState } from 'react';
import ProductOverviewItem from './ProductOverviewItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import productOverviewActions from '../Actions/productOverviewActions';
import ProductInformationModal from './ProductInformationModal';

function ProductOverviewList({productList, productOverviewActions}) {

  const [productId, setProductId] = useState(null);

  const setActiveProduct = (id) => {
    productOverviewActions.setSelectedProduct(id);
  }

  const moreInfoProductId = (id) => {
    setProductId(id);
  }
  
  return (
    <>
    <ul className='productOverviewList'>
      { productList && productList.map((product, i) => {
        return <li key={i} className='mauto'><ProductOverviewItem  {...product} handleActiveProduct={setActiveProduct} handleMoreInformation={moreInfoProductId}/></li>
      })}  
    </ul>
    <ProductInformationModal productInfo={productList && productList.find(prod => prod.id === productId)}/>
    </>
    )
    
}

function mapDispatchToProps(dispatch) {
  return {
    productOverviewActions: bindActionCreators(productOverviewActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(ProductOverviewList));