
import React from 'react';

//To be deleted
const OrderGridActions = ({orderline, onEdit, onRemove}) => {

    return  <td>
                <span className='vismaicon vismaicon-dynamic vismaicon-edit align-actionIcon' title="Edit" onClick={() => onEdit(orderline)}></span>
                <span className='vismaicon vismaicon-dynamic vismaicon-delete align-actionIcon' title="Remove" onClick={() => onRemove(orderline.orderlineId)}></span>
            </td>
}

export default OrderGridActions;