import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import commonActions from '../Actions/commonActions'

import Loader from '../Components/Loader'
import {FormEditor} from '../Components/Editor'

function NonCustomerSignatureContainer({ signatureForm, t, commonActions, toggleSetting }) {
    useEffect(() => {
        commonActions.getMyNonCustomerSignature();
    }, []);

    const onSaveClick = () => {
        commonActions.updateMyNonCustomerSignature(signatureForm, () => onCancelClick());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(signatureForm.id, () => toggleSetting(toggleSetting => !toggleSetting));
    }

    const onFieldChange = (value) => {
        commonActions.changeMultiFormField(signatureForm.id, 'signature', value);

    }

    if (!signatureForm) {
        return null;
    }

    return (
        <div className="panel-body">
            <form className="form-horizontal">
                <div className="margin-bottom">
                    <FormEditor label={t('myappointmentssignaturecontainer.signature')} onChange={onFieldChange} value={signatureForm.data.signature} />
                </div>
                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                    <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>{t('myappointmentssignaturecontainer.save')}</button>
                    <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('myappointmentssignaturecontainer.cancel')}</button>
                    <Loader />
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        signatureForm: state.CommonReducer.forms && state.CommonReducer.forms.nonCustomerMySignature
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NonCustomerSignatureContainer));