import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import AppointmentFormContainer from './AppointmentFormContainer'
import AppointmentDetailActions from '../../Components/Appointment/AppointmentDetailActions'

import routes from '../../Constants/routes'
import appointmentActions from '../../Actions/appointmentActions'
import ActionHistory from '../../Components/ActionHistory'

import SendEmailModal from './AppointmentSendEmailModal'
import CloseModal from './AppointmentCloseModal'
import BuyModal from './AppointmentBuyModal'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';


function MyAppointmentsFormContainer({ appointmentActions, customer, user, appointment, t, location, width, hideButtons }) {

    const navigate = useNavigate()
    const { appointmentId } = useParams()

    const [activeModal, setActiveModal] = useState("")
    const [emailActionLogId, setEmailActionLogId] = useState(0)

    useEffect(() => {
        appointmentId
            ? appointmentActions.editAppointment(appointmentId)
            : appointmentActions.addAppointment('appointmentForm', createNewAppointmentFormData());

        return () => {
            appointmentActions.clearAppointment();
        }
    }, [])

    const createNewAppointmentFormData = () => {

        return {
            customerNumber: customer ? customer.customerNumber : "",
            customerName: customer ? customer.customerName : "",
            contactPerson: customer ? customer.contactPerson : "",
            phoneNumber: customer ? customer.phoneNumber : "",
            emailAddress: customer ? customer.emailAddress : "",
            time: new Date(),
            assigneeName: user.name,
            assignee: user.email,
            subject: "",
            comments: ""
        }
    }

    const navigateAfterAction = () => {
        return () => navigate(routes.appointments);
    }

    const onNoAnswerClick = () => {
        let payload = { data: { action: "No answer" } };

        appointmentActions.saveAction(appointment.id, payload, false);
        refresh();

    }

    const onBusyClick = () => {
        let payload = { data: { action: "Busy" } };
        appointmentActions.saveAction(appointment.id, payload, false);
        refresh();
    }

    const refresh = () => {
        appointmentActions.editAppointment(appointment.id);
    }

    const toggleModal = (modalName, emailActionLogId = 0) => {
        setActiveModal(modalName)
        setEmailActionLogId(emailActionLogId);
    }

    const onCloseClick = (isModal) => {
        if (isModal) {
            navigate(routes.appointments);
        } else {
            if (location.useGoBack) {
                navigate(-1)
            } else {
                navigate(routes.appointments)
            }
        }
    }

    const onBuyClick = (actionName) => {
        if (actionName === 'order') {
            navigate(routes.orderPlacement(appointment.customerNumber))
        }
        else if (actionName === 'close') {
            toggleModal("showBuyModal");
        }
    }

    const isCreation = (!appointmentId);
    const isCompleted = (appointment && appointment.isCompleted) || false;

    let leadType = "";

    if (appointment)
        leadType = appointment.lead && appointment.lead.isAoLead ? "aoLead" : "lead";

    return (
        <div className="row">
            {activeModal === 'showSendEmailModal' && <SendEmailModal onClose={() => toggleModal()} onSave={() => onCloseClick(true)} emailActionLogId={emailActionLogId} />}
            {activeModal === 'showCloseModal' && <CloseModal leadType={leadType} onClose={() => toggleModal()} onSave={() => onCloseClick(true)} callback={navigateAfterAction()} />}
            {activeModal === 'showBuyModal' && <BuyModal leadType={leadType} onClose={() => toggleModal()} onSave={() => onCloseClick(true)} callback={navigateAfterAction()} />}

            <div className={`col-lg-${width || 7} col-xs-12`}>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">{isCreation ? t('myappointmentsformcontainer.createappointment') : t('myappointmentsformcontainer.appointmentdetails')}</h3>
                    </div>
                    <div className="panel-body">
                        <AppointmentFormContainer callback={navigateAfterAction()} isCreation={isCreation} isMyAppointment={true} appointmentFormWidth={12} hideButtons={hideButtons} />
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-lg-5">
                {!isCreation && !isCompleted && <AppointmentDetailActions
                    leadType={leadType}
                    onClick={(modalId) => toggleModal(modalId)}
                    onNoAnswerClick={() => onNoAnswerClick()}
                    onBuyClick={onBuyClick}
                    onBusyClick={onBusyClick}
                    hasCustomer={appointment && !!appointment.customerNumber}
                />}
                {!isCreation && appointment && <ActionHistory actionHistory={appointment.actionHistory} toggleModal={(modalId, emailActionLogId) => toggleModal(modalId, emailActionLogId)} />}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        appointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyAppointmentsFormContainer));    