import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import commonActions from "../../Actions/commonActions";
import {connect} from "react-redux";
import SkynetIcon from "./SkynetIcon";

function SkynetCopy({ label, value, toggleCopy, children, classList, commonActions }) {

    useEffect(() => {
        if (toggleCopy != null)
            copy();
    }, [toggleCopy]);

    function copy() {
        if (!value)
            return;
        commonActions.copyToClipboard(label, value)
    }

    return <a
        className={classList}
        onClick={() => copy()}>
        {children}
        <SkynetIcon icon={`vismaicon-sm vismaicon-copy ml-8${!value ? " disabled cursordefault" : ""}`}/>
    </a>
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}
export default connect(null,mapDispatchToProps)(SkynetCopy)