import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import commonActions from '../../Actions/commonActions';
import { withTranslation } from 'react-i18next';
import TextInput from '../../Components/TextInput';
import Loader from '../../Components/Loader';
import Checkbox from '../../Components/Checkbox';
import DropDown from '../../Components/DropDown';
import { useSearchParams } from 'react-router-dom';
import * as formValidationRules from '../../Constants/formValidationRules'
import { useState } from 'react';

function FileBoxCreateTicketContainer({ commonActions, vbCustomer, t, fileBoxActions, fileBoxTeams, fileBoxTicketForm }) {

    const [searchParams] = useSearchParams();
    const [hasConfirmedInfo, setHasConfirmedInfo] = useState(false);

    useEffect(() => {
        commonActions.startFormEditing("fileBoxTicketForm",
            {
                ticketId: null,
                owner: null,
                customerNumber: vbCustomer.customerNumber,
                customerName: searchParams.get('CustomerName'),
                email: null,
                phoneNumber: null
            },
            formValidationRules.CreateFileBoxTicket
        )
        fileBoxActions.getTeams();
    }, [])

    const onSaveClick = () => {
        fileBoxActions.createTicket(fileBoxTicketForm)
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField("fileBoxTicketForm", field, value)
    }

    const data = fileBoxTicketForm && fileBoxTicketForm.data;

    if (!data) {
        return null;
    }

    const inputStyle = {
        height: 45
    }

    const checkboxStyle = {
        padding: 20
    }

    return (
        <div className="row">
            <div className="col-xs-12">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">Create new ticket</h3>
                    </div>
                    <div className="panel-body">
                        <div>
                            <form className="form-horizontal col-xs-10">
                                <fieldset className="col-xs-6">
                                    <TextInput placeholder={"123456"} label={"Ticket Id *"} value={data.ticketId} style={inputStyle} onChange={(value) => onFieldChange("ticketId", value)}/>
                                    <DropDown blankOption={true} value={data.teamId} list={fileBoxTeams} label={"Owner *"} style={inputStyle} onChange={(value) => onFieldChange("teamId", value)} />
                                    <TextInput disabled={vbCustomer.customerNumber} label={"Customer Number *"} value={data.customerNumber} style={inputStyle} onChange={(value) => onFieldChange("customerNumber", value)}/>
                                    <TextInput disabled={searchParams.get("CustomerName")} label={"Customer Name *"} value={data.customerName} style={inputStyle} onChange={(value) => onFieldChange("customerName", value)}/>
                                    <TextInput label={"E-mail *"} placeholder={"siw.eriksson@visma.com"} value={data.email} style={inputStyle} onChange={(value) => onFieldChange("email", value)}/>
                                    <TextInput label={"Phone Number *"} placeholder={"0470-123456"} value={data.phone} style={inputStyle} onChange={(value) => onFieldChange("phone", value)}/>
                                    <div style={checkboxStyle}>
                                        <Checkbox label={"I confirm that all information above is correct"} checked={hasConfirmedInfo} onChange={() => setHasConfirmedInfo(!hasConfirmedInfo)}/>
                                    </div>
                                </fieldset>
                                <fieldset className="col-xs-6">
                                    <span className="vismaicon happy-file"></span>
                                </fieldset>
                            </form>
                            <div className="form-group padding-top no-left-margin btn-toolbar">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-primary" disabled={!hasConfirmedInfo} onClick={onSaveClick}>{"Create"}</button>
                                </div>
                                <div className="btn-group">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        fileBoxTicketForm: state.CommonReducer.forms && state.CommonReducer.forms.fileBoxTicketForm,
        fileBoxTeams: state.FileBoxReducer.fileBoxTeams,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxCreateTicketContainer));