import { useTranslation } from "react-i18next";
import helpers from "../../../../Utils/helpers";
import { ProductSummaryCard } from "../ProductSummary";

export function BlikkProductSummaryCard({ ...props }) {
    const { product } = props;

    const { t } = useTranslation();

    const getAddons = () => {
        const addons = [];

        product.licenseTypes.forEach((licenseType) => {
            if (licenseType.count > 0) {
                const licenseAddon = {
                    description: `${licenseType.name} - ${licenseType.count} licenses`,
                    initialPrice: product.totalInitialPrice > 0 ? licenseType.displayPrice : 0,
                    recurringPrice: licenseType.initialPrice * licenseType.count
                };
                addons.push(licenseAddon);
            }
        });

        product.transactionTypes.forEach((transactionType) => {
            transactionType.transactions.forEach((transaction) => {
                if (transaction.count > 0) {
                    const transactionAddon = {
                        description: `${transactionType.name} - ${transaction.name}`,
                        initialPrice: transaction.initialPrice,
                        recurringPrice: 0
                    };
                    addons.push(transactionAddon);
                }
            });
        });

        return addons;
    };

    let addons = getAddons();

    return <ProductSummaryCard product={product}>
        <ProductSummaryCard.Table>
            <ProductSummaryCard.Table.THeader>
                <th className='col-6'>{t('productsummary.main')}</th>
                <th className='col-2'>{t('productsummary.initialprice')}</th>
                <th className='col-2'>{t('productsummary.nextprice')}</th>
            </ProductSummaryCard.Table.THeader>
            <ProductSummaryCard.Table.TBody>
                <tr>
                    <td scope="row">{product.productDescription}</td>
                    <td>{helpers.currencyFormatter(product.mainInitialPrice ?? 0)}</td>
                    <td>{helpers.currencyFormatter(product.mainRecurringPrice ?? 0)}</td>
                </tr>
            </ProductSummaryCard.Table.TBody>
        </ProductSummaryCard.Table>
        {(addons && addons.length > 0) && <ProductSummaryCard.Table>
            <ProductSummaryCard.Table.THeader>
                <th className="col-6">{t('productsummary.addons')}</th>
                <th className='col-2'></th>
                <th className='col-2'></th>
            </ProductSummaryCard.Table.THeader>
            <ProductSummaryCard.Table.TBody>
                {addons.map((addon) => (
                    <tr>
                        <td scope="row">{addon.description}</td>
                        <td>{helpers.currencyFormatter(addon.initialPrice ?? 0)}</td>
                        <td>{helpers.currencyFormatter(addon.recurringPrice ?? 0)}</td>
                    </tr>
                ))}
            </ProductSummaryCard.Table.TBody>
        </ProductSummaryCard.Table>}
        <ProductSummaryCard.Table className="border-0 shadow-0">
            <ProductSummaryCard.Table.THeader>
                <th className="col-6">{t('productsummary.total')}</th>
                <th className='col-2'>{helpers.currencyFormatter(product.totalInitialPrice)}</th>
                <th className='col-2'>{helpers.currencyFormatter(product.totalRecurringPrice)}</th>
            </ProductSummaryCard.Table.THeader>
        </ProductSummaryCard.Table>
        <div className='d-flex d-flex flex-row justify-content-end my-24 mr-24 align-items-center'>
            <div>
                <ProductSummaryCard.Actions {...props} />
            </div>
        </div>
    </ProductSummaryCard>
}