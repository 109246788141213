import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';

class ButtonDropdown extends Component {

    constructor(props) {
        super(props);

        this.box = createRef();
    }

    state = {
        isOpen: false
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if(this.box && !this.box.current.contains(event.target)) {
            if(this.state.isOpen == true) {
                this.setState({ isOpen: false});
            }
        }
    }

    buttonClick = (identifier) => {
        this.setState({ isOpen: !this.state.isOpen })
        this.props.onClickAction(identifier)
    }

    render() {
        const { disabled, bottomOptions, t, onSplitClick } = this.props;

        if (disabled)
            this.state.isOpen = false;

        const className = `dropdown ${this.state.isOpen ? 'open' : ''}`;        

        return <><div className={className} ref={this.box}>
            {onSplitClick && <a style={{ marginBottom: "0" }} onClick={() => onSplitClick()} className="btn btn-primary btn-split-main" id="newFileRequestButton">New transfer</a>
            }
            <button type="button"
                // className={`btn ${this.props.defaultStyle ? 'btn-default' : 'btn-primary btn-blue'} dropdown-toggle no-margin`}
                className={`btn dropdown-toggle no-margin ` + (onSplitClick ? "btn-primary btn-split-caret" : "btn-default")}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={this.state.isOpen ? 'true' : 'false'}
                onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}
                disabled={disabled}
            >
                {this.props.title}
            </button>
             <ul className="dropdown-menu" role="menu" style={{width: "fit-content"}}>
                {
                    this.props.options.map(nav => {
                        return <li key={t(nav.title)}><a onClick={nav.buttonClick ? () => nav.buttonClick(nav.identifier) : () => this.buttonClick(nav.identifier)}>{t(nav.title)}</a></li>
                    })
                }
                {bottomOptions && this.props.options.length > 0 && <li className="divider"></li>}
                {bottomOptions && bottomOptions.map(bottomOption => {
                    return <li key={t(bottomOption.title)}><a onClick={() => bottomOption.onClick()}>{t(bottomOption.title)}</a></li>
                })}
            </ul>
        </div>
        </>
    }
}

export default withTranslation()(ButtonDropdown)