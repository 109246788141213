import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import DropDown from '../../Components/DropDown';
import { hasAdminAccess } from '../../Utils/permissionUtils';
import { UserRoles } from '../../Constants/userRoles';
import Card from '../../Components/Card/Card';
import BaseSearch from '../../Components/BaseSearch';
import {FeatureFlags} from "../../Utils/featureFlags";
import Grid from "../../Components/Grid/Grid";
import SkynetIcon from "../../Components/Fundamentals/SkynetIcon";

function FeatureToggleContainer({ settingsActions, users, managedTeams, user, backendFeatureFlags, enabledFeatureFlags }) {

    const [userType, setUserType] = useState('team');
    const [featureFlag, setFeatureFlag] = useState('');
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        if (!hasAdminAccess(user, UserRoles.AdministratorSkynet))
            return;
        if (!users || !managedTeams)
            settingsActions.getAllEditRoleModel();
        if (!backendFeatureFlags)
            settingsActions.getAllFeatureFlags();
        if (!enabledFeatureFlags)
            settingsActions.getEnabledFeatureFlags();
    }, [])

    if (!backendFeatureFlags || !users || !managedTeams || !enabledFeatureFlags)
        return null

    const userTypes = [
        {id: 'team', name: 'Team'},
        {id: 'user', name: 'User'},
        {id: 'api', name: 'External Api'},
        {id: 'worker', name: 'Worker'},
        {id: 'all', name: 'All'}
    ];

    const changeUserType = (type) => {
        setSelectedItem(null)

        setUserType(type)
        if (type === "api" || type === "worker" || type === "all")
            setSelectedItem({id: 0, userType: type, name: userTypes.find(t => t.id === type).name})
    }

    const filterItem = query => {
        const items = userType === 'user' ? users : managedTeams;
        return items.filter(user =>
            (user.name.toLowerCase().includes(query.toLowerCase()) || user.email?.toLowerCase().includes(query.toLowerCase())))
    }

    const uiFeatureFlags = Object.keys(FeatureFlags).map(key => FeatureFlags[key]);
    const distinctFeatureFlags = ['', ...new Set([...uiFeatureFlags, ...backendFeatureFlags])];
    const featureFlags = distinctFeatureFlags.map((flag) => {
        return {name: flag, key: flag}
    });

    const nonExistent = (id) => {
        return <><span className="text-error"><SkynetIcon icon="vismaicon-sm vismaicon-filled vismaicon-error mr-8"/>{`Non-existent user/team: ${id}`}</span></>
    }

    const getFlagUser = (flag) => {
        if (flag.userType === "user")
            return users.find(u => u.id === flag.userId)?.name || nonExistent(flag.userId);
        if (flag.userType === "team")
            return managedTeams.find(t => t.id === flag.userId)?.name || nonExistent(flag.userId);
        return ''
    }

    const addFeatureFlag = (flag, userType, id) => settingsActions.addFeatureToggle(flag, userType, id)

    const deleteFeatureFlag = (e, flagId) => {
        e.stopPropagation();
        settingsActions.deleteFeatureToggle(flagId, () =>
            settingsActions.storeEnabledFeatureFlags(enabledFeatureFlags.filter(f => f.id !== flagId)))
    }

    const sortFlags = (key) => {
        const sorted = [...enabledFeatureFlags]
        if (key === 'userId') {
            sorted.sort((a, b) => {
                let avalue = a[key], bvalue = b[key]
                if (a.userType === 'user')
                    avalue = getFlagUser(a)
                if (b.userType === 'user')
                    bvalue = getFlagUser(b)
                if (a.userType === 'team')
                    avalue = managedTeams.find(t => t.id === a[key])?.name
                if (b.userType === 'team')
                    bvalue = managedTeams.find(t => t.id === b[key])?.name
                if (avalue < bvalue) return -1;
                if (avalue > bvalue) return 1;
                return 0;
            })
        }
        else
            sorted.sort((a, b) => {
                if (a[key] < b[key]) return -1;
                if (a[key] > b[key]) return 1;
                return 0;
            })
        settingsActions.storeEnabledFeatureFlags(sorted)
    }

    let effectiveFlags = null
    let flagsToShow = enabledFeatureFlags
    if (selectedItem) {
        flagsToShow = enabledFeatureFlags.filter(f => f.userId === selectedItem.id && f.userType === userType)
        effectiveFlags = enabledFeatureFlags.filter(f => f.userId === selectedItem.id && f.userType === 'user' ||
            f.userId === selectedItem.teamId && f.userType === 'team' || f.userType === 'all')
    }

    const selectUser = (flag) => {
        setSelectedItem(null)
        setUserType(flag.userType)
        if (flag.userType === "user")
            setSelectedItem(users.find(u => u.id === flag.userId))
        if (flag.userType === "team")
            setSelectedItem(managedTeams.find(t => t.id === flag.userId))

    }

    return <div className="mb-64">
        <Grid>
            <Grid.Row>
                <Grid.Row.Column width={4}>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>Add Feature Flag</Card.Header.Title>
                        </Card.Header>
                        <Card.Content>
                            <DropDown onChange={(value) => setFeatureFlag(value)} list={featureFlags} nameValued={false}
                                      value={featureFlag} customClass={'budget-dropdown mt-24'}/>
                            <DropDown onChange={(value) => changeUserType(value)} list={userTypes} value={userType}
                                      customClass={'budget-dropdown mt-24'}/>
                            {
                                (userType === 'user' || userType === 'team') &&
                                <SearchUsers filterItem={filterItem} onChange={setSelectedItem}/>
                            }
                            {
                                selectedItem &&
                                <div>
                                    <div><b>Feature flag:</b> {featureFlag}</div>
                                    <div>
                                        <b>Target:</b> {selectedItem.name}
                                        <span className="ml-12"><SkynetIcon icon="vismaicon-undo" onClick={() => setSelectedItem(null)}/></span>
                                    </div>
                                    <br/>
                                    {featureFlag && <button className="btn btn-default"
                                                            onClick={a => addFeatureFlag(featureFlag, userType, selectedItem.id)}>Add</button>}
                                </div>
                            }
                            <br/>
                        </Card.Content>
                    </Card>
                    {effectiveFlags ? <Card>
                        <Card.Header>
                            <Card.Header.Title>Effective feature flags</Card.Header.Title>
                        </Card.Header>
                        <Card.Content>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>Feature flag</th>
                                        <th>Target</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        effectiveFlags.map((flag, index) => {
                                            return <tr key={index}>
                                                <td>{flag.name}</td>
                                                <td>{userTypes.find(t => t.id === flag.userType).name}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Card.Content>

                    </Card> : null}
                </Grid.Row.Column>
                <Grid.Row.Column>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>Enabled Feature Flags</Card.Header.Title>
                            <Card.Header.Buttons>
                                <SkynetIcon icon="vismaicon-refresh" onClick={() => settingsActions.getEnabledFeatureFlags()}/>
                            </Card.Header.Buttons>
                        </Card.Header>
                        <Card.Content>
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th onClick={() => sortFlags('name')}>Feature flag</th>
                                        <th onClick={() => sortFlags('userType')}>UserType</th>
                                        <th onClick={() => sortFlags('userId')}>Target</th>
                                        <th onClick={() => sortFlags('teamName')}>Team</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        flagsToShow.map(flag => {
                                            return <tr key={flag.id} onClick={() => selectUser(flag)}>
                                                <td>{flag.name}</td>
                                                <td>{userTypes.find(t => t.id === flag.userType).name}</td>
                                                <td>{getFlagUser(flag)}</td>
                                                <td>{flag.teamName}</td>
                                                <td>
                                                    <button className="btn"
                                                            onClick={e => deleteFeatureFlag(e, flag.id)}>Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Card.Content>
                    </Card>
                </Grid.Row.Column>
            </Grid.Row>
        </Grid>
    </div>
}

const SearchUsers = ({filterItem, onChange}) => {
    const [userSearchResult, setuserSearchResult] = useState([]);

    const attributes = {
        labelKey: item => item.name + (item.email ? ` (${item.email})` : ''),
        defaultSelected: [],
        options: userSearchResult || [],
        onSearchAttr: query => setuserSearchResult(filterItem(query)),
        onChangeAttr: onChange,
        clearButton: false,
        className: "col-8"
    }

    const labelExists = false

    return (<>
            <div className={'form-group ' + (labelExists ? 'form-search' : '')}>
                <BaseSearch attributes={attributes}/>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        users: state.SettingsReducer.users,
        managedTeams: state.SettingsReducer.managedTeams,
        user: state.CommonReducer.user,
        backendFeatureFlags: state.SettingsReducer.allFeatureFlags,
        enabledFeatureFlags: state.SettingsReducer.enabledFeatureFlags
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FeatureToggleContainer));