import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../Actions/commonActions'
import leadActions from '../../Actions/leadActions'
import { withTranslation } from 'react-i18next';

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'

class BuyModal extends Component {

    componentDidMount() {
        const { leadActions} = this.props;
        leadActions.startBuyModal(this.getModel())
    }

    getModel() {
        return { 
            reason: '',
            reasonId: '',
            action:'Close',
            amount: 0
        }
    }

    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId } } = this.props;
        commonActions.changeMultiFormField(formId, field, value);
    }

    handleClickSave = () => {
        const { leadActions, lead, form, onSave } = this.props;

        leadActions.close(lead.id, form, null, onSave)

        this.props.onClose();
    }

    handleClickClose = () => {
        this.props.onClose();
    }

    onFormSubmit = e => {
        e.preventDefault();
        this.handleClickSave();
    }

    render() {
        const { reasonCodes, form, leadType, t} = this.props;

        if(!form ) {
            return null;
        }

        const modalProps = {
            title: t('buymodal.close'),
            mainButtonTitle: t('buymodal.mainbuttontitle'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }
        
        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10' onSubmit={this.onFormSubmit}>
                    {leadType !== "aoLead" && <DropDown label={t('buymodal.reason')} onChange={this.handleFieldChange.bind(this, 'reasonId')} list={reasonCodes} />}
                    <TextInput label={t('buymodal.amount')} type={"number"} onChange={this.handleFieldChange.bind(this, 'amount')} value={form.data.amount} />
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        reasonCodes: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.LeadsBuyReasonCodes,
        form: state.CommonReducer.forms && state.CommonReducer.forms.leadBuyModal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuyModal));
