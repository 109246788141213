import {
    productOverviewActionTypes as actionTypes
} from "../ActionTypes/productOverviewActionTypes";

const initialState = {
    productList: null,
    selectedProductId: null,
    singleProduct: null,
    productVersionList: null,
    sendVersionsList: null,
}

export default function ProductOverviewReducer(state = initialState, action) {
    let newState;

    switch (action.type) {
        case actionTypes.STORE_PRODUCT_LIST:
            newState = {
                productList: action.productList
            }
            break;
        
        case actionTypes.STORE_PRODUCT_VERSION_LIST:
            newState = {
                productVersionList: action.list
            }
            break;
        
        case actionTypes.STORE_SINGLE_PRODUCT:
            const list = [...state.productList]
            const output = list.find(p => p.id === parseInt(state.selectedProductId));
            newState = {
                singleProduct: output
            }
            break;

        case actionTypes.SET_SELECTED_PRODUCT_ID:
            newState = {
                selectedProductId: action.selectedProductId
            }
            break;
        
        case actionTypes.RESET_VERSION_STATE:
            newState = {...state, selectedProductId: null, singleProduct: null ,productVersionList: null};
            break;
        
        case actionTypes.STORE_SEND_VERSION_LIST:
            newState = {
                sendVersionsList: action.sendVersionsList
            }
            break;

        default:
            return state;
    }

    if (newState) {
        return {
            ...state,
            ...newState
        }
    }

    return state;
}