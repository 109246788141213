import SkynetIcon from "../../Fundamentals/SkynetIcon";

function DiscountCustomerGroupsTable({ form: { data }, onChange }) {

    const onDeleteCustomerGroup = (index) => {
        const existingCustomerGroups = data.customerGroups || [];
        existingCustomerGroups.splice(index, 1);
        onChange(existingCustomerGroups, 'customerGroups');
    }

    return (
        <div>
            <table className="table table-stripe skynet-table-no-divider">
                <thead>
                    <tr>
                        <td colSpan={2}>Customer Group</td>
                    </tr>
                </thead>
                <tbody>
                    {data?.customerGroups?.length > 0 ? data.customerGroups.map((customerGroup, index) => (
                        <tr key={index}>
                            <td width={500}>{customerGroup.intervalStart} - {customerGroup.intervalEnd}</td>
                            <td className="text-center">
                                <SkynetIcon icon={"vismaicon-delete"} onClick={() => onDeleteCustomerGroup(index)} />
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={2}><em>No customer groups added</em></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default DiscountCustomerGroupsTable;