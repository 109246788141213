import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'
import { withTranslation } from 'react-i18next';

import Loader from '../../Components/Loader'
import {FormEditor} from '../../Components/Editor'
import TextInput from '../../Components/TextInput'
import DropDown from '../../Components/DropDown'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function MyTemplatesContainer({ commonActions, leadActions, leadTemplateForm, templates, t }) {

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.loadDropdownList("LeadsMyTemplates");
        createBlankFormData();
    }, [])

    const onSaveClick = () => {
        leadActions.saveMyTemplate(leadTemplateForm, () => onCancelClick());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(leadTemplateForm.data.formId, () => navigate(-1));
    }

    const onDeleteClick = () => {

        leadActions.deleteMyTemplate(leadTemplateForm.data.templateId, () => navigate(-1));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(leadTemplateForm.id, field, value);
    }

    const onTemplateSelect = (id) => {
        if (id > 0) {
            leadActions.getMyTemplate(id);
        }
        else {
            createBlankFormData();
        }
    }

    const createBlankFormData = () => {
        commonActions.startFormEditing("leadsMyTemplate", { name: "", body: "", subject: "" })
    }

    if (!templates || !leadTemplateForm) {
        return null;
    }

    return (
        <div className="panel">
            <div className="panel-heading">
                <h3 className="panel-title">{t('mytemplatescontainer.mytemplates')}</h3>
            </div>
            <div className="panel-body">
                <form className="form-horizontal col-xs-10">
                    <div className="margin-bottom">
                        <DropDown label={t('mytemplatescontainer.template')} onChange={onTemplateSelect} list={templates} blankOption={true} />
                        <TextInput value={leadTemplateForm.data.name} onChange={(value) => onFieldChange("name", value)} label={t('mytemplatescontainer.templatename')} />
                        <br />
                        <TextInput label={t('mytemplatescontainer.subject')} value={leadTemplateForm.data.subject} onChange={(value) => onFieldChange("subject", value)} />
                        <FormEditor label={t('mytemplatescontainer.text')} onChange={(value) => onFieldChange("body", value)} value={leadTemplateForm.data.body} />
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                        <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>
                            {leadTemplateForm.data.templateId > 0 ? t('mytemplatescontainer.update') : t('mytemplatescontainer.createnew')}</button>
                        <button type="button" className="btn btn-default" onClick={() => onCancelClick()}>{t('mytemplatescontainer.cancel')}</button>
                        {leadTemplateForm.data.templateId > 0 &&
                            <>
                                <button type="button" className="btn btn-default pull-right" onClick={() => onDeleteClick()}>{t('mytemplatescontainer.delete')}</button>
                            </>
                        }
                        <Loader />
                    </div>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        leadTemplateForm: state.CommonReducer.forms && state.CommonReducer.forms.leadsMyTemplate,
        templates: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.LeadsMyTemplates
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyTemplatesContainer));