import React, { createContext, useEffect, useState, useRef, useContext } from 'react';
import './Tabs.css';

export const TabsContext = createContext();

export default function Tabs({ tabs, initialTab, onChange, children }) {
    const getInitialTab = () => {
        return initialTab ? tabs.find(tab => tab.name === initialTab) : ""
    }
    const [activeTab, setActiveTab] = useState(getInitialTab());

    useEffect(() => {
        if (onChange)
            onChange(activeTab)

    }, [activeTab])

    useEffect(() => {
        setActiveTab(getInitialTab())
    }, [initialTab]);

    return (<>
        <TabsContext.Provider value={{ activeTab, setActiveTab }}>
            <TabsList tabs={tabs} initialTab={initialTab} />
            {children}
        </TabsContext.Provider>
    </>
    )
}

export function TabContent({ tabs }) {
    const { activeTab, setActiveTab } = useContext(TabsContext);

    var tab = tabs.find(tab => tab.name === activeTab?.name);

    return (
        tab && <div className="tab-content p-24">
            {tab.content}
        </div>
    )
}

function Tab({ tab }) {
    const { activeTab, setActiveTab } = useContext(TabsContext);

    const isActive = activeTab?.name === tab.name;
    const className = `nav-item ${isActive ? 'active bold' : ''}`;

    return (<>
            <li className={className} key={tab.name}>
                <a
                    id={`${tab.name}-tab`}
                    tabIndex="0"
                    role="tab"
                    data-toggle="tab"
                    aria-controls={tab.name.toLowerCase().replace(/\s/g, '-')}
                    onClick={() => setActiveTab(tab)}
                    className="bottom-0 left-0 normal-tab"
                >
                    {tab.label}
                </a>
            </li>
        </>
    )
}

function TabsList({tabs}) {
    const listRef = useRef()
    const dropRef = useRef();
    const dropLi = useRef();
    const [tabDropOpen, setTabDropOpen] = useState(false);
    const handleResize = () => {
        setTimeout(() => {
            if (listRef.current) {
                listRef.current.append(...dropRef.current.children);
                if (listRef.current.contains(dropLi.current))
                    listRef.current.removeChild(dropLi.current);

                var hasItems = false;
                [...listRef.current.children].map((child) => {
                    if (!child.classList.contains("tabdrop") && child.offsetTop > 0) {
                        dropRef.current.appendChild(child);
                        hasItems = true;
                    }
                });

                if (hasItems) {
                    listRef.current.appendChild(dropLi.current);
                }

            }
        }, 0)
    };

    useEffect(() => {
        handleResize();
    }, [listRef.current && listRef.current.clientWidth || dropRef.current]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ul className="nav nav-tabs nav-tabs-primary skynet-nav-tabs pl-0 pr-0 d-inline-block" role="tablist" onMouseLeave={() => setTabDropOpen(false)} ref={listRef}>
            {tabs.map((tab, index) => (
                <Tab key={tab.name} tab={tab}/>
            ))}
            <li className={`menu-drop dropdown tabdrop icon pt-12 ${tabDropOpen ? "open" : ""}`}  ref={dropLi}>
                    <a className="tabdrop-dropdown mt-8" tabIndex="0" data-toggle="dropdown"
                       onMouseEnter={() => setTabDropOpen(true)}><span className="vismaicon vismaicon-dynamic vismaicon-burger" aria-hidden="true"/></a>
                    <ul className="dropdown-menu rounded-6" role="menu" ref={dropRef}></ul>
            </li>
        </ul>
    )
}
