import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import PanelActions from '../../Components/PanelActions'
import LeadForm from '../../Components/Leads/LeadForm'
import ActionHistory from '../../Components/ActionHistory'
import RelatedLeads from '../../Components/Leads/RelatedLeads'

import SendEmailModal from '../../Components/Leads/SendEmailModal'
import AppointmentModal from '../../Components/Leads/AppointmentModal'
import CloseModal from '../../Components/Leads/CloseModal'
import BuyModal from '../../Components/Leads/BuyModal'
import './Lead.css'

import PhoneLink from '../../Components/PhoneLink'
import ButtonDropdown from '../../Components/ButtonDropdown'

import LeadCustomers from '../../Components/Leads/LeadCustomers';
class LeadDetailsContainer extends Component {

    constructor(props) {
        super(props);
        this.initState = {
            showSendEmailModal: false,
            showAppointmentModal: false,
            showCloseModal: false,
            showBuyModal: false,
            emailActionLogId: 0
        }
        this.state = this.initState;
    }

    componentWillUnmount() {
        const { commonActions } = this.props;

        commonActions.clearStoreSelected();
    }


    onNoAnswerClick = () => {

        const { leadActions, lead} = this.props;

        var payload = { data: { action: "No answer" } };

        leadActions.saveAction(lead.id, payload, false);
        this.refresh();

    }

    onBusyClick = () => {

        const { leadActions, lead} = this.props;

        var payload = { data: { action: "Busy" } };

        leadActions.saveAction(lead.id, payload, false);
        this.refresh();

    }

    onCloseClick = (modalName) => {
      
        if (modalName)
            this.toggleModal(modalName);
      
        this.refresh();
    }

    onReopenClick = () => {
        const { leadActions, lead } = this.props;

        leadActions.reopen(lead.id);
        leadActions.getMyLeads();
    }

    onUnassignClick = () => {
        const { leadActions, lead } = this.props;

        lead.assignee = "";
        leadActions.unassignLead(lead);
        this.props.navigate(routes.leads);
    }

    toggleModal = (modalName, emailActionLogId = 0) => {
        this.setState({
            [modalName]: !this.state[modalName],
            emailActionLogId: emailActionLogId
        });
    }

    refresh = () => {
        const { leadActions, lead } = this.props;

        leadActions.getLead(lead.id, true, true)
        leadActions.getMyLeads();
    }

    onAssingButtonClick = () => {
        const { leadActions, lead } = this.props;

        leadActions.save(lead, () => this.refresh());
    }

    onBuyClick = (actionName) => {
        switch(actionName){
            case "order" : this.props.navigate(routes.orderPlacement(this.props.lead.customerNumber))
                break;
            case "manualOrder" : this.toggleModal("showBuyModal")
                break;
            case "closeLead" :  this.toggleModal("showCloseModal")
                break;
            case "appointment" : this.toggleModal("showAppointmentModal")
                break;
            case "sendEmail" : this.toggleModal("showSendEmailModal")
                break;
            case "noAnswer" : this.onNoAnswerClick()
                break;
            case "reopenLead" : this.onReopenClick()
                break;
            case "unassignLead" : this.onUnassignClick()
                break;
            case "busy" : this.onBusyClick()
                break;
            default: return null;
        }
    }

    sortListByKey(listName, list) {
        return key => {
            const { commonActions } = this.props;
            commonActions.sortListByKey(list, key, listName);
        }
    }

    onCloseDetails = () => {
        const { leadActions } = this.props;
        leadActions.clearLead();
        this.props.navigate(routes.leads);
    }

    render() {
        const { lead, t } = this.props;

        if (!lead)
            return null;

        const detailsWidth = 12;

        const rightContainerWidth = 12;

        let actionDropdownOptions = [];

        if(lead.assignee){
            actionDropdownOptions.push({ title: 'leaddetailscontainer.unassignlead', identifier: "unassignLead" })
        }

        let additionalDropdownOptions = [
            { title: 'appointmentdetailactions.busy', identifier: "busy" },
            { title: 'leaddetailscontainer.noanswer', identifier: "noAnswer" },
            { title: 'leaddetailscontainer.sendemail', identifier: "sendEmail" },
            { title: 'leaddetailscontainer.createappointment', identifier: "appointment" },
            { title: 'leaddetailscontainer.closelead', identifier: "closeLead" }
        ];

        actionDropdownOptions = actionDropdownOptions.concat(additionalDropdownOptions)


        const placeOrderOptions =  [
            { title: 'leaddetailscontainer.placeorder', identifier: "order"},
            { title: 'leaddetailscontainer.manualorder', identifier: "manualOrder" }];

        if(lead.customerNumber !== null)
            actionDropdownOptions.unshift(...placeOrderOptions);

        const actionDropdownOptionsWhenLeadIsClosed = [
            {title: t('leaddetailscontainer.reopen'), identifier: 'reopenLead'}
        ];

        if(lead.isClosed)
            actionDropdownOptions = actionDropdownOptionsWhenLeadIsClosed;
            
        return (
            <div className="row lead-panel">
                {this.state.showSendEmailModal && <SendEmailModal leadType="lead" lead={lead} onClose={this.toggleModal.bind(this, "showSendEmailModal")} onSave={() => this.onCloseClick("showSendEmailModal")} emailActionLogId={this.state.emailActionLogId} />}
                {this.state.showAppointmentModal && <AppointmentModal leadType="lead" lead={lead} onClose={this.toggleModal.bind(this, "showAppointmentModal")} onComplete={() => this.onCloseClick()} />}
                {this.state.showCloseModal && <CloseModal lead={lead} onClose={this.toggleModal.bind(this, "showCloseModal")} onSave={() => this.onCloseClick()} />}
                {this.state.showBuyModal && <BuyModal lead={lead} onClose={this.toggleModal.bind(this, "showBuyModal")} onSave={() => this.onCloseClick()} />}

                <div className={`col-${detailsWidth} p-0`}>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            {!lead.isClosed && <PanelActions editLink={routes.leadEdit(lead.id)} onRefresh={this.refresh} />}
                            <h3 className="panel-title">{t('leaddetailscontainer.leaddetails')}</h3>
                        </div>
                        <div className="panel-body">
                            <div className="btn-toolbar">
                                <div role="group">
                                    {!lead.assignee && !lead.isClosed && <button type="button" className="btn btn-default" onClick={this.onAssingButtonClick}>
                                            <span className="vismaicon vismaicon-sm vismaicon-user"></span>{t('leaddetailscontainer.assigntome')}
                                    </button>}
                                    {!lead.isClosed &&<PhoneLink phoneNumber={lead.phoneNumber} button={true} label={t('leaddetailscontainer.callcustomer')} icon={true} />}
                                    <ButtonDropdown title={t('leaddetailscontainer.actions')} disabled={false} options={actionDropdownOptions} onClickAction={this.onBuyClick} defaultStyle={false}>{t('leaddetailscontainer.actions')}</ButtonDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <LeadForm
                                lead={lead}
                                onFieldChange={() => { }}
                                readonly={true}
                                onCancelClick={() => this.onCloseClick()}
                                onReopenClick={this.onReopenClick}
                                onAssingButtonClick={this.onAssingButtonClick}
                                onCloseClick={this.onCloseDetails}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 p-0">
                    <RelatedLeads leads={lead.leadsForCustomerEmail} moveToDetails={(leadId) => this.props.moveToDetails(leadId)} />
                </div>

                <div className={`col-${rightContainerWidth} p-0`} >
                    <div className="row">
                        <div className="col-6 p-0">
                            <ActionHistory actionHistory={lead.actionHistory} toggleModal={(modalId, emailActionLogId) => this.toggleModal(modalId, emailActionLogId)} />
                        </div>
                        <div className="col-6 p-0">                    
                            <LeadCustomers lead={lead}/>
                        </div>
                    </div>
                    {/* TODO add customerproducts here */}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lead: state.LeadsReducer.lead,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadDetailsContainer));