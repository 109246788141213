import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import moment from "moment";
import leadActions from "../../Actions/leadActions";

import MyStatsGraph from "../MyStatsGraph";

import {
  handleClick,
  handleDataFromTime,
  countOccurrenceOnDate,
  matchDates,
  getTimePeriod,
} from "./graphHelpers";

class AoLeadsTeamGraph extends Component {
  state = {
    data: [],
    timePeriod: "month",
  };

  async componentDidMount() {
    const { leadActions, user } = this.props;
    await leadActions.getOpenAoLeads();
    this.setState({
      ...this.state,
      data: handleDataFromTime(
        this.props.openAoLeadsTeam.filter((lead) => lead.id === user.id),
        moment().startOf("month"),
        moment().endOf("month"),
        "dateCreated"
      ),
    });
  }

  clickFunc = (event) => {
    const { value } = event.target;

    this.setState({
      ...this.state,
      data: handleClick(
        event,
        this.props.openAoLeadsTeam.filter(
          (lead) => lead.id === this.props.user.id
        ),
        "dateCreated"
      ),
    });

    this.setState((state) => ({ timePeriod: value }));
  };

  render() {
    const { openAoLeadsTeam, t } = this.props;

    if (!openAoLeadsTeam) {
      return null;
    }

    const finalData = matchDates(
      getTimePeriod(this.state.timePeriod),
      countOccurrenceOnDate(this.state.data, "dateCreated"),
      "dateCreated",
      "occurrence"
    );

    return (
      <MyStatsGraph
        typeOfGraph='Line'
        labels={finalData.map(({ date }) => date)}
        data={
          finalData.every(({ value }) => value === 0)
            ? []
            : finalData.map(({ value }) => value)
        }
        timePeriod={this.state.timePeriod}
        title={t("graph.openAoLeadsTeam")}
        backgroundColor={["rgb(245, 245, 245)"]}
        onClick={(event) => this.clickFunc(event)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.CommonReducer.user,
    openAoLeadsTeam: state.LeadsReducer.openAoLeads,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadActions: bindActionCreators(leadActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AoLeadsTeamGraph));
