import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import React, {useEffect, useState} from "react";
import { bokaMeraActions } from "../../Actions/bokaMeraActions";
import helpers from "../../Utils/helpers";
import { NoInformation } from "../../Components/Panel";
import Modal from "../../Components/Modal";
import DateTimeInput from "../../Components/DateTimeInput";
import TextInput from "../../Components/TextInput";
import AlertSmall from "../../Components/AlertSmall";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";

function BokaMeraAdminContainer({invoiceDates, bokaMeraActions, errorOrders, user}) {

    useEffect(() => {
        bokaMeraActions.getDates();
    }, [])

    const [showInvoicingModal, setInvoicingModalOpen] = useState(false);
    const [showAddReferenceModal, setAddReferenceModelOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [openListItems, setOpen] = useState([])

    if(!invoiceDates)
        return;

    const handleOpenListItems = (invoiceDate) => {
        var array = [...openListItems]; // make a separate copy of the array
        var index = array.indexOf(invoiceDate)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(invoiceDate);
        }
        setOpen(array);
    }

    const openAddReferenceModel = (item) => {
        setSelectedCustomer(item);
        setAddReferenceModelOpen(true);
    }

    const errorOrdersForDate = (invoiceDate) => {

        const errors = errorOrders.filter(e => e.invoiceDate === invoiceDate)

        if(!errors.length)
            return <NoInformation />

        return <>
            <h2>Boka mera order errors</h2>
            <div className="table-responsive">
            <table className="table table-stripe table-condensed table-stripe" style={{whiteSpace: "nowrap"}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Kundnummer</th>
                    <th>CompanyId</th>
                    <th>Error</th>
                    <th>CompanyId</th>
                    <th>Orgnr</th>
                    <th>InvoiceId</th>
                    <th>Name</th>
                    <th>Adress 1</th>
                    <th>Adress 2</th>
                    <th>Postort</th>
                    <th>Postnr</th>
                    <th>Landskod</th>
                    <th>Valuta</th>
                    <th>Billing</th>
                    <th>Adress 1</th>
                    <th>Adress 2</th>
                    <th>Postnr</th>
                    <th>Postort</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {errors.map((item, key) => <tr key={key}>
                    <td><button className="btn btn-default" onClick={() => openAddReferenceModel(item)}>Uppdatera kundnummer</button></td>
                    <td>{item.customerNumber}</td>
                    <td>{item.companyId}</td>
                    {item.errors.map((text, key) => <td key={key}>{text}</td>)}
                </tr>)}
                </tbody>
            </table>
        </div></>
    }

    const modalProps = {
        title: "Skapa fakturering för BokaMera",
        onCancelClick: () => setInvoicingModalOpen(false),
        stateful: true,
        open: true,
        showButtons: true,
        mainButtonTitle: "Starta fakturering",
        disableMainButton: !invoiceDate,
        onSaveClick: () => runInvoicing(),
    }

    const addReferenceModalProps = {
        title: "Lägg till referens för en kund",
        onCancelClick: () => setAddReferenceModelOpen(false),
        stateful: true,
        open: true,
        showButtons: true,
        mainButtonTitle: "Spara",
        disableMainButton: false, // TODO
        onSaveClick: () => bokaMeraActions.addReference(selectedCustomer)
    }

    const getDetails = (invoiceDate) => {
        handleOpenListItems(invoiceDate);

        const exist = openListItems.some(item => item === invoiceDate);

        if(exist)
            return; // This is to prevent an extra backend call when collapsing the list item

        bokaMeraActions.getInvoiceDetails(invoiceDate);
    }

    const runInvoicing = () => {
        setInvoicingModalOpen(false);
        var date = new Date(invoiceDate); // For converting string "2023 11 30" to dateTime
        bokaMeraActions.runInvoicing(date.toLocaleDateString("se-SV"));
    }

    const changeCustomerNumber = (value) => setSelectedCustomer({...selectedCustomer, customerNumber: value})

    return <>

        <button type="button" className="btn btn-primary" onClick={() => setInvoicingModalOpen(true)}>Skapa ny fakturering</button>

        {invoiceDates.map((item, key) => <details className="collapse-list" key={key}>
            <summary className="collapse-list-header" onClick={() => getDetails(helpers.formatDate(item.invoiceDate))}>
                <div className="collapse-list-title d-flex align-items-center"
                     style={{justifyContent: "space-between"}}>
                            <span>
                                Fakturadatum: {helpers.formatDate(item.invoiceDate)}
                            </span>
                    <span>{item.totalCount > 0 && <span className="badge badge-success">{item.totalCount}</span>}
                        {item.errorCount > 0 && <span className="badge badge-danger">{item.errorCount}</span>}
                </span>
                    <span className="caret"></span>
                </div>
            </summary>
            <div className="collapse-list-content">
                {errorOrdersForDate(item.invoiceDate)}
            </div>
        </details>)}

        {showInvoicingModal && <Modal {...modalProps}>
            <DateTimeInput showTime={false} closeOnSelect={true} label="Fakturadatum"
                           onChange={(value) => setInvoiceDate(value)} value={invoiceDate}/>
        </Modal>}

        {showAddReferenceModal && <Modal {...addReferenceModalProps}>

            <form className="form-horizontal">

                <TextInput label="CompanyId" disabled={true} value={selectedCustomer.companyId} copyToClipBoard={true}/>
                <TextInput label="Organisationsnummer" disabled={true} value={selectedCustomer.organizationNumber}
                           copyToClipBoard={true}/>
                <TextInput label="Kundnummer" value={selectedCustomer.customerNumber} onChange={changeCustomerNumber}/>
            </form>
            <AlertSmall alert={{ type: "info", message: "När du sparar uppdateras kundnummer hos BokaMera"}} />
        </Modal>}
    </>
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        invoiceDates: state.BokaMeraReducer && state.BokaMeraReducer.invoiceDates,
        errorOrders: state.BokaMeraReducer && state.BokaMeraReducer.errorOrders
    }
}

function mapDispatchToProps(dispatch) {
    return {
        bokaMeraActions: bindActionCreators(bokaMeraActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BokaMeraAdminContainer));
