import React, { Component } from 'react';
import { connect } from 'react-redux';

import routes from '../Constants/routes'
import helpers from '../Utils/helpers'

class Login extends Component {

    render() {
        
        if(this.props.isAuthenticating) {
            return null;
        }

        //let location = (this.props.location && this.props.location.pathname) || '/';
        let location = window.location.pathname

        if(location.indexOf(routes.logout) > -1) {
            location = '/'
        }
        
        return (
            <div className="loginForm">
                <h2>Please sign in</h2>
                <div><a href={`${helpers.getFullApiHostname()}/api/auth/login?returnPath=${location}`} className='btn btn-primary btn-lg'>Login with Google</a></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticating: state.CommonReducer.authenticating
    }
}

export default connect(mapStateToProps)(Login);