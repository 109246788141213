import React, { useState } from 'react';
import Modal from '../Modal';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commonActions from '../../Actions/commonActions';
import { categorizedShortcuts, shortcuts } from '../../Constants/shortcuts';

function EditShortcutsModal({ t, commonActions, userShortcuts, onClose }) {
    const [selectedShortcuts, setSelectedShortcuts] = useState(userShortcuts);

    const modalProps = {
        title: t('editshortcutsmodal.editshortcuts'),
        mainButtonTitle: t('editshortcutsmodal.save'),
        onCancelClick: onClose,
        stateful: true,
        open: true,
        onSaveClick: () => {
            commonActions.modifyShortcuts(selectedShortcuts)
            onClose();
            window.location.reload()
        },
    };
    const addToUserShortcuts = (shortcut) => {
        setSelectedShortcuts([...selectedShortcuts, shortcut.id]);
    };

    const removeFromUserShortcuts = (shortcut) => {
        setSelectedShortcuts(selectedShortcuts.filter(s => s !== shortcut));
    };
    

    return (
        <Modal {...modalProps}>
            <div style={{maxHeight: "500px", overflowY: "scroll"}}>
                <div className='d-flex justify-content-between'>
                <div className='col-6'>
                        <h2 className='text-center'>{t('editshortcutsmodal.availableshortcuts')}</h2>
                        <div className='d-flex flex-column' style={{gap: "15px"}}>
                        {categorizedShortcuts.map((category, categoryIndex) => (
                            <div key={categoryIndex}>
                                <h3 className='bold'>{category.category}</h3>
                                {category.shortcuts.map((shortcut, shortcutIndex) => (
                                    <button className="btn btn-default w-100" disabled={selectedShortcuts.includes(shortcut.id)}key={shortcutIndex} onClick={() => addToUserShortcuts(shortcut)}>{t(shortcut.name)}</button>
                                ))}
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className='col'>
                        <h2 className='text-center'>{t('editshortcutsmodal.selectedshortcuts')}</h2>
                        {selectedShortcuts.map((shortcut, index) => (
                            <button className='btn btn-default w-100' key={index} onClick={() => removeFromUserShortcuts(shortcut)}>{t(Object.values(shortcuts).find(x => x.id === shortcut).name)}</button>
                        ))}
                    </div>
                   
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        userShortcuts: state.CommonReducer.userShortcuts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditShortcutsModal));