import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import Modal from './Modal';
import NonCustomerSignatureContainer from './NonCustomerSignatureContainer';
import NonCustomerTemplateContainer from './NonCustomerTemplateContainer';
import EmailTemplate from './EmailTemplate';
import commonActions from '../Actions/commonActions'
import MyAppointmentsFormContainer from '../Containers/Appointment/MyAppointmentsFormContainer';
import SwitchToggle from "./SwitchToggle";

class NonCustomerEmailModal extends Component {

    constructor(props) {
        super(props);

        this.initState = {
            toggleSetting: false,
            createAppointment: false
        }

        this.state = this.initState;
    }


    componentDidMount() {
        const { commonActions } = this.props;
        commonActions.startNonCustomerEmailSendingModal();
        commonActions.loadDropdownList("NonCustomerTemplates")
    }

    handleClickClose = () => {
        const { commonActions, onClose } = this.props;
        commonActions.cancelFormEditing("nonCustomerEmail", onClose)
    }

    handleFieldChange = (field, value) => {
        const { commonActions } = this.props;
        commonActions.changeMultiFormField("nonCustomerEmail", field, value)
    }

    handleClickSave() {
        const { commonActions, emailForm, appointmentForm } = this.props;

        commonActions.sendNonCustomerEmail(emailForm, appointmentForm, () => this.handleClickClose());
    }

    onTemplateChange = (id) => {
        const { commonActions } = this.props;
        if (id != '') {
            commonActions.getEmailTemplate(id);
        } else {
            commonActions.startNonCustomerEmailSendingModal();
        }
    }

    setToggleSetting = () => {
        this.setState({ toggleSetting: !this.state.toggleSetting })
    }

    render() {
        const { emailForm, templates, t } = this.props
        const modalProps = {
            title: t("noncustomeremailmodal.sendnoncustomeremail"),
            mainButtonTitle: "Send",
            onCancelClick: this.handleClickClose.bind(this),
            stateful: true,
            open: true,
            onChange: this.handleFieldChange.bind(this),
            onSaveClick: this.handleClickSave.bind(this),
            customClassName: "nonCustomerModalClass",
            showButtons: this.state.toggleSetting === false,
        }

        if (!emailForm) {
            return null;
        }

        return (
            <Modal {...modalProps}>
                <div>
                    <span className="vismaicon vismaicon-settings cursorpointer" title={t("noncustomeremailmodal.mysettings")} onClick={this.setToggleSetting}></span>
                </div>
                {this.state.toggleSetting ?
                    <div>
                        <NonCustomerSignatureContainer toggleSetting={this.setToggleSetting} />
                        <NonCustomerTemplateContainer toggleSetting={this.setToggleSetting} />
                    </div> : <><EmailTemplate templates={templates} data={emailForm.data}
                        onChange={this.handleFieldChange.bind(this)}
                        onSaveClick={this.handleClickSave.bind(this)}
                        onCancelClick={this.handleClickClose.bind(this)}
                        onTemplateChange={this.onTemplateChange.bind(this)}
                        senderEmails={emailForm.data.defaultSenderAddresses}
                        onSenderChange={this.handleFieldChange.bind(this)}
                        hideWarning={true} />
                        <div className='create-appointment-checkbox-container'>
                            <label className="col-sm-4 control-label">{t("noncustomeremailmodal.createappointment")}</label>
                            <SwitchToggle checked={this.state.createAppointment} value={this.state.createAppointment} onChange={() => this.setState({ createAppointment: !this.state.createAppointment })} />
                        </div>
                        {this.state.createAppointment && <MyAppointmentsFormContainer hideButtons={true} width={12} />}
                    </>}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        templates: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.NonCustomerTemplates,
        emailForm: state.CommonReducer.forms && state.CommonReducer.forms.nonCustomerEmail,
        appointmentForm: state.CommonReducer.forms && state.CommonReducer.forms.appointmentForm,
        selectedAppointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NonCustomerEmailModal));