export const VonEmployeeTypes = {
    User: { id: "0", name: 'User' },
    Auditor: { id: "1", name: 'Auditor' },
    Conversion: { id: "2", name: 'Conversion' }
}

export const newEmployee = [
    VonEmployeeTypes.User,
    VonEmployeeTypes.Auditor,
    VonEmployeeTypes.Conversion
];

export const editEmployee = [
    VonEmployeeTypes.User,
    VonEmployeeTypes.Auditor
];