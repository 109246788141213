import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import Modal from '../../Components/Modal'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import EmailTemplate from '../../Components/EmailTemplate'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'
import StaticTextInput from '../../Components/StaticTextInput'
import MessageAlertLevel from '../../Components/MessageAlertLevel'
import SwitchToggle from "../SwitchToggle";

class CompensationModal extends Component {

    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    handleFieldChange = (field, value) => {
        const { onChange, onMonthChange, form, t, reasons } = this.props;
        
        if(field === "compensationMonth")
        {
            onMonthChange(value);

            if(value === "0")
            {
                value = "";
                onChange(form.id, 'compensationPrice', '')
            }
        }

        if(field==="compensationReason" && reasons){
            var reason = reasons.find(reason => reason.id === value)
            if(reason && reason.subOptions.length > 0){
                onChange(form.id, "compensationSubReason", reason.subOptions[0].id)
            }else{
                onChange(form.id, "compensationSubReason", null)
            }
        }

        if(field === "to")
        {
            return alert(t("compensationmodal.compensationalert"));
        }
        
        if(value === "0")
        {
            value = "";
        }

        onChange(form.id, field, value);
    }

    onContactCustomerToggle() {
        const form = this.props.form
        const value = !form.data["contactCustomer"]

        this.handleFieldChange('contactCustomer', value)
        this.props.customerActions.switchToEmailAddress(value, form);
    }

    renderContactCustomerToggle(data) {
        const form = this.props.form
        if (data.product.collaborationInvoiceParty === "AO" || data.product.isBlikk) {
            if(!form.data["contactCustomer"]){
                this.onContactCustomerToggle()
            }
            return false
        }
        return true
    }

    handleSaveClick = () => {
        const {onSave, form} = this.props;
        
        const dataWithPreview = {...form.data}
        dataWithPreview.body = this.renderPreview(dataWithPreview)
        onSave(form, dataWithPreview.body);
    }

    handleCloseClick = () => {
        const { onClose, form } = this.props;
        
        onClose(form.id);
    }

    renderPreview(data) {
        const { reasons } = this.props
        let newBody = data.body_copy
        data.rules.forEach(r => {
            if(r.id === 'compensationReason')
                newBody = newBody.replace(`[${r.id}]`, reasons[data['compensationReason'] || 0].name || '')
            else
                newBody = newBody.replace(`[${r.id}]`, data[r.id] || '')
        })
        return newBody
    }

    renderSubOptions(data){
        const { reasons, t } = this.props
        if(reasons)
        var reason = reasons.find(reason => reason.id === data.compensationReason)

        if(reason != undefined && reason.subOptions.length > 1){
            return <DropDown label={t('compensationmodal.compensationsubreason')} onChange={value => this.handleFieldChange('compensationSubReason', value)} list={reason.subOptions} value={reason.subOptions[0].Id} />
        }
    
    }

    isComplaint(compensationReason) {
        const { reasons } = this.props
        
        if(reasons)
        var reason = reasons.find(reason => reason.id === compensationReason)
        if (reason && reason.name === "Klagomål") 
            return true
    }

    isComplaintOrContactCustomer(data){
        const {form} = this.props
        if(this.isComplaint(data.compensationReason) || data.contactCustomer){
            if(!data.validationAllFields){
                this.props.customerActions.switchCompensationValidation(true, form)
                this.props.commonActions.changeMultiFormField(form.id, 'compensationPrice', "");
                this.props.commonActions.changeMultiFormField(form.id, 'compensationMonth', "");
            }
            if(!form.data["contactCustomer"]){
                this.onContactCustomerToggle()
            }
            return false
        }else{
            if(data.validationAllFields)
                this.props.customerActions.switchCompensationValidation(false, form);
            return true
        }
    }

    render() {

        const { onClose, form, reasons, months, aoRestriction, t } = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        var isComplaintOrContactCustomer = this.isComplaintOrContactCustomer(data)

        const modalProps = {
            title: t('compensationmodal.compensation'),
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.handleFieldChange,
            onCancelClick: () => onClose(form.id),
            onSaveClick: this.handleSaveClick
        }

        const dataWithPreview = {...data}
        dataWithPreview.body = this.renderPreview(dataWithPreview)

        return (
            <Modal {...modalProps}>
                <div>
                <div>
                    <form className="form-horizontal">
                        <fieldset className="margin-top">
                            <legend>{t('compensationmodal.compensates') + `${data.product.product}`}</legend>
                            <div className="form-group">
                                <div className="col-xs-12">
                                {aoRestriction && 
                                        <div>
                                            <MessageAlertLevel message={t('compensationmodal.accountingofficemessage') + dataWithPreview.to}/>
                                            <div className="form-group"></div>
                                        </div>
                                }
                                
                                {!aoRestriction && this.renderContactCustomerToggle(data) && 
                                        <div className="form-group">
                                            <label className="col-xs-4 control-label">{t('compensationmodal.contactcustomer')}</label>
                                            <div className="col-xs-8">
                                                <SwitchToggle checked={data.contactCustomer} onChange={this.onContactCustomerToggle.bind(this)} value={data.contactCustomer} />
                                            </div>
                                        </div>
                                }
                                    <DropDown label={t('compensationmodal.compensationreason')} onChange={value => this.handleFieldChange('compensationReason', value)} list={reasons} value={reasons[0].Id} />
                                    {this.renderSubOptions(data)}
                                    {isComplaintOrContactCustomer && <DropDown label={t('compensationmodal.months')} onChange={value => this.handleFieldChange('compensationMonth', value)} list={months} value={months[0].Id} />}
                                    <TextInput label={t('compensationmodal.salesforceticket')} onChange={value => this.handleFieldChange('salesforceTicketLink', value)} value={data.salesforceTicketLink}/>
                                    {isComplaintOrContactCustomer && <StaticTextInput label={t('compensationmodal.compensationsum')} value={data.compensationPrice}/>}
                                    <TextInput label={t('compensationmodal.comment')} maxAmountOfCharacters={250} onChange={value => this.handleFieldChange('compensationComment', value)} value={data.compensationComment} multiline={true}/>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <EmailTemplate data={dataWithPreview} onChange={(field, value) => this.handleFieldChange(field, value)} />
                </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentReason: state.CustomerReducer && state.CustomerReducer.currentCompensationReasonId
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompensationModal));