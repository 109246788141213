import { useState } from "react";
import helpers from "../../../Utils/helpers";
import { useTranslation } from "react-i18next";

export function ActivityLogTable({ activityLogs, ...props }) {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleRowClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const noLogsFound = () => {
        return <div className="w-100 text-center">
            <p>{t("activitylogcontainer.nologsfound")}</p>
        </div>;
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedLogs = [...activityLogs.data].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            if (aValue === null || aValue === undefined) return sortConfig.direction === 'asc' ? -1 : 1;
            if (bValue === null || bValue === undefined) return sortConfig.direction === 'asc' ? 1 : -1;

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
        }
        return 0;
    });

    const getSortIconClass = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? "float-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "float-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
        }
        return '';
    };

    const renderHeader = (key, label) => (
        <th className="col-1" onClick={() => handleSort(key)}>
            {label}
            {sortConfig.key === key && <i className={getSortIconClass(key)}></i>}
        </th>
    );

    if (activityLogs.data.length === 0) {
        return noLogsFound();
    }

    return (
        activityLogs.type === 'failedMails' ? (
            <FailedMailsTable
                sortedLogs={sortedLogs}
                activeIndex={activeIndex}
                handleRowClick={handleRowClick}
                renderHeader={renderHeader}
                {...props}
            />
        ) : (
            <GeneralActivityTable
                sortedLogs={sortedLogs}
                activeIndex={activeIndex}
                handleRowClick={handleRowClick}
                renderHeader={renderHeader}
                {...props}
            />
        )
    );
}

function GeneralActivityTable({ sortedLogs, activeIndex, handleRowClick, renderHeader, ...props }) {
    const { t } = useTranslation();

    return (
        <table className="table table-stripe skynet-table-no-divider table-active">
            <thead>
                <tr>
                    {renderHeader('performerLastName', t('activitylogtable.lastname'))}
                    {renderHeader('performerFirstName', t('activitylogtable.firstname'))}
                    {renderHeader('affectedCustomerName', t('activitylogtable.company'))}
                    {renderHeader('eventResource', t('activitylogtable.event'))}
                    {renderHeader('logDateLocal', t('activitylogtable.date'))}
                </tr>
            </thead>
            <tbody>
                {sortedLogs && sortedLogs.map((log, index) => (
                    <ActivityLogRow
                        key={index}
                        log={log}
                        index={index}
                        isActive={index === activeIndex}
                        onRowClick={() => handleRowClick(index)}
                        {...props}
                    />
                ))}
            </tbody>
        </table>
    );
}

function FailedMailsTable({ sortedLogs, activeIndex, handleRowClick, renderHeader, ...props }) {
    const { t } = useTranslation();

    return (
        <table className="table table-stripe skynet-table-no-divider table-active">
            <thead>
                <tr>
                    {renderHeader('eventDate', t('activitylogtable.date'))}
                    {renderHeader('emailAddress', t('activitylogtable.email'))}
                    {renderHeader('event', t('activitylogtable.event'))}
                </tr>
            </thead>
            <tbody>
                {sortedLogs && sortedLogs.map((log, index) => (
                    <FailedMailRow
                        key={index}
                        log={log}
                        index={index}
                        isActive={index === activeIndex}
                        onRowClick={() => handleRowClick(index)}
                        {...props}
                    />
                ))}
            </tbody>
        </table>
    );
}

function ActivityLogRow({ log, isActive, onRowClick }) {
    const { t } = useTranslation();

    function renderField(label, value) {
        return (
            <div className="d-flex flex-row mb-8">
                <p className="col-2 mb-0 font-family-ubuntu-semibold">{label}</p>
                <p className="mb-0">{value}</p>
            </div>
        );
    }

    return (
        <>
            <tr className={isActive ? 'active' : ''} onClick={onRowClick}>
                <td>
                    <span className={`caret caret-lg ${isActive ? 'open' : ''}`}></span>
                    <div className="ml-24 d-inline-block">{log.performerLastName}</div>
                </td>
                <td>{log.performerFirstName}</td>
                <td>{log.affectedCustomerName}</td>
                <td>{log.eventResource}</td>
                <td>{helpers.formatDateAndTime(log.logDateLocal)}</td>
            </tr>
            {isActive && (
                <tr>
                    <td colSpan={12} style={{ pointerEvents: "none" }}>
                        <div className="my-12">
                            {renderField(t('activitylogtable.affecteduser'), `${log.affectedFirstName ? (log.affectedFirstName + " " + log.affectedLastName) : "Okänd användare"} , ${log.affectedEmail ?? 'Okänd e-postadress'} , ${log.affectedCustomerName ?? 'Okänt företag'}`)}
                            {renderField(t('activitylogtable.performedbyuser'), `${log.performerFirstName ? (log.performerFirstName + " " + log.performerLastName) : "Okänd användare"} , ${log.performerEmail ?? 'Okänd e-postadress'}`)}
                            {renderField(t('activitylogtable.performedfrom'), `${log.performedFrom ?? 'Okänd användare'}`)}
                            {renderField(t('activitylogtable.ipaddress'), `${log.currentUserIp ?? ''}`)}
                            {renderField(t('activitylogtable.webbrowser'), `${log.userAgent ?? ''}`)}
                            {renderField(t('activitylogtable.service'), `${log.applicationName ?? ''}`)}
                            {renderField(t('activitylogtable.partofservice'), `${log.partResource ?? ''}`)}
                            {renderField(t('activitylogtable.event'), `${log.eventResource ?? ''}`)}
                            {renderField(t('activitylogtable.oldvalue'), `${log.valueBefore ?? ''}`)}
                            {renderField(t('activitylogtable.newvalue'), `${log.valueAfter ?? ''}`)}
                            {renderField(t('activitylogtable.date'), `${helpers.formatDateAndTime(log.logDateLocal) ?? ''}`)}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

function FailedMailRow({ log, isActive, onRowClick }) {
    const { t } = useTranslation();

    function renderField(label, value) {
        return (
            <div className="d-flex flex-row mb-8">
                <p className="col-2 mb-0 font-family-ubuntu-semibold">{label}</p>
                <p className="mb-0">{value}</p>
            </div>
        );
    }

    return (
        <>
            <tr className={isActive ? 'active' : ''} onClick={onRowClick}>
                <td>
                    <span className={`caret caret-lg ${isActive ? 'open' : ''}`}></span>
                    <div className="ml-24 d-inline-block">{helpers.formatDateAndTime(log.eventDate)}</div>
                </td>
                <td>{log.emailAddress}</td>
                <td>{log.event}</td>
            </tr>
            {isActive && (
                <tr>
                    <td colSpan={12} style={{ pointerEvents: "none" }}>
                        <div className="my-12">
                            {renderField(t('activitylogtable.date'), `${helpers.formatDateAndTime(log.eventDate)}`)}
                            {renderField(t('activitylogtable.email'), `${log.emailAddress}`)}
                            {renderField(t('activitylogtable.event'), `${log.event}`)}
                            {renderField(t('activitylogtable.ipaddress'), `${log.ip ?? ''}`)}
                            {renderField(t('activitylogtable.tlsused'), `${log.tlsUsed ? t('activitylogtable.yes') : t('activitylogtable.no')}`)}
                            {renderField(t('activitylogtable.certerror'), `${log.cert_Error ? t('activitylogtable.yes') : t('activitylogtable.no')}`)}
                            {renderField(t('activitylogtable.status'), `${log.status ?? ''}`)}
                            {renderField(t('activitylogtable.reason'), `${log.reason ?? ''}`)}
                            {renderField(t('activitylogtable.type'), `${log.type ?? ''}`)}
                            {renderField(t('activitylogtable.attempt'), `${log.attempt}`)}
                            {renderField(t('activitylogtable.response'), `${log.response ?? ''}`)}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}
