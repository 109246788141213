import React from "react";
import { useTranslation } from "react-i18next";

export function NoInformation({renderNoData}) {
    
    const { t } = useTranslation();
    
    if (!renderNoData)
        renderNoData = () => <p><i>{t('common.noinformationavailable')}</i></p>
    
    return renderNoData();
}

export default function Panel({title, children, isNoData, renderNoData}) {
    
    return <div className="panel">  
        {title && <div className="panel-heading">
            <h3 className="panel-title">{title}</h3>
        </div>
        }
        <div className="panel-body">
            {isNoData ? <NoInformation renderNoData={renderNoData} /> : children}
        </div>
    </div>
}

export function PanelGrid({children, isNoData, renderNoData, columnWidth}) {

    return <div className={`col-sm-12 col-md-${columnWidth} p-0`}>
        {React.Children.toArray(children).map((element, index) => <Panel key={index} title={element.props.title} isNoData={isNoData} renderNoData={renderNoData}>
            {element}</Panel>)}
    </div>
}
