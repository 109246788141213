import React, { useState } from "react";
import { Link } from 'react-router-dom'
import BadgeCounter from './BadgeCounter'

function TopNavigationBarItem({ item, isTopNav, isMainDropDown }) {

    const [showDropDown, setShowDropDown] = useState(false);

    const getTitle = () => {

        return ( <>
            {item.icon &&
                <span className={`vismaicon ${!isTopNav ? "vismaicon-sm" : "" } vismaicon-dynamic vismaicon-menu ${item.icon}`} aria-expanded={true}></span>}
                {!isTopNav && item.title}
               <BadgeCounter badgeNumber={item.badgeNumber} />
            </>
        )
    }

    const getClassList = () => {
        const classes = [];

        if (item.dropdownItems) {
            classes.push("menudrop dropdown");
        }
        if (item.icon && isTopNav) {
            classes.push("icon");
        }

        return classes.join(" ");
    }

    const handleDropDown = (show) => {
        if (isMainDropDown) {
            setShowDropDown(show);
        }
    }

    return (
        <li className={`${getClassList()}${showDropDown ? " open" : ""}`} onMouseEnter={() => handleDropDown(true) } onMouseLeave={() => handleDropDown(false)}>
            
            {item.path && <Link onClick={item.onClick} className={`${item.className? item.className : ""} text-nowrap`} to={item.path}>{getTitle()}</Link>}
            
            {!item.dropdownItems && !item.path && item.onClick && <a onClick={item.onClick} >{getTitle()}</a>}
            
            {item.dropdownItems && <a className="text-nowrap" onClick={() => setShowDropDown(!showDropDown) } >{getTitle()}{!isMainDropDown && <span className="caret"></span>}</a>}
            
            {item.dropdownItems &&
                <ul className={`dropdown-menu ${item.className? item.className : ""}`}>
                    {item.dropdownItems.map((dropdownItem, index) => {
                        return <TopNavigationBarItem key={index} item={dropdownItem} isMainDropDown={false} />
                    })}
                </ul>
            }
        
        </li>
    );
}

export default TopNavigationBarItem;