import routes from "./routes";

export const shortcuts = {
    Customer: { id: 1, name: 'editshortcutsmodal.customer', route: routes.customerMain(':customerNumber')},
    Editcustomer: { id: 2, name: 'editshortcutsmodal.editcustomer', route: routes.customerEdit(':customerNumber')},
    Relatedcustomer: { id: 3, name: 'editshortcutsmodal.relatedcustomer',route: routes.relatedCustomers(':customerNumber')},
    Agreements: { id: 4, name: 'editshortcutsmodal.agreements', route: routes.customerAgreements(':customerNumber')},
    Vonaccount: { id: 5, name: 'editshortcutsmodal.vonaccount', route: routes.customerVonAccounts(':customerNumber')},
    Collaborations: { id: 6, name: 'editshortcutsmodal.collaborations', route: routes.customerCollaborations(':customerNumber')},
    Placeorder: { id: 7, name: 'editshortcutsmodal.placeorder', route: routes.orderPlacement(':customerNumber')},
    Leads: { id: 8, name: 'editshortcutsmodal.leads', route: routes.customerLeads(':customerNumber')},
    Appointments: { id: 9, name: 'editshortcutsmodal.appointments', route: routes.customerAppointments(':customerNumber')},
    Campaigns: { id: 10, name: 'editshortcutsmodal.campaigns', route: routes.customerCampaign(':customerNumber')},
    Bookeducation: { id: 11, name: 'editshortcutsmodal.bookeducation', route: routes.educations(':customerNumber')},
    Attendededucations: { id: 12, name: 'editshortcutsmodal.attendededucations', route: routes.customerAttendedEducations(':customerNumber')},
    Emailforms: { id: 13, name: 'editshortcutsmodal.emailforms', route: routes.customerAdminForms(':customerNumber')},
    Actionhistory: { id: 14, name: 'editshortcutsmodal.actionhistory', route: routes.customerActions(':customerNumber')},
    Desktopversion: { id: 15, name: 'editshortcutsmodal.desktopversion', route: routes.customerSendDesktopVersion(':customerNumber')},
    Compensation: { id: 16, name: 'editshortcutsmodal.compensation', route: routes.customerCompensation(':customerNumber')},
    Consulting: { id: 17, name: 'editshortcutsmodal.consulting', route: routes.customerConsulting(':customerNumber')},
    Invoices: { id: 18, name: 'editshortcutsmodal.invoices', route: routes.customerInvoices(':customerNumber')},
    Salesforce: { id: 19, name: 'editshortcutsmodal.salesforce', route: 'https://vismaspcsab.my.salesforce.com/', redirect: true}
};
export const categorizedShortcuts = [
    {
        category: 'Customer',
        shortcuts: [
            shortcuts.Customer,
            shortcuts.Editcustomer,
            shortcuts.Relatedcustomer,
            shortcuts.Agreements
        ]
    },
    {
        category: 'VON',
        shortcuts: [
            shortcuts.Vonaccount,
            shortcuts.Collaborations
        ]
    },
    {
        category: "Sales",
        shortcuts: [
            shortcuts.Placeorder,
            shortcuts.Leads,
            shortcuts.Appointments,
            shortcuts.Campaigns
        ]
    },
    {
        category: "Education",
        shortcuts: [
            shortcuts.Bookeducation,
            shortcuts.Attendededucations
        ]
    },
    {
        category: "Email forms",
        shortcuts: [
            shortcuts.Emailforms
        ]
    },
    {
        category: "Admin",
        shortcuts: [
            shortcuts.Actionhistory,
            shortcuts.Desktopversion,
            shortcuts.Compensation,
            shortcuts.Consulting,
            shortcuts.Invoices
        ]
    },
    {
        category: "Additional links",
        shortcuts: [
            shortcuts.Salesforce
        ]
    }
];