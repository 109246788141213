import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import quotationActions from '../../Actions/quotationActions'
import commonActions from '../../Actions/commonActions'
import {FormEditor} from '../../Components/Editor'
import TextInput from '../../Components/TextInput'

import '../../Components/QuotationFAQ.css'

import routes from '../../Constants/routes'

class QuotationFaqContainer extends Component {

    componentDidMount() {
        const { quotationActions } = this.props;

        quotationActions.getFaq();
    }

    handleClickClose = () => {
        const { quotationActions } = this.props;

        quotationActions.clearAnswer();
    }

    startFaqForm(question)
    {
        if(question === "")
        {
            question = {
                question: "",
                answer: "",
                id: 0
            }
        }

        this.props.quotationActions.startFaqForm(question);
    }

    onFieldChange = (field, value) => {
        const { commonActions, faqForm } = this.props;

        commonActions.changeMultiFormField(faqForm.id, field, value)
    }

    reloadWithMessage(message)
    {   
        const theMessage = localStorage.setItem('quotationReturnMessage', message);
        window.location.reload(() => this.showMessage(theMessage));
    }

    showReturnMessage(returnMessage)
    {
        const { commonActions } = this.props;

        commonActions.showSuccess(returnMessage);

        localStorage.removeItem('quotationReturnMessage');
    }

    saveFaq()
    {
        const { quotationActions, faqForm} = this.props;
        quotationActions.saveFaq(faqForm, () => this.reloadWithMessage('FAQ has been successfully saved!'));
    }

    deleteFaq(id)
    {
        const { quotationActions } = this.props;
        quotationActions.deleteFaq(id, () => this.reloadWithMessage('FAQ has been successfully deleted!'));
    }

    renderEditFields(data)
    {
        const {t} = this.props
        return(
            <div>
                <form className="form-horizontal text-wrap col-md-12">
                    <TextInput label={t('quotationfaqcontainer.question')} labelWidth={1} textInputWidth={11} value={data.question} onChange={(value) => this.onFieldChange("question", value)} />
                    <FormEditor value={data.answer} onChange={(value) => this.onFieldChange("answer", value)} />
                        <button type="button" className="btn btn-primary" style={{ marginRight: 20 }} onClick={() => this.saveFaq()}>{t('quotationfaqcontainer.save')}</button>
                        {data.id !== 0 ? <button type="button" className="btn btn-danger" onClick={() => this.deleteFaq(data.id)}>{t('quotationfaqcontainer.delete')}</button> : null}
                </form>
            </div>
        )
    }

    render() {
        const { faq, faqForm, t } = this.props;

        const returnMessage = localStorage.getItem('quotationReturnMessage');

        if(returnMessage !== "" && returnMessage !== null)
            this.showReturnMessage(returnMessage);

        if(!faq)
            return null;

        const data = faqForm && faqForm.data

        return (
            <div>
                <fieldset>
                    <legend>{t('quotationfaqcontainer.quotationfaq')}</legend>
                    <div>
                        <form className="form-horizontal text-wrap col-md-12">
                            <div>
                                <div className="form-horizontal text-wrap col-md-10">
                                    <button type="button" className="btn btn-primary" onClick={() => this.startFaqForm("")}>{t('quotationfaqcontainer.createnew')}</button>
                                </div>
                            </div>
                            {
                                faq.map((question, index) => {
                                    return (
                                        <div key={index} className="col-md-6">
                                            <button type="button" className="btn btn-primary btn-sm btn-block btn-outline btn-wrap-text" data-toggle="tooltip" title={question.question} onClick={() => this.startFaqForm(question)}>{question.question}</button>
                                        </div>
                                    )
                                })
                            }
                        </form>
                    </div>
                    {data && this.renderEditFields(data)}
                </fieldset>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        faq: state.QuotationReducer.quotationFaq && state.QuotationReducer.quotationFaq,
        faqForm: state.CommonReducer.forms && state.CommonReducer.forms.quotationFaqForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        quotationActions: bindActionCreators(quotationActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuotationFaqContainer));