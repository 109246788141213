import ServiceAccordion from "../../Components/Discounts/ServiceAccordion";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import ArticleListContainer from "./ArticleListContainer";

function DiscountArticlesContainer({ services, onChangeArticles, includedArticles }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredServices, setFilteredServices] = useState();
    const [selectedArticlesInformation, setArticleInformation] = useState([])

    useEffect(() => {
        if (services) {
          const updatedServices = {
            ...services,
            applicationsWithArticles: services.applicationsWithArticles.map(application => ({
              ...application,
              sortedArticles: application.sortedArticles.map(sortedArticle => ({
                ...sortedArticle,
                articles: sortedArticle.articles.filter(
                  article => !includedArticles.find(x => x.articleId === article.articleId)
                )
              }))
            }))
          };
      
          setFilteredServices(updatedServices);
          handleSearchInputChange(searchQuery)
        }
      }, [services, includedArticles]);
      

    useEffect(() => {
        if (services) {
            const selectedArticles = services.applicationsWithArticles
              .map(application => application.articles)
              .flat()
              .filter(article => includedArticles.find(x => x.articleId === article.articleId));
        
            setArticleInformation(selectedArticles);
          }
    }, [includedArticles, services])

    const handleSearchInputChange = (query) => {
        setSearchQuery(query);
    
        const filtered = services.applicationsWithArticles.map((application) => {
            const filteredVariants = application.sortedArticles.map((variant) => {
                const filteredArticles = variant.articles.filter((article) => {
                    return (
                        article.articleNo.toLowerCase().includes(query.toLowerCase()) &&
                        !includedArticles.find(x => x.articleId === article.articleId)
                    );
                });
                if (filteredArticles.length > 0) {
                    return {
                        ...variant,
                        articles: filteredArticles,
                    };
                } else {
                    return null;
                }
            }).filter((filteredVariant) => filteredVariant !== null);
    
            if (filteredVariants.length > 0) {
                return {
                    ...application,
                    sortedArticles: filteredVariants,
                };
            } else {
                return null;
            }
        }).filter((filteredApplication) => filteredApplication !== null);
    
        setFilteredServices({ ...services, applicationsWithArticles: filtered });
    };
    

    const onRemoveArticle = (articleId) => {
        const updatedArticles = includedArticles.filter(article => article.articleId !== articleId);
        onChangeArticles("includedArticles", updatedArticles);
      }

    const onAddArticle = (articleId) => {
        const updatedArticles = [...includedArticles];
        updatedArticles.push(articleId)
        onChangeArticles("includedArticles", updatedArticles);
    }

    return (
        <div>
            <div className='d-flex flex-row'>
                <div className="col-xs-5 mr-64">
                    <div className='mb-32'>
                        <h4 className="panel-title">Lägg till artiklar</h4>
                    </div>
                    <div className="form-group">
                        <div className='form-group d-flex'>
                            <div className="form-group search-group">
                                <input
                                    className="form-control"
                                    placeholder="Sök artikel"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => handleSearchInputChange(e.target.value)}
                                />
                                <span className="search-icon" />
                                <span className={`clear-search ${searchQuery && "show"}`} onClick={() => handleSearchInputChange('')} />
                            </div>
                        </div>
                        <ServiceAccordion services={filteredServices} searchQuery={searchQuery} onAddArticle={onAddArticle}/>
                    </div>
                </div>
                <div className="col-xs-5">
                    <div className='mb-32'>
                        <h4 className="panel-title">Tillagda artiklar</h4>
                    </div>
                    <ArticleListContainer articles={includedArticles} onDelete={onRemoveArticle} selectedArticlesInformation={selectedArticlesInformation}/>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountArticlesContainer));