import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'

import customerActions from '../../Actions/customerActions'

import helpers from '../../Utils/helpers'
import Card from '../../Components/Card/Card';
import SkynetCopy from '../../Components/Fundamentals/SkynetCopy';

function VonAccounts({ vbCustomer, customer, customerActions, searchVonAccountInput, vonAccounts, t, dataLoading, vonProducts }) {

    const [accountLines, setAccountLines] = useState([]);
    const [VismaOnlineAccountLines, setVismaOnlineAccountLines] = useState([]);
    const [showAdmins, setShowAdmins] = useState(false);
    const [groupedVonAccounts, setGroupedVonAccounts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            customerActions.setSearchVonAccountInput(null);
        }
    }, [])

    useEffect(() => {

        if (vonAccounts) {
            setAccountLineValues();
        }

    }, [vonAccounts])

    const renderAccountHeader = (item) => {
        const handleAnchorClick = (e) => {
            e.stopPropagation();
        };

        return (
            <tr key={item.id} onClick={() => navigate(routes.customerEditVonEmployee(customer.pathId, item.id))}>
                <td
                    className="text-left"
                    style={item.isPrimaryContact ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                >
                    <div className='d-flex'>

                        {item.name}
                        <div onClick={handleAnchorClick}>
                            <SkynetCopy label={t("vonaccountscontainer.name")} value={item.name} />
                        </div>
                    </div>
                </td>
                <td className="text-left">
                    <div className='d-flex'>
                        <p className='mb-0'>
                            {item.email}
                        </p>
                        <div onClick={handleAnchorClick}>
                            <SkynetCopy label={t("vonaccountscontainer.email")} value={item.email} />
                        </div>
                    </div>
                </td>
                <td colSpan={2} className="p-0">
                    <table className="table mb-0">
                        <tbody>
                            {item.roles.map((product, index) => {
                                return (
                                    <tr key={`product-${index}`}>
                                        <td width="200" className="border-0">
                                            {product.productName}
                                        </td>
                                        <td className="text-capitalize border-0">{product.role}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    };


    const onChange = (event) => {
        customerActions.setSearchVonAccountInput(event.target.value);
    }

    const filterVonAccounts = () => {
        const searchInput = searchVonAccountInput;

        if (!/\S/.test(searchInput) || typeof searchInput == "undefined" || searchInput === null) {
            return vonAccounts;
        }
        return helpers.filterVonAccountArrayByValue(vonAccounts, searchInput)
    }

    const groupVonAccounts = (vonAccounts) => {
        var arr = [];
        vonAccounts.forEach(account => {
            arr.push(...account.roles);
        });

        return arr.reduce((result, role) => {
            const product = role.productName || 'n/a';
            const specifiedProductName = role.specifiedProductName || "";
            var roles = role.role || 'n/a';
            result[product] = result[product] || [];
            result[product].specifiedProductName = specifiedProductName;
            result[product].push(roles);

            return result;

        }, Object.create(null));
    }

    const renderRoles = (accountList, product, role) => {
        return (
            <li className="list-group-item p-2" style={{ listStyle: "none" }}>
                <span className="badge">{accountList[product].length}</span>
                <span> </span>
                {!!role.specifiedProductName ? `${product} - ${role.specifiedProductName}` : product}
            </li>
        );
    }

    const pushToArray = (array) => {
        let x = 1;
        let tmp = [];

        for (var item of array) {
            tmp.push(renderAccountHeader(item, 'hl', x++))
        }
        return tmp
    }

    const setAccountLineValues = () => {
        const vonAccounts = filterVonAccounts().sort((a, b) => b.isPrimaryContact - a.isPrimaryContact);
        const customerAdminsForVismaOnline = vonAccounts.filter(a => a.roles.find(r => r.productName === 'Visma Online' && r.role === 'customeradmin'))

        const vonAdminAccountLines = pushToArray(customerAdminsForVismaOnline);
        const vonAccountLines = pushToArray(vonAccounts);

        setGroupedVonAccounts(groupVonAccounts(vonAccounts));
        setVismaOnlineAccountLines(vonAdminAccountLines);
        setAccountLines(vonAccountLines);
    }

    if (!vonAccounts) {
        return null;
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>VON Accounts</Card.Header.Title>
        </Card.Header>
        <Card.Content isLoading={dataLoading}>
            <>
                <div className='mb-10'>
                    <button className='btn btn-default' onClick={() => navigate(routes.customerNewVonEmployee(customer.pathId))}>{t('vonaccountscontainer.createnewuser')}</button>
                    <button className='btn btn-default' disabled={vbCustomer.vonProducts?.filter(product => product.licenses) <= 0} onClick={() => navigate(routes.customerAdministerVonLicenses(customer.pathId))}>Administrera licenser </button>
                </div>
                <div className="mb-10">
                    <input placeholder={t("vonaccountscontainer.searchfield")} className="form-control" type="text"
                        autoFocus value={searchVonAccountInput} onChange={onChange.bind(this)} />
                </div>
                <div className="mb-10">
                    {Object.keys(groupedVonAccounts).map((product, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                {renderRoles(groupedVonAccounts, product, groupedVonAccounts[product])}
                            </div>
                        )
                    })}
                </div>
                <br />
                <hr />
                <button className='btn btn-default'
                    onClick={() => setShowAdmins(!showAdmins)}>{showAdmins ? t("vonaccountscontainer.showall") : t("vonaccountscontainer.showadmins")}</button>
                <table className="table table-stripe table-vonAccount table-active">
                    <thead>
                        <tr>
                            <th className="text-left">{t("vonaccountscontainer.name")}</th>
                            <th className="text-left">{t("vonaccountscontainer.email")}</th>
                            <th className="text-left" width="200">{t("vonaccountscontainer.product")}</th>
                            <th className="text-left">{t("vonaccountscontainer.role")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showAdmins ? VismaOnlineAccountLines : accountLines}
                    </tbody>
                </table></>
        </Card.Content>
    </Card>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        searchVonAccountInput: state.CustomerReducer.searchVonAccountInput,
        dataLoading: state.CommonReducer.dataLoading.vonAccounts,
        vonProducts: state.CustomerReducer.vonProducts
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VonAccounts));