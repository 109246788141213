import { useTranslation } from "react-i18next";
import { BlikkTextField } from "./BlikkTextField";
import helpers from "../../../../Utils/helpers";
import Card from "../../../Card/Card";

export function BlikkProductInfoCard ({ ...props }) {

    const { t } = useTranslation();

    return <Card>
            <Card.Header>
                <Card.Header.Title>{t("blikkproductinfo.title")}</Card.Header.Title>
            </Card.Header>
            <Card.Content>
                <ProductInfo {...props} />
            </Card.Content>
        </Card>
};

function ProductInfo({ product }) {

    const { t } = useTranslation();

    return <div className='d-flex flex-column' style={{ gap: "32px" }}>
        <BlikkTextField header={t("blikkproductinfo.agreementperiod")} value={helpers.formatDate(product.currentPeriodStartDate) + " - " + helpers.formatDate(product.currentPeriodEndDate)} />
        <BlikkTextField header={t("blikkproductinfo.activeusers")} value={product.activeUsers} />
    </div>
}