import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import packageActions from "../../../Actions/Voss/packageActions";
import commonActions from "../../../Actions/commonActions";
import discountAgreementActions from "../../../Actions/Voss/discountAgreementActions";
import DiscountPricingFormContainer from "./DiscountPricingFormContainer";
import { TabsCard } from "../../../Components/Card/TabsCard";
import Card from "../../../Components/Card/Card";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../Constants/routes";
import { VossVersionStatus } from "../../../Constants/VOSS/vossVersionStatus";
import DiscountCustomerFormContainer from "./DiscountCustomerFormContainer";
import DiscountInfoFormContainer from "./DiscountInfoFormContainer";

function EditDiscountAgreementFormContainer({ ...props }) {
    const { packageActions, commonActions, form, discountAgreementActions, discountAgreement, chargePlanDiscounts, readOnly } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const discountId = searchParams.get("DiscountId")
    const versionId = searchParams.get("VersionId");

    useEffect(() => {
        discountAgreementActions.getDiscountAgreementForEdit(discountId, versionId);
        discountAgreementActions.getDiscountAgreementVersions(discountId);

        return () => {
            packageActions.clear('package');
            commonActions.cancelFormEditing('editDiscountAgreementForm');
        };
    }, []);

    useEffect(() => {
        discountAgreementActions.getDiscountAgreementForEdit(discountId, versionId);
        discountAgreementActions.getDiscountAgreementVersions(discountId);
    }, [discountId, versionId]);

    useEffect(() => {
        packageActions.getPackages();
    }, []);

    const onChange = (value, field) => {
        commonActions.changeMultiFormField('editDiscountAgreementForm', field, value);
    };

    const onAddDiscount = (discount) => {
        const chargePlanId = discount.selectedChargePlan.id;
        const productGroupId = discount.selectedProductGroupPricing;
        const productId = discount.selectedProductPricing;
        const productPricingDiscount = {
            productId,
            discountType: discount.selectedDiscountType,
            unitPriceDiscount: discount.unitPrice,
            tierPricingDiscounts: discount.tierPricingDiscounts,
        };

        const existingDiscounts = chargePlanDiscounts || [];

        const chargePlanIndex = existingDiscounts.findIndex(d => d.chargePlanId === chargePlanId);

        if (chargePlanIndex !== -1) {
            const chargePlanDiscount = existingDiscounts[chargePlanIndex];

            const productGroupIndex = chargePlanDiscount.productGroupDiscounts.findIndex(g => g.productGroupId === productGroupId);

            if (productGroupIndex !== -1) {
                const productGroupDiscount = chargePlanDiscount.productGroupDiscounts[productGroupIndex];

                const productPricingIndex = productGroupDiscount.productPricingDiscounts.findIndex(p => p.productId === productId);

                if (productPricingIndex !== -1) {
                    const existingProductPricingDiscount = productGroupDiscount.productPricingDiscounts[productPricingIndex];
                    discountAgreementActions.updateProductPricingDiscount(discountId,
                        chargePlanDiscount.id,
                        productGroupDiscount.id,
                        existingProductPricingDiscount.id,
                        productPricingDiscount,
                        chargePlanChangeCallback);
                } else {
                    discountAgreementActions.createProductPricingDiscount(discountId,
                        chargePlanDiscount.id,
                        productGroupDiscount.id,
                        productPricingDiscount,
                        chargePlanChangeCallback);
                }
            } else {
                discountAgreementActions.createProductGroupDiscount(discountId,
                    chargePlanDiscount.id,
                    {
                        productGroupId,
                        productPricingDiscounts: [productPricingDiscount],
                    },
                    chargePlanChangeCallback);
            }
        } else {
            discountAgreementActions.createChargePlanDiscount(discountId, {
                chargePlanId,
                productGroupDiscounts: [{
                    productGroupId,
                    productPricingDiscounts: [productPricingDiscount],
                }],
            }, chargePlanChangeCallback);
        }
    };

    const chargePlanChangeCallback = () => {
        discountAgreementActions.getChargePlanDiscounts(discountId, versionId)
    };

    const onDeleteChargePlanDiscount = (chargePlan) => {
        discountAgreementActions.deleteChargePlanDiscount(discountId, chargePlan.id, chargePlanChangeCallback);
    }

    const onDeleteProductPricingDiscount = (chargePlan, productGroup, product) => {
        discountAgreementActions.deleteProductPricingDiscount(discountId, chargePlan.id, productGroup.id, product.id, chargePlanChangeCallback);
    };

    const onUpdateDiscountAgreement = () => {
        discountAgreementActions.updateDiscountAgreement(discountId, versionId, form, () => { discountAgreementActions.getDiscountAgreementForEdit(discountId, versionId), discountAgreementActions.getDiscountAgreementVersions(discountId); });
    };

    const navigateToEdit = (discount) => {
        const params = new URLSearchParams();
        params.append("DiscountId", discount.id);
        versionId && params.append("VersionId", discount.versionId);
        navigate(routes.editDiscount + "?" + params.toString())
    }

    const onCreateNewVersion = () => {
        discountAgreementActions.createNewDiscountAgreementVersion(discountId, versionId, navigateToEdit);
    };

    let disableEdit = discountAgreement && discountAgreement.status !== VossVersionStatus.Draft;

    const tabs = [
        {
            name: 'discount-information',
            label: 'Discount information',
            content: <DiscountInfoFormContainer onChange={onChange} disableEdit={disableEdit} {...props} />
        },
        {
            name: 'customer-categories',
            label: 'Customer categories',
            content: <DiscountCustomerFormContainer onChange={onChange} disableEdit={disableEdit} {...props} />
        }
    ];

    if (!form) return null;

    return (
        <div>
            <h2>Edit discount agreement</h2>
            <fieldset className="d-grid gap-24 mb-0">
                <TabsCard tabs={tabs} initialTab={tabs[0].name}>
                    <Card.Footer>
                        <Card.Footer.Buttons>
                            <button className="btn btn-primary" onClick={() => navigate(routes.discounts)}>Cancel</button>
                            {discountAgreement.status != VossVersionStatus.Draft && <button className={`btn btn-primary ${readOnly ? 'disabled' : ''}`} onClick={onCreateNewVersion}>Create new version</button>}
                            <button className={`btn btn-primary active ${readOnly ? 'disabled' : ''}`} onClick={onUpdateDiscountAgreement}>Save</button>
                        </Card.Footer.Buttons>
                    </Card.Footer>
                </TabsCard>
                <div className={`${readOnly ? 'disabled' : ''}`}>
                    <Card>
                        <Card.Content>
                            <DiscountPricingFormContainer
                                onAddDiscount={onAddDiscount}
                                onDeleteChargePlanDiscount={onDeleteChargePlanDiscount}
                                onDeleteProductPricingDiscount={onDeleteProductPricingDiscount}
                                onChange={onChange}
                                disableEdit={disableEdit}
                                {...props}
                            />
                        </Card.Content>
                    </Card>
                </div>
            </fieldset>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        packages: state.PackageReducer.packages,
        selectedPackage: state.PackageReducer.package,
        form: state.CommonReducer.forms && state.CommonReducer.forms.editDiscountAgreementForm,
        discountAgreement: state.DiscountAgreementsReducer.discountAgreement,
        chargePlanDiscounts: state.DiscountAgreementsReducer.chargePlanDiscounts,
        customerSearchResults: state.CommonReducer.vossCustomerSearchResults && state.CommonReducer.vossCustomerSearchResults.results,
        discountAgreementVersions: state.DiscountAgreementsReducer.discountAgreementVersions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        packageActions: bindActionCreators(packageActions, dispatch),
        discountAgreementActions: bindActionCreators(discountAgreementActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscountAgreementFormContainer);