import { useState } from "react";

export function DropDownToggle({buttonLabel, items, itemOnClick}) {

    const [open, setOpen] = useState(false)
   
    const random = () => {
        return Math.floor(Math.random() * 100000);
    }
    
    const id = random();
    
    const toggleOpen = () => {
        setOpen(!open);
    }
    
    const onClick = (item) => {
        toggleOpen();
        itemOnClick(item);
    }
    
    return <div className="dropdown" style={{zIndex: "1"}}>
        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" id={id} onClick={toggleOpen}>{buttonLabel}
        </button>
        <ul className={`dropdown-menu ${open ? 'show' : ''}`} style={{width: "300px"}} role="menu" aria-expanded="false" aria-hidden="true" data-boundary="window"
            aria-labelledby={id}>
            {
                items.map(item => {
                    return <li key={item.label}>
                        <a onClick={() => onClick(item)} className="d-flex align-items-center justify-content-between">
                            {item.label} {item.selected && <span className="vismaicon vismaicon-sm vismaicon-ok-circle" />}
                        </a>
                    </li>
                })
            }
        </ul>
    </div>
}