export default class WebSocketHandler {

    constructor(onMessageCallback) {
        this.onMessageCallback = onMessageCallback
    }

    connect(path, email) {
        const onMessageCallback = this.onMessageCallback
        const port = window.location.port === "3000" ? ":5000" : ""

        const uri = `wss://${window.location.hostname}${port}/${path}?email=${email}`;

        this.socket = new WebSocket(uri);
        this.socket.onopen = function (event) {
            //alert("opened connection to " + uri);
        };
        this.socket.onclose = function (event) {
            //alert("closed connection from " + uri);
        };
        this.socket.onmessage = function (event) {
            onMessageCallback(event.data)
        };
        this.socket.onerror = function (event) {
        };
    }

    sendMessage(message) {
        if (this.socket.readyState === 1)
            this.socket.send(message);
    }

    close() {
        this.socket.close()
    }
}