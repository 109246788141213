import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import Modal from '../../Components/Modal'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import EmailTemplate from '../../Components/EmailTemplate'
import DropDown from '../../Components/DropDown'
import TextInput from '../../Components/TextInput'
import StaticTextInput from '../../Components/StaticTextInput'
import SwitchToggle from "../SwitchToggle";

class CompensationModal extends Component {

    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    handleFieldChange = (field, value) => {
        const { onChange, form } = this.props;

        onChange(form.id, field, value);
    }

    onPartialCreditToggle(invoice) {
        const {form, reasons, onChange} = this.props;
        const value = !form.data["partialCredit"]

        var reason = reasons[form.data.invoiceCreditReason];

        if(reason && reason.secondLevelReasons && reason.secondLevelReasons.length > 1)
            this.props.customerActions.switchInvoiceCreditValidation(value, true, form);
        else
            this.props.customerActions.switchInvoiceCreditValidation(value, false, form);

        if (value) {
            this.props.commonActions.changeMultiFormField(form.id, 'sum', "");
            onChange(form.id, 'partialCreditReadAble', 'Yes');
        }
        else
        {
            this.props.commonActions.changeMultiFormField(form.id, 'sum', invoice.amount);
            onChange(form.id, 'partialCreditReadAble', 'No');
        }
            
    }

    handleSaveClick = () => {
        const {onSave, form} = this.props;

        let dataWithPreview = {...form.data}
        dataWithPreview.body = this.renderPreview(dataWithPreview)

        onSave(form, dataWithPreview.body);
    }

    handleCloseClick = () => {
        const { onClose, form } = this.props;
        
        onClose(form.id);
    }

    renderPreview(data) {
        let newBody = data.body_copy
        if(data)
        {
            data.rules.forEach(r => {
                    newBody = newBody.replace(`[${r.id}]`, data[r.id] || '')
            })
        }
        return newBody
    }

    renderReasonDropdowns() {
        const data = this.props.form && this.props.form.data;
        const {t} = this.props
        if (!data)
            return null
        const reasons = this.props.reasons;
        const firstLevelReasonId = (data.invoiceCreditReason ? data.invoiceCreditReason : reasons[0].id);
        const firstLevelReason = reasons.find(reason => reason.id === firstLevelReasonId);

        let result = []

        result.push(<DropDown key={result.length ++} label={t('sendinvoicecreditmodal.reason1')} onChange={(value) => this.onChangeReason1('invoiceCreditReason', value)} list={reasons} value={firstLevelReasonId} />)

        if (firstLevelReason && firstLevelReason.secondLevelReasons && firstLevelReason.secondLevelReasons.length > 1) {
            const reasonCode2 = data.invoiceCreditReason2 ? data.invoiceCreditReason2 : firstLevelReason.secondLevelReasons[0].id
            result.push(<DropDown key={result.length ++} label={t('sendinvoicecreditmodal.reason2')} onChange={(value) => this.onChange('invoiceCreditReason2', value)} list={firstLevelReason.secondLevelReasons} value={reasonCode2} />)
        }

        return <div>
            {result.map(i => i)}
        </div>
    }

    onChange = (key, value) => {
        const { onChange, form, reasons} = this.props;
        if(key === 'invoiceCreditReason2')
        {
            value = parseInt(value);
            var firstReason = reasons[form.data.invoiceCreditReason];
            var secondReason = firstReason.secondLevelReasons.find(r => r.id === value);
            onChange(form.id, key.concat('Name'), secondReason.name);
            onChange(form.id, key, value);
        }
        else
            onChange(form.id, key, value);
    }

    onChangeReason1 = (key, value) => {
        const { onChange, form, reasons } = this.props; 
    
        value = parseInt(value);
        var reason = reasons.find(reason => reason.id === value);

        if (reason.secondLevelReasons && reason.secondLevelReasons.length > 1)
            this.props.customerActions.switchInvoiceCreditValidation(form.data.partialCredit, true, form);
        else
        {
            this.props.customerActions.switchInvoiceCreditValidation(form.data.partialCredit, false, form);
            onChange(form.id, 'invoiceCreditReason2', '');
            onChange(form.id, 'invoiceCreditReason2Name', '');
        }
        
        var concatName = key.concat('Name');
        onChange(form.id, concatName, reason.name);
        onChange(form.id, key, value);
    }

    render() {
        const { onClose, form, invoice, t } = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }

        const modalProps = {
            title: t('sendinvoicecreditmodal.invoicecredit'),
            stateful: true,
            open: true,
            bodyHeight: 200,
            onChange: this.handleFieldChange,
            onCancelClick: () => onClose(form.id),
            onSaveClick: this.handleSaveClick
        }

        const dataWithPreview = {...data}
        dataWithPreview.body = this.renderPreview(dataWithPreview)

        return (
            <Modal {...modalProps}>
                <div>
                <div>
                    <form className="form-horizontal">
                        <fieldset className="margin-top">
                            <legend>{t('sendinvoicecreditmodal.creditforinvoicenumber') + `${invoice.invoiceNumber}`}</legend>
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label
                                            className="col-xs-4 control-label">{t('sendinvoicecreditmodal.partialcredit')}</label>
                                        <div className="col-xs-8">
                                            <SwitchToggle checked={data.partialCredit}
                                                          onChange={this.onPartialCreditToggle.bind(this, invoice)}
                                                          value={data.partialCredit}/>
                                        </div>
                                    </div>
                                    {this.renderReasonDropdowns()}

                                    {data.partialCredit &&
                                        <TextInput label="Sum *" value={data.sum}
                                                   onChange={value => this.handleFieldChange('sum', value)}/>
                                    }
                                    {!data.partialCredit &&
                                        <StaticTextInput label="Sum" value={data.sum}/>
                                    }
                                    <TextInput label={t('sendinvoicecreditmodal.comment')}
                                               onChange={value => this.handleFieldChange('invoiceCreditComment', value)}
                                               value={data.invoiceCreditComment} multiline={true}/>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <EmailTemplate data={dataWithPreview} onChange={this.handleFieldChange} />
                </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.InvoiceCreditReasons
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompensationModal));