import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom';

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import EducationsList from '../../Components/Customer/EducationsList'

function CoursesContainer({ customerActions, commonActions, educations, vbCustomer, educationFilters  }) {
    const navigate = useNavigate();
    useEffect(() => {
        customerActions.getEducations()
    }, [])

    const fullWarning = (text, header) => {
        commonActions.showWarning(text, header)
    }

    return <EducationsList educations={educations}
                redirect={navigate}
                customerNumber={vbCustomer.customerNumber}
                warning={fullWarning}
                filters={educationFilters} />
}

function mapStateToProps(state) {
    return {
        educations: state.CustomerReducer.educations,
        educationFilters: state.CustomerReducer.educationFilters,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesContainer);