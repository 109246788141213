import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import settingsActions from "../../Actions/settingsActions";
import commonActions from "../../Actions/commonActions";
import routes from "../../Constants/routes";
import StaticTextInput from "../../Components/StaticTextInput";
import Loader from "../../Components/Loader";
import { withTranslation } from "react-i18next";
import TextInput from "../../Components/TextInput";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

const AdminEditBudgetContainer = (props) => {
  const [year, setYear] = useState(moment());
  const [i, setI] = useState(0);
  const { type, id } = useParams();
  const navigate = useNavigate();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {

    if (type === 'user') {
      props.settingsActions.getBudgetUser(id);
      props.settingsActions.getUserBudgetById(id);
    } else {
      props.settingsActions.getTeamBudgetById(id);
    }

  }, []);

  useEffect(() => {
    const formDataModel = {
      id: 0,
      userId: type != "team" ? (props.user ? props.user.id : null) : null,
      month: null,
      year: year.format("YYYY"),
      sek: 0,
      amount: 0,
      teamId: type === "team" ? id : props.user ? props.user.teamId : null,
    };

    const formData = [];
    if (props.budgetUserData) {
      for (let i = 0; i < months.length; i++) {
        const monthWithData = props.budgetUserData.budgets.find((item) => item.month === months[i] && item.year === year.format("YYYY"));
        if (monthWithData) {
          formData.push(monthWithData)
        } else {
          formData.push({ ...formDataModel, month: months[i] });
        }
      }

      props.commonActions.startFormEditing("budgetForm", formData);
    }
  }, [props.budgetUserData, props.user, year])

  const onSaveClick = () => {

    props.settingsActions.updateUserBudget(props.budgetForm, () => navigate(routes.budget));
  };

  const onCancelClick = () => {

    props.commonActions.cancelFormEditing(props.budgetForm.id, () => navigate(routes.budget));
  };

  const changeYear = (e) => {
    if (e.currentTarget.id === "sub") {
      setYear(moment(year).subtract(1, "years"));
    }
    if (e.currentTarget.id === "add") {
      setYear(moment(year).add(1, "years"));
    }
  };

  const onFieldChange = (field, arr, index, value) => {

    if (value === '') {
      value = 0
    }

    props.commonActions.changeBudgetFormArrayField("budgetForm", field, arr, index, value)

  }

  return (
    <div>
      <div className='editBudgetLayout'>
        {type === 'user' && <div className='budgetUserInfo'>
          <StaticTextInput
            label={props.t("adminusereditcontainer.name")}
            value={props.user?.userName}
          />
          <StaticTextInput
            label={props.t("adminusereditcontainer.email")}
            value={props.user?.email}
          />
        </div>
        }

        {type === 'team' && <div className='budgetUserInfo'>
          <StaticTextInput
            label={props.t("adminusereditcontainer.teamname")}
            value={props.budgetUserData?.teamName}
          />
        </div>
        }

        <div className='budgetFormContainer'>
          <div className='changeYearContainer'>
            <button
              id='sub'
              onClick={(e) => changeYear(e)}
              className='yearBtn'
              disabled={
                year.format("yyyy") ===
                moment().subtract(1, "years").format("yyyy")
              }
            >
              <i className='vismaicon vismaicon-md vismaicon-arrow-left-circle'></i>
            </button>
            <p className='yearText'>{year.format("yyyy")}</p>
            <button
              id='add'
              onClick={(e) => changeYear(e)}
              className='yearBtn'
              disabled={
                year.format("yyyy") ===
                moment().add(1, "years").format("yyyy")
              }
            >
              <i className='vismaicon vismaicon-md vismaicon-arrow-right-circle'></i>
            </button>
          </div>
          <form className='budgetForm'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th>{props.t("months.month")}</th>
                  <th>{props.t("budget.sek")}</th>
                  <th>{props.t("budget.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {props.budgetForm ? props.budgetForm.data.map((item, i) => {
                  return <tr key={i}>
                    <td>{item.month}</td>
                    <td>
                      <TextInput onChange={(value) => onFieldChange("sek", props.budgetForm.data, i, value)} value={item.sek} />
                    </td>
                    <td>
                      <TextInput onChange={(value) => onFieldChange("amount", props.budgetForm.data, i, value)} value={item.amount} />
                    </td>

                  </tr>
                }) : null}
              </tbody>
            </table>
          </form>
        </div>
      </div>

      <div className='form-group padding-top no-left-margin btn-toolbar'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={onSaveClick}
        >
          {props.t("adminusereditcontainer.save")}
        </button>
        <button
          type='button'
          className='btn btn-default'
          onClick={onCancelClick}
        >
          {props.t("adminusereditcontainer.cancel")}
        </button>
      </div>
      <Loader />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    budgetForm: state.CommonReducer.forms && state.CommonReducer.forms.budgetForm,
    budgetUserData: state.SettingsReducer.budgetUserData,
    user: state.SettingsReducer.budgetUser,
    team: state.SettingsReducer.budgetTeam
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminEditBudgetContainer));
