import leadActions from "../Actions/leadActions";
import DropDown from "../Components/DropDown";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import customerActions from "../Actions/customerActions";
import CustomerDirectorytable from "../Components/CustomerDirectorytable";
import commonActions from "../Actions/commonActions";
import { withTranslation } from "react-i18next";

function CustomerDirectoryContainer({
    t,
    salesPersons,
    aoCustomers,
    leadActions,
    customerActions,
    commonActions,
    user,
}) {

    const selectedSalesPersons = localStorage.salesPersonsForCustomerDirectory ? JSON.parse(localStorage.salesPersonsForCustomerDirectory) : [];

    useEffect(() => {
        leadActions.getAoSalesPersons();
        getAoCustomers();
    }, []);

    let notSelectedSalesPersons = [];

    if (salesPersons) {
        notSelectedSalesPersons = salesPersons.filter(s => !selectedSalesPersons.some(salesPerson => s.salesNumber === salesPerson.salesNumber));
        notSelectedSalesPersons = notSelectedSalesPersons.filter(s => s.salesNumber > 0);
    }

    const sortListByKey = (listName, list) => {
        return key => {
            commonActions.sortListByKey(list, key, listName);
        }
    }

    const addSalesPerson = (salesPerson) => {
        selectedSalesPersons.push(salesPerson);
        localStorage.salesPersonsForCustomerDirectory = JSON.stringify(selectedSalesPersons);
        getAoCustomers();
    }

    const onRemoveSalesPerson = (salesPerson) => {
        const salesPersons = selectedSalesPersons.filter(x => x != salesPerson);
        localStorage.salesPersonsForCustomerDirectory = JSON.stringify(salesPersons);

        getAoCustomers();
    }

    const getAoCustomers = () => {
        const salesPersons = localStorage.salesPersonsForCustomerDirectory ? JSON.parse(localStorage.salesPersonsForCustomerDirectory) : [];
        if (!salesPersons)
            return
        customerActions.getAoCustomers(salesPersons.map((item, index) => {
            return item.salesNumber;
        }));
    }

    const onSelect = (salesPersonId) => {
        let salesPerson = salesPersons.find(s => s.id === salesPersonId)
        if (!selectedSalesPersons.some(x => x.salesNumber === salesPerson.salesNumber)) {
            addSalesPerson(salesPerson);
        }
    }

    const renderSelectedSalesPersons = () => {
        return (
            <div className="address-button-container">
                {selectedSalesPersons && selectedSalesPersons.length > 0 && selectedSalesPersons.map((selectedSalesPerson, index) => {
                    return <button key={index} className="btn btn-default false address-button" type="button">{selectedSalesPerson.name}<span onClick={() => onRemoveSalesPerson(selectedSalesPerson)} className="address-remove">&times;</span></button>
                })}
            </div>
        )
    }

    const clearSelectedSalesPersons = () => {
        localStorage.salesPersonsForCustomerDirectory = [];
        getAoCustomers();
    }

    if (!salesPersons) {
        return null
    }

    return (
        <div className="row panel" style={{ marginLeft: "20px" }}>
            <div className="panel-body">
                <div className="panel-heading">
                    <div className="panel-title"><h3>{t('customerdirectorytable.customerdirectory')}</h3></div>
                    <div>
                        <DropDown list={notSelectedSalesPersons} onChange={onSelect.bind()} blankOption={true}></DropDown>
                        {selectedSalesPersons && selectedSalesPersons.length > 0 && <button className="btn btn-default" onClick={() => clearSelectedSalesPersons()}>{t('customerdirectorytable.removeall')}</button>}
                    </div>
                    {renderSelectedSalesPersons()}
                    {aoCustomers && aoCustomers.length > 0 && <CustomerDirectorytable aoCustomers={aoCustomers} sortListByKey={sortListByKey("aoCustomers", aoCustomers)} />}
                </div>
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        salesPersons: state.LeadsReducer.aoSalesPersons,
        aoCustomers: state.CustomerReducer.aoCustomers,
        user: state.CommonReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerDirectoryContainer));