import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DropDown from '../DropDown'
import TextInput from '../TextInput'
import commonActions from '../../Actions/commonActions'
import Modal from '../../Components/Modal'
import { withTranslation } from 'react-i18next';

class CompanyExpenseAddAdminUserModel extends Component {

    componentDidMount() {
        const { onLoad } = this.props;     
            
        onLoad();
    }    

    copyContactInfo() {
        const { vbCustomer } = this.props;
        var contact = customer.contactPerson.split(" ");
        if(contact.length > 1){
            this.onFieldChange('firstName', contact[0]);
            this.onFieldChange('lastName', contact[contact.length-1]);
        }else{
            this.onFieldChange('firstName', vbCustomer.contactPerson);
            this.onFieldChange('lastName', '' );
        }        
        this.onFieldChange('email', vbCustomer.emailAddress);
    }

    onFieldChange(field, value) {
        const { commonActions, form } = this.props;
        
        commonActions.changeMultiFormField(form.id, field, value);
    }

    render() {

        const {form, onSave, onClose, onChange, t} = this.props;

        const data = form && form.data;

        if (!data) {
            return null;
        }
        
        const modalProps = {
            title: 'addadminusermodal.header',
            stateful: true,
            open: true,
            onChange: onChange,
            onCancelClick: onClose,
            onSaveClick: onSave
        }        

        return (
            <Modal {...modalProps}>
                <div >
                    <form className="form-horizontal col-xs-10">
                    <fieldset>
                    <TextInput label={t('addadminusermodal.firstname')} value={data.firstName} onChange={this.onFieldChange.bind(this, 'firstName')}/>
                    <TextInput label={t('addadminusermodal.lastname')} value={data.lastName} onChange={this.onFieldChange.bind(this, 'lastName')}/>
                    <TextInput  label={t('addadminusermodal.email')} value={data.email} onChange={this.onFieldChange.bind(this, 'email')}/>                    
                    <DropDown label={t('addadminusermodal.language')} blankOptionLast={false} onChange={this.onFieldChange.bind(this, 'langCode')} list={data.languageList} value={data.langCode}  />
                    <a className="btn btn-default pull-right" onClick={this.copyContactInfo.bind(this)}>{t('addadminusermodal.usecontactsinfo')}</a>
                    </fieldset>
                    </form>
                </div>
            </Modal>
        );
       
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.addCompanyExpenseAdminUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyExpenseAddAdminUserModel));