import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import LeadDetailActions from '../../Components/Leads/LeadDetailActions'
import PanelActions from '../../Components/PanelActions'
import AoLeadForm from '../../Components/Leads/AoLeadForm'
import ActionHistory from '../../Components/ActionHistory'

import SendEmailModal from '../../Components/Leads/SendEmailModal'
import AppointmentModal from '../../Components/Leads/AppointmentModal'
import CloseModal from '../../Components/Leads/CloseModal'
import AoBuyModal from '../../Components/Leads/AoBuyModal'
import './Lead.css'
import { useNavigate, useParams } from 'react-router-dom';

function InternalLeadDetailsContainer({ leadActions, lead, user, t, salesPersons }) {

    const [activeModal, setActiveModal] = useState("")
    const [emailActionLogId, setEmailActionLogId] = useState(0)
    const { leadId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        leadActions.getInternalLead(leadId);
        leadActions.getInternalEmails();
    }, [])


    const onNoAnswerClick = () => {
        var payload = { data: { action: "No answer", leadType: "InternalLead" } };

        leadActions.saveInternalAction(leadId, payload, () => refresh());
    }

    const onBusyClick = () => {
        var payload = { data: { action: "Busy", leadType: "InternalLead" } };

        leadActions.saveInternalAction(leadId, payload, () => refresh());
    }

    const onCloseClick = (isModal) => {

        if (isModal) {
            setActiveModal("");
            refresh();
        } else {
            navigate(-1)
        }
    }

    const onReopenClick = () => {

        leadActions.reopenInternal(leadId);
    }

    const toggleModal = (modalName, emailActionLogId = 0) => {

        if (activeModal === modalName) {
            setActiveModal("")
        } else {
            setActiveModal(modalName)

        }
        setEmailActionId(emailActionLogId)
    };

    const refresh = () => {

        leadActions.getInternalLead(lead.id, true)
    }

    const onSendbuttonClick = (modalName) => {
        toggleModal(modalName);
    }

    const onAssingButtonClick = () => {

        leadActions.assignInternalLead(lead.id, user.id, refresh);
    }

    const AssignTo = (leadId, userId) => {

        leadActions.assignInternalLead(leadId, userId, refresh);
    }

    const onBuyClick = () => {
        toggleModal("showBuyModal");
    }

    if (!lead) {
        return null;
    }

    const containerWidth = 7;

    return (
        <div className="row">
            {activeModal === "showSendEmailModal" && <SendEmailModal leadType="internalLead" lead={lead} onClose={toggleModal.bind(this, "showSendEmailModal")} onSave={() => onSendbuttonClick("showSendEmailModal")} emailActionLogId={emailActionLogId} />}
            {activeModal === "showAppointmentModal" && <AppointmentModal leadType="internalLead" lead={lead} onClose={toggleModal.bind(this, "showAppointmentModal")} onComplete={() => onCloseClick(true)} />}
            {activeModal === "showCloseModal" && <CloseModal leadType="internalLead" lead={lead} onClose={toggleModal.bind(this, "showCloseModal")} onSave={() => onCloseClick(true)} />}
            {activeModal === "showBuyModal" && <AoBuyModal lead={lead} leadType="internalLead" onClose={toggleModal.bind(this, "showBuyModal")} onSave={() => onCloseClick(true, "showBuyModal")} />}

            <div className={`col-lg-${containerWidth} col-xs-12`}>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        {!lead.isClosed && <PanelActions editLink={routes.internalLeadEdit(lead.id)} onRefresh={refresh} />}
                        <h3 className="panel-title">{t('internalleaddetailscontainer.details')}</h3>
                    </div>
                    <div className="panel-body">
                        <AoLeadForm
                            user={user}
                            lead={lead}
                            onFieldChange={() => { }}
                            readonly={true}
                            salesPersons={salesPersons}
                            AssignTo={(leadId, userId) => AssignTo(leadId, userId)}
                            onCancelClick={() => onCloseClick()}
                            onReopenClick={onReopenClick}
                            onAssingButtonClick={onAssingButtonClick}
                        />
                    </div>
                </div>

            </div>
            <div className="col-lg-5 col-xs-12">
                {!lead.isClosed && <LeadDetailActions
                    leadType="InternalLead"
                    onClick={(modalId) => toggleModal(modalId)}
                    onNoAnswerClick={() => onNoAnswerClick()}
                    onBuyClick={onBuyClick}
                    onBusyClick={onBusyClick}
                    hasCustomer={!!lead.customerNumber}
                />}
                <ActionHistory actionHistory={lead.actionHistory} toggleModal={(modalId, emailActionLogId) => toggleModal(modalId, emailActionLogId)} />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        lead: state.LeadsReducer.internalLead,
        salesPersons: state.LeadsReducer.internalEmails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalLeadDetailsContainer));