import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Modal from '../Modal'
import Editor from '../Editor'
import StaticTextInput from '../StaticTextInput'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import OrderConfirmationTable from './OrderConfirmationTable';

class OrderConfirmationList extends Component {

    onLineClick = (item) => {
        this.props.settingsActions.openOrderConfirmationPopup(item)
    }

    onOrderConfirmationUpdateClick = () => {
        const { settingsActions, editOrderConfirmationForm, templateId } = this.props;

        settingsActions.updateOrderConfirmation(editOrderConfirmationForm, () => settingsActions.getEmailTemplate(templateId));
    }

    onCancelClick = () => {
        const { commonActions: actions, editOrderConfirmationForm } = this.props;

        actions.cancelFormEditing(editOrderConfirmationForm.id);
        actions.showModal(false);
    }

    onFieldChange = (field, value) => {
        const { commonActions: actions, editOrderConfirmationForm } = this.props;

        actions.changeMultiFormField(editOrderConfirmationForm.id, field, value)
    }

    render() {

        const { data, editOrderConfirmationForm } = this.props;

        if(!data) {
            return null;
        }

        const modalProps = {
            title: `Edit order confirmation content`,
            component: OrderConfirmationEdit,
            data: editOrderConfirmationForm && editOrderConfirmationForm.data,
            mainButtonTitle: "Update",
            onChange: this.onFieldChange,
            onSaveClick: this.onOrderConfirmationUpdateClick,
            onCancelClick: this.onCancelClick
        }

        return  <fieldset>
                    <h4 style={{marginBottom: 10}}><strong>[dynamic text] will be replaced by one of these based on order type</strong></h4>
                    <OrderConfirmationTable label="Desktop" data={data.filter(item => item.isDesktop)} onClick={this.onLineClick} />
                    <OrderConfirmationTable label="Saas" data={data.filter(item => !item.isDesktop)} onClick={this.onLineClick} />
                    <Modal {...modalProps} />
                </fieldset>
    }
}

const OrderConfirmationEdit = ({data, onChange}) => {
    return  <div className="margin-bottom">
                <StaticTextInput value={data.name} />
                <Editor value={data.body} onChange={(value) => onChange("body", value)} />
                <div className="clearfix" />
            </div>
}

function mapStateToProps(state) {
    return {
        editOrderConfirmationForm: state.CommonReducer.forms && state.CommonReducer.forms.editOrderConfirmation
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationList);