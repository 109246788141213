import React from 'react';
import { Link } from 'react-router-dom';

import Loader from './Loader'
import routes from "../Constants/routes";

const PanelActions = ({ editLink, onRefresh, editCustomerLayout }) => {
    
return  <div>
            <Loader />
            {editCustomerLayout ? <Link to={editCustomerLayout}><span className="vismaicon vismaicon-panel pull-right cursorpointer" title="Layout" /></Link> : <></>}
            <Link to={editLink}><span className="vismaicon vismaicon-edit pull-right margin-right cursorpointer" title="Edit" /></Link>
            <span className="vismaicon vismaicon-refresh pull-right margin-right cursorpointer" title="Refresh" onClick={() => onRefresh()} />
        </div>
}

export default PanelActions;