import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'
import { useParams, useNavigate } from 'react-router-dom';
import GuidedSellingEdit from '../../Components/Settings/GuidedSellingEdit'

function GuidedSellingEditContainer({ settingsActions, guidedRulesForm, commonActions, guidedSellingRule }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        settingsActions.getGuidedSellingRule(id);
    }, [])

    const onSaveClick = () => {
        settingsActions.updateGuidedSellingRule(guidedRulesForm, () => navigate(routes.settingsGuidedSelling));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(guidedRulesForm.id, () => navigate(routes.settingsGuidedSelling))
    }

    const onDropDownChange = (index, productNumber) => {

        const suggestions = JSON.parse(JSON.stringify(guidedRulesForm.data.productSuggestions))

        suggestions[index] = guidedSellingRule.availableSuggestions.find((suggestion) => suggestion.id === productNumber);

        commonActions.changeMultiFormField(guidedRulesForm.id, 'productSuggestions', suggestions)
    }


    return (
        <GuidedSellingEdit
            guidedSellingRule={guidedRulesForm && guidedRulesForm.data}
            onSaveClick={onSaveClick.bind(this)}
            onCancelClick={onCancelClick.bind(this)}
            onDropDownChange={onDropDownChange.bind(this)}
        />
    )
}


function mapStateToProps(state) {
    return {
        guidedSellingRule: state.SettingsReducer.guidedSellingRule,
        guidedRulesForm: state.CommonReducer.forms && state.CommonReducer.forms.editGuidedRule
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidedSellingEditContainer);