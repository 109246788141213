import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Modal from '../Modal'
import StaticTextInput from '../../Components/StaticTextInput'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import TextInput from '../TextInput';
import {FormEditor} from '../Editor';
import TextInputWithButton from '../../Components/TextInputWithButton'


class VismaVceModal extends Component {

    componentDidMount() {
        const { vbCustomer } = this.props;
        this.props.customerActions.getVceEmailTemplate(vbCustomer.organizationNumber, vbCustomer.name)
    }


    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("vceEmailTemplate", field, value);
    }

    handleClickSave = () => {
        const { vceEmailTemplate, vbCustomer, customerActions } = this.props;
        customerActions.activateVce(vceEmailTemplate, vbCustomer.customerNumber, this.handleClickClose)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("vceEmailTemplate", onClose)
    }

    getReceiverEmails() {

    }

    render() {
        const { vbCustomer, vceEmailTemplate } = this.props;

        if (!vceEmailTemplate) {
            return null;
        }

        const emailInputProps = {
            buttonTitle: 'Use contact email',
            buttonClick: () => this.handleFieldChange('to', vbCustomer.emailAddress),
            label: 'Email *',
            value: vceEmailTemplate.data.to,
            onChange: this.handleFieldChange.bind(this, "to")
        }
        const modalProps = {
            title: 'Visma Utlägg',
            mainButtonTitle: 'Activate',
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                    <form className="form-horizontal">
                        <fieldset>
                            <TextInputWithButton {...emailInputProps} />
                            <FormEditor label="Description *" toolbar={false} value={vceEmailTemplate.data.body} onChange={value => this.handleFieldChange("body", value)} />
                        </fieldset>
                    </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        vceEmailTemplate: state.CommonReducer.forms && state.CommonReducer.forms.vceEmailTemplate,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VismaVceModal);