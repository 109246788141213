import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import commonActions from '../Actions/commonActions'

function Logout({ commonActions }) {

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.signOut(() => navigate('/'))
    }, [])
    
    return null;
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(Logout);