import { useTranslation } from "react-i18next";
import { ProductSummaryCard } from "../ProductSummary";
import helpers from "../../../../Utils/helpers";
import { VossProductType } from "../../../../Constants/VOSS/vossProductType";
import Tooltip, { TooltipContent, TooltipTrigger } from "../../../Overlays/Tooltip";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

export function VossProductSummaryCard({ ...props }) {
    const { product } = props;
    const { t } = useTranslation();

    const mainItems = product.priceCalculation.billingItems.filter(item => item.type === VossProductType.Main);
    const addonItems = product.priceCalculation.billingItems.filter(item => item.type === VossProductType.Addon || item.type === VossProductType.License);
    const hasSubscription = product.priceCalculation.subscriptionId;

    const renderDiscountTooltip = (item) => (
        <Tooltip>
            <TooltipTrigger attributes={{ className: "max-w-max-content d-flex align-items-center" }}>
                {<><span className="mr-6">{helpers.currencyFormatter(item.discountAmount)}</span><SkynetIcon icon={'vismaicon-sm vismaicon-info vismaicon-dynamic '}></SkynetIcon></>}
            </TooltipTrigger>
            <TooltipContent>
                <strong>Utan rabatt</strong><br /> <span>{helpers.currencyFormatter(item.amount)}</span>
            </TooltipContent>
        </Tooltip>
    );

    const renderSubscriptionTableRow = (item) => (
        <tr key={item.id || item.name}>
            <td>
                {item.quantity <= 0
                    ? <s>{item.name}</s>
                    : `${item.name} ${item.quantity > 0 && item.type === VossProductType.License ? `- ${item.quantity}` : ''}`
                }
            </td>
            <td>{helpers.currencyFormatter(item.currentPrice)}</td>
            <td>{item.discountAmount > 0 ? renderDiscountTooltip(item) : helpers.currencyFormatter(item.discountAmount)}</td>
            <td>{helpers.currencyFormatter(item.changedPrice)}</td>
        </tr>
    );

    const renderTableRow = (item) => (
        <tr key={item.id || item.name}>
            <td>
                {item.quantity <= 0
                    ? <s>{item.name}</s>
                    : `${item.name} ${item.quantity > 0 && item.type === VossProductType.License ? `- ${item.quantity}` : ''}`
                }
            </td>
            <td></td>
            <td>{item.discountAmount > 0 ? renderDiscountTooltip(item) : helpers.currencyFormatter(item.discountAmount)}</td>
            <td>{helpers.currencyFormatter(item.changedPrice)}</td>
        </tr>
    );
    const renderTableHeader = (headers) => (
        <ProductSummaryCard.Table.THeader>
            {headers.map((header, index) => <th key={index} className={header.className}>{header.text}</th>)}
        </ProductSummaryCard.Table.THeader>
    );

    const mainHeaders = hasSubscription
        ? [
            { text: t('productsummary.main'), className: "col-6" },
            { text: t('productsummary.currentprice'), className: 'col-2' },
            { text: t('productsummary.discountamount'), className: 'col-2' },
            { text: t('productsummary.changedprice'), className: 'col-2' }
        ]
        : [
            { text: t('productsummary.main'), className: "col-6" },
            { text: '', className: 'col-2' },
            { text: t('productsummary.discountamount'), className: 'col-2' },
            { text: t('productsummary.price'), className: 'col-2' },
        ];

    const totalHeaders = hasSubscription
        ? [
            { text: t('productsummary.total'), className: "col-6" },
            { text: helpers.currencyFormatter(product.priceCalculation.totalCurrentPrice), className: 'col-2' },
            { text: helpers.currencyFormatter(product.priceCalculation.totalDiscountAmount), className: 'col-2' },
            { text: helpers.currencyFormatter(product.priceCalculation.totalChangedPrice), className: 'col-2' }
        ]
        : [
            { text: t('productsummary.total'), className: "col-6" },
            { text: '', className: 'col-2' },
            { text: helpers.currencyFormatter(product.priceCalculation.totalDiscountAmount), className: 'col-2' },
            { text: helpers.currencyFormatter(product.priceCalculation.totalChangedPrice), className: 'col-2' },
        ];

    return (
        <ProductSummaryCard product={product}>
            {mainItems.length > 0 && (
                <ProductSummaryCard.Table>
                    {renderTableHeader(mainHeaders)}
                    <ProductSummaryCard.Table.TBody>
                        {mainItems.map(hasSubscription ? renderSubscriptionTableRow : renderTableRow)}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>
            )}
            {addonItems.length > 0 && (
                <ProductSummaryCard.Table>
                    {renderTableHeader([
                        { text: t('productsummary.addons'), className: "col-6" },
                        { text: '', className: 'col-2' },
                        { text: '', className: 'col-2' },
                        { text: '', className: 'col-2' }
                    ])}
                    <ProductSummaryCard.Table.TBody>
                        {addonItems.map(hasSubscription ? renderSubscriptionTableRow : renderTableRow)}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>
            )}
            <ProductSummaryCard.Table className="border-0 shadow-0">
                {renderTableHeader(totalHeaders)}
            </ProductSummaryCard.Table>
            <div className="d-flex d-flex flex-row justify-content-end my-24 mr-24 align-items-center">
                <ProductSummaryCard.Actions {...props} />
            </div>
        </ProductSummaryCard>
    );
}