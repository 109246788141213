import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Modal from '../Modal'
import EmailTemplate from '../EmailTemplate'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'


class InternalLeadSendEmailModal extends Component {

    componentDidMount() {
        this.props.customerActions.getInternalLeadEmailTemplate(this.props.vbCustomer.customerNumber, this.props.internalLeadEmailTemplate)
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("internalLeadEmail", field, value);
    }

    handleClickSave = () => {
        const { emailForm, vbCustomer, customerActions } = this.props;
        customerActions.sendInternalLeadEmail(emailForm, vbCustomer.customerNumber, this.handleClickClose)
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("internalLeadEmail", onClose)
    }

    getReceiverEmails() {
        const { emailForm } = this.props;
        return emailForm && emailForm.data && emailForm.data.defaultToAddresses;
    }

    render() {
        const { emailForm } = this.props;

        if (!emailForm) {
            return null;
        }

        const modalProps = {
            title: 'Send internal lead',
            mainButtonTitle: 'Send',
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave
        }

        return (
            <Modal {...modalProps}>
                <EmailTemplate data={emailForm.data} templates={null} onChange={this.handleFieldChange}
                    onSaveClick={this.handleClickSave} onCancelClick={this.handleClickClose} onTemplateChange={this.onTemplateChange}
                    senderEmails={null} receiverEmails={this.getReceiverEmails()} onSenderChange={this.handleFieldChange} />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        emailForm: state.CommonReducer.forms && state.CommonReducer.forms.internalLeadEmail,
        internalLeadEmailTemplate: state.CustomerReducer.internalLeadEmailTemplate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalLeadSendEmailModal);