import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import DropDown from '../../Components/DropDown'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import TextInput from '../../Components/TextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function SettingsCompensationEditContainer({ settingsActions, commonActions, form, results, t, reasons, customerActions }) {

    const { id } = useParams();
    const navigate = useNavigate();
    const [validateSubResult, setValidationSubResult] = useState(false);

    useEffect(() => {
        customerActions.getCompensationResultsDropDown();

        settingsActions.getSelectedTicket(id);
    }, [])

    const onSaveClick = () => {
        settingsActions.saveTicket(form, () => navigate(routes.settingsCompensation), createSalesforceTicket());
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.settingsCompensation))
    }

    const onFieldChange = (field, value) => {

        if (field === 'compensationResultId') {
            form.data.compensationSubResultId = null
        }
        if (field === 'compensationReasonId') {
            form.data.compensationSubReasonId = null
        }
        commonActions.changeMultiFormField(form.id, field, value)
    }

    const renderSubResults = (id, list, fieldName) => {
        if (list) {
            var result = list.find(result => result.id === id)

            if (result && result.subOptions.length > 0) {
                return (
                    <DropDown label={" "} blankOption={true} onChange={value => onFieldChange(fieldName, value)} list={result.subOptions} value={form.data[fieldName] || ""} />
                )
            }
        }
    }

    const createSalesforceTicket = () => {

        var result = results.find(result => result.id === form.data.compensationResultId)
        var subResult;

        if (result)
            subResult = result.subOptions.find(subOption => subOption.id === form.data.compensationSubResultId)

        if ((result && result.createSalesforceTicket) || (subResult && subResult.createSalesforceTicket)) {
            return true
        }
    }

    const setValidation = () => {
        if (results) {
            var result = results.find(result => result.id === form.data.compensationResultId)
            if (result && result.subOptions.length > 0) {
                if (validateSubResult === false) {
                    setValidationSubResult(true)
                }
                settingsActions.switchCompensationValidation(true, form)
            }
        }
        else {
            if (validateSubResult === true) {
                setValidationSubResult(false)
                settingsActions.switchCompensationValidation(false, form)
            }
        }
    }

    const data = form && form.data;

    if (!data) {
        return null;
    }

    var inputStyle = {
        width: 130
    }

    setValidation()

    return (
        <div>
            <form className="form-horizontal col-xs-12">
                <fieldset className="col-xs-6">
                    <StaticTextInput label={t("settingscompensationeditcontainer.customernumber")} value={data.customerNumber} />
                    <StaticTextInput label={t("settingscompensationeditcontainer.product")} value={data.product} />
                    <DropDown label={t("settingscompensationeditcontainer.compensationreason")} onChange={value => onFieldChange('compensationReasonId', value)} list={reasons} value={data.compensationReasonId || ""} />
                    {renderSubResults(data.compensationReasonId, reasons, 'compensationSubReasonId')}
                    <TextInput label={t("settingscompensationeditcontainer.sum")} value={data.sum} onChange={(value) => onFieldChange("sum", value)} style={inputStyle} />
                    <DropDown label={t("settingscompensationeditcontainer.compensationresult")} onChange={value => onFieldChange('compensationResultId', value)} blankOption={true} list={results} value={data.compensationResultId || ""} />
                    {renderSubResults(data.compensationResultId, results, 'compensationSubResultId')}
                    <TextInput label={t("settingscompensationeditcontainer.comment")} onChange={value => onFieldChange('compensationComment', value)} value={data.compensationComment} multiline={true} />
                </fieldset>
                <div className="clearfix"></div>
                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary" onClick={onSaveClick}>{t("settingscompensationeditcontainer.save")}</button>
                    <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("settingscompensationeditcontainer.cancel")}</button>
                    <Loader />
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        ticket: state.SettingsReducer.compensationTicket,
        form: state.CommonReducer.forms && state.CommonReducer.forms.settingsCompensation,
        results: state.CustomerReducer.compensationsResultsDropdown,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['CompensationReasons']
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsCompensationEditContainer));