import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Bar, Line } from "react-chartjs-2";

class MyStatsGraph extends Component {
  graph = (type) => {
    const data = {
      labels: this.props.labels, //must be an array, you can map out an array aswell.
      datasets: [
        {
          data: this.props.data, //must be an array, you can map out an array aswell.
          backgroundColor: this.props.backgroundColor, //array of rgb-colors
          maxBarThickness: 45,
          lineTension: 0.5,
          borderColor: "rgb(9, 116, 179)",
          radius: this.props.removeFalsyPoints,
        },
        {
          type: "line",
          radius: 0,
          data: this.props.reference,
          borderColor: "rgb(106, 108, 109, 0.7)",
        },
      ],
    };

    switch (type) {
      case "Bar":
        return (
          <Bar
            data={data}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  yAlign: "bottom",
                  displayColors: false,
                  backgroundColor: "rgb(255, 255, 255)",
                  borderColor: "rgb(217, 220, 222)",
                  borderWidth: 1,
                  titleColor: "rgb(9, 116, 179)",
                  bodyColor: "rgb(9, 116, 179)",
                },
              },
              scales: {
                yAxes: {
                  grid: {
                    borderColor: "rgb(106, 108, 109)",
                    color: "rgb(170, 172, 174, 0.7)",
                  },
                  ticks: {
                    color: "rgb(106, 108, 109)",
                  },
                  beginAtZero: true,
                },
                xAxes: {
                  grid: {
                    borderColor: "rgb(106, 108, 109)",
                    display: false,
                  },
                  ticks: {
                    color: "rgb(106, 108, 109)",
                  },
                },
              },
            }}
          />
        );

      case "Line":
        return (
          <Line
            data={data}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  yAlign: "bottom",
                  displayColors: false,
                  backgroundColor: "rgb(255, 255, 255)",
                  borderColor: "rgb(217, 220, 222)",
                  borderWidth: 1,
                  titleColor: "rgb(9, 116, 179)",
                  bodyColor: "rgb(9, 116, 179)",
                },
              },
              scales: {
                yAxes: {
                  grid: {
                    borderColor: "rgb(106, 108, 109)",
                    color: "rgb(170, 172, 174, 0.7)",
                  },
                  ticks: {
                    color: "rgb(106, 108, 109)",
                  },
                  beginAtZero: true,
                },
                xAxes: {
                  grid: {
                    borderColor: "rgb(106, 108, 109)",
                    display: false,
                  },
                  ticks: {
                    maxTicksLimit: 6,
                    color: "rgb(106, 108, 109)",
                  },
                  offset: true,
                },
              },
            }}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className='myStatsGraphContainer'>
        <div className='graphTitleContainer'>
          <h4>{this.props.title}</h4>
        </div>
        <div className='graphComponentWrapper'>
          {this.props.data.length !== 0 ? (
            this.graph(this.props.typeOfGraph)
          ) : (
            <h4>{t("graph.noData")}</h4>
          )}
        </div>
        <div className='graphController'>
          <div className='controllerContainer'>
            <button
              type='button'
              className={`btn btn-default ${
                this.props.timePeriod === "week" ? "active" : "false"
              }`}
              onClick={this.props.onClick}
              value='week'
            >
              {t("graph.thisWeek")}
            </button>
            <button
              type='button'
              className={`btn btn-default ${
                this.props.timePeriod === "month" ? "active" : "false"
              }`}
              onClick={this.props.onClick}
              value='month'
            >
              {t("graph.thisMonth")}
            </button>
            <button
              type='button'
              className={`btn btn-default ${
                this.props.timePeriod === "lastMonth" ? "active" : "false"
              }`}
              onClick={this.props.onClick}
              value='lastMonth'
            >
              {t("graph.lastMonth")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyStatsGraph);
