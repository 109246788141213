import React from 'react'
import { connect } from 'react-redux';
import customerActions from '../../Actions/customerActions'
import { bindActionCreators } from 'redux'
import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next'
import CheckAddressModal from '../Customer/CheckAddressModal'
import { useLocation } from 'react-router-dom';

function CustomerLinks({customerActions, vbCustomer, customer, openHeader, updatedInfo, t, setHeader}) {

    const location = useLocation();

    function openCheckAddressModal() {
        customerActions.getCustomerInfoForUpdate(vbCustomer.organizationNumber)
    }
    function getLinks() {
        return [
            {
                title: t('leftnavigation.main'), id: "main", onClick: () => setHeader("main"), subItems: [
                    {  title: t('leftnavigation.customer'), path: routes.customerMain(customer.pathId)},
                    !vbCustomer.isNonInteractive && { title: t('leftnavigation.editcustomer'), path: routes.customerEdit(customer.pathId) },
                    { title: t('leftnavigation.relatedcustomers'), path: routes.relatedCustomers(customer.pathId) },
                    !vbCustomer.isNonInteractive && { title: t('leftnavigation.updateaddress'), onClick: () => openCheckAddressModal() },
                    { title: t('leftnavigation.agreements'), path: routes.customerAgreements(customer.pathId) }
                ]
            }
        ];
    }
    
    return <>
            {getLinks().filter(link => !link.disabled).map((item, index) => {
                return <LeftNavigationBarItem key={index} link={item} location={location} openHeader={openHeader} />
            })}
            <CheckAddressModal vbCustomer={vbCustomer} updatedInfo={updatedInfo} />
            </>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        updatedInfo: state.CommonReducer.forms && state.CommonReducer.forms.updateCustomerForm,
        openHeader: state.CommonReducer.openHeader
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerLinks));