import React from 'react';
import helpers from '../../Utils/helpers'
import { useTranslation } from 'react-i18next';

const BlikkProducts = ({ products }) => {

    let key = 1;

    const renderUsers = licenses => {
        return licenses.map(item => {
            return <p key={item.id}>{item.name}: {item.used}</p>
        });
    }

    const renderInactiveUsers = licenses => {
        return licenses.map(item => {
            return <p key={item.id}>{item.name}: {item.count - item.used}</p>
        });
    }

    const renderLicenses = licenses => {
        return licenses.map(item => {
            return <p key={item.id}>{item.name}: {item.count}</p>
        });
    }

    const renderTransactions = transactions => {
        return transactions.map(item => {
            return <p key={item.id}>{item.name}: {item.credit}</p>
        });
    }
    const {t} = useTranslation();
    return products &&
        <>
            <div className="table-responsive">
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th className="text-left">{t('blikkproducts.program')}</th>
                        <th className="text-left ">{t('blikkproducts.evaluationenddate')}</th>
                        <th className="text-left">{t('blikkproducts.purchasedate')}</th>
                        <th className="text-left">{t('blikkproducts.enddate')}</th>
                        <th className="text-left">{t('blikkproducts.totallicenses')}</th>
                        <th className="text-left">{t('blikkproducts.users')}</th>
                        <th className="text-left">{t('blikkproducts.availablelicenses')}</th>
                        <th className="text-left">{t('blikkproducts.availabletransactions')}</th>
                        {products.some(product => product.canceledDate) &&
                            <th className="text-left">{t('blikkproducts.canceled')}</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {products.map(item => {
                        return <tr key={key++}>
                            <td className="text-left text-nowrap">{item.productDescription} </td>
                            <td className="text-left">{item.proEvaluationEndDate && "Visma Tid Pro: " + helpers.formatDate(item.proEvaluationEndDate)} </td>
                            <td className="text-left text-nowrap">{helpers.formatDate(item.activationDate)}</td>
                            <td className="text-left text-nowrap">{helpers.formatDate(item.currentPeriodEndDate)}</td>
                            <td className="text-left">{renderLicenses(item.licenseTypes)}</td>
                            <td className="text-left">{renderUsers(item.licenseTypes)}</td>
                            <td className="text-left">{renderInactiveUsers(item.licenseTypes)}</td>
                            <td className="text-left">{renderTransactions(item.transactionTypes)}</td>
                            {item.canceledDate && <td className="text-left"
                                                      style={{color: "red"}}>{helpers.formatDate(item.canceledDate)}</td>}
                        </tr>
                    })
                    }
                    </tbody>
                </table>
            </div>
        </>
}

export default BlikkProducts;