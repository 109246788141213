import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import TextInput from '../../Components/TextInput'
import Loader from '../../Components/Loader'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import helpers from '../../Utils/helpers'
import { useNavigate } from 'react-router-dom';
import { FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";
import {FormInput} from "../../Components/FormComponents/Input/Input";
import Card from "../../Components/Card/Card";
function CustomerSearchContainer({ customerActions, customerSearchForm, commonActions, t, user }) {

    const navigate = useNavigate();

    useEffect(() => {
        const leadId = helpers.getUrlQueryParameter(window.location, 'leadId');
        const input = helpers.getUrlQueryParameter(window.location, 'input');

        customerActions.beginCustomerCreation(input, leadId);
    }, [])


    const onNextButtonClick = () => {
        if (!isFeatureEnabled(user, FeatureFlags.VOSS)) {
            customerActions.getCustomerByOrganizationNumber(customerSearchForm, "customerFormData",() => navigate(routes.newCustomerForm));
        } else {
            customerActions.createVossCustomerFromBusinessCheck(customerSearchForm, "customerFormData",() => navigate(routes.createCustomerForm));
        }
    }

    const onCancelClick = () => {
        const leadId = helpers.getUrlQueryParameter(window.location, 'leadId');
        const returnUrl = leadId ? routes.leadEdit(leadId) : routes.search(helpers.getUrlQueryParameter(window.location, 'input'))

        commonActions.cancelFormEditing(customerSearchForm.id, () => navigate(returnUrl));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(customerSearchForm.id, field, value)
    }

    if (!customerSearchForm) {
        return null;
    }

    return (<>
        <Card>
            <Card.Header>
                <Card.Header.Title>{t('customersearchcontainer.createnewcustomer')}</Card.Header.Title>
            </Card.Header>
            <Card.Content>
                <form className={"container"}>
                    <fieldset className="d-grid mb-0 grid-cols-5">
                        <FormInput label={t('customersearchcontainer.organizationnumber')}
                                   onChange={onFieldChange.bind(this, 'organizationNumber')}
                                   value={customerSearchForm.data.organizationNumber || ''}/>
                    </fieldset>
                </form>
            </Card.Content>
            {<Card.Footer>
                <Card.Footer.Buttons>
                    {<>
                        <Loader/>
                        <button type="button" className="btn btn-primary"
                                onClick={onNextButtonClick.bind(this)}>{t('customersearchcontainer.next')}</button>
                        <button type="button" className="btn btn-default"
                                onClick={onCancelClick.bind(this)}>{t('customersearchcontainer.cancel')}</button>

                    </>}
                </Card.Footer.Buttons>
            </Card.Footer>}
        </Card></>)
}

function mapStateToProps(state) {
    return {
        customerSearchForm: state.CommonReducer.forms && state.CommonReducer.forms.customerSearchForm,
        user: state.CommonReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSearchContainer))