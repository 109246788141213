import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import EmailTemplateWithButtons from '../../Components/EmailTemplateWithButtons'
import Loader from '../../Components/Loader'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import Panel from '../../Components/Panel';

class AdminFormsContainer extends Component {

    componentDidMount() {
        this.props.commonActions.loadDropdownList('AdminForms');
        if(this.props.vbCustomer.hasVonAccount){
            this.props.customerActions.getVonAccounts(this.props.vbCustomer.customerNumber);
        }
    }

    setDefaultSelection() {
        if (!this.props.selectedAdminForm && this.props.selectAdminForm == null && this.props.adminFormsList) {
            this.props.customerActions.selectAdminForm(this.props.adminFormsList[0].id);
            this.props.customerActions.getAdminFormTemplate(this.props.vbCustomer.customerNumber, this.props.adminFormsList[0].id);
        } 
    }

    onFormSelection(event) {
        this.props.customerActions.selectAdminForm(parseInt(event.target.value, 10));
        this.props.customerActions.getAdminFormTemplate(this.props.vbCustomer.customerNumber, parseInt(event.target.value, 10));
    }

    onFieldChange(field, value) {
        const { commonActions, adminForm } = this.props;

        commonActions.changeMultiFormField(adminForm.id, field, value)
    }

    onSaveClick() {
        this.props.customerActions.sendAdminForm(this.props.adminForm);
    }

    onCancelClick() {
        const { commonActions, adminForm } = this.props;

        commonActions.cancelFormEditing(adminForm.id)
    }

    getSenderEmails(){
        const {adminForm} = this.props;
        return adminForm && adminForm.data && adminForm.data.defaultSenderAddresses;
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("adminForm", field, value);
    }

    render() {

        const { adminForm, selectedAdminForm, t} = this.props;

        if(!this.props.adminFormsList) 
            return null;

        return <Panel title={t('adminformscontainer.header')}>
                    <div className="row mb-10">
                        <div className="col-8">
                            <div className="input-group searchInput">
                                <select className="form-control" defaultValue={selectedAdminForm ? selectedAdminForm : -1} value={this.props.activeAdminForm} onChange={this.onFormSelection.bind(this)}>
                                    {
                                        <option key={-1} disabled hidden value={-1}>{t('adminformscontainer.select')}</option>
                                    }
                                    {
                                        this.props.adminFormsList.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </select>
                            </div>  
                            
                        </div>
                    </div>
                    {selectedAdminForm && <div className="row">
                            <div className="col-8">
                                <EmailTemplateWithButtons data={adminForm && adminForm.data}
                                isAdminForm={true}
                                onChange={this.onFieldChange.bind(this)}
                                onSaveClick={this.onSaveClick.bind(this)}
                                onCancelClick={this.onCancelClick.bind(this)}
                                senderEmails={this.getSenderEmails()}
                                onSenderChange={this.handleFieldChange}
                                vonAccounts={this.props.vonAccounts}
                                hasVonAccount={this.props.vbCustomer.hasVonAccount}
                                vbEmail={this.props.vbCustomer.emailAddress}
                                />
                            </div>
                    </div>}
            </Panel>
    }
}

function mapStateToProps(state) {
    return {
        vonAccounts: state.CustomerReducer.vonAccounts,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        adminFormsList: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.AdminForms,
        selectedAdminForm: state.CustomerReducer.selectedAdminForm,
        adminForm: state.CommonReducer.forms && state.CommonReducer.forms.adminForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminFormsContainer));