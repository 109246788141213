import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from '../Modal'
import ListSearch from '../ListSearch';
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import { bindActionCreators } from 'redux';
import UserSearchResultTable from './UserSearchResultTable';

function UserSearchModal({ commonActions, onClose, userSearchResults }) {
    
    const { t } = useTranslation();

    useEffect(() => () => {
        // unmount will clean up state with
        commonActions.clearUserSearch();
    }, [] );
    
    
    const onSearchClick = (phrase) => {
        commonActions.searchUser(phrase)
    }

    const onClearClick = () => {
        commonActions.clearUserSearch();
    }
    
    const modalProps = {
        title: t('usersearch.header'),
        onCancelClick: onClose,
        stateful: true,
        open: true,
        showButtons: false,
        dialogStyle: {width: '900px'}
    }
        
    return <Modal {...modalProps}>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <ListSearch onSearchClick={onSearchClick} onClearClick={onClearClick} results={userSearchResults && userSearchResults.results} placeholder={t('usersearch.searchplaceholder')}>
                            <UserSearchResultTable key={456} searchResult={userSearchResults && userSearchResults.results} />
                        </ListSearch>
                    </div>
                </div>
            </Modal>
}

function mapStateToProps(state) {
    return {
        userSearchResults: state.CommonReducer.userSearchResults
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchModal);
