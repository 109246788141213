import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import EducationsList from '../../Components/Customer/EducationsList'

const WebinarsContainer = ({ educations, educationFilters, vbCustomer, customerActions, commonActions }) => {

  const navigate = useNavigate();

  useEffect(() => {
    customerActions.getEducations()
  }, [])

  const fullWarning = (text, header) => {
    commonActions.showWarning(text, header)
  }

  return (
    <div>
      <EducationsList educations={educations}
        redirect={navigate}
        customerNumber={vbCustomer.customerNumber}
        warning={fullWarning}
        filters={educationFilters}
        freeWebinars={true} />
    </div>
  )
}

const mapStateToProps = state => ({
  educations: state.CustomerReducer.educations,
  educationFilters: state.CustomerReducer.educationFilters,
  vbCustomer: state.CustomerReducer.selectedCustomer
})

const mapDispatchToProps = dispatch => ({
  customerActions: bindActionCreators(customerActions, dispatch),
  commonActions: bindActionCreators(commonActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WebinarsContainer);
