import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import './OrderContainer.css'
import EmailTemplateWithButtons from '../../Components/EmailTemplateWithButtons'
import routes from '../../Constants/routes'
import orderActions from '../../Actions/orderActions'
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '../../Components/Grid/Grid';
import Card from '../../Components/Card/Card';
import orderRocket from '../../Images/milestone_rocket.svg'


function OrderConfirmationContainer({ commonActions, confirmationForm, t, orderActions, customerActions }) {

    const navigate = useNavigate();
    const { customerNumber, orderId } = useParams()

    useEffect(() => {
        orderActions.getConfirmationFormTemplate(customerNumber, orderId);
    }, [])

    const navigateAfterAction = () => {
        return () => navigate(routes.customerMain(customerNumber));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(confirmationForm.id, field, value)
    }

    const onSaveClick = () => {
        customerActions.sendAdminForm(confirmationForm, navigateAfterAction(), "Order Confirmation has been sent");
    }

    const onCancelClick = () => {

        commonActions.cancelFormEditing(confirmationForm.id, navigateAfterAction())
    }


    if (!confirmationForm) {
        return null;
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Row.Column width={6}>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                {t('orderconfirmationcontainer.orderconfirmation')}
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Content>
                                    <EmailTemplateWithButtons data={confirmationForm.data}
                                        onChange={onFieldChange.bind(this)}
                                        onSaveClick={onSaveClick.bind(this)}
                                        onCancelClick={onCancelClick.bind(this)}
                                        senderEmails={confirmationForm.data.defaultSenderAddresses}
                                        onSenderChange={onFieldChange.bind(this)}
                                        hideWarning={true}
                                    />
                        </Card.Content>
                    </Card>
                </Grid.Row.Column>
                <Grid.Row.Column>
                    <div className='d-flex justify-content-center h-100'>
                    <img src={orderRocket} alt='en fin raket' width={450}/>
                    </div>
                </Grid.Row.Column>
            </Grid.Row>
        </Grid >
    );
}


function mapStateToProps(state) {
    return {
        confirmationForm: state.CommonReducer.forms && state.CommonReducer.forms.confirmationMail
    }
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderConfirmationContainer)); 