import { useEffect, useState } from "react";
import { AlertModal } from "../../AlertModal";

export function ProductConfirmation({ product, editOrderline, orderActions }) {

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setIsOpen(true);
    }, [product.productNumber]);

    const conditions = [
        {
            criteria: (product) => ['ADM02', 'ADM05', 'LÖN300'].includes(product.productNumber),
            type: 'warning',
            title: `Ingen nyförsäljning gäller för ${product.productDescription}!`,
            content: <p>Gäller ej om kunden haft ett avtal de senaste 15 månaderna</p>,
            onConfirm: () => setIsOpen(false),
            onCancel: () => {
                orderActions.hideProductDetails()
                setIsOpen(false)
            }
        }
    ];

    const matchedCondition = conditions.find(condition => condition.criteria(product));

    return <>
        {matchedCondition && !editOrderline && (
            <AlertModal isOpen={isOpen} type={matchedCondition.type}>
                <AlertModal.Header.Title>{matchedCondition.title}</AlertModal.Header.Title>
                <AlertModal.Body>
                    {matchedCondition.content}
                </AlertModal.Body>
                <AlertModal.Footer>
                    <div className="float-right">
                        <button type="button" className="btn btn-primary" onClick={matchedCondition.onCancel}>Tillbaka</button>
                        <button type="button" className="btn btn-default" onClick={matchedCondition.onConfirm}>Fortsätt</button>
                    </div>
                </AlertModal.Footer>
            </AlertModal>
        )}
    </>
}