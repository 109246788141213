import React, { useEffect } from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import commonActions from "../Actions/commonActions";
import helpers from "../Utils/helpers";
import routes from "../Constants/routes";
import { useNavigate } from "react-router-dom";

function VersionArchive({commonActions, t, releaseVersionList}){

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.getReleaseVersions();
    }, [])

    return (
        <div className="panel">
            <div className="panel-heading">
                <h1>{t("versionarchive.heading")}</h1>
                <h4>{t("versionarchive.description")}</h4>
            </div>
            <div className="panel-body">
                <div className="table-responsive">
                    <table className="table table-stripe table-hover table-hover-custom">
                        <thead>
                        <tr>
                            <th>{t("versionarchive.title")}</th>
                            <th>{t("versionarchive.created")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {releaseVersionList && releaseVersionList.map((item, index) => {
                            return <tr key={index} onClick={() => navigate(routes.versionArchiveDetails(item.id))}>
                                <td>{item.title}</td>
                                <td>{helpers.formatDate(item.created)}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        releaseVersionList: state.CommonReducer.releaseVersionList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VersionArchive));