import React, { Component } from 'react';
import { connect } from 'react-redux';

import DropDown from '../../Components/DropDown'
import { bindActionCreators } from 'redux';
import orderActions from '../../Actions/orderActions';
import { withTranslation } from 'react-i18next';

class AppointmentOrderDropdown extends Component {

    componentDidMount() {
        if (!this.props.currentlyInBasket && this.props.appointments && this.props.appointments.length > 0) {
            this.onChange(this.props.appointments[0].id);
        }
    }

    formatAppointmentName = (appointment) => {
        if (appointment.id > 0) {
            return `${appointment.subject}`;
        }
    }

    onChange = (value) => {
        this.props.orderActions.changeAssignedAppointment(value);
    }

    render() {
        const appointments = this.props.appointments;
        const { t } = this.props;

        if (!appointments || appointments.length === 0) {
            return null;
        }

        return (
            <div>
                <h3 className="font-family-ubuntu-semibold mb-12">{t("appointmentorderdropdown.assignedappointment")}</h3>
                <DropDown blankOptionLast={true} onChange={this.onChange} list={appointments} value={this.props.appointmentId ? this.props.appointmentId : -1} nameAggregate={this.formatAppointmentName} labelWidth={12} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    orderActions: bindActionCreators(orderActions, dispatch),
});

function mapStateToProps(state) {
    return {
        appointments: state.AppointmentReducer.myCustomerAppointments
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentOrderDropdown)); 