export const quotationActionTypes = {
    START_ORDER_QUOTATION: "START_ORDER_QUOTATION",
    SHOW_SEARCH_RESULTS_QUOTATION: "SHOW_SEARCH_RESULTS_QUOTATION",
    SHOW_PRODUCT_DETAILS_QUOTATION: "SHOW_PRODUCT_DETAILS_QUOTATION",
    HIDE_PRODUCT_DETAILS_QUOTATION: "HIDE_PRODUCT_DETAILS_QUOTATION",
    SHOW_ORDER_DETAILS_QUOTATION: "SHOW_ORDER_DETAILS_QUOTATION",
    CHANGE_ADDITIONAL_USERS_QUOTATION: "CHANGE_ADDITIONAL_USERS_QUOTATION",
    CHANGE_ADDITIONAL_BLIKK_USERS_QUOTATION: "CHANGE_ADDITIONAL_BLIKK_USERS_QUOTATION",
    CLEAR_BLIKK_ADDITIONAL_USERS_QUOTATION: "CLEAR_BLIKK_ADDITIONAL_USERS_QUOTATION",
    CLEAR_BLIKK_TRANSACTIONS_QUOTATION: "CLEAR_BLIKK_TRANSACTIONS_QUOTATION",
    CHANGE_BLIKK_TRANSACTIONS_QUOTATION: "CHANGE_BLIKK_TRANSACTIONS_QUOTATION",
    CHANGE_API_QUOTATION: "CHANGE_API_QUOTATION",
    CANCEL_ORDER_QUOTATION: "REMOVE_ALL_PRODUCTS_FROM_ORDER:_QUOTATION",
    EDIT_ORDERLINE_QUOTATION: "EDIT_ORDERLINE_QUOTATION",
    CHANGE_PERIOD_QUOTATION: "CHANGE_PERIOD_QUOTATION",
    CHANGE_ADDONS_QUOTATION: "CHANGE_ADDONS_QUOTATION",
    CHANGE_COMPETITOR_DISCOUNT_QUOTATION: "CHANGE_COMPETITOR_DISCOUNT_QUOTATION",
    CHANGE_ASSIGNED_LEAD_QUOTATION: "CHANGE_ASSIGNED_LEAD_QUOTATION",
    CHANGE_ASSIGNED_APPOINTMENT_QUOTATION: "CHANGE_ASSIGNED_APPOINTMENT_QUOTATION",
    CHANGE_LANG_CODE_QUOTATION: "CHANGE_LANG_CODE_QUOTATION",
    CHANGE_TEMPLATE_ORGANIZATION_QUOTATION: "CHANGE_TEMPLATE_ORGANIZATION_QUOTATION",
    SHOW_TEMPLATE_ORGANIZATIONS_QUOTATION: "SHOW_TEMPLATE_ORGANIZATIONS_QUOTATION",
    SHOW_TRIAL_SEARCH_RESULTS_QUOTATION: "SHOW_TRIAL_SEARCH_RESULTS_QUOTATION",
    FAQ_QUOTATION: "FAQ_QUOTATION",
    SET_ANSWER_QUOTATION: "SET_ANSWER_QUOTATION",
    CLEAR_ANSWER_QUOTATION: "CLEAR_ANSWER_QUOTATION",
    STORE_QUOTATIONS: "STORE_QUOTATIONS",
    CLEAR_QUOTATION_SEARCH_RESULTS: "CLEAR_QUOTATION_SEARCH_RESULTS",
    STORE_QUOTATION_WITH_ORDER: "STORE_QUOTATION_WITH_ORDER",
    STORE_CUSTOMER_QUOTATIONS_AMOUNT: "STORE_CUSTOMER_QUOTATIONS_AMOUNT"
}