import React from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'
import { useLocation } from 'react-router-dom';

function VonLinks({vbCustomer, customer, t, openHeader, setHeader}) {

    const location = useLocation();

    function getLinks() {
        let items = [
            {
                title: t('leftnavigation.von'), id: "von", onClick: () => setHeader("von"), subItems: []
            }
        ];
        if (vbCustomer.hasVonAccount) {
            items[0].subItems.push({ title: t('leftnavigation.vonaccounts'), path: routes.customerVonAccounts(customer.pathId) },
                {
                    title: t('leftnavigation.collaborations'), path: routes.customerCollaborations(customer.pathId)
                },
                {
                    title: t('leftnavigation.discounts'), path: routes.customerDiscounts(customer.pathId)
                },
                {
                    title: t('leftnavigation.log'), path: routes.icaCustomerLog(customer.pathId)
                }
                )
        }
        else
        {
            items[0].subItems.push({ title: t('leftnavigation.createvonaccount'), path: routes.customerNewVonAccount(customer.pathId)})
        }

        return items;
    }

    return <>
            {getLinks().filter(link => !link.disabled).map((item, index) => {
                return <LeftNavigationBarItem key={index} link={item} openHeader={openHeader} location={location}/>
            })}
        </>
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        openHeader: state.CommonReducer.openHeader
    }
}

export default (connect(mapStateToProps)(withTranslation()(VonLinks)));