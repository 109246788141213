import AlertSmall from "../../AlertSmall";

export function ProductWarnings({ product }) {
    
    let warnings = [];
    if (['ADM02', 'ADM05', 'LÖN300'].includes(product.productNumber)) {
      warnings.push({ title: "Ingen nyförsäljning gäller för denna produkt", type: "danger" });
    }

    return warnings.map(warning => <AlertSmall alert={warning} />);
}