import { httpFetch } from '../../Utils/httpUtils'
import { internalCustomerAdminActionTypes as actionTypes } from '../../ActionTypes/InternalCustomerAdmin/internalCustomerAdminActionTypes'
import commonActions from '../commonActions'

const terminationReasonsActions = {

    getTerminationReasons() {
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/GetTerminationReasons`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_TERMINATION_REASONS, data: json });
                })
        }
    },

    getSubTerminationReason(id, parentId) {
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/GetSubTerminationReason/${parentId}/${id}`, dispatch)
                .then(json => {
                    json.parentId = parentId;
                    return json;
                });
        };
    },

    getTerminationReason(id) {
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/GetTerminationReason/${id}`, dispatch)
                .then(json => {
                    return json;
                });
        };
    },

    getTerminationReasonForEdit(id, parentId) {
        if (!id) {
            var data = { name: null, subReasons: [] }
            return commonActions.startFormEditing("terminationReasonForm", data);
        }

        return (dispatch) => {
            return (parentId ? this.getSubTerminationReason(id, parentId) : this.getTerminationReason(id))
                .then(json => {
                    dispatch(commonActions.startFormEditing("terminationReasonForm", json))
                });
        }
    },

    createTerminationReason(terminationReasonForm, callback) {

        let data = terminationReasonForm.data;

        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/${data.parentId ? ('CreateSubTerminationReason/' + data.parentId) : 'CreateTerminationReason'}`, dispatch, { method: "POST", body: JSON.stringify(data) })
                .then(json => {
                    dispatch(commonActions.showSuccess(`Termination reason "${json.name}" has been created`));
                    callback();
                })
        }
    },

    updateTerminationReason(terminationReasonForm, callback) {
        let data = terminationReasonForm.data;
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/UpdateTerminationReason/${data.id}`, dispatch, { method: "PUT", body: JSON.stringify(data) })
                .then(json => {
                    dispatch(commonActions.showSuccess(`Termination reason "${json.name}" has been updated`));
                    callback();
                })
        }
    },

    updateSubTerminationReason(terminationReasonForm, callback) {
        let data = terminationReasonForm.data;
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/UpdateSubTerminationReason/${data.parentId}/${data.id}`, dispatch, { method: "PUT", body: JSON.stringify(data) })
                .then(json => {
                    dispatch(commonActions.showSuccess(`Termination reason "${json.name}" has been updated`));
                    callback();
                })
        }
    },

    deleteTerminationReason(id, callback) {
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/DeleteTerminationReason/${id}`, dispatch, { method: "DELETE" })
                .then(json => {
                    dispatch(commonActions.showSuccess('Termination reason has been deleted'));
                    callback();
                })
        }
    },

    deleteSubTerminationReason(id, parentId, callback) {
        return (dispatch) => {
            return httpFetch(`/api/terminationReasons/DeleteSubTerminationReason/${parentId}/${id}`, dispatch, { method: "DELETE" })
                .then(json => {
                    dispatch(commonActions.showSuccess('Termination reason has been deleted'));
                    callback();
                });
        }
    }
}
export default terminationReasonsActions;