import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import {useTranslation, withTranslation} from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import helpers from '../../Utils/helpers';
import commonActions from '../../Actions/commonActions'
import { useParams  } from 'react-router-dom'
import {NoInformation} from "../../Components/Panel";

const SentMoviesContainer = ({ vbCustomer, customerActions, sentMovies }) => {

    const { t } = useTranslation(); 
    
    useEffect(() => {
        customerActions.getSentMovies(vbCustomer.customerNumber);
    }, [])

    if (!sentMovies || sentMovies.length === 0) 
        return <NoInformation/>

    return <div className="table-responsive">
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th className="text-left">{t('sentmoviescontainer.sentto')}</th>
                        <th className="text-left">{t('sentmoviescontainer.movie')}</th>
                        <th className="text-left">{t('sentmoviescontainer.datesent')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sentMovies.map((movie, index) => {
                        return <tr key={index}>
                            <td>{movie.sentTo}</td>
                            <td>{movie.movie}</td>
                            <td>{helpers.formatDate(movie.dateSent)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
}

function mapStateToProps(state) {
    return {
        sentMovies: state.CustomerReducer.sentMovies,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SentMoviesContainer));

