import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import AdditionalUsers from '../../Components/Order/AdditionalUsers'
import ApiProduct from '../../Components/Order/ApiProduct'
import CompetitorDiscount from '../../Components/Order/CompetitorDiscount'
import helper from '../../Utils/helpers'
import LeadsOrderDropdown from '../../Components/Leads/LeadsOrderDropdown'
import AppointmentOrderDropdown from '../Appointment/AppointmentOrderDropdown'
import CustomerSearch from '../../Components/Search/CustomerSearch';
import commonActions from '../../Actions/commonActions'
import helpers from "../../Utils/helpers";
import HorizontalText, { HorizontalRow } from "../../Components/HorizontalText";

class VbProductDetails extends Component {

    onApiProductChange(isChecked) {
        var { customerNumber, product } = this.props;

        this.props.genericActions.changeApi(isChecked ? "yes" : "no")
        this.props.genericActions.getProductDetails(product.productId, customerNumber, product.additionalUsers, product.api, product.competitorDiscount, this.props.orderId, product.loyaltyDiscountCustomer)
    }

    onCompetitorDiscountChange(isChecked) {
        var { customerNumber, product } = this.props;

        this.props.genericActions.changeCompetitorDiscount(isChecked ? "yes" : "no");

        this.props.genericActions.getProductDetails(product.productId, customerNumber, product.additionalUsers, product.api, product.competitorDiscount, this.props.orderId, product.loyaltyDiscountCustomer)
    }

    onCustomerSelect(loyaltyDiscountCustomer) {
        var { customerNumber, product, commonActions, t } = this.props;
        if (loyaltyDiscountCustomer === customerNumber) {
            commonActions.showWarning(t('vbproductdetailscontainer.loyaltydiscountwarning'));
            return;
        }
        this.props.genericActions.changeLoyaltyDiscountCustomer(loyaltyDiscountCustomer);
        this.props.genericActions.getProductDetails(product.productId, customerNumber, product.additionalUsers, product.api, product.competitorDiscount, this.props.orderId, product.loyaltyDiscountCustomer)
    }

    onAdditionalUserChange(value) {
        const { customerNumber, product } = this.props
        this.props.genericActions.changeAdditionalUsersCount(value)
        this.props.genericActions.getProductDetails(productId, customerNumber, product.additionalUsers, product.api, product.competitorDiscount, this.props.orderId, product.loyaltyDiscountCustomer)
    }

    onAssignedLeadChange = (value) => {
        this.props.genericActions.changeAssignedLead(value);
    }

    onAssignedAppointmentChange = (value) => {
        this.props.genericActions.changeAssignedAppointment(value);
    }

    render() {
        const product = this.props.product;
        const { t } = this.props

        return <div className="row">
            <div className="col-6">
                <h3>{t('vbproductdetailscontainer.main')}</h3>
                <HorizontalRow>
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.number')} value={product.productNumber} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.description')} value={product.productDescription} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.renewperiod')} value={product.mainRenewPeriod} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.initialprice')} value={helper.currencyFormatter(product.mainInitialPrice)} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.nextprice')} value={helper.currencyFormatter(product.mainRecurringPrice)} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.nextinvoicedate')} value={product.mainRenewPeriod !== 0 ? helper.formatDate(product.nextInvoiceDate) : ''} />
                    {product.dueDate && <HorizontalText columns={1} label={t('vbproductdetailscontainer.duedate')} value={helper.formatDate(product.dueDate)} />}
                    <LeadsOrderDropdown onChange={this.onAssignedLeadChange} leadId={product.leadId} currentlyInBasket={product.currentlyInBasket} />
                    <AppointmentOrderDropdown onChange={this.onAssignedAppointmentChange} appointmentId={product.appointmentId} currentlyInBasket={product.currentlyInBasket} />
                </HorizontalRow>
            </div>
            <div className="col-6">
                <h3>{t('vbproductdetailscontainer.addons')}</h3>
                <AdditionalUsers product={product} onChange={this.onAdditionalUserChange.bind(this)} onSave={this.onAdditionalUserSave.bind(this)} label={t("additionalusers.additionalusers")} />
                <hr />
                {product.showHideDisableAddonsToggle.hideApi !== true && <>
                    <ApiProduct product={product} onChange={this.onApiProductChange.bind(this)} />
                    <hr />
                </>}

                {(product.allowCompetitorDiscount && product.competitorDiscount) && <>
                    <h3>{t('vbproductdetailscontainer.discounts')}</h3>
                    <CompetitorDiscount product={product} onChange={this.onCompetitorDiscountChange.bind(this)} />
                    <hr />
                </>}

                {this.props.vbCustomer && !helpers.isAoCustomer(this.props.vbCustomer) && <>
                    <h3>{t('vbproductdetailscontainer.loyaltydiscount')}</h3>
                    <CustomerSearch onChange={this.onCustomerSelect.bind(this)} />
                    <hr />
                </>}
                <HorizontalRow>
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.initialprice')} value={helper.currencyFormatter(product.addonsInitialPrice)} />
                    <HorizontalText columns={1} label={t('vbproductdetailscontainer.nextprice')} value={helper.currencyFormatter(product.addonsRecurringPrice)} />
                </HorizontalRow>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VbProductDetails));