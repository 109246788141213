import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next';
import Loader from '../../Components/Loader'
import DesktopTemplate from '../../Files/LeadListTemplates/Desktop.xlsx'
import DesktopCustNoTemplate from '../../Files/LeadListTemplates/Desktop(CustNo).xlsx'
import TableauTemplate from '../../Files/LeadListTemplates/Tableau.xlsx'
import settingsActions from '../../Actions/settingsActions'

class LeadsListsContainer extends Component {

    // componentWillUnmount() {
    //     this.handleCancelClick();
    // }

    state = {
        file: null,
        loading: false
    }

    handleUploadClick = () => {
        this.setState({ loading: true })
        this.props.settingsActions.uploadLeadsList(this.state.file);
    }

    handleCancelClick = () => {
        //window.URL.revokeObjectURL(this.state.file.preview); //todo: find a way to make this work properly. Not revoking can cause memory leaks.
        this.setState({ file: null });
    }

    render() {
        const { t } = this.props

        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className='col-xs-3'>
                            <Dropzone
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onDrop={(files) => this.setState({ file: files[0] })}
                                disabled={this.state.loading}
                                multiple={false}
                            >
                                {({ getRootProps }) => (
                                    <div {...getRootProps()} className='text-center padding-top'>
                                        <span className='vismaicon vismaicon-lg vismaicon-filled vismaicon-info' />
                                        <h1>{t('leadslistcontainer.dropfilehere')}</h1>
                                    </div>
                                )
                                }
                            </Dropzone>
                        </div>

                        <div className='col-xs-9'>
                            <aside>
                                <h2>{t('leadslistcontainer.fileinfo')}</h2>
                                {this.state.file &&
                                    <div>
                                        <ul>
                                            <li>{t('leadslistcontainer.name')}{this.state.file.name}</li>
                                            <li>{t('leadslistcontainer.size')}{(this.state.file.size / 1024 / 1024).toFixed(2)} Mb</li>
                                        </ul>
                                        <div className='padding-top'>
                                            <button className="btn btn-primary" type="button" disabled={this.state.loading} onClick={this.handleUploadClick}>{t('leadslistcontainer.upload')}</button>
                                            <button className="btn btn-default" type="button" disabled={this.state.loading} onClick={this.handleCancelClick}>{t('leadslistcontainer.cancel')}</button>
                                        </div>
                                        <Loader />
                                    </div>
                                }
                            </aside>
                        </div>
                    </div>
                </div>
                <h2>{t('leadslistcontainer.downloadabletemplates')}</h2>
                <label className="col-xs-4 control-label downloadable-template-description">{t("leadslistcontainer.downloaddescription")}</label>
                <ul>
                    <li><a href={DesktopTemplate} download="Desktop_SaaS_template.xlsx">Desktop/SaaS</a></li>
                    <li><a href={DesktopCustNoTemplate} download="Desktop_SaaS_CustNo_template.xlsx">Desktop/SaaS with customer number</a></li>
                    <li><a href={TableauTemplate} download="Tableau_template.xlsx">Tableau</a></li>
                </ul>
            </div >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(withTranslation()(LeadsListsContainer));