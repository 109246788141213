export const caseStatuses = {
    1: "Open",
    2: "Closed",
    3: "InMind"
}

export const eventStatuses = {
    1: {status: "Draft", className: "bs-callout-info"},
    2: {status: "External pending", className: "bs-callout-warning"},
    3: {status: "New", className: "bs-callout-success"},
    4: {status: "Downloaded", className: "bs-callout-success"},
    5: {status: "Timed out", className: "bs-callout-alert"},
    6: {status: "No events", className:  "bs-callout-info"}
}