import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment'
import { withTranslation } from 'react-i18next';

import Loader from '../Components/Loader'
import MyDashboardCharts from '../Components/MyDashboardCharts'

import helpers from '../Utils/helpers.js'
import myDashboardActions from '../Actions/myDashboardActions'

class DashboardContainer extends Component {

    state = {
        active: "week",
        dateDomain: { x: [moment().startOf('week'), moment().endOf('week')] },
        availableFilters: {
            education: 1,
            transactionService: 2,
            order: 4,
            consulting: 8
        },
        filterValue: 15,
        accumulative : localStorage.getItem("accumulative") === "true" ? true : false,
        showgoals : localStorage.getItem("showgoals") === "true" ? true : false,
    }

    componentDidMount() {
        this.props.myDashboardActions.getMyDashboardGoals();
        this.props.myDashboardActions.getMyDashboardData();
    }

    handleDateDomain = (date) => {
        this.setState({ dateDomain: { x: date } });
    }

    handleDateSet = (identifier) => {
        this.setState({ active: identifier })

        if (identifier === "week") {
            this.setState({ dateDomain: ({ x: [moment().startOf('week'), moment().endOf('week')] }) });

            return;
        }
        if (identifier === "month") {
            this.handleDateDomain([moment().startOf('month'), moment().endOf('month')]);

            return;
        }

        if (identifier === "prevMonth") {
            this.handleDateDomain([moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')])
        }
    }

    handleFilterSet(value) {
        this.setState({ filterValue: this.state.filterValue ^ value })
    }

    handleToggleSettings(toggle) {
        localStorage.setItem(toggle, JSON.stringify(!this.state[toggle]))
        this.setState({ [toggle] : !this.state[toggle] })
    }

    groupTotalsByDate = (data) => {

        const result = [];
        const startDate = this.state.dateDomain.x[0];
        const endDate = this.state.dateDomain.x[1];

        data.forEach(function (a) {
            if (a.x < startDate || a.x > endDate) {
                return;
            }

            result.push(a);
        }, Object.create(null));

        return helpers.getArrayPropertyTotals(result, "y");
    }

    groupDataByDate = (data, property, accumulative) => {
        data = this.filterSalesByType(data);

        const result = [];
        const startDate = this.state.dateDomain.x[0];
        const endDate = this.state.dateDomain.x[1];

        data.forEach(function (a) {
            if (a.x < startDate || a.x > endDate) {
                return;
            }

            if (!this[a[property]]) {
                this[a[property]] = { [property]: a[property], y: 0 };
                result.push(this[a[property]]);
            }
            this[a[property]].y += a.y;
        }, Object.create(null));

        
        if(accumulative){
            let previousValue = 0;
            result.sort((a, b) => a.x > b.x ? 1 : -1);
            result.forEach(function (a, index) {
                a.y += previousValue;
                previousValue = a.y;
            })
        }

        return result;
    }

    groupGoalData = (data) => {
        const goalData = this.props.data.dashboardGoals;

        var goal = {
            revenueGoal: [{x: goalData.startDate, y: data.revenue}, {x: goalData.endDate, y: data.revenue}],
            quantityGoal: [{x: goalData.startDate, y: data.quantity}, {x: goalData.endDate, y: data.quantity}],
        }
        
        return goal;
    }

    filterSalesByType = (data) => {
        return data.filter(e => e.type & this.state.filterValue)
    }

    renderNoData = () => {
        const {t} = this.props
        return (
            <div className="alert" role="alert">
                <div>
                    <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-info" aria-hidden="true"></span>
                    <strong>{t('dashboardcontainer.nodata')}</strong> {t('dashboardcontainer.selectedperiodnodata')}
                </div>
            </div>
        )
    }

    render() {
        const {t} = this.props
        if (!this.props.data) {
            return <div className="row margin-top">
                <div className="col-xs-12">
                    <div style={{ display: 'inline-block' }}><Loader override={true} /></div>
                </div>
            </div>;
        }

        return (

            <div className="row mt-10">
                <div className="col-xs-12">
                    <button type="button" className={`btn btn-default ${this.state.active === "week" && "active"}`} onClick={() => this.handleDateSet("week")}>{t('dashboardcontainer.thisweek')}</button>
                    <button type="button" className={`btn btn-default ${this.state.active === "month" && "active"}`} onClick={() => this.handleDateSet("month")}>{t('dashboardcontainer.thismonth')}</button>
                    <button type="button" className={`btn btn-default ${this.state.active === "prevMonth" && "active"}`} onClick={() => this.handleDateSet("prevMonth")}>{t('dashboardcontainer.previousmonth')}</button>
                </div>

                <div className="col-xs-12">
                    <button type="button" className={`btn btn-default ${this.state.filterValue & this.state.availableFilters.order && "active"}`} onClick={() => this.handleFilterSet(this.state.availableFilters.order)}>{t('dashboardcontainer.order')}</button>
                    <button type="button" className={`btn btn-default ${this.state.filterValue & this.state.availableFilters.transactionService && "active"}`} onClick={() => this.handleFilterSet(this.state.availableFilters.transactionService)}>{t('dashboardcontainer.transactionservice')}</button>
                    <button type="button" className={`btn btn-default ${this.state.filterValue & this.state.availableFilters.education && "active"}`} onClick={() => this.handleFilterSet(this.state.availableFilters.education)}>{t('dashboardcontainer.education')}</button>
                    <button type="button" className={`btn btn-default ${this.state.filterValue & this.state.availableFilters.consulting && "active"}`} onClick={() => this.handleFilterSet(this.state.availableFilters.consulting)}>{t('dashboardcontainer.consulting')}</button>
                </div>

                <div className="col-xs-12">
                <button type="button" className={`btn btn-default ${this.state.accumulative && "active"}`} onClick={() => this.handleToggleSettings("accumulative")}>{t('dashboardcontainer.accumulative')}</button>
                <button type="button" className={`btn btn-default ${this.state.showgoals && "active"}`} onClick={() => this.handleToggleSettings("showgoals")}>{t('dashboardcontainer.showgoals')}</button>
                </div>

                <div className="col-xs-6 mt-10">
                    <MyDashboardCharts
                        data={this.props.data.myData}
                        goalData={this.props.data.dashboardGoals ? this.props.data.dashboardGoals.myDashboardGoal : null}
                        groupDataAction={this.groupDataByDate}
                        groupGoalAction={this.groupGoalData.bind(this)}
                        groupTotalsAction={this.groupTotalsByDate}
                        domain={this.state.dateDomain}
                        renderNoData={this.renderNoData}
                        titlePrefix={t('dashboardcontainer.my')}
                        accumulative={this.state.accumulative}
                        showgoals={this.state.showgoals} />
                </div>

                <div className="col-xs-6 mt-10">
                    <MyDashboardCharts
                        data={this.props.data.teamData}
                        goalData={this.props.data.dashboardGoals ? this.props.data.dashboardGoals.teamDashboardGoal : null}
                        groupGoalAction={this.groupGoalData.bind(this)}
                        groupDataAction={this.groupDataByDate}
                        groupTotalsAction={this.groupTotalsByDate}
                        domain={this.state.dateDomain}
                        renderNoData={this.renderNoData}
                        titlePrefix={t('dashboardcontainer.team')}
                        accumulative={this.state.accumulative}
                        showgoals={this.state.showgoals}  />
                </div>

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.MyDashboardReducer.data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        myDashboardActions: bindActionCreators(myDashboardActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardContainer));