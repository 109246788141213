import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import Modal from '../Modal'
import NavTabs from '../NavTabs';
import FileBoxRequestRequirementContainer from '../../Containers/FileBox/FileBoxRequestRequirementContainer';
import FileBoxUploadFileContainer from '../../Containers/FileBox/FileBoxUploadFileContainer';
import FileBoxTextsContainer from '../../Containers/FileBox/FileBoxTextsContainer';

function EditFileTransferModal({ onClose, t, editModalTab, selectedEvent }) {


    const tabs = [
        {
            name: 'texts',
            label: 'Texts',
            content: <FileBoxTextsContainer selectedEvent={selectedEvent}/>
        },
        {
            name: 'filerequest',
            label: 'File request requirements',
            content: <FileBoxRequestRequirementContainer selectedEvent={selectedEvent}/>
        },
        {
            name: 'uploadfiles',
            label: 'Upload files to customer',
            content: <FileBoxUploadFileContainer/>
        },
    ];

    const handleClickClose = () => {
        onClose();
    }

    const modal = {
        title: t('editfiletransfermodal.editfiletransfer'),
        hideMainButton: true,
        onCancelClick: handleClickClose,
        stateful: true,
        open: true,
        dialogStyle: { width: "60%" }
    }


    return (
        <Modal {...modal}>
            <div className='edit-file-transfer-container'>
                <NavTabs tabs={tabs} initialTab={editModalTab} />
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditFileTransferModal));