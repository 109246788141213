import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import discountActions from '../../Actions/discountActions';
import TextInput from '../../Components/FormComponents/Input/TextInput';

function DiscountInformationFormContainer({ onChange, discountForm }) {

    const onFieldChange = (field, value) => {
        onChange(field, value);
    };

    const onDiscountTypeChange = (type) => {
        if (discountForm.data.discountType !== type) {
            if(discountForm.data.discountType)
                onFieldChange(discountForm.data.discountType, "0");
                
            onFieldChange(type, "");
        }
        onFieldChange("discountType", type)
    };

    const data = discountForm ? discountForm.data : {};
    const discountType = data?.discountType

    return (
        <div className='d-flex flex-row'>
            <div className="col-xs-4 mr-64">
                <TextInput
                    label="*Rabattavtalets namn"
                    value={data.name}
                    field="name"
                    onChange={(e) => onFieldChange('name', e.target.value)}
                    fieldErrorFlags={discountForm?.fieldErrorFlags}
                />
                <TextInput
                    label="*Beställare"
                    value={data.salesPersonEmail}
                    field="salesPersonEmail"
                    onChange={(e) => onFieldChange('salesPersonEmail', e.target.value)}
                    fieldErrorFlags={discountForm?.fieldErrorFlags}
                />
                <div className={`form-group`}>
                    <label>Kommentar</label>
                    <textarea value={data.description} type="text" style={{ height: "125px" }} className="form-control" onChange={(e) => onFieldChange('description', e.target.value)} />
                </div>
            </div>
            <div className="col-xs-4">
                <div className={`mb-12 form-group`}>
                    <label>*Rabattyp</label>
                    <div className="btn-group btn-group-primary d-block">
                        <button type="button" className={`btn ${discountType === 'discountMoney' && "active"}`} style={{ width: "50%" }} onClick={() => onDiscountTypeChange('discountMoney')}>SEK/månad</button>
                        <button type="button" className={`btn ${discountType === 'discountPercent' && "active"}`} style={{ width: "50%" }} onClick={() => onDiscountTypeChange('discountPercent')}>Procent</button>
                    </div>
                </div>
                <div className={`mb-10 form-group`}>
                    <label>*Rabattsats</label>
                    {discountType === 'discountPercent' ? (
                        <TextInput
                            placeholder='Ange rabattsats'
                            value={data.discountPercent}
                            field={"discountPercent"}
                            type="text"
                            onChange={(e) => onFieldChange('discountPercent', e.target.value)}
                            fieldErrorFlags={discountForm?.fieldErrorFlags}
                        />
                    ) : discountType === "discountMoney" ? (
                        <TextInput
                            placeholder='Ange rabatt i SEK (per månad)'
                            value={data.discountMoney}
                            field={"discountMoney"}
                            onChange={(e) => onFieldChange('discountMoney', e.target.value)}
                            fieldErrorFlags={discountForm?.fieldErrorFlags}
                        />
                    ) : (
                        <TextInput
                        field={"discountType"}
                        fieldErrorFlags={discountForm?.fieldErrorFlags}
                         disabled 
                         />
                    )}
                </div>
                <div className={`mb-48 mt-40 form-group`}>
                    <div className="checkbox mb-12">
                        <input type="checkbox" id="allCustomers" checked={data.allCustomers} onChange={(e) => onFieldChange('allCustomers', e.target.checked)} />
                        <label htmlFor="allCustomers">Rabatt för alla kunder</label>
                    </div>
                    <br />
                    <div className="checkbox">
                        <input type="checkbox" id="appliesToClients" checked={data.appliesToClients} onChange={(e) => onFieldChange('appliesToClients', e.target.checked)} />
                        <label htmlFor="appliesToClients">Ge byrån rabatt per kund</label>
                    </div>
                </div>
                <div className={`form-group`}>
                    <div className="checkbox">
                        <input type="checkbox" id="hideForCustomer" checked={data.hiddenForCustomers} onChange={(e) => onFieldChange('hiddenForCustomers', e.target.checked)} />
                        <label htmlFor="hideForCustomer">Dölj i lista</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountInformationFormContainer));
