import Modal from './Modal';
import MyAppointmentsFormContainer from '../Containers/Appointment/MyAppointmentsFormContainer';

function CustomerDirectoryAppointmentModal({
    
modalProps,
aoCustomer
}){

    const customer = {
        customerNumber: aoCustomer.customerNumber,
        customerName: aoCustomer.customerName,
        contactPerson: aoCustomer.contactPerson,
        phoneNumber: aoCustomer.phoneNumber,
        emailAddress: aoCustomer.email,
    }

    return (
        <Modal {...modalProps}>
                    <MyAppointmentsFormContainer customer={customer} hideButtons={true} width={12}/>         
        </Modal>
    )
}
export default CustomerDirectoryAppointmentModal;