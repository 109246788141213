import React, { useState } from 'react';
import PastActionInfo from '../PastActionInfo'
import helpers from '../../Utils/helpers'
import { useTranslation } from 'react-i18next';
import Modal from "../Modal";
import HorizontalText, { HorizontalRow } from "../HorizontalText";
import InvoiceList from "./InvoiceList";
import SkynetIcon from "../Fundamentals/SkynetIcon";
import {_adapters} from "chart.js";

function InvoiceListVoss({ invoices, selectInvoice, customerNumber, onSendClick, onCreditClick}) {

    const { t}  = useTranslation();

    return <div>
        <table className="table table-stripe table-invoices">
            <thead>
            <tr>
                <th className="text-left">{t('invoicelist.invoicenumber')}</th>
                <th className="text-left">{t('invoicelist.invoicedate')}</th>
                <th className="text-left">{t('invoicelist.expirydate')}</th>
                <th className="text-left">{t('invoicelist.amount')}</th>
                <th className="text-left">{t('invoicelist.residualamount')}</th>
                <th className="text-left">{t('invoicelist.paidunpaid')}</th>
            </tr>
            </thead>
            <tbody>
            {invoices.map((invoice) => {
                return <tr key={invoice.id} onClick={() => selectInvoice(invoice.id)}>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{helpers.formatDate(invoice.invoiceDate)}</td>
                    <td>{invoice.paymentDueDate ? helpers.formatDate(invoice.paymentDueDate) : ""}</td>
                    <td className="text-right">{invoice.totalAmount}</td>
                    <td className="text-right">{invoice.unpaidAmount}</td>
                    <td>{t(`invoicelist.${invoice.paymentStatus}`)}</td>
                </tr>
            })}
            </tbody>
        </table>
    </div>

}

const uniqueIds = function(subscriptions) {
    const ids = subscriptions.map(sub => sub.customer.id);
    return ids.filter((sub, index) => ids.indexOf(sub) === index);
};

export function InvoiceDetailsVoss({invoice, invoiceReceiverCustomerId}) {
    const { t}  = useTranslation();
    const [activeCustomerId, setActiveCustomerId] = useState(null);

    if (!invoice)
        return <></>;

    function renderPeriod(period) {
        if (!period || !period.startDate || !period.endDate)
            return ""
        return `${helpers.formatDate(period.startDate)} - ${helpers.formatDate(period.endDate)}`;
    }

    const allIds = invoice.subscriptions.map(sub => sub.customer.id).filter(id => id !== invoiceReceiverCustomerId);
    const customerIds = allIds.filter((sub, index) => allIds.indexOf(sub) === index);

    const handleRowClick = (customerId) => {
        setActiveCustomerId(prevCustomerId => (prevCustomerId === customerId ? null : customerId));
    }

    function renderSubscriptions(subscription, customerId) {
        let subscriptionName = subscription.name;
        return subscription.items.map(row => {
            const result =
                <tr key={customerId + row.name}>
                    <td>{subscriptionName}</td>
                    <td>{row.name}</td>
                    <td>{renderPeriod(row.period)}</td>
                    <td className="text-right">{row.quantity}</td>
                    <td className="text-right">{row.unitPrice}</td>
                    <td className="text-right">{row.amount}</td>
                    <td className="text-right">{row.totalAmount}</td>
                </tr>
            subscriptionName = ""
            return result
        })
    }

    return <table className="table table-stripe table-invoices">
        <thead>
        <tr>
            <th className="text-left">{t('invoicelist.subscription')}</th>
            <th className="text-left">{t('invoicelist.service')}</th>
            <th className="text-left">{t('invoicelist.period')}</th>
            <th className="text-left">{t('invoicelist.quantity')}</th>
            <th className="text-left">{t('invoicelist.unitprice')}</th>
            <th className="text-left">{t('invoicelist.amount')}</th>
            <th className="text-left">{t('invoicelist.totalamount')}</th>
        </tr>
        </thead>
        <tbody>
        { // Invoice receiver
            invoice.subscriptions.filter(s => s.customer.id === invoiceReceiverCustomerId).map(sub => renderSubscriptions(sub, invoiceReceiverCustomerId))
        }
        { // Clients
            customerIds.map(customerId => {
                const currentSubscriptions = invoice.subscriptions.filter(s => s.customer.id === customerId);
                const customer = currentSubscriptions[0].customer;
                return <>
                    {
                        <tr key={customerId} onClick={() => handleRowClick(customerId)}>
                            <td colSpan={5} className="bold">
                                <span className={`caret caret-lg ${activeCustomerId === customerId ? 'open' : ''}`}></span>
                                <span className="ml-24 d-inline-block">{customer.organizationNumber}</span>
                                <span className="ml-24 d-inline-block">{customer.name}</span>
                            </td>
                            <td className="bold">
                                {currentSubscriptions.reduce((total, s) => total + s.amount, 0)}
                            </td>
                            <td className="bold">
                                {currentSubscriptions.reduce((total, s) => total + s.totalAmount, 0)}
                            </td>
                        </tr>
                    }
                    {activeCustomerId === customerId ?
                        currentSubscriptions.map(sub => renderSubscriptions(sub, customerId)): null
                    }
                </>
            })
        }
        </tbody>
    </table>
}


export default InvoiceListVoss;