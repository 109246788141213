import React from 'react';
import { Route, Routes } from 'react-router-dom'

import InternalLeadsContainer from '../../Containers/Leads/InternalLeadsContainer'
import AOLeadDetailsContainer from '../../Containers/Leads/AoLeadDetailsContainer'
import AoLeadEditContainer from '../../Containers/Leads/AoLeadEditContainer'
import InternalLeadDetailsContainer from '../../Containers/Leads/InternalLeadDetailsContainer'
import InternalLeadEditContainer from '../../Containers/Leads/InternalLeadEditContainer'

import {UserRoles} from "../../Constants/userRoles";
import RequireAuth from "../Permission/RequireAuth";

const AoLeadsLayout = () => (
    <div className="row">
        <div className="col-xs-12">
            <Routes>
                <Route element={<RequireAuth.RequireReadWriteAuth roles={[UserRoles.AOLeads]}/>}>
                    <Route path={'/ao-leads/edit/:leadId'} element={<AoLeadEditContainer />} />
                </Route>
                <Route path={'/ao-leads/:leadId'} element={<AOLeadDetailsContainer />} />
                <Route element={<RequireAuth.RequireReadWriteAuth roles={[UserRoles.AOLeads]}/>}>
                    <Route path={'/edit/:leadId'} element={<InternalLeadEditContainer />} />
                </Route>
                <Route path={'/:leadId'} element={<InternalLeadDetailsContainer />} />
                <Route index element={<InternalLeadsContainer />} />
            </Routes>
        </div>
    </div>
);

export default AoLeadsLayout;