import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

import InternalCustomerCreateForm from '../../Components/Settings/InternalCustomerCreateForm'

class InternalCustomerContainer extends Component {

    state = {
        createdCustomer: null
    }

    componentDidMount() {
        this.props.settingsActions.startInternalCustomerCreateForm(this.createDefaultForm());
    }

    componentWillUnmount() {
        this.props.commonActions.cancelFormEditing(this.props.internalCustomerForm.id)
    }

    createDefaultForm() {
        return {
            firstName: "",
            lastName: "",
            companyName: "",
            removalTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            emailAddress: "@visma.com"
        }
    }

    successCallback = (newCustomer) => {
        this.props.settingsActions.startInternalCustomerCreateForm(this.createDefaultForm());
        this.setState({ createdCustomer: newCustomer });
    }

    handleCreateClick = () => {
        var date = this.props.internalCustomerForm.data.removalTime

        if (date && !moment(date).isBetween(moment().startOf('day'), moment().endOf('day').add(1, 'year'))) {
            this.props.commonActions.showWarning("Valid to has to be either blank or between " + moment().startOf('day').format("YYYY-MM-DD") + " and " + moment().endOf('day').add(1, 'year').format("YYYY-MM-DD"), 'Please validate!');
        } else {
            this.props.settingsActions.createInternalCustomer(this.props.internalCustomerForm, this.successCallback);
        }
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField(this.props.internalCustomerForm.id, field, value)
    }

    render() {
        const internalCustomerForm = this.props.internalCustomerForm;
        const createdCustomer = this.state.createdCustomer;

        if (!internalCustomerForm) {
            return null;
        }

        return (
            <div className='panel'>
                <div className="panel-heading"><h3 className="panel-title">Internt kundnummer</h3></div>
                <div className='panel-body'>
                    <div className="form-horizontal">

                        <InternalCustomerCreateForm
                            data={internalCustomerForm.data}
                            saveAction={this.handleCreateClick.bind(this)}
                            fieldChangeAction={this.handleFieldChange}
                        />

                        {createdCustomer &&
                            <div className="alert col-xs-6">
                                <div>
                                    <span className="vismaicon vismaicon vismaicon-filled vismaicon-info" aria-hidden="true"></span>
                                    Customer <strong>{createdCustomer.firstName} {createdCustomer.lastName}</strong> with Customernumber <Link to={routes.customerMain(createdCustomer.customerNumber)}> {createdCustomer.customerNumber} </Link> is created
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        internalCustomerForm: state.CommonReducer.forms && state.CommonReducer.forms.internalCustomerForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalCustomerContainer);