import React from 'react';
import { useTranslation} from 'react-i18next';
import Checkbox from "../Checkbox";

export default function ApiProduct({product, onChange}) {

    const { t } = useTranslation();
    
    const change = (element) => {
        onChange(element.checked)
    }
    
    const disabled = product.showHideDisableAddonsToggle.disableApi || product.showHideDisableAddonsToggle.hideApi
    const checked = product.api === "yes" || false;

    return <Checkbox label={t('apiproduct.api')} checked={checked} disabled={disabled} onChange={change} />
}