import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import routes from '../../Constants/routes'

const GuidedSellingList = ({guidedSellingRules}) => {

    if(!guidedSellingRules || guidedSellingRules.length === 0) {
        return null;
    }
    const {t} = useTranslation();
    const arr = guidedSellingRules.map(rule => rule.productSuggestions.length);
    const maxSuggestions = Math.max(...arr);

    return  <div>
                <table className="table table-stripe table-hover table-hover-custom">
                    <thead>
                        <tr>
                            <th>{t('guidedsellinglist.priority')}</th>
                            <th>{t('guidedsellinglist.cjomain')}</th>
                            <th>{t('guidedsellinglist.hasproduct')}</th>
                            { [...Array(maxSuggestions)].map((number, index) => <th key={index}>{t('guidedsellinglist.suggest')} {index + 1}</th> )}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { guidedSellingRules.map((rule, index) => {
                            return <tr key={index + 10}>
                                        <td>{rule.priority}</td>
                                        <td>{rule.cjoMain}</td>
                                        <td>{rule.productName}</td>
                                        {rule.productSuggestions.map((suggestion, index) => {
                                            return <td key={index + 100}>{suggestion.name}</td>
                                        })}
                                        <td><Link to={routes.settingsGuidedSellingEdit(rule.id)}>{t('guidedsellinglist.edit')}</Link></td>
                                    </tr>
                        }) }
                    </tbody>
                </table>
            </div>
}

export default GuidedSellingList;