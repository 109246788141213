import CustomerSearchNew from "./CustomerSearchNew";
import React, {useEffect, useRef, useState} from "react";

export default function TopNavigationSearch({ onSearch}) {
    const [isSearching, setIsSearching] = useState(false);
    const [keepOpen, setKeepOpen] = useState(false);
    const ref = useRef();
    useOutsideClickAlerter(ref, () => handleClickOutside(), keepOpen);

    function handleClickOutside() {
        setKeepOpen(false);
        setIsSearching(false);
    }

    function onChange (customerNumber) {
        onSearch(customerNumber);
        setKeepOpen(false)
        setIsSearching(false);
    }

    return (
        <li ref={ref} className={`w-100 skynet-icon-search ${isSearching ? "navbar-form open" : "icon"} `} role="search" onMouseLeave={() => setIsSearching(!!keepOpen)}>
            {isSearching ?
                <div onClick={() => setKeepOpen(true)} onContextMenu={() => setKeepOpen(true)}>
                    <CustomerSearchNew id="topnavigationsearch" onChange={(customerNumber) => onChange(customerNumber)}
                                       placeholder="Search customer" autoFocus={true}/>
                </div>
                :
                <a onMouseEnter={() => setIsSearching(true)}>
                    <span className={`vismaicon vismaicon-dynamic vismaicon-search`} aria-hidden="true"></span>
                </a>
            }
        </li>)
}

// Custom hook. Should be moved to a separate file when/if used somewhere else
function useOutsideClickAlerter(ref, onClickOutside, isActive = true) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }

        if (isActive) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isActive]);
}