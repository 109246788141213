import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

function FileBoxUploadFileContainer({ t }) {

    const [file, dropFile] = useState();

    useEffect(() => {
    }, [])


    return (
        <>
<div className="col-xs-12">
                <h3>Ladda upp filer</h3>
            </div>
        <div className="panel-body">
            
            <div>
                    <form style={{ display: "contents" }} className="form-horizontal col-xs-10">
                        <div className="col-xs-6">
                            <Dropzone
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onDrop={(files) => dropFile({ file: files[0] })}
                                multiple={false}
                            >
                                {({ getRootProps }) => (
                                    <div {...getRootProps()} className="filedrag">
                                        <label className="btn btn-primary btn-lg fileSelectButton">
                                            Ladda upp filer
                                        </label>
                                        <div className="clearfix"></div>

                                        <div className="filedragLabel">Dra och släpp filer här</div>
                                        
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <fieldset style={{ float: "right" }} className="col-xs-4">
                        </fieldset>
                    </form>
            </div>
        </div>
        </>
    );
}
function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxUploadFileContainer));