import SkynetIcon from "../../Fundamentals/SkynetIcon";

function DiscountCustomersTable({ form: { data }, onChange, disableEdit }) {

    const onDeleteCustomer = (index) => {
        const existingCustomers = data.customers || [];
        existingCustomers.splice(index, 1);
        onChange(existingCustomers, 'customers');
    }

    return (
        <div>
            <table className="table table-stripe skynet-table-no-divider">
                <thead>
                    <tr>
                        <td colSpan={2}>Customer</td>
                    </tr>
                </thead>
                <tbody>
                    {data?.customers?.length > 0 ? data.customers.map((customer, index) => (
                        <tr key={index}>
                            <td width={500}>{customer.customerId}</td>
                            <td>
                                <div className="text-center">
                                    <SkynetIcon icon={"vismaicon-delete"} onClick={() => onDeleteCustomer(index)} />
                                </div>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={2}><em>No customers added</em></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default DiscountCustomersTable;