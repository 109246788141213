import React, { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './Editor.css'

export function FormEditor ({ label, value, onChange, readOnly}) {
    return <div className="form-group flexclass">
        {label && <label className="col-xs-4 control-label">{label}</label>}
        <div className={`col-xs-${label ? 8 : 12}`}>
            <Editor value={value} onChange={onChange} readOnly={readOnly} />
        </div>
    </div>
}
export default function Editor ({ value, onChange, readOnly}) {

    useEffect(() => {
        let Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Quill.register(Block, true)

        let Embed = Quill.import('blots/embed');
        class Signature extends Embed {
            static create(paramValue) {
                let node = super.create();
                node.innerHTML = paramValue;
                return node;
            }

            static value(node) {
                return node.innerHTML;
            }
        }

        Signature.blotName = 'signature';
        Signature.tagName = 'signature';

        Quill.register(Signature);

        let Color = Quill.import("attributors/style/color");
        Color.whitelist = [];
        Quill.register(Color);

    }, []);

    Editor.modules = {
        toolbar: !readOnly
    };

    return <div>
        <ReactQuill
            className="edit-quill"
            modules={Editor.modules}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
        />
    </div>
}