import {
    productOverviewActionTypes as actionTypes
} from "../ActionTypes/productOverviewActionTypes";
import { httpFetch } from "../Utils/httpUtils";
import commonActions from './commonActions';

const productOverviewActions = {
    resetVersionState(){
        return {type: actionTypes.RESET_VERSION_STATE};
    },
    getProducts() {
        return dispatch => {
            httpFetch("/api/productoverview/getproductlist")
            .then(res => {
                dispatch({type: actionTypes.STORE_PRODUCT_LIST, productList: res})
            });
        }
    },

    getProductById(){
        return (dispatch) => {
            dispatch({type: actionTypes.STORE_SINGLE_PRODUCT});
        }
    },

    setSelectedProduct(id) {
        return dispatch => {
            dispatch({type: actionTypes.SET_SELECTED_PRODUCT_ID, selectedProductId: id})
        }
    },

    getProductVersionList(id) {
        return dispatch => {
            httpFetch(`/api/productoverview/getproductversionsbyId/${id}`)
            .then(res => {
                dispatch({type: actionTypes.STORE_PRODUCT_VERSION_LIST, list: res});
            })
        };
    },

    getSendVersionsList(){
        return dispatch => {
            httpFetch('/api/productoverview/getsendversionslist')
            .then(res => {
                dispatch({type: actionTypes.STORE_SEND_VERSION_LIST, sendVersionsList: res})
            })
        }
    },

    updateProduct(id, data) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(data)
            }
            httpFetch(`/api/productoverview/updateproduct/${id}`, dispatch, httpRequest)
            .then(res => {
                dispatch(commonActions.showSuccess('Successfully edited product information'));
                dispatch(productOverviewActions.getProducts());
            })
        }
    },

    updateProductVersion(data) {
        return dispatch => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(data)
            }
            httpFetch(`/api/productoverview/updateproductversion`, dispatch, httpRequest)
            .then(res => {
                dispatch(commonActions.showSuccess('Successfully edited product version list'));
                dispatch(productOverviewActions.getProductVersionList(data.productId));
                dispatch(commonActions.loadDropdownList("DesktopVersion"));
            })
        }
    },

    deleteProductVersion(data) {
        return dispatch => {
            const httpRequest = {
                method: 'DELETE'
            }

            httpFetch(`/api/productoverview/deleteproductversion/${data.id}`, dispatch, httpRequest)
            .then(res => {
                dispatch(commonActions.showSuccess(`Successfully deleted version: ${data.version}`));
                dispatch(productOverviewActions.getProductVersionList(data.productId));
            })
        }
    },

    uploadProductImage(file, id) {
        return dispatch => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", id);

            const httpRequest = {
                method: 'POST',
                body: formData,
            }

            if(!file) {
                dispatch(commonActions.showError("File is missing or is incorrect format"));
                return;
            }
            httpFetch("/api/productoverview/uploadproductimage", dispatch, httpRequest, true)
            .then(res => {
                dispatch(commonActions.showSuccess(`Successfully added image to product`));
                dispatch(productOverviewActions.getProducts());
            })
        }
    },

    deleteProductAndVersions(id){
        return dispatch => {

            const httpRequest = {
                method: 'DELETE'
            }

            httpFetch(`/api/productoverview/deleteproductandversions/${id}`, dispatch, httpRequest)
            .then(res => {
                dispatch(productOverviewActions.getProducts());
            })
        }
    }
}

export default productOverviewActions;