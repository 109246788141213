import React from 'react';

 export default function RadioButtonsInline({ label, name, values, currentChecked, onChange, disabled, column, affix }){
      
        return <div className="col-12 p-2">
            <div className={"row"}>
                {label && <div className="col-6 p-0">
                    <span className="horizontal-text-label">{label}</span>
                </div>}
                <div className={column ? `d-flex flex-column` : `col-6 p-0`} style={column ? {gap: "12px"} : {}}>
                    {
                        values.map((value, index) => {

                            const attributes = {
                                type: 'radio',
                                value: value,
                                name: name || 'radio-group',
                                checked: value === currentChecked,
                                onChange: () => onChange(value),
                                disabled: disabled
                            }

                            return <div className="radio my-0">
                            <input {...attributes} id={index + name} />
                            <label className="radio-inline" htmlFor={index + name} key={index}>
                                {affix ? `${value}${affix}` : value}
                            </label>
                        </div>
                        })
                    }
                </div>
            </div>
        </div>
}