export const isFeatureEnabled = (user, flag) => {
    return user && user.featureFlags.includes(flag)
};

export const FeatureFlags = {
    VOSS: "VOSS",
    DiscountAgreements: "Discount_Agreements",
    VOSS_2308_Invoicing: "Voss_Invoicing",
    VOSS_2308_Invoicing_TransactionSource: "Voss_Invoicing_TransactionSource",
    VSCO_2308_Future_dates: "Voss_Invoicing_Future_dates"
};