import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import moment from 'moment'
import appointmentActions from '../../Actions/appointmentActions'

import Modal from '../../Components/Modal'
import AppointmentForm from  '../Appointment/AppointmentForm'  
import { withTranslation } from 'react-i18next';

import leadActions from '../../Actions/leadActions'
import commonActions from '../../Actions/commonActions'

import routes from '../../Constants/routes'

class AppointmentModal extends Component {

    componentDidMount() {
       this.props.leadActions.startAppointmentModal(this.createModel())
    }

    createModel() {
        const { lead, user, leadType } = this.props;

        return {
            customerName: lead.name,
            customerNumber: lead.customerNumber,
            contactPerson: lead.contact,
            phoneNumber: lead.phoneNumber,
            emailAddress: lead.email,
            time: moment().format('YYYY-MM-DD HH:mm'),
            assignee: user.email,
            subject: `Lead: ${lead.contact}`,
            refData: leadType === "aoLead" ? `${routes.aoleadDetails(lead.id)}` : leadType === "internalLead" ? `${routes.internalLeadDetails(lead.id)}` : `${routes.leadDetails(lead.id)}`
        }
    }

    onSaveClick = () => {
        const { lead, appointmentForm, leadActions, onComplete, leadType} = this.props;
        
        leadActions.saveAppointment(appointmentForm, lead.id, leadType, () => onComplete())
    }

    onSaveAndCreateClick() {
        const { lead, appointmentForm, leadActions, onComplete, leadType} = this.props;

        leadActions.saveAppointment(appointmentForm, lead.id, leadType, () => onComplete(), true)
    }

    onCancelClick = () => {
        const { commonActions, appointmentForm, onClose } = this.props;

        commonActions.cancelFormEditing(appointmentForm, () => onClose());
    }

    onFieldChange = (field, value) => {
        const { commonActions, appointmentForm } = this.props;

        commonActions.changeMultiFormField(appointmentForm.id, field, value);
    }

    customerSearchOnChange(customerNumber, customerName) {
        this.onFieldChange('customerNumber', customerNumber);
        this.onFieldChange('customerName', customerName);
    }

    getCustomerNumberForCallback() {
        return this.props.isMyAppointment ? null : this.props.appointmentForm.data.customerNumber;
    }

    render() {

        const { appointmentForm, user, t } = this.props;
        const appointment = appointmentForm && appointmentForm.data

        if(!appointment) {
            return null;
        }

        const modalProps = {
            title: t('appointmentmodal.createappointment'),
            mainButtonTitle: t('appointmentmodal.create'),
            stateful: true,
            open: true,
            showButtons: false,
            onSaveClick: this.onSaveClick,
            onCancelClick: this.onCancelClick,
        }

        const formProps = {
            appointment: appointment, 
            assignee: {name: user.name || '', email: user.email },
            onFieldChange: this.onFieldChange, 
            isCompleted: false, 
            readonly: false, 
            hideButtons: false,
            onCancelClick: () => this.onCancelClick(),
            onSaveClick: () => this.onSaveClick(),
            customerSearchOnChange: (customerNumber, customerName) => this.customerSearchOnChange(customerNumber, customerName),
            showCompleteButton: false,
            onSaveAndCreateClick: () => this.onSaveAndCreateClick(), 
            showReopenButton: false,
            isCreation: true,
            width: 12
        }

        return  <Modal {...modalProps}>
                    <AppointmentForm {...formProps} />
                </Modal>
    }
}

function mapStateToProps(state) {
    return {
        appointmentForm: state.CommonReducer.forms && state.CommonReducer.forms.leadAppointment,
        user: state.CommonReducer.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch),
        appointmentActions: bindActionCreators(appointmentActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentModal));