import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import leadActions from '../../Actions/leadActions'
import { withTranslation } from 'react-i18next';

import routes from '../../Constants/routes'
import helpers from '../../Utils/helpers'

import StaticTextInput from '../StaticTextInput'
import TextInput from '../TextInput'
import {FormEditor} from '../Editor';

import Loader from '../Loader'
import {UserRoles} from '../../Constants/userRoles'
import DropDown from '../DropDown';
import {hasReadWriteAccess} from "../../Utils/permissionUtils";

class AoLeadForm extends Component {

    assignTo(leadId, userId) {
        this.props.AssignTo(leadId, userId)
    }

    render() {

        const { lead, onFieldChange, onSaveClick, onCancelClick, readonly, onReopenClick, onAssingButtonClick, user, t, salesPersons } = this.props;

        if (!lead) {
            return null;
        }

        const isSale = lead.isClosed && lead.amount > 0;
        const aoSalesAccess = hasReadWriteAccess(user, UserRoles.AOSales)
        const cancelButtonText = readonly ? t('aoleadform.back') : t('aoleadform.cancel');

        const priorityTypes = [
            { id: 'high', name: t('leadsendemailmodal.high') },
            { id: 'medium', name: t('leadsendemailmodal.medium') },
            { id: 'low', name: t('leadsendemailmodal.low') }
        ]
        let priority = '';

        if (lead.priorityType === "high")
            priority = "vismaicon vismaicon-priority-high";
        else if (lead.priorityType === "medium")
            priority = "vismaicon vismaicon-priority-medium";
        else if (lead.priorityType === "low")
            priority = "vismaicon vismaicon-priority-low";

        return <form className="form-horizontal col-xs-12">
            <StaticTextInput label={t('aoleadform.customername')} value={lead.name} />
            <StaticTextInput label={t('aoleadform.customernumber')} value={lead.customerNumber ? lead.customerNumber : 'N/A'} type={lead.customerNumber && lead.customerNumber !== 'N/A' ? 'link' : ''} to={routes.customerMain(lead.customerNumber)} newTab={true} />

            <TextInput label={t('aoleadform.contactperson')} onChange={onFieldChange.bind(this, 'contact')} value={lead.contact} readonly={readonly} />
            <TextInput label={t('aoleadform.phonenumber')} onChange={onFieldChange.bind(this, 'phoneNumber')} value={lead.phoneNumber} readonly={readonly} />
            <TextInput label={t('aoleadform.emailaddress')} onChange={onFieldChange.bind(this, 'email')} value={lead.email} type="email" readonly={readonly} />

            <DropDown label={t('aoleadform.assignedto')} elementWidth={8} list={salesPersons} blankOption={true} value={lead.assigneeUserId} onChange={value => this.assignTo(lead.id, value)} />

            <StaticTextInput label={t('aoleadform.created')} value={helpers.formatDateAndTime(lead.dateCreated)} />
            {lead.isClosed && <StaticTextInput label={t('aoleadform.closed')} value={helpers.formatDateAndTime(lead.dateClosed)} />}
            {lead.isClosed && <StaticTextInput label={t('aoleadform.closedby')} value={lead.closedBy} />}

            <StaticTextInput label={t('aoleadform.createdby')} value={lead.createdBy} />
            {readonly && <StaticTextInput label={t('aoleadform.priority')} icon={priority} value={lead.priorityType ? t('leadsendemailmodal.' + lead.priorityType) : t('aoleadform.nopriority')} />}
            {!readonly && <DropDown label={t('aoleadform.priority')} elementWidth={8} list={priorityTypes} value={lead.priorityType} onChange={onFieldChange.bind(this, "priorityType")} />}
            {readonly && <StaticTextInput label={t('aoleadform.description')} type="raw" value={lead.description} />}
            {!readonly && <FormEditor label={t('aoleadform.descriptionedit')} toolbar={false} value={lead.description} onChange={onFieldChange.bind(this, 'description')} />}

            {lead.isClosed && <StaticTextInput label={t('aoleadform.sale')} value={isSale ? t('aoleadform.yes') : t('aoleadform.no')} />}
            {isSale && <StaticTextInput label={t('aoleadform.amount')} value={helpers.currencyFormatter(lead.amount)} />}

            <div className="clearfix"></div>
            <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                {!readonly && <div>
                    <button type="button" className="btn btn-primary" onClick={onSaveClick.bind(this)}>{t('aoleadform.save')}</button>
                </div>
                }

                {lead.isClosed && aoSalesAccess && <div>
                    <button type="button" className="btn btn-default" onClick={onReopenClick}>
                        <span className="vismaicon vismaicon-sm vismaicon-refresh"></span> {t('aoleadform.reopen')}
                    </button>
                </div>
                }

                {readonly && lead.assigneeUserId !== user.id && !lead.isClosed && aoSalesAccess && <div>
                    <button type="button" className="btn btn-default" onClick={onAssingButtonClick}>
                        <span className="vismaicon vismaicon-sm vismaicon-user"></span> {t('aoleadform.assigntome')}
                    </button>
                </div>
                }

                <button type="button" className="btn btn-default" onClick={onCancelClick.bind(this)}><span className="vismaicon vismaicon-sm vismaicon-undo"></span> {cancelButtonText}</button>
                <Loader />
            </div>
        </form>

    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AoLeadForm));