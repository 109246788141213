import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import TransactionServiceGraph from "../Components/Graphs/TransactionServiceGraph";
import TransactionServiceGraphTeam from "../Components/Graphs/TransactionServiceGraphTeam";
import AoLeadsGraph from "../Components/Graphs/AoLeadsGraph";
import AoLeadsTeamGraph from "../Components/Graphs/AoLeadsTeamGraph";
import CreatedAoLeadsGraph from "../Components/Graphs/CreatedAoLeadsGraph";
import CreatedAoLeadsTeamGraph from "../Components/Graphs/CreatedAoLeadsTeamGraph";

class MyStatsContainer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='col-xs-12'>
        <div className='panel panel-default'>
          <div className='panel-heading'>
            <h3 className='panel-title'>{t("graph.myStats")}</h3>
          </div>
          <div className='panel-body'>
            <div className='graph-wrapper'>
              <div className='individGraphs'>
                <TransactionServiceGraph />
                <AoLeadsGraph />
                <CreatedAoLeadsGraph />
              </div>
              <div className='teamGraphs'>
                <TransactionServiceGraphTeam />
                <AoLeadsTeamGraph />
                <CreatedAoLeadsTeamGraph />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyStatsContainer);
