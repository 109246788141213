import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux'
import DateTimeInput from '../../Components/DateTimeInput'

import Modal from '../../Components/Modal'
import DropDown from '../../Components/DropDown'
import StaticTextInput from '../../Components/StaticTextInput'
import TextInput from '../../Components/TextInput'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'



class NewDiscountModal extends Component {

    componentDidMount() {
        const { customerActions } = this.props;
        customerActions.startNewDiscountModal(this.getModel())
    }

    getModel() {
        return {
            discountAgreementId: '',
            notifyEmailAddress: null,
            validToDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        }
    }

    handleFieldChange = (field, value) => {
        const { commonActions, form: { id: formId } } = this.props;

        commonActions.changeMultiFormField(formId, field, value);
    }

    handleClickClose = () => {
        this.props.onClose();
    }

    handleClickSave = () => {
        const { customerActions, form, vbCustomer, onSaveClick } = this.props
        onSaveClick(form, vbCustomer.customerId);
    }

    render() {
        const { t, vonDiscounts, form } = this.props;

        if (!form)
            return null;

        const modalProps = {
            title: t('newdiscountmodal.newdiscount'),
            mainButtonTitle: t('newdiscountmodal.save'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onSaveClick: this.handleClickSave
        }



        return (
            <Modal {...modalProps}>
                <form className='form-horizontal col-xs-10'>
                    <DropDown label={t('newdiscountmodal.discount')} blankOption={true} onChange={this.handleFieldChange.bind(this, 'discountAgreementId')} list={vonDiscounts} />
                    <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} closeOnSelect={false} label={t("newdiscountmodal.validto")} onChange={(value) => this.handleFieldChange('validToDate', value)} value={form.data.validToDate} />
                    <TextInput label={t('newdiscountmodal.comment')} maxAmountOfCharacters={1000} onChange={value => this.handleFieldChange('discountComment', value)} value={form.data.discountComment || ""} multiline={true} />
                    <div className="clearfix"></div>
                </form>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.newDiscountModal,
        vonDiscounts: state.CustomerReducer.vonDiscounts,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewDiscountModal));