import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import fileBoxActions from '../../Actions/fileBoxActions'
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from '../../Utils/helpers';
import EditFileTransferModal from '../../Components/FileBox/EditFileTransferModal';
import { eventStatuses } from '../../Constants/fileBoxStatuses';

function FileBoxTicketInstructionsContainer({ fileBoxActions, t, fileBoxCase }) {

    const [isExpanded, setExpanded] = useState(false);
    const [editModalTab, setEditModalTab] = useState("")
    const [selectedEvent, setSelectedEvent] = useState(null)

    const toggleEditModal = (tab, event) =>{
        setEditModalTab(tab)
        setSelectedEvent(event)
    }

    const showEditButtons = (status) =>{
        switch (status) {
            case 3:
            return false
            default: 
            return true
        }
    }

    return (
        <fieldset className="col-xs-12" style={{ marginBottom: "0px" }}>
            {fileBoxCase.events.length > 0 ? <legend style={{ marginBottom: "0px" }}>File tranfers <span style={{ cursor: "pointer" }} className={`vismaicon vismaicon-sm vismaicon-arrow-${isExpanded ? "up" : "down"}-circle`} id="toggleDetails" onClick={() => setExpanded(!isExpanded)}></span></legend> : <h4 style={{ marginBottom: "0px" }}>No instructions</h4>}
            {fileBoxCase.events.map((event, index) => {
                return <div className={eventStatuses[event.status].className}>
                    <div className="float-right text-right">
                        <h4>{eventStatuses[event.status].status}</h4>
                        {showEditButtons(event.status) && <div className='instruction-icon-container'>
                            <a><span className="vismaicon vismaicon-upload" onClick={() => toggleEditModal("uploadfiles", event)} aria-hidden="true"></span></a>
                            <a><span className="vismaicon vismaicon-edit" aria-hidden="true" onClick={() => toggleEditModal("texts", event)}></span></a>
                            <a><span className="vismaicon vismaicon vismaicon-delete" aria-hidden="true"></span></a>
                        </div>}
                    </div>
                    <div><h4>{event.title}</h4>
                        <span className="help-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{"Created: " + helpers.formatDateAndTime(event.created) + ", " + "Modified: " + helpers.formatDateAndTime(event.modified)}</span></div>
                    {isExpanded && <div style={{ paddingTop: "20px" }}>
                        <div className='filebox-ticket-instruction-wrapper'>
                            {event.fileRequirements.length > 0 && <fieldset className="col-xs-12" style={{ marginBottom: "20px" }}>
                                <legend style={{ marginBottom: "0px" }}>{t("fileboxticketinstructionscontainer.requestedfiles")}</legend>
                                <table className="table table-stripe table-actionLogs">
                                    <thead>
                                        <tr>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.filename')}</th>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.extension')}</th>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.required')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {event.fileRequirements.map((requirement, index) => {
                                            return <tr key={index}>
                                                <td >{requirement.fileName}</td>
                                                <td>{requirement.fileExtension}</td>
                                                <td>{t(requirement.required ? "fileboxticketinstructionscontainer.yes" : "fileboxticketinstructionscontainer.no")}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>}
                            {event.uploadedFiles.length > 0 && <fieldset className="col-xs-12" style={{ marginBottom: "20px" }}>
                                <legend style={{ marginBottom: "0px" }}>{t("fileboxticketinstructionscontainer.sentfiles")}</legend>
                                <table className="table table-stripe table-actionLogs">
                                    <thead>
                                        <tr>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.filename')}</th>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.size')}</th>
                                            <th className="text-left">{t('fileboxticketinstructionscontainer.downloaded')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {event.uploadedFiles.map((upload, index) => {
                                            return <tr key={index}>
                                                <td >{upload.fileName}</td>
                                                <td>{upload.formatedFileSize}</td>
                                                <td>{helpers.formatDateAndTime(upload.downloadedAt)}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: event.text }} />

                    </div>}
                </div>
            })}
            {editModalTab && <EditFileTransferModal editModalTab={editModalTab} selectedEvent={selectedEvent} onClose={() => toggleEditModal(null, null)} />}
        </fieldset>
    );
}
function mapStateToProps(state) {
    return {
        fileBoxCase: state.FileBoxReducer.fileBoxCase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fileBoxActions: bindActionCreators(fileBoxActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileBoxTicketInstructionsContainer));