import React, { Component } from 'react';
import { connect } from 'react-redux';

import DropDown from '../DropDown'

class LeadsOrderDropdown extends Component {

    componentDidMount() {
        if (!this.props.currentlyInBasket && this.props.leads && this.props.leads.length > 0) {
            this.props.onChange(this.props.leads[0].id);
        }
    }

    render() {

        const leads = this.props.leads;

        if (!leads || leads.length === 0) {
            return null;
        }

        return (
            <div>
                <br />
                <fieldset>
                    <legend />
                    <DropDown label="leadorderdropdown.assignedlead" blankOptionLast={true} onChange={this.props.onChange} list={leads} value={this.props.leadId ? this.props.leadId : -1} />
                </fieldset>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        leads: state.LeadsReducer.myCustomerLeads
    }
}

export default connect(mapStateToProps, null)(LeadsOrderDropdown); 