
import Card from "./Card";
import {ContentTabs} from "../Tabs/ContentTabs";
export function TabsCard({ tabs, initialTab, children }) {

    return <>
        <Card>
            <ContentTabs tabs={tabs} initialTab={initialTab} />
            {children}
        </Card>
    </>
}